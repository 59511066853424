import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import { rangeDate } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { mixed, object } from 'yup';
import AnalyticsModal from '../../../../../../../analytics/AnalyticsModal'
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { ClinicsContext } from '../../../../../../../contexts/ClinicsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import CollapsibleCard from '../../../../gameIncidentReports/components/CollapsibleCard';
import AttendeeCard from './AttendeeCard';

/**
 * @param {{ mode: 'PRESENCE'|'RESULT', participants: any[] }} param
 */
const AttendeeModalBulkUpdate = ({ mode, participants, currentClinic, ...props }) => {
    const clinicsContext = useContext(ClinicsContext);
    const [ view, setView ] = useState('CONFIRM');


    const { validParticipants, invalidParticipants } = participants.reduce((sortedParticipant, participant) => {
        switch (mode){
            case 'PRESENCE':
                if(participant.passed != '1'){ // Updating the attended only works on attendees without the passed and that have paid
                    sortedParticipant.validParticipants.push(participant);
                }else{
                    sortedParticipant.invalidParticipants.push(participant);
                }
                break;
            case 'RESULT':
                if(participant.attended == '1'){ // Updating passed only works on attendees that have attended and paid
                    sortedParticipant.validParticipants.push(participant);
                }else{
                    sortedParticipant.invalidParticipants.push(participant);
                }
                break;
            default:
                sortedParticipant.invalidParticipants.push(participant);
                break;
        }
        return sortedParticipant;
    }, {
        validParticipants: [],
        invalidParticipants: [],
    });

    const minAttendedDate = rangeDate(validParticipants, 'attended_date', 'attended_date').max
    const minPassedDate = rangeDate(validParticipants, 'passed_date', 'passed_date').max

    return (
        <AnalyticsModal isOpen={props.isOpen} toggle={props.toggle} analyticsName='AttendeeModalBulkUpdate' onClosed={() => { setView('CONFIRM'); }}>
            {view === 'CONFIRM' ?
                <Formik
                    initialValues={{
                        date: moment(),
                        value: '0',
                    }}
                    validationSchema={object().shape({
                        date: mixed().when('value', {
                            is: (value) => value == 1,
                            then: mixed()
                                .required(<Translate id='account.members.clinicsManagement.attendees.attended.date.required' />)
                                .isDate(<Translate id='form.validation.date.format' />)
                                .test({
                                    name: 'dateBeforeValidation',
                                    message: <Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateBefore' />,
                                    test: (date) => {
                                        if(moment.isMoment(date)){
                                            return date.isBefore(new Date());// Before today
                                        }
                                        return true;
                                    },
                                })
                                .test({
                                    name: 'dateValidation',
                                    message: (
                                        <Translate
                                            id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter'
                                            values={{ minDate: mode === 'PRESENCE' ? minAttendedDate : minPassedDate }}
                                        />
                                    ),
                                    test: (date) => {
                                        if(moment.isMoment(date)){
                                            switch (mode){
                                                case 'PRESENCE':
                                                    return validParticipants.every((participant) => {
                                                        if(participant.attended_date){
                                                            const attendedDate = moment(participant.attended_date);
                                                            if(attendedDate.isValid()){
                                                                return date.isSameOrAfter(attendedDate);
                                                            }
                                                        }
                                                        return true;
                                                    });
                                                case 'RESULT':
                                                    return validParticipants.every((participant) => {
                                                        if(participant.passed_date){
                                                            const passedDate = moment(participant.passed_date);
                                                            if(passedDate.isValid()){
                                                                return date.isSameOrAfter(passedDate);
                                                            }
                                                        }
                                                        return true;
                                                    });
                                                default:
                                                    break;
                                            }
                                        }
                                        return true;
                                    },
                                }).when('value', (value, schema) => {
                                    if(mode === 'RESULT'){
                                        return schema.test({
                                            name: 'checkAttendedDate',
                                            message: (
                                                <Translate
                                                    id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter.attended'
                                                    values={{ minDate: minAttendedDate }}
                                                />
                                            ),
                                            test: (date) => {
                                                if(moment.isMoment(date)){
                                                    return validParticipants.every((participant) => {
                                                        if(participant.attended_date){
                                                            const attendedDate = moment(participant.attended_date);
                                                            if(attendedDate.isValid()){
                                                                return date.isSameOrAfter(attendedDate);
                                                            }
                                                        }
                                                        return true;
                                                    });
                                                }
                                                return true;
                                            },
                                        });
                                    }
                                    return schema;
                                })

                            ,
                        }),
                    })}
                    onSubmit={async(values, { setStatus }) => {
                        if(validParticipants.length){
                            const newValues = mode === 'PRESENCE' ? { // Attended
                                attended: values.value,
                                attended_date: values.value == '1' ? values.date.format('YYYY-MM-DD') : '',
                            } : { // Results
                                passed: values.value,
                                passed_date: values.value == '1' ? values.date.format('YYYY-MM-DD') : '',
                            };

                            const invalidMembers = invalidParticipants.map((participant) => participant.clinic_attendee_id);

                            return clinicsContext.updateClinicAttendeesPartial(currentClinic.clinic_id, {
                                ...newValues,
                                clinic_attendee_id: validParticipants.map((participant) => participant.clinic_attendee_id).join(','),
                            })
                                .then((results) => {
                                    invalidMembers.pushArray(results.failed);
                                    const newSpordleTableValues = props.createNewValues(newValues, invalidMembers);
                                    props.syncRows(newSpordleTableValues);
                                    setView({
                                        updatedMembers: validParticipants.filter((participant) => results.success.includes(participant.clinic_attendee_id)),
                                        failedMembers: validParticipants.filter((participant) => results.failed.includes(participant.clinic_attendee_id)),
                                    });
                                })
                                .catch((e) => {
                                    if(!AxiosIsCancelled(e.message)){
                                        console.error(e.message);
                                        setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                                    }
                                })
                        }
                    }}
                >
                    {(formik) => (
                        <Form id='bulkUpdate-attendee-status'>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={props.toggle}>
                                    <Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.title' />
                                </ModalHeader>
                                <ModalBody>
                                    <div className='mb-3'>
                                        <Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.helper' values={{ mode: mode }} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label><Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.inputLabel' values={{ mode: mode }} /></Label>
                                        <Row className="align-items-start" form>
                                            <Col sm='6' className='mb-2'>
                                                <FormikSelect
                                                    name="value"
                                                    id="value"
                                                    renderOption={(option) => <Translate id={option.option.label} values={{ count: validParticipants.length }} />}
                                                    loadingStatus='success'
                                                    defaultData={mode === 'PRESENCE' ? [
                                                        { value: '1', label: 'account.members.clinicsManagement.attendees.attended' },
                                                        { value: '0', label: 'account.members.clinicsManagement.attendees.attended.not' },
                                                    ] : [
                                                        { value: '1', label: 'account.members.clinicsManagement.attendees.passed' },
                                                        { value: '0', label: 'account.members.clinicsManagement.attendees.passed.not' },
                                                    ]}
                                                />
                                            </Col>
                                            <Col sm='6' className='mb-2'>
                                                <FormikDateTime
                                                    id='date'
                                                    name='date'
                                                    className='date-picker-left'
                                                    inputProps={{ disabled: formik.values.value == 0 }}
                                                    timeFormat={false}
                                                    dateFormat='YYYY-MM-DD'
                                                    isValidDate={(current) => {
                                                        const isBeforeToday = current.isSameOrBefore(new Date());
                                                        const validMinAttendedDate = moment(minAttendedDate).isValid();
                                                        switch (mode){
                                                            case 'PRESENCE':
                                                                if(validMinAttendedDate){
                                                                    return isBeforeToday && current.isSameOrAfter(minAttendedDate)
                                                                }
                                                                return isBeforeToday;
                                                            case 'RESULT':
                                                                const validMinPassedDate = moment(minPassedDate).isValid();
                                                                if(validMinAttendedDate && validMinPassedDate){
                                                                    return isBeforeToday && current.isSameOrAfter(minAttendedDate) && current.isSameOrAfter(minPassedDate);
                                                                }else if(validMinAttendedDate){
                                                                    return isBeforeToday && current.isSameOrAfter(minAttendedDate);
                                                                }else if(validMinPassedDate){
                                                                    return isBeforeToday && current.isSameOrAfter(minPassedDate);
                                                                }
                                                            default:
                                                                return isBeforeToday;
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CollapsibleCard
                                        title={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.title' values={{ members: validParticipants.length }} />}
                                        subtitle={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.description' values={{ members: validParticipants.length }} />}
                                    >
                                        {validParticipants.length ?
                                            validParticipants.map((participant) => (
                                                <AttendeeCard att={participant} withErrors key={participant.clinic_attendee_id} />
                                            ))
                                            :
                                            <Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.none' />
                                        }
                                    </CollapsibleCard>
                                    {!!invalidParticipants.length &&
                                        <CollapsibleCard
                                            error
                                            title={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.title' values={{ members: invalidParticipants.length }} />}
                                            subtitle={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.subtitle' />}
                                            className='mt-3'
                                        >
                                            {invalidParticipants.map((participant) => (
                                                <AttendeeCard att={participant} withErrors={mode} key={participant.clinic_attendee_id} />
                                            ))}
                                        </CollapsibleCard>
                                    }
                                    {/* <Collapse isOpen={props.isOpen && (!validParticipants.length || !!formik.status)} mountOnEnter unmountOnExit>
                                        <CustomAlert
                                            color='danger'
                                            className='mt-3 mb-0'
                                            text={formik.status || 'account.members.clinicsManagement.attendeesActions.bulkUpdate.cannot'}
                                            translateText={!formik.status}
                                            toggle={validParticipants.length ? () => formik.setStatus() : undefined}
                                        />
                                    </Collapse> */}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting || !validParticipants.length}><Translate id='misc.confirm' /></Button>
                                    <Button color='primary' type='button' outline onClick={props.toggle} disabled={formik.isSubmitting} className='ml-2'><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
                :
                <>
                    <ModalHeader toggle={props.toggle}>
                        <Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.title' />
                    </ModalHeader>
                    <ModalBody>
                        {!!view.updatedMembers.length &&
                            <CollapsibleCard
                                title={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success' values={{ count: view.updatedMembers.length }} />}
                                subtitle={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success.desc' values={{ count: view.updatedMembers.length }} />}
                                initOpen
                            >
                                {view.updatedMembers.map((participant) => (
                                    <AttendeeCard att={participant} withErrors key={participant.clinic_attendee_id} />
                                ))}
                            </CollapsibleCard>
                        }
                        {!!view.failedMembers.length &&
                            <CollapsibleCard
                                className='mt-3'
                                error
                                title={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail' values={{ count: view.failedMembers.length }} />}
                                subtitle={<Translate id='account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail.desc' values={{ count: view.failedMembers.length }} />}
                                initOpen={!view.updatedMembers.length}// Default open when there is no successfull update
                            >
                                {view.failedMembers.map((participant) => (
                                    <AttendeeCard att={participant} withErrors key={participant.clinic_attendee_id} />
                                ))}
                            </CollapsibleCard>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='button' onClick={props.toggle}><Translate id='misc.close' /></Button>
                    </ModalFooter>
                </>
            }
        </AnalyticsModal>
    )
}

export default AttendeeModalBulkUpdate
