import Translate from "@spordle/intl-elements";
import { stringifyUrl } from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../analytics/AnalyticsModal"
import { DisplayI18n } from "../../../helpers/i18nHelper";

const PreinitModal = ({ invoice }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    return (
        invoice &&
            <AnalyticsModal isOpen={isOpen} analyticsName="preinitNotificationModal">
                <PreinitModalInner invoice={invoice} toggle={() => setIsOpen(false)} />
            </AnalyticsModal>
    )
}

const PreinitModalInner = ({ invoice, toggle }) => {
    const type = findType(invoice.invoice_items);
    const invoiceItem = findInvoiceItem(invoice.invoice_items);
    const history = useHistory();

    function findType(items){
        let type = '';
        for(let i = 0; i < items.length; i++){
            const item = items[i];

            if(item.registration_fee)
                type = 'REGISTRATION'

            if(item.clinic)
                type = 'CLINIC'
        }

        if(!type)
            type = 'OTHER'

        return type
    }

    function findInvoiceItem(items){
        return items?.find((item) => {
            if(type === "REGISTRATION"){
                return item.registration_fee !== null;
            }else if(type === 'CLINIC'){
                return item.clinic !== null;
            }else if(type === 'OTHER'){
                return item.other_fee !== null && item.payments?.length > 0;
            }
        });
    }

    function handleOnPay(){
        history.push(stringifyUrl({
            url: '/payment',
            query: {
                invoicePaymentId: invoiceItem.payments.find((payment) => payment.payment_type === 'INITIAL_PAYMENT')?.invoice_payment_id,
            },
        }, {
            skipNull: true,
            skipEmptyString: true,
        }));
        toggle();
    }

    return (
        <>
            <ModalHeader className="border-0 position-absolute top-0 right-0 z-index-1" toggle={toggle} />
            <ModalBody className="text-center">
                <i className="mdi mdi-bell-ring-outline text-primary" style={{ fontSize: 75 }} />
                <div className="h2 font-bold text-primary"><Translate id='preinitModal.modal.title' /></div>
                <div className="mb-3">
                    {
                        type === "REGISTRATION" ?
                            <Translate
                                id='preinitModal.modal.text'
                                values={{
                                    org: (
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={invoice.organisation?.organisation_name}
                                            i18n={invoice.organisation?.i18n}
                                        />
                                    ),
                                    name: invoiceItem?.member?.first_name + ' ' + invoiceItem?.member?.last_name,
                                    registration: <DisplayI18n field="name" i18n={invoiceItem?.registration_fee?.fee?.i18n} defaultValue={invoiceItem?.registration_fee?.fee?.name} />,
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            : type === 'CLINIC' ?
                                <Translate
                                    id='preinitModal.modal.text'
                                    values={{
                                        org: (
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={invoice.organisation?.organisation_name}
                                                i18n={invoice.organisation?.i18n}
                                            />
                                        ),
                                        name: invoiceItem?.member?.first_name + ' ' + invoiceItem?.member?.last_name,
                                        registration: <DisplayI18n field='name' defaultValue={invoiceItem.clinic.name} i18n={invoiceItem.clinic.i18n} />, //<ClinicCategoryBuilder qualification={invoiceItem.clinic?.qualification}  />,
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                                : type === 'OTHER' ?
                                    <Translate
                                        id='preinitModal.modal.text.otherItems'
                                        values={{
                                            org: (
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={invoice.organisation?.organisation_name}
                                                    i18n={invoice.organisation?.i18n}
                                                />
                                            ),
                                            name: invoiceItem?.member?.first_name + ' ' + invoiceItem?.member?.last_name,
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                    : null
                    }
                </div>
                <Button onClick={handleOnPay} color="primary" type="button">
                    <Translate id="preinitModal.modal.btn" /> <i className="mdi mdi-arrow-right" />
                </Button>
            </ModalBody>
        </>
    )
}

export default PreinitModal;