import { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../Schedule';
import HoursList from '../../../components/HoursList';
import { stringBuilder } from "@spordle/helpers";
import TimeLine from '../../../components/TimeLine';
import ScheduleDailyFull from '../daily/ScheduleDailyFull';
import ScheduleWeeklyTopEvents from './ScheduleWeeklyTopEvents';
import WeeklyHeaderDay from './WeeklyHeaderDay';
import { classPrefix } from '../../../components/helpers';

/**
 * @description Displays weekly calendar.
 *
 * @param {string} [className] className of container
 * @param {boolean} [shortDays=false] Will only display short days labels
 * @returns {JSX.Element}
 */
const ScheduleWeeklyFull = ({ className, hideShared = false, shortDays = false }) => {
    const { week, selected, today, setNewDate } = useContext(ScheduleContext);
    const [ parts, setParts ] = useState({
        sections: [],
        headers: [],
    })

    function filterWeek(){
        const headers = [];
        const sections = [];

        for(let i = 0; i < week.length; i++){
            const day = week[i];
            const dayString = day.format('L');
            const date = day.format('D');

            headers.push(
                <WeeklyHeaderDay
                    onClick={() => setNewDate(day)}
                    day={day}
                    shortDays={shortDays}
                    key={`weekly-weekday-${date}`}
                    className={stringBuilder(`${classPrefix}-weekly__header-day`, { 'is-today': today.format('L') === dayString })}
                />,
            )

            sections.push(<ScheduleDailyFull key={`weekly-${dayString}`} date={day} />)
        }

        setParts({ sections: sections, headers: headers });
    }

    useEffect(filterWeek, [ week ]);

    return (
        <section className={`${classPrefix}-weekly ${className || ''}`}>
            <header className={`${classPrefix}-weekly__header`}>
                <div className={`${classPrefix}-weekly__header-timezone`}>UTC</div>
                {parts.headers}
            </header>
            {!hideShared && <ScheduleWeeklyTopEvents />}
            <div className={`${classPrefix}-weekly__inner`}>
                <TimeLine parentClass={`${classPrefix}-weekly__inner`} />
                <HoursList className={`${classPrefix}-weekly__hours-list`} />
                {parts.sections}
                <ScheduleDailyFull showShared className="is-selected" date={selected.selected} />
            </div>
        </section>
    );
}

export default ScheduleWeeklyFull;