import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Badge, Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Fade, UncontrolledDropdown } from "reactstrap";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import TablePagination from "../../../../../components/table/TablePagination";
import { AuthContext } from "../../../../../contexts/contexts";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { exportReportsToCSV, handleExcelExport } from "../../../../../helpers/reportsHelper";
import { attendeeHasAttended, attendeeHasPaid, attendeeHasPassed, READ_WRITE } from "../accountMemberClinicsHelper";
import SidePanelAttendees from "./sidePanel/SidePanelAttendees";

const AccountMemberAttendeesManagement = ({ member, selectedClinic }) => {
    const canEdit = selectedClinic.instructor_access === READ_WRITE;
    const intl = useIntl();
    const { getGenericLocale } = useContext(I18nContext);
    const reportsContext = useContext(ReportsContext);
    const { userData } = useContext(AuthContext);

    const exportAttendees = async() => {
        const reports = await reportsContext.getClinicAttendeesReport({ clinic_id: selectedClinic.clinic_id });
        const keys = reports[0];

        delete keys.member_id;
        delete keys.clinic_id;
        delete keys.clinic_organisation_id;
        delete keys.qualification_id;

        exportReportsToCSV(reports, intl, Object.keys(keys).map((column) => ({ key: column })));
    }

    return (
        <Card body className='card-shadow'>
            <div className="border-bottom pb-1 mb-3 d-flex">
                <div className="card-title font-bold h4 mb-0">
                    <Translate id='account.members.clinicsManagement.attendees' />
                </div>
            </div>
            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(sProps) => (
                    <SidePanelAttendees
                        currentClinic={selectedClinic}
                        canEdit={canEdit}
                        exportAttendees={exportAttendees}
                        {...sProps}
                    />
                )}
                dataIndex='clinic_attendee_id'
                table={(panelProps) => (
                    <SpordleTableProvider
                        id='accountMemberAttendees'
                        ref={panelProps.spordleTableRef}
                        key={selectedClinic.clinic_id}
                        tableHover bordered striped
                        clickable
                        pagination={10}
                        desktopWhen='md'
                        dataIndex='clinic_attendee_id'
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        loadData={(from, _, spordleTable) => {
                            switch (from){
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return Promise.resolve(selectedClinic.attendees || [])
                                default:
                                    break;
                            }
                        }}
                        searchKeys={[
                            { name: 'member.unique_identifier', weight: 2 },
                            'member.last_name',
                            'member.first_name',
                        ]}
                        columns={[
                            {
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                                mobile: false,
                            },
                            {
                                label: <span className="d-md-inline d-none"><Translate id='form.fields.name' /></span>,
                                key: "name",
                                className: 'text-left',
                                dataClassName: 'text-left',
                                sortable: true,
                                sortKey: 'member.first_name',
                            },
                            {
                                label: <Translate id='account.members.gir.addModal.label.org' />,
                                key: "organisation",
                                className: 'text-left',
                                dataClassName: 'text-left',
                                sortable: true,
                                sortKey: 'member.organisation.organisation_name',
                            },
                            {
                                label: <Translate id='form.fields.dateOfBirth' />,
                                key: "birthdate",
                                className: 'text-left',
                                dataClassName: 'text-left',
                                sortable: true,
                                sortKey: 'member.birthdate',
                            },
                            {
                                label: <Translate id='account.members.clinicsManagement.attendees.label.paid' />,
                                key: "paid",
                                className: 'text-center td-shrink',
                                dataClassName: 'text-center td-shrink',
                                sortable: true,
                            },
                            {
                                label: <Translate id='account.members.clinicsManagement.attendees.label.attended' />,
                                key: "attended",
                                className: 'text-center td-shrink',
                                dataClassName: 'text-center td-shrink',
                                sortable: true,
                            },
                            {
                                label: <Translate id='account.members.clinicsManagement.attendees.label.passed' />,
                                key: "passed",
                                className: 'text-center td-shrink',
                                dataClassName: 'text-center td-shrink',
                                sortable: true,
                            },
                        ]}
                        onColumnClick={(e, attendee, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(attendee);
                                    }else{
                                        panelProps.onSingleSelectChange(attendee);
                                    }
                                    break;
                            }
                        }}
                        rowIsHighlighted={(attendee) => !!attendee.checked}
                        renderRow={(columnKey, attendee) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label
                                            className='my-auto'
                                            htmlFor={'checkbox-' + attendee.clinic_attendee_id}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <input
                                                onChange={() => panelProps.onMultiSelectChange(attendee)}
                                                id={'checkbox-' + attendee.clinic_attendee_id}
                                                type='checkbox'
                                                checked={!!attendee.checked}
                                            />
                                            <span className='table-checkbox' />
                                        </label>
                                    )
                                case 'name':
                                    return (
                                        <>
                                            <div>
                                                <div className="font-medium">{attendee.member.first_name + ' ' + attendee.member.last_name}</div>
                                                <div className="small">#{attendee.member.unique_identifier}</div>
                                            </div>
                                        </>
                                    );
                                case 'organisation':
                                    return (
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={attendee.member?.organisation?.organisation_name}
                                            i18n={attendee.member?.organisation?.i18n}
                                        />
                                    )
                                case 'birthdate':
                                    return (
                                        <>
                                            {(attendee.member.birthdate || attendee.member.age) ?
                                                <>
                                                    {attendee.member.birthdate && <div><DateFormat value={attendee.member.birthdate} utc /></div>}
                                                    {attendee.member.age && <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: attendee.member.age }} /></div>}
                                                </>
                                                :
                                                '-'
                                            }
                                        </>
                                    );
                                case 'paid':
                                    if(attendeeHasPaid(attendee)){
                                        return <i className="text-primary mdi mdi-check" />
                                    }
                                    return <span>-</span>
                                case 'attended':
                                    if(attendeeHasAttended(attendee)){
                                        return <i className="text-primary mdi mdi-check" />
                                    }
                                    return <span>-</span>
                                case 'passed':
                                    if(attendeeHasPassed(attendee)){
                                        return <i className="text-primary mdi mdi-check" />
                                    }
                                    return <span>-</span>

                                default:
                                    break;
                            }
                        }}
                        emptyLayout={<EmptyLayout hideArrow hideMsg />}
                    >
                        {(spordleTable) => (
                            <>
                                <div className='align-items-center d-flex flex-wrap flex-md-nowrap'>
                                    <UncontrolledDropdown className="mb-0 order-1 order-md-0 mb-3">
                                        <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                            <div className='align-self-center custom-checkbox custom-control'>
                                                <input
                                                    id='topCheckID'
                                                    type='checkbox'
                                                    className='custom-control-input d-none'
                                                    checked={panelProps.topChecked}
                                                    onChange={panelProps.onTopCheckChange}
                                                />
                                                <label className='custom-control-label' htmlFor='topCheckID' />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={panelProps.checkAll}>
                                                <Translate id='misc.all' />
                                            </DropdownItem>
                                            <DropdownItem onClick={panelProps.uncheckAll}>
                                                <Translate id='misc.none' />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="flex-grow-1 flex-sm-grow-0 order-0 w-100 w-md-auto mb-3">
                                        <SpordleTableProvider.SearchInput />
                                    </div>
                                    <div className="ml-auto mr-2 order-1 order-md-0 mb-3">
                                        <TablePagination paginationArray={[ 5, 25, 50, 100 ]} />
                                    </div>
                                    <Button
                                        className="order-1 order-md-0 mb-3"
                                        color='primary'
                                        outline
                                        onClick={() => handleExcelExport({
                                            organizationId: selectedClinic.organisation.organisation_id,
                                            genericLocale: getGenericLocale(),
                                            totalCount: selectedClinic.attendees?.length,
                                            reportsContext: reportsContext,
                                            reportType: 'clinic_attendee',
                                            identity_id: userData.Username,
                                            filters: {
                                                clinic_id: selectedClinic.clinic_id,
                                                organisation_id: selectedClinic.organisation.organisation_id, // we need to keep the clinic org here for the report data
                                                period_id: selectedClinic.period.period_id,
                                            },
                                        })}
                                        disabled={selectedClinic.attendees < 1}
                                        type="button"
                                    >
                                        <i className='fas fa-file-excel mr-1' />
                                        <Translate id='misc.excel' />
                                    </Button>
                                    <SpordleTableProvider.Refresh className="ml-2 order-1 order-md-0 mb-3" />
                                </div>
                                {spordleTable.isMobile() ?
                                    <SpordleTableMobileView selectedClinic={selectedClinic} panelProps={panelProps} spordleTable={spordleTable} />
                                    :
                                    <SpordleTableView />
                                }
                            </>
                        )}
                    </SpordleTableProvider>
                )}
            />
        </Card>
    );
}

const SpordleTableMobileView = ({ spordleTable, panelProps }) => {
    const attendees = spordleTable.getDisplayedData();
    const [ filters, setFilters ] = useState([]);

    const handleCardClick = (e, attendee) => {
        e.stopPropagation();
        e.preventDefault();
        panelProps.onMultiSelectChange(attendee);
    }

    const handleRemoveFilter = (filterKey) => {
        setFilters((prev) => prev.filter((filter) => filter.key !== filterKey));
    }

    const handleAddFilter = (data) => {
        if(!filters.some((filter) => filter.key === data.key)) setFilters((prev) => [ ...prev, data ]);
    }

    const handleFilter = (attendee) => {
        return filters.length <= 0 || filters.every((filter) => (
            attendee[filter.key] === filter.value
        ))
    }

    const checkedIcon = "mdi-check-circle text-success";
    const uncheckedIcon = "mdi-checkbox-blank-circle-outline text-secondary";
    const filteredAttendees = attendees.filter(handleFilter)

    return (
        attendees.length > 0 ?
            <>
                <SpordleTableMobileFilters
                    filters={filters}
                    removeFilter={handleRemoveFilter}
                    addFilter={handleAddFilter}
                />
                {
                    filteredAttendees.length <= 0 ?
                        <EmptyLayout hideArrow hideMsg />
                        :
                        filteredAttendees
                            .map((attendee, index) => (
                                <Card
                                    id={spordleTable.generateId('table-body', 'row', attendee.clinic_attendee_id)}
                                    onClick={(e) => handleCardClick(e, attendee)}
                                    className={stringBuilder(
                                        "card-shadow mb-2 clickable transition",
                                        { "bg-light-inverse": index % 2 == 0 && !attendee.checked },
                                        { "bg-primary-light": attendee.checked },
                                    )}
                                    key={attendee.clinic_attendee_id}
                                >
                                    <CardBody className="d-flex align-items-center">
                                        <div className="custom-checkbox custom-control mr-2">
                                            <input
                                                onChange={() => panelProps.onMultiSelectChange(attendee)}
                                                name={'checkbox-' + attendee.clinic_attendee_id}
                                                id={spordleTable.generateId('checkbox', attendee.clinic_attendee_id)}
                                                type='checkbox'
                                                checked={!!attendee.checked}
                                                className="custom-control-input d-none"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={'checkbox-' + attendee.clinic_attendee_id}
                                            />
                                        </div>
                                        <div>
                                            <div className="font-medium text-dark h5 mb-1">{attendee.member.first_name + ' ' + attendee.member.last_name}</div>
                                            <div className="mb-1">#{attendee.member.unique_identifier}</div>
                                            <span className="small d-inline-block mr-2">
                                                <i className={`mdi ${attendeeHasPaid(attendee) ? checkedIcon : uncheckedIcon }`} /> <Translate id='account.members.clinicsManagement.attendees.label.paid' />
                                            </span>
                                            <span className="small d-inline-block mr-2">
                                                <i className={`mdi ${attendeeHasAttended(attendee) ? checkedIcon : uncheckedIcon }`} /> <Translate id='account.members.clinicsManagement.attendees.label.attended' />
                                            </span>
                                            <span className="small d-inline-block mr-2">
                                                <i className={`mdi ${attendeeHasPassed(attendee) ? checkedIcon : uncheckedIcon }`} /> <Translate id='account.members.clinicsManagement.attendees.label.passed' />
                                            </span>
                                        </div>
                                        <div className="ml-auto">
                                            <i className="text-muted font-20 mdi mdi-chevron-right" />
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                }
            </>
            :
            <EmptyLayout hideArrow hideMsg />
    )
}

// TODO: Cleanup when have the time to
const SpordleTableMobileFilters = ({ filters, addFilter, removeFilter }) => {
    return (
        <>
            <span className="d-inline-block">
                <UncontrolledDropdown>
                    <DropdownToggle color="link" type="button">
                        {/* <Button > */}
                        <i className="mdi mdi-filter-variant" /> Filters
                        {/* </Button> */}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => addFilter({ key: 'paid', value: '1', label: <Translate id='account.members.clinicsManagement.attendees.label.paid' /> })}>
                            <Translate id='account.members.clinicsManagement.attendees.label.paid' />
                        </DropdownItem>
                        <DropdownItem onClick={() => addFilter({ key: 'attended', value: '1', label: <Translate id='account.members.clinicsManagement.attendees.label.attended' /> })}>
                            <Translate id='account.members.clinicsManagement.attendees.label.attended' />
                        </DropdownItem>
                        <DropdownItem onClick={() => addFilter({ key: 'passed', value: '1', label: <Translate id='account.members.clinicsManagement.attendees.label.passed' /> })}>
                            <Translate id='account.members.clinicsManagement.attendees.label.passed' />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </span>
            {filters.length > 0 &&
                <ul className="list-unstyled list-inline">
                    {filters.map((filter) => (
                        <Fade tag="li" className="list-inline-item" onClick={() => removeFilter(filter.key)} key={filter.key} in>
                            <Badge color="light" className="shadow-sm mb-0 font-12 border clickable">
                                {filter.label}
                                <i className="ml-2 mdi mdi-close" />
                            </Badge>
                        </Fade>
                    ))}
                </ul>
            }
        </>
    )
}

export default AccountMemberAttendeesManagement;