import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    DropdownItem, DropdownMenu, DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
// contexts
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { AccountsContext, AuthContext } from '../../../contexts/contexts';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import TransactionsSidepanelCredit from './components/Sidepanel/TransactionsSidepanelCredit';
import TransactionsSidepanel from './components/Sidepanel/TransationsSidepanel';
import TransationsSidepanelClinic from './components/Sidepanel/TransationsSidepanelClinic';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import UnsignedWaiversIcon from './components/UnsignedWaiversIcon';
import UATWarning from '../../../components/UATWarning';
import { I18nContext } from '../../../contexts/I18nContext';

const Transactions = () => {
    const accountsContext = useContext(AccountsContext);
    const authContext = useContext(AuthContext);
    const dataList = useRef([]);
    const history = useHistory();
    const tableRef = useRef();
    const panelRef = useRef();

    const filterRegistrations = (item) => item.registration_fee != null;
    const filterOtherItems = (item) => item.other_fee != null;
    const getClinic = (item) => item.clinic != null;
    const filterClinicFees = (item) => item.clinic_fee != null;

    const defaultSearchInvoiceNumber = queryString.parse(window.location.search).search

    // logic for when the user refreshes from the purchases page
    useEffect(() => {
        const invoiceNumber = queryString.parse(window.location.search).search
        if(invoiceNumber && tableRef.current && tableRef.current.state.loadingState === 'success'){ // wait until the table has loaded the invoices
            const invoice = tableRef.current.getData().find((invoice) => invoice.invoice_number === invoiceNumber)

            if(invoice){
                tableRef.current.setInputValue(invoiceNumber)
                panelRef.current.onSingleSelectChange(invoice)
                // no need to remove the invoice number from the URL
                // we wait for the sidepanel to catch it and then the sidepanel will remove it
            }else{
                removeInvoiceNumberFromURL();
            }
        }
    }, [ queryString.parse(window.location.search).search, tableRef.current?.state.loadingState ])

    // manually sets the checked index on the row we want to select programmatically
    const getDataWithChecked = (data, searchValue) => {
        return data.map((invoice) => ({
            ...invoice,
            checked: invoice.invoice_number === searchValue,
        }))
    }

    // remove invoice number from URL
    const removeInvoiceNumberFromURL = () => {
        const parsedUrl = queryString.parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
        delete parsedUrl.query.search;
        delete parsedUrl.query.tab;
        history.replace(queryString.stringifyUrl(parsedUrl))
    }

    const formatData = ({ invoices, credits }) => {
        let _invoices = []
        if(invoices){
            // invoices
            _invoices = invoices.filter((invoice) => invoice.status !== "VOID" && invoice.type !== 'WAIVER').map((invoice) => { // Don't show VOID transactions in Account
                const paidAmount = invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.paid_amount), 0) + getCreditAmount(invoice);

                return ({
                    ...invoice,
                    registrations: invoice.invoice_items.filter(filterRegistrations),
                    otherItems: invoice.invoice_items.filter(filterOtherItems),
                    clinic: invoice.invoice_items.find(getClinic),
                    clinicFees: invoice.invoice_items.filter(filterClinicFees),
                    waivers: invoice.invoice_items.reduce((waivers, item) => {
                        item.waivers.forEach((waiver) => {
                            waivers.push({
                                ...waiver,
                                invoiceItem: item,
                                id: `${waiver.waiver.waiver_id}-${item.member.member_id}`,
                            })
                        })
                        return waivers
                    }, []),

                    creditAmount: getCreditAmount(invoice) + getPaymentReceptionCreditAmount(invoice),
                    total: parseInt(invoice.total_amount),
                    paid: paidAmount,
                    due: invoice.total_amount - paidAmount,
                })
            })
        }

        // credit
        // credits logic:
        // total is the used amount of the credit (amount - balance * -1)
        // paid is never used (the paid credit is added on the invoice's paid cell)
        // due is the remaining (unused) of the credit (balance * -1)
        // total and due are negative so they can subtract a certain amount off the total at the bottom
        _invoices.pushArray(credits.map((credit) => ({
            ...credit,
            isCredit: true,
            invoice_number: credit.member_credit_id, // to simulate an invoice && be valid for table using invoice_number ad data index
            invoiceNumber: '', // added for search, user will probably search for an invoice number, we want to hide all credits when he does
            invoice_date: credit.created_at, // for sort
            total: (parseInt(credit.amount) - parseInt(credit.balance)) * -1,
            paid: 0, // no paid, see logic
            due: parseInt(credit.balance) * -1,
        })))

        dataList.current = _invoices;
        return _invoices;
    }

    // const getDueAmount = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status == "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0)
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the due amount
    //     // we get the total amount and subtract the total of every payment_reception
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         const totalReceived = invoice.payment_receptions.reduce((total, pM) => {
    //             return total += parseInt(pM.amount)
    //         }, 0)

    //         // there are 2 types of credits
    //         // applied credits (from the credits array), and the credits from payment_receptions
    //         // the applied credits are never subtracted from the dueAmount when there are payment_receptions
    //         // they are subtracted from the invoice item payments, so we only want to do that here
    //         let dueTotal = parseInt(invoice.total_amount) - totalReceived - getCreditAmount(invoice)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             dueTotal -= getInvoiceItemsPaymentsTotal();
    //         }

    //         return dueTotal;
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         // Sum of everything that is not payed
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status !== "CANCELED" && payment.status !== "CANCELLED" && payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0);
    //     }
    //     // return invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt((payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0), 0)), 0)
    // }

    // const getPaidAmount = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return parseInt(invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0)
    //             }, 0))
    //         }, 0))
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the paid amount
    //     // we get the total of all the payment_receptions
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         let totalReceptions = invoice.payment_receptions.reduce((total, pM) => {
    //             if(!pM.member_credit_id){
    //                 total += parseInt(pM.amount)
    //             }
    //             return total
    //         }, 0)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             totalReceptions += getInvoiceItemsPaymentsTotal();
    //         }

    //         return totalReceptions
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         return getInvoiceItemsPaymentsTotal();
    //         // removed _creditAmount from paid, we don't want to consider credits in the paid column's total
    //     }
    //     // parseInt(invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0), 0)), 0))
    // }

    // const getTotalCreditAmount = (invoice) => {
    //     return getCreditAmount(invoice) + getPaymentReceptionCreditAmount(invoice);
    // }

    const getCreditAmount = (invoice) => {
        return invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0)
    }

    const getPaymentReceptionCreditAmount = (invoice) => {
        let paymentReceptionsCreditsTotal = 0;

        if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
            paymentReceptionsCreditsTotal += invoice.payment_receptions.reduce((total, pM) => {
                if(pM.member_credit_id){
                    total += parseInt(pM.amount)
                }
                return total
            }, 0)
        }

        return paymentReceptionsCreditsTotal
    }

    return (
        <>
            <ReactTooltip id="unsignedWaiversToolTip" />
            <header className="p-3 mb-0 card card-border card-shadow">
                <div className="h3 font-medium text-primary mb-0"><Translate id='sidebar.purchases' /></div>
            </header>
            <UATWarning />
            <div className="page-content container-fluid">
                <Card className="card-shadow">
                    <CardBody>
                        <SpordlePanelTable
                            allowOutsideClick
                            ref={panelRef}
                            dataIndex='invoice_number'
                            sidePanel={(props) => {
                                if(props.selectedRows[0]?.isCredit){
                                    return <TransactionsSidepanelCredit tableRef={tableRef.current} removeInvoiceNumberFromURL={removeInvoiceNumberFromURL} {...props} />
                                }else if(props.selectedRows[0]?.type === 'CLINIC'){
                                    return <TransationsSidepanelClinic tableRef={tableRef.current} removeInvoiceNumberFromURL={removeInvoiceNumberFromURL} {...props} />
                                }
                                return <TransactionsSidepanel tableRef={tableRef.current} removeInvoiceNumberFromURL={removeInvoiceNumberFromURL} {...props} />
                            }}
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='transactions'
                                        tableHover bordered striped
                                        clickable hasFooter
                                        ref={(r) => {
                                            tableRef.current = r;
                                            panelProps.spordleTableRef(r);
                                        }}
                                        dataIndex='invoice_number'
                                        desktopWhen
                                        pagination={20}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        defaultSorting={defaultSearchInvoiceNumber ? undefined : '-invoice_date'}
                                        searchKeys={[
                                            'invoice_number',
                                            'invoice_date',
                                            'identity.name',
                                            'identity.family_name',
                                        ]}
                                        emptyLayout={<EmptyLayout title='account.transactions.memberProfileTransactions.noResults' hideArrow hideMsg translateTitle />}
                                        defaultSearchValue={defaultSearchInvoiceNumber}
                                        loadData={(from, extraData, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    return Promise.all([
                                                        accountsContext.getAccountInvoices(authContext.userData.Username),
                                                        accountsContext.getAccountMemberCredits(authContext.userData.Username),
                                                    ])
                                                        .then((promises) => formatData({ invoices: promises[0], credits: promises[1] }))
                                                        .then((data) => {
                                                            const invoiceNumber = spordleTable.getInputValue()
                                                            if(invoiceNumber){
                                                                const item = data.find((invoice) => invoice.invoice_number === invoiceNumber);
                                                                if(item){
                                                                    panelProps.onSingleSelectChange(data.find((invoice) => invoice.invoice_number === invoiceNumber));
                                                                    // no need to remove the invoice number from the URL
                                                                    // we wait for the sidepanel to catch it and then the sidepanel will remove it
                                                                }else{
                                                                    removeInvoiceNumberFromURL();
                                                                }
                                                            }
                                                            return getDataWithChecked(data, invoiceNumber)
                                                        })
                                                case 'SEARCH':
                                                    return Promise.resolve(getDataWithChecked(spordleTable.getData(), extraData.searchValue))
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.tableView.header.transaction' />,
                                                key: 'invoice_date',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.tableView.header.paymentMethod' />,
                                                key: 'paymentMethod',
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.registrationType' />,
                                                key: 'type',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.tableView.header.items' />,
                                                key: 'items',
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.total' />,
                                                key: 'total',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.tableView.header.paid' />,
                                                key: 'paid',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='account.transactions.memberProfileTransactions.tableView.header.due' />,
                                                key: 'due',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                        ]}
                                        renderRow={(columnKey, invoice) => {
                                            switch (columnKey){
                                                case 'invoice_date':
                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div>
                                                                <div className="small"><DateFormat value={invoice.invoice_date} utc format='YYYY-MM-DD H:mm' /></div>
                                                                {!invoice.isCredit ?
                                                                    <Link className="text-link" to={`/purchases/invoice/${invoice.invoice_number}`}>#{invoice.invoice_number}</Link>
                                                                    :
                                                                    <DisplayI18n field='name' defaultValue={invoice.credit_type.name} i18n={invoice.credit_type.i18n} />
                                                                }
                                                            </div>
                                                            {!invoice.isCredit &&
                                                                <div className='ml-2'>
                                                                    <UnsignedWaiversIcon waivers={invoice.waivers} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                case 'paymentMethod':
                                                    if(!invoice.isCredit){
                                                        return (
                                                            <DisplayI18n field='name' defaultValue={invoice.invoice_payment_method?.name} i18n={invoice.invoice_payment_method?.i18n} />
                                                        )
                                                    }
                                                    return '-'

                                                case 'type':
                                                    return (
                                                        <>
                                                            <div>
                                                                {invoice.type ?
                                                                    <Translate id={`account.transactions.memberProfileTransactions.registrationType.${invoice.type}`} />
                                                                    :
                                                                    '-'
                                                                }
                                                            </div>
                                                            {invoice.organisation &&
                                                                <div className='small text-muted'>
                                                                    <DisplayI18n field='name' defaultValue={invoice.organisation.organisation_name} i18n={invoice.organisation.i18n} />
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                case 'items':
                                                    if(!invoice.isCredit){
                                                        if(invoice.type === 'CLINIC'){
                                                            const registration = invoice.invoice_items.find((invoiceItem) => !!invoiceItem.clinic);
                                                            return (
                                                                registration ? <DisplayI18n field='name' defaultValue={registration.clinic.name} i18n={registration.clinic.i18n} /> : '-' //<ClinicCategoryBuilder qualification={registration.clinic.qualification}/>
                                                            )
                                                        }

                                                        return (
                                                            <>
                                                                {(invoice.registrations && invoice.registrations.length > 0) &&
                                                                        <div>{invoice.registrations.length} <Translate id='account.transactions.memberProfileTransactions.registrations' /></div>
                                                                }
                                                                {(invoice.otherItems && invoice.otherItems.length > 0) &&
                                                                        <div>{invoice.otherItems.length} <Translate id='account.transactions.memberProfileTransactions.items' /></div>
                                                                }
                                                            </>
                                                        )

                                                    }
                                                    return (
                                                        <>
                                                            <Translate id='account.transactions.memberProfileTransactions.credit' />
                                                            {invoice.active === '0' &&
                                                                    <div className='text-warning small'><i className='mdi mdi-alert-outline' /> <Translate id='misc.inactive' /></div>
                                                            }
                                                        </>
                                                    )

                                                case 'total':
                                                    if(invoice.total !== 0){
                                                        return <div className={stringBuilder("text-right font-medium", { 'text-success': invoice.isCredit, 'text-muted': invoice.active === '0' })}><CurrencyFormat value={invoice.total / 100} /></div>
                                                    }
                                                    return '-'

                                                case 'paid':
                                                    if(invoice.paid > 0 || !!invoice.creditAmount){
                                                        return (
                                                            <div className={stringBuilder("text-right font-medium", { 'text-muted': invoice.active === '0' })}>
                                                                <CurrencyFormat value={parseInt(invoice.paid) / 100} />
                                                                {!!invoice.creditAmount &&
                                                                    <div className="d-block text-success font-medium small">(<CurrencyFormat value={(parseInt(invoice.creditAmount)) / 100} />)</div>
                                                                }
                                                                {invoice.total_refunded_amount > 0 &&
                                                                    <div className='text-danger font-medium small'>(<Translate id='account.transactions.memberProfileTransactions.tableView.header.refunded' />: {<CurrencyFormat value={invoice.total_refunded_amount / 100} />})</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                case 'due':
                                                    // because it can be < 0 in case of a credit
                                                    if(invoice.due !== 0){
                                                        return (
                                                            <div className='text-right'>
                                                                <span className={stringBuilder("font-medium", { 'text-success': invoice.isCredit, 'text-muted': invoice.active === '0' })}><CurrencyFormat value={invoice.due / 100} /></span>
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                default:
                                                    break;
                                            }
                                        }}
                                        renderFooter={(columnKey, columnTotal) => {
                                            switch (columnKey){
                                                case 'total':
                                                case 'paid':
                                                case 'due':
                                                    return <div className='text-right font-medium'><CurrencyFormat value={columnTotal / 100} /></div>
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, data) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(data);
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(data) => data.checked}
                                    >
                                        <div className='mb-2'>
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                <div className="d-flex flex-grow-1 align-items-center">
                                                    <SpordleTableProvider.SearchInput />
                                                </div>
                                                <div className='d-flex ml-auto text-right'>
                                                    <SpordleTableProvider.Refresh className='mr-2' />
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='primary' outline caret>
                                                            <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <ToCSV />
                                                            <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                            <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                            <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </>
    );
}

const ToCSV = () => {
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const { getGenericLocale } = useContext(I18nContext)

    const formatData = () => {
        return spordleTable.getData().map((invoice) => ({
            invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD H:mm'),
            invoice_number: !invoice.isCredit ? invoice.invoice_number : 'N/A',
            invoice_payment_method: displayI18n('name', invoice.invoice_payment_method?.i18n, invoice.invoice_payment_method?.name, getGenericLocale()),
            total: (invoice.total / 100).toFixed(2),
            paid: (invoice.paid / 100).toFixed(2),
            due: (invoice.due / 100).toFixed(2),
        }));
    }

    return (
        <CSVLink
            className="dropdown-item" separator=','
            filename={`Spordle_MyAccount-Transactions-${moment().format('YYYY-MM-DD H:mm')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.csv.invoiceDate' }),
                    key: 'invoice_date',
                },
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.csv.invoiceNumber' }),
                    key: 'invoice_number',
                },
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.tableView.header.paymentMethod' }),
                    key: 'invoice_payment_method',
                },
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.csv.total' }),
                    key: 'total',
                },
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.csv.paid' }),
                    key: 'paid',
                },
                {
                    label: intl.formatMessage({ id: 'account.transactions.memberProfileTransactions.csv.due' }),
                    key: 'due',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default Transactions;