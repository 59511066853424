import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import UserImg from "../../../../components/UserImg";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { success } from '@spordle/toasts';

const ImgDeleteConfirmModal = ({ toggle, member, onConfirm, isOpen }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    const handleConfirm = () => {
        setIsLoading(true);

        onConfirm()
            .then(() => {
                setIsLoading(false);
                success();
                toggle();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setIsLoading(false);
                }
            })
    }

    return (
        <AnalyticsModal analyticsName='ImgDeleteConfirmModal' isOpen={isOpen}>
            <OverlayLoader isLoading={isLoading}>
                <ModalHeader toggle={toggle}><Translate id='account.members.img.deleteModal.title' /></ModalHeader>
                { member &&
                    <ModalBody className="text-center">
                        <div className="d-flex justify-content-center mb-3">
                            <UserImg
                                alt={member.first_name + ' ' + member.last_name}
                                abbr={member.first_name?.charAt(0) + member.last_name?.charAt(0) || 'HC'}
                                width={150}
                                filePos={member.attachment?.file_position}
                                src={member.attachment?.full_path}
                            />
                        </div>
                        <p>
                            <Translate id='account.members.img.deleteModal.msg' values={{ name: member.first_name + ' ' + member.last_name }} />
                        </p>
                    </ModalBody>
                }
                <ModalFooter>
                    <Button disabled={isLoading} color="primary" type="button" onClick={handleConfirm}>
                        <Translate id="misc.confirm" />
                    </Button>
                    <Button disabled={isLoading} color="primary" outline type="button" onClick={toggle}>
                        <Translate id="misc.cancel" />
                    </Button>
                </ModalFooter>
            </OverlayLoader>
        </AnalyticsModal>

    )
}

export default ImgDeleteConfirmModal;