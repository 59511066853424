import Translate from '@spordle/intl-elements';
import {
    ModalHeader,
    ModalBody
} from "reactstrap";
import SubmitTicketTypeData from './submitTicket/components/SubmitTicketTypeData';
import { displayI18n, DisplayI18n } from '../../helpers/i18nHelper';
import { I18nContext } from '../../contexts/I18nContext';
import AnalyticsModal from '../../analytics/AnalyticsModal';
import { useContext, useState } from 'react';

/**
* @param {Function} toggleSubmitTicket(type=null)
*/
const UserGuide = ({ isOpen, guide, toggle, toggleSubmitTicket, hasSupportRequests }) => {
    const i18n = useContext(I18nContext);

    // used as a checkup everytime we close modal to see if it has ben closed by the "submit a support" button
    const [ openSubmitTicketOnClose, setOpenSubmitTicketOnClose ] = useState(false)

    // called when "submit a suport" btn is clicked
    const openSubmitTicket = () => {
        setOpenSubmitTicketOnClose(true)
        toggle();
    }

    // opens the submit ticket after the modal was closed if the button "submit a request" has ben clicked
    const handleClose = () => {
        if(openSubmitTicketOnClose){
            toggleSubmitTicket(SubmitTicketTypeData.bug)
        }

        // reset
        setOpenSubmitTicketOnClose(false)
    }

    return (
        <AnalyticsModal
            analyticsName='UserGuide'
            isOpen={isOpen}
            toggle={toggle}
            onOpened={() => setOpenSubmitTicketOnClose(false)}
            onClosed={handleClose}
        >
            <ModalHeader tag='div' toggle={toggle}>
                {/* this should be only the name of the module, ex: "clinic items" instead of "catalog - clinic items" */}
                <Translate id='helpCenter.userGuide.title' /> <DisplayI18n field="title" i18n={guide?.i18n} defaultValue={guide?.title} />
            </ModalHeader>
            <ModalBody>
                { guide?.video_id &&
                    <div className='w-100'>
                        <iframe
                            className='w-100'
                            width='640'
                            height='360'
                            src={`https://www.youtube.com/embed/${displayI18n('video_id', guide.i18n, guide.video_id, i18n.getGenericLocale())}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="YouTube video player"
                        />
                    </div>
                }
                <div className="mb-2" dangerouslySetInnerHTML={{ __html: displayI18n('content', guide?.i18n, guide?.content, i18n.getGenericLocale()) }} />
                {hasSupportRequests && <p><Translate id='helpCenter.userGuide.didNotFind' /> <button type='button' className="reset-btn text-primary" onClick={openSubmitTicket}><Translate id='helpCenter.userGuide.submitTicket' /></button></p>}
            </ModalBody>
        </AnalyticsModal>
    );
}

export default UserGuide;