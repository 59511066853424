import AnalyticsModal from "../../../../../../analytics/AnalyticsModal"
import OverlayLoader from "../../../../../../components/loading/OverlayLoader"
import PaysafeView from "./components/PaysafeView"
import SportPayView from "./components/SportPayView"

/**
 *
 * @param {Object} props
 * @returns {React.ReactNode}
*/
const ManagePaymentModal = (props) => {
    return (
        <AnalyticsModal size="lg" analyticsName="ManagePaymentModal" isOpen={props.isOpen}>
            <ManagePaymentModalInner {...props} />
        </AnalyticsModal>
    )
}

/**
 * @param {Object} props
 * @param {'qe'|'paysafe'} props.transactionType
 * @returns {React.ReactNode}
 */
const ManagePaymentModalInner = ({ transactionType, toggle, invoiceItem, cardToken, updatePaymentCard, paymentMode, updateInvoices }) => {
    return (
        <OverlayLoader isLoading={false}>
            {transactionType === "qe" ?
                <SportPayView toggle={toggle} invoiceItem={invoiceItem} paymentMode={paymentMode} updateInvoices={updateInvoices} />
                :
                <PaysafeView toggle={toggle} invoiceItem={invoiceItem} cardToken={cardToken} updatePaymentCard={updatePaymentCard} paymentMode={paymentMode} updateInvoices={updateInvoices} />
            }
        </OverlayLoader>
    )
}

export default ManagePaymentModal;