/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {stringBuilder}from'@spordle/helpers';import {useField}from'formik';import PropTypes from'prop-types';import {useIntl}from'react-intl';import NumberFormat from'react-number-format';import {Label,FormText}from'reactstrap';import FormikError from'./FormikError.js';/**
 * @member FormikCurrencyInput
 * @description
 * FormikCurrencyInput will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikCurrencyInput id='amount' name='amount' className="mb-1" manualError allowNegative={false} />
 *
 * @prop {string} name Used to make the FormikCurrencyInput work with validation
 * @prop {string|React.ReactNode} [label] The lang id to be translated OR JSX
 * @prop {string|React.ReactNode} [helper] The lang id to be translated OR JSX
 * @prop {string} [placeholder] The lang id to be translated
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translatePlaceholder=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @prop {string} [currencySign='$'] The currency sign to display
 * @returns {React.ReactNode} Returns an input text that will work with Formik that accepts numbers only
 *
 * @see https://github.com/s-yadav/react-number-format
 * @copyright Spordle
 */
const FormikCurrencyInput = ({ label, helper, translatePlaceholder, translateHelper, translateLabel, manualError, currencySign = '$', ...props }) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const [{ onChange, ...field }, meta, helpers] = useField({ ...props, type: 'text' });
    const intl = useIntl();
    const currentLocale = intl.locale.split(/(-|_)/)[0];
    if (translatePlaceholder && props.placeholder && typeof props.placeholder === 'string')
        props.placeholder = intl.formatMessage({ id: props.placeholder });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx(NumberFormat, { ...field, ...props, fixedDecimalScale: props.fixedDecimalScale ?? true, decimalScale: props.decimalScale ?? 2, thousandSeparator: props.thousandSeparator || ' ', decimalSeparator: props.decimalSeparator || currentLocale === 'fr' ? ',' : '.', suffix: props.suffix || (currentLocale === 'fr' ? ' ' + currencySign : undefined), prefix: props.prefix || (currentLocale !== 'fr' ? currencySign : undefined), className: stringBuilder('form-control', props.className, { 'is-invalid': meta.touched && meta.error }), onValueChange: (value, sourceInfo) => {
                    helpers.setValue(value.floatValue == null ? '' : value.floatValue);
                    props.onValueChange?.(value, sourceInfo);
                }, allowedDecimalSeparators: ['.', ','] }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
};
FormikCurrencyInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    translatePlaceholder: PropTypes.bool,
    manualError: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
FormikCurrencyInput.defaultProps = {
    translateHelper: true,
    translateLabel: true,
    translatePlaceholder: true,
};
FormikCurrencyInput.displayName = 'FormikCurrencyInput';
const FormikCurrencyInput$1 = FormikCurrencyInput;export{FormikCurrencyInput$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/