import Translate, { DateFormat } from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import { Card, CardBody } from "reactstrap";
import LerpAmount from "./LerpAmount";
import RetryPaymentBtn from "./RetryPaymentBtn";
import UpcomingPaymentChangeCard from "./UpcomingPaymentChangeCard";

/**
 * @description Showcases next due payment
 * @param {Object} props
 * @param {boolean} [props.isDeclined=false]
 * @returns {React.ReactNode}
 */
const UpcomingPayment = ({ isDeclined = false, invoiceItem, updatePaymentList }) => {
    const theme = isDeclined ? 'danger' : 'primary';

    return (
        invoiceItem ?
            <Card className={stringBuilder({ "bg-light-danger": isDeclined }, "card-shadow")}>
                <CardBody>
                    <small className="payments-upcoming-date d-flex justify-content-between"><DateFormat value={invoiceItem.due_date} utc /></small>
                    <div className="h4 font-medium">
                        { isDeclined ?
                            <span className="text-danger">
                                <i className="mdi mdi-alert mr-1" /><Translate id='account.paymentMethods.declinedPayment.title' />
                            </span>
                            :
                            <Translate id='account.paymentMethods.nextPayment.title' />
                        }
                    </div>
                    <div className={stringBuilder("payments-upcoming-price", { "text-danger": isDeclined })}>
                        <LerpAmount amount={invoiceItem.amount / 100} />
                    </div>
                    <p className="payments-upcoming-info"><invoiceItem.InvoiceItem /></p>
                    { isDeclined &&
                        <RetryPaymentBtn
                            onSuccess={updatePaymentList}
                            provider={invoiceItem.provider}
                            paymentId={invoiceItem.invoice_payment_id}
                            color={theme}
                        />
                    }
                    <UpcomingPaymentChangeCard theme={theme} invoiceItem={invoiceItem} />
                </CardBody>
            </Card>
            : null
    )
}

export default UpcomingPayment;