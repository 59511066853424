import { useContext, useEffect, useState } from "react";
import Translate from "@spordle/intl-elements";
import { DisplayI18n, displayI18n } from "../../../../helpers/i18nHelper";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Badge } from "reactstrap";
import Svg from "../../../../components/Svg";
import { useHistory } from "react-router-dom";
import { NotificationsContext } from "../../../../contexts/NotificationsContext";
import { I18nContext } from "../../../../contexts/I18nContext";
import { triggerDownload } from "../../../../components/uploader/uploadHelpers";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { fail } from '@spordle/toasts';
import isValidUUID from "../../../../helpers/isValidUUID";

const getNotificationIcon = (notif) => {
    switch (notif.notification_type.code){
        // Organisation
        case 'organisation-user-expired':
            return 'organisation'

        // Clinics
        case 'clinic-open':
        case 'clinic-closed':
        case 'clinic-full':
            return 'clinics';

        // Members
        case 'member-declined-payment':
        case 'member-crc-expired':
        case 'member-transfer':
            return 'members'

        // Online stores
        case 'online-store-open':
        case 'online-store-close':
            return 'clinics';

        // Registrations
        case 'registration-full':
            return 'catalog';

        // Finance
        case 'credit_pre_init':
        case 'installment-failed':
            return 'finance';

        case 'report_ready':
            return 'report_download'

        default:
            return 'communications';
    }
}

const Notification = ({ mutate, deleteNotif, readNotification, showPreview, notification, notifIsRead = false, isValidating, close }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { updateMarkAsRead } = useContext(NotificationsContext);
    const reportsContext = useContext(ReportsContext)
    const [ isRead, setIsRead ] = useState(notifIsRead);
    const history = useHistory();

    const notificationIcon = getNotificationIcon(notification)

    const handleDeleteNotif = (e) => {
        e.preventDefault();
        e.stopPropagation();

        deleteNotif(notification);
    }

    // Mark a single notification as read;
    function handleReadNotif(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        setIsRead(true);
        readNotification();

        updateMarkAsRead(notification.notification_id)
            .then(() => mutate())
            .catch(console.error);
    }

    const getNotifDate = (notif) => {
        const days = moment().diff(moment(notif.created_at), 'days')

        return (
            days > 0 ?
                <Translate id="misc.daysAgo" values={{ days: days }} />
                :
                <Translate id="misc.hoursAgo" values={{ hours: moment().diff(moment(notif.created_at), 'hours') }} />
        );
    }

    useEffect(() => {
        setIsRead(notifIsRead);
    }, [ notifIsRead ]);

    const urlParameters = JSON.parse(notification.url_parameter);

    return (
        <div
            className="list-group-item list-group-item-action notification-item is-new p-3"
            onClick={(e) => {

                if(notification.notification_type.code === 'report_ready'){
                    const downloadParams = JSON.parse(notification.url_parameter);

                    reportsContext.getReportDownload(downloadParams.params.request_report_id)
                        .then((reportDownload) => {
                            triggerDownload(reportDownload?.download_link);
                        }).catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                                console.error(e.message);
                            }
                        })
                }else if(notification.notification_type.code === 'communication'){
                    showPreview(notification);
                }

                if(e.target.nodeName !== "BUTTON"){
                    !isRead && handleReadNotif();
                    close();

                    // notification redirection logic, checks url_parameter in the notification object, allows for more types of notifications in the future with no modifications if they use the same parameters
                    if(urlParameters.params.member_id && urlParameters.params.invoice_number && isValidUUID(urlParameters.params.member_id)) // installment-failed, installment-cancelled, installment-new-date
                        history.push(`/payment`); // TODO: check if we can point to a specific payment
                    else if(urlParameters.params.member_id && urlParameters.params.invoice_number) // schedule-update
                        history.push(`/purchases/${urlParameters.params.invoice_number}`);
                }
            }}
        >
            <Badge color="primary" className="round d-flex flex-center flex-shrink-0">
                {notificationIcon == 'report_download'
                    ?
                    <i className='mdi mdi-download' style={{ fontSize: '24px' }} />
                    :
                    <Svg icon={notificationIcon} />
                }
            </Badge>
            <div className="pl-3 flex-grow-1">
                <div className="h5 font-bold mb-0">
                    <DisplayI18n
                        field='title'
                        defaultValue={notification.title}
                        i18n={notification.i18n}
                    />
                </div>
                {notification.notification_type.code === "credit_pre_init" ?
                    <p className="small mb-0 text-dark notification-description">
                        <Translate id='header.notifications.preinitInvoice.text' />
                    </p>
                    :
                    <p
                        className="small mb-0 text-dark notification-description"
                        dangerouslySetInnerHTML={{
                            __html: displayI18n('description', notification.i18n, notification.description, getGenericLocale()),
                        }}
                    />
                }
                <span className="text-muted small">{getNotifDate(notification)}</span>
            </div>
            <div className="align-self-start d-flex align-items-center">
                <button
                    type='button'
                    disabled={isValidating}
                    className="notification-delete mr-1"
                    data-for={`org-notification-${notification.notification_id}`}
                    data-tip="header.notifications.item.tip.clear"
                    onClick={handleDeleteNotif}
                >
                    <i className="mdi mdi-close" />
                </button>
                <button
                    type='button'
                    disabled={isValidating}
                    className={`notification-read ${isRead ? 'invisible' : ''}`}
                    data-for={`org-notification-${notification.notification_id}`}
                    data-tip="header.notifications.item.tip.read"
                    onClick={handleReadNotif}
                />
                <ReactTooltip
                    id={`org-notification-${notification.notification_id}`}
                    getContent={(tip) => tip && <Translate id={tip} />}
                    effect="solid"
                />
            </div>
        </div>
    )
}

export default Notification;
