import { Col, ListGroupItem, Row } from 'reactstrap';
import { stringBuilder } from "@spordle/helpers";
import Translate from '@spordle/intl-elements';

const ListGroupItemLayout = ({ isFirst, isLast, title, value, onAction, disableEdit }) => (
    <ListGroupItem className={stringBuilder({ 'border-top-0': !!isFirst }, { 'border-bottom-0': !!isLast })} action={!disableEdit} onClick={!disableEdit ? onAction : undefined}>
        <Row className='align-items-center'>
            <Col xs="8" md='10'>
                <Row>
                    <Col md='4' className='text-muted no-wrap'>
                        {title}
                    </Col>
                    <Col md='8' className='font-medium no-wrap'>
                        {value}
                    </Col>
                </Row>
            </Col>
            <Col xs="4" md='2' className={stringBuilder('text-right', { 'text-link': !disableEdit })}>
                {disableEdit || <Translate id='misc.edit' />}
            </Col>
        </Row>
    </ListGroupItem>
);

export default ListGroupItemLayout;