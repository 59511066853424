export default{
    "account.api.logs.sidePanelAccountLogs.tabs.details" : "Details",

    "account.api.logs.sidePanelAccountLogs.logs.title" : "Log Information",

    "account.api.logs.sidePanelAccountLogs.logs.summary"  : "Summary",
    "account.api.logs.sidePanelAccountLogs.logs.status"   : "Status",
    "account.api.logs.sidePanelAccountLogs.logs.method"   : "Method",
    "account.api.logs.sidePanelAccountLogs.logs.endpoint" : "Endpoint",
    "account.api.logs.sidePanelAccountLogs.logs.date"     : "Date",

    "account.api.logs.sidePanelAccountLogs.logs.request" : "Request {method} body",

    "account.api.logs.sidePanelAccountLogs.logs.response" : "Response body",

    "account.api.logs.sidePanelAccountLogs.logs.copy" : "Copy",
}