import Translate from "@spordle/intl-elements";
import getReferrer from "../../../helpers/getReferer";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import queryString from 'query-string';
import { useContext, useState } from "react";
import { AccountsContext, AuthContext } from "../../../contexts/contexts";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { Alert, Button, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import OverlayLoader from "../../loading/OverlayLoader";
import { Form, Formik } from "formik";
import { FormikTextArea } from '@spordle/formik-elements';
import { object, string } from 'yup';
import Required from "../../formik/Required";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { success } from '@spordle/toasts';
import { OrganizationContext } from "../../../contexts/OrganizationContext";

const WaitingListItemsModal = ({ waitingListItem }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="waitingListItemModal">
            <WaitingListItemsModalInner waitingListItem={waitingListItem} toggle={() => setIsOpen(false)} />
        </AnalyticsModal>
    )
}

const WaitingListItemsModalInner = ({ waitingListItem, toggle }) => {
    const [ activeStep, setActiveStep ] = useState(1);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const auth = useContext(AuthContext);
    const accountsContext = useContext(AccountsContext);
    const orgContext = useContext(OrganizationContext);

    const isRegistration = waitingListItem.type === 'REGISTRATION';

    const onConfirm = async() => {
        if(isRegistration){
            setIsLoading(true);
            const org = await orgContext.getOrganization(waitingListItem.organisation.organisation_id)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setIsLoading(false);
                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                    }
                })
            goToURL(org.deploy_on == 'PAGE' ?
                `${getReferrer('PAGE-VERCEL-2')}/${org.short_name}/participant/${waitingListItem.online_store_id}`
                : org.deploy_on == 'VERCEL' ?
                    getReferrer('PAGE-VERCEL') + `/${org.short_name}/participant/${waitingListItem.online_store_id}`
                    :
                    getReferrer('PAGE') + `/page/${org.short_url}/participant/${waitingListItem.online_store_id}`)
        }else{
            goToURL(getReferrer('HCR-SITE') + `/clinic-details/${waitingListItem.clinic.clinic_id}`)
        }
        toggle();
    }

    const getExtraParameters = () => {
        if(isRegistration){
            return {
                registrationFeeId: waitingListItem.registration_fee.registration_fee_id,
                registrationName: waitingListItem.registration_fee.name,
                registrationI18n: JSON.stringify(waitingListItem.registration_fee.i18n),
            }
        }
        return {}
    }

    const goToURL = (url) => {
        window.open(queryString.stringifyUrl({
            url: url,
            fragmentIdentifier: 'waiting-list-return',
            query: {
                accessToken: auth.accessToken,
                firstName: waitingListItem.member.first_name,
                lastName: waitingListItem.member.last_name,
                memberId: waitingListItem.member.member_id,
                waitingListItemId: waitingListItem.waiting_list_item_sequential_number,
                ...getExtraParameters(),
            },
        }), undefined, 'noopener,noreferrer')
        toggle();
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <Collapse isOpen={activeStep == 1}>
                <Fade in={activeStep == 1}>
                    <ModalHeader className="border-0 position-absolute top-0 right-0 z-index-1" toggle={toggle} />
                    <ModalBody className="text-center">
                        <i className="mdi mdi-bell-ring-outline text-primary" style={{ fontSize: 75 }} />
                        <p>
                            {isRegistration ?
                                <>
                                    <div><Translate id='waitingListItemsModal.modal.text1.registration' /></div>
                                    <span className="font-bold">
                                        <DisplayI18n field='name' defaultValue={waitingListItem.registration_fee.name} i18n={waitingListItem.registration_fee.i18n} />
                                    </span>
                                </>
                                :
                                <>
                                    <div><Translate id='waitingListItemsModal.modal.text1.clinic' /></div>
                                    <span className="font-bold">
                                        <DisplayI18n field='name' defaultValue={waitingListItem.clinic.name} i18n={waitingListItem.clinic.i18n} />
                                    </span>
                                </>
                            }
                        </p>
                        <Translate
                            id='waitingListItemsModal.modal.text2'
                            values={{
                                firstName: <b>{waitingListItem.member.first_name}</b>,
                                lastName: <b>{waitingListItem.member.last_name}</b>,
                            }}
                        />

                        {error &&
                            <Alert color='danger' toggle={() => setError(null)} className='mt-3'>
                                {error}
                            </Alert>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onConfirm} color="primary" type="button">
                            <Translate id="preinitModal.modal.btn" /> <i className="mdi mdi-arrow-right" />
                        </Button>
                        <Button onClick={() => setActiveStep(2)} color="primary" type="button" outline>
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </Fade>
            </Collapse>

            <Collapse isOpen={activeStep == 2}>
                <Fade in={activeStep == 2}>
                    <Formik
                        initialValues={{
                            reason: '',
                        }}
                        validationSchema={object().shape({
                            reason: string().required(<Translate id='waitingListItemsModal.modal.cancelReason.required' />),
                        })}
                        onSubmit={(values) => {
                            setIsLoading(true);
                            return accountsContext.cancelWaitingListItem(auth.userData.Username, waitingListItem.waiting_list_item_sequential_number, {
                                cancellation_reason: values.reason,
                            })
                                .then(() => {
                                    setIsLoading(false);
                                    toggle();
                                    success({
                                        msg: 'waitingListItemsModal.modal.toast',
                                    })
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        setIsLoading(false)
                                    }
                                })
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <ModalHeader>
                                    <Translate id='waitingListItemsModal.modal.header' toggle={toggle} />
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for='reason'><Translate id='waitingListItemsModal.modal.cancelReason' /> <Required /></Label>
                                        <FormikTextArea name='reason' id='reason' rows={3} />
                                    </FormGroup>

                                    {error &&
                                        <Alert color='danger' toggle={() => setError(null)}>
                                            {error}
                                        </Alert>
                                    }
                                </ModalBody>
                                <ModalFooter className="justify-content-between">
                                    <Button onClick={() => setActiveStep(1)} color="primary" type="button" outline>
                                        <Translate id="misc.back" />
                                    </Button>
                                    <Button color="primary" type="submit">
                                        <Translate id='misc.confirm' />
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fade>
            </Collapse>
        </OverlayLoader>
    )
}

export default WaitingListItemsModal;