import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, Collapse, Fade, ModalFooter } from "reactstrap";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { HoverableCopy } from "../../../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { AllOfficialReportsModalForm } from "../../allOfficialReportsModal/AllOfficialReportsModal";
import { AccountMemberAddCoachContext } from "./AccountMemberAddCoachContext";

const OfficialInfo = ({ official, selectBtn }) => {
    const { formData, updateFormData } = useContext(AccountMemberAddCoachContext);

    const handleOnViewClick = () => {
        updateFormData("viewMode", "official");
    }

    const handleOnPrevious = () => {
        updateFormData("viewMode", "steps");
    }

    return (
        <>
            <UserDisplay className="mb-3 flex-wrap flex-md-nowrap" card block>
                <UserDisplay.Container>
                    <UserImg
                        width={45}
                        abbr={official ? `${official.first_name.charAt(0)}${official.last_name.charAt(0)}` : "HC"}
                        alt="Official"
                    />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserDisplay.Title>{official ? `${official.first_name} ${official.last_name}` : '-'}</UserDisplay.Title>
                    <UserDisplay.Subtitle>{official?.unique_identifier ? <HoverableCopy toCopy={official?.unique_identifier}>#{official?.unique_identifier}</HoverableCopy> : '-'}</UserDisplay.Subtitle>
                    {official && <UserDisplay.Subtitle>{official.birthdate} (<Translate id='misc.yearsOld' values={{ age: official.age }} />)</UserDisplay.Subtitle>}
                    {official?.member_qualification &&
                        <UserDisplay.Subtitle>
                            <DisplayI18n field='name' defaultValue={official.member_qualification.qualification.name} i18n={official.member_qualification.qualification.i18n} />{(official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={official.member_qualification.qualification_level.name} i18n={official.member_qualification.qualification_level.i18n} /></>}
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                <UserDisplay.Container className="flex-shrink-0 ml-md-auto text-md-right">
                    {selectBtn}
                    {official?.is_official == 1 &&
                        <Fade in={formData.viewMode !== "official"}>
                            <button disabled={formData.viewMode == "official"} onClick={handleOnViewClick} type="button" className="reset-btn text-primary">
                                <span className="small">
                                    <Translate id='account.members.coach.allReportsBtn.short' />
                                </span>
                            </button>
                        </Fade>
                    }
                </UserDisplay.Container>
            </UserDisplay>
            <Collapse isOpen={formData.viewMode == "official"}>
                {official && <AllOfficialReportsModalForm pagination={3} member={official} />}
                <ModalFooter className="mx-n3 mt-3">
                    <Button className="mr-auto" color="primary" outline type="button" onClick={handleOnPrevious}><Translate id="misc.previous" /></Button>
                </ModalFooter>
            </Collapse>
        </>
    );
}

export default OfficialInfo;