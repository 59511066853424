import authContext from "./authContext";
import i18nContext from "./i18nContext";
import membersContext from "./membersContext";
import themeContext from "./themeContext";

export default{
    ...authContext,
    ...i18nContext,
    ...membersContext,
    ...themeContext,
}