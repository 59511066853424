import Translate from "@spordle/intl-elements";
import { useState } from "react";
import SidePanel from "../../../../../../../components/sidePanel/SidePanel";
import AttendeeCard from "../sidePanelActionsModals/AttendeeCard";
import AttendeeModalBulkUpdate from "../sidePanelActionsModals/AttendeeModalBulkUpdate";

/**
 * @typedef {Object} MultiProps
 * @prop {object[]} selectedRows
 * @prop {function} handleResendInvoice
 * @prop {function} onMultiSelectChange
 */

/**
 * @description SidePanel shown when multiple attendees are selected in the spordle table
 * @param {MultiProps & import("./SidePanelAttendeeSharedInfo").SharedProps} props
 * @returns {JSX.Element}
 */
const SidePanelAttendeeMulti = ({ selectedRows: attendees, handleResendInvoice, onMultiSelectChange, ...props }) => {
    const [ bulkUpdateMode, setBulkUpdateMode ] = useState(null);

    return (
        <>
            <SidePanel.Header>
                <AttendeeModalBulkUpdate {...props} participants={attendees} mode={bulkUpdateMode} isOpen={!!bulkUpdateMode} toggle={() => { setBulkUpdateMode(null) }} />
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    {props.canEdit &&
                        <SidePanel.ActionsMenu>
                            <SidePanel.MenuAction onClick={() => { setBulkUpdateMode('PRESENCE') }}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence' />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction onClick={() => { setBulkUpdateMode('RESULT') }}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result' />
                            </SidePanel.MenuAction>
                        </SidePanel.ActionsMenu>
                    }
                </div>
                <SidePanel.Title>
                    <Translate id='account.members.clinicsManagement.attendees.sidePanel.selected' values={{ count: attendees.length }} />
                </SidePanel.Title>
            </SidePanel.Header>
            <SidePanel.Body>
                {attendees.map((att) => (
                    <AttendeeCard att={att} onMultiSelectChange={onMultiSelectChange} key={att.clinic_attendee_id} />
                ))}
            </SidePanel.Body>
        </>
    )
}

export default SidePanelAttendeeMulti;