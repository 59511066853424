import { Redirect } from 'react-router-dom';
import SpordleForgotPassword from '../../views/spordle/forgotPassword';
import HCRForgotPassword from '../../views/hcr/forgotPassword';
import TourForgotPassword from '../../views/tournaments/forgotPassword';
import { LOGINTYPE } from '../../helpers/constants';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import { isMultiSport } from '../../helpers/getReferer';

const ForgotPasswordPicker = (props) => {
    switch (getLocalStorageItem('platformLogin') || LOGINTYPE.SPORDLE){
        case LOGINTYPE.HCR:
        case LOGINTYPE.PLAY:
        case LOGINTYPE.CLINIC:
            if(!isMultiSport())
                return <HCRForgotPassword {...props} />;
        case LOGINTYPE.SPORDLE:
            return <SpordleForgotPassword {...props} />;
        case 'TOUR':
            return <TourForgotPassword {...props} />;

        default:
            return <Redirect to='/404' />
    }
}

export default ForgotPasswordPicker;