export default{
    "forgotPassword.title.accountRecovery" : "Récupération de mot de passe",

    "forgotPassword.label.enterEmail"         : "Veuillez entrer votre adresse courriel",
    "forgotPassword.label.noEmail"            : "Vous n'avez pas reçu de courriel?",
    "forgotPassword.label.enterNewPassword"   : "Nouveau mot de passe",
    "forgotPassword.label.confirmNewPassword" : "Confirmez le mot de passe",

    "forgotPassword.label.forgot"     : "Mot de passe oublié?",
    "forgotPassword.label.weWillSend" : "Nous allons envoyer un courriel à",
    "forgotPassword.label.toRecover"  : "pour récupérer votre mot de passe",
    "forgotPassword.label.enterCode"  : "Entrez le code de vérification envoyé à votre courriel",

    "forgotPassword.action.backToLogin"        : "Retour à la connexion",
    "forgotPassword.action.sendEmail"          : "Envoyer un courriel",
    "forgotPassword.action.resendCode"         : "Renvoyer un code",
    "forgotPassword.action.confirmNewPassword" : "Soumettre",
    "forgotPassword.action.verifyCode"         : "Valider le code",

    "forgotPassword.success.emailWasSent"    : "Entrez le code de vérification envoyé à votre courriel",
    "forgotPassword.success.emailSent"       : "Un courriel de récupération à été envoyé à l'adresse courriel ci-dessus. Veuillez cliquez sur le lien dans le courriel pour commencer la récupération de votre compte.",
    "forgotPassword.success.emailSent.toast" : "Un courriel a été envoyé!",
    "forgotPassword.success.newPassword"     : "Votre mot de passe à été changé!",

    "forgotPassword.error.genericError"  : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "forgotPassword.error.requiredEmail" : "Un adresse courriel est requis.",
    "forgotPassword.error.invalidEmail"  : "Please enter a valid email.",

    "forgotPassword.error.300" : "Nous n'avons trouvé aucun compte lié à l'adresse courriel fournis.",
}