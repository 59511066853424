import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import getReferrer from "../../../../helpers/getReferer";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/contexts";
import moment from "moment";
import SingleApp from "./SingleApp";
import { classPrefix } from "./AppsHelper";

// App images
import One from "../../../../assets/images/spordleSuite/One.png";
import Event from "../../../../assets/images/spordleSuite/Event.png";
import Site from "../../../../assets/images/spordleSuite/Site.png";
import Plex from "../../../../assets/images/spordleSuite/Plex.png";
import iD from "../../../../assets/images/spordleSuite/iD.png";
import HC from "../../../../assets/images/logos/HC.png";
import UserImg from "../../../../components/UserImg";
import ReactTooltip from 'react-tooltip';

/**
 * Mettre en haut les most visited ?
 * Personne connait Page faque Page 3eme
 * IMPORTANT : WHEN ADDING NEW ICON, please make sure the dimensions of the icons are all the same for a smooth visual.
 */
const HeaderApps = () => {
    const TOGGLER_ID = "appsToggler";
    const auth = useContext(AuthContext);
    const [ identities, setIdentities ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);
    const accessQuery = {
        accessToken: auth.accessToken,
        type: auth.type,
    };

    useEffect(() => {
        auth.getUserIdentityRole()
            .then((ids) => {
                const filteredIds = ids.filter((ir) => {
                    if(ir.active === undefined || ir.active === '0' || ir.role.active === undefined || ir.role.active === '0'){
                        return false;
                    }else if((!ir.start_date && !ir.end_date)){
                        // both fields are empty strings/null/undefined
                        return true;
                    }else if(!!ir.start_date && !!ir.end_date && moment(moment.now()).isBetween(moment(ir.start_date), moment(ir.end_date), undefined, '[]')){
                        // both dates are valid and now is between them
                        return true;
                    }
                    const start = ir.start_date ? moment(ir.start_date).isBefore(moment.now()) : true;
                    const end = ir.end_date ? moment(ir.end_date).isAfter(moment.now()) : true;

                    // we want to return false if only one of them is false
                    // start can be false only if moment.now() is before, so the start date is in the future
                    // end can be false only if moment.now() is after, so the end date is in the past
                    // any other cases where they both are ''/null/undefined or valid with moment.now() is a valid case and we return true
                    return start && end;

                })

                setIdentities({ list: filteredIds, main: filteredIds.find((role) => role.is_primary == "1") ?? filteredIds[0] });
            })
            .catch(console.error)
    }, []);

    const getIDUrl = () => {
        if(identities.main){
            return queryString.stringifyUrl({
                url: getReferrer('HCR'),
                query: {
                    ...accessQuery,
                    idToken: auth.idToken,
                    identityRoleId: identities.main.identity_role_id,
                    organizationId: identities.main.organisation.organisation_id,
                },
            })
        }
        return undefined;

    }

    /**
     *
     * @param {string} url
     * @returns {string} url with query
     */
    const getBasicUrl = (url) => {
        return queryString.stringifyUrl({
            url: url,
            query: accessQuery,
        })
    }

    return (
        auth.isPowerUser() ?
            <li
                tabIndex={1}
                onFocus={(e) => {
                    if(e.target.id !== TOGGLER_ID){
                        setIsOpen(true);
                    }
                }}
                onBlur={(e) => {
                    if(!e.currentTarget.contains(e.relatedTarget)){
                        // Not triggered when swapping focus between children
                        setIsOpen(false);
                    }
                }}
                className="dropdown nav-item"
            >
                <button type='button' data-for="tooltip-apps" data-tip="Power User Only" id={TOGGLER_ID} onClick={() => setIsOpen(!isOpen)} className="reset-btn nav-link text-purple"><i className="mdi mdi-view-grid font-18" /></button>
                <ReactTooltip id="tooltip-apps" />
                <div className={`${classPrefix}-menu${isOpen ? ' show' : ''}`}>
                    <PerfectScrollbar>
                        <nav className={`${classPrefix}-container`}>
                            <section className={`${classPrefix}-section`}>
                                <h2 className={`${classPrefix}-header`}>Spordle</h2>
                                <ul className={`${classPrefix}-list`}>

                                    {/* MY ACCOUNT */}
                                    <SingleApp img={iD} name="My Account" link={getBasicUrl(getReferrer("ACCOUNT"))} />

                                    {/* SPORDLE ONE */}
                                    <SingleApp img={One} name="One" link="https://www.spordle.com/solutions/one/" />

                                    {/* EventNRoll */}
                                    <SingleApp img={Event} name="Event" link="https://www.eventnroll.com/" />

                                    {/* SPORDLE PLEX */}
                                    <SingleApp img={Plex} name="Plex" link="https://www.spordle.com/solutions/plex/" />

                                    {/* SPORTSITES */}
                                    <SingleApp img={Site} name="Site" link="https://www.spordle.com/solutions/site/" />

                                    {/* PAGE */}
                                    <SingleApp
                                        img={iD} name="Page"
                                        link={
                                            getBasicUrl(identities.main ?
                                                identities.main.organisation.deploy_on == 'PAGE' ?
                                                    `${getReferrer('PAGE-VERCEL-2')}/${identities.main.organisation.short_name}`
                                                    : identities.main.organisation.deploy_on == 'VERCEL' ?
                                                        `${getReferrer('PAGE-VERCEL')}/${identities.main.organisation.short_name}`
                                                        :
                                                        `${getReferrer('PAGE')}/page/${identities.main.organisation.short_url}`
                                                :
                                                `${getReferrer('PAGE')}/page/hc`)
                                        }
                                    />
                                </ul>
                            </section>
                            <section className={`${classPrefix}-section`}>
                                <h2 className={`${classPrefix}-header`}>Hockey Canada</h2>
                                <ul className={`${classPrefix}-list`}>
                                    {/* SID */}
                                    {!!getIDUrl() &&
                                        <SingleApp
                                            customImg={
                                                <UserImg
                                                    abbr={identities.main.organisation.abbreviation}
                                                    alt={identities.main.organisation.organisation_name}
                                                    src={identities.main.organisation.logo?.full_path}
                                                    filePos={identities.main.organisation.logo?.file_position}
                                                    width={50}
                                                />
                                            }
                                            name="iD"
                                            link={getIDUrl()}
                                        />
                                    }

                                    {/* HCR SITE */}
                                    <SingleApp
                                        img={HC}
                                        name="HCR Registry"
                                        link={getBasicUrl(getReferrer("HCR-SITE"))}
                                    />

                                    {/* <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Very Long AppName For Tests" />
                                    <SingleApp img={iD} name="Test" /> */}
                                </ul>
                            </section>
                            <footer className={`${classPrefix}-footer`}>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.spordle.com/solutions/" className="btn btn-outline-spordle d-block">
                                    Spordle products
                                </a>
                            </footer>
                        </nav>
                    </PerfectScrollbar>
                </div>
                {/* <DropdownMenu  right>
                </DropdownMenu> */}
            </li>
            :
            null
    )
}

export default HeaderApps;