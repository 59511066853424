import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { Badge, Card } from "reactstrap";
import { formatFileName, getIcon } from "../../../../../components/uploader/uploadHelpers";
import { AuthContext, AccountsContext } from "../../../../../contexts/contexts";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import FileViewer from "../../../../../components/fileViewer/FileViewer";
import { useDisclosure } from "@mantine/hooks";

const MemberDocumentItem = ({ document, memberId }) => {
    const { userData } = useContext(AuthContext);
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const { downloadAccountDocument } = useContext(AccountsContext);

    if(!document.attachment){
        return null;
    }

    return (
        <>
            <FileViewer
                fullPath={() => downloadAccountDocument(userData.Username, document.member_attachement_id)}
                creationDate={<DateFormat value={document.created_at} format={DateFormat.formats.longMonthDayYearTime} />}
                fileName={document.attachment.file_name}
                type={document.attachment.file_ext}
                isOpen={isOpen}
                close={isOpenHandlers.close}
            />
            <Card
                className="mb-0 card-shadow overflow-hidden position-relative d-flex flex-column justify-content-end bg-light h-100 card-hover clickable"
                onClick={() => {
                    isOpenHandlers.open();
                }}
            >
                <div style={{ height: 0, paddingBottom: "75%", width: "100%" }} className="flex-grow-1 position-relative overflow-hidden">
                    <i className={stringBuilder("line-height-1 fa-5x d-flex position-absolute top-0 bottom-0 left-0 right-0 flex-center", getIcon(document.attachment.file_ext))} />
                </div>
                <div className="p-3 rounded-lg bg-white border-top">
                    {/* <UncontrolledDropdown className="reset-btn position-absolute top-0 right-0">
                        <DropdownToggle color="light">
                            <i className="mdi mdi-dots-vertical" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={handleOnDownload}><Translate id='misc.download' /></DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={handleOnDelete}><Translate id="misc.delete" /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <div className="font-medium d-flex align-items-center">
                        {formatFileName(document.attachment.file_name)}
                    </div>
                    <div className="small">
                        <DateFormat value={document.created_at} />
                    </div>
                    {document.document_type ?
                        <Badge className="mb-1" color="secondary">
                            <DisplayI18n
                                field="name"
                                defaultValue={document.document_type.name}
                                i18n={document.document_type.i18n}
                            />
                        </Badge>
                        :
                        <Badge className="mb-1" color="light">
                            {document.note || <Translate id='account.members.documents.documentType.default' />}
                        </Badge>
                    }
                </div>
            </Card>
        </>
    );
}

export default MemberDocumentItem;