import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import GoToHomeBtn from '../components/GoToHomeBtn';

// auth pages's 404 page not found
const NotFound = () => (
    <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
        <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
            <GoToHomeBtn />
        </div>
        <Container className="ml-2 mr-2 mt-5 mb-5 text-center">
            <div className="h1 text-dark font-bold mt-5">404</div>
            <div className="h3 text-dark mt-5">The page you tried to reach does not exist</div>
            <div className="h5 text-dark mt-5">
                <a href="https://www.spordle.com/" rel="noopener noreferrer">Spordle</a>
                <br />
                Please contact <a href="mailto:hcr@spordle.com" rel="noopener noreferrer">support</a>
            </div>
            <Link to='/login' className='btn btn-primary'>
                Go to login
            </Link>
        </Container>
    </div>
);

// We do't define a theme, this way it will have the current theme
export default NotFound;