export const getFutureDate = () => {
    var year = new Date().getFullYear();
    var month = new Date().getMonth();
    var day = new Date().getDate();
    return new Date(year + 1, month, day).toISOString().split('T')[0];
}
export const getPastDate = () => {
    var year = new Date().getFullYear();
    var month = new Date().getMonth();
    var day = new Date().getDate();
    return new Date(year - 1, month, day).toISOString().split('T')[0];
}