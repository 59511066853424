import React, { createContext } from 'react';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { I18nContext } from './I18nContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<TeamCategoriesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const TeamCategoriesContext = createContext();
TeamCategoriesContext.displayName = 'TeamCategoriesContext';


class TeamCategoriesContextProvider extends React.Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {}

        this.state = this._initState;
    }

    /**
     * [GET] Gets team categories of an org
     * @param {string} orgId
     * @param {string} periodId
     * @param {object} [queryParams={}]
     * @returns {Promise.<object[]>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Categories/4f1d01b11d63cfa16354dfc0db3f97b0|documentation}
     */
    getTeamCategories = (orgId, periodId, queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `team-categories`,
            query: {
                organisation_id: orgId,
                period_id: periodId,
                active: 1,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_categories;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <TeamCategoriesContext.Provider value={{ ...this }}>
                {this.props.children}
            </TeamCategoriesContext.Provider>
        )
    }
}

export default withContexts(I18nContext)(TeamCategoriesContextProvider);