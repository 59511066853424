import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Collapse, Fade, Row } from "reactstrap";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { AccountsContext, AuthContext } from "../../../../contexts/contexts";
import classes from './AccountMemberClinicsManagement.module.scss';
import AccountMemberAttendeesManagement from "./attendeesManagement/AccountMemberAttendeesManagement";
import AccountClinicCard from "./components/AccountClinicCard";

const AccountMemberClinicsManagement = ({ member }) => {
    const { getClinicInstructorInfo } = useContext(AccountsContext);
    const { userData } = useContext(AuthContext);
    const [ clinicInstructorInfo, setClinicInstructorInfo ] = useState(null);
    const [ selectedClinic, setSelectedClinic ] = useState(null);
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);
    const transitionMs = parseInt(classes.transitionMs);
    const isManagingAttendees = !!selectedClinic;

    const clearSelectedClinic = () => {
        setSelectedClinic(null);
    }

    const handleOnClinicSelect = (clinic) => {
        setSelectedClinic(clinic);
        setCollapseIsOpen(true);
    }

    const clearClinicList = () => setClinicInstructorInfo(null);


    useEffect(() => {
        clearClinicList();
        clearSelectedClinic();

        getClinicInstructorInfo(userData.Username, member.member_id)
            .then(setClinicInstructorInfo)
            .catch(console.error);

    }, [ member.member_id ]);

    const getColProps = (clinic) => {

        const colProps = {
            sm: 12,
        };
        const classnames = [ classes.Col ];

        if(isManagingAttendees && collapseIsOpen){
            if(selectedClinic.clinic_id === clinic.clinic_id){
                classnames.push(classes.ColGrow);
            }else{
                colProps.md = "6";
                colProps.xl = "4";
                classnames.push(classes.ColShrink);
            }
        }else{
            colProps.md = "6";
            colProps.xl = "4";
        }

        colProps.className = classnames.join(' ');

        return colProps;
    }

    return (
        <div className="mb-5 pb-5">
            {!!selectedClinic &&
                <Collapse onExited={clearSelectedClinic} className={classes.Collapse} isOpen={!!collapseIsOpen} appear>
                    <button
                        onClick={() => setCollapseIsOpen(false)}
                        type="button"
                        className="btn btn-link"
                    >
                        <i className="mdi mdi-arrow-left" /> <Translate id='account.members.clinicsManagement.backToList' />
                    </button>
                </Collapse>
            }
            <Row form>
                {clinicInstructorInfo === null ?
                    <>
                        <Col sm="12" md="6" xl="4">
                            <Skeleton
                                className="d-block"
                                width="100%"
                                height={160}
                            />
                        </Col>
                        <Col sm="12" md="6" xl="4">
                            <Skeleton
                                className="d-block"
                                width="100%"
                                height={160}
                            />
                        </Col>
                    </>
                    :
                    clinicInstructorInfo?.length > 0 ?
                        clinicInstructorInfo
                            .map((clinicInfo, index) => (
                                <Col
                                    key={clinicInfo.clinic_id}
                                    {...getColProps(clinicInfo, index * transitionMs)}
                                >
                                    <Collapse unmountOnExit className={classes.Collapse} isOpen={!selectedClinic || selectedClinic.clinic_id == clinicInfo.clinic_id}>
                                        <Fade delay={index * transitionMs} timeout={transitionMs / 2} in={!selectedClinic || selectedClinic.clinic_id == clinicInfo.clinic_id}>
                                            <AccountClinicCard collapseIsOpen={collapseIsOpen} clinic={clinicInfo} selectedClinic={selectedClinic} showAttendeesList={handleOnClinicSelect} />
                                        </Fade>
                                    </Collapse>
                                </Col>
                            ))
                        :
                        <Col sm="12">
                            <EmptyLayout
                                hideArrow
                                translateTitle
                                translateMsg
                                title='account.members.clinicsManagement.noResults.title'
                                msg='account.members.clinicsManagement.noResults.msg'
                            />
                        </Col>
                }
            </Row>
            <Collapse isOpen={!!selectedClinic && collapseIsOpen}>
                <div className="pt-3">
                    <AccountMemberAttendeesManagement member={member} selectedClinic={selectedClinic || {}} />
                </div>
            </Collapse>
        </div>
    );
}

export default AccountMemberClinicsManagement;