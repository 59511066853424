import Translate from "@spordle/intl-elements";
import { useState } from "react";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { ModalBody, ModalHeader } from "reactstrap";
import UserDisplay from "../../userDisplay/UserDisplay";
import UserImg from "../../UserImg";
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';

const MissingInformationModal = ({ metaMembers }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="missingInformationModal">
            <MissingInformationModalInner metaMembers={metaMembers} toggle={() => setIsOpen(false)} />
        </AnalyticsModal>
    )
}

const MissingInformationModalInner = ({ metaMembers, toggle }) => {
    return (
        <>
            <ModalHeader className="border-0 position-absolute top-0 right-0 z-index-1" toggle={toggle} />
            <ModalBody className="text-center">
                <i className="mdi mdi-bell-ring-outline text-danger" style={{ fontSize: 75 }} />
                <p className="font-bold">
                    <Translate id='missingInformationModal.title' values={{ amount: metaMembers.length }} />
                </p>

                <div style={{ maxHeight: '40vh', minHeight: 0 }} className='overflow-hidden d-flex flex-column'>
                    <PerfectScrollbar>
                        {metaMembers.map((metaMember) => {
                            const member = { ...metaMember, ...metaMember.members?.[0] }
                            const missingFields = metaMember.members?.[0]?.missing_required_fields?.length > 0;
                            const missingDocuments = metaMember.members?.[0]?.need_address_document == 1;
                            const unsignedWaivers = metaMember.members?.[0]?.unsigned_critical_waivers == 1;

                            return (
                                <UserDisplay card className="w-100 mb-2" key={member.meta_member_id}>
                                    <UserDisplay.Container>
                                        <UserImg
                                            height={40}
                                            width={40}
                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                            className='shadow-sm'
                                            src={member.attachment?.full_path}
                                            filePos={member.attachment?.file_position}
                                            alt={member.first_name + ' ' + member.last_name}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <div className="d-flex">
                                            <UserDisplay.Title className="mr-2">{member.first_name} {member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle>({member.age} <Translate id='misc.yrs' />)</UserDisplay.Subtitle>
                                        </div>
                                        <ul className="mb-0 mt-2">
                                            {missingFields &&
                                                <li><span className="text-danger"><Translate id='account.members.missingFields.tooltip' /></span></li>
                                            }
                                            {missingDocuments &&
                                                <li><span className="text-danger"><Translate id='account.members.missingAddressDocument.tooltip' /></span></li>
                                            }
                                            {unsignedWaivers &&
                                                <li><span className="text-danger"><Translate id='account.members.unsignedCriticalWaiver.tooltip' /></span></li>
                                            }
                                        </ul>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="ml-auto">
                                        <Link
                                            to={queryString.stringifyUrl({
                                                url: '/members',
                                                query: {
                                                    metaMemberId: member.meta_member_id,
                                                },
                                            })}
                                            onClick={toggle}
                                        >
                                            <Translate id='missingInformationModal.view' /> <i className="mdi mdi-chevron-right" />
                                        </Link>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            )
                        })}
                    </PerfectScrollbar>
                </div>
            </ModalBody>
        </>
    )
}

export default MissingInformationModal;