import React from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Button,
    Card,
    CardBody,
    Alert,
    Fade,
    FormGroup
} from 'reactstrap';
import { stringBuilder } from "@spordle/helpers";

import { Formik, Form } from 'formik';

// Contexts

// Language
import Translate from '@spordle/intl-elements';

// Components
import CapsLock from '@spordle/capslock';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';

import HCLogo from '../../assets/images/logos/HC@2x.png';
import spordleMyAccount from '../../assets/images/logos/spordleMyAccount.svg';
import WithHcrTheme from '../../components/HOC/WithHcrTheme';

import FormikInputAuth from '../../components/loginInputs/FormikInputAuth';
import hcrStyles from '../../assets/scss/auth/hcr/style.module.scss';
import { AuthenticatorContext } from '../../components/authentication/AuthenticatorProvider';
import { FormikAddress, FormikError, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from '@spordle/formik-elements';
import { I18nContext } from '../../contexts/I18nContext';
import { LOCALES } from '../../i18n';
import AuthLayout from '../../layouts/layout-components/auth/AuthLayout';
import ReactTooltip from 'react-tooltip';
import { number, object, string } from 'yup';
import { UtilsContext } from '../../contexts/UtilsContext';
import SpordleSelect, { formatSelectData } from '@spordle/spordle-select';
import { DisplayI18n } from '../../helpers/i18nHelper';

class Signup extends React.Component{
    state = {
        showPsw: false,
        showConfirmPsw: false,
    };

    MAXIMUM_STEPS = 2;

    componentWillUnmount(){
        AxiosCancelAll();

        if(this.emailTimeOut){
            clearTimeout(this.emailTimeOut);
            this.emailTimeOut = 0;
        }
        if(this.lazyLoadTimeOut){
            clearTimeout(this.lazyLoadTimeOut);
            this.lazyLoadTimeOut = 0;
        }
    }

    render(){
        return (
            <AuthLayout layout="hcr">
                <AuthenticatorContext.Consumer>
                    {(authenticator) => (
                        <>
                            <Card className="text-dark card-shadow">
                                <CardBody>
                                    <div className="ml-n2 mt-n2">
                                        <Link to={authenticator.goToPage('LOGIN', true, { show: 'email' })} className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id='forgotPassword.action.backToLogin' /></Link>
                                    </div>
                                    <div className="text-center mb-4">
                                        <img className="mb-2" src={HCLogo} width="90" alt="Hockey Canada" />
                                        <div className="h4 font-bold mb-4"><Translate id='signup.header.hcr' /></div>
                                    </div>
                                    <div className="h6 mb-1 text-muted text-center"><Translate id='signup.label.title' /></div>
                                    <div className="text-center text-muted"><Translate id='signup.fields.helper' /></div>

                                    <Fade mountOnEnter unmountOnExit in={authenticator.show !== 'email'} className={stringBuilder({ 'd-none': authenticator.show === 'email' })}>
                                        <Formik
                                            key={authenticator.email}
                                            initialValues={{
                                                signup_identity: authenticator.email ?? '', // email - renamed to prevent chrome autocomplete
                                                first_name: '',
                                                last_name: '',
                                                password_signup: '',
                                                password_confirm: '',
                                                language: 'en_US',
                                                phone: '',
                                                address: {
                                                    ...getFormikAddressInitialValues(),
                                                    countryId: '', // Used to updated the state's select's list
                                                },
                                                step: 1,
                                            }}
                                            validationSchema={object().shape({
                                                signup_identity: string()
                                                    .required(<Translate id='login.form.validation.email.required' />)
                                                    .email(<Translate id='login.form.validation.email.email' />),
                                                first_name: string().required(<Translate id='signup.signupForm.validation.firstName.required' />),
                                                last_name: string().required(<Translate id='signup.signupForm.validation.lastName.required' />),
                                                password_signup: string().cognitoPassword(),
                                                password_confirm: string()
                                                    .test('password_signup-match', <Translate id='signup.signupForm.validation.passwordConfirm.match' />, function(value){
                                                        return this.parent.password_signup === value;
                                                    }),
                                                language: string(),
                                                phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                                                address: object().when('step', {
                                                    is: 2,
                                                    then: object().address(true, {
                                                        streetNumber: <Translate id='form.validation.streetNumber.required' />,
                                                        address: <Translate id='form.validation.address.required' />,
                                                        city: <Translate id='form.validation.city.required' />,
                                                        zipCode: <Translate id='form.validation.zip.required' />,
                                                        state: <Translate id='form.validation.province.required' />,
                                                        country: <Translate id='form.validation.country.required' />,
                                                    }),
                                                }),
                                                step: number(),
                                            })}
                                            onSubmit={async({ step, address, ...values }, { setStatus, setFieldValue, setFieldTouched }) => {
                                                if(step != this.MAXIMUM_STEPS){
                                                    await setFieldTouched('address', false);
                                                    await setFieldValue('step', step + 1);
                                                }else{
                                                    setStatus();
                                                    return authenticator.handleSignUp(values.first_name, values.last_name, authenticator.email, values.password_signup, values.language, values.phone, {
                                                        street_number: address.streetNumber,
                                                        street: address.address,
                                                        country_code: address.country,
                                                        province_code: address.state,
                                                        city: address.city,
                                                        zip_code: address.zipCode,
                                                        active: 1,
                                                        map_url: address.mapsUrl,
                                                        origin_address: address.origin,
                                                    })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                setStatus(error.message);
                                                            }
                                                        });
                                                }
                                            }}
                                        >
                                            {(formik) => (
                                                <Form className='mt-3' id='sign_up-info' autoComplete="off">
                                                    <Collapse isOpen={formik.values.step != 1}>
                                                        <button type='button' onClick={() => { formik.setFieldValue('step', formik.values.step - 1) }} className='reset-btn text-dark mb-2'>
                                                            <i className="mdi mdi-chevron-left" /><Translate id='signup.action.previous' />
                                                        </button>
                                                    </Collapse>
                                                    <Collapse isOpen={formik.values.step == 1}>
                                                        <FormikInputAuth type='signup_identity' id='signup_identity' name='signup_identity' label='form.fields.email' icon='ti-user' trim />
                                                        <FormikInputAuth id='first_name' name='first_name' label='signup.signupForm.label.firstName' trim />
                                                        <FormikInputAuth id='last_name' name='last_name' label='signup.signupForm.label.lastName' trim />
                                                        <div className="position-relative">
                                                            <FormikInputAuth id='password_signup' name='password_signup' label='signup.signupForm.label.password' type={this.state.showPsw ? 'text' : 'password'} trim />
                                                            <div className="position-absolute top-0 right-0 mt-3 px-2 mr-1 bg-white d-flex align-items-center">
                                                                <i
                                                                    onClick={() => this.setState((prevState) => ({ showPsw: !prevState.showPsw }))}
                                                                    className={`${formik.values.password_signup ? 'show' : 'hide'} inputAuth-showPsw position-static mdi mdi-eye${this.state.showPsw ? '' : '-off'}-outline`}
                                                                />
                                                                <button
                                                                    className="reset-btn inputAuth-tooltip"
                                                                    data-event="click mouseenter"
                                                                    data-event-off="blur mouseleave"
                                                                    data-for='password_tip'
                                                                    data-tip='signup.signupForm.tooltip.password.title'
                                                                    type="button"
                                                                    tabIndex="-1"
                                                                >
                                                                    <i className="font-16 mdi mdi-information-outline" />
                                                                </button>
                                                                <ReactTooltip
                                                                    id='password_tip'
                                                                    getContent={(tip) => tip &&
                                                                        <div>
                                                                            <div className="font-medium mb-1"><Translate id={tip} /></div>
                                                                            <ol className="p-0 small">
                                                                                <li><Translate id='signup.signupForm.tooltip.password.1' /></li>
                                                                                <li><Translate id='signup.signupForm.tooltip.password.2' /></li>
                                                                                <li><Translate id='signup.signupForm.tooltip.password.3' /></li>
                                                                                <li><Translate id='signup.signupForm.tooltip.password.4' /></li>
                                                                                <li><Translate id='signup.signupForm.tooltip.password.5' /></li>
                                                                            </ol>
                                                                        </div>
                                                                    }
                                                                    place='top'
                                                                    effect='solid'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="inputAuth-container">
                                                            <FormikInputAuth id='password_confirm' name='password_confirm' label='signup.signupForm.label.passwordConfirm' type={this.state.showConfirmPsw ? 'text' : 'password'} trim />
                                                            <i
                                                                onClick={() => this.setState((prevState) => ({ showConfirmPsw: !prevState.showConfirmPsw }))}
                                                                className={`${formik.values.password_confirm ? 'show' : 'hide'} inputAuth-showPsw mdi mdi-eye${this.state.showConfirmPsw ? '' : '-off'}-outline`}
                                                            />
                                                        </div>
                                                    </Collapse>
                                                    <Collapse isOpen={formik.values.step == 2}>
                                                        <FormGroup>
                                                            <I18nContext.Consumer>
                                                                {({ setLocale }) => (
                                                                    <FormikSelect
                                                                        id='language'
                                                                        name='language'
                                                                        className="auth-select"
                                                                        search={false}
                                                                        defaultData={[
                                                                            { label: 'English', value: 'en_US' },
                                                                            { label: 'Français', value: 'fr_CA' },
                                                                        ]}
                                                                        onOptionSelected={(values) => {
                                                                            setLocale(values[0] === 'en_US' ? LOCALES.ENGLISH : LOCALES.FRENCH);
                                                                        }}
                                                                        loadingStatus="success"
                                                                    />
                                                                )}
                                                            </I18nContext.Consumer>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormikPhoneInput
                                                                id='phone'
                                                                name='phone'
                                                                className="inputAuth-phone"
                                                                inputProps={{
                                                                    className: 'inputAuth-phoneInput',
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <UtilsContext.Consumer>
                                                            {({ getCountries }) => (
                                                                <FormikAddress
                                                                    id='address' name='address'
                                                                    placeholder='form.fields.address'
                                                                    allowManualPlace
                                                                    onPlaceChanged={(newPlace) => {
                                                                        if(newPlace.country){
                                                                            getCountries()
                                                                                .then((countries) => {
                                                                                    const countryId = countries.find((country) => country.code === newPlace.country).country_id;
                                                                                    formik.setFieldValue('address', {
                                                                                        streetNumber: newPlace.streetNumber,
                                                                                        address: newPlace.address,
                                                                                        city: newPlace.city,
                                                                                        country: newPlace.country,
                                                                                        countryId: countryId,
                                                                                        state: newPlace.state,
                                                                                        zipCode: newPlace.zipCode,
                                                                                        fullAddress: newPlace.fullAddress,
                                                                                        mapsUrl: newPlace.mapsUrl,
                                                                                        origin: newPlace.origin,
                                                                                    });
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.error(error);
                                                                                })
                                                                        }
                                                                    }}
                                                                    customRenderManualPlace={({ manualPlace, setManualPlace }) => (
                                                                        <div>
                                                                            <FormikInputAuth trim label='form.fields.streetNumber' name="address.streetNumber" id="address.streetNumber" autoComplete="new-password" />
                                                                            <FormikInputAuth trim label='form.fields.address' name="address.address" id="address.address" autoComplete="new-password" />
                                                                            <FormikInputAuth trim label='form.fields.city' name="address.city" id="address.city" autoComplete="new-password" />
                                                                            <FormikInputAuth trim label='form.fields.zip' name="address.zipCode" id="address.zipCode" autoComplete="new-password" />

                                                                            <FormGroup>
                                                                                <I18nContext.Consumer>
                                                                                    {({ getGenericLocale }) => (
                                                                                        <FormikSelect
                                                                                            id="address.country" name="address.country"
                                                                                            renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                                                            placeholder='form.fields.country'
                                                                                            className="auth-select"
                                                                                            inputClassName={stringBuilder({ 'border-danger': formik.touched.address && formik.errors.address })}
                                                                                            searchKeys={[
                                                                                                `i18n.${getGenericLocale()}.name`,
                                                                                            ]}
                                                                                            loadData={(from) => {
                                                                                                switch (from){
                                                                                                    case 'CDM':
                                                                                                        return getCountries().then((countries) => {
                                                                                                            return formatSelectData(countries.map((country) => {
                                                                                                                return {
                                                                                                                    value: country.code,
                                                                                                                    label: country.code,
                                                                                                                    countryId: country.country_id,
                                                                                                                    i18n: country.i18n,
                                                                                                                }
                                                                                                            }), {
                                                                                                                newGroupIndexes: {
                                                                                                                    0: {
                                                                                                                        label: "misc.select.suggested",
                                                                                                                        groupId: "suggested",
                                                                                                                    },
                                                                                                                },
                                                                                                                getGroupId: (option) => (option.value === 'CA' || option.value === 'US') ? 'suggested' : undefined,
                                                                                                            })
                                                                                                        })
                                                                                                    default:
                                                                                                        break;
                                                                                                }
                                                                                            }}
                                                                                            onOptionSelected={([ country ], select) => {
                                                                                                formik.setFieldValue('address.countryId', select.getSpordleTable().getData().find((option) => option.value === country).countryId)
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </I18nContext.Consumer>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <I18nContext.Consumer>
                                                                                    {({ getGenericLocale }) => (
                                                                                        <UtilsContext.Consumer>
                                                                                            {({ getProvinces }) => (
                                                                                                <FormikSelect
                                                                                                    key={formik.values.address.countryId}
                                                                                                    id="address.state" name="address.state"
                                                                                                    placeholder='form.fields.province'
                                                                                                    disabled={!formik.values.address.countryId}
                                                                                                    className="auth-select"
                                                                                                    inputClassName={stringBuilder({ 'border-danger': formik.touched.address && formik.errors.address })}
                                                                                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                                                                    searchKeys={[
                                                                                                        `i18n.${getGenericLocale()}.name`,
                                                                                                    ]}
                                                                                                    loadData={(from) => {
                                                                                                        switch (from){
                                                                                                            case 'CDM':
                                                                                                                return formik.values.address.countryId ?
                                                                                                                    getProvinces(formik.values.address.countryId)
                                                                                                                        .then(([ country ]) => {
                                                                                                                            return country?.sub_divisions?.map((state) => ({
                                                                                                                                key: country.country_id + '-' + state.code,
                                                                                                                                id: state.code,
                                                                                                                                value: state.code,
                                                                                                                                label: state.code,
                                                                                                                                i18n: state.i18n,
                                                                                                                            })) || []
                                                                                                                        })
                                                                                                                    :
                                                                                                                    Promise.resolve([]);
                                                                                                            default:
                                                                                                                break;
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </UtilsContext.Consumer>
                                                                                    )}
                                                                                </I18nContext.Consumer>
                                                                            </FormGroup>
                                                                            <div className='mb-3 text-right'>
                                                                                <button type='button' className='reset-btn' onClick={() => setManualPlace(false)}>
                                                                                    <span className='small text-primary'>
                                                                                        <i className='mdi mdi-information-outline mr-1' /><Translate id='misc.googlePlaces.manualAddress.toggleOff' />
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {({ spordleSelectProps, manualPlace, setManualPlace, customRenderManualPlace }) => (
                                                                        <>
                                                                            <Collapse isOpen={!manualPlace}>
                                                                                <Fade in={!manualPlace}>
                                                                                    <FormGroup>
                                                                                        <SpordleSelect
                                                                                            {...spordleSelectProps}
                                                                                            className={stringBuilder('auth-select', spordleSelectProps.className)}
                                                                                            inputClassName={stringBuilder({ 'border-danger': formik.touched.address && formik.errors.address })}
                                                                                        />
                                                                                        <div className='d-flex small'>
                                                                                            <div className='flex-grow-1 text-danger'>
                                                                                                <FormikError name="address">
                                                                                                    {/* Always show first error from the address yup validation */}
                                                                                                    {(e) => spordleSelectProps.values[0] ? e[Object.keys(e)[0]] : <Translate id='form.validation.address.required' />}
                                                                                                </FormikError>
                                                                                            </div>
                                                                                            <div className='flex-grow-0 ml-3'>
                                                                                                <button
                                                                                                    type='button' className='reset-btn'
                                                                                                    onClick={() => {
                                                                                                        setManualPlace(true)
                                                                                                    }}
                                                                                                >
                                                                                                    <span className='text-primary'>
                                                                                                        <i className='mdi mdi-information-outline mr-1' /><Translate id='misc.googlePlaces.manualAddress.toggleOn' />
                                                                                                    </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Fade>
                                                                            </Collapse>
                                                                            <Collapse isOpen={manualPlace}>
                                                                                <Fade in={manualPlace}>
                                                                                    {customRenderManualPlace()}
                                                                                </Fade>
                                                                            </Collapse>
                                                                        </>
                                                                    )}
                                                                </FormikAddress>
                                                            )}
                                                        </UtilsContext.Consumer>
                                                    </Collapse>
                                                    {/* <FormGroup>
                                                        <I18nContext.Consumer>
                                                            {({ setLocale }) => (
                                                                <FormikSelect
                                                                    id='language'
                                                                    name='language'
                                                                    className="auth-select"
                                                                    search={false}
                                                                    defaultData={[
                                                                        { label: 'English', value: 'en_US' },
                                                                        { label: 'Français', value: 'fr_CA' },
                                                                    ]}
                                                                    onOptionSelected={(values) => {
                                                                        setLocale(values[0] === 'en_US' ? LOCALES.ENGLISH : LOCALES.FRENCH);
                                                                    }}
                                                                    loadingStatus="success"
                                                                />
                                                            )}
                                                        </I18nContext.Consumer>
                                                    </FormGroup> */}

                                                    <Button className={`py-3 ${hcrStyles['btn-hcr']}`} block type='submit' disabled={formik.isSubmitting}>
                                                        {formik.values.step != 2 ? <Translate id='signup.action.next' /> : <Translate id='signup.action.signup' />}
                                                        <i className="ti-arrow-right ml-2 align-middle" />
                                                    </Button>

                                                    {formik.status &&
                                                        <div className='pt-3'>
                                                            <Alert color='danger'><Translate id={`signup.error.${formik.status}`} /></Alert>
                                                        </div>
                                                    }

                                                    <CapsLock>
                                                        {(isActive) => (
                                                            <Collapse isOpen={isActive}>
                                                                <div className="pt-3">
                                                                    <Alert color='warning' className='mb-0'>
                                                                        <i className="mr-1 mdi mdi-apple-keyboard-caps" />
                                                                        <Translate id='warning.capsLock' />
                                                                    </Alert>
                                                                </div>
                                                            </Collapse>
                                                        )}
                                                    </CapsLock>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Fade>

                                    <Fade mountOnEnter unmountOnExit in={authenticator.show === 'email'} className={stringBuilder({ 'd-none': authenticator.show !== 'email' })}>
                                        <Formik
                                            key={authenticator.email}
                                            initialValues={{
                                                email: authenticator.email ?? '',
                                            }}
                                            validationSchema={object().shape({
                                                email: string()
                                                    .required(<Translate id='signup.signupForm.validation.email.required' />)
                                                    .email(<Translate id='signup.signupForm.validation.email.email' />),
                                            })}
                                            onSubmit={({ email }, { setStatus, setSubmitting }) => {
                                                setStatus();
                                                authenticator.checkForUser(email)
                                                    .then((data) => {
                                                        if(data.name){
                                                            authenticator.goToPage('LOGIN', false, { show: 'password' });
                                                        }else{
                                                            authenticator.changeViewTo('signup')
                                                            setSubmitting(false);
                                                        }
                                                    }, (error) => {
                                                        switch (error.message){
                                                            case 'UNCONFIRMED':
                                                                authenticator.goToPage('CONFIRMATION', false, { from: 'UNCONFIRMED' });
                                                                break;
                                                            case 'COMPROMISED':
                                                            case 'RESET_REQUIRED':
                                                            default:
                                                                console.error(error.message);
                                                                setSubmitting(false)
                                                                break;
                                                        }
                                                    })
                                            }}
                                        >
                                            {(signupFormik) => (
                                                <Form className="mt-3" id="signup_form">
                                                    <FormikInputAuth type='email' id={'email'} name={'email'} label={'form.fields.email'} icon={'ti-user'} trim translateLabel />

                                                    <Button className={`py-3 ${hcrStyles['btn-hcr']}`} block type='submit' disabled={signupFormik.isSubmitting}>
                                                        <Translate id='signup.action.next' />
                                                        <i className="ti-arrow-right ml-2 align-middle" />
                                                    </Button>

                                                    <CapsLock>
                                                        {(isActive) => (
                                                            <Collapse isOpen={isActive}>
                                                                <div className="pt-3">
                                                                    <div className={'alert alert-warning mb-0'}>
                                                                        <i className="mr-1 mdi mdi-apple-keyboard-caps" />
                                                                        <Translate id='warning.capsLock' />
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        )}
                                                    </CapsLock>

                                                </Form>
                                            )}
                                        </Formik>
                                    </Fade>
                                    <div className="text-center pt-3 mt-3">
                                        <span className="text-muted mr-2"><Translate id='signup.header.poweredBy' /></span>
                                        <img src={spordleMyAccount} alt="Spordle MyAccount" height="24" />
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mb-1 text-center">
                                <Translate id='signup.header.alreadyHaveAccount' />
                                <Link to={authenticator.goToPage('LOGIN', true, true)} className={hcrStyles["signup-btn"] + " ml-1"}>
                                    <Translate id='signup.header.signIn' />
                                </Link>
                            </div>
                        </>
                    )}
                </AuthenticatorContext.Consumer>
            </AuthLayout>
        );
    }
}

export default WithHcrTheme(Signup);
