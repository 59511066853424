import Translate from "@spordle/intl-elements";
import moment from "moment";
import { Card } from "reactstrap";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import Dot from "../../../../../components/Dot";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const QualificationCard = ({ qualification }) => {
    const qualificationData = qualification.qualification;

    if(!qualificationData){
        return null;
    }

    const getStatus = () => {
        if(qualification.expiration_date && moment(qualification.expiration_date).isBefore(moment().add(1, 'day'))){
            return 'expired'
        }else if(qualification.taken_date && moment(qualification.taken_date).isBefore(moment().add(1, 'day'))){
            return 'certified'
        }
        return 'valid'

    }
    const status = getStatus();

    const displayLocation = () => {
        const returnString = [];

        if(qualification.location){
            returnString.push(qualification.location)
        }
        if(qualification.city){
            returnString.push(qualification.city)
        }
        if(qualification.province){
            returnString.push(qualification.province)
        }

        if(returnString.length === 0){
            return null
        }
        return (
            <div className='small text-muted'>
                {returnString.join(', ')}
            </div>
        )

    }

    return (
        <Card body className={`card-shadow mb-3${status == 'expired' ? ' bg-light' : ''}`}>
            <div className="d-flex align-content-center">
                <div
                    className={`${status == 'valid' ? 'mdi mdi-calendar-clock' : 'ti-medall-alt'} ${status == 'expired' ? 'text-muted' : 'text-dark'} flex-shrink-0 bg-white rounded-circle d-flex justify-content-center align-items-center border mr-3`}
                    style={{ width: 60, height: 60, fontSize: 30 }}
                />
                <div className='d-flex justify-content-between w-100 flex-wrap flex-md-nowrap'>
                    <div>
                        <div className="text-muted text-uppercase small"><Translate id='account.members.qualifications.qualificationCard.season' /> <DisplayI18n field="name" i18n={qualification.period.i18n} defaultValue={qualification.period.name} /></div>
                        <div className="h4 mb-0 font-bold">
                            <Dot color={qualificationData.qualification_category?.color} className="mr-1" />
                            <DisplayI18n
                                field="name"
                                i18n={qualificationData.i18n}
                                defaultValue={qualificationData.name}
                            />
                        </div>
                        <p className="text-dark mb-0">
                            <DisplayI18n
                                field="description"
                                defaultValue={qualificationData.description}
                                i18n={qualificationData.i18n}
                            />
                        </p>
                        <div className="h6 mb-0 mt-2 font-medium d-flex align-items-center">
                            {qualification.certification_number &&
                                <span className='mr-2'>
                                    <Translate values={{ number: <sup>o</sup> }} id='account.members.qualifications.certification.number' />
                                    <span className="font-medium">
                                        {qualification.certification_number}
                                    </span>
                                </span>
                            }
                            <StatusBadge status={qualification.qualification_status?.i18n ? qualification.qualification_status : qualification.member_qualification_status} />
                        </div>
                    </div>
                    <div className='mt-2 mt-md-0 w-100 w-md-auto text-md-right'>
                        { qualification.expiration_date ?
                            <div className="small text-muted"><Translate id='account.members.qualifications.qualificationCard.validFromTo' values={{ from: qualification.taken_date, to: qualification.expiration_date }} /></div>
                            :
                            <div className="small text-muted">
                                <Translate id={`account.members.qualifications.qualificationCard.${moment().isBefore(qualification.taken_date) ? 'willBeValid' : 'validSince'}`} values={{ date: qualification.taken_date }} />
                            </div>
                        }
                        {displayLocation()}
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default QualificationCard;