import sidePanel from "./sidepanel";
import memberProfileTransactions from "./memberProfileTransactions";
import metaMemberSearch from "./metaMemberSearch";

export default{
    "account.transactions.status.confirmed"      : "Confirmé",
    "account.transactions.status.paid"           : "Payé",
    "account.transactions.status.partially_paid" : "Partiel",
    "account.transactions.status.pending"        : "En attente",
    "account.transactions.status.cancelled"      : "Annulé",

    "account.transactions.transaction"                     : "Transaction",
    "account.transactions.transaction.order"               : "Commande",
    "account.transactions.transaction.date"                : "Date de transaction",
    "account.transactions.transaction.registrationFee"     : "Frais d'inscription",
    "account.transactions.transaction.annualMembershipFee" : "Frais d'affiliation",
    "account.transactions.transaction.totalPrice"          : "Prix Total",
    "account.transactions.transaction.balance"             : "Balance",

    "account.transactions.sendWaivers.title"               : "Envoyer les dispenses",
    "account.transactions.sendWaivers.selectWaiver"        : "Dispense(s)",
    "account.transactions.sendWaivers.selectContact"       : "Contacte(s)",
    "account.transactions.sendWaivers.waiversWillBeSendTo" : "Les dispenses seront envoyé à :",
    "account.transactions.sendWaivers.submit"              : "S'inscrire",

    "account.transactions.confirmWaivers.title" : "Accepter les dispenses",
    "account.transactions.confirmWaivers.hint"  : "* Les dispenses sélectionné seront marqué en tant que \"reçu par moi\"",

    "account.transactions.form.status"                     : "Statut",
    "account.transactions.form.status.ONLINE"              : "En ligne",
    "account.transactions.form.status.ADMIN"               : "Manuel",
    "account.transactions.form.status.ONLINE.registration" : "Inscription en ligne",
    "account.transactions.form.status.ADMIN.registration"  : "Inscription manuel",

    "account.transactions.form.confirm"               : "Confirmer",
    "account.transactions.form.waivers"               : "Dispense(s)",
    "account.transactions.form.category"              : "Categorie",
    "account.transactions.form.member"                : "Membre",
    "account.transactions.form.registration"          : "Inscription",
    "account.transactions.form.position"              : "Position du membre",
    "account.transactions.form.memberType"            : "Type de membre",
    "account.transactions.form.registrationDate"      : "Date d'inscription",
    "account.transactions.form.season"                : "Saison",
    "account.transactions.form.wrongOrganization"     : "Oops! Vous devez gérer {organization} pour inscrire ce membre.",
    "account.transactions.form.changeOrganization"    : "Cliquez ici pour changer d'organisation.",
    "account.transactions.form.possibleRegistrations" : "{count} inscriptions possibles",

    "account.transactions.form.signedBy" : "Signée par {name}",

    "account.transactions.table.emptyLayout.noResult" : "Aucune inscription effectuer pour ce meembre pour la période en cours.",

    "account.transactions.error.registrationAgeError" : "Cette inscription ne s'applique pas à ce membre (mauvaise âge)",
    "account.transactions.error.alreadyRegistered"    : "Déjà inscrit",
    "account.transactions.error.soldOut"              : "Épuisé",

    ...memberProfileTransactions,
    ...sidePanel,
    ...metaMemberSearch,
}