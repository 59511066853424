import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Card } from "reactstrap";
import DisplayCategory from "../../../../../components/DisplayCategory";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { SupervisionSpordleTableMobileView } from "../memberCoachHelper";
import AccountMemberCoachSidePanel from "../sidePanel/AccountMemberCoachSidePanel";

const AccountMemberSupervisionsOfficial = ({ member, supervisions }) => {
    const tableRef = useRef();
    const { getGenericLocale } = useContext(I18nContext);

    useEffect(() => {

        if(supervisions){
            tableRef.current?.refreshTable();
        }else{
            tableRef.current?.setLoading();
        }

    }, [ supervisions ])

    return (
        <>
            <Card body className='card-shadow'>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0"><Translate id='account.members.coach.myReports' /></div>
                </div>
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='supervision_id'
                    sidePanel={(sProps) => <AccountMemberCoachSidePanel mode="official" {...sProps} />}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            tableHover
                            bordered
                            striped
                            clickable
                            ref={(r) => {
                                panelProps.spordleTableRef(r);
                                tableRef.current = r;
                            }}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            id='memberSupervisions'
                            pagination={10}
                            desktopWhen="md"
                            dataIndex='supervision_id'
                            loadingMessage={
                                <>
                                    <Skeleton height={55} />
                                    <Skeleton height={92} count={3} />
                                </>
                            }
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                        return Promise.resolve(supervisions);
                                    case 'CDM':
                                        return Promise.resolve([]);
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={[
                                'supervision_date',
                                'home_team.short_name',
                                'away_team.short_name',
                                `period.${getGenericLocale()}.name`,
                                `officiating_coach.first_name`,
                                `officiating_coach.last_name`,
                                `team_category.division.i18n.${getGenericLocale()}.short_name`,
                                `team_category.class.i18n.${getGenericLocale()}.short_name`,
                                'home_team_name',
                                'away_team_name',
                            ]}
                            columns={[
                                {
                                    label: <Translate id='account.members.coach.season.label' />,
                                    key: 'period',
                                    sortKey: `period.${getGenericLocale()}.name`,
                                    fallbackSortKey: 'period.name',
                                    sortable: true,
                                },
                                {
                                    label: 'Date',
                                    key: 'supervision_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.officiatingCoach.label' />,
                                    key: 'officiating_coach',
                                    sortKey: 'official.first_name',
                                    fallbackSortKey: 'official.last_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.teamCategory.label' />,
                                    key: 'category',
                                    sortKey: `team_category.division.i18n.${getGenericLocale()}.short_name`,
                                    fallbackSortKey: 'team_category.division.short_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.homeTeam.label' />,
                                    key: 'home_team',
                                    sortKey: 'home_team.short_name',
                                    fallbackSortKey: 'home_team_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.awayTeam.label' />,
                                    key: 'away_team',
                                    sortKey: 'away_team.short_name',
                                    fallbackSortKey: 'away_team_name',
                                    sortable: true,
                                },
                            ]}
                            renderRow={(columnKey, supervision) => {
                                switch (columnKey){
                                    case 'period':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                i18n={supervision.period.i18n}
                                                defaultValue={supervision.period.name}
                                            />
                                        )
                                    case 'supervision_date':
                                        return <div className="text-nowrap">{supervision.supervision_date}</div>
                                    case 'officiating_coach':
                                        return (
                                            <>
                                                <div>{`${supervision.officiating_coach?.first_name} ${supervision.officiating_coach?.last_name}`}</div>
                                                <div className="text-muted small">#{supervision.officiating_coach?.unique_identifier}</div>
                                            </>
                                        )
                                    case 'category':
                                        return <DisplayCategory category={supervision.team_category} short />
                                    case 'away_team':
                                        return supervision.away_team?.short_name || supervision.away_team_name || "-";
                                    case 'home_team':
                                        return supervision.home_team?.short_name || supervision.home_team_name || "-";
                                    default:
                                        break;
                                }
                            }}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                            emptyLayout={({ emptySearch }) => (
                                <div className="text-center py-2">
                                    <i className="mdi mdi-book-open-page-variant fa-5x" />
                                    <div className="font-bold h5 text-body"><Translate id='account.members.coach.allMemberReports.noReports.title' /></div>
                                    <p className="text-muted">
                                        <Translate id={`account.members.coach.allMemberReports.noReports.${emptySearch ? 'search.' : ''}subtitle`} />
                                    </p>
                                </div>
                            )}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className='mb-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div className="w-100 w-sm-auto mr-2">
                                                <SpordleTableProvider.SearchInput />
                                            </div>
                                            <div className='d-flex ml-auto text-right'>
                                                <SpordleTableProvider.Refresh />
                                            </div>
                                        </div>
                                    </div>
                                    {spordleTable.isMobile() ?
                                        <SupervisionSpordleTableMobileView spordleTable={spordleTable} panelProps={panelProps} />
                                        :
                                        <SpordleTableView />
                                    }
                                </>
                            )}
                        </SpordleTableProvider>
                    )}
                />
            </Card>
        </>
    );
}

export default AccountMemberSupervisionsOfficial;