import Translate from "@spordle/intl-elements";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { I18nContext } from "../../../contexts/I18nContext";
import UserDisplay from "../../userDisplay/UserDisplay";
import UserImg from "../../UserImg";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

const MemberHasUnsignedWaivers = ({ data, changeMember, closeModal }) => {
    const history = useHistory();

    return (
        <>
            <ModalBody className="text-center">
                <UserDisplay card>
                    <UserDisplay.Container>
                        <UserImg
                            abbr={data.first_name.charAt(0) + data.last_name.charAt(0)}
                            src={null}
                            alt={data.first_name + ' ' + data.last_name}
                            height={40}
                            width={40}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>{data?.first_name} {data?.last_name}</UserDisplay.Title>
                        <I18nContext.Consumer>
                            {({ getGenericLocale }) => <UserDisplay.Subtitle>{moment(data?.birthdate).locale(getGenericLocale()).format('LL')} (<Translate id='misc.yearsOld' values={{ age: moment().diff(data?.birthdate, 'years') }} />)</UserDisplay.Subtitle>}
                        </I18nContext.Consumer>
                    </UserDisplay.Container>
                </UserDisplay>
                <div className="h5 font-medium mb-4"><Translate id='addMemberModal.unsignedWaivers' /></div>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    className="ml-auto"
                    color="primary"
                    onClick={() => {
                        if(changeMember){ // from members page
                            changeMember(data)
                            history.push(`#waivers-open`)
                        }else{ // from identity roles page
                            history.push(queryString.stringifyUrl({
                                url: '/members',
                                fragmentIdentifier: 'waivers-open',
                                query: {
                                    metaMemberId: data.meta_member_id,
                                },
                            }))
                        }
                        closeModal();
                    }}
                >
                    <Translate id='addMemberModal.goToSign' />
                </Button>
            </ModalFooter>
        </>
    )
}

export default MemberHasUnsignedWaivers;