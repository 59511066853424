export default{
    "members.profile.suspensions.title" : "Suspensions",

    // fields
    "members.profile.suspensions.infraction"             : "Infraction",
    "members.profile.suspensions.infraction.existing"    : "Existing Infraction",
    "members.profile.suspensions.infraction.other"       : "Other Infraction",
    "members.profile.suspensions.organization"           : "Organization",
    "members.profile.suspensions.penaltyType"            : "Penalty Type",
    "members.profile.suspensions.suspensionType"         : "Suspension Type",
    "members.profile.suspensions.duration"               : "Duration",
    "members.profile.suspensions.duration.definite"      : "Definite",
    "members.profile.suspensions.duration.indefinite"    : "Indefinite",
    "members.profile.suspensions.duration.games"         : "{games} Games",
    "members.profile.suspensions.duration.numberOfGames" : "Number of games",
    "members.profile.suspensions.endDate"                : "End Date",
    "members.profile.suspensions.incidentDate"           : "Incident Date",
    "members.profile.suspensions.receivedDate"           : "Received Date",
    "members.profile.suspensions.location"               : "Incident Location",
    "members.profile.suspensions.status"                 : "Status",
    "members.profile.suspensions.status.active"          : "Active",
    "members.profile.suspensions.status.expired"         : "Expired",
    "members.profile.suspensions.team"                   : "Team",
    "members.profile.suspensions.position"               : "Position",
    "members.profile.suspensions.opposingTeamName"       : "Opposing Team Name",
    "members.profile.suspensions.notes"                  : "Notes",
    "members.profile.suspensions.attachments"            : "Attachments",
    "members.profile.suspensions.created_date"           : "Created Date",
    "members.profile.suspensions.submitted_by"           : "Submitted by",
    "members.profile.suspensions.submitted_email"        : "Submitted by Email",

    // validation
    "members.profile.suspensions.infraction.required"         : "Infraction is required",
    "members.profile.suspensions.penaltyType.required"        : "Penalty type is required",
    "members.profile.suspensions.games.required"              : "Number of games is required",
    "members.profile.suspensions.endDate.required"            : "End date is required",
    "members.profile.suspensions.endDate.afterReceived"       : "End date must be after the received date",
    "members.profile.suspensions.incidentDate.required"       : "Incident date is required",
    "members.profile.suspensions.incidentDate.afterToday"     : "Incident date cannot be after today",
    "members.profile.suspensions.incidentDate.beforeReceived" : "Incident date cannot be after the reception date",
    "members.profile.suspensions.receivedDate.required"       : "Received date is required",
    "members.profile.suspensions.receivedDate.afterIncident"  : "Received date cannot be before the incident date",
    "members.profile.suspensions.location.required"           : "Incident location is required",
    "members.profile.suspensions.team.required"               : "Team is required",
    "members.profile.suspensions.position.required"           : "Position is required",

    // table

    // add modal
    "members.profile.suspensions.add.title" : "Add suspension",

    // sidepanel
    "members.profile.suspensions.sidepanel.title" : "Suspended on",
    "members.profile.suspensions.sidepanel.PDF"   : "Export PDF",

    // delete suspension modal
    "members.profile.suspensions.delete.message" : "You are about to delete a suspension for this player. This action is irreversible.",
}