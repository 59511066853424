import { useContext, useState } from 'react';
import { Alert, Button, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import { FormikInputText } from '@spordle/formik-elements';
import * as Yup from 'yup';
import Translate from '@spordle/intl-elements';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';

const AccountPassword = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };
    return (
        <>
            <ListGroupItemLayout
                isLast
                title={<Translate id='form.fields.password' />}
                value='**********'
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountPassword' isOpen={isOpen}>
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.password' />
                </ModalHeader>
                <Formik
                    initialValues={{
                        password: '',
                        passwordConfirm: '',
                        oldPassword: '',
                    }}
                    validationSchema={Yup.object().shape({
                        oldPassword: Yup.string().required(<Translate id='account.settings.profile.accountPassword.update.field.validation.currentPwd.required' />).min(6, <Translate id='form.fields.password.length.min' />),
                        password: Yup.string().cognitoPassword(),
                        passwordConfirm: Yup.string()
                            .test('passwordConfirm-match', <Translate id='signup.signupForm.validation.passwordConfirm.match' />, function(value){
                                return this.parent.password === value;
                            }),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus()
                        auth.submitNewPassword(values.oldPassword, values.password)
                            .then(() => {
                                success({ msg: 'account.settings.profile.accountPassword.update.toast.success' });
                                toggleModal();
                            })
                            .catch((error) => {
                                switch (error.message){
                                    case 'NotAuthorizedException':
                                        setFieldError('oldPassword', <Translate id='account.settings.profile.accountPassword.update.field.validation.currentPwd.noMatch' />)
                                        break;
                                    case 'InvalidPasswordException':
                                        setFieldError('password', <Translate id='account.settings.profile.accountPassword.update.field.validation.newPwd.apiNoMatch' />)
                                        break;

                                    default:
                                        setStatus(error.message)
                                        break;
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <FormikInputText name='oldPassword' label='account.settings.profile.accountPassword.update.field.label.currentPwd' type='password' autoComplete='current-password' />
                                </FormGroup>
                                <FormGroup>
                                    <FormikInputText name='password' label='signup.signupForm.label.password' type='password' autoComplete='new-password' />
                                </FormGroup>
                                <FormGroup>
                                    <FormikInputText name='passwordConfirm' label='signup.signupForm.label.passwordConfirm' type='password' autoComplete='new-password' />
                                </FormGroup>
                                {formik.status &&
                                    <Alert color='danger'><Translate id={`login.error.${formik.status}`} /></Alert>
                                }
                                <div className='d-flex align-items-center bg-light-inverse p-1 mt-4 rounded'>
                                    <i className='text-primary mdi mdi-information-outline mx-2' />
                                    <div className='small'>
                                        <Translate id='account.settings.profile.accountPassword.tip' />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    );
}

export default AccountPassword;