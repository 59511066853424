import { FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { mixed, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import { fail } from '@spordle/toasts';
import { GameIncidentsContext } from "../../../../../../../contexts/GameIncidentsContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import BtnDisplay from "../../btnDisplay/BtnDisplay";
import GameIncidentAddOfficial from "./components/GameIncidentAddOfficial";
import GameIncidentOfficialCard from "./components/GameIncidentOfficialCard";

const GameIncidentInfoTab = (props) => {
    const { partiallyUpdateGameIncidentOfficial, deleteGameIncidentOfficial } = useContext(GameIncidentsContext);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const { updateValues, setIsLoading, gameIncident, statuses, syncNewVal, member } = props;

    /**
     * @description Default error catch handler
     * @param {Error} e
     */
    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
            setIsLoading(false);
        }
    }

    const handleOnRefreshTable = (rowValues) => {
        syncNewVal(rowValues);
    }

    /**
     * @description Updates an official
     * @param {string} officialId Official Id
     * @param {object} values Values to send to the api
     * @param {object} [tableValues=values] The data to update the official object with. Will use the values if not specified
     */
    const updateOfficialValues = (officialId, values, tableValues) => {
        setIsLoading(true);
        const updatedOfficialsList = gameIncident.game_incident_officials.map((p) => {
            if(p.game_incident_official_id === officialId){
                return { ...p, ...(tableValues || values) };
            }

            return p;
        });

        partiallyUpdateGameIncidentOfficial(gameIncident.game_incident_id, officialId, values)
            .then(() => {
                syncNewVal({ game_incident_officials: updatedOfficialsList })
            })
            .catch(handleCatch)
    }


    return (
        <div className="pb-5 mb-5">
            <GameIncidentAddOfficial
                gameIncident={gameIncident}
                toggle={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                refreshTable={handleOnRefreshTable}
            />
            <div className="font-bold h5">Info</div>
            {!!gameIncident.submitted_date &&
                <p>
                    <Translate
                        id='account.members.gir.sidePanel.lastUpdated'
                        values={{
                            date: (
                                <span className="font-medium">
                                    <DateFormat format="YYYY-MM-DD HH:mm" value={gameIncident.submitted_date} />
                                </span>
                            ),
                        }}
                        utc
                    />
                </p>
            }
            <Row form>
                <Col xs="6">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='misc.status' /></Label>
                        <FormikEditable
                            noConfirmation
                            id="game_incident_status_id"
                            initialValues={{
                                game_incident_status_id: gameIncident.game_incident_status.game_incident_status_id,
                            }}
                            validationSchema={object().shape({
                                game_incident_status_id: string().required(<Translate id='account.members.gir.addModal.validation.status' />),
                            })}
                            onSubmit={({ game_incident_status_id }) => {
                                if(game_incident_status_id !== gameIncident.game_incident_status.game_incident_status_id){
                                    const statusObj = statuses.find((s) => s.game_incident_status_id === game_incident_status_id);
                                    updateValues({ game_incident_status_id: game_incident_status_id }, { game_incident_status: statusObj });
                                }
                            }}
                        >
                            {(isEditing, editableOptions) => {
                                if(!isEditing){
                                    return (
                                        <div>
                                            <DisplayI18n
                                                field="name"
                                                i18n={gameIncident.game_incident_status.i18n}
                                                defaultValue={gameIncident.game_incident_status.name}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        id='game_incident_status_id' name='game_incident_status_id'
                                        autoFocus menuIsDefaultOpen search={false}
                                        options={statuses?.map((stat) => ({
                                            i18n: stat.i18n,
                                            label: stat.name,
                                            value: stat.game_incident_status_id,
                                        }))}
                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                        onOptionSelected={editableOptions.stopEditing}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='account.members.gir.addModal.label.date' /></Label>
                        <FormikEditable
                            id="game_incident_date"
                            initialValues={{
                                game_incident_date: moment(gameIncident.game_incident_date),
                            }}
                            validationSchema={object().shape({
                                game_incident_date: mixed().test({
                                    name: 'isValid',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: moment.isMoment,
                                }).test({
                                    name: 'cannotBeFuture',
                                    message: 'Please set a date that is not in the future',
                                    test: function(date){
                                        return !!date || moment(date).isSameOrBefore(moment());
                                    },
                                }),
                            })}
                            onSubmit={({ game_incident_date }) => {
                                if(game_incident_date.format('YYYY-MM-DD') !== gameIncident.game_incident_date){
                                    updateValues({ game_incident_date: game_incident_date.format('YYYY-MM-DD') });
                                }
                            }}
                        >
                            {(isEditing, _options) => {
                                if(!isEditing){
                                    return (
                                        <div><DateFormat value={gameIncident.game_incident_date} utc /></div>
                                    )
                                }
                                return (
                                    <FormikDateTime
                                        name="game_incident_date"
                                        id="game_incident_date"
                                        isValidDate={(current) => current.isSameOrBefore(moment(), 'days')}
                                        timeFormat={false}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='account.members.gir.addModal.label.league' /></Label>
                        <FormikEditable
                            id="league_name"
                            initialValues={{
                                league_name: gameIncident.league_name || '',
                            }}
                            validationSchema={object().shape({
                                league_name: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.league_name !== gameIncident.league_name){
                                    updateValues(values);
                                }
                            }}
                        >
                            {(isEditing, _options) => {
                                if(!isEditing){
                                    return (
                                        <div>{gameIncident.league_name || '-'}</div>
                                    )
                                }
                                return <FormikInputText trim name="league_name" />

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='account.members.gir.addModal.label.location' /></Label>
                        <FormikEditable
                            id="location_name"
                            initialValues={{
                                location_name: gameIncident.location_name || '',
                            }}
                            validationSchema={object().shape({
                                location_name: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.location_name !== gameIncident.location_name){
                                    updateValues(values);
                                }
                            }}
                        >
                            {(isEditing, _options) => {
                                if(!isEditing){
                                    return (
                                        <div>{gameIncident.location_name || '-'}</div>
                                    )
                                }
                                return <FormikInputText trim name="location_name" />

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col xs="6">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='account.members.gir.addModal.label.game' /></Label>
                        <FormikEditable
                            id="game_incident_memo"
                            initialValues={{
                                game_number: gameIncident.game_number || '',
                            }}
                            validationSchema={object().shape({
                                game_number: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.game_number !== gameIncident.game_number){
                                    updateValues(values);
                                }
                            }}
                        >
                            {(isEditing, _options) => {
                                if(!isEditing){
                                    return (
                                        <div>{gameIncident.game_number || '-'}</div>
                                    )
                                }
                                return <FormikInputText trim name="game_number" />

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col xs="12">
                    <FormGroup>
                        <Label className="text-muted mb-1"><Translate id='account.members.gir.addModal.label.note' /></Label>
                        <FormikEditable
                            id="game_incident_memo"
                            initialValues={{
                                game_incident_memo: gameIncident.game_incident_memo || '',
                            }}
                            validationSchema={object().shape({
                                game_incident_memo: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.game_incident_memo !== gameIncident.game_incident_memo){
                                    updateValues(values);
                                }
                            }}
                        >
                            {(isEditing, _options) => {
                                if(!isEditing){
                                    return (
                                        <div>{gameIncident.game_incident_memo || '-'}</div>
                                    )
                                }
                                return <FormikTextArea trim name="game_incident_memo" />

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>
            <div className="font-bold h5">
                <Translate id='account.members.gir.addModal.steps.officials.title' />
            </div>
            <BtnDisplay
                onClick={() => setModalIsOpen(true)}
                icon="addMember"
                svgProps={{ viewBox: "0 0 50 50" }}
                title={<Translate id='account.members.gir.addModal.officials.title' />}
                subtitle={<Translate id='account.members.gir.addModal.officials.btn.subtitle' />}
            />
            {gameIncident.game_incident_officials?.map((official, index) => (
                <GameIncidentOfficialCard
                    key={official.game_incident_official_id}
                    index={index}
                    member={member}
                    official={official}
                    handleDelete={() => {
                        setIsLoading(true);
                        deleteGameIncidentOfficial(gameIncident.game_incident_id, official.game_incident_official_id)
                            .then(() => {
                                syncNewVal({
                                    game_incident_officials: gameIncident.game_incident_officials.filter((o) => o.game_incident_official_id !== official.game_incident_official_id),
                                })
                            })
                            .catch((e) => {
                                if(!AxiosIsCancelled(e.message)){
                                    setIsLoading(false);
                                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                                }
                            })
                    }}
                    {...props}
                    updateValues={updateOfficialValues}
                />
            ))}
        </div>
    );
}

export default GameIncidentInfoTab;