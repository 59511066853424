import PropTypes from 'prop-types';

const Svg = (props) => {

    return (
        <>
            <svg className={props.className} fill="currentColor" width={props.width ?? "35"} height={props.height ?? "25"} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox={props.viewBox ?? "0 0 35 25"}>
                {getSvgPart(props.icon)}
            </svg>
        </>
    );
};

export default Svg;

Svg.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
};


const getSvgPart = (icon) => {
    switch (icon){
        case 'changeOrganization':
            return (
                <g>
                    <path d="M9.1,8.8h13.7l-1.7,1.3c-0.5,0.3-0.6,1-0.2,1.5c0,0,0,0,0,0c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2
                        l4.2-3.2c0.5-0.4,0.6-1,0.2-1.5C26.7,7,26.7,7,26.6,6.9l-4.1-3.2c-0.4-0.4-1.1-0.4-1.5,0s-0.4,1.1,0,1.5c0,0,0.1,0.1,0.2,0.1l0,0
                        l1.7,1.3H9.1C8.5,6.6,8,7.1,7.9,7.7s0.4,1.1,1,1.2C8.9,8.8,9,8.8,9.1,8.8z"
                    />
                    <path
                        opacity="0.25" d="M25.9,16.2H12.2l1.7-1.3c0.4-0.4,0.5-1.1,0.1-1.5
                        c-0.3-0.4-0.9-0.5-1.4-0.2l-4.2,3.2c-0.5,0.4-0.6,1-0.2,1.5C8.3,18,8.3,18,8.4,18.1l4.1,3.2c0.2,0.1,0.4,0.2,0.6,0.2
                        c0.3,0,0.6-0.1,0.8-0.4c0.4-0.5,0.3-1.1-0.2-1.5l-1.7-1.3h13.8c0.6,0.1,1.1-0.4,1.2-1c0.1-0.6-0.4-1.1-1-1.2
                        C26.1,16.2,26,16.2,25.9,16.2z"
                    />
                </g>
            )
        case 'dashboard':
            return (
                <>
                    <g>
                        <g>
                            <rect x="15.5" y="14.5" opacity="0.25" width="4" height="7" />
                            <path
                                opacity="0.25" d="M25.9,10.7l-7.7-7.9c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-7.7,7.9c-0.4,0.4-0.6,0.9-0.6,1.4
                                v8.4c0,1.1,0.8,1.9,1.9,2h3.1v-9c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v9h3.1c1.1-0.1,1.9-0.9,1.9-2v-8.4
                                C26.5,11.6,26.3,11.1,25.9,10.7z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M25.9,10.7l-7.7-7.9c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-7.7,7.9c-0.4,0.4-0.6,0.9-0.6,1.4v8.4
                                c0,1.1,0.8,1.9,1.9,2h14.2c1.1-0.1,1.9-0.9,1.9-2v-8.4C26.5,11.6,26.3,11.1,25.9,10.7z M15.5,20.5v-6h4v6H15.5z M24.5,20.5h-3v-7
                                c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v7h-3v-8.4l7-7.2l7,7.2V20.5z"
                            />
                        </g>
                    </g>
                </>
            );
        case 'organisation':
            return (
                <>
                    <path d="M26.7,14.6h-1.8V13c0-0.9-0.7-1.5-1.5-1.6h-4.7v-1h1.9c0.7,0,1.3-0.6,1.3-1.3V3.3c0-0.7-0.6-1.3-1.3-1.3
                        h-5.8c-0.7,0-1.3,0.6-1.3,1.3v5.8c0,0.7,0.6,1.3,1.3,1.3h1.8v1h-4.7c-0.9,0-1.6,0.7-1.6,1.6c0,0,0,0,0,0v1.6H8.3
                        c-0.7,0-1.3,0.6-1.3,1.3c0,0,0,0,0,0v5.8C7,22.4,7.6,23,8.3,23c0,0,0,0,0,0h5.8c0.7,0,1.3-0.6,1.3-1.3v-5.8c0-0.7-0.6-1.3-1.3-1.3
                        h-1.9v-1h10.5v1h-1.9c-0.7,0-1.3,0.6-1.3,1.3v5.8c0,0.7,0.6,1.3,1.3,1.3h5.8c0.7,0,1.3-0.6,1.3-1.3v-5.8
                        C28,15.2,27.4,14.6,26.7,14.6z M13.3,16.7v4.2H9.1v-4.2H13.3z M15.4,8.3V4.1h4.2v4.2L15.4,8.3z M25.9,20.9h-4.2v-4.2h4.2V20.9z"
                    />
                    <rect x="15.4" y="4.1" opacity="0.25" width="4.2" height="4.2" />
                    <rect x="9.1" y="16.7" opacity="0.25" width="4.2" height="4.2" />
                    <rect x="21.7" y="16.7" opacity="0.25" width="4.2" height="4.2" />
                </>
            )
        case 'members':
            return (
                <>
                    <g opacity="0.25">
                        <path d="M17.5,11.5c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S15.3,11.5,17.5,11.5z" />
                        <path d="M23.5,21.5c0.6,0,1-0.4,1-1c0-3.9-3.1-7-7-7s-7,3.1-7,7c0,0.6,0.4,1,1,1H23.5z" />
                    </g>
                    <path d="M17.5,11.5c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S15.3,11.5,17.5,11.5z M17.5,5.5c1.1,0,2,0.9,2,2
                        s-0.9,2-2,2s-2-0.9-2-2S16.4,5.5,17.5,5.5z"
                    />
                    <path d="M24.5,20.5v0.6c0,0.2-0.2,0.4-0.4,0.4h-1.2c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0.1-2.8-2.2-5.1-5-5.2
                        c-0.2,0-0.3,0-0.5,0c-2.6,0.2-4.6,2.4-4.6,5v0.6c0,0.2-0.2,0.4-0.4,0.4h-1.2c-0.2,0-0.4-0.2-0.4-0.4v-0.6c0-3.9,3.1-7,7-7
                        S24.5,16.6,24.5,20.5z"
                    />
                </>
            )
        case 'teams':
            return (
                <>
                    <g opacity="0.25">
                        <path d="M14.6,11.5c2.1,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9v0C10.7,9.8,12.4,11.5,14.6,11.5z" />
                        <path d="M22.4,13.5c1.6,0,2.9-1.3,2.9-2.9S24,7.6,22.4,7.6s-2.9,1.3-2.9,2.9c0,0,0,0,0,0
                            C19.4,12.2,20.7,13.5,22.4,13.5C22.4,13.5,22.4,13.5,22.4,13.5z"
                        />
                        <path d="M26.2,20.3c0.6,0,1-0.4,1-1c0-2.7-2.2-4.9-4.9-4.9c-1.1,0-2.1,0.4-3,1c-2.6-2.7-7-2.7-9.6,0
                            c-1.3,1.3-2,3-2,4.9c0,0.6,0.4,1,1,1h11.6c0.6,0,1-0.4,1-1"
                        />
                    </g>
                    <path d="M14.5,11.5c2.1,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.1,0-3.9,1.7-3.9,3.9c0,0,0,0,0,0
                        C10.6,9.8,12.4,11.5,14.5,11.5z M14.5,5.7c1.1,0,1.9,0.9,1.9,1.9s-0.9,1.9-1.9,1.9s-1.9-0.9-1.9-1.9l0,0C12.6,6.6,13.5,5.7,14.5,5.7
                        z"
                    />
                    <path d="M22.3,13.5c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,0,0,0,0,0
                        C19.4,12.2,20.7,13.5,22.3,13.5z M22.3,9.6c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S21.7,9.6,22.3,9.6z"
                    />
                    <path d="M22.4,14.4c-1.1,0-2.1,0.3-3,1c-2.7-2.6-7-2.6-9.6,0.1c-1.2,1.3-1.9,3-1.9,4.7v0.6c0,0.2,0.2,0.4,0.4,0.4
                        c0,0,0,0,0,0h1.2c0.2,0,0.4-0.2,0.4-0.4v-0.6c-0.1-2.7,2-5,4.6-5.1s5,2,5.1,4.6c0,0.1,0,0.3,0,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4H21
                        c0.2,0,0.4-0.2,0.4-0.4v0v-0.6c0-1.1-0.3-2.3-0.8-3.3c1.3-1,3.1-0.8,4.1,0.5c0,0,0,0,0,0c0.4,0.5,0.6,1.1,0.6,1.8v0.6
                        c0,0.2,0.2,0.4,0.4,0.4h1.2c0.2,0,0.4-0.2,0.4-0.4v0v-0.6C27.2,16.6,25,14.4,22.4,14.4z"
                    />
                </>
            )
        case 'clinics':
            return (
                <>
                    <path d="M23.7,20.7l-2.2-8.5l0.1-0.1c1.8-2.3,1.4-5.6-0.8-7.4s-5.6-1.4-7.4,0.8c-0.7,0.9-1.1,2.1-1.1,3.3
                        c0,1.2,0.4,2.4,1.2,3.3l0.1,0.1l-2.2,8.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1l5.1-3
                        l5.4,3c0.1,0.1,0.2,0.1,0.3,0.1l0,0c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0C23.7,20.8,23.7,20.7,23.7,20.7z M13.5,8.8c0-2.2,1.8-4,4-4
                        s4,1.8,4,4s-1.8,4-4,4S13.5,11,13.5,8.8z M17.8,16.9c-0.3-0.2-0.6-0.2-0.9,0l-3.5,2l1.3-5.3c1.7,1,3.8,1,5.5,0l1.4,5.4L17.8,16.9z"
                    />
                    <g opacity="0.25">
                        <path d="M23.7,20.7l-2.2-8.5l0.1-0.1c1.8-2.3,1.4-5.6-0.8-7.4s-5.6-1.4-7.4,0.8c-0.7,0.9-1.1,2.1-1.1,3.3
                            c0,1.2,0.4,2.4,1.2,3.3l0.1,0.1l-2.2,8.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1l5.1-3
                            l5.4,3c0.1,0.1,0.2,0.1,0.3,0.1l0,0c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0C23.7,20.8,23.7,20.7,23.7,20.7z M13.5,8.8c0-2.2,1.8-4,4-4
                            s4,1.8,4,4s-1.8,4-4,4S13.5,11,13.5,8.8z M17.8,16.9c-0.3-0.2-0.6-0.2-0.9,0l-3.5,2l1.3-5.3c1.7,1,3.8,1,5.5,0l1.4,5.4L17.8,16.9z"
                        />
                        <path d="M23.7,20.7l-2.2-8.5l0.1-0.1c1.8-2.3,1.4-5.6-0.8-7.4s-5.6-1.4-7.4,0.8c-0.7,0.9-1.1,2.1-1.1,3.3
                            c0,1.2,0.4,2.4,1.2,3.3l0.1,0.1l-2.2,8.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1l5.1-3
                            l5.4,3c0.1,0.1,0.2,0.1,0.3,0.1l0,0c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0C23.7,20.8,23.7,20.7,23.7,20.7z M13.5,8.8c0-2.2,1.8-4,4-4
                            s4,1.8,4,4s-1.8,4-4,4S13.5,11,13.5,8.8z M17.8,16.9c-0.3-0.2-0.6-0.2-0.9,0l-3.5,2l1.3-5.3c1.7,1,3.8,1,5.5,0l1.4,5.4L17.8,16.9z"
                        />
                        <path d="M21.6,19l-3.8-2.1c-0.3-0.2-0.6-0.2-0.9,0l-3.5,2l1.3-5.3c1.7,1,3.8,1,5.5,0L21.6,19z" />
                        <path d="M21.5,8.8c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4S21.5,6.6,21.5,8.8z" />
                    </g>
                </>
            )
        case 'leagues':
            return (
                <>
                    <path
                        opacity="0.25" d="M25.6,6.1H9.4c-0.4,0-0.7,0.3-0.8,0.8v11.3
                        c0,0.4,0.3,0.7,0.8,0.8h16.3c0.4,0,0.7-0.3,0.8-0.8V6.8C26.4,6.4,26.1,6.1,25.6,6.1z M11.5,16.1V8.9c0-0.2,0.2-0.4,0.3-0.4h0h1.3
                        c0.2,0,0.4,0.2,0.4,0.3v0v7.3c0,0.2-0.2,0.4-0.3,0.4h0h-1.3C11.7,16.5,11.5,16.3,11.5,16.1L11.5,16.1L11.5,16.1z M16.8,13.5h1.4
                        c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0v1.4c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0h-1.4c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0v-1.4
                        C16.5,13.6,16.6,13.5,16.8,13.5C16.8,13.5,16.8,13.5,16.8,13.5L16.8,13.5z M16.5,11.2V9.8c0-0.2,0.1-0.3,0.3-0.3c0,0,0,0,0,0h1.4
                        c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0v1.4c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0h-1.4C16.6,11.5,16.5,11.4,16.5,11.2
                        C16.5,11.2,16.5,11.2,16.5,11.2L16.5,11.2z M23.5,16.1c0,0.2-0.2,0.4-0.4,0.4l0,0h-1.3c-0.2,0-0.4-0.2-0.4-0.4l0,0V8.9
                        c0-0.2,0.2-0.4,0.4-0.4l0,0h1.3c0.2,0,0.4,0.2,0.4,0.3v0V16.1z"
                    />
                    <path d="M16.8,9.5h1.4c0.2,0,0.3,0.1,0.3,0.3v1.4c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3V9.8
                        C16.5,9.6,16.6,9.5,16.8,9.5z"
                    />
                    <path d="M11.9,8.5h1.3c0.2,0,0.4,0.2,0.4,0.4v7.3c0,0.2-0.2,0.4-0.4,0.4h-1.3c-0.2,0-0.4-0.2-0.4-0.4V8.9
                        C11.5,8.7,11.7,8.5,11.9,8.5z"
                    />
                    <path d="M16.8,13.5h1.4c0.2,0,0.3,0.1,0.3,0.3v1.4c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3v-1.4
                        C16.5,13.6,16.6,13.5,16.8,13.5z"
                    />
                    <path d="M23.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3c0,0.2,0.2,0.4,0.4,0.4l0,0h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9
                        C23.5,8.7,23.3,8.5,23.1,8.5L23.1,8.5z M13.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3c0,0.2,0.2,0.4,0.3,0.4h0h1.3
                        c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9C13.5,8.7,13.3,8.5,13.1,8.5L13.1,8.5z M13.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3
                        c0,0.2,0.2,0.4,0.3,0.4h0h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9C13.5,8.7,13.3,8.5,13.1,8.5L13.1,8.5z M23.1,8.5h-1.3
                        c-0.2,0-0.4,0.2-0.4,0.3v0v7.3c0,0.2,0.2,0.4,0.4,0.4l0,0h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9C23.5,8.7,23.3,8.5,23.1,8.5L23.1,8.5z
                        M13.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3c0,0.2,0.2,0.4,0.3,0.4h0h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9
                        C13.5,8.7,13.3,8.5,13.1,8.5L13.1,8.5z M23.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3c0,0.2,0.2,0.4,0.4,0.4l0,0h1.3
                        c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9C23.5,8.7,23.3,8.5,23.1,8.5L23.1,8.5z M23.1,8.5h-1.3c-0.2,0-0.4,0.2-0.4,0.3v0v7.3
                        c0,0.2,0.2,0.4,0.4,0.4l0,0h1.3c0.2,0,0.4-0.2,0.4-0.4l0,0V8.9C23.5,8.7,23.3,8.5,23.1,8.5L23.1,8.5z"
                    />
                    <path d="M26.5,4.5H8.6C7.7,4.5,7,5.2,7,6.1V19c0,0.8,0.7,1.5,1.6,1.5h17.9c0.8,0,1.5-0.7,1.5-1.5V6.1
                        C28,5.2,27.3,4.5,26.5,4.5z M26.4,18.1c0,0.4-0.3,0.7-0.8,0.8H9.4c-0.4,0-0.7-0.3-0.8-0.8V6.8c0-0.4,0.3-0.7,0.8-0.8h16.3
                        c0.4,0,0.7,0.3,0.8,0.8V18.1z"
                    />
                    <path d="M16.8,9.5h1.4c0.2,0,0.3,0.1,0.3,0.3v1.4c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3V9.8
                        C16.5,9.6,16.6,9.5,16.8,9.5z"
                    />
                    <path d="M16.8,13.5h1.4c0.2,0,0.3,0.1,0.3,0.3v1.4c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3v-1.4
                        C16.5,13.6,16.6,13.5,16.8,13.5z"
                    />
                </>
            )
        case 'tournaments':
            return (
                <>
                    <path d="M28.3,6.9c-0.1-0.4-0.3-0.7-0.5-1c-0.1-0.2-0.3-0.3-0.4-0.4c0,0-0.1-0.1-0.1-0.1C27.1,5.2,27,5.1,26.8,5
                        l-0.3-0.2c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4-0.1-0.9-0.1-1.4,0h-0.2c0,0,0-0.1,0-0.1V4.5l-0.1-0.1c-0.1,0-0.1,0-0.2,0l0,0l-0.1-0.1
                        c0,0-0.1-0.1-0.1-0.1l-0.1-0.1h-0.1h-0.2H11.6h-0.2h-0.1l-0.1,0.1c0,0-0.1,0-0.1,0.1L11,4.3l0,0c0,0-0.1,0.1-0.1,0.1l-0.1,0.1v0.1
                        c0,0,0,0.1,0,0.1h-0.2c-0.4-0.1-0.9-0.1-1.4,0C9,4.8,8.8,4.8,8.6,4.9L8.2,5C8,5.1,7.9,5.2,7.7,5.3c-0.1,0-0.1,0.1-0.1,0.1
                        C7.4,5.6,7.3,5.7,7.2,5.9C7,6.2,6.8,6.5,6.7,6.9c-0.1,0.3-0.2,0.7-0.2,1c0,0.1,0,0.2,0,0.3v0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
                        c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.4c0.2,0.4,0.4,0.8,0.7,1.2l0.2,0.2l0.2,0.2c0.5,0.4,0.9,0.8,1.5,1.1
                        c0.3,0.2,0.6,0.3,0.9,0.5c0.9,0.4,1.9,0.8,2.9,1l0.5,0.1l0.5,0.1l0.3,0.1h0.1h0.1l0,0l0,0l0.3,0.3c0.1,0,0.1,0.1,0.1,0.1l0.1,0.2
                        c0.2,0.2,0.3,0.4,0.3,0.7v0.1l0,0c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.7,1-1.4,2-2.2,3
                        c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l0.1,0.1
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.7,0,1,0h5.4c0.3,0,0.7,0,1,0c0.3,0,0.5-0.1,0.7-0.3l0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1
                        c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.7-1-1.4-2-2.2-3c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.2
                        c0-0.1,0-0.2,0-0.4l0,0v-0.1c0.1-0.2,0.2-0.5,0.3-0.7l0.1-0.2c0-0.1,0.1-0.1,0.1-0.1l0.3-0.3l0,0l0,0c0,0,0.1,0,0.1,0h0l0.3-0.1
                        l0.5-0.1l0.5-0.1c1-0.2,2-0.6,2.9-1c0.3-0.2,0.6-0.3,0.9-0.5c0.5-0.3,0.9-0.7,1.4-1.1l0.2-0.2l0.2-0.2c0.3-0.4,0.5-0.8,0.7-1.2
                        c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2V8.2c0-0.1,0-0.2,0-0.3
                        C28.3,7.6,28.3,7.2,28.3,6.9z M12.6,12.2c-1.6-0.4-3-1.3-4.1-2.5C8.1,9.2,7.9,8.6,7.9,7.9c0-0.8,0.4-1.5,1.1-1.8C9.3,6,9.5,6,9.8,6
                        c0.4,0,0.8,0.1,1.1,0.4c0.4,2.2,1.2,4.3,2.5,6.1L12.6,12.2z M19,13.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.3-0.2,0.7-0.2,1.1v0.1
                        c0,0.6,0.2,1.2,0.5,1.6l0,0l0.7,1l0.2,0.3l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2l0.5,0.8h-5.7l0.5-0.8c0-0.1,0.1-0.1,0.1-0.2l0.1-0.1
                        l0.2-0.3l0.7-1l0,0c0.3-0.5,0.5-1,0.5-1.6v-0.1c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.6-0.6-0.8c-1.8-1.7-3-3.9-3.6-6.3
                        c-0.1-0.3-0.1-0.7-0.2-1L12,5.4h11l-0.1,0.9c-0.1,0.4-0.1,0.7-0.2,1C22,9.7,20.8,11.9,19,13.6z M26.5,9.7c-1.1,1.2-2.5,2.1-4.1,2.5
                        l-0.8,0.3c1.3-1.8,2.1-3.9,2.5-6.1C24.4,6.1,24.8,6,25.2,6c0.3,0,0.5,0.1,0.8,0.2c0.7,0.3,1.2,1,1.1,1.8C27.1,8.6,26.9,9.2,26.5,9.7
                        L26.5,9.7z"
                    />
                    <path
                        opacity="0.25" d="M23,5.4l-0.1,0.9c-0.1,0.4-0.1,0.7-0.2,1
                        c-0.6,2.4-1.8,4.6-3.6,6.3c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.3-0.2,0.7-0.2,1.1v0.1c0,0.6,0.2,1.2,0.5,1.6l0,0l0.7,1l0.2,0.3l0.1,0.1
                        c0,0.1,0.1,0.1,0.1,0.2l0.5,0.8h-5.7l0.5-0.8c0-0.1,0.1-0.1,0.1-0.2l0.1-0.1l0.2-0.3l0.7-1l0,0c0.3-0.5,0.5-1,0.5-1.6v-0.1
                        c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.6-0.6-0.8c-1.8-1.7-3-3.9-3.6-6.3c-0.1-0.3-0.1-0.7-0.2-1L12,5.4H23z"
                    />
                </>
            )
        case 'onlineStore':
            return (
                <>
                    <path d="M10.5,15.4h4.8c0.2,0,0.4,0.2,0.4,0.4v2.8c0,0.2-0.2,0.4-0.4,0.4h0c-2.7,0-5.3,0-8,0c0,0,0,0-0.1,0
                        c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h2.8c-0.8-0.4-1.3-0.9-1.3-1.7c0-3,0-5.9,0-8.9c0-0.8,0.6-1.5,1.3-1.6
                        c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.1h13.2C24,5,24.3,5.1,24.5,5.2c0.5,0.3,0.8,0.8,0.8,1.5H10.5V15.4z"
                    />
                    <path d="M25.5,11.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.6,0-1.1,0-1.6c0-0.9-0.7-1.7-1.7-1.7c-0.6,0-1.3,0-1.9,0
                        c-0.8,0-1.5,0.6-1.6,1.4c-0.1,0.3,0,0.7-0.1,1.1c0,0.3,0,0.6,0,0.9c0,0.1-0.1,0.2-0.2,0.2h-2.5l0,0.1c0,2.3,0,4.6,0,7
                        c0,0.9,0.8,1.7,1.7,1.7c2.4,0,4.8,0,7.2,0c0.8,0,1.3-0.4,1.6-1.1c0-0.1,0.1-0.2,0.1-0.3v-7.1c0-0.1-0.1-0.2-0.2-0.2H25.5z
                        M21.9,10.2h1.7v1.7h-1.7V10.2z M25.9,18.9c-2.1,0-4.2,0-6.3,0c-0.2,0-0.3,0-0.3-0.3c0-1.6,0-3.2,0-4.8c0,0,0-0.1,0-0.2h6.9
                        c0,0.1,0,0.2,0,0.3c0,1.5,0,3.1,0,4.6C26.2,18.8,26.2,18.9,25.9,18.9z"
                    />
                    <path
                        opacity="0.25" d="M26.2,18.6c0,0.2-0.1,0.3-0.3,0.3c-2.1,0-4.2,0-6.3,0c-0.2,0-0.3,0-0.3-0.3
                        c0-1.6,0-3.2,0-4.8c0,0,0-0.1,0-0.2h6.9c0,0.1,0,0.2,0,0.3C26.2,15.5,26.2,17.1,26.2,18.6z"
                    />
                </>
            )
        case 'tasks':
            return (
                <>
                    <path d="M22.1,18.4c0.5-0.3,1-0.8,1.3-1.3C23.1,17.6,22.6,18.1,22.1,18.4z M27.2,20.7L24.5,18
                        c1.5-2.3,0.8-5.4-1.5-6.9s-5.4-0.8-6.9,1.5c-1.5,2.3-0.8,5.4,1.5,6.9c1.6,1.1,3.8,1.1,5.4,0l2.6,2.7c0.4,0.4,1.1,0.3,1.5-0.1
                        C27.5,21.7,27.5,21.1,27.2,20.7L27.2,20.7z M23.4,17.1c-0.3,0.5-0.8,1-1.3,1.3c-1.7,1-3.9,0.4-4.9-1.3s-0.4-3.9,1.3-4.9
                        c1.7-1,3.9-0.4,4.9,1.3C24,14.6,24,16,23.4,17.1z"
                    />
                    <path
                        opacity="0.25" d="M23.9,15.3c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,1-1.3,1.3
                        c-1.7,1-3.9,0.4-4.9-1.3c-1-1.7-0.4-3.9,1.3-4.9c1.7-1,3.9-0.4,4.9,1.3C23.7,14.1,23.9,14.7,23.9,15.3z"
                    />
                    <path d="M14.1,16.3c0,0.2-0.1,0.4-0.3,0.4c0,0-0.1,0-0.1,0H8.1c-0.3,0-0.6-0.3-0.6-0.6l0,0v-13
                        c0-0.3,0.2-0.6,0.6-0.6c0,0,0,0,0,0h3.2V4H9.1C9,4,9,4.1,9,4.1v11.1c0,0.1,0.1,0.1,0.1,0.1l0,0H14C14,15.7,14,16,14.1,16.3z"
                    />
                    <path d="M21.7,3.1v5.7c0,0.2-0.1,0.3-0.3,0.3c0,0-0.1,0-0.1,0C21,9,20.7,9,20.3,9V4.1C20.3,4,20.3,4,20.2,4
                        c0,0,0,0,0,0H18V2.5h3.2C21.5,2.5,21.7,2.8,21.7,3.1z"
                    />
                    <path d="M16.8,2.5V7c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1,0-0.2-0.1l-1.5-1.1c-0.1-0.1-0.3-0.1-0.4,0L13,7.2
                        c-0.1,0.1-0.3,0.1-0.4,0c0-0.1-0.1-0.1-0.1-0.2V2.5H16.8z"
                    />
                    <path d="M16.8,2.5V7c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1,0-0.2-0.1l-1.5-1.1c-0.1-0.1-0.3-0.1-0.4,0L13,7.2
                        c-0.1,0.1-0.3,0.1-0.4,0c0-0.1-0.1-0.1-0.1-0.2V2.5H16.8z"
                    />
                    <path
                        opacity="0.25" d="M20.3,4.1V9h-0.4c-3.2,0.2-5.7,2.7-5.9,5.9c0,0.1,0,0.3,0,0.4
                        H9.1C9,15.3,9,15.3,9,15.2V4.1C9,4.1,9,4,9.1,4h2.2v3c0,0.8,0.7,1.5,1.5,1.5c0.3,0,0.7-0.1,0.9-0.3l0.9-0.7l0.9,0.7
                        c0.7,0.5,1.6,0.4,2.1-0.2C17.9,7.7,18,7.3,18,7V4h2.2C20.3,4,20.3,4.1,20.3,4.1z"
                    />
                </>
            )
        case 'finance':
            return (
                <>
                    <g>
                        <path d="M25.8,11.5h-0.5C25,10,24,8.8,22.6,8.2l0.1-0.4c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.1-0.4,0.2-0.7
                            c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0-0.5-0.1-0.7C23,5,22.8,4.9,22.6,4.9h-0.2c-1.3,0.3-2.4,1.1-3,2.2c0,0,0,0.1,0,0.1L19,7.1
                            c-0.5-0.1-1-0.1-1.5-0.2H17c-0.9,0-1.7,0.1-2.6,0.4c-1.2,0.3-2.4,1-3.4,1.8c-0.6,0.5-1,1.2-1.3,1.9l0,0c-0.1-0.1-0.3-0.2-0.4-0.3
                            c-0.2-0.2-0.3-0.5-0.2-0.8c0-0.2,0.2-0.4,0.4-0.5C9.8,9.3,9.9,9.1,10,8.9c0-0.2-0.1-0.4-0.2-0.6C9.6,8.1,9.5,8,9.3,8
                            C9.1,8,9,8,8.9,8.1c-1,0.5-1.5,1.7-1.1,2.7c0.3,0.7,0.8,1.3,1.5,1.6c0,0.2-0.1,0.4-0.1,0.6c-0.2,1.2,0.1,2.5,0.7,3.5
                            c0.4,0.7,1,1.3,1.6,1.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3,0.7,0,1.5,0.7,1.8c0.5,0.2,1,0.4,1.5,0.6c0.1,0,0.3,0.1,0.5,0.1
                            c0.6,0,1.1-0.4,1.3-1l0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5l0.6,0.1c0.4,0.1,0.8,0.1,1.2,0.1c0.5,0,1.1-0.1,1.6-0.1
                            c0.1,0.3,0.2,0.6,0.3,0.8c0.2,0.5,0.7,0.8,1.2,0.8h0.1c0.1,0,0.2,0,0.2,0l0.3-0.1c0.4-0.1,0.8-0.3,1.2-0.5c0.6-0.2,1-0.9,0.9-1.5
                            c0-0.2-0.1-0.3-0.1-0.5l0-0.1h0c0.5-0.3,1-0.7,1.3-1.1c0.3-0.4,0.6-0.8,0.8-1.3c0.1-0.1,0.1-0.1,0.1-0.1h0.5c0.8,0,1.4-0.6,1.4-1.3
                            c0,0,0-0.1,0-0.1V13c0.1-0.8-0.5-1.4-1.3-1.5C25.9,11.5,25.9,11.5,25.8,11.5z M26.2,14.8c0,0.3-0.1,0.3-0.3,0.3s-0.5,0-0.7,0
                            c-0.4,0-0.7,0.3-0.9,0.6c-0.4,0.9-1.1,1.6-1.9,2.1c-0.4,0.2-0.6,0.7-0.4,1.1c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5s0,0.4-0.2,0.5
                            l-1.2,0.5c-0.2,0.1-0.3,0-0.4-0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.4-0.5-0.6-0.9-0.6c-0.7,0-1.5,0.1-2.2,0.1
                            c-0.4,0-0.8-0.1-1.1-0.2c-0.6-0.1-0.9,0-1.1,0.6c-0.1,0.2-0.2,0.5-0.3,0.8c0,0.2-0.2,0.3-0.4,0.2c0,0-0.1,0-0.1,0L12.5,20
                            c-0.2-0.1-0.3-0.2-0.2-0.5s0.2-0.6,0.3-0.9c0.2-0.4,0-0.9-0.3-1.1c-0.7-0.5-1.3-1.2-1.6-2c-0.2-0.5-0.3-1-0.4-1.5
                            c-0.1-1.1,0.2-2.3,0.8-3.3c0.5-0.7,1.2-1.4,2-1.8c1.5-0.8,3.2-1.1,4.9-0.9c0.5,0,1,0.1,1.5,0.2c0.3,0.1,0.5,0,0.6-0.3
                            c0.3-0.7,0.9-1.2,1.5-1.6l0.2-0.1l0,0l-0.3,1.8c0,0,0,0.1,0,0.1c0,0.5,0,0.6,0.5,0.8c1.2,0.5,2.1,1.5,2.3,2.8
                            c0.1,0.4,0.4,0.7,0.8,0.7h0.7c0.2,0,0.3,0.1,0.3,0.3C26.2,13.6,26.2,14.2,26.2,14.8z"
                        />
                        <path d="M17.4,6.5c-0.2,0-0.3,0-0.3-0.2c-0.1-1.1-1.1-2-2.2-2c-1.1,0-2.1,0.7-2.3,1.8c-0.1,0.4-0.1,0.8,0.1,1.2
                            c0,0.1,0,0.1,0.1,0.2l-0.6,0.3C12,7.8,12,7.7,12,7.6c-0.4-1-0.3-2.1,0.3-2.9c0.7-1,1.9-1.5,3.1-1.3c1.2,0.2,2.2,1.2,2.4,2.4
                            C18,6.4,18,6.4,17.4,6.5z"
                        />
                        <path d="M23,12.6c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8C22.6,11.8,23,12.2,23,12.6z" />
                    </g>
                    <path
                        opacity="0.25" d="M25.8,12.6h-0.7c-0.4,0-0.8-0.3-0.8-0.7
                        c-0.2-1.3-1.1-2.3-2.3-2.8c-0.5-0.2-0.5-0.3-0.5-0.8c0,0,0-0.1,0-0.1l0.3-1.8l0,0l-0.2,0.1c-0.7,0.3-1.2,0.9-1.5,1.5
                        c-0.1,0.3-0.3,0.4-0.6,0.3c-0.5-0.1-1-0.2-1.5-0.2c-1.7-0.2-3.4,0.1-4.8,0.9c-0.8,0.4-1.5,1-2,1.8c-0.6,1-0.9,2.1-0.8,3.3
                        c0,0.5,0.1,1,0.4,1.5c0.4,0.8,1,1.5,1.6,2c0.4,0.2,0.5,0.7,0.3,1.1l-0.3,0.9c-0.1,0.2,0,0.4,0.2,0.5l1.2,0.5c0.1,0.1,0.3,0,0.4-0.1
                        c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.5,0.5-0.7,1-0.7c0.1,0,0.1,0,0.2,0.1C16,19,16.4,19,16.8,19c0.7,0,1.5,0,2.2-0.1
                        c0.4-0.1,0.9,0.1,1,0.5c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.5,0.3,0.7s0.2,0.3,0.5,0.2l1.1-0.5c0.2-0.1,0.3-0.2,0.2-0.5s-0.1-0.3-0.2-0.5
                        c-0.2-0.4-0.1-0.9,0.4-1.1c0,0,0,0,0.1,0c0.9-0.5,1.5-1.2,1.9-2.1c0.1-0.4,0.5-0.6,0.9-0.6c0.2,0,0.5,0,0.7,0s0.3-0.1,0.3-0.3V13
                        C26.2,12.7,26.1,12.6,25.8,12.6z M22.2,13.4c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                        C23,13.1,22.7,13.4,22.2,13.4z"
                    />
                </>
            )
        case 'communications':
            return (
                <>
                    <g>
                        <path d="M22.2,3.9l-0.5-0.1H9.2H9.1C8.2,4,7.6,4.6,7.4,5.5v0.1l0,0v7.8v0.1c0.1,0.4,0.3,0.8,0.5,1.1
                            c0.4,0.4,1,0.7,1.6,0.7h0.1c0.1,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2v1.7c0,0.6,0.4,1,1,1c0.3,0,0.6-0.1,0.8-0.4l1.8-2.1l0.4-0.4
                            c0,0,0.1-0.1,0.1-0.1h7.9c1.2,0,2.1-0.9,2.2-2c0,0,0,0,0-0.1c0-2.4,0-4.8,0-7.2C23.8,4.9,23.1,4.1,22.2,3.9z M22.5,13.1
                            c0,0.4-0.3,0.8-0.7,0.8c0,0-0.1,0-0.1,0h-8.2c-0.3,0-0.5,0.1-0.7,0.3l-1.3,1.5L11,16.3v-1.7c0-0.3-0.2-0.6-0.5-0.6c0,0-0.1,0-0.1,0
                            H9.5c-0.4,0-0.7-0.3-0.8-0.7c0,0,0,0,0-0.1V5.9c0-0.4,0.3-0.7,0.7-0.8c0,0,0,0,0,0h12.2c0.4-0.1,0.8,0.2,0.8,0.6c0,0.1,0,0.1,0,0.2
                            L22.5,13.1z"
                        />
                        <path
                            opacity="0.25" d="M22.5,5.8c-0.1-0.4-0.4-0.6-0.8-0.6H9.5
                            c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v7.2c0,0.4,0.3,0.7,0.7,0.8c0,0,0,0,0.1,0h0.9c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0.1,0,0.1v1.7
                            l0.5-0.5l1.3-1.5c0.2-0.2,0.4-0.3,0.7-0.3h8.2c0.4,0,0.8-0.3,0.8-0.7c0,0,0-0.1,0-0.1V6C22.5,5.9,22.5,5.8,22.5,5.8z M13.6,10.6
                            c-0.5,0-0.9-0.4-0.9-0.8c0,0,0,0,0,0c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.2c0.5,0,0.8,0.3,0.9,0.8c0,0,0,0,0,0.1
                            C14.5,10.2,14.1,10.6,13.6,10.6C13.7,10.6,13.6,10.6,13.6,10.6z M15.6,10.6L15.6,10.6c-0.2,0-0.5-0.1-0.6-0.3
                            c-0.3-0.4-0.3-0.9,0-1.2c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.8,0.3,0.9,0.8c0,0,0,0.1,0,0.1C16.5,10.2,16.1,10.6,15.6,10.6
                            C15.7,10.6,15.6,10.6,15.6,10.6L15.6,10.6z M17.6,10.6c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.5,0.4-0.8,0.8-0.8
                            c0,0,0,0,0,0c0.5,0,0.9,0.4,0.9,0.8c0,0,0,0,0,0C18.5,10.2,18.1,10.6,17.6,10.6C17.6,10.6,17.6,10.6,17.6,10.6L17.6,10.6z"
                        />
                        <path d="M27.5,8.9v7.3c0,1-0.8,1.8-1.8,1.8h-0.4c-0.1,0-0.1,0-0.1,0.1c0,0.5,0,0.9,0,1.4v0.5c0,0.4-0.2,0.7-0.5,0.8
                            c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5-0.1-0.7-0.3l-0.3-0.3c-0.6-0.7-1.2-1.4-1.8-2.1c-0.1-0.1-0.2-0.2-0.3-0.2h-6.8
                            c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.4-0.4-0.3-0.6c0-0.3,0.3-0.5,0.6-0.5h7.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.5,0.8,0.9,1.1,1.3l0.6,0.7
                            v-1.7c0-0.3,0.2-0.6,0.5-0.6c0,0,0.1,0,0.1,0h0.9c0.4,0,0.8-0.3,0.8-0.7c0,0,0,0,0-0.1c0-2.4,0-4.8,0-7.2c0-0.4-0.3-0.8-0.7-0.8
                            c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.6-0.5c0-0.3,0.1-0.5,0.3-0.6C25.4,7,25.6,7,25.7,7h0.1C26.7,7.1,27.5,7.9,27.5,8.9z"
                        />
                        <path d="M18.5,9.7c0,0.5-0.4,0.9-0.9,0.9c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.2-0.4-0.2-0.6
                            c0-0.5,0.4-0.8,0.8-0.8c0,0,0,0,0,0C18,8.9,18.4,9.2,18.5,9.7C18.5,9.7,18.5,9.7,18.5,9.7z"
                        />
                        <path d="M14.5,9.7c0.1,0.5-0.3,0.9-0.8,1c-0.5,0.1-0.9-0.3-1-0.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.4,0.2-0.6
                            c0.2-0.2,0.4-0.3,0.6-0.2C14.1,8.9,14.5,9.2,14.5,9.7C14.5,9.7,14.5,9.7,14.5,9.7z"
                        />
                        <path d="M16.5,9.8c0,0.5-0.4,0.8-0.8,0.8c0,0,0,0,0,0l0,0c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.4-0.3-0.9,0-1.2
                            c0.2-0.1,0.4-0.2,0.6-0.2C16.1,8.9,16.5,9.2,16.5,9.8C16.5,9.7,16.5,9.7,16.5,9.8L16.5,9.8z"
                        />
                    </g>
                </>
            )
        case 'catalog':
            return (
                <>
                    <g>
                        <path d="M26,10.18V5.64a1.6,1.6,0,0,0-.27-.94,1.49,1.49,0,0,0-1.3-.7H21.16V4H18.55a1.56,1.56,0,0,0-1.14.48L12.47,9.44l-.16.17-1,1-.13.13L9.42,12.49A1.34,1.34,0,0,0,9,13.61a1.43,1.43,0,0,0,.46.89l1,1,.69.69,4.34,4.35a1.36,1.36,0,0,0,2,0l.48-.48.25-.26q3.61-3.61,7.24-7.24a1.36,1.36,0,0,0,.44-.83c0-.2,0-.4.05-.6Zm-1.65,1.67-7.68,7.66-.12.13c-2.07-2.05-4.13-4.11-6.22-6.19l.56-.53,5.39-5.39,2-2a.54.54,0,0,1,.36-.16h5.61c.35,0,.39,0,.39.38,0,1.79,0,3.57,0,5.36A1,1,0,0,1,24.35,11.85Z" />
                        <path opacity="0.25" d="M24.66,5.72c0-.34,0-.38-.39-.38H18.66a.54.54,0,0,0-.36.16l-2,2-5.39,5.39-.56.53c2.09,2.08,4.15,4.14,6.22,6.19l.12-.13,7.68-7.66a1,1,0,0,0,.32-.77C24.66,9.29,24.66,7.51,24.66,5.72Zm-1,2.68a2,2,0,1,1-2-2A2,2,0,0,1,23.63,8.4Z" />
                        <path d="M21.59,6.37a2,2,0,1,0,2,2A2,2,0,0,0,21.59,6.37Zm0,3.06a1,1,0,0,1,0-2,1,1,0,1,1,0,2.05Z" />
                    </g>
                </>
            )
        case 'reports':
            return (
                <>
                    <g>
                        <g>
                            <path d="M25.7,13.1c0.2,0,0.3,0.2,0.3,0.4c0,0.2,0,0.4-0.1,0.5c-0.7,4-4.2,7-8.4,7C12.8,21,9,17.2,9,12.5
                                c0-4.2,3-7.7,7-8.4C16.2,4,16.4,4,16.6,4c0.2,0,0.4,0.1,0.4,0.3v1.1c-0.3,0-0.6,0.1-0.9,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
                                c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
                                c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                                c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0.1
                                c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
                                C13.1,7,13,7.1,13,7.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
                                c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1
                                c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.6c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0,0,0.1
                                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                                c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                                c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v0
                                c0,0,0,0.1,0,0.1c0,0.3,0,0.7,0.1,1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                                c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
                                c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1
                                c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                                c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                                c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1
                                c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
                                c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
                                c0.1,0,0.2,0.1,0.2,0.1h0c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                                c0.3,0,0.7,0.1,1,0.1c0,0,0.1,0,0.1,0h0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
                                c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1
                                c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
                                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
                                c0.2-0.2,0.5-0.3,0.7-0.5c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.2
                                c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3
                                c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
                                c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
                                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
                                c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.6,0.1-0.9H25.7z"
                            />
                            <path
                                opacity="0.25" d="M24.5,13.1c0,0.3-0.1,0.6-0.1,0.9c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1
                                c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1
                                c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1
                                c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1
                                c0,0,0,0-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.2
                                c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.2,0.2-0.5,0.3-0.7,0.5c0,0-0.1,0-0.1,0.1
                                c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
                                c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                                c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0
                                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
                                c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0-1-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
                                c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.2,0-0.2-0.1h0c-0.1,0-0.2,0-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0
                                c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
                                c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1
                                c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
                                c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
                                c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1
                                c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                                c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.3-0.1-0.7-0.1-1c0,0,0-0.1,0-0.1v0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
                                c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                                c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                                c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
                                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
                                c0.1-0.2,0.2-0.4,0.4-0.6c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
                                c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1
                                c0.1-0.1,0.1-0.1,0.2-0.1C13,7.1,13.1,7,13.1,7c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
                                c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1
                                c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0v0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
                                c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.4-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.1,0.9-0.1v5.9c0,0.9,0.8,1.7,1.7,1.7H24.5z"
                            />
                        </g>
                    </g>
                    <path d="M26.3,11.6H19c-0.3,0-0.6-0.3-0.6-0.6V3.7c0-0.4,0.3-0.7,0.7-0.6c4,0.7,7.1,3.8,7.8,7.8
                        C27,11.2,26.7,11.6,26.3,11.6z"
                    />

                </>
            );
        case 'addMember':
            return (
                <>
                    <g id="Groupe_3408" data-name="Groupe 3408">
                        <g id="Groupe_3405" data-name="Groupe 3405">
                            <g id="Groupe_3404" data-name="Groupe 3404" opacity={0.25}>
                                <path id="Tracé_777" data-name="Tracé 777" className="cls-2" d="M20.42,23.33a6.67,6.67,0,1,0-6.67-6.67A6.66,6.66,0,0,0,20.42,23.33Z" />
                                <path id="Tracé_778" data-name="Tracé 778" className="cls-2" d="M30.42,40a1.66,1.66,0,0,0,1.66-1.67A11.67,11.67,0,0,0,8.75,37.8v.53A1.67,1.67,0,0,0,10.42,40Z" />
                            </g>
                        </g>
                        <g id="Groupe_3407" data-name="Groupe 3407">
                            <g id="Groupe_3406" data-name="Groupe 3406">
                                <path id="Tracé_779" data-name="Tracé 779" className="cls-2" d="M20.42,23.33a6.67,6.67,0,1,0-6.67-6.67A6.66,6.66,0,0,0,20.42,23.33Zm0-10a3.34,3.34,0,1,1-3.34,3.34h0a3.34,3.34,0,0,1,3.34-3.34Z" />
                            </g>
                        </g>
                        <path id="Tracé_780" data-name="Tracé 780" className="cls-2" d="M32.08,38.33v1a.68.68,0,0,1-.68.68h-2a.69.69,0,0,1-.69-.68h0v-.71A8.44,8.44,0,0,0,20.47,30a6.26,6.26,0,0,0-.75,0,8.34,8.34,0,0,0-7.64,8.3v1a.69.69,0,0,1-.68.69h-2a.69.69,0,0,1-.69-.69h0v-1a11.67,11.67,0,0,1,23.33-.53,4.68,4.68,0,0,1,0,.53Z" />
                        <path id="Tracé_781" data-name="Tracé 781" className="cls-2" d="M39.59,15.83H37.92V14.16a1.67,1.67,0,0,0-3.33,0h0v1.67H32.92a1.67,1.67,0,1,0,0,3.34h1.67v1.66a1.67,1.67,0,1,0,3.33,0h0V19.16h1.67a1.67,1.67,0,0,0,0-3.33Z" />
                    </g>
                </>
            )
        case 'settings':
            return (
                <>
                    <g opacity="0.25">
                        <path d="M13,21.6l-1.6-1.2L13,21.6c0.2,0.2,0.4,0.3,0.7,0.4C13.4,21.8,13.2,21.7,13,21.6z M10.6,17
                            c0.2-0.3,0.3-0.7,0.3-1.1C10.9,16.3,10.8,16.7,10.6,17c-0.6,0.9-0.6,2.1,0.2,2.9C10,19.1,9.9,17.9,10.6,17z M9.3,7.9
                            C9.5,7.7,9.7,7.5,10,7.4C9.7,7.5,9.5,7.7,9.3,7.9z M8.9,8.6l-0.8,2.5C8.1,11.2,8,11.4,8,11.5c0-0.1,0-0.2,0.1-0.4L8.9,8.6L9,8.5
                            C9,8.5,8.9,8.6,8.9,8.6z"
                        />
                    </g>
                    <path d="M25.5,13.7l-0.2,0.1l-0.2,0.1l0.2-0.1L25.5,13.7l0.2-0.1L25.5,13.7z M24.6,16.9l-0.2-0.4
                        c-0.1-0.2-0.2-0.3-0.2-0.5c0,0.2,0.1,0.3,0.2,0.5L24.6,16.9c0.4,0.6,0.5,1.4,0.2,2.1C25.1,18.3,25.1,17.5,24.6,16.9z M22.2,21.4
                        l-0.1,0.1c-0.7,0.5-1.7,0.5-2.4,0C20.5,22,21.5,22,22.2,21.4l1.9-1.4L22.2,21.4z M17.6,20c-0.5,0-1.1,0.3-1.4,0.7L16,21
                        c-0.3,0.4-0.7,0.8-1.2,0.9c0.5-0.1,0.9-0.4,1.2-0.9l0.2-0.3C16.6,20.3,17.1,20,17.6,20c0.4,0,0.7,0.1,1,0.3C18.4,20.1,18,20,17.6,20
                        z M14.1,5.2v0.2c0,0.2,0,0.4-0.1,0.6c0.1-0.2,0.1-0.4,0.1-0.6L14.1,5.2c0-0.2,0-0.3,0-0.5C14,4.9,14,5.1,14.1,5.2z M10.8,15.2
                        L10.7,15c-0.2-0.5-0.5-1-1.1-1.2H9.5c-0.8-0.2-1.4-0.9-1.5-1.7C8.1,13,8.7,13.7,9.5,14h0.2C10.2,14.2,10.6,14.6,10.8,15.2L10.8,15.2
                        c0,0.2,0.1,0.3,0.1,0.4C10.9,15.5,10.8,15.3,10.8,15.2L10.8,15.2z"
                    />
                    <path d="M26.9,10.9l-0.8-2.4V8.4l0,0c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.3C25.4,7.3,24.8,7,24.1,7h-0.3
                        c-0.1,0-0.2,0-0.3,0.1l-0.3,0.1c-0.5,0.2-1.1,0.1-1.5-0.2l-0.1-0.1C21,6.5,20.8,6,20.8,5.5V5.2c0-0.6-0.2-1.2-0.6-1.6
                        c-0.4-0.4-1-0.6-1.5-0.6h-2.4c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.5,0.3-0.8,0.8-0.9,1.4c0,0.2,0,0.3,0,0.5v0.2
                        c0,0.2,0,0.4-0.1,0.6c-0.1,0.3-0.3,0.6-0.6,0.8L13.3,7c-0.5,0.4-1.1,0.5-1.7,0.3c-0.5-0.2-1.1-0.1-1.6,0.1C9.7,7.5,9.5,7.7,9.3,7.9
                        l0,0C9.3,8,9.2,8,9.2,8.1L9,8.3v0.2L8.9,8.7l-0.8,2.5c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
                        c0.1,0.8,0.7,1.5,1.5,1.7h0.1c0.5,0.2,0.9,0.6,1,1.1l0.1,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.8-0.3,1.1
                        c-0.6,0.9-0.5,2,0.2,2.8l0.1,0.1l0.2,0.2l0.4,0.3l1.6,1.2c0.2,0.2,0.4,0.3,0.7,0.4l0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0h0.2
                        c0.5-0.1,0.9-0.5,1.2-0.9l0.2-0.3c0.3-0.5,0.8-0.7,1.4-0.8c0.4,0,0.7,0.1,1,0.3l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.2l0.1,0.2
                        l0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3l0.2,0.2c0.7,0.5,1.7,0.5,2.4,0l0.1-0.1l2-1.4l0,0c0.1-0.1,0.1-0.1,0.2-0.2
                        c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.3-0.7,0.2-1.5-0.2-2.1l-0.2-0.4c-0.1-0.2-0.2-0.3-0.2-0.5
                        c-0.1-0.3-0.1-0.7,0-1.1c0.1-0.3,0.2-0.5,0.4-0.8l0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.3l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1
                        c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.7,0.6-1.2C27,11.6,27,11.2,26.9,10.9z M25,12.1l-0.2,0.1
                        c-1.1,0.4-1.9,1.2-2.2,2.3c-0.3,1-0.1,2.1,0.5,3l0.2,0.4c0.2,0.3,0.1,0.7-0.1,0.9l-2,1.5c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1
                        c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.2c-1-1.5-3.1-1.9-4.5-0.8c-0.3,0.2-0.6,0.5-0.8,0.8l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
                        c-0.1,0-0.2,0-0.3-0.1l-2.1-1.6c-0.2-0.2-0.2-0.6,0-0.9c0.6-0.9,0.8-2.1,0.4-3.2v-0.1c-0.3-1-1.1-1.8-2.1-2.2L10,12.4
                        c-0.3-0.1-0.5-0.5-0.4-0.8l0.8-2.5c0.1-0.1,0.2-0.3,0.3-0.3h0.1H11c0.4,0.1,0.7,0.2,1.1,0.2c0.7,0,1.5-0.2,2.1-0.7l0.1-0.1
                        c0.8-0.6,1.3-1.6,1.3-2.7V5.2c0-0.3,0.2-0.6,0.5-0.6c0,0,0,0,0,0h2.4c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4v0.3
                        c0,1,0.5,2,1.3,2.6l0.1,0.1c0.6,0.4,1.2,0.6,1.9,0.6c0.3,0,0.7-0.1,1-0.2l0.3-0.1c0.1,0,0.1-0.1,0.2-0.1h0.1h0
                        c0.2,0.1,0.3,0.2,0.4,0.4l0.7,2.3C25.4,11.6,25.3,12,25,12.1C25,12.1,25,12.1,25,12.1z"
                    />
                    <path
                        opacity="0.25" d="M25.4,11.3L24.6,9c-0.1-0.2-0.2-0.3-0.4-0.4h0h-0.1
                        c-0.1,0-0.2,0-0.2,0.1l-0.3,0.1c-0.3,0.1-0.7,0.2-1,0.2c-0.7,0-1.3-0.2-1.9-0.6l-0.1-0.1c-0.8-0.6-1.3-1.6-1.3-2.6V5.2
                        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2h-2.4c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0,0,0v0.2c0,1.1-0.5,2.1-1.3,2.7l-0.1,0.1
                        c-0.6,0.5-1.3,0.7-2.1,0.7c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.1c-0.1,0.1-0.2,0.2-0.3,0.3l-0.8,2.5c-0.1,0.3,0.1,0.7,0.4,0.8l0.2,0.1
                        c1,0.4,1.8,1.2,2.1,2.2v0.1c0.4,1.1,0.2,2.2-0.4,3.2c-0.2,0.2-0.2,0.6,0,0.9l2.2,1.6c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2
                        l0.2-0.4c1-1.5,3.1-1.9,4.5-0.8c0.3,0.2,0.6,0.5,0.8,0.8l0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2H21c0.1,0,0.2,0,0.3-0.1l2-1.5
                        c0.3-0.2,0.3-0.6,0.1-0.9l-0.2-0.4c-0.6-0.9-0.8-2-0.5-3c0.3-1.1,1.1-1.9,2.2-2.3l0.2-0.1C25.3,12,25.5,11.6,25.4,11.3z M17.5,15.5
                        c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S19.2,15.5,17.5,15.5C17.5,15.5,17.5,15.5,17.5,15.5z"
                    />
                    <path d="M17.5,9.5c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S19.2,9.5,17.5,9.5z M17.5,13.9c-0.8,0-1.4-0.6-1.4-1.4
                        c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0,0,0,0,0C18.9,13.3,18.3,13.9,17.5,13.9z"
                    />
                </>
            );
        default:
            console.error('icon: ', icon, 'could not be found');
            break;
    }
}

