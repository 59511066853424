export default{
    "components.uploader.imgUpload.modal.title"   : "Upload an image",
    "components.uploader.imgUpload.modal.maxSize" : "Maximum file size:",
    "components.uploader.imgUpload.modal.drag"    : "drop it here",
    "components.uploader.imgUpload.modal.drop"    : "Drop to upload",
    "components.uploader.imgUpload.modal.or"      : "or",
    "components.uploader.imgUpload.modal.btn"     : "Add image",
    "components.uploader.imgUpload.modal.noFile"  : "No file.",

    "components.uploader.imgUpload.error.title"    : "Oops!",
    "components.uploader.imgUpload.error.tooMany"  : "You cannot upload that many files",
    "components.uploader.imgUpload.error.max"      : "Cannot upload more than {maxSize} files",
    "components.uploader.imgUpload.error.noFile"   : "Please add a file",
    "components.uploader.imgUpload.error.tooLarge" : "File size should not exceed {maxSize, number, ::.## unit/megabyte}",
    "components.uploader.imgUpload.error.3331"     : "The file size exceeds the limit",
    "components.uploader.imgUpload.error.3332"     : "This file extension is not supported",
    "components.uploader.imgUpload.error.3189"     : "No background check settings found",
    "components.uploader.imgUpload.error.fileType" : "Only these file types are supported: {accepted}",
    "components.uploader.imgUpload.error.exists"   : "You already added this file: {name}",

    "components.uploader.alreadyUploaded" : "is already uploaded",
}