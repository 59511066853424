import submitTicket from "./submitTicket";
import userGuide from "./userGuide";

export default{
    ...submitTicket,
    ...userGuide,

    "helpCenter.title"        : "Need help?",
    "helpCenter.userGuide"    : "User Guide",
    "helpCenter.submitTicket" : "Support request",
    "helpCenter.chat"         : "Chat",
}