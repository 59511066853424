import { FormikDateTime, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { object, string } from "yup";
import DisplayCategory from "../../../../../../components/DisplayCategory";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const SupervisionSidePanelInfo = ({ supervision, isReadOnly, updateSupervisionReport }) => {
    return (
        <>
            <div className="h4 font-bold"><Translate id="account.members.coach.sidePanel.tab.info" /></div>
            <Row form className="mb-1">
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='account.members.coach.homeTeam.label' /></div>
                    <div>{supervision.home_team?.short_name || supervision.home_team_name || "-"}</div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='account.members.coach.awayTeam.label' /></div>
                    <div>{supervision.away_team?.short_name || supervision.away_team_name || "-"}</div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='account.members.coach.season.label' /></div>
                    <div><DisplayI18n field="name" i18n={supervision.period.i18n} defaultValue={supervision.period.name} /></div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted">Date</div>
                    <FormikEditable
                        id="supervision-date"
                        disabled={isReadOnly}
                        className="editable-transparent"
                        initialValues={{
                            supervision_date: supervision.supervision_date || "",
                        }}
                        onSubmit={(values) => {
                            const newDate = moment(values.supervision_date).format('YYYY-MM-DD');
                            if(newDate !== supervision.supervision_date){
                                updateSupervisionReport({ supervision_date: newDate });
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">{supervision.supervision_date || "-"}</div>
                                )
                            }
                            return (
                                <FormikDateTime
                                    id="date"
                                    className='date-picker-left'
                                    name="supervision_date"
                                    dateFormat="YYYY-MM-DD"
                                    placeholder="misc.birthdate.format"
                                    timeFormat={false}
                                    isValidDate={(current) => moment(current).isSameOrBefore(moment(), 'days')}
                                />
                            )
                        }}
                    </FormikEditable>
                </Col>
                <Col xs="12" sm="6" className="mb-3">
                    <div className="text-muted"><Translate id="account.members.coach.officialType.label" /></div>
                    <FormikEditable
                        id="official-type"
                        disabled={isReadOnly}
                        noConfirmation
                        className="editable-transparent"
                        initialValues={{
                            official_type: supervision.official_type || "",
                        }}
                        validationSchema={object().shape({
                            official_type: string().required(<Translate id="account.members.coach.addSupervision.officialType.required" />),
                        })}
                        onSubmit={(values) => {
                            if(values.official_type !== supervision.official_type){
                                updateSupervisionReport(values);
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div>{supervision.official_type ? <Translate id={`account.members.coach.officialType.${supervision.official_type}`} defaultMessage={supervision.official_type} /> : "-"}</div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name="official_type"
                                    id="official_type"
                                    search={false}
                                    autoFocus
                                    menuIsDefaultOpen
                                    loadingStatus='success'
                                    onOptionSelected={async([ value ]) => {
                                        await formik.setFieldValue('official_type', value);
                                        options.stopEditing();
                                        return true;
                                    }}
                                    defaultData={[
                                        {
                                            label: "account.members.coach.officialType.REFEREE",
                                            translateLabel: true,
                                            value: "REFEREE",
                                        },
                                        {
                                            label: "account.members.coach.officialType.LINESPERSON",
                                            translateLabel: true,
                                            value: "LINESPERSON",
                                        },
                                        {
                                            label: "account.members.coach.officialType.OFFICIAL_SYSTEM",
                                            translateLabel: true,
                                            value: "OFFICIAL_SYSTEM",
                                        },
                                    ]}
                                />
                            )
                        }}
                    </FormikEditable>
                </Col>
                <Col xs="12" className="mb-3">
                    <div className="text-muted"><Translate id='account.members.coach.teamCategory.label' /></div>
                    <div><DisplayCategory category={supervision.team_category} /></div>
                </Col>
            </Row>
        </>
    );
}

export default SupervisionSidePanelInfo;