/* eslint-disable react/no-unused-class-component-methods */
import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import SidePanel from './SidePanel';
import SpordleTableProvider from '@spordle/datatables';
import { fire } from '@spordle/toasts';
import { addBreadcrumb, Severity } from '@sentry/react';
import { sendGAEvent } from '@spordle/ga4';

/** @type {React.Context<Omit<SpordlePanelTable, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const SpordletablePanelContext = createContext();
SpordletablePanelContext.displayName = 'SpordletablePanelContext';

/**
 * This class Component provides default functionalities to handle a SpordleTableProvider with a SidePanel.
 * @class
 * @prop {function} sidePanel A function that returns a SidePanel's children
 * @prop {function} table A function that returns a SpordleTableProvider
 * @prop {boolean} [allowOutsideClick] Will allow the click outside the sidePanel to close it
 * @prop {string} [dataIndex] DEFAULT: 'id' This is used to find the data inside the SpordleTableProvider. Make sure the SpordleTableProvider.dataIndex === SpordlePanelTable.dataIndex
 * @prop {string} [selectedIndex] DEFAULT: 'checked' - This is used to mark the data inside the SpordleTableProvider as selected or not
 *
 * @example
 * <SpordlePanelTable
 *    allowOutsideClick
 *    sidePanel={(props) => <SidePanelAttendees {...props} goToRefund={this.props.goToRefundTab}/>}
 *    table={(panelProps) => {
 *        return(
 *            <SpordleTableProvider
 *                tableHover bordered striped
 *                ref={r => {this.attendeesTableRef = r; panelProps.spordleTableRef(r);}}
 *                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
 *                columns={[
 *                    {
 *                        label: '-',
 *                        key: "checkbox",
 *                        className: 'text-center',
 *                        dataClassName: 'text-center',
 *                        mobile: false,
 *                        sortable: false
 *                    },
 *                    {
 *                        label: 'Name',
 *                        key: "name",
 *                        className: 'text-left',
 *                        dataClassName: 'text-left',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Organization',
 *                        key: "organization",
 *                        className: 'text-left',
 *                        dataClassName: 'text-left',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Date of Birth',
 *                        key: "dateOfBirth",
 *                        className: 'text-left',
 *                        dataClassName: 'text-left',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Status',
 *                        key: "status",
 *                        className: 'text-left',
 *                        dataClassName: 'text-left',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Paid',
 *                        key: "payed",
 *                        className: 'text-center',
 *                        dataClassName: 'text-center',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Attended',
 *                        key: "attended",
 *                        className: 'text-center',
 *                        dataClassName: 'text-center',
 *                        mobile: true,
 *                        sortable: true
 *                    },
 *                    {
 *                        label: 'Passed',
 *                        key: "passed",
 *                        className: 'text-center',
 *                        dataClassName: 'text-center',
 *                        mobile: true,
 *                        sortable: true
 *                    }
 *                ]}
 *                onColumnClick={(e, attendee) => {
 *                    switch(e.button){
 *                        case 0: // Left mouse button
 *                            panelProps.onSingleSelectChange(attendee);
 *                            break;
 *                    }
 *                }}
 *                desktopWhen='md'
 *                pagination={20}
 *                renderRow={(columnKey, attendee) => {
 *                    switch (columnKey) {
 *                        case 'checkbox':
 *                            return(
 *                                <input type="checkbox" checked={attendee.checked} onClick={(e) => {
 *                                        // Use the onClick event to prevent the row click
 *                                        // onChange is triggered after onClick
 *                                        e.stopPropagation();
 *                                        panelProps.onMultiSelectChange(attendee);
 *                                    }}
 *                                    onChange={function(){}}// This needs to be here to remove the console error
 *                                />
 *                            )
 *                        case 'name':
 *                            return(
 *                                <>
 *                                    <div className="font-medium">{attendee.firstName+' '+attendee.lastName}</div>
 *                                    <div className="small">{attendee.memberId}</div>
 *                                </>
 *                            );
 *                        case 'payed':
 *                            return (attendee.payed) && <Button color="link"><i className="text-success fas fa-check"/></Button>;
 *                        case 'attended':
 *                            return (attendee.attended) && <Button color="link"><i className="text-success fas fa-check"/></Button>;
 *                        case 'passed':
 *                            return (attendee.passed) && <Button color="link"><i className="text-success fas fa-check"/></Button>;
 *
 *                        default:
 *                            break;
 *                    }
 *                }}
 *                rowIsHighlighted={(attendee) => !!attendee.checked}
 *            >
 *                <TopSection/>
 *                <SpordleTableView/>
 *            <SpordleTalbeProvider/>
 *        )
 *    }}
 * />
 *
 * @copyright Spordle
 */
class SpordlePanelTable extends Component{
    state = {
        /** @type {boolean} */
        topChecked: false,
        /** @type {boolean} */
        sidePanelOpen: false,
        /** @type {array} */
        selectedRows: [],
        /** @type {Object.<string, object>} */
        editingFlags: {},
    }

    /**
     * @type {import('@spordle/datatables/dist/components/types').ContextValues}
     */
    tableRef

    componentDidUpdate(_prevProps, prevState){
        if(prevState.sidePanelOpen !== this.state.sidePanelOpen){
            addBreadcrumb({
                type: 'info',
                message: `${this.state.sidePanelOpen ? 'Opening' : 'Closing'} side panel`,
                level: Severity.Log,
                category: 'SpordlePanelTable',
                data: this.state.sidePanelOpen ? { // Opening
                    [this.props.dataIndex]: this.state.selectedRows.map((sRow) => sRow[this.props.dataIndex]).toString() || undefined,
                } : { // Closing
                    [this.props.dataIndex]: prevState.selectedRows.map((sRow) => sRow[this.props.dataIndex]).toString() || undefined,
                },
            });

            sendGAEvent('side_panel', {
                event_category: 'user_click',
                event_label: 'SpordlePanelTable',
                state: this.state.sidePanelOpen ? 'OPEN' : 'CLOSE',
                name: this.props.dataIndex,
            });
        }else if(prevState.selectedRows.length !== this.state.selectedRows.length){
            addBreadcrumb({
                type: 'info',
                message: 'Selected row(s) changed',
                level: Severity.Log,
                category: 'SpordlePanelTable',
                data: {
                    [this.props.dataIndex]: this.state.selectedRows.map((sRow) => sRow[this.props.dataIndex]).toString() || undefined,
                },
            });
            sendGAEvent('side_panel', {
                event_category: 'data_change',
                event_label: 'SpordlePanelTable - Selected row(s) changed',
                name: this.props.dataIndex,
            });
        }
    }

    /**
     * Function to do an action when pressing arrow up/down
     * @param {KeyboardEvent} e
     * @private
     */
    _onKeyDown = (e) => {
        const data = this.tableRef.getDisplayedData();
        /**
         * Function to get the last or the first selected index in the SpordleTableProvider
         * @param {boolean} wantLast
         * @returns {number}
         */
        const getExtremeIndex = (wantLast = false) => {
            let extremeIndex = wantLast ? 0 : data.length - 1;
            this.state.selectedRows.forEach((selectedAttendee) => {
                const index = data.findIndex((attendee) => attendee[this.props.dataIndex] === selectedAttendee[this.props.dataIndex]);
                if(index !== -1){
                    extremeIndex = wantLast ? Math.max(extremeIndex, index) : Math.min(extremeIndex, index);
                }
            })
            return extremeIndex;
        }

        if(this.state.sidePanelOpen){
            if(e.key === 'ArrowDown'){
                e.preventDefault();
                const index = getExtremeIndex(true);
                if(index !== -1){
                    // If index correspond to the last item, select last
                    if(index + 1 < data.length){
                        this.onSingleSelectChange(data[index + 1])
                    }else{
                        // Selecting last
                        data[index][this.props.selectedIndex] = false;
                        this.onSingleSelectChange(data[index])
                    }
                }
            }else if(e.key === 'ArrowUp'){
                e.preventDefault();
                const index = getExtremeIndex();
                if(index !== -1){
                    // If index correspond to the first item, select first
                    if(index - 1 >= 0){
                        this.onSingleSelectChange(data[index - 1])
                    }else{
                        // Selecting first
                        data[index][this.props.selectedIndex] = false;
                        this.onSingleSelectChange(data[index])
                    }
                }
            }
        }
    }

    /**
     * Selects all the rows
     */
    checkAll = () => {
        this.setState(() => {
            const data = this.tableRef.getDisplayedData();
            return {
                topChecked: data.length > 0,
                sidePanelOpen: data.length > 0,
                selectedRows: data.map((item) => {
                    item[this.props.selectedIndex] = true;
                    return item;
                }),
            }
        })
    }

    /**
     * Unselect all the rows
     */
    uncheckAll = () => {
        this.setState(() => {
            this.tableRef.getDisplayedData().forEach((row) => {
                row[this.props.selectedIndex] = false;
            })
            return { topChecked: false, selectedRows: [], sidePanelOpen: false, editingFlags: {} };
        });
    }

    /**
     * Select the given item. If the item is already selected, it unselect the item
     * @param {object} item
     */
    onSingleSelectChange = async(item) => {
        if(this.shouldPreventClose()){
            this.showErrors();
            return;
        }

        this.setState(() => {
            this.tableRef.getDisplayedData().forEach((row) => {
                if(row[this.props.dataIndex] !== item[this.props.dataIndex])
                    row[this.props.selectedIndex] = false;
            })
            item[this.props.selectedIndex] = !item[this.props.selectedIndex];
            if(item[this.props.selectedIndex]){ // Will be selected
                return { selectedRows: [ item ], topChecked: true, sidePanelOpen: true };
            }// Will be unselected
            return { selectedRows: [], topChecked: false, sidePanelOpen: false };

        })
    }

    /**
     * Add this item to the selected rows. If the item is already selected, it unselect the item
     * @param {object} item
     */
    onMultiSelectChange = async(item) => {
        if(this.shouldPreventClose()){
            this.showErrors();
            return;
        }
        this.setState((prevState) => {
            item[this.props.selectedIndex] = !item[this.props.selectedIndex];
            if(item[this.props.selectedIndex]){
                prevState.selectedRows.push(item);
                return { selectedRows: prevState.selectedRows, topChecked: true, sidePanelOpen: true };
            }
            prevState.selectedRows = prevState.selectedRows.filter((row) => row[this.props.dataIndex] !== item[this.props.dataIndex]);
            return { selectedRows: prevState.selectedRows, topChecked: prevState.selectedRows.length !== 0, sidePanelOpen: prevState.selectedRows.length !== 0 }

        })
    }

    /**
     * Handles a select all checkbox
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    onTopCheckChange = (e) => {
        if(e.target.checked){
            this.checkAll();
        }else{
            this.uncheckAll();
        }
    }

    /**
     * Open or closes the sidebar
     */
    toggleSidePanel = () => {
        if(this.state.sidePanelOpen){
            if(this.shouldPreventClose()){
                this.showErrors();
            }else
                this.uncheckAll();
        }else{
            this.setState((prevState) => {
                return { sidePanelOpen: !prevState.sidePanelOpen && !!prevState.selectedRows.length };// Open only if there is some selected rows
            });
        }
    }

    forceCloseSidePanel = () => {
        this.setState(() => {
            return { sidePanelOpen: false };
        });
    }

    /**
     * @returns {boolean}
     */
    shouldPreventClose = () => {
        const preventClose = Object.values(this.state.editingFlags).some((i) => i.editingMode);
        return preventClose;
    };

    showErrors = () => {
        fire({ theme: "warning", msg: 'sidePanel.spordlePanel.preventClose.title.toast' })
        Object.values(this.state.editingFlags).forEach((flag) => {
            if(flag.editingMode)
                flag.showError();
        })
    }

    /**
     * When no parameter is provided, it acts like a GET. If a param is provided, it acts like a SET
     * @param {string} id
     * @param {?boolean} [editingMode=false]
     */
    askBeforeClose = (id, editingMode = false, showError) => {
        this.setState((prevState) => ({ editingFlags: { ...prevState.editingFlags, [id]: { editingMode: editingMode, showError: showError } } }));
    }

    /**
     * Call this function to sync the data in the SidePanel and in the SpordleTableProvider.
     * When given partial data, it will completelly override the current data with the partial data. Call createNewValues before calling this if this is the case.
     * @param {object|Array} item The new item or items
     */
    syncRows = (item) => {
        this.setState((prevState) => {
            if(Array.isArray(item)){ // Batch update
                // To update inside the sidePanel
                prevState.selectedRows.forEach((sRow, index) => {
                    const rowIndex = item.findIndex((r) => r[this.props.dataIndex] === sRow[this.props.dataIndex]);
                    if(rowIndex !== -1){
                        prevState.selectedRows[index] = item[rowIndex];
                    }
                })

                item.forEach((sItem) => {
                    this.tableRef.updateDataWith(sItem[this.props.dataIndex], sItem);
                })
                return prevState;
            }
            // Single update
            // To update inside the sidePanel
            const rowIndex = prevState.selectedRows.findIndex((sRow) => sRow[this.props.dataIndex] === item[this.props.dataIndex]);
            prevState.selectedRows[rowIndex] = item;

            // To update in the table
            this.tableRef.updateDataWith(item[this.props.dataIndex], item);
            return prevState;

        })
    }

    /**
     * Get the common value of all the selectedRows of the given index - It will returns '-' If there is not commun value
     * @param {string} index Index to check
     * @returns {any|'-'} The commun value, otherwise '-'
     */
    getCommonValue = (index) => {
        if(this.state.selectedRows.length > 0){
            if(this.state.selectedRows.length === 1){
                return this.state.selectedRows[0][index];
            }
            if(this.state.selectedRows.every((row) => row[index] === this.state.selectedRows[0][index])){ // If every row has the same value, return the common value
                return this.state.selectedRows[0][index];
            }
            return '-';


        }

        return '-'

    }

    /**
     * This function create a new value for the SidePanel and SpordleTableProvider.
     * Give the returned value to the syncRows function
     * @param {object} values The partial data to update for every selected rows
     * @returns {object|object[]} The new builded data
     */
    createNewValues = (values) => {
        if(this.state.selectedRows.length > 1){ // Multi
            return this.state.selectedRows.map((sRow) => ({ ...sRow, ...values }));
        }// Single
        return {
            ...this.state.selectedRows[0],
            ...values,
        };

    }

    // Commented for now... causing unwanted behaviors when modals are open
    // componentDidMount() {
    //     document.addEventListener('keydown', this._onKeyDown);
    // }

    // componentWillUnmount(){
    //     document.removeEventListener('keydown', this._onKeyDown);
    // }

    render(){
        return (
            <SpordletablePanelContext.Provider value={{ ...this.state, ...this }}>
                <SidePanel isOpen={this.state.sidePanelOpen} overlayToggle={this.props.allowOutsideClick ? this.toggleSidePanel : undefined}>
                    {this.props.sidePanel({
                        ...this.state,
                        toggle: this.toggleSidePanel,
                        forceCloseSidePanel: this.forceCloseSidePanel,
                        syncRows: this.syncRows,
                        getCommonValue: this.getCommonValue,
                        onSingleSelectChange: this.onSingleSelectChange,
                        onMultiSelectChange: this.onMultiSelectChange,
                        createNewValues: this.createNewValues,
                        askBeforeClose: this.askBeforeClose,
                        tableRef: this.tableRef,
                    })}
                </SidePanel>
                {this.props.table({
                    ...this.state,
                    checkAll: this.checkAll,
                    uncheckAll: this.uncheckAll,
                    onTopCheckChange: this.onTopCheckChange,
                    onSingleSelectChange: this.onSingleSelectChange,
                    onMultiSelectChange: this.onMultiSelectChange,
                    syncRows: this.syncRows,
                    spordleTableRef: (r) => { this.tableRef = r },
                })}
            </SpordletablePanelContext.Provider>
        );
    }
}

SpordlePanelTable.propTypes = {
    sidePanel: PropTypes.elementType.isRequired,
    table: PropTypes.elementType.isRequired,
    allowOutsideClick: PropTypes.bool,
    dataIndex: PropTypes.string,
    selectedIndex: PropTypes.string,
}

SpordlePanelTable.defaultProps = {
    dataIndex: SpordleTableProvider.defaultProps.dataIndex,
    selectedIndex: 'checked',
}

export default SpordlePanelTable;