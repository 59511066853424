import { Redirect } from 'react-router-dom';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import TourRecovery from '../../views/tournaments/recovery';

const RecoveryPicker = (props) => {
    switch (getLocalStorageItem('platformLogin')){
        case 'TOUR':
            return <TourRecovery {...props} />;

        default:
            return <Redirect to='/404' />
    }
}

export default RecoveryPicker;