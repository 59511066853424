import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Button, Card } from "reactstrap";
import { AxiosCancelAll, AxiosIsCancelled } from "../../../../api/CancellableAPI";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { GameIncidentsContext } from "../../../../contexts/GameIncidentsContext";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import AddMemberGameIncidentModal from "./components/modal/AddMemberGameIncident";
import { BC_ID } from "./components/gameIncidentHelper";
import AccountMemberGameIncidentSidePanel from "./components/sidePanel/AccountMemberGameIncidentSidePanel";

const AccountMemberGameIncident = ({ member }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { getGameIncidentList, getGameIncidentStatusList, getSuspensions, getSuspensionTypes } = useContext(GameIncidentsContext);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ gameIncidentRessources, setGameIncidentRessources ] = useState({
        statuses: [],
        suspensions: [],
        suspension_types: [],
    })

    // TODO: REFACTOR
    useEffect(() => {
        Promise.all([
            getSuspensions(member.organisation.organisation_id, { visible_on_gir: 1 }),
            getSuspensionTypes(member.organisation.organisation_id),
            getGameIncidentStatusList(BC_ID),
        ])
            .then(([ suspensions, suspensionTypes, statuses ]) => {
                setGameIncidentRessources({
                    statuses: statuses,
                    suspensions: suspensions,
                    suspension_types: suspensionTypes,
                })
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            });

        return AxiosCancelAll
    }, []);

    return (
        <>
            <ReactTooltip id="react-tooltip-gameincident" />
            <Card body className='card-shadow'>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0">
                        <Translate id='account.members.gameIncidentReport.title' />
                    </div>
                </div>
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='game_incident_id'
                    sidePanel={(sProps) => (
                        <AccountMemberGameIncidentSidePanel
                            statuses={gameIncidentRessources.statuses}
                            suspensions={gameIncidentRessources.suspensions}
                            suspensionTypes={gameIncidentRessources.suspension_types}
                            member={member}
                            {...sProps}
                        />
                    )}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            tableHover bordered striped
                            clickable
                            id='AccountMemberGameIncidents'
                            key={member.member_id}
                            ref={panelProps.spordleTableRef}
                            pagination={10}
                            desktopWhen="md"
                            dataIndex='game_incident_id'
                            defaultSorting="-season"
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            loadData={(from, _, { setLoading }) => {
                                switch (from){
                                    case 'REFRESH':
                                        setLoading();
                                    case 'CDM':
                                        return getGameIncidentList(member.member_id)
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={[
                                'period.name',
                                'period.i18n.name',
                                'home_team.short_name',
                                'home_team.name',
                                'home_team.i18n.short_name',
                                'away_team.short_name',
                                'away_team.name',
                                'away_team.i18n.short_name',
                                'game_incident_status.name',
                                'game_incident_status.i18n.name',
                            ]}
                            columns={[
                                {
                                    label: '#',
                                    key: 'game_incident_number',
                                    sortKey: 'game_incident_number',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.gir.addModal.label.season' />,
                                    key: 'season',
                                    sortKey: 'period.start_date',
                                    fallbackSortKey: 'period.end_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.gir.addModal.teams.home.label' />,
                                    key: 'home_team',
                                    sortKey: 'home_team.short_name',
                                    fallbackSortKey: 'home_team.name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.gir.addModal.teams.away.label' />,
                                    key: 'away_team',
                                    sortKey: 'away_team.short_name',
                                    fallbackSortKey: 'away_team.name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id="misc.status" />,
                                    key: 'status',
                                    sortKey: () => `game_incident_status.${getGenericLocale()}.name`,
                                    fallbackSortKey: 'game_incident_status.name',
                                    sortable: true,
                                },
                            ]}
                            renderRow={(columnKey, gameIncident) => {
                                switch (columnKey){
                                    case 'season':
                                        return (
                                            <span className="text-nowrap">
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={gameIncident.period?.name}
                                                    i18n={gameIncident.period?.i18n}
                                                />
                                            </span>
                                        )
                                    case 'home_team':
                                        return (
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{gameIncident.home_team.name}</UserDisplay.Title>
                                                    {gameIncident.home_team.short_name &&
                                                        <UserDisplay.Subtitle>({gameIncident.home_team.short_name})</UserDisplay.Subtitle>
                                                    }
                                                    {gameIncident.home_team.unique_identifier &&
                                                        <UserDisplay.Subtitle>
                                                            #{gameIncident.home_team.unique_identifier}
                                                        </UserDisplay.Subtitle>
                                                    }
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        )
                                    case 'away_team':
                                        return (
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{gameIncident.away_team.name}</UserDisplay.Title>
                                                    {gameIncident.away_team.short_name &&
                                                        <UserDisplay.Subtitle>({gameIncident.away_team.short_name})</UserDisplay.Subtitle>
                                                    }
                                                    {gameIncident.away_team.unique_identifier &&
                                                        <UserDisplay.Subtitle>
                                                            #{gameIncident.away_team.unique_identifier}
                                                        </UserDisplay.Subtitle>
                                                    }
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        )
                                    case 'status':
                                        return (
                                            <span className="text-nowrap">
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={gameIncident.game_incident_status.i18n}
                                                    defaultValue={gameIncident.game_incident_status.name}
                                                />
                                            </span>
                                        )
                                    default:
                                        break;
                                }
                            }}
                            emptyLayout={<EmptyLayout hideArrow hideMsg />}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                        >
                            <div className='mb-2'>
                                <div className='d-flex flex-wrap justify-content-between'>
                                    <div className="order-1 order-md-0 flex-grow-1 flex-md-grow-0">
                                        <SpordleTableProvider.SearchInput />
                                    </div>
                                    <SpordleTableProvider.Refresh className="order-3 order-md-0 ml-2 ml-md-auto" />
                                    <Button onClick={() => setIsOpen(true)} type="button" className="mb-2 mb-md-0 w-100 w-md-auto ml-md-2 order-0 order-md-3" color="primary">
                                        <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                                    </Button>
                                    <AddMemberGameIncidentModal
                                        statuses={gameIncidentRessources.statuses}
                                        suspensions={gameIncidentRessources.suspensions}
                                        suspensionTypes={gameIncidentRessources.suspension_types}
                                        member={member}
                                        isOpen={isOpen}
                                        toggle={() => setIsOpen(false)}
                                    />
                                </div>
                            </div>
                            <SpordleTableView />
                        </SpordleTableProvider>
                    )}
                />
            </Card>
        </>
    )
}

export default AccountMemberGameIncident;