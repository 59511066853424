import Maintenance from '../views/maintenance/Maintenance';
import NotFound from '../views/NotFound';
import authRoutes from './authRoutes';
import FullLayout from '../layouts/fulllayout';

const routes = [
    ...authRoutes,

    { path: '/maintenance', component: Maintenance },
    { path: '/', component: FullLayout, privateRoute: true },

    { path: '/404', component: NotFound, exact: true },

    // redirect to login instead of home because home is devOnly, we dont want a user to be redirected to a 404
    { path: '/', pathTo: '/login', redirect: true, exact: true },


    // fallback
    { path: '/', component: NotFound },
];
export default routes;