import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { useRef, useContext, useEffect, useState } from "react";
import HCLogo from '../../assets/images/logos/HC.png';
import SpordleLogo from '../../assets/images/logos/logoSpordle.png';
import { Button, Card, CardBody, Col, Collapse, Container, Fade, Row, Spinner, Table } from "reactstrap";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { AccountsContext, AuthContext } from "../../contexts/contexts";
import { UtilsContext } from "../../contexts/UtilsContext";
import ToPrint from '@spordle/print';
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import * as Sentry from '@sentry/react';
import InvoiceItemsClinic from "./InvoiceItemsClinic";
import InvoiceItemsOther from "./InvoiceItemsOther";
import InvoiceItemsRegistration from "./InvoiceItemsRegistration";
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import { groupByAsArray } from "@spordle/helpers";

const Invoice = ({ match }) => {
    const [ invoice, setInvoice ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ org, setOrg ] = useState(null);

    const utilsContext = useContext(UtilsContext);
    const accountContext = useContext(AccountsContext);
    const authContext = useContext(AuthContext);
    const orgContext = useContext(OrganizationContext);

    // print
    const printContainer = useRef(null);

    const formatInvoice = (inv) => ({
        ...inv,
        affiliationFees: inv.invoice_items.filter((item) => item.affiliation_fee !== null),
        creditAmount: calcAmount(inv.credits, 'amount'),
        rebateAmount: calcAmount(inv.invoice_items, 'rebate_amount'),
        rebateData: inv.invoice_items.find((item) => item.rebate !== null)?.rebate,
        members: groupByAsArray(inv.invoice_items.map((item) => ({ ...item, memberId: item.member.member_id })), 'memberId'),
    });

    /**
     * Returns the sum of a field in an array
     * @param {array} arr
     * @param {string} field
     * @returns {number}
     */
    const calcAmount = (arr, field) => (
        arr.reduce((sum, item) => sum += parseInt(item[field]), 0)
    );

    useEffect(() => {
        setIsLoading(true);
        if(match.params.invoiceNumber){
            accountContext.getAccountInvoices(authContext.userData.Username, {
                invoice_number: match.params.invoiceNumber,
            })
                .then(async(invoice) => {
                    if(invoice && invoice.length > 0 && invoice[0].status !== 'VOID'){ // Prevent loading an invoice that is VOID
                        setInvoice(formatInvoice(invoice[0]));
                        const promises = await Promise.all([
                            orgContext.getOrganization(invoice[0].organisation.organisation_id),
                            invoice[0].country_id ? utilsContext.getProvinces(invoice[0].country_id) : Promise.resolve(),
                            utilsContext.getPaymentMethods(),
                        ]);

                        setOrg(promises[0]);
                        if(invoice.country_id)
                            setInvoice((prev) => ({ ...prev, country: promises[1][0].code }));

                        setInvoice((prev) => ({ ...prev, paymentMethod: promises[2].find((m) => m.code === invoice[0].invoice_payment_method?.code) }));

                    }else{
                        setInvoice('none');
                    }
                    setIsLoading(false)
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        setInvoice('none');
                        setIsLoading(false);
                    }
                });
        }
    }, [ match.params.invoiceNumber ]);

    return (
        <div className="page-content" ref={printContainer}>
            {/* start invoice */}
            <Container>
                <Card className="card-shadow">
                    <CardBody>
                        {!isLoading ?
                            <Collapse isOpen={!isLoading}>
                                { invoice && invoice !== "none" &&
                                    <Fade>
                                        <div className="clearfix">
                                            { org.logo && org.abbreviation !== "HC" &&
                                                <img className="float-left mr-2" src={org.logo.full_path} alt={org.organisation_name} width="50" style={{ maxHeight: 50 }} />
                                            }
                                            <img className="float-left" src={HCLogo} alt="Hockey Canada" width="50" style={{ maxHeight: 50 }} />
                                            {/* <UserImg
                                                abbr={orgContext.abbreviation}
                                                alt={orgContext.organisation_name}
                                                src={orgContext.logo?.full_path}
                                            /> */}
                                            <ToPrint
                                                contentRef={printContainer.current}
                                                documentTitle={invoice.invoice_number}
                                                onBeforeGetContent={() => {
                                                    Sentry.addBreadcrumb({ message: 'Before invoice print', level: Sentry.Severity.Info, category: 'info', type: 'info' })
                                                }}
                                            >
                                                {(handlePrint) => (
                                                    <Button onClick={handlePrint} color="primary" className="float-right d-print-none"><Translate id='misc.print' /></Button>
                                                )}
                                            </ToPrint>
                                        </div>
                                        <div className="border-top border-bottom py-3 my-3">
                                            <div className='clearfix'>
                                                <div className='float-left'>
                                                    <span className="font-bold text-dark"><Translate id='invoice.date' />: </span>
                                                    <span>
                                                        <DateFormat value={invoice.invoice_date} utc />
                                                    </span>
                                                </div>
                                                <div className='invoice-info-right'>
                                                    <span className="font-bold text-dark"><Translate id='invoice.invoiceNo' />: </span>
                                                    <span>{invoice.invoice_number}</span>
                                                    {invoice.paymentMethod &&
                                                        <div>
                                                            <span className="font-bold text-dark"><Translate id='invoice.paymentMethod' />: </span>
                                                            <DisplayI18n field="name" i18n={invoice.paymentMethod.i18n} defaultValue={invoice.paymentMethod.name} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='float-left mb-3'>
                                            <div className="font-bold text-dark mb-2"><Translate id='invoice.payedBy' /></div>
                                            {invoice.identity ?
                                                <div>{invoice.identity.name} {invoice.identity.family_name}</div>
                                                :
                                                invoice.invoice_items[0]?.member && <div>{invoice.invoice_items[0]?.member.first_name} {invoice.invoice_items[0]?.member.last_name}</div>
                                            }
                                            <div>
                                                {invoice.street_number && <span>{invoice.street_number}, </span>}
                                                {invoice.street && <span>{invoice.street}</span>}
                                            </div>
                                            {invoice.unit_number && <div><span>{invoice.unit_number}</span></div>}
                                            <div>
                                                {invoice.city && <span>{invoice.city}, </span>}
                                                {invoice.province_id && <span>{invoice.province_id}, </span>}
                                                {invoice.country && <span>{invoice.country}</span>}
                                            </div>
                                            {invoice.zip_code && <div>{invoice.zip_code}</div>}
                                            <DisplayPhoneNumber phoneString={invoice.phone_number} />
                                            {invoice.invoice_items[0]?.member && <div><a href={`mailto:${invoice.invoice_items[0]?.member.email}`}>{invoice.invoice_items[0]?.member.email}</a></div>}
                                        </div>
                                        {org &&
                                            <div className='invoice-info-right mb-3'>
                                                <div className="font-bold text-dark mb-2"><Translate id='invoice.organization' /></div>
                                                <div><DisplayI18n field="name" i18n={org.i18n} defaultValue={org.organisation_name} /></div>
                                                {org.street && <div>{org.street_number} {org.street}</div>}
                                                {org.unit_number && <div><span>{org.unit_number}</span></div>}
                                                {org.city && <div>{org.city}, {org.province_code}, {org.country_code} </div>}
                                                {org.zip_code && <div>{org.zip_code}</div>}
                                                {org.organisation_email && <div><a href={`mailto:${org.organisation_email}`}>{org.organisation_email}</a></div>}
                                                <div><DisplayPhoneNumber phoneString={org.phone} emptyValue='-' /></div>
                                            </div>
                                        }

                                        <Table size="sm" className="mb-5">
                                            <thead>
                                                <tr>
                                                    <th className="font-bold w-100"><Translate id='invoice.table.item' /></th>
                                                    <th className="font-bold text-right"><Translate id='invoice.table.price' /></th>
                                                    <th />
                                                    <th className="font-bold "><Translate id='invoice.table.qty' /></th>
                                                    <th className="font-bold text-right"><Translate id='invoice.table.total' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.members?.map((member) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td colSpan={5} className="w-100 font-bold">
                                                                    {member.values[0].member.first_name} {member.values[0].member.last_name} #{member.values[0].member.unique_identifier}
                                                                </td>
                                                            </tr>
                                                            {member.values.map((item) => {
                                                                // we display the other fees and the clinic fees differently because an invoice can contain only other fees, but cannot contain only clinic fees
                                                                const otherItemsCount = member.values.filter((_item) => item.other_fee && _item.other_fee?.other_fee_id === item.other_fee?.other_fee_id).length
                                                                if(item.registration_fee){
                                                                    return <InvoiceItemsRegistration key={item.invoice_item_id} invoice={invoice} item={item} />
                                                                }else if(item.other_fee){
                                                                    return <InvoiceItemsOther key={item.invoice_item_id} item={item} number={otherItemsCount} />
                                                                }else if(item.clinic || item.clinic_fee){
                                                                    return <InvoiceItemsClinic key={item.invoice_item_id} invoice={invoice} item={item} />
                                                                }
                                                            })}
                                                        </>
                                                    )
                                                })}
                                                { invoice.rebateAmount > 0 &&
                                                    <>
                                                        <tr>
                                                            <td colSpan={5} className="font-bold">
                                                                <Translate id='invoice.table.discount' />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-top-0 pt-0"><DisplayI18n field="title" i18n={invoice.rebateData?.i18n} defaultValue={invoice.rebateData?.title} /></td>
                                                            <td className="border-top-0 pt-0 text-right">-<CurrencyFormat value={invoice.rebateAmount / 100} /></td>
                                                            <td className="border-top-0 pt-0">X</td>
                                                            <td className="border-top-0 pt-0">1</td>
                                                            <td className="border-top-0 pt-0 text-right">-<CurrencyFormat value={invoice.rebateAmount / 100} /></td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="text-right" colSpan="4">
                                                        <span className="font-bold"><Translate id='invoice.table.subTotal' />:</span>
                                                    </td>
                                                    <td className="text-right">
                                                        <CurrencyFormat value={invoice.sub_total_amount / 100} />
                                                    </td>
                                                </tr>
                                                {/* foreach tax */}
                                                <tr>
                                                    <td className="text-right" colSpan="4">
                                                        <Translate id='invoice.table.tax' />:
                                                    </td>
                                                    <td className="text-right">
                                                        <CurrencyFormat value={invoice.total_tax_amount / 100} />
                                                    </td>
                                                </tr>
                                                {/* end foreach tax */}
                                                { invoice.creditAmount > 0 &&
                                                    <tr className="border-top">
                                                        <td colSpan="4" className="border-top-0 text-right">
                                                            <Translate id='invoice.table.credits' />:
                                                        </td>
                                                        <td className="border-top-0 text-right text-success">
                                                            -<CurrencyFormat value={invoice.creditAmount / 100} />
                                                        </td>
                                                    </tr>
                                                }
                                                <tr className="border-top">
                                                    <td className="text-right font-bold" colSpan="4">
                                                        <Translate id='invoice.table.total' />:
                                                    </td>
                                                    <td className="text-right">
                                                        <CurrencyFormat value={(parseInt(invoice.total_amount) - parseInt(invoice.creditAmount)) / 100} />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Fade>
                                }
                                { invoice && invoice === "none" &&
                                    <div>
                                        <div className="font-bold h4 mb-2"><Translate id='invoice.error.title' /></div>
                                        <p><Translate id='invoice.error.message' /></p>
                                    </div>
                                }
                                <Row className="align-items-center">
                                    <Col sm="6" className="mb-2">
                                        <span className="small"><Translate id='invoice.table.poweredBy' values={{ year: new Date().getFullYear() }} /></span>
                                    </Col>
                                    <Col sm="6" className="mb-2 text-right">
                                        <img src={SpordleLogo} alt="Spordle" />
                                    </Col>
                                </Row>
                            </Collapse>

                            :
                            <div className="text-center py-4"><Spinner type="grow" color="primary" /></div>
                        }
                    </CardBody>
                </Card>
            </Container>
            {/* end invoice */}
        </div>
    )
}

export default Invoice;