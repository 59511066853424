import { FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap"
import { AxiosIsCancelled } from "../../../../../../../../../api/CancellableAPI";
import { GameIncidentsContext } from "../../../../../../../../../contexts/GameIncidentsContext";
import { PeriodsContext } from "../../../../../../../../../contexts/PeriodsContext";
import { TeamCategoriesContext } from "../../../../../../../../../contexts/TeamCategoriesContext";
import { I18nContext } from "../../../../../../../../../contexts/I18nContext";
import StepTitle from "../../../components/StepTitle"
import { DisplayI18n } from "../../../../../../../../../helpers/i18nHelper";
import moment from "moment";
import { BC_ID } from "../../../../gameIncidentHelper";
import Required from "../../../../../../../../../components/formik/Required";

const StepMatchInnerForm = ({ member }) => {
    const { setFieldValue, values, setStatus } = useFormikContext();
    const { getTeamCategories } = useContext(TeamCategoriesContext);
    const { getClasses, getDivisions } = useContext(GameIncidentsContext);
    const { getPeriods } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ ressources, setRessources ] = useState({
        classes: null,
        divisions: null,
        teamCat: false, // false disables
    });

    const getCategoryLabel = (option) => (
        <>
            <DisplayI18n
                field="short_name"
                defaultValue={option.option.division?.short_name}
                i18n={option.option.division?.i18n}
            />
            {option.option.class?.class_id &&
                <>
                    {' - '}
                    <DisplayI18n
                        field="short_name"
                        defaultValue={option.option.class.short_name}
                        i18n={option.option.class.i18n}
                    />
                </>
            }
        </>
    )

    /**
     * @description function that updates the data of the ressources
     * @param {object} newData
     */
    const updateData = (newData) => { setRessources((prev) => ({ ...prev, ...newData })) };


    /**
     * @description Generic function that sets a formik status error on catch
     * @param {Error} e
     */
    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);
        }
    }

    /**
     * @description Sorts a ressource array by display_order
     * @param {{display_order: string|number}[]} ressource
     * @returns {{display_order: string|number}[]} sorted ressource
     */
    const sortRessource = (ressource) => ressource.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order));


    /**
     * CLASS_ID, DIVISION_ID, PERIOD_ID USEFFECT
     * Gets the team categories when there's a class id or a division id
     */
    useEffect(() => {
        if(values.period_id && (values.class_id || values.division_id)){
            updateData({ teamCat: null });
            getTeamCategories(
                BC_ID,
                values.period_id,
                {
                    class_id: values.class_id,
                    division_id: values.division_id,
                    limit_to_my_organisation: 1,
                },
            )
                .then((categories) => {
                    updateData({ teamCat: sortRessource(categories) });
                })
                .catch(handleCatch)
        }
    }, [ values.class_id, values.division_id ]);

    /**
     * ON MOUNT USE EFFECT
     */
    useEffect(async() => {
        if(member){
            const per = await getPeriods(BC_ID).catch(handleCatch);
            const currPerId = per?.find((p) => p.current == 1)?.period_id;
            const classes = await getClasses(BC_ID, { period_id: currPerId }).catch(handleCatch);
            const divs = await getDivisions(BC_ID, { period_id: currPerId }).catch(handleCatch);

            setFieldValue('period_id', currPerId);
            updateData({
                classes: sortRessource(classes),
                divisions: sortRessource(divs),
            });
        }
    }, []);

    return (
        <>
            <StepTitle title='account.members.gir.addModal.steps.match.title' />
            <Row form>
                <Col xs="6" md="4" className="form-group">
                    <Label for="division_id" className="text-muted"><Translate id='account.members.gir.addModal.label.division' /> <Required /></Label>
                    <FormikSelect
                        id={"division_id"} name={"division_id"} clearable
                        searchKeys={[
                            `i18n.${getGenericLocale()}.name`,
                            `i18n.${getGenericLocale()}.short_name`,
                        ]}
                        options={ressources.divisions?.map((div) => ({
                            label: div.short_name,
                            value: div.division_id,
                            i18n: div.i18n,
                        }))}
                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                    />
                </Col>
                <Col xs="6" md="4" className="form-group">
                    <Label for="class_id" className="text-muted"><Translate id='account.members.gir.addModal.label.class' /></Label>
                    <FormikSelect
                        id={"class_id"} name={"class_id"} clearable
                        searchKeys={[
                            `i18n.${getGenericLocale()}.name`,
                            `i18n.${getGenericLocale()}.short_name`,
                        ]}
                        options={ressources.classes?.map((myClass) => ({
                            label: myClass.short_name,
                            value: myClass.class_id,
                            i18n: myClass.i18n,
                        }))}
                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                    />
                </Col>
                <Col xs="6" md="4" className="form-group">
                    <Label for="team_category_id" className="text-muted"><Translate id='account.members.gir.addModal.label.category' /> <Required /></Label>
                    <FormikSelect
                        id={"team_category_id"} name={"team_category_id"} clearable
                        disabled={!values.division_id}
                        isLoading={ressources.teamCat === null}
                        searchKeys={[
                            'division.short_name',
                            'division.name',
                            `division.i18n.${getGenericLocale()}.name`,
                            `division.i18n.${getGenericLocale()}.short_name`,
                            'class.short_name',
                            'class.name',
                            `class.i18n.${getGenericLocale()}.name`,
                            `class.i18n.${getGenericLocale()}.short_name`,
                        ]}
                        options={Array.isArray(ressources.teamCat) ? ressources.teamCat.map((cat) => ({
                            label: cat.short_name,
                            value: cat.team_category_id,
                            division: cat.division,
                            class: cat.class,
                        })) : []}
                        renderOption={getCategoryLabel}
                    />
                </Col>
                <Col xs="6" className="form-group">
                    <Label for="game_incident_date" className="text-muted"><Translate id='account.members.gir.addModal.label.date' /> <Required /></Label>
                    <FormikDateTime
                        name="game_incident_date"
                        timeFormat={false}
                        dateFormat='YYYY-MM-DD'
                        isValidDate={(current) => moment(current).isSameOrBefore(moment())}
                    />
                </Col>
                <Col sm="6" className="form-group">
                    <Label for="league_name" className="text-muted"><Translate id='account.members.gir.addModal.label.league' /></Label>
                    <FormikInputText name="league_name" />
                </Col>
                <Col xs="6" className="form-group">
                    <Label for="location_name" className="text-muted"><Translate id='account.members.gir.addModal.label.location' /></Label>
                    <FormikInputText name="location_name" />
                </Col>
                <Col xs="6" className="form-group">
                    <Label className="text-muted"><Translate id='account.members.gir.addModal.label.game' /></Label>
                    <FormikInputText name="game_number" />
                </Col>
                <Col sm="12" className="form-group">
                    <Label className="text-muted" for="game_incident_memo"><Translate id='account.members.gir.addModal.label.note' /></Label>
                    <FormikTextArea name="game_incident_memo" />
                </Col>
            </Row>
        </>
    )
}

export default StepMatchInnerForm;