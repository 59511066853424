import { Redirect } from 'react-router-dom';
import SpordleSignup from '../../views/spordle/signup';
import HCRSignup from '../../views/hcr/signup';
import TourSignup from '../../views/tournaments/signup';
import { LOGINTYPE } from '../../helpers/constants';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import { isMultiSport } from '../../helpers/getReferer';

const SignUpPicker = (props) => {
    switch (getLocalStorageItem('platformLogin') || LOGINTYPE.SPORDLE){
        case LOGINTYPE.HCR:
        case LOGINTYPE.PLAY:
        case LOGINTYPE.CLINIC:
            if(!isMultiSport())
                return <HCRSignup {...props} />;
        case LOGINTYPE.SPORDLE:
            return <SpordleSignup {...props} />;
        case 'TOUR':
            return <TourSignup {...props} />;
        default:
            return <Redirect to='/404' />
    }
}

export default SignUpPicker;