import { classPrefix } from "./AppsHelper";
import Site from "../../../../assets/images/spordleSuite/Site.png";

const SingleApp = ({ img, name, link, customImg }) => {
    return (
        <li className={`${classPrefix}-single`}>
            <a href={link ?? "!#"} target="_blank" rel="noopener noreferrer" className={`${classPrefix}-link`}>
                {customImg ?? <img className={`${classPrefix}-img`} src={img ?? Site} alt={name} />}
                <span className={`${classPrefix}-name`}>
                    {name}
                </span>
            </a>
        </li>
    )
}

export default SingleApp;