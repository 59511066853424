import { useCallback, useContext, useState } from 'react';
import { Alert, Button, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Translate from '@spordle/intl-elements';
import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { FormikPhoneInput } from '@spordle/formik-elements';
import PinField from 'react-pin-field';

import SpordleMyAccountLogo from '../../../../../assets/images/logos/spordleMyAccount.svg';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { fail, success } from '@spordle/toasts';

const AccountPhone = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const [ phone, setPhone ] = useState('');
    const [ mustVerify, setVerify ] = useState(auth.userData.UserAttributes.phone_number_verified !== 'true' && auth.userData.UserAttributes.phone_number);
    const resendEmail = useCallback(() => {
        auth.sendAttributeCode('phone_number')
            .then(() => {
                success({ msg: 'account.settings.profile.accountPhone.update.sms.success' });
            })
            .catch(() => {
                fail({ msg: 'account.settings.profile.accountPhone.update.sms.fail' });
            })
    });
    const toggleModal = () => { setOpen(!isOpen) };

    /**
     * @returns {string}
     */
    const getLastFourDigits = useCallback(() => {
        if(auth.userData.UserAttributes.phone_number){
            const hidenNumber = Array(auth.userData.UserAttributes.phone_number.length - 4).join('*');// Creates a string of * of the appropriate amount
            return auth.userData.UserAttributes.phone_number ? hidenNumber + auth.userData.UserAttributes.phone_number.substring(auth.userData.UserAttributes.phone_number.length - 4) : '';
        }
        return '';

    }, [ auth.userData.UserAttributes.phone_number ])

    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='account.settings.profile.accountPhone.phone' />}
                value={
                    <div>
                        {getLastFourDigits()}
                        {(auth.userData.UserAttributes.phone_number && auth.userData.UserAttributes.phone_number_verified !== 'true') &&
                            <div className='small text-warning'>
                                <i className='mdi mdi-alert-outline mr-1' />
                                <Translate id='account.settings.profile.accountPhone.phone.notVerified' />
                            </div>
                        }
                    </div>
                }
                onAction={toggleModal}
                disableEdit={auth.userData.UserMFASettingList.sms ? <span className="font-12 font-medium"><i className="text-primary mdi mdi-information-outline mx-1" /><Translate id='account.settings.profile.accountPhone.2fa.bySms' /></span> : undefined}
            />
            <AnalyticsModal
                analyticsName='AccountPhone' isOpen={isOpen}
                onClosed={() => {
                    setPhone('');
                    setVerify(auth.userData.UserAttributes.phone_number_verified !== 'true' && auth.userData.UserAttributes.phone_number)
                }}
            >
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.phone' />
                </ModalHeader>
                <Fade in={(!phone && !mustVerify) || !auth.userData.UserAttributes.phone_number} className={(!phone && !mustVerify) || !auth.userData.UserAttributes.phone_number ? undefined : 'd-none'} mountOnEnter unmountOnExit>
                    <Formik
                        initialValues={{
                            currentPhone: '',
                            newPhone: '',
                            confirmPhone: '',
                        }}
                        validationSchema={Yup.object().shape({
                            currentPhone: auth.userData.UserAttributes.phone_number ? Yup.string()
                                .test({
                                    name: 'current-phone',
                                    message: <Translate id='form.validation.phone.matchCurrent' />,
                                    test: (currentPhone) => currentPhone === auth.userData.UserAttributes.phone_number,
                                }) : Yup.string(),
                            newPhone: Yup.string().required(<Translate id='form.validation.phone.required' />).isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                            confirmPhone: Yup.string().test({
                                name: 'confirm-phone',
                                message: <Translate id='form.validation.phone.matchConfirm' />,
                                test: function(phone){
                                    return this.parent.newPhone === phone;
                                },
                            }),
                        })}
                        onSubmit={({ newPhone }, { setStatus, setSubmitting }) => {
                            setStatus();
                            auth.updateUserInfo({
                                ...auth.userData.UserAttributes,
                                phone_number: newPhone,
                                phone_number_verified: 'false',
                            }).then(() => {
                                setPhone(newPhone);
                                setStatus();
                                success({ msg: 'account.settings.profile.accountPhone.update' });
                            })
                                .catch((error) => {
                                    setStatus(error.message);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <ModalBody>
                                    {auth.userData.UserAttributes.phone_number &&
                                        <FormGroup>
                                            <Label for='currentPhone'><Translate id='form.fields.currentPhone' /></Label>
                                            <FormikPhoneInput name='currentPhone' id='currentPhone' />
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label for='newPhone'><Translate id='form.fields.newPhone' /></Label>
                                        <FormikPhoneInput name='newPhone' id='newPhone' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='confirmPhone'><Translate id='form.fields.confrimPhone' /></Label>
                                        <FormikPhoneInput name='confirmPhone' id='confirmPhone' />
                                    </FormGroup>
                                    <div className='d-flex align-items-center bg-light-inverse p-1 mt-4 rounded'>
                                        <i className='text-primary mdi mdi-information-outline mx-2' />
                                        <div className='small'>
                                            <Translate id='account.settings.profile.accountPhone.phone.2faOnly' />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='account.settings.profile.accountPhone.verify' /></Button>
                                    <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fade>
                <Fade in={!!phone} className={!phone ? 'd-none' : undefined} mountOnEnter unmountOnExit>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        onSubmit={({ code }, { setStatus, setSubmitting }) => {
                            setStatus();
                            auth.verifyAttributeCode('phone_number', code)
                                .then(() => {
                                    setStatus();
                                    auth.getUserData()
                                        .then(() => {
                                            success({ msg: 'account.settings.profile.accountPhone.update' });
                                            toggleModal();
                                        }, () => {
                                            fail({ msg: 'account.settings.profile.accountPhone.update.fail' });
                                        })
                                })
                                .catch((error) => {
                                    setStatus(error.message);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <ModalBody>
                                    <div className="text-center">
                                        <img className="mb-2" src={SpordleMyAccountLogo} width="150px" alt="Spordle My Account" />
                                        <div className="h5 mb-3"><Translate id='account.settings.profile.accountPhone.2fa.enterCode' /></div>
                                        <div className="h6 text-muted mb-3">{phone}</div>
                                    </div>
                                    <FormGroup>
                                        <div className='mb-3 mx-n2 d-flex'>
                                            <PinField
                                                autoFocus className="min-w-0 form-control mx-2 text-center" validate="0123456789"
                                                disabled={formik.isSubmitting} length={6}
                                                onChange={(code) => {
                                                    formik.setFieldValue('code', code)
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                    {formik.status &&
                                        <Alert color='danger' className='mb-0'><Translate id={`confirm-account.error.${formik.status}`} /></Alert>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting || formik.values.code.length !== 6}><Translate id='misc.confirm' /></Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fade>
                {auth.userData.UserAttributes.phone_number_verified !== 'true' && !phone &&
                    <Fade in={!!mustVerify} className={mustVerify ? undefined : 'd-none'} mountOnEnter unmountOnExit>
                        <ModalBody>
                            <div>
                                <Translate id='account.settings.profile.accountPhone.phone.current.notVerified' />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='primary' outline type='button'
                                onClick={() => {
                                    setVerify(false);
                                }}
                            >
                                <Translate id='account.settings.profile.accountPhone.phone.current.change' />
                            </Button>
                            <Button
                                color='warning' type='button' className='ml-1'
                                onClick={() => {
                                    setPhone(auth.userData.UserAttributes.phone_number);
                                    resendEmail();
                                    setVerify(false)
                                }}
                            >
                                <Translate id='account.settings.profile.accountPhone.verify' />
                            </Button>
                        </ModalFooter>
                    </Fade>
                }
            </AnalyticsModal>
        </>
    );
}

export default AccountPhone;