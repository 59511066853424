export default{
    // general
    "account.transactions.memberProfileTransactions.paymentMethods.CHEQUE"              : "Check",
    "account.transactions.memberProfileTransactions.paymentMethods.CASH"                : "Cash",
    "account.transactions.memberProfileTransactions.paymentMethods.CREDITCARD"          : "Credit card",
    "account.transactions.memberProfileTransactions.paymentMethods.PAYMENTPROXY"        : "Credit card (proxy payment)",
    "account.transactions.memberProfileTransactions.paymentMethods.INTERAC"             : "Interac transfer",
    "account.transactions.memberProfileTransactions.paymentMethods.undefined"           : "N/A",
    "account.transactions.memberProfileTransactions.paymentMethods.ORGANISATION_CREDIT" : "Credited (Free)",
    "account.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT"       : "Member credit",
    "account.transactions.memberProfileTransactions.paymentMethods.FREE"                : "Free",
    "account.transactions.memberProfileTransactions.paymentMethods.EXTERNALPAYMENT"     : "External Payment",
    "account.transactions.memberProfileTransactions.paymentMethods.FUNDING"             : "Funding",
    "account.transactions.memberProfileTransactions.paymentMethods.OFFLINECREDIT"       : "Offline Credit",

    "account.transactions.memberProfileTransactions.registrationType"              : "Type",
    "account.transactions.memberProfileTransactions.registrationType.REGISTRATION" : "Registration",
    "account.transactions.memberProfileTransactions.registrationType.OTHER"        : "Item",
    "account.transactions.memberProfileTransactions.registrationType.CLINIC"       : "Clinic",

    "account.transactions.memberProfileTransactions.registrations" : "Registration(s)",
    "account.transactions.memberProfileTransactions.items"         : "Item(s)",
    "account.transactions.memberProfileTransactions.paidBy"        : "Paid by",
    "account.transactions.memberProfileTransactions.total"         : "Total",
    "account.transactions.memberProfileTransactions.add"           : "Add a credit",
    "account.transactions.memberProfileTransactions.credit"        : "Credit",

    "account.transactions.memberProfileTransactions.noResults" : "No purchases have been found",

    "account.transactions.memberProfileTransactions.csv.invoiceDate"   : "Invoice Date",
    "account.transactions.memberProfileTransactions.csv.invoiceNumber" : "Invoice Number",
    "account.transactions.memberProfileTransactions.csv.total"         : "Total ($)",
    "account.transactions.memberProfileTransactions.csv.paid"          : "Paid ($)",
    "account.transactions.memberProfileTransactions.csv.due"           : "Due ($)",

    // table view
    "account.transactions.memberProfileTransactions.tableView.header.transaction"         : "Transaction",
    "account.transactions.memberProfileTransactions.tableView.header.transaction.tooltip" : "{waiverCount, plural, one {A waiver needs to be signed} other {Waivers need to be signed}}",
    "account.transactions.memberProfileTransactions.tableView.header.members"             : "Member",
    "account.transactions.memberProfileTransactions.tableView.header.status"              : "Status",
    "account.transactions.memberProfileTransactions.tableView.header.paymentMethod"       : "Payment method",
    "account.transactions.memberProfileTransactions.tableView.header.items"               : "Items",
    "account.transactions.memberProfileTransactions.tableView.header.waivers"             : "Waivers",
    "account.transactions.memberProfileTransactions.tableView.header.paid"                : "Paid",
    "account.transactions.memberProfileTransactions.tableView.header.refunded"            : "Refunded",
    "account.transactions.memberProfileTransactions.tableView.header.due"                 : "Due",


    // sidepanel
    "account.transactions.memberProfileTransactions.sidePanel.status.PENDING"     : "Pending",
    "account.transactions.memberProfileTransactions.sidePanel.status.PAID"        : "Paid",
    "account.transactions.memberProfileTransactions.sidePanel.status.COMPLETED"   : "Completed",
    "account.transactions.memberProfileTransactions.sidePanel.status.NEED_REVIEW" : "In review",
    "account.transactions.memberProfileTransactions.sidePanel.status.SUSPENDED"   : "Suspended",
    "account.transactions.memberProfileTransactions.sidePanel.status.DECLINED"    : "Declined",
    "account.transactions.memberProfileTransactions.sidePanel.status.CANCELED"    : "Cancelled",

    "account.transactions.memberProfileTransactions.sidePanel.header.invoice"            : "Invoice",
    "account.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate" : "Initial payment date",

    "account.transactions.memberProfileTransactions.sidePanel.actions.refund" : "Refund",

    "account.transactions.memberProfileTransactions.sidePanel.items.payments" : "Payments",
    "account.transactions.memberProfileTransactions.sidePanel.items.date"     : "Date",
    "account.transactions.memberProfileTransactions.sidePanel.items.status"   : "Status",
    "account.transactions.memberProfileTransactions.sidePanel.items.amount"   : "Amount",
    "account.transactions.memberProfileTransactions.sidePanel.items.price"    : "Price",
    "account.transactions.memberProfileTransactions.sidePanel.items.due"      : "Due",
    "account.transactions.memberProfileTransactions.sidePanel.items.credit"   : "Credit",

    "account.transactions.memberProfileTransactions.sidePanel.credit.delete.message" : "You are about to delete this credit.",
    "account.transactions.memberProfileTransactions.sidePanel.credit.updated"        : "Credit updated!",
    "account.transactions.memberProfileTransactions.sidePanel.credit.creationDate"   : "Creation date",
    "account.transactions.memberProfileTransactions.sidePanel.credit.balance"        : "Balance",

    // add modal
    "account.transactions.memberProfileTransactions.addModal.creditType"     : "Credit type",
    "account.transactions.memberProfileTransactions.addModal.amount"         : "Amount",
    "account.transactions.memberProfileTransactions.addModal.expirationDate" : "Expiration date",
    "account.transactions.memberProfileTransactions.addModal.status"         : "Statut",

    "account.transactions.memberProfileTransactions.addModal.validation.creditType.required"       : "Credit type is required",
    "account.transactions.memberProfileTransactions.addModal.validation.amount.required"           : "Amount is required",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.required"   : "Expiration date is required",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.valid"      : "Expiration date must be of a valid format",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.validAfter" : "Expiration date can't be before today",
}