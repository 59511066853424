import CapsLock from '@spordle/capslock';
// Language
import Translate from '@spordle/intl-elements';
import images from '@spordle/ui-kit';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import React from 'react';
import PinField from 'react-pin-field';
import { Link } from 'react-router-dom';
import {
    Alert, Button,
    Card,
    CardBody, Collapse,
    Fade,
    Spinner
} from 'reactstrap';
import * as Yup from 'yup';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import SpordleLogo from '../../assets/images/logos/spordleMyAccount.svg';
import { AuthenticatorContext } from '../../components/authentication/AuthenticatorProvider';
import FormikInputAuth from '../../components/loginInputs/FormikInputAuth';
import { AuthContext } from "../../contexts/contexts";
import withContexts from '../../helpers/withContexts';
import AuthLayout from '../../layouts/layout-components/auth/AuthLayout';

const noProfileLogo = images.noprofile;

class Login extends React.Component{
    constructor(props){
        super(props);
        this.inputRef = React.createRef();
        this.focusInput = this.focusInput.bind(this);
    }

    state = {
        code: '',
        isSubmitting: false,
        pinCodeError: '',
        showPsw: false,
        signup: '',
    }

    focusInput(){
        if(this.inputRef.current && this.props.AuthenticatorContext.show === 'password'){
            this.inputRef.current.focus();
        }
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    componentDidUpdate(prevP, prevS){
        if((this.props.AuthenticatorContext.show !== prevP.AuthenticatorContext.show) || (prevS.showPsw !== this.state.showPsw)){
            this.focusInput();
        }
    }

    pinHeading = (pinType) => {
        switch (pinType){
            case 'SMS_MFA':
                return (
                    <div className="d-flex px-4 align-items-center">
                        <i className="mdi mdi-comment-processing-outline mr-3" style={{ fontSize: "40px" }} />
                        <p className="mb-0"><Translate id='login.mfa.SMS_MFA.label' />{this.props.AuthenticatorContext.challengeInfo.CODE_DELIVERY_DESTINATION.substring(1)}</p>
                    </div>
                );
            case 'SOFTWARE_TOKEN_MFA':
                return (
                    <div className="d-flex px-4 align-items-center">
                        <i className="mdi mdi-cellphone mr-3" style={{ fontSize: "40px" }} />
                        <p className="mb-0"><Translate id='login.mfa.SOFTWARE_TOKEN_MFA.label' /></p>
                    </div>
                );
            default:
                break;
        }
    }

    render(){
        return (
            <AuthLayout>
                <Card className="card-shadow">
                    <CardBody>
                        {(this.props.AuthenticatorContext.show === 'reactivate' || this.props.AuthenticatorContext.show === 'locked' || this.props.AuthenticatorContext.show === 'SMS_MFA' || this.props.AuthenticatorContext.show === 'SOFTWARE_TOKEN_MFA') &&
                            <button onClick={() => { this.props.AuthenticatorContext.changeViewTo('email') }} className="d-inline-block mb-2 text-dark reset-btn" type='button'>
                                <i className="mdi mdi-chevron-left" />
                                <Translate id='forgotPassword.action.backToLogin' />
                            </button>
                        }

                        <div className="text-center">
                            <img className="mb-3 mt-2 ml-3 mr-3" src={SpordleLogo} alt="Hockey Canada" />
                            {this.props.AuthenticatorContext.show === 'email' &&
                                <div className="h6 mb-3 text-muted"><Translate id='login.card.signIn' /></div>
                            }
                        </div>

                        <Fade in={this.props.AuthenticatorContext.show === 'email'} className={stringBuilder({ 'd-none': this.props.AuthenticatorContext.show !== 'email' })}>
                            <Formik
                                initialValues={{
                                    email: this.props.AuthenticatorContext.email ?? '',
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .required(<Translate id='login.form.validation.email.required' />)
                                        .email(<Translate id='login.form.validation.email.email' />),
                                })}
                                onSubmit={({ email }, { setStatus, setErrors, setSubmitting }) => {
                                    setStatus();
                                    this.props.AuthenticatorContext.checkForUser(email)
                                        .then((data) => {
                                            setSubmitting(false);
                                            if(!data.isSuccess){
                                                // setStatus('login.modal.text');
                                                this.setState({ signup: email });
                                                setErrors({
                                                    email: (
                                                        <>
                                                            <Translate id="login.modal.text" />. <button onClick={() => this.props.AuthenticatorContext.goToPage('SIGNUP', false, { show: 'signup' })} className="reset-btn text-link" type='button'><Translate id='login.form.action.signup' /></button>
                                                        </>
                                                    ),
                                                });
                                            }
                                        }, (error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                switch (error.message){
                                                    case 'UNCONFIRMED':
                                                        this.props.AuthenticatorContext.goToPage('CONFIRMATION', false, { from: 'UNCONFIRMED' });
                                                        break;
                                                    case 'COMPROMISED':
                                                    case 'RESET_REQUIRED':
                                                    default:
                                                        setSubmitting(false);
                                                        console.error(error.message);
                                                        break;
                                                }
                                            }
                                        });
                                }}
                            >
                                {(formik) => (
                                    <Form id="email_form">
                                        <FormikInputAuth id='email' name='email' label='form.fields.email' icon='ti-user' type='email' trim translateLabel autoComplete='email' />

                                        <div className="mb-3">
                                            <Button color="spordle" size="lg" block type='submit' disabled={formik.isSubmitting}>
                                                {formik.isSubmitting ?
                                                    <Spinner size="sm" type='grow' color='light' />
                                                    :
                                                    <>
                                                        <Translate id='login.form.action.submit' />
                                                        <i className="ti-arrow-right ml-2 align-middle" />
                                                    </>
                                                }
                                            </Button>
                                        </div>

                                        <CapsLock>
                                            {(isActive) => (
                                                <Collapse isOpen={isActive}>
                                                    <div className="pt-2">
                                                        <Alert color='warning' className='mb-0'>
                                                            <i className="mr-1 mdi mdi-apple-keyboard-caps" />
                                                            <Translate id='warning.capsLock' />
                                                        </Alert>
                                                    </div>
                                                </Collapse>
                                            )}
                                        </CapsLock>
                                        {formik.status &&
                                            <Alert color='danger'><Translate id={`login.error.${formik.status}`} /></Alert>
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </Fade>
                        {this.props.AuthenticatorContext.show === 'password' &&
                            <Fade in={this.props.AuthenticatorContext.show === 'password'} className={stringBuilder({ 'd-none': this.props.AuthenticatorContext.show !== 'password' })}>
                                <Formik
                                    initialValues={{
                                        password: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        password: Yup.string().required(<Translate id='login.form.validation.password.required' />),
                                    })}
                                    onSubmit={({ password }, { setStatus, setSubmitting, setFieldValue }) => {
                                        setStatus();

                                        this.props.AuthenticatorContext.handleLogin(this.props.AuthenticatorContext.email, password)
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    this.props.AuthenticatorContext.checkForUser(this.props.AuthenticatorContext.email)
                                                        .then(() => {
                                                            if(typeof error.message === 'object'){
                                                                if(error.message.code === 'NotAuthorizedException'){
                                                                    this.attempsLeft = error.message.attempsLeft;
                                                                }
                                                                setStatus(error.message.code);
                                                                setSubmitting(false);
                                                            }else{
                                                                switch (error.message){
                                                                    default:
                                                                        setStatus(error.message);
                                                                        setSubmitting(false);
                                                                        break;
                                                                }
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                if(this.props.AuthenticatorContext.show === "locked"){
                                                                    setFieldValue("password", "");
                                                                }

                                                                console.error(error.message);
                                                                setSubmitting(false);
                                                            }
                                                        })
                                                }
                                            });
                                    }}
                                >
                                    {(formik) => (
                                        <Form id='password_form'>
                                            <div className="mb-4 mt-2">
                                                <div className="d-flex mb-2 align-items-center shadow-sm border p-3 text-left rounded">
                                                    <img className="rounded-circle shadow" src={this.props.AuthenticatorContext.logo ?? noProfileLogo} alt={this.props.AuthenticatorContext.name} width="50" height="50" />
                                                    <div className="ml-2 pr-5">
                                                        <div className="font-medium h5 mb-0">{this.props.AuthenticatorContext.name}</div>
                                                        <div className="text-muted">{this.props.AuthenticatorContext.email}</div>
                                                    </div>
                                                </div>
                                                <button type="button" className="reset-btn text-link" onClick={() => { this.props.AuthenticatorContext.changeViewTo('email', true) }}>
                                                    <i className="ti-arrow-left mr-2" />
                                                    <span><Translate id='login.card.changeUser' /></span>
                                                </button>
                                            </div>
                                            <div className="inputAuth-container">
                                                <FormikInputAuth
                                                    ref={this.inputRef}
                                                    className="mb-1"
                                                    id='password'
                                                    name='password'
                                                    label='login.form.label.password'
                                                    type={this.state.showPsw ? 'text' : 'password'}
                                                    icon='ti-lock'
                                                    trim
                                                    translateLabel
                                                    autoComplete='password'
                                                />
                                                <i
                                                    onClick={() => this.setState((prevState) => ({ showPsw: !prevState.showPsw }))}
                                                    className={`${formik.values.password ? 'show' : 'hide'} inputAuth-showPsw mdi mdi-eye${this.state.showPsw ? '' : '-off'}-outline`}
                                                />
                                            </div>
                                            {this.props.AuthenticatorContext.showForgotPassword &&
                                                <Link
                                                    id="startRecover"
                                                    to={this.props.AuthenticatorContext.goToPage('FORGOT-PASSWORD', true)}
                                                    className="forgot text-link ml-auto d-inline-block"
                                                >
                                                    <Translate id='login.form.action.forgotPassword' />
                                                </Link>
                                            }
                                            <Button color="spordle" size="lg" block type='submit' disabled={formik.isSubmitting} className='mt-3'>
                                                {formik.isSubmitting ?
                                                    <Spinner size="sm" type='grow' color='light' />
                                                    :
                                                    <>
                                                        <Translate id='login.form.action.submit' />
                                                        <i className="ti-arrow-right ml-2 align-middle" />
                                                    </>
                                                }
                                            </Button>
                                            <CapsLock>
                                                {(isActive) => (
                                                    <Collapse isOpen={isActive}>
                                                        <div className="pt-2">
                                                            <Alert color='warning' className='mb-0'>
                                                                <i className="mr-1 mdi mdi-apple-keyboard-caps" />
                                                                <Translate id='warning.capsLock' />
                                                            </Alert>
                                                        </div>
                                                    </Collapse>
                                                )}
                                            </CapsLock>

                                            {formik.status &&
                                                <Collapse isOpen appear>
                                                    <Alert color='danger' className='mb-0 mt-2'><Translate id={`login.error.${formik.status}`} values={{ attempsLeft: this.attempsLeft }} /></Alert>
                                                </Collapse>
                                            }
                                        </Form>
                                    )}
                                </Formik>
                            </Fade>
                        }
                        {(this.props.AuthenticatorContext.show === 'SMS_MFA' || this.props.AuthenticatorContext.show === 'SOFTWARE_TOKEN_MFA') &&
                            <Fade in={this.props.AuthenticatorContext.show === 'SMS_MFA' || this.props.AuthenticatorContext.show === 'SOFTWARE_TOKEN_MFA'} className={stringBuilder({ 'd-none': this.props.AuthenticatorContext.show !== 'SMS_MFA' && this.props.AuthenticatorContext.show !== 'SOFTWARE_TOKEN_MFA' })} mountOnEnter unmountOnExit>
                                <Formik
                                    initialValues={{
                                        code: '',
                                    }}
                                    onSubmit={({ code }, { setStatus, setSubmitting }) => {
                                        setStatus();
                                        this.props.AuthenticatorContext.challengeLogin(code)
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    setStatus(error.message);
                                                    setSubmitting(false);
                                                }
                                            });
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <div className='mb-4'>{this.pinHeading(this.props.AuthenticatorContext.show)}</div>
                                            <div className='d-flex mx-n2 mb-3'>
                                                <PinField
                                                    autoFocus ref={(r) => this.pinRef = r} className="min-w-0 form-control mx-2 text-center"
                                                    validate="0123456789" length={6} disabled={formik.isSubmitting}
                                                    onChange={(code) => {
                                                        formik.setFieldValue('code', code)
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Button className='py-3' color='spordle' block type='submit' disabled={formik.isSubmitting || formik.values.code.length !== 6}>
                                                    {formik.isSubmitting ?
                                                        <Spinner size="sm" type='grow' color='light' />
                                                        :
                                                        <>
                                                            <Translate id='login.form.action.submit' />
                                                            <i className="ti-arrow-right ml-2 align-middle" />
                                                        </>
                                                    }
                                                </Button>
                                            </div>
                                            {formik.status && <Alert className="mt-3 mb-0" color='danger'><Translate id={`confirm-account.error.${formik.status}`} defaultMessageId='confirm-account.error.server' /></Alert>}
                                        </Form>
                                    )}
                                </Formik>
                            </Fade>
                        }
                        {this.props.AuthenticatorContext.show === 'reactivate' &&
                            <Fade in={this.props.AuthenticatorContext.show === 'reactivate'} className={stringBuilder({ 'd-none': this.props.AuthenticatorContext.show !== 'reactivate' })}>
                                <div>
                                    <div className='text-center h4'><Translate id='login.reactivate.title' /></div>
                                    <div className='text-center'>
                                        <Translate id='login.reactivate.message' values={{ email: <a href='mailto:hcr@spordle.com'>hcr@spordle.com</a> }} />
                                    </div>
                                </div>
                            </Fade>
                        }
                        {this.props.AuthenticatorContext.show === 'locked' &&
                            <Fade in={this.props.AuthenticatorContext.show === 'locked'} className={stringBuilder({ 'd-none': this.props.AuthenticatorContext.show !== 'locked' })}>
                                <div className='text-center text-muted'>
                                    <div>
                                        <Translate id='login.locked.title' />
                                    </div>
                                    <div>
                                        <Translate id='login.locked.message' values={{ email: <a href='mailto:hcr@spordle.com'>hcr@spordle.com</a> }} />
                                    </div>
                                </div>
                            </Fade>
                        }
                    </CardBody>
                </Card>
                <div className={stringBuilder('mb-1 text-center', { 'd-none': this.props.AuthenticatorContext.show === 'password' })}>
                    <Translate id='login.form.label.signup' />
                    <Link to={this.props.AuthenticatorContext.goToPage('SIGNUP', true)} className="ml-1">
                        <Translate id='login.form.action.signup' />
                    </Link>
                </div>
            </AuthLayout>
        );
    }
}

export default withContexts(AuthContext, AuthenticatorContext)(Login);
