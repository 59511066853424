export default{
    "hcrSearch.fields.organization" : "Organisation",

    "hcrSearch.search"   : "Rechercher",
    "hcrSearch.noResult" : "Aucun Résultat",
    "hcrSearch.select"   : "Sélectionner",

    "addMemberModal.title"                 : "Lier un membre HCR",
    "addMemberModal.searchResult.existing" : "Membres existants",
    "addMemberModal.searchResult.already"  : "Déjà lié",
    "addMemberModal.searchResult.noResult" : "Oops ! Il semble que le membre que vous cherchez n'existe pas dans notre sytème.",
    "addMemberModal.confirm.text"          : "Vous êtes sur le point de lier ce membre à votre compte.",
    "addMemberModal.confirm.orgSearch"     : "Recherche d'organisation",

    "addMemberModal.button.add"  : "Lier ce membre",
    "addMemberModal.button.link" : "Lier un membre",

    "addMemberModal.formik.label.uniqueIdentifier"         : "Numéro HCR",
    "addMemberModal.formik.label.search.title"             : "Rechercher un membre HCR",
    "addMemberModal.formik.label.search.or"                : "ou",
    "addMemberModal.formik.warning.filters.ignored"        : "Les autres filtres seront ignorés",
    "addMemberModal.formik.validation.dateOfBirth.minDate" : "La date de naissance du membre doit être avant aujourd'hui",
    "addMemberModal.formik.validation.dateOfBirth.minAge"  : "Un membre doit avoir au moins 4 ans pour participer. Cependant, vous pouvez toujours créer ce membre.",
    "addMemberModal.formik.validation.firstName"           : "Le prénom doit être d'un bon format",
    "addMemberModal.formik.validation.lastName"            : "Le nom de famille doit être d'un bon format",

    "addMemberModal.unsignedWaivers" : "Ce membre a des dispenses non-signées",
    "addMemberModal.goToSign"        : "Signer maintenant",

    "addMemberModal.error.2000.btn" : "Cliquez ici pour éditer le prénom et le nom de ce membre.",
}