import { Button } from 'reactstrap';
import { I18nContext } from '../contexts/I18nContext';
import { LOCALES } from '../i18n';

const LangSwitcher = ({ className = "" }) => (
    <I18nContext.Consumer>
        {({ locale, setLocale }) => (locale === 'en-ca') ?
            <Button className={className} color="link" onClick={() => { setLocale(LOCALES.FRENCH) }}>FR</Button>
            :
            <Button className={className} color="link" onClick={() => { setLocale(LOCALES.ENGLISH) }}>EN</Button>
        }
    </I18nContext.Consumer>
)

export default LangSwitcher;