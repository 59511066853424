import Translate from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import { Button, ModalBody, ModalFooter } from 'reactstrap';

const PaymentConfirmation = ({ invoiceNumber, toggle }) => {

    return (
        <>
            <ModalBody>
                <div className="text-center">
                    <i className="mdi mdi-check text-success" style={{ fontSize: 75 }} />
                    <div className='h2 font-bold'>
                        <Translate id='account.paymentMethods.paymentConfirmation.title' />
                    </div>
                    <div>
                        <Translate id='account.paymentMethods.paymentConfirmation.text' values={{ invoiceNumber: <Link id={`payment-confirmation__${invoiceNumber}`} to={`/purchases/invoice/${invoiceNumber}`}>{invoiceNumber}</Link> }} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={toggle} type='button'><Translate id='misc.close' /></Button>
            </ModalFooter>
        </>
    )
}

export default PaymentConfirmation