import React from 'react';
import PropTypes from 'prop-types';
import { Label, FormText, FormGroup } from 'reactstrap';
import { stringBuilder } from "@spordle/helpers";
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormikError } from '@spordle/formik-elements';

/**
 * FormikInputAuth will create an input text that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikInputAuth id={'password'} name={'password'} label={'Password'} type={'password'} icon={'ti-lock'} trim translateLabel={false}/>
 *
 * @prop {string} name Used to make the FormikInputAuth work with validation
 * @prop {string} id input's id
 * @prop {string} className FormGroup's className
 * @prop {bool} [trim] If the field trims on onBlur
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {string|JSX.Element} [helper] FormText after input
 * @prop {boolean} [translateHelper] DEFAULT: <code>true</code>
 * @prop {boolean} [translateLabel] DEFAULT: <code>true</code>
 * @prop {boolean} [manualError] DEFAULT: <code>false</code> - This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns an input text that will work with Formik
 *
 * @copyright Spordle
 */
const FormikInputAuth = React.forwardRef(({ icon, trim, label, helper, translateHelper, translateLabel, autoComplete, manualError, ...props }, ref) => {
    const [ field, meta, helpers ] = useField(props);
    if(trim)
        field.onBlur = (event) => helpers.setValue(event.target.value.trim());

    const intl = useIntl();

    if(translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });

    if(translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });

    return (
        <FormGroup className={props.className}>
            <div className={stringBuilder('inputAuth', { 'has-icon': icon })}>
                <input
                    {...field} {...props} ref={ref}
                    type={props.type ?? 'text'}
                    className={stringBuilder('inputAuth-text', { 'is-empty': !field.value, 'border-danger': meta.touched && meta.error })}
                    autoComplete={autoComplete ?? 'new-password'}
                />
                {icon && <i className={icon} />}
                {label && typeof label === 'string' && <Label className='inputAuth-label' for={props.id}>{label}</Label>}
            </div>
            {helper && typeof helper === 'string' && <FormText color="muted">{helper}</FormText>}
            {!manualError && <FormikError name={props.name} />}
        </FormGroup>
    )
});

FormikInputAuth.propTypes = {
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    placeholder: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    helper: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    manualError: PropTypes.bool,
    trim: PropTypes.bool,
    icon: PropTypes.string,
    className: PropTypes.string,
    autoComplete: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
};

FormikInputAuth.defaultProps = {
    translateHelper: true,
    translateLabel: true,
};

FormikInputAuth.displayName = 'FormikInputAuth';

export default React.memo(FormikInputAuth);