import { useContext, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import useSWR from 'swr';
import { AccountsContext, AuthContext } from '../../../contexts/contexts';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import SportPayReturnModal from './components/SportPayReturnModal';
import Payments from './payments/Payments';
import Vault from './vault/AccountVault';
import ManagePaymentModal from './payments/components/managePaymentModal/ManagePaymentModal';
import { parse, stringifyUrl, parseUrl } from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import Translate from '@spordle/intl-elements';

const AccountPaymentMethodsContent = () => {
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    const location = useLocation();
    const history = useHistory();

    const invoicePaymentId = parse(location.search).invoicePaymentId;

    const [ installmentToPay, setInstallmentToPay ] = useState(null);

    const getOrg = (org) => {
        return (
            org ?
                <span className="d-block text-truncate small">
                    <DisplayI18n
                        field="name"
                        defaultValue={org.organisation_name}
                        i18n={org.i18n}
                    />
                </span>
                :
                null
        )
    }

    /**
     * This function will format the invoice item to a standard object that can easily be used later
     * @param {'CLINIC'|'REGISTRATION'|'OTHER'} type
     * @param {object} item
     * @returns {React.FC}
     */
    const getInvoiceItem = (type, item) => {
        switch (type){
            case 'CLINIC':
                if(item.clinic){
                    return () => (
                        <>
                            {/* <ClinicCategoryBuilder qualification={item.clinic.qualification}/> */}
                            <DisplayI18n field='name' defaultValue={item.clinic?.name} i18n={item.clinic?.i18n} />
                            {getOrg(item.organisation)}
                        </>
                    );
                }else if(item.clinic_fee){
                    return () => (
                        <>
                            <DisplayI18n field='name' i18n={item.clinic_fee?.fee?.i18n} defaultValue={item.clinic_fee?.fee?.name} />
                            {getOrg(item.organisation)}
                        </>
                    );
                }
                break;
            case 'REGISTRATION':
                return () => (
                    <>
                        <DisplayI18n
                            field='name'
                            i18n={item.registration_fee.fee.i18n}
                            defaultValue={item.registration_fee.fee.name}
                        />
                        {getOrg(item.organisation)}
                    </>
                );
            case 'OTHER':
                return () => (
                    <>
                        <Translate id='account.paymentMethods.otherItems' values={{ amount: 0 }} />
                        {getOrg(item.organisation)}
                    </>
                )
            default:
                return () => null;
        }
        return () => null;
    }

    const buildInstallment = (invoice, item, payment, extraData) => {
        // we need to add the affiliation fees total and the other items total to the initial payment
        // because when we open the payment modal for an installment, the initial payment only display the amount of the installment, when in reality the initial payment
        // included the affiliation fees and other items
        const itemInstallments = item.payments
            .sort((paymentA, paymentB) => new Date(paymentA.due_date) - new Date(paymentB.due_date))
            .map((ins) => ({
                ...ins,
                amount: getAmountWithOtherItems(invoice.type === "OTHER" ? { ...ins, amount: 0 } : ins, invoice, item.member),
            }))

        return {
            ...payment,
            amount: getAmountWithOtherItems(payment, invoice, item.member),
            totalAmount: invoice.total_amount,
            invoice_number: invoice.invoice_number,
            shopping_cart_id: invoice.shopping_cart_id,
            organisation: invoice.organisation,
            isPending: payment.status === 'PENDING', // This includes due_date that are today
            InvoiceItem: getInvoiceItem(invoice.type, item),
            installments: itemInstallments,
            item: item,
            isPreInit: invoice.is_pre_init == 1,
            canPayNow: invoice.is_pre_init == 1 && payment.status === 'PENDING',
            otherItems: invoice.invoice_items,
            ...extraData,
        }
    }

    // Member will be null when we want to consider all items instead of items of a specific member -> used in invoice types == "OTHER"
    const getAmountWithOtherItems = (payment, invoice, member = null) => {
        const otherItems = invoice.invoice_items.filter((invoiceItem) => (invoiceItem.clinic_fee !== null || invoiceItem.other_fee !== null) && (!member || member.member_id === invoiceItem.member.member_id)) // other fees or clinic fees

        let total = otherItems.reduce(
            (newAmount, otherItem) => newAmount + parseInt(otherItem.new_amount), 0,
        );

        for(let index = 0; index < invoice.invoice_items.length; index++){ // Adding affiliation fees amount
            const item = invoice.invoice_items[index];
            if(item.affiliation_fee && member.member_id === item.member.member_id)
                total += parseInt(item.new_amount);
        }

        // only want to add the other items amount on the initial payment
        // the other items can only be paid on the first payment 100% of the time
        return parseInt(payment.amount) + (payment.payment_type === 'INITIAL_PAYMENT' ? total : 0)
    }

    const getCreditsAmount = (invoice) => {
        return invoice.credits?.reduce((creditsAmount, credit) => creditsAmount + parseInt(credit.amount), 0) || 0;
    }

    const manageOtherItemsPayments = (invoice, installments) => {
        const item = invoice.invoice_items.find((invoiceItem) => invoiceItem.payments?.length > 0);
        const payment = item?.payments?.[0];

        if(payment?.provider){
            installments.push(
                buildInstallment(invoice, item, payment, {
                    amount: getAmountWithOtherItems({
                        ...payment,
                        amount: 0, // to "trick" the function to make this look like a registration fee
                    }, invoice, null),
                }),
            )
        }
    }

    const manageClinicPayment = (invoice, installments) => {
        const clinicItem = invoice.invoice_items.find((invoiceItem) => invoiceItem.clinic);
        if(clinicItem){ // Older invoices are broken :(
            const payment = invoice.invoice_items.find((invoiceItem) => invoiceItem.payments?.length > 0)?.payments[0];
            if(payment){ // Some invoices might be free thus, won't have any payments -> don't show in the list
                installments.push(buildInstallment(invoice, clinicItem, payment, {
                    otherItems: [],
                    amount: invoice.total_amount - getCreditsAmount(invoice), // This will override the amount calculation which is wrong for Clinics
                    installments: [ { // Simulating registration with one installment to be shown the same way as a single installment
                        ...payment,
                        amount: invoice.total_amount - getCreditsAmount(invoice),
                        payment_type: 'INITIAL_PAYMENT',
                    } ],
                }));
            }
        }
    }

    // Not used anymore to filter out cancelled payments - users must see cancelled items in case a payment is due
    // const isCancelled = (invoiceItem, payment) => {
    //     return payment.status === 'CANCELED' || !!invoiceItem.cancelled_by;
    // }

    const { data, isValidating: isValidatingInvoices, error, mutate } = useSWR(
        [ 'accountInvoices', auth.userData.Username ],
        () => accountsContext.getAccountInvoices(auth.userData.Username)
            .then((invoices) => { // Formatting invoices to a single array of installments
                const installments = invoices.reduce((installments, invoice) => {
                    // If my transaction AND payment method is CREDITCARD AND comes from online
                    if(!(invoice.identity?.identity_id === auth.userData.Username && invoice.origin === 'ONLINE' && invoice.invoice_payment_method?.code === "CREDITCARD")){
                        return installments;
                    }

                    switch (invoice.type){
                        case 'OTHER':
                            manageOtherItemsPayments(invoice, installments);
                            break;
                        case 'CLINIC':
                            manageClinicPayment(invoice, installments);
                            break;
                        default:
                            for(let invoiceItemsIndex = 0; invoiceItemsIndex < invoice.invoice_items.length; invoiceItemsIndex++){
                                const item = invoice.invoice_items[invoiceItemsIndex];

                                // initial payment is probably in the affiliation fees
                                if(item.payments_to_display && !item.payments.find((payment) => payment.payment_type === 'INITIAL_PAYMENT')){
                                    item.payments.unshift({
                                        ...item.payments_to_display.find((payment) => payment.payment_type === 'INITIAL_PAYMENT'),
                                        amount: 0, // because the function to get the amount will add the affiliation fees later
                                    })
                                }

                                for(let paymentsIndex = 0; paymentsIndex < item.payments.length; paymentsIndex++){
                                    const payment = item.payments[paymentsIndex];
                                    if(!item.other_fee && !item.affiliation_fee && payment.provider){
                                        installments.push(buildInstallment(invoice, item, payment, {
                                            otherItems: [],
                                        }));
                                    }
                                }
                            }
                            break;
                    }

                    return installments;
                }, []);
                return installments;
            })
            .then((installments) => { // Sorting due date
                return installments.sort((installmentA, installmentB) => new Date(installmentA.due_date) - new Date(installmentB.due_date))
            })
        ,
        {
            onSuccess: (installments) => {
                openModal(installments);
            },
        },
    );

    useEffect(() => {
        openModal()
    }, [ invoicePaymentId ])

    /**
     * Function that opens the payment overview modal
     */
    const openModal = (installments = data) => {
        if(invoicePaymentId && Array.isArray(installments)){
            setInstallmentToPay(installments.find((installment) => installment.invoice_payment_id === invoicePaymentId))
            const parsedUrl = parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
            delete parsedUrl.query.invoicePaymentId;
            history.replace(stringifyUrl(parsedUrl))
        }
    }

    return (
        <Container>
            {installmentToPay &&
                <ManagePaymentModal
                    isOpen={!!installmentToPay}
                    toggle={() => setInstallmentToPay(null)}
                    invoiceItem={installmentToPay}
                    transactionType={installmentToPay.provider === "QUICK_ENROLLMENT" ? "qe" : 'paysafe'}
                    updateInvoices={mutate}
                    paymentMode
                />
            }

            <SportPayReturnModal updatePaymentList={mutate} installments={data} />
            <Vault />
            <Payments updatePaymentList={mutate} invoicesIsLoading={isValidatingInvoices} installments={data} error={error} />
        </Container>
    )
}

export default AccountPaymentMethodsContent
