import React, { createContext } from 'react';
import API_PUBLIC from '../api/API-Public';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<AddressTypesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const AddressTypesContext = createContext();
AddressTypesContext.displayName = 'AddressTypesContext';

class AddressTypesContextProvider extends React.Component{

    /**
     * Get all the Addess Types within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Addresstypes/Apicontroller%5COrganisations%5CAddresstypes%3A%3Aindex|documentation}
     * @returns {Promise<Array>}
     */
    getAddressTypes = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: '/address-types',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.federation.organisation_id, // put fed Id here
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.address_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <AddressTypesContext.Provider value={{ ...this }}>
                {this.props.children}
            </AddressTypesContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(AddressTypesContextProvider);