import { FormikCheckedButton, FormikInputNumber, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { Col, Label, Row } from "reactstrap";
import { bool, number, object, string } from 'yup';
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import { useTooltip } from "../../../../../../../../components/customHooks/useTooltip";
import FormikEditable from "../../../../../../../../components/formik/FormikEditable";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import CollapsibleCard from "../../../CollapsibleCard";
import CrossFade from "../../../CrossFade";
import { GAME_PERIODS } from "../../../gameIncidentHelper";

const GameIncidentCard = ({ incident, handleDelete, index, updateValues, suspensions, suspensionTypes }) => {
    const { formatMessage } = useIntl();
    const i18nContext = useContext(I18nContext);
    const tooltipProps = useTooltip('react-tooltip-gameincident', formatMessage({ id: 'misc.delete' }));

    return (
        <CollapsibleCard
            title={incident.member.first_name + ' ' + incident.member.last_name}
            subtitle={
                <>
                    {!!incident.jersey_number &&
                        <span className="mr-1">
                            #{incident.jersey_number} -
                        </span>
                    }
                    <DisplayI18n
                        field="name"
                        defaultValue={incident.team.name}
                        i18n={incident.team.i18n}
                    />
                </>
            }
            deleteBtn={
                index === 0 ?
                    null
                    :
                    <ConfirmModal
                        modalMsg='account.members.gir.sidePanel.delete.incident'
                        translateModalMsg
                        modalTitle={incident.member.first_name + ' ' + incident.member.last_name}
                        toggler={(fn) => (
                            <button
                                {...tooltipProps}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    fn();
                                }}
                                className="ml-auto p-3 m-n3 align-self-start reset-btn text-danger"
                                type="button"
                            >
                                <small><i className="fas fa-trash" /></small>
                            </button>
                        )}
                        onConfirm={handleDelete}
                    />
            }
            className="mb-3"
            initOpen={index === 0}
        >
            <UserDisplay.Container>
                <Label className="d-block mb-0 text-muted"><Translate id='account.members.gir.addModal.label.infractionType' /></Label>
                <FormikEditable
                    id="suspension_type_id"
                    noConfirmation
                    initialValues={{
                        suspension_type_id: incident.suspension_type.suspension_type_id || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        suspension_type_id: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.suspension_type_id !== incident.suspension_type.suspension_type_id){
                            const newSuspensionType = suspensionTypes.find((s) => s.suspension_type_id === values.suspension_type_id);
                            updateValues(incident.game_incident_player_id, values, { suspension_type: newSuspensionType });
                        }
                    }}
                >
                    {(isEditing, editableOptions) => {
                        if(!isEditing){
                            return (
                                <div>
                                    <DisplayI18n
                                        field="name"
                                        i18n={incident.suspension_type.i18n}
                                        defaultValue={incident.suspension_type.name}
                                    />
                                </div>
                            )
                        }
                        return (
                            <CrossFade isVisible={isEditing}>
                                <FormikSelect
                                    id='suspension_type_id' name='suspension_type_id'
                                    autoFocus menuIsDefaultOpen
                                    searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    options={suspensionTypes?.map((suspension) => ({
                                        label: suspension.name,
                                        value: suspension.suspension_type_id,
                                        i18n: suspension.i18n,
                                    }))}
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    onOptionSelected={editableOptions.stopEditing}
                                />
                            </CrossFade>
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted"><Translate id='account.members.gir.addModal.label.infraction' /></Label>
                <FormikEditable
                    id="suspension_id"
                    noConfirmation
                    initialValues={{
                        suspension_id: incident.suspension.suspension_id || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        suspension_id: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.suspension_id !== incident.suspension.suspension_id){
                            const newSuspension = suspensions.find((s) => s.suspension_id === values.suspension_id);
                            updateValues(incident.game_incident_player_id, values, { suspension: newSuspension });
                        }
                    }}
                >
                    {(isEditing, editableOptions) => {
                        if(!isEditing){
                            return (
                                <div>
                                    <DisplayI18n
                                        field="name"
                                        i18n={incident.suspension.i18n}
                                        defaultValue={incident.suspension.name}
                                    />
                                </div>
                            )
                        }
                        return (
                            <CrossFade isVisible={isEditing}>
                                <FormikSelect
                                    id='suspension_id' name='suspension_id'
                                    autoFocus menuIsDefaultOpen
                                    searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    options={suspensions?.map((suspension) => ({
                                        label: suspension.name,
                                        value: suspension.suspension_id,
                                        i18n: suspension.i18n,
                                    }))}
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    onOptionSelected={editableOptions.stopEditing}
                                />
                            </CrossFade>
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted"><Translate id='account.members.gir.addModal.label.period' /></Label>
                <FormikEditable
                    id="description"
                    initialValues={{
                        game_period: incident.game_period || '',
                        period_minutes: incident.period_minutes || '',
                        period_seconds: incident.period_seconds || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        game_period: string(),
                        period_minutes: number().max(20, <Translate id='account.members.gir.addModal.validation.time.max' values={{ max: 20 }} />).min(0, <Translate id='account.members.gir.addModal.validation.time.min' />),
                        period_seconds: number().max(59, <Translate id='account.members.gir.addModal.validation.time.max' values={{ max: 60 }} />).min(0, <Translate id='account.members.gir.addModal.validation.time.min' />),
                    })}
                    onSubmit={(values) => {
                        const apiValues = {};
                        if(values.game_period !== incident.game_period){
                            apiValues.game_period = values.game_period;
                        }

                        if(values.period_minutes !== incident.period_minutes){
                            apiValues.period_minutes = values.period_minutes;
                        }

                        if(values.period_seconds !== incident.period_seconds){
                            apiValues.period_seconds = values.period_seconds;
                        }

                        if(Object.keys(apiValues).length > 0){
                            updateValues(incident.game_incident_player_id, apiValues);
                        }
                    }}
                >
                    {(isEditing, _options) => {
                        if(!isEditing){
                            return (
                                <div>{ incident.game_period &&
                                    <span className="mr-1"><Translate id={`account.members.gir.addModal.label.gamePeriod.${incident.game_period}`} /> -</span>
                                }
                                {incident.period_minutes && incident.period_minutes + 'm'} {incident.period_seconds && incident.period_seconds + 's'}
                                </div>
                            )
                        }
                        return (
                            <CrossFade isVisible={isEditing}>
                                <Row form>
                                    <Col xs="6">
                                        <small><Translate id='account.members.gir.addModal.label.period' /></small>
                                        <FormikSelect
                                            id='game_period' name='game_period'
                                            autoFocus menuIsDefaultOpen isClearable
                                            search={false}
                                            options={GAME_PERIODS?.map((period) => ({
                                                label: `account.members.gir.addModal.label.gamePeriod.${period}`,
                                                value: period,
                                            }))}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <small><Translate id='account.members.gir.addModal.label.minutes' /></small>
                                        <FormikInputNumber
                                            name="period_minutes"
                                            max={59}
                                            format='##'
                                            min={0}
                                            translatePlaceholder={false}
                                            placeholder="00"
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <small><Translate id='account.members.gir.addModal.label.seconds' /></small>
                                        <FormikInputNumber
                                            name="period_seconds"
                                            max={59}
                                            format='##'
                                            min={0}
                                            translatePlaceholder={false}
                                            placeholder="00"
                                        />
                                    </Col>
                                </Row>
                            </CrossFade>
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted">Description</Label>
                <FormikEditable
                    id="description"
                    initialValues={{
                        description: incident.description || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        description: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.description !== incident.description){
                            updateValues(incident.game_incident_player_id, values);
                        }
                    }}
                >
                    {(isEditing, _options) => {
                        if(!isEditing){
                            return (
                                <div>{incident.description || '-'}</div>
                            )
                        }
                        return <FormikTextArea trim name="description" />

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted">Injury</Label>
                <FormikEditable
                    id="injured"
                    initialValues={{
                        injured: incident.injured == "1",
                        returned_to_game: incident.returned_to_game == "1",
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        injured: bool(),
                        returned_to_game: bool(),
                    })}
                    onSubmit={(values) => {
                        if((values.injured !== incident.injured == "1") || ((values.returned_to_game !== incident.returned_to_game == "1") && values.injured)){
                            const apiVal = {
                                injured: +values.injured,
                            }
                            if(values.injured){
                                apiVal.returned_to_game = +values.returned_to_game;
                            }
                            updateValues(incident.game_incident_player_id, apiVal);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div>
                                    {incident.injured == "1" ?
                                        <>
                                            <span className='mr-1'>
                                                <Translate id='account.members.gir.addModal.label.injury' />
                                            </span>
                                            {incident.returned_to_game ?
                                                <small>(<Translate id='account.members.gir.addModal.label.returned' />)</small>
                                                :
                                                <small>(<Translate id='account.members.gir.addModal.label.notReturned' />)</small>
                                            }
                                        </>
                                        :
                                        'No injury'
                                    }
                                </div>
                            )
                        }
                        return (
                            <div className="mt-1">
                                <FormikCheckedButton label='account.members.gir.addModal.label.injury' name="injured" />
                                <CrossFade isVisible={formik.values.injured}>
                                    <FormikCheckedButton className="mt-2" label='account.members.gir.addModal.label.returned' name="returned_to_game" />
                                </CrossFade>
                            </div>
                        )

                    }}
                </FormikEditable>
            </UserDisplay.Container>
        </CollapsibleCard>
    );
}

export default GameIncidentCard;