/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {CustomInput}from'reactstrap';import {useIntl}from'react-intl';import {useField}from'formik';import {memo,forwardRef}from'react';/**
 * @member FormikCheckedButton
 * @description
 * FormikCheckedButton will create a checkbox that will work automagically with Fromik(yes it's a typo :))
 * IMPORTANT -> Validation do work but are not displayed here, check FormikGroup to display validation errors
 * @example
 * <FormikCheckedButton id="exampleCustomCheckbox" label="Remember me" name="exampleCustomCheckbox"/>
 * <FormikCheckedButton id="terms" name="terms" label={<>blabla<AnotherComponent/></>} translateLabel={false}/>
 *
 * @prop {string} name Used to make the FormikCheckedButton
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {boolean} [translateLabel=true]
 * @returns {JSX.Element} Returns a checkbox that will work with Formik
 *
 * @copyright Spordle
 */
const FormikCheckedButton = forwardRef(({ label, translateLabel, ...props }, ref) => {
    // @ts-ignore
    const [field] = useField({ ...props, type: 'checkbox' });
    const intl = useIntl();
    if (translateLabel && label)
        label = intl.formatMessage({ id: label });
    if (!props.id)
        props.id = props.name;
    return (jsx("div", { children: jsx(CustomInput, { ...field, ...props, ref: ref, label: label, type: "checkbox" }) }));
});
FormikCheckedButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateLabel: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
FormikCheckedButton.defaultProps = {
    translateLabel: true,
};
FormikCheckedButton.displayName = 'FormikCheckedButton';
const FormikCheckedButton$1 = memo(FormikCheckedButton);export{FormikCheckedButton$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/