import Axios from "axios";

export const serverError = (error) => {
    if(Axios.isCancel(error)){
        throw new Error('Cancelled');
    }else if(error.message == 'forbidden'){
        throw new Error("forbidden");
    }else if(error.message == 'unauthorized'){
        throw new Error("unauthorized");
    }else if(error.message == 'teapot'){
        throw new Error("teapot");
    }else{
        throw new Error("server");
    }
}

export let activeCalls = [];

export const AxiosCancelAll = () => {
    activeCalls.forEach((call) => { call() });
    activeCalls = [];
}

export const AxiosIsCancelled = (message) => message === 'Cancelled' || message === 'forbidden' || message === 'unauthorized' || message === 'teapot';