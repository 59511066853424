export default{
    // Example of a specific field  : Organization Name (only used in organization)
    // Fields
    "form.fields.streetNumber"                  : "Street number",
    "form.fields.address"                       : "Address",
    "form.fields.address2.placeholder"          : "Apartment, suite, unit, floor, building, etc.",
    "form.fields.addressType"                   : "Address Type",
    "form.fields.city"                          : "City",
    "form.fields.confrimEmail"                  : "Confirm email",
    "form.fields.confrimPhone"                  : "Confirm Phone number",
    "form.fields.country.ca"                    : "Canada",
    "form.fields.country"                       : "Country",
    "form.fields.currentEmail"                  : "Current email",
    "form.fields.currentPhone"                  : "Current Phone Number",
    "form.fields.date"                          : "Date",
    "form.fields.dateOfBirth"                   : "Date of Birth",
    "form.fields.description.lang"              : "Description ({lang})",
    "form.fields.description"                   : "Description",
    "form.fields.email"                         : "Email",
    "form.fields.extension"                     : "Extension",
    "form.fields.fees"                          : "Fees",
    "form.fields.firstName"                     : "First Name",
    "form.fields.forms"                         : "Forms",
    "form.fields.gender.female"                 : "Female",
    "form.fields.gender.FEMALE"                 : "Female",
    "form.fields.gender.male"                   : "Male",
    "form.fields.gender.MALE"                   : "Male",
    "form.fields.gender.OTHER"                  : "X",
    "form.fields.gender.MIXED"                  : "Mixed",
    "form.fields.gender.other"                  : "X",
    "form.fields.gender.placeholder"            : "Select a gender",
    "form.fields.gender.prefer_not_to_say"      : "X",
    "form.fields.gender"                        : "Gender Identity",
    "form.fields.general"                       : "General",
    "form.fields.generalInfo"                   : "General Information",
    "form.fields.language"                      : "Language",
    "form.fields.lastConnection"                : "Last connection",
    "form.fields.lastName"                      : "Last Name",
    "form.fields.mobilePhone"                   : "Mobile phone",
    "form.fields.name.lang"                     : "Name ({lang})",
    "form.fields.name"                          : "Name",
    "form.fields.nationality.CA"                : "Canadian",
    "form.fields.nationality.placeholder"       : "Select a citizenship",
    "form.fields.nationality.selected"          : "{count} citizenships selected",
    "form.fields.nationality.US"                : "American",
    "form.fields.nationality"                   : "Citizenship",
    "form.fields.newEmail"                      : "New email",
    "form.fields.newPhone"                      : "New Phone Number",
    "form.fields.oldEmail"                      : "Old email",
    "form.fields.oldPassword.required"          : "Old Password is required",
    "form.fields.oldPassword"                   : "Old Password",
    "form.fields.password.length.max"           : "Must be less than 99 characters long",
    "form.fields.password.length.min"           : "Must be at least 8 characters long",
    "form.fields.password.letter"               : "Must contain one letter",
    "form.fields.password.lowercase"            : "Must contain one lowercase",
    "form.fields.password.number"               : "Must contain 1 number",
    "form.fields.password.required"             : "Password required",
    "form.fields.password.space"                : "Password cannot have spaces",
    "form.fields.password.specialChar"          : "Password must have a special character ",
    "form.fields.password.uppercase"            : "Must contain one uppercase",
    "form.fields.password"                      : "Password",
    "form.fields.phone"                         : "Phone Number",
    "form.fields.phoneType"                     : "Phone type",
    "form.fields.primaryLanguage.placeholder"   : "Select a language",
    "form.fields.primaryLanguage"               : "Primary Language",
    "form.fields.province"                      : "Province",
    "form.fields.relation.CHILD"                : "Child",
    "form.fields.relation.DAUGHTER"             : "Daughter",
    "form.fields.relation.FATHER"               : "Father",
    "form.fields.relation.MOTHER"               : "Mother",
    "form.fields.relation.OTHER"                : "Other",
    "form.fields.relation.SON"                  : "Son",
    "form.fields.relation"                      : "Relation",
    "form.fields.secondaryLanguages"            : "Secondary Language",
    "form.fields.short_name.lang"               : "Short name ({lang})",
    "form.fields.short_name"                    : "Short name",
    "form.fields.state.ca.ab"                   : "Alberta",
    "form.fields.state.ca.bc"                   : "British Columbia",
    "form.fields.state.ca.mb"                   : "Manitoba",
    "form.fields.state.ca.nb"                   : "New Brunswick",
    "form.fields.state.ca.nl"                   : "Newfoundland and Labrador",
    "form.fields.state.ca.ns"                   : "Nova Scotia",
    "form.fields.state.ca.nt"                   : "Northwest Territories",
    "form.fields.state.ca.nu"                   : "Nunavut",
    "form.fields.state.ca.on"                   : "Ontario",
    "form.fields.state.ca.pe"                   : "Prince Edward Island",
    "form.fields.state.ca.qc"                   : "Quebec",
    "form.fields.state.ca.sk"                   : "Saskatchewan",
    "form.fields.state.ca.yt"                   : "Yukon",
    "form.fields.state"                         : "State",
    "form.fields.status"                        : "Status",
    "form.fields.title.lang"                    : "Title ({lang})",
    "form.fields.title"                         : "Title",
    "form.fields.zip"                           : "Postal code",
    "form.fields.format"                        : "Format",
    "form.fields.identifyAsIndigenous"          : "Identify as Indigenous",
    "form.fields.identifyAsIndigenous.required" : "Please specify if you identify as Indigenous.",
    "form.fields.indigenousGroup"               : "Indigenous group",
    "form.fields.indigenousGroup.required"      : "Indigenous group is required.",
    "form.fields.ethnicity"                     : "Ethnicity",
    "form.fields.ethnicity.required"            : "Ethnicity is required.",
    "form.fields.ethnicityNote"                 : "Note",
    "form.fields.ethnicityNote.required"        : "Note is required.",
    "form.fields.birthCountry"                  : "Birth country",
    "form.validation.required"                  : "Required field",
    "form.validation.max"                       : "Max",
    "form.validation.min"                       : "Min",
    "form.validation.streetNumber.required"     : "Street number is required",
    "form.validation.address.required"          : "Address is required",
    "form.validation.addressType.required"      : "Address Type is required",
    "form.validation.city.required"             : "City is required",
    "form.validation.country.required"          : "Country is required",
    "form.validation.date.format"               : "Date must be of a valid format",
    "form.validation.dateOfBirth.format"        : "Date of Birth must be of a valid format",
    "form.validation.dateOfBirth.required"      : "Date of birth is required",
    "form.validation.dateOfBirth.valid"         : "Date of birth must be of a valid format",
    "form.validation.description.required"      : "Description is required",
    "form.validation.email.matchConfirm"        : "Emails must match",
    "form.validation.email.matchCurrent.not"    : "Email cannot be the same email",
    "form.validation.email.matchCurrent"        : "Email must match your current email",
    "form.validation.email.required"            : "Email is required",
    "form.validation.email.valid"               : "Email must be valid",
    "form.validation.ethnicity.required"        : "Ethnicity is required",
    "form.validation.firstName.required"        : "First name is required",
    "form.validation.gender.required"           : "Gender is required",
    "form.validation.language.required"         : "Language is required",
    "form.validation.lastName.required"         : "Last name is required",
    "form.validation.name.lang.required"        : "Name ({lang}) is required",
    "form.validation.name.required"             : "Name is required",
    "form.validation.nationality.required"      : "Citizenship is required",
    "form.validation.phone.matchConfirm"        : "Phone numbers must match",
    "form.validation.phone.matchCurrent"        : "Phone number must match your current phone number",
    "form.validation.phone.required"            : "Phone number is required",
    "form.validation.phone.valid"               : "Phone number must be valid",
    "form.validation.phoneType.required"        : "Phone type is required",
    "form.validation.primaryLanguage.required"  : "Primary language is required",
    "form.validation.relation.required"         : "Relation is required",
    "form.validation.state.required"            : "State is required",
    "form.validation.province.required"         : "Province is required",
    "form.validation.title.required"            : "Title is required",
    "form.validation.zip.required"              : "Zip code is required",
    "form.validation.zip.valid"                 : "Zip code must be valid",
    "form.validation.number"                    : "This value must be a number",
    "form.validation.number.positive"           : "This value must be a positive number",

    "form.fields.waiver.required"      : "Please select an option.",
    "form.fields.waiver.defaultAccept" : "I have read and accept the waiver",
    "form.fields.waiver.doNotSign"     : "I prefer not to sign",
    "form.fields.waiver.notMandatory"  : "This waiver is not mandatory",
    "form.fields.waiver.mandatory"     : "This waiver is mandatory",
    "form.fields.waiver.clear"         : "Clear selected",
    "form.fields.waiver.VERBAL"        : "Verbal agreement collected",
    "form.fields.waiver.PHYSICAL"      : "Physical agreement collected",
    "form.fields.waiver.ONLINE"        : "Online agreement collected",
    // Canada
    // Error messages (Yup)
}