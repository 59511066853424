import React, { createContext } from 'react';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { displayI18n } from '../helpers/i18nHelper';
import { I18nContext } from './I18nContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<PeriodsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const PeriodsContext = createContext();
PeriodsContext.displayName = 'PeriodsContext';


class PeriodsContextProvider extends React.Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {
            periods: [],
            selectedPeriod: {},
        }

        this.state = this._initState;
    }

    /**
     * [GET] Gets periods of an org
     * @param {string} orgId
     * @param {object} [queryParams={}]
     * @returns {Promise.<object[]>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payment%20Receptions/cdca28a9a86c98d54711941ce967df0f|documentation}
     */
    getPeriods = (orgId, queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `periods`,
            query: { organisation_id: orgId, active: 1, ...queryParams },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const _periods = response.data.periods?.sort((a, b) => {
                        const valueA = (displayI18n("name", a.i18n, a.name, this.props.I18nContext.getGenericLocale())).toLowerCase()
                        const valueB = (displayI18n("name", b.i18n, b.name, this.props.I18nContext.getGenericLocale())).toLowerCase()
                        if(valueA > valueB)
                            return 1;
                        else if(valueA < valueB)
                            return -1;
                        return 0;
                    }).reduce((activeP, period) => {
                        if(period.active == "1"
                            && !!period.start_date
                            && !!period.end_date
                            && period.start_date !== "0000-00-00"
                            && period.end_date !== "0000-00-00"
                        ){
                            activeP.push(period);
                        }
                        return activeP;
                    }, []);

                    this.setState(() => ({
                        periods: _periods,
                        selectedPeriod: _periods.find((period) => period.active == 1 && period.current == 1),
                    }));
                    return _periods;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get active period
     * @param {*} orgId
     * @param {*} queryParams
     * @returns
     */
    getActivePeriod = (orgId, queryParams = {}) => {
        if(this.state.selectedPeriod){
            return Promise.resolve(this.state.selectedPeriod)
        }

        return this.getPeriods(orgId, queryParams = {})
            .then(() => this.state.selectedPeriod)
    }

    render(){
        return (
            <PeriodsContext.Provider value={{ ...this }}>
                {this.props.children}
            </PeriodsContext.Provider>
        )
    }
}

export default withContexts(I18nContext)(PeriodsContextProvider);