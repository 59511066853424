import Translate, { DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useIntl } from 'react-intl';
import MC from '../../../../../../assets/images/logos/mastercard_white.png';
import VISA from '../../../../../../assets/images/logos/visa_white.png';

const types = { //
    'mastercard': MC,
    'visa': VISA,
}

const VaultCard = ({ className, isExpired, color = "dark", type, name, canEdit = false, lastDigits, expirationMonth, expirationYear, cardId, paymentToken, mutate }) => {
    const intl = useIntl();
    // const accountsContext = useContext(AccountsContext);
    // const authContext = useContext(AuthContext);

    return (
        <div className={stringBuilder(`vault-card-${color}`, className)}>
            <div className="vault-card-top">
                {/* {canEdit &&
                    <UncontrolledDropdown inNavbar>
                        <DropdownToggle color="link" data-toggle="dropdown" className="vault-card-edit" id={`vault-card-more-actions-${cardId}`}>
                            <i className="fa fa-cog" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <ConfirmModal
                                translateModalTitle
                                modalTitle='account.paymentMethods.removeCard.modal.title'
                                modalContent={
                                    <div style={{ maxWidth: 300 }} className="mb-2 text-left mx-auto">
                                        <VaultCard color="danger" name={name} lastDigits={lastDigits} isExpired={isExpired} expirationMonth={expirationMonth} expirationYear={expirationYear} type={type} />
                                    </div>
                                }
                                onConfirm={() => {
                                    return accountsContext.deletePaysafeCard(authContext.userData.Username, paymentToken)
                                        .then(() => {
                                            success({ msg: <Translate id='account.paymentMethods.removeCard.modal.success' />, skipMsgTranslate: true });
                                            mutate();
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                                            }
                                        })
                                }}
                                toggler={(fn) => (
                                    <DropdownItem onClick={fn} id={`vault-card-delete-${cardId}`}>
                                        <Translate id="misc.remove" />
                                    </DropdownItem>
                                )}
                            />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                } */}
            </div>
            <div className="vault-card-number" aria-label={intl.formatMessage({ id: 'account.paymentMethods.label.card.accessibility.endingWith' }, { lastDigits: lastDigits })}>
                **** **** **** {lastDigits || '****'}
            </div>
            <div className="vault-card-name">
                {name}
            </div>
            <div className="vault-card-bottom">
                <div className="vault-card-date">
                    <div className="vault-card-label">
                        <Translate id='account.paymentMethods.label.validUntil' />
                    </div>
                    <span>
                        <DateFormat format='MM/YY' value={[ expirationYear, expirationMonth ]} />
                        {isExpired &&
                            <small className="text-light-danger ml-1">
                                <i className="mdi mdi-alert-outline mr-1" /><Translate id='account.paymentMethods.label.cardExpired' />
                            </small>
                        }
                    </span>
                </div>
                {!!types[type] &&
                    <img className="vault-card-brand" src={types[type]} alt={type} />
                }
            </div>
        </div>
    )
}

export default VaultCard;