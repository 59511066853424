import React, { useContext } from 'react';
import moment from 'moment';
import EventList from '../events/EventList';
import { ScheduleContext } from '../Schedule';
import FadeIn from '../../animations/FadeIn';
import Translate from '@spordle/intl-elements';

// NEEDS CLEANUP
const ScheduleUpcoming = ({ maxDays = 3 }) => {
    const { events, today, onEventClick } = useContext(ScheduleContext);
    const getUpcomingEvents = () => {
        let filteredEvents = [];

        if(events){
            const eventsArr = Object.keys(events);
            const currentDay = parseInt(today.format('D'));
            const currentMonth = parseInt(today.format('M'));

            // eslint-disable-next-line no-cond-assign
            for(let i = 0, year; year = eventsArr[i]; i++){ // Years
                if(filteredEvents.length > maxDays)break;

                if(parseInt(year) >= today.year()){
                    const monthsArr = Object.keys(events[year]);

                    // eslint-disable-next-line no-cond-assign
                    for(let y = 0, month; month = monthsArr[y]; y++){ // Months
                        if(filteredEvents.length > maxDays)break;

                        if(parseInt(month) >= currentMonth){
                            const daysArr = Object.keys(events[year][month]);

                            // eslint-disable-next-line no-cond-assign
                            for(let d = 0, day; day = daysArr[d]; d++){ // Days
                                if(filteredEvents.length > maxDays)break;
                                if(!(parseInt(month) == currentMonth && day < currentDay)){
                                    filteredEvents.push({
                                        momentObj: moment(`${year}-${month}-${day}`),
                                        key: `${year}-${month}-${day}`,
                                        year: year,
                                        month: month,
                                        event: events[year][month][day],
                                    })
                                }
                            }

                        }
                    }
                }
            }
        }

        if(filteredEvents.length > 0){
            // Filters events in order
            filteredEvents = filteredEvents.sort((aEvent, bEvent) => {
                return aEvent.key < bEvent.key ? -1 : aEvent.key > bEvent.key ? 1 : 0;
            });
        }

        return filteredEvents;
    }

    const upcomingEvents = getUpcomingEvents();

    return (
        <>
            <h3 className="font-medium mb-3"><Translate id='schedule.label.upcomingEvents' /></h3>
            { upcomingEvents.length > 0 ?
                upcomingEvents.map((event) => (
                    <React.Fragment key={event.key}>
                        <small className="d-block text-muted text-uppercase mb-3">{event.momentObj.format('DD MMMM YYYY')}</small>
                        <EventList itemClickCallback={onEventClick} momentObj={event.momentObj} year={event.year} month={event.month} />
                    </React.Fragment>
                ))
                :
                <FadeIn
                    distance={50}
                    direction='bottom'
                    style={{ opacity: 0 }}
                    delay={0.25}
                >
                    <h4 className="h2"><Translate id='schedule.daily.empty.title' /></h4>
                    <p className="text-muted"><Translate id='schedule.daily.empty.text' /></p>
                </FadeIn>
            }
        </>
    );
}

export default ScheduleUpcoming;