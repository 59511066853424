export default{
    "invoice.title"              : "Invoice",
    "invoice.date"               : "Date",
    "invoice.invoiceNo"          : "Invoice No",
    "invoice.paymentMethod"      : "Payment method",
    "invoice.payedBy"            : "Paid By",
    "invoice.organization"       : "Organization",
    "invoice.table.item"         : "Item",
    "invoice.table.item.other"   : "Other item",
    "invoice.table.tax"          : "Tax",
    "invoice.table.credits"      : "Credit",
    "invoice.table.discount"     : "Discount",
    "invoice.table.registration" : "Registration",
    "invoice.table.clinic"       : "Clinic",
    "invoice.table.season"       : "Season",
    "invoice.table.season.to"    : "to",
    "invoice.table.price"        : "Price",
    "invoice.table.qty"          : "QTY",
    "invoice.table.total"        : "Total",
    "invoice.table.subTotal"     : "Sub Total",
    "invoice.table.poweredBy"    : "Powered by Spordle ©{year} Spordle Inc. All rights reserved.",

    "invoice.receipt.members"     : "Member(s)",
    "invoice.receipt.total"       : "Total",
    "invoice.receipt.paid"        : "Paid",
    "invoice.receipt.alreadyPaid" : "Already paid",
    "invoice.receipt.balance"     : "Balance",

    "invoice.error.title"   : "Something went wrong",
    "invoice.error.message" : "We haven't found any invoice. Please come back later or contact the support team.",

    "invoice.transactions.paymentMethods.CHEQUE"       : "Check",
    "invoice.transactions.paymentMethods.CASH"         : "Cash",
    "invoice.transactions.paymentMethods.CREDITCARD"   : "Credit card",
    "invoice.transactions.paymentMethods.PAYMENTPROXY" : "Credit card (proxy payment)",
    "invoice.transactions.paymentMethods.INTERAC"      : "Interac transfer",
    "invoice.transactions.paymentMethods.undefined"    : "N/A",
}