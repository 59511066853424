export default{
    "members.profile.suspensions.title" : "Suspensions",

    // fields
    "members.profile.suspensions.infraction"             : "Infraction",
    "members.profile.suspensions.infraction.existing"    : "Infraction existante",
    "members.profile.suspensions.infraction.other"       : "Autre Infraction",
    "members.profile.suspensions.organization"           : "Organisation",
    "members.profile.suspensions.penaltyType"            : "Type de pénalité",
    "members.profile.suspensions.suspensionType"         : "Type de suspension",
    "members.profile.suspensions.duration"               : "Durée",
    "members.profile.suspensions.duration.definite"      : "Définie",
    "members.profile.suspensions.duration.indefinite"    : "Indéfinie",
    "members.profile.suspensions.duration.games"         : "{games} parties",
    "members.profile.suspensions.duration.numberOfGames" : "Nombre de parties",
    "members.profile.suspensions.endDate"                : "Date de fin",
    "members.profile.suspensions.incidentDate"           : "Date de l'incident",
    "members.profile.suspensions.receivedDate"           : "Date de réception",
    "members.profile.suspensions.location"               : "Emplacement de l'incident",
    "members.profile.suspensions.status"                 : "Statut",
    "members.profile.suspensions.status.active"          : "Actif",
    "members.profile.suspensions.status.expired"         : "Expiré",
    "members.profile.suspensions.team"                   : "Équipe",
    "members.profile.suspensions.position"               : "Position",
    "members.profile.suspensions.opposingTeamName"       : "Nom de l'équipe adverse",
    "members.profile.suspensions.notes"                  : "Notes",
    "members.profile.suspensions.attachments"            : "Pièces jointes",
    "members.profile.suspensions.created_date"           : "Date de création",
    "members.profile.suspensions.submitted_by"           : "Soumis par",
    "members.profile.suspensions.submitted_email"        : "Soumis par courriel",

    // validation
    "members.profile.suspensions.infraction.required"         : "L'infraction est requise",
    "members.profile.suspensions.penaltyType.required"        : "Le type de pénalité est requis",
    "members.profile.suspensions.games.required"              : "Le nombre de parties est requis",
    "members.profile.suspensions.endDate.required"            : "La date de fin est requise",
    "members.profile.suspensions.endDate.afterReceived"       : "La date de fin doit être après la date de réception",
    "members.profile.suspensions.incidentDate.required"       : "La date de l'incident est requise",
    "members.profile.suspensions.incidentDate.afterToday"     : "La date de l'incident ne peut pas être après aujourd'hui",
    "members.profile.suspensions.incidentDate.beforeReceived" : "La date de l'incident ne peut pas être après la date de réception",
    "members.profile.suspensions.receivedDate.required"       : "La date de réception est requise",
    "members.profile.suspensions.receivedDate.afterIncident"  : "La date de réception ne peut pas être avant la date de l'incident",
    "members.profile.suspensions.location.required"           : "L'emplacement de l'incident est requis",
    "members.profile.suspensions.team.required"               : "L'équipe est requise",
    "members.profile.suspensions.position.required"           : "La position est requise",

    // table

    // add modal
    "members.profile.suspensions.add.title" : "Ajouter une suspension",

    // sidepanel
    "members.profile.suspensions.sidepanel.title" : "Suspendu le",
    "members.profile.suspensions.sidepanel.PDF"   : "Exporter PDF",

    // delete suspension modal
    "members.profile.suspensions.delete.message" : "Vous allez supprimer une suspension pour ce joueur. Cette action est irréversible.",
}