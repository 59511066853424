/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {memo}from'react';import {Row}from'reactstrap';import FormikError from'./FormikError.js';/**
 * @member FormikGroup
 * @description
 * FormikGroup will create a group for a common error validation like with CheckBoxes and RadioButtons<br>
 * This group create a bootstrap row to allow easy responsiveness -> see example<br>
 * FormikGroup's children must have the same name<br>
 * Better used with FormikCheckedButton and FormikRadioButton
 * @example
 * <FormikGroup name="encoding" rowProps={{className:''}}>
 * 	 <FormikRadioButton name="encoding" label="x-www-form-urlencoded" id="url_encoded" value="x-www-form-urlencoded"/>
 * 	 <FormikRadioButton name="encoding" label="form-data" id="form_data_encoded" value="form-data"/>
 * </FormikGroup>
 *
 * @prop {string} name Used to make the validation
 * @prop {object} rowProps Props to customize the Row
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns Group that have a common validation
 *
 * @copyright Spordle
 */
const FormikGroup = ({ name, rowProps, manualError, ...props }) => {
    return (jsxs(Fragment, { children: [jsx(Row, { ...rowProps, children: props.children }), !manualError && jsx(FormikError, { name: name })] }));
};
FormikGroup.propTypes = {
    name: PropTypes.string.isRequired,
    rowProps: PropTypes.object,
    manualError: PropTypes.bool,
};
FormikGroup.displayName = 'FormikGroup';
const FormikGroup$1 = memo(FormikGroup);export{FormikGroup$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/