import dashboard from "./dashboard";
import maintenance from "./maintenance";
import members from "./members";
import custom from "./custom";
import settings from "./settings";
import api from "./api";
import paymentMethods from "./paymentMethods";
import transactions from "./transactions";
import supportRequests from "./supportRequests";

export default{
    ...dashboard,
    ...maintenance,
    ...members,
    ...custom,
    ...settings,
    ...paymentMethods,
    ...api,
    ...transactions,
    ...supportRequests,
}