// /* eslint-disable react/no-unused-class-component-methods */
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<ClinicsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & ClinicsContextProvider['state']>} */
export const ClinicsContext = createContext();
ClinicsContext.displayName = 'ClinicsContext';

class ClinicsContextProvider extends React.PureComponent{
    /**
     * Certifies a clinic attendee
     * @param {string} clinicId Clinic ID
     * @param {string} clinicAttendeeId Clinic attendee ID
     * @param {Object} values Object containing the qualification values
     * @param {string} values.certificationDate Date of the certification (YYYY-MM-DD)
     * @param {string} [values.certificationNumber] String representing the certification number of the qualification - OPTIONNAL
     * @param {string} [values.expirationDate] Expiration date of the qualification - OPTIONNAL
     * @param {string} [values.memo] Memo - OPTIONNAL
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicattendees%3A%3AcertifyClinicAttendee|documentation}
     * @returns {Promise}
     */
    certifyClinicAttendee = (clinicId, clinicAttendeeId, { certificationDate, certificationNumber, expirationDate, memo }) => {
        const params = new URLSearchParams()

        params.append('certified_date', certificationDate);
        if(certificationNumber)
            params.append('certification_number', certificationNumber)
        if(expirationDate)
            params.append('expiration_date', expirationDate)
        if(memo)
            params.append('memo', memo)

        return API_PUBLIC_LOGGEDIN.post(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/attendees/${clinicAttendeeId}/certify`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_qualification_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * update a Clinic Attendee
     * @param {Object} values
     * @param {string} clinic_id
     * @param {string} clinic_attendee_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AupdateClinicAttendee|documentation}
     * @returns {Promise}
     */
    updateClinicAttendee = (clinic_id, clinic_attendee_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'qualification_external_provider_id':
                    params.append('qualification_external_provider_id', values.qualification_external_provider_id);
                    break;
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
                case 'certified':
                    params.append('certified', values.certified);
                    break;
                case 'certified_date':
                    params.append('certified_date', values.certified_date);
                    break;
                case 'sent_to_external_provider':
                    params.append('sent_to_external_provider', values.sent_to_external_provider);
                    break;
                case 'certification_number':
                    params.append('certification_number', values.certification_number);
                    break;
                case 'expiration_date':
                    params.append('expiration_date', values.expiration_date);
                    break;
                case 'memo':
                    params.append('memo', values.memo);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_PUBLIC_LOGGEDIN.put(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees/${clinic_attendee_id}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * update a Clinic Attendee (partial)
     * @param {string} clinic_id
     * @param {string} clinic_attendee_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AupdateClinicAttendee|documentation}
     * @returns {Promise}
     */
    updateClinicAttendeePartial = (clinic_id, clinic_attendee_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
                case 'post_task':
                    params.append('post_task', values.post_task);
                    break;
                case 'pre_task':
                    params.append('pre_task', values.pre_task);
                    break;
                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_PUBLIC_LOGGEDIN.patch(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees/${clinic_attendee_id}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update multiple Clinic Attendees (partial)
     * @param {string} clinic_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/ae00a2243019732893715560f14d1ea6 documentation}
     * @returns {Promise}
     */
    updateClinicAttendeesPartial = (clinic_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'clinic_attendee_id':
                    params.append('clinic_attendee_id', Array.isArray(values.clinic_attendee_id) ? values.clinic_attendee_id.join(',') : values.clinic_attendee_id);
                    break;
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
            }
        }

        return API_PUBLIC_LOGGEDIN.patch(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        failed: response.data.invalid_clinic_attendees,
                        success: response.data.updated_clinic_attendees,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <ClinicsContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </ClinicsContext.Provider>
        );
    }
}

export default ClinicsContextProvider;
