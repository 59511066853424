import { stringBuilder } from "@spordle/helpers";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Col, Collapse, ListGroupItem, Row } from "reactstrap";
import ManagePaymentModal from "./managePaymentModal/ManagePaymentModal";
import PaymentPayNowButton from "./PaymentPayNowButton";
import PaymentPaysafeCard from "./PaymentPaysafeCard";
import RetryPaymentBtn from "./RetryPaymentBtn";

// Possible status: 'PENDING','COMPLETED','NEED_REVIEW','SUSPENDED','DECLINED','PARTIALLY_PAID','CANCELED'
export const statusData = (status) => {
    switch (status){
        case 'declined':
        case 'cancelled':
        case 'canceled':
            return ({
                icon: "mdi-alert",
                Label: () => <Translate id='account.paymentMethods.label.status.DECLINED' />,
                theme: "danger",
            })
        case 'suspended':
            return ({
                icon: "mdi-alert",
                Label: () => <Translate id='account.paymentMethods.label.status.SUSPENDED' />,
                theme: "danger",
            })
        case 'partially_paid':
            return ({
                icon: "mdi-alert",
                Label: () => <Translate id='account.paymentMethods.label.status.PARTIALLY_PAID' />,
                theme: "danger",
            })
        case 'need_review':
            return ({
                icon: "mdi-alert",
                Label: () => <Translate id='account.paymentMethods.label.status.DECLINED' />,
                theme: "danger",
            })
        case 'completed':
            return ({
                icon: "mdi-check-circle",
                Label: () => <Translate id='account.paymentMethods.label.status.COMPLETED' />,
                theme: "success",
            })
        case 'pending':
            return ({
                icon: "mdi-clock-outline",
                Label: () => <Translate id='account.paymentMethods.label.status.PENDING' />,
                theme: "warning",
            })
        default:
            return ({
                icon: "",
                Label: () => '',
                theme: "warning",
            })
    }
}
/**
 * @description Row in the transactions list. Can be clicked to show more info
 * @param {Object} props
 * @param {need_review|success|pending} props.status
 * @returns {React.ReactNode}
 */
const PaymentItem = ({ amount, date, desc: Item, status, invoiceNumber, isPaysafe, invoiceItem, updatePaymentList }) => {
    const [ manageIsOpen, setManageIsOpen ] = useState(false);
    const [ changeIsOpen, setChangeIsOpen ] = useState(false);
    const currentStatusData = statusData(status) ?? statusData('pending');

    const isSmallView = isPaysafe ? !invoiceItem.canPayNow : false;

    return (
        <ListGroupItem className={stringBuilder('p-3', { "bg-light-danger border-0": status === "need_review" })}>
            <Row form onClick={() => setManageIsOpen(!manageIsOpen)} className='clickable' id={`invoicePayment-${invoiceItem.invoice_payment_id}`}>
                <Col sm="3" className="d-flex text-nowrap">
                    <i className={`mr-2 mdi ${currentStatusData.icon} text-${status !== "pending" ? currentStatusData.theme : 'body'}`} />
                    <div className="text-muted"><DateFormat value={date} utc /></div>
                </Col>
                <Col sm="6" className="text-truncate">
                    <small className="d-block">
                        <Link id={`invoice-${invoiceItem.invoice_payment_id}__${invoiceNumber}`} to={`/purchases/invoice/${invoiceNumber}`}>#{invoiceNumber}</Link>
                    </small>
                    <Item />
                </Col>
                <Col sm="3" className="text-right">
                    <span className={`text-right font-medium mr-2 text-${status !== "pending" ? currentStatusData.theme : 'body'}`}>
                        <CurrencyFormat value={amount} currencyDisplay='symbol' />
                    </span>
                    <div className="d-inline-block">
                        <i className={stringBuilder('d-block mdi mdi-chevron-right transition', { 'rotate-90': manageIsOpen })} />
                    </div>
                </Col>
            </Row>
            <Collapse isOpen={manageIsOpen} unmountOnExit mountOnEnter>
                <Card className="card-shadow p-3 mt-3 mb-0">
                    <Row form>
                        <Col className={stringBuilder({ "form-group": isSmallView })} sm="3">
                            <div className="text-muted">
                                <Translate id="misc.status" />
                            </div>
                            <div className="font-medium">
                                <Badge color={currentStatusData.theme}><currentStatusData.Label /></Badge>
                            </div>
                        </Col>
                        <Col className={stringBuilder({ "form-group": isSmallView })} sm={isSmallView ? "9" : "5"}>
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.description' />
                            </div>
                            <p className="font-medium">
                                <Item />
                            </p>
                        </Col>
                        <Col className='mb-3 mb-sm-0' sm={isSmallView ? "3" : "4"}>
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.paymentMode' />
                            </div>
                            <div className="font-medium">{!invoiceItem.provider ? '-' : isPaysafe ? 'Paysafe' : 'SportsPay'}</div>
                        </Col>
                        {isPaysafe && !invoiceItem.canPayNow &&
                            <PaymentPaysafeCard invoiceItem={invoiceItem} canChangeCard={status === 'pending'} />
                        }
                    </Row>
                    {status === "need_review" &&
                        <RetryPaymentBtn onSuccess={updatePaymentList} provider={invoiceItem.provider} paymentId={invoiceItem.invoice_payment_id} color="primary" className="mt-4" />
                    }
                    {invoiceItem.canPayNow ?
                        <PaymentPayNowButton invoiceItem={invoiceItem} updateInvoices={updatePaymentList} fromList />
                        : invoiceItem.provider === "QUICK_ENROLLMENT" && (status === 'pending' || status === "need_review") ?
                            <Button
                                block
                                id={`invoice-changeCard-${invoiceItem.invoice_payment_id}`}
                                type="button"
                                onClick={() => setChangeIsOpen(true)}
                                className="ml-1 py-0"
                                color="link"
                                size="sm"
                            >
                                <ManagePaymentModal
                                    isOpen={changeIsOpen}
                                    toggle={() => setChangeIsOpen(false)}
                                    invoiceItem={invoiceItem}
                                    transactionType="qe"
                                />
                                <Translate id='account.paymentMethods.btn.changeCard' /><i className="mdi mdi-pencil ml-1" />
                            </Button>
                            : null
                    }
                </Card>
            </Collapse>
        </ListGroupItem>
    )
}

export default PaymentItem;