import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { useTooltip } from "../../../../../components/customHooks/useTooltip";
import { AuthContext } from "../../../../../contexts/contexts";
import { copyToClipBoard, HoverableCopy } from "../../../../../helpers/clipboardHelper";
import getReferrer from "../../../../../helpers/getReferer";
import queryString from 'query-string';
import { stringBuilder } from "@spordle/helpers";
import classes from '../AccountMemberClinicsManagement.module.scss';
import ClinicCategory from "../../../../../components/ClinicCategory";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const AccountClinicCard = ({ clinic, showAttendeesList, selectedClinic, collapseIsOpen }) => {
    const isTheSelectedClinic = selectedClinic?.clinic_id === clinic.clinic_id;

    return (
        <Card
            onClick={() => showAttendeesList(clinic)}
            className={stringBuilder(
                { [`clickable ${classes.Card}`]: !selectedClinic },
                "card-shadow mb-2",
            )}
        >
            <CardHeader className={`bg-gradient-clinic-${clinic.qualification.qualification_category?.color || 0} text-white`}>
                <span className="d-flex align-items-center position-relative">
                    <span className="text-truncate mr-2">
                        {clinic.clinic_reference &&
                            <span className="small d-block">#{clinic.clinic_reference}</span>
                        }
                        <span className="font-bold">
                            <ClinicCategory.Builder
                                showDot={false}
                                qualification={clinic.qualification}
                            />
                        </span>
                    </span>
                    { !collapseIsOpen &&
                        <span className="d-flex align-items-center ml-auto line-height-1">
                            <i className="ml-2 font-22 mdi mdi-arrow-right" />
                        </span>
                    }
                    {isTheSelectedClinic && collapseIsOpen &&
                        <ClinicStoreBtns clinic={clinic} />
                    }
                </span>
            </CardHeader>
            <CardBody>
                <Collapse isOpen={collapseIsOpen && isTheSelectedClinic}>
                    <div className="d-flex align-items-center">
                        <i className="font-18 mdi mdi-pound mr-1" />
                        <HoverableCopy toCopy={clinic.clinic_reference}> {clinic.clinic_reference}</HoverableCopy>
                    </div>
                </Collapse>
                <div className="d-flex align-items-center">
                    <i className="font-18 mdi mdi-file-tree mr-1" /><DisplayI18n field='name' defaultValue={clinic.organisation.organisation_name} i18n={clinic.organisation.i18n} />
                </div>
                <div className="d-flex align-items-center">
                    <i className="font-18 mdi mdi-map-marker mr-1" />{clinic.first_session_location_name || '-'}
                </div>
                <div className="d-flex align-items-center">
                    <i className="font-18 mdi mdi-calendar mr-1" />
                    {clinic.first_session_start_date ?
                        <>
                            <DateFormat value={clinic.first_session_start_date} /> <DateFormat value={clinic.first_session_start_date} format={'h:mm a'} />
                            {clinic.end_date &&
                                <>
                                    <span className="mx-1">-</span>
                                    <DateFormat value={clinic.end_date} /> <DateFormat value={clinic.end_date} format={'h:mm a'} />
                                </>
                            }
                        </>
                        :
                        '-'
                    }
                </div>
                <div className="d-flex align-items-center">
                    <i className="font-18 mdi mdi-account-multiple mr-1" />
                    <Translate id='account.members.clinicsManagement.attendees.count' values={{ count: clinic.attendees?.length || 0 }} />
                </div>
            </CardBody>
        </Card>
    )
}

const ClinicStoreBtns = ({ clinic }) => {
    const { accessToken } = useContext(AuthContext);
    const { formatMessage } = useIntl();
    const openStoreTooltip = useTooltip('member_tip', formatMessage({ id: 'account.members.clinicsManagement.openStore' }));
    const copyStoreTooltip = useTooltip('member_tip', formatMessage({ id: 'account.members.clinicsManagement.copyStoreLink' }));
    const storeLink = `${getReferrer('HCR-SITE')}/clinic-details/${clinic.clinic_id}`;

    return (
        <span className="d-flex align-items-center ml-auto line-height-1">
            <a
                {...openStoreTooltip}
                className="reset-btn px-2 text-white mr-2"
                href={queryString.stringifyUrl({
                    url: storeLink,
                    query: {
                        accessToken: accessToken,
                    },
                })}
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="font-20 mdi mdi-cart-outline" />
            </a>
            <button
                {...copyStoreTooltip}
                className="reset-btn text-white"
                type="button"
                onClick={() => copyToClipBoard(storeLink)}
            >
                <i className="font-20 mdi mdi-content-copy" />
            </button>
        </span>
    )
}

export default AccountClinicCard;