import { useIntl } from 'react-intl';
import { useTooltip } from '../../../../components/customHooks/useTooltip';
import { waiverNotSigned } from '../../../../helpers/waiversHelper';

const UnsignedWaiversIcon = ({ waivers }) => {
    const { formatMessage } = useIntl();
    const tipProps = useTooltip("unsignedWaiversToolTip", (() => formatMessage({ id: 'account.transactions.memberProfileTransactions.tableView.header.transaction.tooltip' }, { waiverCount: waivers.filter((w) => w.answer === 'NO').length }))());

    return (
        waivers.filter((w) => w.answer === waiverNotSigned && w.waiver.mandatory != "0").length > 0 ?
            <i data-html {...tipProps} className='mdi mdi-alert-circle-outline text-danger' />
            : null
    )
}

export default UnsignedWaiversIcon