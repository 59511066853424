import Confirm from "./confirm-account";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import Recovery from "./recovery";
import Signup from "./signup";

export default{
    ...Confirm,
    ...ForgotPassword,
    ...Login,
    ...Recovery,
    ...Signup,
}