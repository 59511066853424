/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {useIntl}from'react-intl';/* eslint-disable react-hooks/rules-of-hooks */
/**
 * Translate a given index to the correct language
 *
 * NOTE: This does not rerender automatically. Trigger a rerender manually to get the string
 * @function
 * @param {string} id String index to be translated
 * @param {object} [value] Object containing indexes and values to be matched with wildcards
 * @returns {string} Translated message
 * @copyright Spordle
 * @example
 * getTranslation('settings.label.edit');
 * getTranslation(`settings.error.${formik.status}`);
 * getTranslation("misc.priceValue", { price: tournament.price });
 * @deprecated
 */
const getTranslation = (id, value) => useIntl().formatMessage({ id: id }, value);export{getTranslation as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/