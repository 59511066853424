import { DropdownItem } from "reactstrap";
import PropTypes from 'prop-types';

/**
 * DropdownItem that handles CanDoAction. Should be child of Uncontrolled dropdown or SidePanelActionsMenu
 * @param {Object} props
 * @param {string|import("react").ReactElement} [props.tag=button]
 * @returns {JSX.Element}
 * @example
 * <SidePanel.ActionsMenu>
 *      <SidePanelMenuAction>
 *          Delete
 *      </SidePanelMenuAction>
 * </SidePanel.ActionsMenu>
 */
const SidePanelMenuAction = ({ tag = "button", ...props }) => {
    return (
        <DropdownItem {...props} tag={tag} />
    )
};

export default SidePanelMenuAction;

SidePanelMenuAction.propTypes = {
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
}

SidePanelMenuAction.defaultProps = {
    tag: "button",
}