export default{
    // header / footer
    "signup.header.hcr"                : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Hockey Canada Registry",
    "signup.header.poweredBy"          : "Powered by",
    "signup.header.alreadyHaveAccount" : "Already have an account?",
    "signup.header.signIn"             : "Sign in",

    // General
    "signup.label.backToLogin" : "Return to login",
    "signup.label.title"       : "Sign up",
    "signup.fields.helper"     : "All fields are required",
    "signup.label.thisEmail"   : "This email will be used to sign in",
    "signup.action.signup"     : "Sign up",
    "signup.action.next"       : "Next",
    "signup.action.previous"   : "Previous",

    // Sign up form
    "signup.signupForm.label.firstName"       : "First name",
    "signup.signupForm.label.lastName"        : "Last name",
    "signup.signupForm.label.email"           : "Email Address",
    "signup.signupForm.label.password"        : "Password",
    "signup.signupForm.label.passwordConfirm" : "Confirm password",
    "signup.signupForm.label.country"         : "Country *",
    "signup.signupForm.label.province"        : "Province *",
    "signup.signupForm.label.language"        : "Language",

    "signup.signupForm.validation.firstName.required"    : "First name required",
    "signup.signupForm.validation.lastName.required"     : "Last name required",
    "signup.signupForm.validation.email.required"        : "Email required",
    "signup.signupForm.validation.email.email"           : "Invalid email",
    "signup.signupForm.validation.password.required"     : "Password required",
    "signup.signupForm.validation.password.space"        : "Password cannot have spaces",
    "signup.signupForm.validation.password.number"       : "Must contain 1 number",
    "signup.signupForm.validation.password.letter"       : "Must contain one letter",
    "signup.signupForm.validation.password.uppercase"    : "Must contain one uppercase",
    "signup.signupForm.validation.password.lowercase"    : "Must contain one lowercase",
    "signup.signupForm.validation.password.lenght"       : "Must be at least 6 characters long",
    "signup.signupForm.validation.password.lenght.max"   : "Must be less than 99 characters long",
    "signup.signupForm.validation.password.specialChar"  : "Must have a special character ",
    "signup.signupForm.validation.passwordConfirm.match" : "Passwords must match",
    "signup.signupForm.validation.birthdate.format"      : "The date must be a valid format",
    "signup.signupForm.validation.birthdate.invalid"     : "Your birthdate is invalid",

    "signup.signupForm.tooltip.password.title" : "Requirements",
    "signup.signupForm.tooltip.password.1"     : "Must be 8 letters",
    "signup.signupForm.tooltip.password.2"     : "Must have 1 number",
    "signup.signupForm.tooltip.password.3"     : "Must have 1 uppercase letter",
    "signup.signupForm.tooltip.password.4"     : "Must have 1 lowercase letter",
    "signup.signupForm.tooltip.password.5"     : "Must have 1 special character",

    // Error
    "signup.error.title"                    : "Oops, an error has occurred !",
    "signup.error.action"                   : "Back to sign up",
    "signup.error.server"                   : "There was a problem. Please refresh the page and try again.",
    "signup.error.306"                      : "An account already exists with this email address",
    "signup.error.InvalidPasswordException" : "The password is invalid",
    "signup.error.UsernameExistsException"  : "An account with the given email already exists",

    // Loading
    "signup.loading.title"       : "Creation in progress!",
    "signup.loading.description" : "Once completed, you will receive a confirmation email.",

    // Confirmed
    "signup.confirmed.title"       : "Confirm your email",
    "signup.confirmed.description" : "We have sent an email, please confirm your account to log in.",
    "signup.confirmed.notReceived" : "You did not receive an email?",
    "signup.confirmed.resend"      : "Send Email",
}