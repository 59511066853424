import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import GameIncidentIncidentsTab from "./tabs/GameIncidentIncidentsTab";
import GameIncidentInfoTab from "./tabs/GameIncidentInfoTab";
import GameIncidentTeamsTab from "./tabs/GameIncidentTeamsTab";
import { fail, success } from '@spordle/toasts';
import { GameIncidentsContext } from "../../../../../../contexts/GameIncidentsContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import Translate from "@spordle/intl-elements";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { isSubmitted } from "../gameIncidentHelper";
import GameIncidentSidePanelExport from "./export/GameIncidentSidePanelExport";
import moment from "moment";

const AccountMemberGameIncidentSidePanel = (props) => {
    const { selectedRows, createNewValues, syncRows, statuses } = props;
    const gameIncident = selectedRows[0];
    const [ isLoading, setIsLoading ] = useState(false);
    const [ activeTab, setActiveTab ] = useState('1');
    const { partiallyUpdateGameIncident } = useContext(GameIncidentsContext);

    const toggle = (tab) => setActiveTab(tab);

    /**
     * @description Default error catch handler
     * @param {Error} e
     */
    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
            setIsLoading(false);
        }
    }

    /**
     * @description Syncs new values to the spordle table
     * @param {object} newVal New values
     * @param {bool} skipSetLoading=false
     */
    const syncNewVal = (newVal, skipSetLoading = false) => {
        syncRows(createNewValues({ ...newVal }));
        if(!skipSetLoading){
            success();
            setIsLoading(false);
        }
    }

    /**
     * @description Patch of fields to update. Handles catch, syncRows, loading state & toasts
     * @param {object} data Object containing the data to update
     * @param {object} [tableData=data] object containing the data to update the table with. Optional
     */
    const updateValues = (data, tableData) => {
        setIsLoading(true);
        partiallyUpdateGameIncident(selectedRows[0].game_incident_id, data)
            .then(() => {
                syncNewVal(tableData || data);
            })
            .catch(handleCatch)
    }

    /**
     * @description Handles the logic of submitting a game incident report.
     * It changes the game_incident_status to SUBMITTED
     */
    const handleSubmitReport = () => {
        const submittedStatus = statuses.find(isSubmitted);

        if(submittedStatus){
            updateValues(
                { game_incident_status_id: submittedStatus.game_incident_status_id },
                { game_incident_status: submittedStatus, submitted_date: moment().toISOString() },
            )
        }
    }

    useEffect(AxiosCancelAll, []);

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header noBorder>
                <div className="d-flex align-items-center">
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <GameIncidentSidePanelExport gir={gameIncident} />
                        <ConfirmModal
                            color="primary"
                            actionButtonText={<Translate id="misc.submit" />}
                            icon="mdi-information-outline"
                            modalMsg={isSubmitted(gameIncident.game_incident_status) ? 'account.members.gir.sidePanel.resubmit.msg' : 'account.members.gir.sidePanel.submit.msg'}
                            translateModalMsg
                            modalTitle={
                                <UserDisplay block card className="justify-content-center mb-0 text-center">
                                    <UserDisplay.Container>
                                        <small className="d-block text-muted mb-2">#{gameIncident.game_incident_number}</small>
                                        <span className="font-medium">
                                            {gameIncident.home_team.short_name || gameIncident.home_team.name}
                                        </span>
                                        <i className="text-muted font-normal"> - vs - </i>
                                        <span className="font-medium">{gameIncident.away_team.short_name || gameIncident.away_team.name}</span>
                                        <span className="d-block mt-2 font-normal">
                                            (<DisplayI18n field="name" defaultValue={gameIncident.period.name} i18n={gameIncident.period.i18n} />)
                                        </span>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            }
                            toggler={(fn) => (
                                <SidePanel.MenuAction onClick={fn}>
                                    {isSubmitted(gameIncident.game_incident_status) ?
                                        <Translate id='account.members.gir.sidePanel.resubmit.btn' />
                                        :
                                        <Translate id='account.members.gir.sidePanel.submit.btn' />
                                    }
                                </SidePanel.MenuAction>
                            )}
                            onConfirm={handleSubmitReport}
                        />
                    </SidePanel.ActionsMenu>
                </div>
                <small className="d-block text-muted">#{gameIncident.game_incident_number}</small>
                <SidePanel.Title><Translate id='account.members.gir.title' /></SidePanel.Title>
                <SidePanel.Subtitle>
                    <span className="font-medium">
                        {gameIncident.home_team.short_name || gameIncident.home_team.name}
                    </span>
                    <i className="text-muted"> - vs - </i>
                    <span className="font-medium">
                        {gameIncident.away_team.short_name || gameIncident.away_team.name}
                    </span>
                    <span className="d-block mt-1">
                        (<DisplayI18n field="name" defaultValue={gameIncident.period.name} i18n={gameIncident.period.i18n} />)
                    </span>
                </SidePanel.Subtitle>
            </SidePanel.Header>
            <Nav tabs className="is-full text-center">
                <NavItem className="flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Info
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        <Translate id='account.members.gir.addModal.steps.teams.title' />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        <Translate id='account.members.gir.addModal.steps.incident.title' />
                    </NavLink>
                </NavItem>
            </Nav>
            <SidePanel.Body>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <GameIncidentInfoTab
                            updateValues={updateValues}
                            syncNewVal={syncNewVal}
                            gameIncident={gameIncident}
                            setIsLoading={setIsLoading}
                            {...props}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <GameIncidentTeamsTab
                            updateValues={updateValues}
                            syncNewVal={syncNewVal}
                            gameIncident={gameIncident}
                            {...props}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <GameIncidentIncidentsTab
                            setIsLoading={setIsLoading}
                            handleCatch={handleCatch}
                            syncNewVal={syncNewVal}
                            gameIncident={gameIncident}
                            {...props}
                        />
                    </TabPane>
                </TabContent>
            </SidePanel.Body>
        </OverlayLoader>
    );
}

export default AccountMemberGameIncidentSidePanel;