export default{
    "account.api.logs.sidePanelAccountLogs.tabs.details" : "Détails",

    "account.api.logs.sidePanelAccountLogs.logs.title" : "Informations de log",

    "account.api.logs.sidePanelAccountLogs.logs.summary"  : "Sommaire",
    "account.api.logs.sidePanelAccountLogs.logs.status"   : "Statut",
    "account.api.logs.sidePanelAccountLogs.logs.method"   : "Méthode",
    "account.api.logs.sidePanelAccountLogs.logs.endpoint" : "Endpoint",
    "account.api.logs.sidePanelAccountLogs.logs.date"     : "Date",

    "account.api.logs.sidePanelAccountLogs.logs.request" : "Request {method} body",

    "account.api.logs.sidePanelAccountLogs.logs.response" : "Response body",

    "account.api.logs.sidePanelAccountLogs.logs.copy" : "Copier",
}