import header from "./header";
import misc from "./misc";
import sidebar from "./sidebar";
import warnings from "./warnings";
import form from "./form";
import paysafe from "./paysafe";
import reports from "./reports";

export default{
    ...header,
    ...misc,
    ...sidebar,
    ...warnings,
    ...form,
    ...paysafe,
    ...reports,
}