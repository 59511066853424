import Translate, { DateFormat } from "@spordle/intl-elements";
import { Card, CardBody } from "reactstrap";
import LerpAmount from "./LerpAmount";
import PaymentPayNowButton from "./PaymentPayNowButton";

/**
 * @description Showcases next due payment
 * @param {Object} props
 * @param {boolean} [props.isDeclined=false]
 * @returns {React.ReactNode}
 */
const PreInitPayment = ({ invoiceItem, updatePaymentList }) => {
    if(!invoiceItem){
        return null
    }

    return (
        <Card className="card-shadow mb-0">
            <CardBody>
                <small className="payments-upcoming-date d-flex justify-content-between"><DateFormat value={invoiceItem.due_date} utc /></small>
                <div className="h4 font-medium">
                    <Translate id='account.paymentMethods.preinit.title' />
                </div>
                <div className="payments-upcoming-price">
                    <LerpAmount amount={invoiceItem.amount / 100} />
                </div>
                <p className="payments-upcoming-info"><invoiceItem.InvoiceItem /></p>
                <PaymentPayNowButton invoiceItem={invoiceItem} updateInvoices={updatePaymentList} />
            </CardBody>
        </Card>
    )
}

export default PreInitPayment;