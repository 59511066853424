export default{
    "account.settings.profile.accountPhone.update"             : "Votre compte a été mis à jour!",
    "account.settings.profile.accountPhone.update.fail"        : "Une erreur est survenue, veuillez rafraichir la page",
    "account.settings.profile.accountPhone.update.sms.success" : "SMS envoyé!",
    "account.settings.profile.accountPhone.update.sms.fail"    : "Le SMS n'a pas pu être envoyé",

    "account.settings.profile.accountPhone.phone"                     : "Téléphone mobile",
    "account.settings.profile.accountPhone.phone.2faOnly"             : "Cette information est privée. Avoir un numéro de téléphone mobile permettra d'ajouter la vérification SMS en 2 étapes à votre compte.",
    "account.settings.profile.accountPhone.phone.notVerified"         : "Votre numéro de téléphone n'est pas vérifié",
    "account.settings.profile.accountPhone.phone.current.notVerified" : "Votre numéro de téléphone actuel n'est pas vérifié",
    "account.settings.profile.accountPhone.phone.current.change"      : "Changer de numéro de téléphone",
    "account.settings.profile.accountPhone.verify"                    : "Vérifier",

    "account.settings.profile.accountPhone.2fa.bySms" : "La vérification en 2 étapes est activée par SMS",

    "account.settings.profile.accountPhone.2fa.enterCode" : "Veuillez entrer le code envoyé à",
}