import PropTypes from 'prop-types';

import {
    Fade,
    Spinner
} from "reactstrap";

/**
 * Loading overlay that will wrap children and add an overlay with a centered spinner
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {string} [props.spinnerColor='primary']
 * @return {React.ReactNode}
 */
const OverlayLoader = (props) => (
    <div className='position-relative'>
        {props.isLoading &&
            <div className='overlay-loader'>
                <Fade appear in>
                    <Spinner color={props.spinnerColor} type='grow' />
                </Fade>
            </div>
        }
        {props.children}
    </div>
)

OverlayLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    spinnerColor: PropTypes.string,
}

OverlayLoader.defaultProps = {
    spinnerColor: 'primary',
}

export default OverlayLoader;