const TimeLine = ({ parentClass }) => {
    function getPercent(){
        const d = new Date();

        // 60 seconds in a minute, 3600 seconds in a hour, 86400 seconsd in a day
        const pctDayElapsed = (((d.getHours() * 3600) + (d.getMinutes() * 60)) / 86400 * 100);
        return `${pctDayElapsed.toFixed(2)}%`;
    }

    return (
        // Is an after element because it's only visual
        <style
            dangerouslySetInnerHTML={{
                __html: [
                    `.${parentClass}::after, .${parentClass}::before {`,
                    `  top: ${getPercent()}`,
                    '}',
                ].join('\n'),
            }}
        />
    )
}

export default TimeLine;