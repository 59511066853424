import { useContext } from 'react'
import { ReportsContext } from '../../contexts/ReportsContext'
import Translate from '@spordle/intl-elements';
import { triggerDownload } from '../uploader/uploadHelpers';
import { removeToast } from '@spordle/toasts';

export const PendingReportToast = () => {
    const reportsContext = useContext(ReportsContext);

    return (<Translate id='reports.exports.generatingQueue' values={{ numberOfReports: reportsContext.state.pendingReports.length || 0 }} />);
}

export const SuccessToastMessage = ({ link, id }) => {

    const handleOnClick = () => {
        triggerDownload(link);
        removeToast(id);
    }

    return (
        <div>
            <div onClick={handleOnClick} className='stretched-link clickable' />
            <button
                type='button'
                className='reset-btn text-primary'
            >
                <Translate id='reports.exports.download' />
            </button>
        </div>
    )
}