import React, { createContext } from 'react';
import API_PUBLIC from '../api/API-Public';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<UtilsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const UtilsContext = createContext();
UtilsContext.displayName = 'UtilsContext';

class UtilsContextProvider extends React.PureComponent{

    /////////////////////////////////////////////////////////////////////
    // This context was taken from SID, some of these calls might not work properly with the public API
    /////////////////////////////////////////////////////////////////////

    state = {
        // false for now -> as soon as a user loads a countries select (e.g. google places), this state will be filled with said countries and cache them
        countries: false,
    }

    /**
     * Returns all the available payment methods
     * @returns {Promise<Array<string>>}
     */
    getPaymentMethods = () => {
        return API_PUBLIC.get(queryString.stringifyUrl({ url: `/utils/payment-methods` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.payment_methods;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Returns all the available relations
     * @returns {Promise}
     */
    getRelations = () => {
        return API_PUBLIC.get(queryString.stringifyUrl({ url: `/utils/relations` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.relations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Returns all countries from the state or makes an API call to get them
     * @returns {Promise<Array>}
     */
    getCountries = () => {
        if(this.state.countries){
            return Promise.resolve(this.state.countries)
        }
        return API_PUBLIC.get(queryString.stringifyUrl({ url: `/utils/countries` }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({
                        ...prevState,
                        countries: response.data.countries,
                    }))
                    return response.data.countries;
                }
                throw response.data.errors[0]
            }, serverError)

    }

    /**
     * Returns all provinces for a country
     * @param {string} countryId ID of the country to get provinces from
     * @returns {Promise<Array>}
     */
    getProvinces = (countryId) => {
        return API_PUBLIC.get(queryString.stringifyUrl({ url: `/utils/countries/${countryId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.countries;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] - Gets the indigenous groups
     * @returns {Promise<Array>}
     */
    getIndigenousGroups = () => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/utils/indigenous-groups`,
            query: {
                organisation_id: this.props.OrganizationContext.federationId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.indigenous_groups;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] - Gets ethnicities
     * @returns {Promise<Array>}
     */
    getEthnicities = () => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/utils/ethnicity`,
            query: {
                organisation_id: this.props.OrganizationContext.federationId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.ethnicities;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <UtilsContext.Provider value={Object.assign(this.state, this)}>
                {this.props.children}
            </UtilsContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(UtilsContextProvider);
