import React, { createContext } from 'react';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';

/** @type {React.Context<Omit<TeamsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const TeamsContext = createContext();
TeamsContext.displayName = 'TeamsContext';


class TeamsContextProvider extends React.Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {}

        this.state = this._initState;
    }

    /**
     * [GET] Gets teams
     * @param {string} orgId
     * @param {string} periodId
     * @param {object} [queryParams={}]
     * @returns {Promise.<object[]>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/get_teams_lite_1|documentation}
     */
    getTeams = (orgId, periodId, queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: '/teams',
            query: {
                organisation_id: orgId,
                period_id: periodId,
                lite: 1,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        categories,
                        classes,
                        divisions,
                        organisations,
                        periods,
                        team_status,
                        teams,
                    } = response.data;
                    return teams.map((team) => {
                        team.organisation = organisations[team.organisation_id];
                        delete team.organisation_id;

                        team.period = periods[team.period_id];
                        delete team.period_id;

                        team.team_category = JSON.parse(JSON.stringify(categories[team.team_category_id]));// Need to deep copy because we delete some properties
                        delete team.team_category_id;
                        team.team_category.division = divisions[team.team_category.division_id];
                        delete team.team_category.division_id;
                        team.team_category.class = classes[team.team_category.class_id];
                        delete team.team_category.class_id;
                        team.team_category.organisation = organisations[team.team_category.organisation_id];
                        delete team.team_category.organisation_id;

                        team.team_status = team_status[team.team_status_id];
                        delete team.team_status_id;

                        return team;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all team members of team
     * @param {string} team_id Team ID
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Members/Apicontroller%5CTeams%5CTeams%3A%3AgetAllTeamMembers|documentation}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getTeamMembers = (team_id) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/members`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <TeamsContext.Provider value={{ ...this }}>
                {this.props.children}
            </TeamsContext.Provider>
        )
    }
}

export default TeamsContextProvider;