import Translate from "@spordle/intl-elements";
import React from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { AuthContext } from "../../../../../contexts/contexts";
import { WaiversContext } from "../../../../../contexts/WaiversContext";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";
import { WaiverFields, waiverNotSigned, WaiverSignatureDisplay } from "../../../../../helpers/waiversHelper";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { object, string } from "yup";
import MemberAlertsIcon from "../../../../../components/memberBadges/MemberAlertsIcon";

class AccountMemberWaiversSidepanel extends React.Component{

    state = {
        isLoading: false,
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-3'>
                        <SidePanel.ToggleButton />
                    </div>
                    {this.props.selectedRows[0].period &&
                        <div>
                            <DisplayI18n
                                field='name'
                                defaultValue={this.props.selectedRows[0].period.name}
                                i18n={this.props.selectedRows[0].period.i18n}
                            />
                        </div>
                    }
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].name}
                            i18n={this.props.selectedRows[0].i18n}
                        />
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].organisation.organisation_name}
                            i18n={this.props.selectedRows[0].organisation.i18n}
                        />
                    </SidePanel.Subtitle>
                    {this.props.selectedRows[0].mandatory == 1 &&
                        <div className='text-danger small d-flex align-items-center'>
                            <MemberAlertsIcon icon="waivers" className="pr-1" />
                            <span><Translate id='misc.mandatory' /></span>
                        </div>
                    }
                </SidePanel.Header>
                <div className="p-3">
                    <div
                        className="border rounded p-2 max-vh-50 overflow-auto mb-3"
                        dangerouslySetInnerHTML={{ __html: displayI18n("description", this.props.selectedRows[0].i18n, this.props.selectedRows[0].description, this.props.I18nContext.getGenericLocale()) }}
                    />

                    <FormikEditable
                        id='sign-waiver'
                        readOnly={this.props.selectedRows[0].answer !== waiverNotSigned ? this.props.selectedRows[0].mandatory == 1 || this.props.selectedRows[0].critical == 1 : false}
                        initialValues={{
                            sign: this.props.selectedRows[0].answer === waiverNotSigned ? '' : this.props.selectedRows[0].answer,
                        }}
                        validationSchema={object().shape({
                            sign: string().test({
                                name: 'requiredTest',
                                message: <Translate id='form.fields.waiver.required' />,
                                test: (value) => {
                                    return !!value || this.props.selectedRows[0].mandatory == 0
                                },
                            }),
                        })}
                        onSubmit={({ sign }) => {
                            if(sign !== ''){
                                this.setState(() => ({ isLoading: true }));

                                const newValues = this.props.createNewValues({
                                    answer: sign,
                                    updated_at: new Date().toISOString(),
                                    identity: {
                                        family_name: this.props.AuthContext.userData.UserAttributes.family_name,
                                        identity_id: this.props.AuthContext.userData.Username,
                                        name: this.props.AuthContext.userData.UserAttributes.name,
                                    },
                                });

                                this.props.WaiversContext.signWaiver(this.props.selectedRows[0].invoice_number, this.props.selectedRows[0].invoice_item_id, this.props.selectedRows[0].waiver_id, {
                                    answer: sign,
                                    language_code: this.props.I18nContext.getGenericLocale(),
                                })
                                    .then(() => {
                                        this.props.syncRows(newValues);
                                        this.props.updateMandatoryWaivers(this.props.tableRef.getData())
                                        success();
                                        this.setState(() => ({ isLoading: false }));
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            this.setState(() => ({ isLoading: false }));
                                        }
                                    })
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <WaiverSignatureDisplay waiver={this.props.selectedRows[0]} minifiedView />
                                )
                            }
                            return (
                                <>
                                    <WaiverFields waiver={this.props.selectedRows[0]} fieldName='sign' />
                                </>
                            )

                        }}
                    </FormikEditable>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(WaiversContext, AuthContext, I18nContext)(AccountMemberWaiversSidepanel);