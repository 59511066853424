import {
    FormikCheckedButton,
    FormikDateTime,
    FormikGroup,
    FormikInputNumber,
    FormikInputText,
    FormikRadioButton,
    FormikSelect,
    FormikTextArea
} from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import * as Yup from 'yup';
import { DisplayI18n } from "./i18nHelper";

export const buildConfig = (field, fieldName) => {
    switch (field.form_field_code){
    // array
        case 'CHECKBOX':
        case 'SELECTBOX_MULTI':
            return {
                id: fieldName,
                validationType: 'array',
                validations: [
                    // conditionnal array building :)
                    // eslint-disable-next-line react/jsx-key
                    ...field.mandatory === '1' ? [ { type: 'min', params: [ 1, <Translate id='form.validation.required' /> ] } ] : [],
                ],
            }

            // number
        case 'NUMBER':
            return {
                id: fieldName,
                validationType: 'number',
                validations: [
                    // conditionnal array building :)
                    // eslint-disable-next-line react/jsx-key
                    ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.validation.required' /> ] } ] : [],
                    // eslint-disable-next-line react/jsx-key
                    ...field.min ? [ { type: 'min', params: [ field.min, <Translate id='form.validation.min' /> ] } ] : [],
                    // eslint-disable-next-line react/jsx-key
                    ...field.max ? [ { type: 'max', params: [ field.max, <Translate id='form.validation.max' /> ] } ] : [],
                ],
            }

            // date / time
        case 'DATE':
        case 'DATETIME':
            return {
                id: fieldName,
                validationType: 'mixed',
                validations: [
                    // conditionnal array building :)
                    ...field.mandatory !== '1' ? [ { type: 'test', params: [ {
                        name: 'dateTimeNotRequired',
                        message: <Translate id='form.validation.date.format' />,
                        test: (value) => {
                            if(!value)
                                return true;
                            return moment.isMoment(value);
                        },
                    } ] } ] : [],
                    // eslint-disable-next-line react/jsx-key
                    ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.validation.required' /> ] }, { type: 'test', params: [ {
                        name: 'dateTimeRequired',
                        message: <Translate id='form.validation.date.format' />,
                        test: (value) => {
                            return moment.isMoment(value);
                        },
                    } ] } ] : [],
                    ...field.min ? [ { type: 'test', params: [ {
                        name: 'dateTimeMin',
                        message: <Translate id='form.validation.min' />,
                        test: (value) => {
                            if(!value)
                                return true;
                            return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') >= moment(field.min).format('HH:mm') : moment(value).isAfter(moment(field.min));
                        },
                    } ] } ] : [],
                    ...field.max ? [ { type: 'test', params: [ {
                        name: 'dateTimeMax',
                        message: <Translate id='form.validation.max' />,
                        test: (value) => {
                            if(!value)
                                return true;
                            return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') <= moment(field.max).format('HH:mm') : moment(value).isBefore(moment(field.max));
                        },
                    } ] } ] : [],
                ],
            }

            // string
        case 'TEXT':
        case 'TEXT_AREA':
        case 'RADIO':
        case 'SELECTBOX':
            return {
                id: fieldName,
                validationType: 'string',
                validations: [
                    // conditionnal array building :)
                    // eslint-disable-next-line react/jsx-key
                    ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.validation.required' /> ] } ] : [],
                ],
            }
        default:
            break;
    }
}

export const getInitVal = (field, fieldName) => {
    const temp = {};

    switch (field.form_field_code){
    // simple input fields
        case 'TEXT':
        case 'TEXT_AREA':
        case 'NUMBER':
            temp[fieldName] = field.answer || '';
            break;

            // dates
        case 'DATE':
        case 'DATETIME':
            temp[fieldName] = field.answer ? moment(field.answer) : '';
            break;

            // arrays
        case 'CHECKBOX':
        case 'SELECTBOX_MULTI':
            // field.custom_form_field_option_selected?.split(',') would still split on "" which caused a bug
            temp[fieldName] = field.custom_form_field_option_selected ? field.custom_form_field_option_selected.split(',') : [];
            break;

            // single options
        case 'RADIO':
            // radio should always have a value
            temp[fieldName] = field.custom_form_field_option_selected || field.options[0].custom_form_field_option_id;
            break;

        case 'SELECTBOX':
            temp[fieldName] = field.custom_form_field_option_selected || '';
            break;
    }

    return temp;
}

export const getLabelHelper = (field) => {
    if(field.min && field.max){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='misc.between' /> {moment(field.min).format('HH:mm')} <Translate id='misc.and' /> {moment(field.max).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='misc.between' /> {field.min} <Translate id='misc.and' /> {field.max})</span>
    }else if(field.min){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='form.validation.min' />: {moment(field.min).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='form.validation.min' />: {field.min})</span>
    }else if(field.max){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='form.validation.max' />: {moment(field.max).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='form.validation.max' />: {field.max})</span>
    }
    return ''
}

/**
 * Formats a JSON config to a validation schema
 * @see Found on this {@link https://github.com/jquense/yup/issues/559#issuecomment-518953000|post}
 */
export const createYupSchema = (config, fromEditable = true) => {
    const { id, validationType, validations = [] } = config;
    if(!Yup[validationType]){
        return;
    }

    let validator = Yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if(!validator[type]){
            return;
        }
        validator = validator[type](...params);
    });
    return fromEditable ? { [id]: validator } : validator;
}

export const getValidationSchema = (field, fieldName, fromEditable = true) => {
    return fromEditable ? Yup.object().shape(createYupSchema(buildConfig(field, fieldName), fromEditable)) : createYupSchema(buildConfig(field, fieldName));
}

// TODO: we might want to add some logic to display the fields differently (example: short answers can be displayed with col-6 instead of col-12)
export const displayField = (field, fieldName, formik, stopEditing, fromEditable = true) => {
    switch (field.form_field_code){
        case 'TEXT':
            return <FormikInputText id={fieldName} name={fieldName} trim />
        case 'TEXT_AREA':
            return <FormikTextArea id={fieldName} name={fieldName} trim />
        case 'CHECKBOX':
            return (
                <FormikGroup name={fieldName} rowProps={{ className: 'mx-0 mt-2' }}>
                    {field.options.map((option) => (
                        <FormikCheckedButton
                            key={fieldName + option.custom_form_field_option_id}
                            className='mr-3 mb-2'
                            id={fieldName + option.custom_form_field_option_id}
                            name={fieldName}
                            value={option.custom_form_field_option_id}
                            label={<DisplayI18n field='field_option' defaultValue={option.field_option} i18n={option.i18n} />}
                            translateLabel={false}
                        />
                    ))}
                </FormikGroup>
            );
        case 'RADIO':
            return (
                <FormikGroup name={fieldName} rowProps={{ className: 'mx-0 mt-2' }}>
                    {field.options.map((option, index) => (
                        <FormikRadioButton
                            key={fieldName + option.custom_form_field_option_id}
                            className='mr-3 mb-2'
                            id={fieldName + option.custom_form_field_option_id}
                            name={fieldName}
                            value={option.custom_form_field_option_id}
                            label={<DisplayI18n field='field_option' defaultValue={option.field_option} i18n={option.i18n} />}
                            translateLabel={false}
                        />
                    ))}
                </FormikGroup>
            )
        case 'SELECTBOX_MULTI':
        case 'SELECTBOX':
            return (
                <FormikSelect
                    name={fieldName} id={fieldName} search={false}
                    multi={field.form_field_code === 'SELECTBOX_MULTI'}
                    autoFocus={fromEditable} menuIsDefaultOpen={fromEditable}
                    renderOption={(option) => <DisplayI18n field={'field_option'} defaultValue={option.option.label} i18n={option.option.i18n} />}
                    loadingStatus='success'
                    onOptionSelected={fromEditable ? () => {
                        stopEditing?.();
                    } : undefined}
                    defaultData={field.options.map((option) => ({
                        value: option.custom_form_field_option_id,
                        label: option.field_option,
                        i18n: option.i18n,
                    }))}
                />
            )
        case 'DATE':
            return (
                <FormikDateTime
                    name={fieldName}
                    timeFormat={false}
                    dateFormat='YYYY-MM-DD'
                    inputProps={{ id: fieldName }}
                    isValidDate={(current) => {
                        if(field.min && field.max){
                            return moment(current).isAfter(moment(field.min)) && moment(current).isBefore(moment(field.max))
                        }else if(field.min){
                            return moment(current).isAfter(moment(field.min))
                        }else if(field.max){
                            return moment(current).isBefore(moment(field.max))
                        }
                        return true
                    }}
                />
            )
        case 'DATETIME':
            return (
                <FormikDateTime
                    id={fieldName}
                    name={fieldName}
                    dateFormat={false}
                />
            )
        case 'NUMBER':
            return <FormikInputNumber id={fieldName} name={fieldName} />
    }
}