import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements'
import { stringBuilder } from "@spordle/helpers";
import { statusData } from '../../PaymentItem';

const PaymentInstallment = ({ amount, installment, isFirst, isNextPayment }) => {
    const status = statusData(installment.status.toLowerCase());

    return (
        <tr className={stringBuilder('text-body', { 'bg-light-inverse border': isNextPayment })}>
            <td className={stringBuilder('w-50', { 'font-bold': isNextPayment }, { 'border-top-0': isFirst })}>
                <i className={`mdi ${status?.icon || 'mdi-clock-outline'} mr-1`} />
                <DateFormat value={installment.due_date} />
            </td>
            <td className={stringBuilder('w-50', { 'border-top-0': isFirst })}>
                {isNextPayment ?
                    <small className="font-bold">
                        <Translate id='account.paymentMethods.label.status.next' />
                    </small>
                    :
                    <small>
                        <status.Label />
                    </small>
                }
            </td>
            <td className={stringBuilder('text-right', isNextPayment ? 'font-bold' : 'font-medium', { 'border-top-0': isFirst })}>
                <CurrencyFormat value={amount / 100} currencyDisplay='symbol' />
            </td>
        </tr>
    )
}

export default PaymentInstallment
