import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { Card, Col, Row } from 'reactstrap'
import { DisplayI18n } from '../../../../../../helpers/i18nHelper'
import VaultCard from './VaultCard'
import VaultCardsHeader from './VaultCardsHeader'

const VaultCards = ({ cards, error, isValidating, mutate }) => {
    const cardIsExpired = (month, year) => {
        const cardExpirationDate = moment([ year, month - 1 ]);
        return cardExpirationDate.isValid() && (moment() > cardExpirationDate.add(1, 'months'));
    }

    const getCreditCardType = (type) => {
        switch (type){
            case 'VI':
                return 'visa';
            case 'MC':
                return 'mastercard';
            case 'AMEX':
                return 'american express';
            default:
                return;
        }
    }

    return (
        <Card body className="card-shadow">
            <VaultCardsHeader />
            <Row>
                {isValidating ?
                    Array.from({ length: cards?.length || 3 }, (_, index) => (
                        <Col sm="4" className='form-group' key={index}>
                            <Skeleton height={155} />
                        </Col>
                    ))
                    : error ?
                        <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />
                        :
                        cards.map((card) => (
                            <Col sm="4" className='form-group' key={card.id}>
                                <VaultCard
                                    isExpired={cardIsExpired(card.cardExpiry.month, card.cardExpiry.year)}
                                    canEdit
                                    name={card.holderName}
                                    type={getCreditCardType(card.cardType)}
                                    lastDigits={card.lastDigits}
                                    expirationMonth={card.cardExpiry.month - 1}
                                    expirationYear={card.cardExpiry.year}
                                    cardId={card.id}
                                    paymentToken={card.paymentToken}
                                    mutate={mutate}
                                />
                            </Col>
                        ))
                }
            </Row>
        </Card>
    )
}

export default VaultCards
