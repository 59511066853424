import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { waiverNotSigned, WaiverSignatureDisplay } from '../../../../../../helpers/waiversHelper';
import WaiverInfo from './WaiverInfo';

/**
* @param {object} waiver waiver data
* @param {object} signedBy signedBy data (could probably be merged in waiver's data)
* @param {string} answer the answer to the waiver
* @param {func} openModal opens waiver modal
*/
const WaiverCard = ({ waiver, signedBy, answer, openModal }) => {
    return (
        <Card className="card-shadow card-hover clickable mb-2" onClick={openModal}>
            <CardBody className="p-2">
                {answer === waiverNotSigned ?
                    <WaiverInfo answer={answer} waiver={waiver} />
                    :
                    <Row form>
                        <Col sm="6">
                            <WaiverInfo answer={answer} waiver={waiver} />
                        </Col>
                        <Col sm="6">
                            <WaiverSignatureDisplay waiver={waiver} minifiedView hideIcon />
                        </Col>
                    </Row>
                }
            </CardBody>
        </Card>
    );
}

export default WaiverCard;