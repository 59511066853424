/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {formikElementStitches}from'./stitches.js';const hideIcons = formikElementStitches.css({
    "& .spordleSelect-icon:last-child": {
        display: "none",
    },
});export{hideIcons};
/*
@Copyrights Spordle 2023 - All rights reserved
*/