import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Collapse,
    FormGroup,
    Row,
    Col,
    Button,
    Fade } from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Context Providers
import { I18nContext } from '../../contexts/I18nContext';
import { AuthContext } from "../../contexts/contexts";

// Language
import { LOCALES } from '../../i18n';
import Translate from '@spordle/intl-elements';

// Components
import AuthHeader from '../../components/tour/Authheader';
import { FormikInputText } from '@spordle/formik-elements';
import CapsLock from '@spordle/capslock';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import GoToHomeBtn from '../../components/GoToHomeBtn';
import WithTourTheme from '../../components/HOC/WithTournamentTheme';

class Recovery extends React.Component{
    static contextType = AuthContext;

    state = {
        accountRecoveryForm: "false", // false, success, error
    };

    showSuccess = () => {
        this.setState({ accountRecoveryForm: "success" });
        this.props.history.push('/account-recovery?tok=success');
    }

    componentDidMount(){
        const params = queryString.parse(this.props.location.search);
        if(!params.tok || params.tok === 'success'){
            this.props.history.push('/login');
            return;
        }
        this.tok = params.tok;
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                    <GoToHomeBtn />
                    <I18nContext.Consumer>
                        {(i18n) => {
                            const { locale, setLocale } = i18n;
                            return (
                                <>
                                    {(locale === 'en-ca') ?
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.FRENCH) }}>FR</Button></>
                                        :
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.ENGLISH) }}>EN</Button></>
                                    }
                                </>
                            )
                        }}
                    </I18nContext.Consumer>
                </div>
                <AuthHeader />

                <div className="auth-box my-0">
                    <div className="ml-n2 mt-n2">
                        <Link to="/login" className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id="forgotPassword.action.backToLogin" /></Link>
                    </div>
                    { this.state.accountRecoveryForm !== 'success' ?
                        <Fade>
                            <div className="h4 font-medium mb-3"><Translate id="forgotPassword.title.accountRecovery" /></div>
                            <Formik
                                initialValues={{
                                    recoveryPassword: '',
                                    confirmPassword: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    recoveryPassword: Yup.string()
                                        .required(<Translate id='recovery.validation.password.required' />)
                                        .matches(/[^\s-]/, { message: <Translate id='recovery.validation.password.space' /> })
                                        .matches(/(?=.*[0-9])/, { message: <Translate id='recovery.validation.password.number' /> })
                                        .matches(/(?=.*[a-z, A-Z])/, { message: <Translate id='recovery.validation.password.letter' /> })
                                        .matches(/(?=.*[A-Z])/, { message: <Translate id='recovery.validation.password.uppercase' /> })
                                        .min(6, <Translate id='recovery.validation.password.lenght' />),
                                    confirmPassword: Yup.string()
                                        .required(<Translate id='recovery.validation.passwordConfirm.required' />)
                                        .test('recoveryPassword-match', <Translate id='recovery.validation.passwordConfirm.match' />, function(value){
                                            return this.parent.recoveryPassword === value;
                                        }),
                                })}
                                onSubmit={(values, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    this.context.resetPassword(this.tok, values.recoveryPassword)
                                        .then(() => this.showSuccess())
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                setStatus(error.message);
                                                setSubmitting(false);
                                            }
                                        });
                                }}
                            >
                                {(formik) => (
                                    <Form id="recoveryForm">
                                        <FormGroup className="mt-3">
                                            <FormikInputText
                                                label="forgotPassword.label.enterNewPassword"
                                                id="recoveryPassword"
                                                name="recoveryPassword"
                                                type="password"
                                                trim
                                            />
                                        </FormGroup>
                                        <FormGroup className="mt-3">
                                            <FormikInputText
                                                label="forgotPassword.label.confirmNewPassword"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                trim
                                            />
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Col xs="12">
                                                <button type="submit" className="btn btn-block btn-primary" disabled={formik.isSubmitting}><Translate id="forgotPassword.action.confirmNewPassword" /></button>
                                            </Col>
                                        </Row>

                                        <CapsLock>
                                            {(isActive) => (
                                                <Collapse isOpen={isActive}>
                                                    <div className={'alert alert-warning mb-0'}><i className="mr-1 mdi mdi-apple-keyboard-caps" /><Translate id="warning.capsLock" /></div>
                                                </Collapse>
                                            )}
                                        </CapsLock>
                                        {formik.status &&
											<div className={'alert alert-danger'}><Translate id={`recovery.error.${formik.status}`} /></div>
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </Fade>
                        : '' }

                    { this.state.accountRecoveryForm === 'success' ?
                        <Fade in={this.state.accountRecoveryForm === 'success'}>
                            <div className="mt-4 mb-4 text-center">
                                <span className="d-block h3 mb-3 text-success strong"><Translate id="forgotPassword.success.newPassword" /></span>
                            </div>
                            <Row className="mb-3">
                                <Col xs="12">
                                    <Link to="/login" type="button" className="btn btn-block btn-primary"><Translate id="forgotPassword.action.backToLogin" /></Link>
                                </Col>
                            </Row>
                        </Fade>
                        : '' }
                </div>
            </div>
        );
    }
}

export default WithTourTheme(Recovery);
