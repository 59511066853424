import accountSettings from "./accountSettings";
import twoFA from "./2FA";
import loginHistory from "./loginHistory";
import profile from "./profile";

export default{
    ...accountSettings,
    ...twoFA,
    ...loginHistory,
    ...profile,
}