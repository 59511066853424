import queryString from 'query-string';

/**
 * Creates the api tester url
 * @param {string} baseUrl
 * @param {string} methodType
 * @param {string} endPoint
 * @param {object} queryParams
 * @param {object} headers
 * @param {object} body
 * @param {string} [platformUrl]
 */
function createApiTesterUrl(baseUrl, methodType, endPoint, queryParams, headers, body, platformUrl){
    return queryString.stringifyUrl({
        url: 'https://id.int.spordle.dev/test-api',
        query: {
            base_URL: baseUrl,
            end_point: endPoint,
            headers: headers,
            body: body,
            method_type: methodType,
            endPointParams: queryParams,
        },
    }, {
        skipNull: true,
        skipEmptyString: true,
    })
}

export default createApiTesterUrl;