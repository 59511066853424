import { addMethod, mixed, string } from 'yup';
import API_SPORDLE from '../api/API-Spordle';
import queryString from 'query-string';
import { debounce } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import '@spordle/formik-elements';// Not necessary but adds a safety
import { isMoment } from 'moment';

const cachedZipCodes = {};

const debounceZipCodeValidation = debounce((zipCode, resolve) => {
    API_SPORDLE.get(queryString.stringifyUrl({
        url: '/utils/postal-codes',
        query: {
            postal_code: zipCode,
        },
    }))
        .then((response) => {
            if(response.data.status){
                cachedZipCodes[zipCode] = !!response.data.postal_code;
                resolve(!!response.data.postal_code)
            }
            resolve(false);
        }, () => {
            resolve(false);
        })
}, 200)

addMethod(string, 'zipCode', function(message = <Translate id='form.validation.zip.valid' />){
    return this.transform((zip) => zip.toUpperCase().replace(/\s/, ''))
        .test({
            name: 'zip-validation',
            message: message,
            test: (zipCode) => {
                if(!zipCode || typeof zipCode !== 'string'){
                    return false;
                }

                if(zipCode.length < 3){
                    return false;
                }

                if(zipCode in cachedZipCodes){
                    return cachedZipCodes[zipCode];// Returning the cached value when it's set
                }

                return new Promise((resolve) => {
                    debounceZipCodeValidation(zipCode, resolve)
                });
            },
        })
});

addMethod(mixed, 'isDate', function(message = <Translate id='form.validation.date.format' />){
    return this.test({
        name: 'date-validation-format',
        message: message,
        test: function(date){
            if(date){
                return isMoment(date);
            }
            return true;
        },
    })
});

// Is defined here so we have access to this validation in the entire project
addMethod(string, 'cognitoPassword', function(){
    // Cognito Password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
    return string()
        .required(<Translate id='form.fields.password.required' />)
        .matches(/[^\s-]/, { message: <Translate id='form.fields.password.space' /> })// No spaces
        .matches(/(?=.*[0-9])/, { message: <Translate id='form.fields.password.number' /> })// At least one number
        .matches(/(?=.*[a-z])/, { message: <Translate id='form.fields.password.lowercase' /> })// At least one lowercase
        .matches(/(?=.*[A-Z])/, { message: <Translate id='form.fields.password.uppercase' /> })// At least one uppercase
        .matches(/(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/, { message: <Translate id='form.fields.password.specialChar' /> })// At least one special caracter fromn the Cognito list
        .min(8, <Translate id='form.fields.password.length.min' />)// Min length
        .max(99, <Translate id='form.fields.password.length.max' />);// Max length
});