import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Alert, Button, Collapse, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { WaiversContext } from '../../../../../../contexts/WaiversContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';
import { WaiverContent, WaiverFields, WaiverHeader, waiverNotSigned, WaiverSignatureDisplay } from '../../../../../../helpers/waiversHelper';

const WaiversModal = ({ waiver, signedBy, closeModal, answer, invoiceNumber, onSubmit, period }) => {
    const i18nContext = useContext(I18nContext);
    const waiverContext = useContext(WaiversContext);

    const editMode = answer === waiverNotSigned || (waiver?.waiver?.mandatory != 1 || waiver?.waiver?.critical != 1)

    return (
        <AnalyticsModal isOpen={!!waiver} analyticsName='WaiversModal'>
            <Formik
                enableReinitialize
                initialValues={{
                    waiver: waiver, // only to reinitialize formik
                    sign: answer === waiverNotSigned ? '' : answer,
                }}
                validationSchema={object().shape({
                    sign: string().test({
                        name: 'requiredTest',
                        message: <Translate id='form.fields.waiver.required' />,
                        test: (value) => {
                            return !!value || waiver?.waiver.mandatory == 0
                        },
                    }),
                })}
                onSubmit={({ sign }, { setStatus }) => {
                    if(!sign){
                        // sign could be '' if the waiver is not mandatory, in that case we don't make the API call
                        return Promise.resolve()
                            .then(() => {
                                success();
                                onSubmit();
                            })
                    }
                    return waiverContext.signWaiver(invoiceNumber, waiver.invoiceItem.invoice_item_id, waiver.waiver.waiver_id, { answer: sign, language_code: i18nContext.getGenericLocale() })
                        .then(() => {
                            success();
                        })
                        .then(onSubmit)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader tag='div' toggle={closeModal}>
                                <WaiverHeader waiver={waiver} />
                            </ModalHeader>
                            <ModalBody>
                                {waiver &&
                                    <>
                                        <WaiverContent waiver={waiver} period={period} />

                                        {!editMode ?
                                            <WaiverSignatureDisplay waiver={waiver} />
                                            :
                                            <WaiverFields waiver={waiver?.waiver} fieldName='sign' />
                                        }
                                    </>
                                }

                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='mt-3 mb-0'>
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                {!editMode ?
                                    // Using normal button because of some weird thing hapenning with the reactsrap button submitting even if it was a type='button'
                                    <button className='btn btn-primary' type='button' onClick={closeModal}><Translate id='misc.ok' /></button>
                                    :
                                    <>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                        <Button color='primary' type='button' onClick={closeModal} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                    </>
                                }
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default WaiversModal;
