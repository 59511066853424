import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import {
    Container, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import UATWarning from "../../../components/UATWarning";
import { AccountsContext, AuthContext } from "../../../contexts/contexts";
// Contexts
import withContexts from '../../../helpers/withContexts';
import AccountMemberClinicsManagement from "./clinicsManagement/AccountMemberClinicsManagement";
import AccountMemberCoach from "./coach/AccountMemberCoach";
import AccountMemberHeader from "./components/AccountMemberHeader";
import MembersNav from "./components/MembersNav";
import AccountMemberCrc from "./crc/AccountMemberCrc";
import AccountMemberDocuments from "./documents/AccountMemberDocuments";
import AccountMemberGameIncident from "./gameIncidentReports/AccountMemberGameIncident";
import AccountMemberQualifications from "./qualifications/AccountMemberQualifications";
import AccountMemberRegistrations from "./registrations/AccountMemberRegistrations";
import AccountMemberSchedule from "./schedule/AccountMemberSchedule";
import AccountMemberWaivers from "./waivers/AccountMemberWaivers";
import queryString from 'query-string';
import AccountMemberTravelPermits from "./travelPermits/TravelPermits";
import AccountMemberContacts from "./contacts/AccountMemberContacts";
import MemberSuspensions from "./suspensions/MemberSuspensions";

const stripHash = (hash) => hash.substring(1);
const hasMember = (member) => !!member;
const hasClinicAccess = (member) => hasMember(member) && member.has_clinic_instructor_access == "1";
const hasGameIncidentAccess = (member) => hasMember(member) && member.has_access_game_incident == "1";
const hasCoachAccess = (member) => hasMember(member) && member.has_access_official == "1"
const hasTravelPermitsAccess = (member) => hasMember(member) && member.has_access_travel_permit == "1";

// Constants for url & tab routes for easy access/modifications
const SCHEDULE = 'schedule';
const REGISTRATIONS = 'registrations';
const DOCUMENTS = 'documents';
const QUALIFICATIONS = 'qualifications';
const WAIVERS = 'waivers';
const SUSPENSIONS = 'suspensions';
const CRC = 'crc'; // needs a member in the meta member to see this tab
const CLINICSMANAGEMENT = 'clinics-management'; // needs an access on the member "hasClinicAccess"
const GAMEREPORTS = 'game-reports'; // needs an access on the member "hasGameIncidentAccess"
const COACH = 'coach'; // needs an access on the member "coach"
const TRAVELPERMITS = 'travel-permits';
const CONTACTS = 'contacts'

const TABS = {
    [SCHEDULE]: {
        hasAccess: () => true,
        label: 'account.members.tabs.schedule',
    },
    [CONTACTS]: {
        hasAccess: hasMember,
        label: 'account.members.tabs.contacts',
    },
    [DOCUMENTS]: {
        hasAccess: hasMember,
        label: 'account.members.tabs.documents',
    },
    [REGISTRATIONS]: {
        hasAccess: () => true,
        label: 'account.members.tabs.memberCards',
        classes: "order-sm-0 order-1",
    },
    [QUALIFICATIONS]: {
        hasAccess: () => true,
        label: 'account.members.tabs.certificates',
        classes: "order-sm-0 order-1",
    },
    [WAIVERS]: {
        hasAccess: hasMember,
        label: 'account.members.tabs.waivers',
    },
    [SUSPENSIONS]: {
        hasAccess: hasMember,
        label: 'account.members.tabs.suspensions',
    },
    [CRC]: {
        hasAccess: hasMember,
        label: 'account.members.tabs.crc',
        classes: "order-sm-0 order-1",
    },
    [CLINICSMANAGEMENT]: {
        hasAccess: hasClinicAccess,
        label: 'account.members.clinicsManagement',
    },
    [GAMEREPORTS]: {
        hasAccess: hasGameIncidentAccess,
        label: 'account.members.tabs.gameincident',
    },
    [COACH]: {
        hasAccess: hasCoachAccess,
        label: 'account.members.tabs.coach',
    },
    [TRAVELPERMITS]: {
        hasAccess: hasTravelPermitsAccess,
        label: 'account.members.tabs.travelPermits',
    },
}

const AccountMembers = (props) => {
    const { updateMembers, getIdentityMetaMembers, getIdentityMetaMember } = useContext(AccountsContext);

    const history = useHistory();
    const [ members, setMembers ] = useState(null);
    const [ currentMember, setCurrentMember ] = useState(null);
    const [ teams, setTeams ] = useState(null);
    const [ activeTab, setActiveTab ] = useState(SCHEDULE);
    const [ openModal, setOpenModal ] = useState(false);

    const [ contactsKey, setContactsKey ] = useState(false);
    const updateContactsKey = () => setContactsKey(!contactsKey);

    const queryMetaMemberId = useRef('');

    const changeMember = (member) => {
        setCurrentMember(member);
    }

    const goToAccessibleTab = (hash) => {
        let newHash = hash
        setOpenModal(false)

        // logic to set state to send down to components to open modal for documents / waivers / missing fields
        if(hash.includes('-open')){
            setOpenModal(true);
            newHash = hash.split('-open')[0];
        }

        const strippedHash = stripHash(newHash);
        const targetedTab = TABS[strippedHash];
        const canAccessTab = !!targetedTab && targetedTab.hasAccess(currentMember.members[0]);
        if(canAccessTab){
            setActiveTab(strippedHash);
        }else{
            history.push('/members' + history.location.search + '#' + SCHEDULE);
        }
    }

    const getMetaMembers = (rethrowError = false) => {
        return getIdentityMetaMembers(props.AuthContext.userData?.Username, {}, true)
            .then((res) => {
                setMembers(res.meta_members);
                setTeams(res.teams);
                return res;
            })
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                    setMembers([]);
                }
                if(rethrowError){
                    throw err;
                }
            })
    }

    const refreshMetaMember = (metaMemberId) => {
        return getIdentityMetaMember(props.AuthContext.userData?.Username, metaMemberId)
            .then((metaMember) => {
                const index = members.findIndex((m) => m.meta_member_id === metaMemberId);
                const newMembers = [ ...members ]
                newMembers[index] = metaMember
                setMembers(newMembers)
                changeMember({ ...metaMember, teams: getCurrentMemberTeams(metaMember) })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setMembers([]);
                }
            })
    }

    const getCurrentMemberTeams = (member) => {
        return Object.keys(teams || {}).reduce((newArray, key) => {
            if(member.members?.[0]?.available_teams?.find((teamId) => teamId === key)){
                newArray.push(teams[key])
            }
            return newArray
        }, [])
    }

    /**
     * Function that handles the catch and update after delete or uploading an image
     * @param {promise} func Function to call
     * @param {string} [path] If is null, will update member attachment with null
     * @param {string} [filePos]
     * @returns {promise}
     */
    const handleUpdateMemberImg = async(func, path, filePos) => {
        return func()
            .then(() => {
                const newAtt = {
                    attachment: path ? {
                        full_path: path,
                        file_position: filePos,
                    } : null,
                };

                setCurrentMember((prev) => ({
                    ...prev,
                    ...newAtt,
                }));

                const membersIndex = members.findIndex((m) => m.meta_member_id == currentMember.meta_member_id);

                setMembers((prev) => {
                    const newM = [ ...prev ];
                    newM[membersIndex] = { ...newM[membersIndex], ...newAtt };
                    return newM;
                });

                updateMembers(currentMember, newAtt);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }

    useEffect(() => {
        if(currentMember){
            goToAccessibleTab(props.location.hash);
        }
    }, [ props.location.hash, currentMember ]);

    useEffect(() => {
        if(history.location.search){
            const parsedURL = queryString.parseUrl(`${history.location.pathname}${history.location.search}${history.location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
            if(parsedURL.query.metaMemberId){
                if(!members){
                    queryMetaMemberId.current = parsedURL.query.metaMemberId
                }else{
                    const member = members.find((m) => m.meta_member_id === parsedURL.query.metaMemberId)
                    changeMember({ ...member, teams: getCurrentMemberTeams(member) })
                }
                delete parsedURL.query.metaMemberId
            }

            history.push(queryString.stringifyUrl(parsedURL))
        }
    }, [ history.location.search ])


    useEffect(() => {
        getMetaMembers(true)
            .then(({ meta_members }) => {
                const member = queryMetaMemberId.current ? meta_members.find((m) => m.meta_member_id === queryMetaMemberId.current) : meta_members[0]
                changeMember({ ...member, teams: getCurrentMemberTeams(member) });
            })
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                }
            })
    }, [ ]);

    return (
        <>
            <header className="p-3 mb-0 card card-border card-shadow">
                <h1 className="flex-grow-0 h3 font-medium text-primary mb-0">
                    <Translate id='sidebar.members' />
                </h1>
            </header>
            <UATWarning />
            <section className="page-content container-fluid">
                <ReactTooltip
                    id="member_tip"
                    place='top'
                    effect='solid'
                />
                <Container className="ml-0">
                    <MembersNav
                        activeTab={activeTab}
                        members={members}
                        currentMember={currentMember}
                        teams={teams}
                        getCurrentMemberTeams={getCurrentMemberTeams}
                        getMetaMembers={getMetaMembers}
                        changeMember={changeMember}
                    />
                    <AccountMemberHeader
                        handleUpdateMemberImg={handleUpdateMemberImg}
                        currentMember={currentMember}
                        refreshMetaMember={refreshMetaMember}
                        updateContactsKey={updateContactsKey}
                    />

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Nav Tabs                                                                       */}
                    {/*--------------------------------------------------------------------------------*/}
                    <Nav tabs className="mb-4 font-medium font-16 text-dark">
                        {currentMember &&
                            Object.entries(TABS)
                                .reduce((keptTabs, [ tab, info ]) => {
                                    if(info.hasAccess(currentMember.members?.[0])){
                                        keptTabs.push(
                                            <NavItem className={stringBuilder(info.classes)} key={tab}>
                                                <NavLink
                                                    href={'#' + tab}
                                                    className={stringBuilder({ 'active': activeTab === tab })}
                                                >
                                                    <Translate id={info.label} />
                                                </NavLink>
                                            </NavItem>,
                                        )
                                    }

                                    return keptTabs;
                                }, [])
                        }
                    </Nav>

                    <TabContent activeTab={activeTab} className="bg-transparent">
                        { currentMember &&
                            <>
                                {/* <TabPane tabId="general">
                                    <AccountMemberGeneral member={currentMember} />
                                </TabPane> */}
                                <TabPane tabId={SCHEDULE}>
                                    <AccountMemberSchedule member={currentMember} />
                                </TabPane>
                                <TabPane tabId={REGISTRATIONS}>
                                    <AccountMemberRegistrations member={currentMember} />
                                </TabPane>
                                <TabPane tabId={QUALIFICATIONS}>
                                    <AccountMemberQualifications member={currentMember} />
                                </TabPane>
                                {currentMember.members[0] &&
                                    <>
                                        <TabPane tabId={DOCUMENTS}>
                                            <AccountMemberDocuments member={{ ...currentMember, ...currentMember.members[0] }} openModal={(openModal && activeTab === DOCUMENTS)} refreshMetaMember={refreshMetaMember} />
                                        </TabPane>
                                        <TabPane tabId={WAIVERS}>
                                            <AccountMemberWaivers member={{ ...currentMember, ...currentMember.members[0] }} openModal={openModal && activeTab === WAIVERS} refreshMetaMember={refreshMetaMember} />
                                        </TabPane>
                                        <TabPane tabId={CRC}>
                                            <AccountMemberCrc member={currentMember.members[0]} />
                                        </TabPane>
                                        <TabPane tabId={SUSPENSIONS}>
                                            <MemberSuspensions member={currentMember.members[0]} />
                                        </TabPane>
                                        {hasGameIncidentAccess(currentMember.members[0]) &&
                                            <TabPane tabId={GAMEREPORTS}>
                                                <AccountMemberGameIncident member={{ ...currentMember, ...currentMember.members[0] }} />
                                            </TabPane>
                                        }
                                        {hasClinicAccess(currentMember.members[0]) &&
                                            <TabPane tabId={CLINICSMANAGEMENT}>
                                                <AccountMemberClinicsManagement member={currentMember.members[0]} />
                                            </TabPane>
                                        }
                                        <TabPane tabId={COACH}>
                                            <AccountMemberCoach member={currentMember.members[0]} />
                                        </TabPane>
                                        {hasTravelPermitsAccess(currentMember.members[0]) &&
                                            <TabPane tabId={TRAVELPERMITS}>
                                                <AccountMemberTravelPermits member={{ ...currentMember.members[0], teams: currentMember.teams }} />
                                            </TabPane>
                                        }
                                        <TabPane tabId={CONTACTS}>
                                            <AccountMemberContacts member={currentMember.members[0]} contactsKey={contactsKey} />
                                        </TabPane>
                                    </>
                                }
                            </>
                        }
                    </TabContent>
                </Container>
            </section>
        </>
    )
}

export default withContexts(AuthContext)(AccountMembers);