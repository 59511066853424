import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import moment from "moment";
import 'moment-timezone';
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import Schedule from "../../../components/schedule/Schedule"
import { AccountsContext, AuthContext } from "../../../contexts/contexts";
import ClinicEventModal from "../members/schedule/components/ClinicEventModal";
import EventItem from "../../../components/schedule/events/EventItem";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import UATWarning from "../../../components/UATWarning";
import ClinicCategory from "../../../components/ClinicCategory";
import { getFutureDate, getPastDate } from "../../../helpers/dateHelper";

const AccountCalendar = () => {
    const [ modalInfo, setModalInfo ] = useState(null);
    const [ events, setEvents ] = useState(null);

    const { getAccountCalendar } = useContext(AccountsContext);
    const { userData } = useContext(AuthContext);
    const i18n = useContext(I18nContext);

    const toggleEvent = (event) => setModalInfo({ event: event, isOpen: true });
    const getExternalLink = (arr) => arr[i18n.getGenericLocale()];

    /**
     * @description Checks if members of a session should be merged
     * @param {object} foundSession Session that has the same session id
     * @param {object} session current session to compare
     * @returns {boolean}
     */
    const shouldAddMemberToExisting = (foundSession, session) => {
        if(!!session.hockey_university_link || !!session.hcop_link){
            // If is a hockey university link or hcop link and that the two links are the same
            return foundSession?.locationLink === session.url_link;
        }

        return true;
    }

    // Filters session with same id -> merge members together
    const filterSessions = (sessions) => (
        sessions.reduce((arr, session) => {
            const existing = arr.findIndex((item) => item.clinic_session_id === session.clinic_session_id);

            if(existing !== -1 && shouldAddMemberToExisting(arr[existing], session)){
                // Add member to an existing clinic_session members array
                arr[existing].members.push({ ...session.member });
            }else{
                const date = moment(session.start_date);
                const endDate = moment(session.end_date);
                let location;
                let locationLink;
                if(session.hockey_university_link || session.hcop_link){
                    location = <Translate id={`schedule.label.link.${session.hcop_link ? 'hcop' : 'hu'}`} />;
                    locationLink = getExternalLink(session.hockey_university_link || session.hcop_link);
                }else if(session.session_format == "ONLINE_LIVE" || session.session_format == "ONLINE"){
                    location = session.url_link;
                    locationLink = session.url_link || session.map_url;
                }else{
                    location = session.location_name;
                    locationLink = session.map_url || session.url_link;
                }

                // Formatted for Calendar events
                arr.push({
                    ...session,
                    id: 'session-' + session.clinic_session_id + session.member.member_id,
                    title: <DisplayI18n field='name' defaultValue={session.clinic?.name} i18n={session.clinic?.i18n} />, //<ClinicCategoryBuilder showDot={false} qualification={session.clinic?.qualification} />,
                    date: date,
                    location: location,
                    locationLink: locationLink,
                    timeZoneAbr: moment.tz(session.clinic.timezone).format('z'),
                    startTime: date.format('HH:mm'),
                    endTime: endDate.format('HH:mm'),
                    desc: (
                        <div>
                            <span className="font-medium d-flex align-items-center"><DisplayI18n field='name' defaultValue={session.clinic?.name} i18n={session.clinic?.i18n} /></span>
                            <span className="small d-flex align-items-center"><ClinicCategory.Builder qualification={session.clinic?.qualification} /></span>
                            <span className="d-block"><span className="font-medium"><Translate id='form.fields.format' /></span>: <Translate id={`schedule.format.${session.session_format}`} /></span>
                            <span className="d-block"><span className="font-medium"><Translate id='schedule.label.duration' /></span>: {session.duration}</span>
                        </div>
                    ),
                    eventType: 'session',
                    members: [ { ...session.member } ],
                    hasExternalUrl: session.clinic.has_external_url, // this is mainly used for moodle (v1)
                })
            }

            return arr;
        }, [])
    )

    const filterPayments = (payments) => (
        payments.reduce((arr, pay) => {
            const existing = arr.findIndex((item) => item.invoice.invoice_number + item.due_date == pay.invoice.invoice_number + pay.due_date);
            if(existing !== -1){
                if(!arr[existing].members.some((m) => m.member_id == pay.member.member_id)){ // Checks if member already added
                    arr[existing].members.push({ ...pay.member });
                }
                const newTotal = arr[existing].total + pay.amount;
                arr[existing].total = newTotal;

                // this approach will probably change
                arr[existing].desc = (
                    <p>
                        <span className="d-block font-medium"><Translate id='schedule.label.scheduledPayment' /></span>
                        <span className="d-block"><Translate id='schedule.label.amount' />: <CurrencyFormat value={newTotal / 100} /></span>
                        <span className="d-block"><Translate id='schedule.label.invoice' />: <Link to={`/purchases/invoice/${pay.invoice.invoice_number}`}>{pay.invoice.invoice_number}</Link></span>
                    </p>
                );
            }else{
                const date = moment(pay.due_date);

                arr.push({
                    ...pay,
                    id: 'payment-' + pay.invoice_payment_id,
                    date: date,
                    title: <Translate id='schedule.label.payment' />,
                    eventType: 'payment',
                    total: pay.amount,
                    // this approach will probably change
                    desc: (
                        <p>
                            <span className="d-block font-medium"><Translate id='schedule.label.scheduledPayment' /></span>
                            <span className="d-block"><Translate id='schedule.label.amount' />: <CurrencyFormat value={pay.amount / 100} /></span>
                            <span className="d-block"><Translate id='schedule.label.invoice' />: <Link to={`/purchases/invoice/${pay.invoice.invoice_number}`}>{pay.invoice.invoice_number}</Link></span>
                        </p>
                    ),
                    members: [ { ...pay.member } ],
                })
            }

            return arr;
        }, [])
    )

    useEffect(() => {
        getAccountCalendar(
            userData.Username,
            {
                search_by: 'CLINIC_SESSION,INSTALLMENT',
                start_date: getPastDate(),
                end_date: getFutureDate(),
            },

        )
            .then((calendars) => {
                let theEvents = [];

                if(calendars.clinic_sessions?.length > 0){
                    theEvents = theEvents.concat(filterSessions(calendars.clinic_sessions))
                }

                if(calendars.payment_schedules.length > 0){
                    theEvents = theEvents.concat(filterPayments(calendars.payment_schedules))
                }

                setEvents(theEvents);
            })
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                }
            });
    }, []);

    return (
        <>
            <header className="p-3 mb-0 card card-border card-shadow">
                <div className="h3 font-medium text-primary mb-0"><Translate id='sidebar.calendar' /></div>
            </header>
            <UATWarning />
            <section className="page-content container-fluid">
                {/* <UiTemplate /> */}
                <Card body className="card-shadow">
                    <ClinicEventModal event={modalInfo?.event} isOpen={modalInfo?.isOpen} toggle={() => setModalInfo((prev) => ({ ...prev, isOpen: false }))} />
                    <Schedule
                        eventComponent={EventItem}
                        onEventClick={toggleEvent}
                        events={events}
                    >
                        <Schedule.Navigation timeUnit='weeks' />
                        <Row form className="mb-3">
                            <Col className="d-none d-xl-block" xl="3">
                                <Schedule.Monthly shortDays className="mb-4" />
                                <Schedule.Daily />
                            </Col>
                            <Col className="mb-3 order-1 order-xl-2" xl="9">
                                <Schedule.WeeklyFull />
                            </Col>
                        </Row>
                    </Schedule>
                </Card>
            </section>
        </>
    )
}

export default AccountCalendar;