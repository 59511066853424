export default{
    "invoice.title"              : "Facture",
    "invoice.date"               : "Date",
    "invoice.invoiceNo"          : "Facture #",
    "invoice.paymentMethod"      : "Méthode de paiement",
    "invoice.payedBy"            : "Payé par",
    "invoice.organization"       : "Organisation",
    "invoice.table.item"         : "Article",
    "invoice.table.item.other"   : "Autre article",
    "invoice.table.credits"      : "Crédit",
    "invoice.table.discount"     : "Rabais",
    "invoice.table.registration" : "Inscription",
    "invoice.table.clinic"       : "Stage",
    "invoice.table.season"       : "Saison",
    "invoice.table.season.to"    : "au",
    "invoice.table.tax"          : "Taxe",
    "invoice.table.price"        : "Prix",
    "invoice.table.qty"          : "Qté",
    "invoice.table.total"        : "Total",
    "invoice.table.subTotal"     : "Sous Total",
    "invoice.table.poweredBy"    : "Propulsée par Spordle ©{year} Spordle Inc. Tous droits réservés.",

    "invoice.receipt.members"     : "Membre(s)",
    "invoice.receipt.total"       : "Total",
    "invoice.receipt.paid"        : "Payé",
    "invoice.receipt.alreadyPaid" : "Déjà payé",
    "invoice.receipt.balance"     : "Balance",

    "invoice.error.title"   : "Une erreur s'est produite",
    "invoice.error.message" : "Nous n'avons pas pu trouver de facture. Veuillez revenir plus tard ou contact l'équipe de support.",

    "invoice.transactions.paymentMethods.CHEQUE"       : "Chèque",
    "invoice.transactions.paymentMethods.CASH"         : "Argent comptant",
    "invoice.transactions.paymentMethods.CREDITCARD"   : "Carte de crédit",
    "invoice.transactions.paymentMethods.PAYMENTPROXY" : "Carte de crédit (paiement proxy)",
    "invoice.transactions.paymentMethods.INTERAC"      : "Transfert interac",
    "invoice.transactions.paymentMethods.undefined"    : "N/A",
}