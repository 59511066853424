import Translate from "@spordle/intl-elements";
import { useState } from "react";
import WaiversModal from "./WaiversModal";
import WaiverCard from './WaiverCard';
import { waiverNotSigned } from "../../../../../../helpers/waiversHelper";

const TransactionRegistrationWaivers = ({ selectedRows, tableRef, forceSignWaivers, ...props }) => {
    const waivers = selectedRows[0].waivers
    const waiversToSign = waivers.filter((waiver) => waiver.answer === waiverNotSigned && waiver.waiver.mandatory === "1");

    const [ selectedWaiver, setSelectedWaiver ] = useState(forceSignWaivers ? waiversToSign[0] : null);

    const closeModal = () => {
        setSelectedWaiver(null)
    }

    return (
        <>
            <WaiversModal
                signedBy={{ ...selectedWaiver?.identity, invoice_date: selectedWaiver?.updated_at }}
                waiver={selectedWaiver}
                answer={selectedWaiver?.answer}
                invoiceNumber={selectedRows[0].invoice_number}
                period={selectedRows[0].period}
                closeModal={closeModal}
                onSubmit={() => {
                    const index = waiversToSign.map((waiver) => waiver.id).indexOf(selectedWaiver.id); // get index of current waiver
                    if(forceSignWaivers && index + 1 < waiversToSign.length){ // check if there are any waivers left
                        setSelectedWaiver(waiversToSign[index + 1]) // reopen modal with next waiver
                    }else{
                        closeModal();
                        tableRef.refreshTable();
                        props.toggle();
                    }
                }}
            />
            {waivers.length > 0 ?
                waivers.map(((waiver) => (
                    <WaiverCard
                        key={waiver.id}
                        waiver={waiver}
                        signedBy={{ ...waiver.identity, invoice_date: waiver.updated_at }}
                        answer={waiver.answer}
                        openModal={() => {
                            setSelectedWaiver({ ...waiver });
                        }}
                    />
                )))
                :
                <div>
                    <div className="h4 font-bold"><Translate id='account.transactions.sidePanel.waivers.title' /></div>
                    <p><Translate id='account.transactions.sidePanel.waivers.text' /></p>
                </div>
            }
        </>
    );
}

export default TransactionRegistrationWaivers;