/* eslint-disable react/no-unused-class-component-methods */
import queryString from 'query-string';
import React, { createContext, useContext } from 'react';
import { Spinner } from 'reactstrap';
import useSWR, { mutate } from 'swr';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import { PendingReportToast, SuccessToastMessage } from '../components/reports/PendingReportToast';
import { fire, removeToast, fail } from '@spordle/toasts';
import withContexts from '../helpers/withContexts';
import { AuthContext } from './contexts';
import { I18nContext } from './I18nContext';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<ReportsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const ReportsContext = createContext();
ReportsContext.displayName = 'ReportsContext';

class ReportsContextProvider extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            pendingReports: [],
            isGenerating: false,
        };
    }

    /**
    * @description state that indicates whether or not we are currently generating reports, affects the refresh timing of notifications (makes it shorter)
    * @param {array} pendingReports reports we are waiting to be generated
    */
    setIsGenerating = (isGenerating) => { this.setState((prevState) => ({ isGenerating: isGenerating })) };

    /**
    * @description reports we're waiting on, set in PendingReportsToast for now, the set should probably be moved
    * @param {array} pendingReports reports we are waiting to be generated
    */
    setPendingReports = (pendingReports) => { this.setState((prevState) => ({ pendingReports: pendingReports })) };

    /**
     * POST - Create an exportable/downloadable report
     * @param {object} queryParams
     * @param {string} queryParams.organisation_id ID of the Organization
     * @param {number} queryParams.total_count Download link will only be returned if the total_count is lower than the report's threshold
     * @param {string} queryParams.language_code language code (example: 'en')
     * @param {string} queryParams.report_id ID of the report type
     * @param {string} queryParams.requested_by identity ID of the requester
     * @param {string} queryParams.request_parameter the filters on the reports sent to the API
     * @returns {Promise.<object>}
     * @throws {Error}
    */
    createExportableReport = (queryParams = {}) => { // Often need to create ressource for curretly selected Org & Period
        const params = new URLSearchParams(queryParams);
        params.append('action', 'EXPORT');
        return API_PUBLIC_LOGGEDIN.post(queryString.stringifyUrl({ url: `/reports/requests` }), params)
            .then((response) => {
                if(response.data.status){
                    // if(queryParams.total_count > 300){ THIS DIDN"T WORK CAUSE GIR HAS TOTAL COUNT 1
                    if(!this.state.isGenerating){
                        this.setState({ isGenerating: true });
                        fire({
                            skipInfoTranslate: true,
                            info: PendingReportToast,
                            permanent: true,
                            msg: 'reports.exports.generating',
                            icon: <Spinner className='mr-2' size='sm' color='primary' />,
                            id: 'reportExportToast',
                        });
                    }
                    mutate('getPendingReports');

                    return response.data; // API usually returns the id of the create ressource
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - Gets list of kinds of reports
     * @param {object} queryParams
     * @param {string} queryParams.type report type REGISTRATION, FINANCIAL, MEMBER, TEAM
     * @returns {Promise}
    */
    getReports = (queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: '/reports',
            query: {
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.reports;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * GET[SPECIFIC] - Get an excel report download link
     * @param {string} report_request_id
     * @returns {Promise}
    */
    getReportDownload = (report_request_id) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({ url: `/reports/requests/${report_request_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data; // May return object or array containing ressource
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get excel report objects by user (not the files themselves)
     * @param {string} requested_by user identity_role_id
     * @param {string} organisation_id user organisation_id
     * @returns {Promise.<Array>}
    */
    getExportsReportList = (queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: '/reports/requests',
            query: Object.assign({
                requested_by: this.props.AuthContext.userData?.Username,
                // organisation_id: BC_ID, // we no longer need to send hardcoded BC hcokey org id
                ...queryParams,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_requests;
            }
            throw response.data.errors[0];
        }, serverError)
    }

    /**
     * @description Returns an object container the pending exports and completed exports
     * @returns {Promise.<{pending: object[], completed: object[]}>}
     */
    getReportsByStatus = async() => {
        return this.getExportsReportList()
            .then((exportList) => ({
                pending: exportList.filter((e) => e.request_status === 'PENDING'),
                completed: exportList.filter((e) => e.request_status === 'COMPLETED'),
                declined: exportList.filter((e) => e.request_status === 'DECLINED'),
            }))
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                }
            })
    }

    /**
     * Get all the clinic attendees and their relevant information for the clinic attendees report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/33918152bb4f0ae362403ddb23c562f3|documentation}
     */
    getClinicAttendeesReport = (queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: '/reports/clinic-attendees',
            query: {
                language_code: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }


    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <ReportsContext.Provider value={{ ...this.state, ...this }}>
                { this.state.isGenerating &&
                    <HandleGeneratingReports />
                }
                {this.props.children}
            </ReportsContext.Provider>
        )
    }
}

/**
 * @description Handles the generation of reports as well as the toasts for downloading and waiting for a report
 */
const HandleGeneratingReports = () => {
    const reportsContext = useContext(ReportsContext);
    useSWR(
        'getPendingReports',
        () => reportsContext.state.isGenerating ? reportsContext.getReportsByStatus().catch(console.error) : [],
        {
            refreshInterval: 30000, // 1 minutes refresh interval to match the API
            onSuccess: ({ pending, completed, declined }) => {

                // recentlyCompletedReports contains reports that were in the pending reports context state.
                // These are compared with the list of completed reports and if there are any that are the same,
                // we know they are done being generated and are ready to export, these reports that are ready to export are
                // then mapped over in the Promise.all, where we trigger a 'Download now" toast for each report that is ready to download.

                const recentlyCompletedReports = completed.filter((value) => reportsContext.state.pendingReports.some((o) => {
                    return (o.request_report_id === value.request_report_id);
                }));
                const recentlyDeclinedReports = declined.filter((value) => reportsContext.state.pendingReports.some((o) => {
                    return (o.request_report_id === value.request_report_id);
                }));

                reportsContext.setPendingReports(pending);

                if(pending?.length === 0){
                    removeToast('reportExportToast');
                    reportsContext.setIsGenerating(false);
                }

                if(recentlyDeclinedReports?.length > 0){
                    fail({
                        msg: 'reports.exports.errors.declined',
                        info: 'reports.exports.errors.declinedText',
                        timeout: 15000,
                    });
                }

                if(recentlyCompletedReports?.length > 0){
                    Promise.all(
                        // change for only the ones that are returned
                        recentlyCompletedReports.map((pendingReport) => {
                            return reportsContext.getReportDownload(pendingReport.request_report_id)
                                .then((link) => {
                                    fire({
                                        msg: "reports.exports.generationSuccess",
                                        icon: <i className='mdi mdi-download font-22 text-primary' />,
                                        skipInfoTranslate: true,
                                        info: SuccessToastMessage({ link: link.download_link, id: pendingReport.request_report_id }),
                                        permanent: true,
                                        id: pendingReport.request_report_id,
                                    });
                                })
                                .catch((err) => {
                                    if(!AxiosIsCancelled(err.message)){
                                        console.error(err);
                                    }
                                })
                        }),
                    )
                        .then(() => {
                            if(pending?.length === 0){
                                reportsContext.setIsGenerating(false);
                                removeToast('reportExportToast');
                            }
                        })
                        .catch((err) => {
                            if(!AxiosIsCancelled(err.message)){
                                console.error(err);
                            }
                        })
                }
            },
        },
    );

    return null;
}

export default withContexts(I18nContext, OrganizationContext, AuthContext)(ReportsContextProvider);