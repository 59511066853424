/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {ErrorMessage}from'formik';import {memo}from'react';/**
 * @member FormikError
 * @description
 * FormikError will display error message for the corresponding name
 * @prop {string} name Used to make the FormikError display the error message
 *
 * @example
 * // Custom error rendering
 * <FormikError name='organization'>
 *     {error => <div className='text-warning'>{error}</div>}
 * </FormikError>
 *
 * // Default error rendering
 * <FormikError name='organization'/>
 *
 * @copyright Spordle
 */
const FormikError = ({ name, children }) => jsx(ErrorMessage, { name: name, render: children || ((msg) => jsx("div", { className: "text-danger small", children: msg })) });
FormikError.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func,
};
FormikError.displayName = 'FormikError';
const FormikError$1 = memo(FormikError);export{FormikError$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/