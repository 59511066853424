export default{
    "account.settings.profile.accountAddress.label" : "New address",

    "account.settings.profile.accountAddress.modalTitle" : "Submit an address change request",

    "account.settings.profile.accountAddress.popUp.newAddress.title"        : "Set address",
    "account.settings.profile.accountAddress.popUp.newAddress.description0" : "Your account has no address",
    "account.settings.profile.accountAddress.popUp.newAddress.description1" : "Our system has detected that your account doesn't have an address.",
    "account.settings.profile.accountAddress.popUp.newAddress.description2" : "Please complete your account by adding an address.",

    "account.settings.profile.accountAddress.popUp.verifyAddress.title"                  : "Verify address",
    "account.settings.profile.accountAddress.popUp.verifyAddress.description1"           : "It's been a while since you verified your address.",
    "account.settings.profile.accountAddress.popUp.verifyAddress.description2"           : "Please verify that your current address is valid:",
    "account.settings.profile.accountAddress.popUp.verifyAddress.valid.title"            : "My address is valid",
    "account.settings.profile.accountAddress.popUp.verifyAddress.valid.subtitle"         : "I still live at this address",
    "account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.title"    : "Change my address",
    "account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.subtitle" : "This address is no longer valid",

    // Publish members step
    "account.settings.profile.accountAddress.validation.members.min"               : "Please select member(s) for whom you would like to publish your new address",
    "account.settings.profile.accountAddress.validation.addressType.required"      : "Please select and address type",
    "account.settings.profile.accountAddress.validation.yearsSameAddress.required" : "Please fill in the move-in year",
    "account.settings.profile.accountAddress.validation.yearsSameAddress.isBefore" : "The year cannot be in the future",

    "account.settings.profile.accountAddress.step1.title"   : "Update {membersCount, plural, one {member} other {members}}",
    "account.settings.profile.accountAddress.step1.success" : "You account address has been updated successfully ",
    "account.settings.profile.accountAddress.step1.helper"  : "Some {membersCount, plural, one {member} other {members}} do not have the same address.",
    "account.settings.profile.accountAddress.step1.helper2" : "Would you like to submit an address change request for your {membersCount, plural, one {member} other {members}}?",
    "account.settings.profile.accountAddress.step1.submit"  : "Edit {membersCount, plural, one {member} other {members}}",

    "account.settings.profile.accountAddress.step2.title"                      : "Submit Address Change Request",
    "account.settings.profile.accountAddress.step2.description1"               : "You have {count, plural, one {a member} other {# members}} in:",
    "account.settings.profile.accountAddress.step2.description"                : "Select the members you want to set the new address to",
    "account.settings.profile.accountAddress.step2.moveIn.label"               : "Move-in year",
    "account.settings.profile.accountAddress.step2.moveIn.tip"                 : "Date since participants resided at current address",
    "account.settings.profile.accountAddress.step2.addressType.label"          : "Address type",
    "account.settings.profile.accountAddress.step2.proofOfResistency"          : "Proof of residency",
    "account.settings.profile.accountAddress.step2.proofOfResistency.required" : "Please add a proof of residency",
    "account.settings.profile.accountAddress.step2.submit"                     : "Submit an address change request",
    "account.settings.profile.accountAddress.step2.skipMembers"                : "Skip {count, plural, one {this member} other {these member}}",
    "account.settings.profile.accountAddress.step2.seeMemberSelection"         : "See member selection",

    "account.settings.profile.accountAddress.step3.title"   : "Change request submitted",
    "account.settings.profile.accountAddress.step3.success" : "The address change request was submitted successfully",
}