import { Alert, Button, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from 'prop-types';
import { useState } from "react";
import Translate from "@spordle/intl-elements";
import OverlayLoader from '../../loading/OverlayLoader';
import { bytesToMb } from '../uploadHelpers';
import ImgUpload, { defaultDrop } from "./ImgUpload";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { success } from '@spordle/toasts';

/**
 * @callback onSave
 * Function to call when img is saved
 * @param {File} data Image data
 * @param {String} filePos Stringified position object
 * @param {String} src Image source
 * @returns {Promise}
 */

/**
 * Image uploader in a modal
 * @param {Boolean} props.isOpen Modal is open
 * @param {object} [props.cropperProps] Refer to {@link https://github.com/ricardo-ch/react-easy-crop|Documentation}
 * @param {object} [props.dropzoneProps] Refer to {@link https://react-dropzone.js.org/#src|Documentation}
 * @param {Function} props.toggle Toggle modal
 * @param {onSave} props.onSave Callback
 */
const ImgUploadModal = ({ isOpen, toggle, ...props }) => (
    <AnalyticsModal analyticsName='ImgUploadModal' isOpen={isOpen} toggle={toggle} unmountOnClose backdrop="static">
        {/* Inner is a component for the prop unmountOnClose */}
        <ImgUploadInner toggle={toggle} {...props} />
    </AnalyticsModal>
)

const ImgUploadInner = ({
    toggle,
    onSave,
    dropzoneProps,
    cropperProps,
}) => {
    const [ file, setFile ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    const handleSubmit = () => {
        if(file?.data){
            setIsLoading(true);
            onSave(file.data, JSON.stringify({ ...file.crop, cropSize: file.cropSize, ...file.dimensions, zoom: file.zoom }), file.src)
                .then(() => {
                    toggle();
                    success();
                })
                .catch((e) => {
                    setError({ show: true, msg: 'misc.error' });
                    console.error(e);
                    setIsLoading(false);
                });
        }else{
            setError({
                msg: 'components.uploader.imgUpload.error.noFile',
                show: true,
            })
        }
    }

    const handleError = (err) => {
        switch (err[0].errors[0].code){
            case "too-many-files":
                setError({ msg: 'components.uploader.imgUpload.error.tooMany', show: true });
                break;
            case 'file-too-large':
                setError({
                    msg: 'components.uploader.imgUpload.error.tooLarge',
                    values: { maxSize: bytesToMb(dropzoneProps.maxSize || defaultDrop.maxSize) },
                    show: true,
                });
                break;
            case 'file-invalid-type':
                setError({
                    msg: 'components.uploader.imgUpload.error.fileType',
                    values: { accepted: `${dropzoneProps.accept || defaultDrop.accept}`?.replace(/image\//gi, ".") },
                    show: true,
                });
                break;
            default:
                setError({ msg: 'misc.error', show: true });
                break;
        }
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}><Translate id='components.uploader.imgUpload.modal.title' /></ModalHeader>
            <ModalBody>
                <ImgUpload
                    dropzoneProps={{ ...dropzoneProps, onDropRejected: handleError }}
                    cropperProps={cropperProps}
                    onChange={setFile}
                />
                { error &&
                    <Collapse isOpen={error.show} appear>
                        <Alert isOpen={error.show} color='danger' className="mt-2 mb-0" toggle={() => setError((prev) => ({ ...prev, show: false }))}>
                            {error.msg && <Translate id={error.msg} values={error.values ? error.values : undefined} defaultMessageId='misc.error' />}
                        </Alert>
                    </Collapse>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="button" onClick={handleSubmit}><Translate id="misc.save" /></Button>
                <Button color="primary" outline type="button" onClick={toggle}><Translate id="misc.cancel" /></Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default ImgUploadModal;

ImgUploadModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    cropperProps: PropTypes.object,
    dropzoneProps: PropTypes.object,
    toggle: PropTypes.func.isRequired,
}

ImgUploadModal.defaultProps = {
    cropperProps: {
        restrictPosition: false,
        showGrid: false,
        cropSize: {
            width: 150,
            height: 150,
        },
        aspect: 1,
        cropShape: 'round',
    },
    dropzoneProps: {
        multiple: false,
        maxSize: 2097152,
        accept: "image/jpeg, image/png, image/jpg",
    },
}