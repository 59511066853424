/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {formikElementStitches}from'./stitches.js';const Btn = formikElementStitches.styled('button', {
    bottom: 0,
    top: 0,
    variants: {
        position: {
            left: {
                left: 0,
            },
        },
        isInvisible: {
            true: {
                visibility: "hidden",
            },
        },
    },
    "&.search-clear": {
        width: "2em",
    },
});
const Input = formikElementStitches.styled('input', {
    "&.form-control": {
        paddingLeft: "2em",
    },
    variants: {
        isClearable: {
            true: {
                "&.form-control": {
                    paddingRight: "2em",
                },
            },
        },
    },
});export{Btn,Input};
/*
@Copyrights Spordle 2023 - All rights reserved
*/