export const OFFICIAL_TYPES = [
    "REFEREE", "LINESMAN", "SUPERVISOR",
];

export const GAME_PERIODS = [
    "FIRST",
    "SECOND",
    "THIRD",
    "OVERTIME",
    "SHOOTOUT",
    "PRE_POST_GAME",
];

export const BC_ID = '1eb5d98e-ac53-658c-b920-06ba8f7ba4e0'; // TEMP

const COACH_POS_GROUP = "COACH";
const MANAGER_POS_GROUP = [ "COACH", "STAFF" ];
const STATUS_NAME_SUBMITTED = "SUBMITTED";
const STATUS_NAME_PENDING = "PENDING";

export function isPending(status){
    if(!status)return false;

    return (status.name || '').toUpperCase() === STATUS_NAME_PENDING;
}

export function isSubmitted(status){
    if(!status)return false;

    return (status.name || '').toUpperCase() === STATUS_NAME_SUBMITTED;
}

export function isManager(posGroup){
    if(!posGroup)return false;

    return MANAGER_POS_GROUP.includes((posGroup.position_type || '').toUpperCase())
}

export function isCoach(posGroup){
    if(!posGroup)return false;

    return COACH_POS_GROUP.includes((posGroup.position_type || '').toUpperCase())
}