import accountAddress from "./accountAddress";
import accountBirthdate from "./accountBirthdate";
import accountEmail from "./accountEmail";
import accountGender from "./accountGender";
import accountLanguage from "./accountLanguage";
import accountName from "./accountName";
import accountPassword from "./accountPassword";
import accountPhone from "./accountPhone";

export default{
    ...accountBirthdate,
    ...accountEmail,
    ...accountGender,
    ...accountLanguage,
    ...accountName,
    ...accountPassword,
    ...accountPhone,
    ...accountAddress,
}