export default{
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.title"                         : "Mise à jour groupée",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.helper"                        : "Vous êtes sur le point de mettre à jour {mode, select, PRESENCE {le statut de présence} RESULT {les résultats} other {{mode}}} pour tous les membres ci-dessous",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.inputLabel"                    : "{mode, select, PRESENCE {Présence} RESULT {Résultat} other {{mode}}}",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.cannot"                        : "Les membres sélectionnés ne peuvent pas être mis à jour",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateBefore"         : "La date doit être avant aujourd'hui",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter"          : "La date doit être après le {minDate}",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter.attended" : "La date doit être après la date de présence ({minDate})",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.title"       : "{members, plural, =0 {Membre} one {Membre} other {Membres}} ({members})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.description" : "{members, plural, =0 {Aucun membre sera} one {Le membre suivant sera} other {Les membres suivants seront}} mis à jour",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.none"        : "Aucun membre ne peut être mis à jour",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.title"    : "{members, plural, =0 {Membre invalide} one {Membre invalide} other {Membres invalides}} ({members})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.subtitle" : "Ces membres ne peuvent pas être mis à jour",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.paid"     : "Ce membre n'a pas payé ",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.attended" : "Ce membre n'a pas assisté",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.passed"   : "Ce membre a déjà réussi",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success"      : "Mise à jour réussie ({count})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success.desc" : "{count, plural, one {Le membre suivant a} other {Les membres suivants ont}} été mis à jour",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail"         : "Échec de la mise à jour ({count})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail.desc"    : "{count, plural, one {Le membre suivant n'a pas pu} other {Les membres suivants n'ont pas pu}} être mis à jour",

    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result"   : "Mettre à jour les résultats",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence" : "Mettre à jour les présences",
}