import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { AccountMemberAddCoachContext } from "../components/AccountMemberAddCoachContext";
import { object, string, mixed } from "yup";
import moment from "moment";
import { FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Required from "../../../../../../../components/formik/Required";
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { displayI18n, DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { I18nContext } from "../../../../../../../contexts/I18nContext";

const StepFindOfficial = ({ toggle, member: officiatingMember }) => {
    const { next, isLoading, setLoading, updateFormData } = useContext(AccountMemberAddCoachContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getOrganizationsByCategory, getOrganizationCategories, federationId } = useContext(OrganizationContext);
    const { getPublicMembers } = useContext(MembersContext);
    const [ results, setResults ] = useState(null);

    const handleOnSelectMember = (member) => {
        updateFormData('official', member);
        next();
    }

    const getBranches = async() => {
        const categories = await getOrganizationCategories(federationId);
        const branchCatId = categories?.find((c) => (c.default_name || '').toLowerCase() === "member branches")?.category_id;
        const branches = await getOrganizationsByCategory(federationId, branchCatId);

        return (branches || []).sort((a, b) => {
            const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
            const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
            return aName.localeCompare(bName);
        })
    }

    return (
        <Formik
            initialValues={{
                organisation_id: '',
                first_name: '',
                last_name: '',
                birthdate: '',
                unique_identifier: '',
            }}
            validationSchema={object().shape({
                organisation_id: string(),
                first_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.firstName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },

                }),
                last_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.lastName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },
                }),
                birthdate: mixed().test({
                    name: 'isValid',
                    message: <Translate id='form.validation.dateOfBirth.valid' />,
                    test: function(date){
                        return !date || (moment.isMoment(date) && moment(date).isSameOrBefore(moment()));
                    },
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values) => {
                setLoading(true);
                const apiValues = {
                    with_official_qualification: 1,
                };

                if(values.unique_identifier){
                    apiValues.unique_identifier = values.unique_identifier;
                }else{
                    apiValues.first_name = values.first_name;
                    apiValues.last_name = values.last_name;

                    if(values.birthdate && moment.isMoment(values.birthdate)){
                        apiValues.birthdate = values.birthdate.format('YYYY-MM-DD');
                    }

                    if(values.organisation_id){
                        apiValues.organisation_id = values.organisation_id;
                    }
                }


                return getPublicMembers(apiValues)
                    .then((members) => {
                        setResults(members);
                        setLoading(false);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setLoading(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form id="searchMemberInfo">
                    <ModalBody>
                        <Row form>
                            <Col className="form-group" sm="12">
                                <Label className="text-muted"><Translate id='account.members.gir.addModal.label.org' /></Label>
                                <FormikSelect
                                    name="organisation_id"
                                    id="organisation_id"
                                    disabled={!!formik.values.unique_identifier}
                                    clearable
                                    searchKeys={[
                                        `organisation_name`,
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getBranches()
                                                    .then((branches) => branches.map((branch) => ({ label: branch.organisation_name, value: branch.organisation_id, i18n: branch.i18n })));
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="last_name"><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="birthdate"><Translate id="form.fields.dateOfBirth" /></Label>
                                <FormikDateTime
                                    id='birthdate'
                                    name='birthdate'
                                    timeFormat={false}
                                    dateFormat='YYYY-MM-DD'
                                    inputProps={{ disabled: !!formik.values.unique_identifier }}
                                    isValidDate={(current) => moment(current).isBefore(moment())}
                                />
                            </Col>
                            <Col sm="12">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="border-top flex-grow-1" />
                                    <div className="px-3 font-medium small"><Translate id="addMemberModal.formik.label.search.or" /></div>
                                    <div className="border-top flex-grow-1" />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Label className="text-muted" for="unique_identifier"><Translate id="addMemberModal.formik.label.uniqueIdentifier" /></Label>
                                <FormikInputText
                                    helper={
                                        <small className="text-muted">
                                            <i className="text-primary mdi mdi-information-outline" /> <Translate id="addMemberModal.formik.warning.filters.ignored" />
                                        </small>
                                    }
                                    translateHelper={false}
                                    name="unique_identifier"
                                    trim
                                />
                            </Col>
                        </Row>
                        <Collapse className="mt-3" isOpen={results !== null}>
                            {results && results.length > 0 ?
                                <div className="border-top">
                                    <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }}>
                                        {results?.map((member) => (
                                            <div key={member.member_id}>
                                                <UserDisplay className="mb-2 w-100" card>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            width={45}
                                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                            alt={member.first_name + ' ' + member.last_name}
                                                            src={member.picture?.full_path}
                                                            filePos={member.picture?.file_position}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        {member.member_id === officiatingMember.member_id &&
                                                            <div className="small text-muted">
                                                                <i className="mdi mdi-alert-outline" /> <Translate id='account.members.coach.addSupervision.cantSuperviseThemselves.label' />
                                                            </div>
                                                        }
                                                        <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                                                        <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>
                                                            <DisplayI18n
                                                                field="name"
                                                                defaultValue={member.organisation?.organisation_name}
                                                                i18n={member.organisation?.i18n}
                                                            />
                                                        </UserDisplay.Subtitle>
                                                        {member.member_qualification &&
                                                            <UserDisplay.Subtitle>
                                                                <DisplayI18n field='name' defaultValue={member.member_qualification.qualification.name} i18n={member.member_qualification.qualification.i18n} />{(member.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={member.member_qualification.qualification_level.name} i18n={member.member_qualification.qualification_level.i18n} /></>}
                                                            </UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="ml-auto text-right">
                                                        <Button
                                                            disabled={member.is_official != "1" || member.member_id === officiatingMember.member_id}
                                                            size="sm"
                                                            onClick={() => {
                                                                handleOnSelectMember(member);
                                                            }}
                                                            color="primary"
                                                            type="button"
                                                        >
                                                            {
                                                                (member.member_id === officiatingMember.member_id || member.is_official == "1") ?
                                                                    <Translate id='misc.select' />
                                                                    :
                                                                    <Translate id='account.members.coach.addSupervision.notOfficial.label' />
                                                            }
                                                        </Button>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </div>
                                        ))}
                                    </PerfectScrollbar>
                                </div>
                                :
                                <div className="text-center">
                                    <Translate id='misc.search.empty.title' />
                                </div>
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="submit">
                            <Translate id="misc.search" />
                        </Button>
                        <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="button" outline onClick={toggle}>
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default StepFindOfficial;