import { FormikRadioButton } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import MASTERCARD from '../../../../../../../assets/images/logos/MasterCard.svg';
import VISA from '../../../../../../../assets/images/logos/Visa.svg';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';

const PaysafeViewCards = ({ paysafeCards, paysafeCardsLoading, paysafeError, edit }) => {
    const formik = useFormikContext();
    const intl = useIntl();

    const cardIsExpired = (month, year) => {
        const cardExpirationDate = moment([ year, month - 1 ]);
        return cardExpirationDate.isValid() && (moment() > cardExpirationDate.add(1, 'months'));
    }

    const getCardLogo = (type) => {
        switch (type){
            case 'VI':
                return VISA;
            case 'MC':
                return MASTERCARD;
            default:
                return;
        }
    }

    return (paysafeCardsLoading ?
        <Skeleton height={71} count={paysafeCards?.length || 2} />
        : paysafeError ?
            <Translate id='misc.error' />
            : paysafeCards.map((card) => {
                const isExpired = cardIsExpired(card.cardExpiry.month, card.cardExpiry.year);
                return (
                    <UserDisplay
                        key={card.id}
                        card
                        hover={!isExpired}
                        tag="label"
                        htmlFor={card.id}
                        className={stringBuilder('d-flex mb-2', { "bg-light": !!isExpired, "border-primary": formik.values.paymentToken === card.paymentToken })}
                    >
                        <UserDisplay.Container>
                            <FormikRadioButton disabled={!!isExpired} name="paymentToken" id={card.id} value={card.paymentToken} />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title aria-label={intl.formatMessage({ id: 'account.paymentMethods.label.card.accessibility.endingWith' }, { lastDigits: card.lastDigits })}>
                            **** **** **** {card.lastDigits}
                            </UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                {card.holderName}
                                <span className='mx-1'>-</span>
                                <DateFormat format='MM/YY' value={[ card.cardExpiry.year, card.cardExpiry.month - 1 ]} utc />
                            </UserDisplay.Subtitle>
                            {isExpired &&
                            <UserDisplay.Subtitle className="text-warning">
                                <i className="mdi mdi-alert-outline mr-1" /><Translate id='account.paymentMethods.label.cardExpired' />
                            </UserDisplay.Subtitle>
                            }
                        </UserDisplay.Container>
                        <UserDisplay.Container className='ml-auto' />
                        <UserDisplay.Container className='ml-auto'>
                            <button className='reset-btn text-link' type='button' onClick={edit}>
                                <Translate id='misc.edit' />
                                <i className='mdi mdi-pencil ml-1' />
                            </button>
                            <img src={getCardLogo(card.cardType)} height='40' className='ml-5' />
                        </UserDisplay.Container>
                    </UserDisplay>
                )
            })
    )
}

export default PaysafeViewCards
