import NotFound from '../../views/NotFound';

import TermsOfUse from '../../components/termsOfUse/TermsOfUse';
import ConfirmationPicker from '../mapping/ConfirmationPicker';
import ForgotPasswordPicker from '../mapping/ForgotPicker';
import LoginPicker from '../mapping/LoginPicker';
import RecoveryPicker from '../mapping/RecoveryPicker';
import SignUpPicker from '../mapping/SignupPicker';
import AuthenticatorProvider from '../../components/authentication/AuthenticatorProvider';

const BundledAuthRoutePicker = ({ rest, ...props }) => (
    <AuthenticatorProvider>
        {(() => {
            switch (rest.dynamicComponent){
                case 'login':
                    return <LoginPicker {...props} />;
                case 'signup':
                    return <SignUpPicker {...props} />;
                case 'confirmation':
                    return <ConfirmationPicker {...props} />;
                case 'recovery':
                    return <RecoveryPicker {...props} />;
                case 'forgot-password':
                    return <ForgotPasswordPicker {...props} />;
                case 'terms':
                    return <TermsOfUse {...props} />;
                default:
                    return <NotFound {...props} />;
            }
        })()}
    </AuthenticatorProvider>
);

export default BundledAuthRoutePicker;