import { stringBuilder } from "@spordle/helpers";

const Hour = ({ time, itemClassName }) => {
    return (
        // <li className="schedule-weekly-hours-list-item">{time}</li>
        <li className={itemClassName}>{time}</li>
    )
}

const HoursList = ({ className, itemClassName, fullClock = false, ...props }) => {

    function getHours(){
        const arr = [];

        for(let i = 0; i < 24; i++){
            if(!fullClock){
                arr.push(i >= 13 ? `${i - 12} PM` : `${i} AM`);
            }else{
                arr.push(`${i < 10 ? `0${i}` : i}:00`);
            }
        }

        return arr;
    }

    return (
        <ol className={stringBuilder(className)} {...props}>
            {getHours().map((hour) => <Hour className={itemClassName} key={hour} time={hour} />)}
        </ol>
    )
}

export default HoursList;