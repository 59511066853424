import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import FadeIn from '../../animations/FadeIn';
import EventList from '../events/EventList';
import { ScheduleContext } from '../Schedule';

/**
 * Showcase events in a day
 * @param {Object} props
 * @param {string} [props.className]
 * @param {boolean} [props.showRecurrent]
 * @returns {JSX.Element}
 */
const ScheduleDaily = ({ className, showRecurrent }) => {
    const { selected } = useContext(ScheduleContext);

    return (
        <div className={className}>
            <EventList
                showRecurrent={showRecurrent}
                momentObj={selected.selected}
                type="daily"
                dep={selected.day}
                emptyLayout={
                    <FadeIn
                        distance={50}
                        direction='bottom'
                        style={{ opacity: 0 }}
                        dependency={selected.day}
                        delay={0.25}
                    >
                        <h4 className="h2"><Translate id='schedule.daily.empty.title' /></h4>
                        <p className="text-muted"><Translate id='schedule.daily.empty.text' /></p>
                    </FadeIn>
                }
            />
        </div>
    );
}

export default ScheduleDaily;