import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SidePanelTitle from './components/SidePanelTitle';
import SidePanelSubtitle from './components/SidePanelSubtitle';
import SidePanelToggleButton from './components/SidePanelToggleButton';
import SidePanelHeader from './components/SidePanelHeader';
import SidePanelBody from './components/SidePanelBody';
import SidePanelMenuAction from './components/SidePanelMenuAction';
import SidePanelMenuDelete from './components/SidePanelMenuDelete';
import SidePanelActionsMenu from './components/SidePanelActionsMenu';
class SidePanel extends React.Component{
    render(){
        return (
            <>
                {this.props.isOpen && this.props.overlayToggle &&
                    <div
                        className="sidePanel-backdrop" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.overlayToggle();
                        }}
                    />
                }
                <aside className={this.props.isOpen ? 'sidePanel isOpen' : 'sidePanel'}>
                    {/* TODO: sidepanel's header should go here,
                        so we wont need the position-sticky and
                        perfectscrollbar won't get under the header */}
                    <PerfectScrollbar>
                        {this.props.isOpen &&
                            this.props.children
                        }
                    </PerfectScrollbar>
                </aside>
            </>
        );
    }
}

SidePanel.ToggleButton = SidePanelToggleButton;
SidePanel.Title = SidePanelTitle;
SidePanel.Subtitle = SidePanelSubtitle;
SidePanel.Header = SidePanelHeader;
SidePanel.Body = SidePanelBody;
SidePanel.MenuDelete = SidePanelMenuDelete;
SidePanel.MenuAction = SidePanelMenuAction;
SidePanel.ActionsMenu = SidePanelActionsMenu;

export default SidePanel;
