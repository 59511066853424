import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

const WithTourTheme = (Component) => (props) => {
    const themeContext = useContext(ThemeContext)
    useEffect(() => {
        themeContext.setTheme('TOUR');
    }, [])
    return <Component {...props} />
}

export default WithTourTheme;