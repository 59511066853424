import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedPlural } from 'react-intl';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { Badge, Card, Collapse, Table } from 'reactstrap';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const TransationRefunds = ({ refunds, totalRefundAmount }) => {
    return (
        (refunds && refunds.length > 0) &&
            <div className='mb-3'>
                <div className="h4 font-bold">
                    <FormattedPlural
                        value={refunds.length}
                        one={<Translate id='account.transactions.sidePanel.items.refund' />}
                        other={<Translate id='account.transactions.sidePanel.items.refunds' />}
                    />
                </div>
                <Card className='card-shadow mb-3 p-3'>
                    <Table size="sm" responsive className="mb-0">
                        <thead>
                            <tr>
                                <td className='td-shrink border-top-0'><Translate id='account.transactions.sidePanel.items.date' /></td>
                                <td className='text-center border-top-0'><Translate id='account.transactions.sidePanel.items.status' /></td>
                                <td className='border-top-0'><Translate id='account.transactions.sidePanel.items.amount' /></td>
                            </tr>
                        </thead>
                        <tbody>
                            {refunds.map((refund) => (
                                <RefundItem key={refund.invoice_payment_id} refund={refund} />
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2" className="text-right">
                                    <div className="font-bold"><Translate id='account.transactions.total' /> : </div>
                                </td>
                                <td className="text-right td-shrink">
                                    <div className="text-nowrap"><CurrencyFormat value={totalRefundAmount / 100} /></div>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Card>
            </div>

    );
}

const RefundItem = ({ refund }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);

    const getBadgeColor = useCallback(() => {
        switch (refund.status){
            case 'COMPLETED':
                return 'success';
            case 'PENDING':
            case 'NEED_REVIEW':
            case 'PARTIALLY_PAID':
                return 'warning';
            default:
                return 'danger';
        }
    }, [ refund.status ]);

    return (
        <>
            <tr onClick={refund.external_note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={refund.external_note ? 'clickable' : undefined}>
                <td className='td-shrink'>
                    {refund.external_note &&
                        <span className='position-absolute left-0'>
                            <i className='font-20 mdi mdi-chevron-down d-inline-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '0' : '-90'}deg)` }} />
                        </span>
                    }
                    <DateFormat value={refund.refund_date} utc />
                    <div className='small'>
                        <DisplayI18n field='name' defaultValue={refund.refund_method?.name} i18n={refund.refund_method?.i18n} />
                    </div>
                </td>
                <td className='align-middle text-center'>
                    <Badge color={getBadgeColor()} size="lg" pill className="px-3 align-self-start">
                        <Translate id={'account.transactions.sidePanel.status.' + refund.status} />
                    </Badge>
                </td>
                <td className='align-middle text-right'>
                    <CurrencyFormat value={refund.amount / 100} />
                </td>
            </tr>
            {refund.external_note &&
                <tr>
                    <td colSpan='3' className='border-0 p-0'>
                        <Collapse isOpen={noteIsOpen}>
                            <div className='mx-2 mb-2' dangerouslySetInnerHTML={{ __html: refund.external_note?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href") }} />
                        </Collapse>
                    </td>
                </tr>
            }
        </>
    )
}

TransationRefunds.propTypes = {
    refunds: PropTypes.array.isRequired,
};

export default TransationRefunds;