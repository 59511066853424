import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Public';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import * as Sentry from "@sentry/react";
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { getMultisportCode } from '../helpers/getReferer';

/** @type {React.Context<Omit<OrganizationContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & OrganizationContextProvider['state']} */
export const OrganizationContext = createContext();
OrganizationContext.displayName = 'OrganizationContext';

export const sessionStorageOrganizationId = 'organizationId';

export function getFederationId(){
    if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
        switch (getMultisportCode()){
            case "UK":
                return 'bf2ef31c-14ce-11ed-bed9-023f3d3ef136';
            default:
                break;
        }
    }
    return '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9';// HC
}

class OrganizationContextProvider extends React.Component{
    // static contextType = IdentityRolesContext;

    /*
    State will look like this:
    {
        abbreviation: "RE"
        active: "1"
        address: ""
        city: ""
        country_id: null
        created_at: "2020-11-23 09:46:44"
        fax: null
        identification_number: "",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: {
            organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90",
            name: "Association / Club"
        }
        name: "Association / Club"
        organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90"
        organisation_category_id: "5"
        organisation_email: null
        organisation_id: "1eb2d9ab-49d4-6fb8-866b-0ec110310a18"
        organisation_id_parent: "1eb1f975-74e6-6376-9b74-e454e80c8447"
        organisation_name: "Région Estrie"
        origin: "ADMIN"
        phone: ""
        province_id: ""
        short_url: "t3"
        updated_at: "2020-12-14 10:17:54"
        visible_on_website: "0"
        website: ""
        with_sub_organisation: "1"
        zip_code: ""
    }
    */

    /*state = {
        abbreviation: "Hockey Canada",
        active: "1",
        address: null,
        city: null,
        country_id: null,
        created_at: "2020-11-17 11:46:19",
        fax: null,
        identification_number: "123456789",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: [],
        organisation_category_id: null,
        organisation_email: "infos@hockeycanada.ca",
        organisation_id: "041ed146-8a8a-40f5-8d82-bb9967cfeb42",
        organisation_id_parent: null,
        organisation_name: "Hockey Canada",
        origin: "ADMIN",
        phone: null,
        province_id: null,
        short_url: "hcr",
        updated_at: null,
        visible_on_website: "1",
        website: null,
        with_sub_organisation: "1",
        zip_code: null
    }*/

    state = {}

    federationId = getFederationId();

    /**
     * @type {?Array}
     * @description Cached organization tree
     */
    orgTree = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgBranch = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgRegion = null;

    /**
     * @type {?Array}
     * @description Cached organization categories
     */
    orgCategories = null;

    /**
     * @typedef {object} Setting
     * @property {string} code
     * @property {string} name
     * @property {Record<string, any>} i18n
     * @property {any} response
     */
    /**
     * Formats the settings info from the api to our JS structure
     * @param {Setting[]} settings
     * @returns {object}
     * @private
     */
    _formatSettings = (settings) => {
        return Array.isArray(settings) ? settings.reduce((formattedSettings, setting) => {
            if(setting.code === 'language' && !Array.isArray(setting.response)){
                setting.response = [ setting.response ];
            }

            if(setting.code === 'language' && setting.response.length === 0){ // No languages set
                setting.response = [ 'en' ];// Defaults to english
            }
            formattedSettings[setting.code] = {
                value: setting.response,
                name: setting.name,
                i18n: setting.i18n,
                ressource: setting.ressource,
            }
            return formattedSettings;
        }, {}) : { language: { value: [ 'en' ], name: 'Languages' } };
    }

    componentDidUpdate(){
        Sentry.setContext('organization', this.state.organisation_id ? {
            organizationId: this.state.organisation_id,
            organizationName: this.state.organisation_name,
            languages: this.state.settings?.languages,
        } : null);
        Sentry.setTag('organizationId', this.state.organisation_id);
    }

    // Temporary, needed for org search
    setFederation = () => {
        return this.getOrganization(this.federationId)
            .then((fedData) => {
                return this.getOrganizationSettings(this.federationId)
                    .then((settings) => {
                        this.setState((prevState) => ({ ...prevState, federation: { ...fedData, ...settings } }));
                        return fedData.organisation_id;
                    }, (error) => {
                        console.error(error.message)
                    })
            }, (error) => console.error(error.message))
    }

    /**
     * Set the new organization
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentOrg = (orgId) => {
        return this.getOrganization(orgId)
            .then((orgData) => {
                return this.getOrganizationSettings(orgId)
                    .then((settings) => {
                        this.setState((prevState) => ({ ...prevState, ...orgData, settings: settings }));
                        return orgData.organisation_id;
                    }, (error) => {
                        console.error(error.message)
                    })
            }, (error) => console.error(error.message))
    }

    /**
     * Get organisation tree with sub-organisation
     * @returns {Promise}
     */
    getOrganizationsTree = (orgId, fromApi = false, cache = true, queryParams = {}) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(Array.isArray(this.orgTree) && !fromApi){
            return Promise.resolve(this.orgTree);
        }

        return API_SPORDLE.get(`organisations/${orgId || this.federationId}/tree`)
            .then((response) => {
                if(response.data.status){
                    if(cache){
                        return this.orgTree = response.data.organisations;
                    }
                    return response.data.organisations;

                }
                this.orgTree = null;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganization = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation[0];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization children
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationChildren = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/children` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationBranch = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgBranch && !fromApi){
            return Promise.resolve(this.orgBranch);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/branch` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgBranch = response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationRegion = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgRegion && !fromApi){
            return Promise.resolve(this.orgRegion);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/region` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgRegion = response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the Organization's Settings
     * @param {string} organizationId ID of the Organization we want to get the Settings from
     * @returns {Promise}
     */
    getOrganizationSettings = (organizationId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/settings` }))
            .then((response) => {
                if(response.data.status){
                    return this._formatSettings(response.data.settings);
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationCategories = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(Array.isArray(this.orgCategories) && !fromApi){
            return Promise.resolve(this.orgCategories);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/category` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgCategories = response.data.categories;
                }
                this.orgCategories = null;
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets all organisations of a organisation category id
     * @param {string} organizationId
     * @param {string} categoryId
     * @returns {Promise.<object[]>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation/dac7684bd9b928ec671b5eddcfc15eae|documentation}
     */
    getOrganizationsByCategory = (organizationId, categoryId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `organisations/${organizationId}/categories`,
            query: {
                organisation_category_id: categoryId,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Does a partial update of the org
     * @param {string} [organizationId]
     * @param {object} values
     * @param {string} values.orgNameEn
     * @param {string} values.orgNameFr
     * @param {string} values.abbreviation
     * @param {string} values.email
     * @param {string} values.phone The phone number with the extension in it. ex: +16135550106#123
     * @param {string} values.website
     * @param {string} values.address
     * @param {string} values.address2
     * @param {string} values.city
     * @param {string} values.zipCode
     * @param {string} values.province
     * @param {string} values.country
     * @param {string} values.identification_number
     * @param {1|0} values.withSubOrganisation
     *
     * @returns {Promise}
     */
    updateOrganizationPartial = (values, organizationId = this.state.organisation_id) => {
        /**
         * Data for the api
         */
        const patchData = new URLSearchParams();
        /**
         * Data to update the context's state
         */
        const formattedData = {};

        // Building the data partial data for the api call
        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                switch (key){
                    case 'name':
                        formattedData.organisation_name = values[key] || '';
                        patchData.append('organisation_name', values[key] || '');
                        break;
                    case 'abbreviation':
                        formattedData.abbreviation = values[key] || '';
                        patchData.append('abbreviation', values[key] || '');
                        break;
                    case 'email':
                        formattedData.organisation_email = values[key] || '';
                        patchData.append('organisation_email', values[key] || '');
                        break;
                    case 'phone':
                        formattedData.phone = values[key] || '';
                        patchData.append('phone', values[key] || '');
                        break;
                    case 'website':
                        formattedData.website = values[key] || '';
                        patchData.append('website', values[key] || '');
                        break;
                    case 'streetNumber':
                        formattedData.street_number = values[key] || '';
                        patchData.append('street_number', values[key] || '');
                        break;
                    case 'street':
                        formattedData.street = values[key] || '';
                        patchData.append('street', values[key] || '');
                        break;
                    case 'address2':
                        formattedData.unit_number = values[key] || '';
                        patchData.append('unit_number', values[key] || '');
                        break;
                    case 'city':
                        formattedData.city = values[key] || '';
                        patchData.append('city', values[key] || '');
                        break;
                    case 'zipCode':
                        formattedData.zip_code = values[key] || '';
                        patchData.append('zip_code', values[key] || '');
                        break;
                    case 'province':
                        formattedData.province_code = values[key] || '';
                        patchData.append('province_code', values[key] || '');
                        break;
                    case 'country':
                        formattedData.country_code = values[key] || '';
                        patchData.append('country_code', values[key] || '');
                        break;
                    case 'mapsUrl':
                        formattedData.map_url = values[key] || '';
                        patchData.append('map_url', values[key] || '');
                        break;
                    case 'identification_number':
                        formattedData.identification_number = values[key] || '';
                        patchData.append('identification_number', values[key] || '');
                        break;
                    case 'withSubOrganisation':
                        formattedData.with_sub_organisation = (!!values[key] >>> 0).toString();// Will always results in 0 or 1 as string
                        patchData.append('with_sub_organisation', !!values[key] >>> 0);// Will always results in 0 or 1 as number
                        break;
                    default:
                        if(key.includes('i18n')){
                            if(typeof values[key] === 'object'){
                                formattedData.i18n = values[key];
                            }else{
                                patchData.append(key, values[key] || '');// Will always results in 0 or 1 as number
                            }
                        }
                        break;
                }
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${organizationId}` }), patchData)
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ ...prevState, ...formattedData }));
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Determines if the french language is activated for this organization
     * @returns {boolean}
     */
    hasFrench = () => this.state.settings?.languages?.fr === 'on';

    /**
     * Determines if the english language is activated for this organization
     * @returns {boolean}
     */
    hasEnglish = () => this.state.settings?.languages?.en === 'on';

    render(){
        return (
            <OrganizationContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </OrganizationContext.Provider>
        );
    }
}

export default OrganizationContextProvider;