import Translate from '@spordle/intl-elements';
import { Container } from 'reactstrap';
import ComingSoonImage from './assets/images/ComingSoon.png';
import { I18nProvider } from './i18n';
import { builtLocale } from './contexts/I18nContext';

/**
 * @type {import('@sentry/react/dist/errorboundary').FallbackRender}
 */
const FatalError = (props) => {
    // console.log(props);
    return (
        <I18nProvider locale={builtLocale(window.navigator.userLanguage?.toLowerCase() || window.navigator.language.toLowerCase())}>
            <Container className='my-5'>
                <div className='text-center h2'>
                    <Translate id='misc.error' />
                </div>
                <div className='text-center'>
                    <img src={ComingSoonImage} height='250' />
                </div>
                <div className='text-center'>
                    <a href={window.location.href} rel="noopener noreferrer"><Translate id='misc.fatalError.goback' /></a>
                </div>
            </Container>
        </I18nProvider>
    );
}

export default FatalError;