import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { object, string, mixed } from 'yup';
import StepFooter from "../../components/StepFooter";
import StepMatchInnerForm from "./components/StepMatchInnerForm";
import moment from "moment";
import { ModalBody } from "reactstrap";

const AddMemberGameIncidentStepMatch = ({ next, updateGirData, member, toggle }) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                class_id: '',
                division_id: '',
                game_incident_date: '',
                game_incident_memo: '',
                game_incident_time: '',
                game_number: '',
                league_name: '',
                location_name: '',
                period_id: '',
                team_category_id: '',
            }}
            validationSchema={object().shape({
                division_id: string().required(<Translate id='account.members.gir.addModal.validation.division' />),
                game_incident_date: mixed()
                    .test({
                        name: 'isRequired',
                        message: <Translate id='account.members.gir.addModal.validation.date' />,
                        test: function(date){
                            return !!date;
                        },
                    })
                    .test({
                        name: 'isValid',
                        message: <Translate id='account.members.gir.addModal.validation.date.valid' />,
                        test: function(date){
                            return moment.isMoment(date);
                        },
                    })
                    .test({
                        name: 'cannotBeFuture',
                        message: <Translate id='account.members.gir.addModal.validation.date.future' />,
                        test: function(date){
                            return moment.isMoment(date) && date.isSameOrBefore(moment())
                        },
                    }),
                team_category_id: string().required(<Translate id='account.members.gir.addModal.validation.category' />),
                period_id: string().required(<Translate id='account.members.gir.addModal.validation.season' />),

            })}
            onSubmit={(values) => {
                updateGirData(values);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <StepMatchInnerForm member={member} />
                    </ModalBody>
                    <StepFooter submitDisabled={!formik.values.team_category_id} previous={toggle} previousLabel="misc.cancel" />
                </Form>
            )}
        </Formik>
    )
}

export default AddMemberGameIncidentStepMatch;