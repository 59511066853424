import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import FadeIn from "../../../../components/animations/FadeIn";
import { MembersContext } from "../../../../contexts/MembersContext";
import QualificationCard from "./components/QualificationCard"

const AccountMemberQualifications = ({ member }) => {
    const { getMemberQualifications } = useContext(MembersContext);
    const [ qualifications, setQualifications ] = useState([]);
    const [ loadingState, setLoadingState ] = useState('loading');
    const location = useLocation();

    useEffect(() => {
        setLoadingState('loading');
        setQualifications([]);

        if(member?.members){
            Promise.all(
                member.members.map((memb) => getMemberQualifications(memb.member_id)),
            )
                .then((values) => {
                    const flatten = values.reduce((builtArr, value) => {
                        builtArr.pushArray(value);
                        return builtArr;
                    }, []);
                    setQualifications(flatten);
                    setLoadingState('success');
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error);
                        setLoadingState('error');
                    }
                })
        }

    }, [ member?.meta_member_id ]);

    const checkIfExpired = (date) => moment(date).isBefore(moment().add(1, 'day'));

    const sortQualifications = () => (
        qualifications.sort((a, b) => {
            const aExp = a.expiration_date;
            const bExp = b.expiration_date;
            let nb = 0;

            if(aExp && bExp){
                // Check if a and b are expired
                const aIsExpired = checkIfExpired(aExp);
                const bIsExpired = checkIfExpired(bExp);

                if(aIsExpired){
                    if(bIsExpired){ // if a & b are expired filter in expiration order
                        nb = aExp > bExp ? 1 : aExp < bExp ? -1 : 0;
                    }else{ // else move up
                        nb = 1;
                    }
                }else if(bIsExpired){ // if b is expired
                    nb = -1; // move down
                }else{ // if none are expired, filter in taken order
                    nb = a.taken_date > b.taken_date ? 1 : a.taken_date < b.taken_date ? -1 : 0;
                }
            }else if(bExp){ // else if there is a b expiration date
                nb = -1;
            }else if(aExp){ // else if there is an a expiration date
                nb = 1;
            }else{ // else Order in taken order
                nb = a.taken_date > b.taken_date ? 1 : a.taken_date < b.taken_date ? -1 : 0;
            }
            return nb;
        })
    )

    return (
        loadingState == 'success' ?
            qualifications.length > 0 ?
                sortQualifications().map((qualification, i) => (
                    <FadeIn
                        dependency={location.hash}
                        key={qualification.member_qualification_id}
                        distance={40}
                        direction="bottom"
                        delay={0.1 * i}
                    >
                        <QualificationCard qualification={qualification} />
                    </FadeIn>
                ))
                :
                <div>
                    <div className="h4 font-bold"><Translate id='account.members.qualifications.noQualifications.title' /></div>
                    <p><Translate id='account.members.qualifications.noQualifications.text' values={{ name: member.first_name + ' ' + member.last_name }} /></p>
                </div>
            : loadingState == 'loading' ?
                <Spinner type="grow" color="primary" />
                :
                <div>
                    <div className="h4 font-bold"><Translate id='account.members.loading.error.title' /></div>
                    <p><Translate id='account.members.loading.error.text' /></p>
                </div>

    )
}

export default AccountMemberQualifications;