import { Component } from 'react';
import { InputIntl } from "@spordle/intl-elements";
import { Button, Col, Fade, Row, Card } from 'reactstrap';
import axios from 'axios';

import SpordleTableProvider, { SpordleTableContext, SpordleTableView } from "@spordle/datatables";

import i18nFR from "../../i18n/messages/fr-CA";
import i18nEN from "../../i18n/messages/en-CA";
import { success } from '@spordle/toasts';

export default class I18nReview extends Component{
    constructor(props){
        super(props);

        this.project = "SPORDLE_ACCOUNT";
        this.myCorrections = [];
        this.columns = [
            {
                label: 'ID',
                key: "id",
                className: 'text-left',
                dataClassName: 'text-left',
                renderCustom: true,
                translate: false,
                mobile: true,
                sortable: false,
            },
            {
                label: 'Text',
                key: "text",
                className: 'text-left',
                dataClassName: 'text-left',
                renderCustom: true,
                translate: false,
                mobile: true,
                sortable: false,
            },
            {
                label: 'Correction En',
                key: "correction_en",
                className: 'text-left',
                dataClassName: 'text-left',
                renderCustom: true,
                mobile: true,
                sortable: false,
            },
            {
                label: 'Correction Fr',
                key: "correction_fr",
                className: 'text-left',
                dataClassName: 'text-left',
                renderCustom: true,
                mobile: true,
                sortable: false,
            },
            {
                label: 'Submit',
                key: "submit",
                className: 'text-right',
                dataClassName: 'text-right',
                renderCustom: true,
                mobile: true,
                sortable: false,
            },
        ];
    }

    getAPI = () => {
        const headers = [];
        //headers['Content-Type'] = 'application/json';
        return axios.create({
            baseURL: "https://app.eventnroll.com/fr/api-react-team/",
            headers: headers,
            validateStatus: (status) => true,
        });
    }

    sendCorrection = (lang_id) => {
        const react_api = this.getAPI();

        var form = new FormData();
        form.append('project', this.project);
        form.append('lang_id', lang_id);
        form.append('correction_fr', document.getElementById(`correction_${lang_id}_fr`).value);
        form.append('correction_en', document.getElementById(`correction_${lang_id}_en`).value);

        react_api.post("set_lang_correction", form)
            .then((response) => {
                success({ msg: 'misc.i18nReview.correctionLogged' });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    sendAccepted = (lang_id, correction_id) => {
        if(correction_id !== null){
            const react_api = this.getAPI();

            var form = new FormData();
            form.append('project', this.project);
            form.append('correction_id', correction_id);

            react_api.post("accept_lang_correction", form)
                .then((response) => {
                    success({ msg: 'misc.i18nReview.correctionAccepted' });
                })
                .catch((error) => {
                    console.error(error);
                });

            document.getElementById(`accept_${lang_id}`).classList = "d-none";
            document.getElementById(`correction_${lang_id}_fr`).value = "";
            document.getElementById(`correction_${lang_id}_en`).value = "";
        }
    }

    createRows = (frLangObject, enLangObject, corrections, showCorrectionsOnly) => {
        const elements = [];
        Object.keys(frLangObject).map((key) => {
            if(!showCorrectionsOnly || (showCorrectionsOnly && corrections[key] && (corrections[key].fr || corrections[key].en)))
                elements.push({ "id": key, "textFr": frLangObject[key], "textEn": enLangObject[key], "correction_en": corrections[key] ? corrections[key].en : "", "correction_fr": corrections[key] ? corrections[key].fr : "", "correction_id": corrections[key] ? corrections[key].id : "", "has_correction": !!(corrections[key] && corrections[key].fr + corrections[key].en !== "") });
        });

        return elements;
    }

    renderCustom = (key, line) => {
        switch (key){
            case "id":
                return (
                    <div style={{ maxWidth: "300px" }}>
                        <Row>
                            <Col md="12"><div className="font-bold mr-1 d-inline-block" style={{ userSelect: "none" }}>Page:</div>{line.id.substring(0, line.id.indexOf('.'))}</Col>
                        </Row>
                        <Row>
                            <Col md="12" className="small"><div className="font-bold mr-1 d-inline-block" style={{ userSelect: "none" }}>Where:</div>{line.id.substring(line.id.indexOf('.') + 1)}</Col>
                        </Row>
                        <Row>
                            <Col md="12" className="text-muted small"><div className="font-bold mr-1 d-inline-block" style={{ userSelect: "none" }}>ID:</div>{line.id}</Col>
                        </Row>
                    </div>
                )

            case "text":
                return (
                    <>
                        <Row>
                            <Col md="12"><div className="font-bold mr-1 d-inline-block" style={{ userSelect: "none" }}>En:</div>{line.textEn}</Col>
                        </Row>
                        <Row>
                            <Col md="12"><div className="font-bold mr-1 d-inline-block" style={{ userSelect: "none" }}>Fr:</div>{line.textFr}</Col>
                        </Row>
                    </>
                )

            case "correction_en":
                return (
                    <div style={{ minWidth: "140px" }}>
                        <Row>
                            <Col md="12"><textarea id={`correction_${line.id}_en`} className="w-100" placeholder="No corrections found" defaultValue={line.correction_en} /></Col>
                        </Row>
                    </div>
                )

            case "correction_fr":
                return (
                    <div style={{ minWidth: "140px" }}>
                        <Row>
                            <Col md="12"><textarea id={`correction_${line.id}_fr`} className="w-100" placeholder="No corrections found" defaultValue={line.correction_fr} /></Col>
                        </Row>
                    </div>
                )

            case "submit":
                return (
                    <div className="text-center">
                        <Button color="primary" className="d-block w-100" onClick={() => this.sendCorrection(line.id)}>Submit</Button>
                        {process.env.REACT_APP_ENVIRONMENT === 'int' && (line.correction_en || line.correction_fr) &&
                            <Button id={`accept_${line.id}`} color="success" className="d-block mt-2 w-100" onClick={() => this.sendAccepted(line.id, (line.correction_id) ? line.correction_id : null)}>Corrected!</Button>
                        }
                    </div>
                )


            default:
                break;
        }
    }


    render(){
        return (
            <div className="text-center mt-5 mb-3" style={{ width: '90%', margin: 'auto' }}>
                <header />
                <Fade>
                    <div className="h2">Text Review</div>
                    <Card body className="card-shadow">
                        <SpordleTableProvider
                            id='I18nReviewTable'
                            tableHover stickyHeader bordered
                            striped
                            columns={this.columns}
                            desktopWhen='md'
                            pagination={50}
                            paginationMessage='misc.templateUi.basicSpordleTable'
                            renderRow={this.renderCustom}
                            emptyLayout={
                                <>
                                    <h2 className="h6 font-bold">{"Aucune données disponibles"}</h2>
                                    <p className="mb-0" />
                                </>
                            }
                            loadData={(from, filterData) => {
                                switch (from){
                                    case 'CDM':
                                        const react_api = this.getAPI();

                                        var form = new FormData();
                                        form.append('project', this.project);

                                        return react_api.post("get_lang_corrections", form)
                                            .then((corrections) => {
                                                const data = corrections.data;
                                                if(data.success){
                                                    this.myCorrections = data.corrections;
                                                    var yo = this.createRows(i18nFR['fr-ca'], i18nEN['en-ca'], this.myCorrections, false);
                                                    return yo;
                                                }
                                            })
                                            .catch((error) => {
                                                console.error(error);
                                            });
                                    case 'FILTER':
                                        return new Promise((resolve) => {
                                            resolve(this.createRows(i18nFR['fr-ca'], i18nEN['en-ca'], this.myCorrections, !!filterData.filters.showCorrectionsOnly));
                                        });
                                    default:
                                        break;
                                }
                            }}
                            initFilters={{
                                showCorrectionsOnly: false,
                            }}
                        >
                            <TopSection />
                            <SpordleTableView />
                        </SpordleTableProvider>
                    </Card>
                </Fade>
            </div>
        )
    }
}

const TopSection = (props) => {
    return (
        <SpordleTableContext.Consumer>
            {(spordleTable) => (
                <>
                    <div className="text-left d-flex align-center">
                        <label htmlFor="switch" className="switch mb-3 mr-1">
                            <input name="switch" id="switch" type="checkbox" value="false" onChange={(e) => spordleTable.filterChange('showCorrectionsOnly', e.target.checked)} />
                            <span className="switch-toggler mr-2" />
                        </label>
                        <label>Only show corrections</label>
                    </div>
                    <InputIntl className="mb-3" onChange={spordleTable.searchInputChange} type='search' placeholder='misc.SimpleSearchTable.search.placeholder' />
                </>
            )}
        </SpordleTableContext.Consumer>
    );
}