import Translate from "@spordle/intl-elements";
import ConfirmModal from "../../confirmModal/ConfirmModal";
import SidePanelMenuAction from "./SidePanelMenuAction";
import PropTypes from 'prop-types';

/**
 * Component used for deletion in side panel actions menu
 * @param {Object} props
 * @param {boolean} [props.disabled=false]
 * @param {string} [props.label="misc.delete"]
 * @param {boolean} [props.skipLabelTranslate=false]
 * @returns {JSX.Element}
 * @example
 * <SidePanel.ActionsMenu>
 *      <SidePanelMenuDelete
 *          translateModalTitle
 *          modalTitle='misc.title'
 *          modalMsg={<div>Message</div>}
 *          onConfirm={handleDelete}
 *      />
 * </SidePanel.ActionsMenu>
 */
const SidePanelMenuDelete = ({
    disabled = false,
    label = "misc.delete",
    skipLabelTranslate = false,
    ...props
}) => {
    return (
        <ConfirmModal
            {...props}
            toggler={(fn) => (
                <SidePanelMenuAction
                    disabled={disabled || false}
                    onClick={fn}
                >
                    {skipLabelTranslate ? { label } : <Translate id={label} />}
                </SidePanelMenuAction>
            )}
        />
    )
};

export default SidePanelMenuDelete;

SidePanelMenuDelete.propTypes = {
    action: PropTypes.string,
    componentCode: PropTypes.string,
    componentPermissionCode: PropTypes.string,
    label: PropTypes.string,
    skipTranslateLabel: PropTypes.bool,
    disabled: PropTypes.bool,
}

SidePanelMenuDelete.defaultProps = {
    skipTranslateLabel: false,
    label: 'misc.delete',
    disabled: false,
}