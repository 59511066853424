import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { AccountsContext, AuthContext } from "../../../../contexts/contexts";
import RegistrationCard from "./components/RegistrationCard";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const AccountMemberRegistrations = ({ member }) => {
    const [ invoices, setInvoices ] = useState(null);
    const { getAccountInvoices } = useContext(AccountsContext);
    const { userData } = useContext(AuthContext);

    useEffect(() => {
        getAccountInvoices(userData.Username)
            .then(setInvoices)
            .catch((err) => {
                if(!AxiosIsCancelled(err.message)){
                    console.error(err);
                }
            })
    }, []);

    // Filters on invoices and returns items that corresponds to
    // temp to cleanup
    const filterMetaMemberRegistration = () => {
        const all = invoices?.reduce((arr, invoice) => {
            invoice.invoice_items.forEach((item) => {
                if(member.members?.some((m) => m?.member_id == item.member?.member_id && !!item.registration_fee)){
                    arr.push({ ...item, period: invoice.period, invoiceNb: invoice.invoice_number, type: invoice.type })
                }
            });
            return arr;
        }, []);

        if(all?.length > 0){
            return (
                <VerticalTimeline
                    layout='1-column-left'
                >
                    {all.sort((a, b) => {
                        if(a.created_at > b.created_at){
                            return -1;
                        }else if(a.created_at < b.created_at){
                            return 1;
                        }
                        return 0;
                    })
                        .map((registration, i) => (
                            <VerticalTimelineElement
                                className="mb-3 mt-0"
                                dateClassName="d-none"
                                icon={
                                    <div
                                        style={{ fontSize: 20 }}
                                        className="position-absolute top-0 bottom-0 right-0 left-0 d-flex align-items-center justify-content-center fas fa-hockey-puck text-primary"
                                    />
                                }
                                // eslint-disable-next-line react/no-array-index-key
                                key={registration.invoice_item_id + i + registration.type}
                                sm="6"
                            >
                                <RegistrationCard item={registration} />
                            </VerticalTimelineElement>
                        ))}
                    <VerticalTimelineElement
                        dateClassName="d-none"
                        iconClassName="bg-primary"
                        icon={<div style={{ fontSize: 20 }} className="position-absolute top-0 bottom-0 right-0 left-0 d-flex align-items-center justify-content-center mdi mdi-star text-white" />}
                    >
                        <span className="text-muted small text-uppercase"><DateFormat value={member.created_at} utc /></span>
                        <div className="h4 font-bold mb-0 text-primary"><Translate id='account.members.registration.memberCreated' /></div>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            )
        }
        return (
            <Col sm="6">
                <div>
                    <div className="h4 font-bold"><Translate id='account.members.registration.noRegistration.title' /></div>
                    <p><Translate id='account.members.registration.noRegistration.text' values={{ name: member.first_name + ' ' + member.last_name }} /></p>
                </div>
            </Col>
        )


    }

    return (
        <Row>
            {filterMetaMemberRegistration()}
        </Row>
    )
}

export default AccountMemberRegistrations;