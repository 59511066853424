export default{
    "confirm-account.title" : "Please enter the verification code sent to",

    // Action
    "confirm-account.action.verify" : "Verify",

    // Loading
    "confirm-account.loading.title"       : "Confirming",
    "confirm-account.loading.description" : "You will be redirected shortly ...",

    // Success
    "confirm-account.success.title" : "Account confirmed",

    // Failed
    "confirm-account.failed.title"                 : "Oops, an error has occurred!",
    "confirm-account.failed.action"                : "Back to Home",
    "confirm-account.error.resendEmail"            : "Oops, could not send email!",
    "confirm-account.error.CodeMismatchException"  : "The verification code is invalid. Please try again.",
    "confirm-account.error.ExpiredCodeException"   : "The verification code is expired. Please try again.",
    "confirm-account.error.LimitExceededException" : "The try limit has been exceeded. Please try again later.",
    "confirm-account.error.server"                 : "There was a problem. Please contact technical support.",
    "confirm-account.error.204"                    : "There was a problem. Please contact technical support.",
    "confirm-account.error.2000"                   : "There was a problem. Please contact technical support.",
    "confirm-account.error.AliasExistsException"   : "An account with the given email already exists.",
}