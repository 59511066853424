import queryString, { stringifyUrl } from 'query-string';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<AccountsStatusContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & AccountsStatusContextProvider['state']>} */
export const AccountsStatusContext = createContext();
AccountsStatusContext.displayName = 'AccountsStatusContext';

class AccountsStatusContextProvider extends React.Component{

    state = {
        declinedPayments: null,
        preInits: null,
        unsignedWaivers: null,
        waitingListItems: null,
        unansweredForms: null,
    }

    showPopups = true;

    changeShowPopups = (show) => {
        this.showPopups = show;
    }

    /**
     * Gets the failed payment status
     * @param {string} identityId ID of the identity to check for declined installments
     * @returns {Promise}
     */
    getFailedPaymentStatus = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/declined-installments`,
        }))
            .then(async(response) => {
                if(response.data.status){
                    if(response.data.declined_installments?.length > 0){
                        this.setState(() => ({
                            declinedPayments: response.data.declined_installments,
                        }))
                    }
                    return response.data.declined_installments
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the preinit status
     * @param {string} identityId ID of the identity to check for preinit
     * @returns {Promise}
     */
    getPreInitStatus = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/prepared-payments`,
        }))
            .then(async(response) => {
                if(response.data.status){
                    if(response.data.invoices?.length > 0){
                        this.setState(() => ({
                            preInits: response.data.invoices,
                        }))
                    }
                    return response.data.invoices
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the unsigned waivers status
     * @param {string} identityId ID of the identity to check for unsigned waivers
     * @returns {Promise}
     */
    getUnsignedWaiverStatus = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/unsigned-waivers`,
        }))
            .then(async(response) => {
                if(response.data.status){
                    if(response.data.waivers?.length > 0){
                        this.setState(() => ({
                            unsignedWaivers: response.data.waivers,
                        }))
                    }
                    return response.data.waivers
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the waiting list notifications
     * @param {string} identityId ID of the identity to check for waiting list notifications
     * @returns {Promise}
     */
    getWaitingListNotifications = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/waiting-list-items`,
        }))
            .then(async(response) => {
                if(response.data.status){
                    if(response.data.waiting_list_items?.length > 0){
                        this.setState(() => ({
                            waitingListItems: response.data.waiting_list_items,
                        }))
                    }
                    return response.data.waiting_list_items
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the waiting list notifications
     * @param {string} identityId ID of the identity to check for waiting list notifications
     * @returns {Promise}
     */
    getUnansweredForms = (identityId, queryParams) => {
        return API_SPORDLE.get(stringifyUrl({
            url: `/accounts/${identityId}/unanswered-forms`,
            query: queryParams,
        }, {
            skipNull: true,
            skipEmptyString: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    if(response.data.unanswered_forms?.length > 0){
                        this.setState(() => ({
                            unansweredForms: response.data.unanswered_forms,
                        }))
                    }
                    return response.data.unanswered_forms;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <AccountsStatusContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </AccountsStatusContext.Provider>
        )
    }
}

export default AccountsStatusContextProvider