export default{
    "account.settings.2fa.update.2fa.success" : "2-Step Verification preferences updated!",
    "account.settings.2fa.update.2fa.fail"    : "2-Step Verification preferences could not be updated",

    "account.settings.2fa.title"          : "2-Step Verification",
    "account.settings.2fa.subtitle.text0" : "Reach a higher level of security!",
    "account.settings.2fa.subtitle.text1" : "Activate the 2-Step Verification to add an extra layer of security to protect your account",
    "account.settings.2fa.subtitle.text2" : "Every time you login to your account, you will be asked to confirm your login by SMS.",

    "account.settings.2fa.yes"     : "Yes",
    "account.settings.2fa.no"      : "No",
    "account.settings.2fa.confirm" : "Confirm",
    "account.settings.2fa.next"    : "Next",

    "account.settings.2fa.sms"                   : "Mobile Phone",
    "account.settings.2fa.sms.active"            : "2-Step Verification is activated!",
    "account.settings.2fa.sms.inactive"          : "2-Step Verification is not activated",
    "account.settings.2fa.sms.needPhone"         : "You need to have a verified phone number to activate this option",
    "account.settings.2fa.sms.preferedMethod"    : "Preferred 2-Step Verification method",
    "account.settings.2fa.sms.activate"          : "Are you sure you want to activate 2-Step Verification by SMS",
    "account.settings.2fa.sms.activate.button"   : "Activate",
    "account.settings.2fa.sms.activated"         : "2-Step Verification by SMS activated",
    "account.settings.2fa.sms.deactivate"        : "Are you sure you want to deactivate 2-Step Verification by SMS",
    "account.settings.2fa.sms.deactivate.button" : "Deactivate",
    "account.settings.2fa.sms.deactivated"       : "2-Step Verification by SMS deactivated",
    "account.settings.2fa.sms.error"             : "An error occurred, please refresh the page",
    "account.settings.2fa.sms.errorUpdate"       : "The 2-Step Verification could not be updated",

    "account.settings.2fa.software"                   : "Software",
    "account.settings.2fa.software.active"            : "2-Step Verification by software is activated!",
    "account.settings.2fa.software.inactive"          : "2-Step Verification by software is not activated",
    "account.settings.2fa.software.preferedMethod"    : "Preferred 2-Step Verification method",
    "account.settings.2fa.software.anyApp"            : "Use any 2-Step Verification app",
    "account.settings.2fa.software.googleApp"         : "We recommend using the Google Authentication app",
    "account.settings.2fa.software.key"               : "Configuration key",
    "account.settings.2fa.software.key.message"       : "Enter the configuration key or scan the QR code in your selected Authentication app if you want to enable or re-enable 2-step verification via software.",
    "account.settings.2fa.software.copy"              : "Copy",
    "account.settings.2fa.software.copied"            : "Copied",
    "account.settings.2fa.software.or"                : "OR",
    "account.settings.2fa.software.qr"                : "QR Code",
    "account.settings.2fa.software.confirm"           : "Please confirm",
    "account.settings.2fa.software.activated"         : "2-Step Verification by software activated!",
    "account.settings.2fa.software.deactivated"       : "2-Step Verification by software deactivated",
    "account.settings.2fa.software.error"             : "Error: {error}",
    "account.settings.2fa.software.error.noMatch"     : "Code does not match, please try again",
    "account.settings.2fa.software.errorUpdate"       : "2-Step Verification by software could not be updated",
    "account.settings.2fa.software.remove"            : "Are you sure you want to remove 2-Step Verification by software?",
    "account.settings.2fa.software.activate.button"   : "Activate",
    "account.settings.2fa.software.deactivate.button" : "Deactivate",
}