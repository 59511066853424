import { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import useIsVisible from "./useIsVisible";

/**
 * @description Handles the show tooltip logic when custom show and hide and needed
 * @param {string} tooltip Tooltip to show
 * @param {string} tip tip
 * @returns {{ref: import("react").MutableRefObject, onMouseEnter: function, onMouseLeave: function}} If the given html element is visible in the DOM
 * @hook
 */
export function useTooltip(tooltip, tip){
    const tooltipRef = useRef(null);
    const isVisible = useIsVisible(tooltipRef.current);

    const showTooltip = () => {
        if(tooltipRef.current){
            ReactTooltip.show(tooltipRef.current);
        }
    }

    const hideTooltip = () => {
        if(tooltipRef.current){
            ReactTooltip.hide(tooltipRef.current);
        }
    }

    useEffect(() => {
        if(!isVisible){
            hideTooltip();
        }
        return hideTooltip;
    }, [ isVisible ])

    return { ref: tooltipRef, "data-for": tooltip, "data-tip": tip, onMouseEnter: showTooltip, onMouseLeave: hideTooltip };
}