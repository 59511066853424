import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Fade, Spinner } from 'reactstrap';
import setDefaultComponentProps from '../helpers/setDefaultComponentProps';
import AuthRoutes from './routeBundles/BundledAuthRoutePicker';

const DynamicRoute = ({ component: Component, ...rest }) => {
    setDefaultComponentProps()
    return (
        <Route
            {...rest} render={(props) => (
                <Suspense fallback={
                    <div className="auth-wrapper">
                        <Fade className='mx-auto'>
                            <Spinner color="primary" type="grow" />
                        </Fade>
                    </div>
                }
                >
                    <AuthRoutes {...props} rest={rest} />
                </Suspense>
            )}
        />
    );
}

export default DynamicRoute;