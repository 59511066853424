import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';

/**
 * Component that show's an image based on a given position (simulates crop)
 * Based on the component UserImg, to be used when image has been uploaded with a cropper
 *
 * @param {string} [abbr] [default = 'HC'] Letters to show if no image
 * @param {string} alt Alt attribute of the image
 * @param {string} [className]
 * @param {string} [container] [container=true] Adds a frame to the img (border + padding)
 * @param {string} [filePos] JSON string of the image's position
 * @param {string|number} [height = width] Height of the circle. If not specified, will be the same as width
 * @param {string} [src] Path of the image
 * @param {string|number} width [default = 40] Width of the circle
 * @returns {React.ReactNode}
 */
const UserImg = ({
    abbr = 'HC',
    alt,
    className,
    container = true,
    filePos,
    height,
    placeholder,
    src,
    width = 60,
}) => {
    let pos = null;
    try{
        if(filePos)
            pos = JSON.parse(filePos);
    }catch(error){
        console.error(error);
    }
    const rDelta = pos?.cropSize?.width && parseInt(width) / parseFloat(pos.cropSize?.width);
    const styles = pos && rDelta ? { width: pos.width, height: pos.height, transform: `translate(${parseFloat(pos.x) * (rDelta || 1)}px, ${parseFloat(pos.y) * (rDelta || 1)}px) scale(${parseFloat(pos.zoom) * (rDelta || 1)})` } : { maxHeight: '100%', maxWidth: '100%' }

    return (
        (src || placeholder ?
            <div className={stringBuilder('userImg-container d-flex align-items-center justify-content-center', (container ? className : ''))} style={{ width: width + 'px', height: (width || height) + 'px' }}>
                <img style={styles} src={src || placeholder} alt={alt} />
            </div>
            :
            <div className={stringBuilder('userImg-abbr', className)} style={{ width: width + 'px', height: (width || height) + 'px' }}>
                <div className="text-center" style={{ width: width + 'px', lineHeight: (height || width) + 'px', fontSize: Math.round(parseInt(height || width) / 3) + 'px' }}>
                    {abbr.substring(0, 2)}
                </div>
            </div>
        )
    )
}

export default UserImg;

UserImg.propTypes = {
    abbr: PropTypes.string,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    container: PropTypes.bool,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    filePos: PropTypes.string,
    src: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

UserImg.defaultProps = {
    abbr: 'HC',
    container: true,
    width: 60,
};