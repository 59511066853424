export default{
    "sidebar.dashboard"      : "Dashboard",
    "sidebar.members"        : "Members",
    "sidebar.calendar"       : "Calendar",
    "sidebar.purchases"      : "Purchases",
    "sidebar.payments"       : "Payments",
    "sidebar.communications" : "Communications",
    "sidebar.documents"      : "Documents",
    "sidebar.settings"       : "Settings",
    "sidebar.api"            : "API",

    "sidebar.terms" : "Terms & conditions",
    "sidebar.about" : "About us",
}