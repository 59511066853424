export default{
    // tabs
    "account.members.ineligibleWarning"           : "Ce profil a été gelé par les administrateurs HCR.",
    "account.members.ineligibleWarning.potential" : "Ce profil a été signalé par les administrateurs HCR.",
    "account.members.ineligibleWarning.txt"       : "Veuillez contacter le membre provincial de Hockey Canada pour le service à la clientèle.",
    "account.members.tabs.general"                : "Général",
    "account.members.tabs.schedule"               : "Calendrier",
    "account.members.tabs.memberCards"            : "Inscriptions",
    "account.members.tabs.certificates"           : "Qualifications",
    "account.members.tabs.waivers"                : "Dispenses",
    "account.members.tabs.suspensions"            : "Suspensions",
    "account.members.tabs.crc"                    : "Vérification d'antécédents",
    "account.members.tabs.gameincident"           : "Rapports d'incident",
    "account.members.tabs.documents"              : "Documents",
    "account.members.tabs.coach"                  : "Officiel",
    "account.members.tabs.travelPermits"          : "Permis de voyage",
    "account.members.tabs.contacts"               : "Contacts",

    "account.members.gameIncidentReport.title"  : "Rapports d'incident",
    "account.members.schedule.title"            : "Calendrier",
    "account.members.personalInfo.title"        : "Informations personnelles",
    "account.members.address.title"             : "Adresse",
    "account.members.otherInfo.title"           : "Autres Informations",
    "account.members.medicalInfo.title"         : "Informations Médicales",
    "account.members.crc.title"                 : "Vérification d'antécédents",
    "account.members.linked.accounts"           : "Comptes liés à ce membre",
    "account.members.linked.accounts.personnel" : "Personnel de Hockey Canada",
    "account.members.linked.accounts.other"     : "Comptes Spordle",
    "account.members.linked.accounts.no.linked" : "Aucun comptes lié",
    "account.members.travelPermits.title"       : "Permis de voyage",


    "account.members.addMember.tooltip" : "Ajouter un membre",

    // Change single member address modal
    "account.members.header.changeAddress"                      : "Demander un changement d'adresse",
    "account.members.changeSingleAddress.modal.success"         : "La nouvelle adresse a été publiée dans le profil d'enregistrement du membre.",
    "account.members.changeSingleAddress.modal.success.subText" : "Veuillez prévoir 2-3 jours ouvrables pour que les modifications soient appliquées.",
    "account.members.changeSingleAddress.modal.success.title"   : "Adresse publiée avec succès",

    // member status badges
    "account.members.unsignedCriticalWaiver.tooltip" : "Dispense obligatoire à signer",
    "account.members.missingFields.tooltip"          : "Champs requis manquants",
    "account.members.missingFields.organization"     : "Votre organisation exige ces champs:",
    "account.members.unconfirmed.address.tooltip"    : "Nouvelle adresse à confirmer",
    "account.members.incomplete_profile.tooltip"     : "Profil de membre incomplet",
    "account.members.missingAddressDocument.tooltip" : "Documents manquants",

    // missing fields modal
    "account.members.missingFieldsModal.title"    : "Informations manquantes",
    "account.members.missingFieldsModal.text"     : "Le système a besoin de ces informations pour ce membre.",
    "account.members.missingFieldsModal.text2"    : "Veuillez remplir les champs ci-dessous.",
    "account.members.missingFieldsModal.required" : "Ce champ est requis",

    // Img uploads
    "account.members.img.dropdown.delete"   : "Supprimer l'image",
    "account.members.img.dropdown.upload"   : "Téléverser une image",
    "account.members.img.deleteModal.title" : "Supprimer l'image de profil",
    "account.members.img.deleteModal.msg"   : "Vous êtes sur le point de supprimer l'image de profil de {name}",

    // Global
    "account.members.loading.error.title" : "Oops !",
    "account.members.loading.error.text"  : "Il y a eu un problème. Veuillez revenir plus tard ou contactez notre équipe de support.",

    // Registrations
    "account.members.registration.noRegistration.title" : "Aucune inscription",
    "account.members.registration.noRegistration.text"  : "Aucune inscription n'a été trouvée pour {name}.",
    "account.members.registration.memberCreated"        : "Création du membre !",

    // Qualifications
    "account.members.qualifications.certification.number"          : "N{number} de certification: ",
    "account.members.qualifications.noQualifications.title"        : "Aucune qualification",
    "account.members.qualifications.noQualifications.text"         : "Aucune qualification n'a été trouvée pour {name}.",
    "account.members.qualifications.qualificationCard.season"      : "Saison",
    "account.members.qualifications.qualificationCard.validFromTo" : "Valide du {from} au {to}",
    "account.members.qualifications.qualificationCard.validSince"  : "Valide depuis {date}",
    "account.members.qualifications.qualificationCard.willBeValid" : "Sera valide le {date}",

    // CRC
    "account.members.crc.column.createdBy.label"       : "Créé par",
    "account.members.crc.column.createdBy.on"          : "Le",
    "account.members.crc.column.createdBy.editedOn"    : "Modifié le",
    "account.members.crc.column.crc_type.label"        : "Type",
    "account.members.crc.column.issue_date.label"      : "Date de validité",
    "account.members.crc.column.expiration_date.label" : "Expire le",
    "account.members.crc.column.status.label"          : "Statut",
    "account.members.crc.empty"                        : "Aucune vérification vérification d'antécédents trouvée",
    "account.members.crc.status.expired"               : "Expiré",

    // TRAVEL PERMITS
    "account.members.travelPermits.travelId"                            : "Numéro de réference",
    "account.members.travelPermits.fee"                                 : "Frais de permis de voyage",
    "account.members.travelPermits.print"                               : "Imprimer (PDF)",
    "account.members.travelPermits.modalTitle"                          : "Ajouter un permis de voyage",
    "account.members.travelPermits.column.team"                         : "Équipe",
    "account.members.travelPermits.column.event"                        : "Événement",
    "account.members.travelPermits.column.location"                     : "Emplacement",
    "account.members.travelPermits.column.startDate"                    : "Date de début",
    "account.members.travelPermits.column.endDate"                      : "Date de fin",
    "account.members.travelPermits.column.status"                       : "Statut",
    "account.members.travelPermits.addModal.teamLabel"                  : "Équipe",
    "account.members.travelPermits.addModal.titleLabel"                 : "Titre",
    "account.members.travelPermits.addModal.titleLabel.tip"             : "Veuillez entrer le nom de l'événement ou une description de celui-ci",
    "account.members.travelPermits.addModal.eventTypeLabel"             : "Type d'événement",
    "account.members.travelPermits.addModal.travelIdLabel"              : "Type de déplacement",
    "account.members.travelPermits.addModal.hostWebsiteLabel"           : "Site Web hôte",
    "account.members.travelPermits.addModal.hostNameLabel"              : "Nom de l'équipe hôte",
    "account.members.travelPermits.addModal.nbGamesLabel"               : "Nombre de parties",
    "account.members.travelPermits.addModal.hostDivision"               : "Division hôte",
    "account.members.travelPermits.addModal.hostClass"                  : "Classe d'accueil",
    "account.members.travelPermits.addModal.hostCategory"               : "Catégorie hôte",
    "account.members.travelPermits.addModal.hostCountry"                : "Pays d'accueil",
    "account.members.travelPermits.addModal.hostProvinceState"          : "Province / État d'accueil",
    "account.members.travelPermits.addModal.hostCity"                   : "Ville hôte",
    "account.members.travelPermits.addModal.hostOrganization"           : "Organisme d'accueil",
    "account.members.travelPermits.addModal.hostArena"                  : "Arena hôte",
    "account.members.travelPermits.addModal.teamRequired"               : "L'équipe est requise.",
    "account.members.travelPermits.addModal.titleRequired"              : "Titre est requis.",
    "account.members.travelPermits.addModal.eventTypeRequired"          : "Type d'événement est requis.",
    "account.members.travelPermits.addModal.travelIdRequired"           : "L'identifiant de voyage est requis.",
    "account.members.travelPermits.addModal.hostNameRequired"           : "L'arène hôte est requise.",
    "account.members.travelPermits.addModal.hostCountryRequired"        : "Le pays hôte est requis.",
    "account.members.travelPermits.addModal.hostProvinceRequired"       : "La province hôte est requise.",
    "account.members.travelPermits.addModal.hostCityRequired"           : "La ville hôte est requise.",
    "account.members.travelPermits.addModal.hostDivisionRequired"       : "La divison hôte est requise",
    "account.members.travelPermits.addModal.startDate"                  : "Date de début",
    "account.members.travelPermits.addModal.endDate"                    : "Date de fin",
    "account.members.travelPermits.addModal.notes"                      : "Remarques",
    "account.members.travelPermits.addModal.attachments"                : "Pièces jointes",
    "account.members.travelPermits.addModal.additionalAttachments"      : "Pièces jointes additionnels",
    "account.members.travelPermits.addModal.startDateRequired"          : "La date de début est requise.",
    "account.members.travelPermits.addModal.endDateRequired"            : "La date de fin est requise.",
    "account.members.travelPermits.panelHeader.travelId"                : "Id de déplacement",
    "account.members.travelPermits.sidePanelGeneral.tabTitle"           : "Général",
    "account.members.travelPermits.sidePanelGeneral.HCRNumber"          : "Numéro HCR d'équipe",
    "account.members.travelPermits.sidePanelGeneral.status"             : "Statut",
    "account.members.travelPermits.sidePanelGeneral.createdBy"          : "Créé par",
    "account.members.travelPermits.sidePanelGeneral.location"           : "Emplacement",
    "account.members.travelPermits.sidePanelGeneral.arena"              : "Aréna",
    "account.members.travelPermits.sidePanelGeneral.city"               : "Ville",
    "account.members.travelPermits.sidePanelGeneral.countryProvince"    : "Pays et Province/État",
    "account.members.travelPermits.sidePanelGeneral.dates"              : "Dates",
    "account.members.travelPermits.sidePanelGeneral.startDate"          : "Date de début",
    "account.members.travelPermits.sidePanelGeneral.startDate.format"   : "La date de début doit être d'un format valide",
    "account.members.travelPermits.sidePanelGeneral.startDate.required" : "La date de début est requise",
    "account.members.travelPermits.sidePanelGeneral.startDate.before"   : "La date de début doit être avant la date de fin",
    "account.members.travelPermits.sidePanelGeneral.endDate"            : "Date de fin",
    "account.members.travelPermits.sidePanelGeneral.endDate.format"     : "La date de fin doit être d'un format valide",
    "account.members.travelPermits.sidePanelGeneral.endDate.required"   : "La date de fin est requise",
    "account.members.travelPermits.sidePanelGeneral.endDate.after"      : "La date de fin doit être après la date de début",
    "account.members.travelPermits.sidePanelGeneral.note"               : "Note",
    "account.members.travelPermits.sidePanelGeneral.noOptions"          : "Aucune option",
    "account.members.travelPermits.sidePanelGeneral.attachments"        : "Pièces jointes",
    "account.members.travelPermits.sidePanelHost.tabTitle"              : "Hôte",
    "account.members.travelPermits.sidePanelHost.name"                  : "Nom",
    "account.members.travelPermits.sidePanelHost.travelType"            : "Type de déplacement",
    "account.members.travelPermits.sidePanelHost.eventType"             : "Type d'événement",
    "account.members.travelPermits.sidePanelHost.division"              : "Division",
    "account.members.travelPermits.sidePanelHost.category"              : "Catégorie",
    "account.members.travelPermits.sidePanelHost.class"                 : "Classe",
    "account.members.travelPermits.sidePanelHost.website"               : "Site Internet",
    "account.members.travelPermits.sidePanelHost.hostTeamName"          : "Nom de l'équipe hôte",
    "account.members.travelPermits.sidePanelHost.nbGames"               : "Nombre de parties",
    "account.members.travelPermits.toast.success"                       : "Permis de voyage mis à jour.",
    "account.members.travelPermits.toast.fail"                          : "Une erreur s'est produite lors du permis de voyage mis à jour, veuillez actualiser la page et essayez à nouveau.",
    "account.members.travelPermits.deleteModal.message"                 : "Vous êtes sur le point de supprimer ce permis de voyage.",
    "account.members.travelPermits.submit.message"                      : "Vous êtes sur le point de soumettre ce permis de voyage.",
    "account.members.travelPermits.submit"                              : "Soumettre",
    "account.members.travelPermits.addModal.submit"                     : "Soumettre le permis",
    "account.members.travelPermits.addModal.submit.subtitle"            : "Ce permis sera disponible pour l'approbation",
    "account.members.travelPermits.addModal.notSubmit"                  : "Soumettre plus tard",
    "account.members.travelPermits.addModal.notSubmit.subtitle"         : "Vous pouvez apporter des changements et soumettre le permis plus tard",

    // GIR
    "account.members.gir.title"                     : "Rapport d'incident",
    "account.members.gir.search.alreadyAdded"       : "Déjà ajouté",
    "account.members.gir.sidePanel.delete.incident" : "Vous êtes sur le point de supprimer cet incident de joueur",
    "account.members.gir.sidePanel.delete.official" : "Vous êtes sur le point de supprimer cet officiel du rapport d'incident",
    "account.members.gir.sidePanel.submit.btn"      : "Soumettre le rapport",
    "account.members.gir.sidePanel.submit.msg"      : "Vous êtes sur le point de soumettre le rapport d'incident",
    "account.members.gir.sidePanel.resubmit.btn"    : "Re-soumettre le rapport",
    "account.members.gir.sidePanel.resubmit.msg"    : "Vous êtes sur le point de soumettre le rapport d'incident à nouveau",
    "account.members.gir.sidePanel.lastUpdated"     : "Ce rapport a été mis à jour le {date}",

    "account.members.gir.sidePanel.export.pdf"              : "Exporter en PDF",
    "account.members.gir.sidePanel.export.error.3382"       : "Une erreur s'est produite pendant la génération du PDF",
    "account.members.gir.sidePanel.export.toast.info"       : "Si votre pdf n'a pas téléchargé automatiquement, essayez en <a>cliquant ici.</a>",
    "account.members.gir.sidePanel.export.toast.generating" : "Génère le pdf...",
    "account.members.gir.sidePanel.export.toast.download"   : "Téchargement !",

    "account.members.gir.addModal.title"                       : "Ajouter rapport d'incident (Étape {step} de {stepNb})",
    "account.members.gir.addModal.steps.officials.title"       : "Officiels",
    "account.members.gir.addModal.steps.officials.add.title"   : "Ajouter un officiel",
    "account.members.gir.addModal.steps.teams.title"           : "Équipes",
    "account.members.gir.addModal.steps.teams.other"           : "Autre",
    "account.members.gir.addModal.steps.teams.existing"        : "Existante",
    "account.members.gir.addModal.steps.match.title"           : "Info de la partie",
    "account.members.gir.addModal.steps.incident.title"        : "Incident",
    "account.members.gir.addModal.steps.status.title"          : "Souhaitez-vous soumettre le rapport d'incident maintenant ?",
    "account.members.gir.addModal.steps.status.btn.yes"        : "Oui",
    "account.members.gir.addModal.steps.status.btn.yes.helper" : "Je souhaite soumettre le rapport",
    "account.members.gir.addModal.steps.status.btn.no"         : "Non",
    "account.members.gir.addModal.steps.status.btn.no.helper"  : "Je ne veux pas soumettre le rapport",

    "account.members.gir.addModal.label.branch"               : "Branche",
    "account.members.gir.addModal.label.season"               : "Saison",
    "account.members.gir.addModal.label.class"                : "Classe",
    "account.members.gir.addModal.label.division"             : "Division",
    "account.members.gir.addModal.label.org"                  : "Organisation",
    "account.members.gir.addModal.label.category"             : "Catégorie",
    "account.members.gir.addModal.label.league"               : "Ligue",
    "account.members.gir.addModal.label.location"             : "Location",
    "account.members.gir.addModal.label.game"                 : "Partie #",
    "account.members.gir.addModal.label.coach"                : "Entraîneur",
    "account.members.gir.addModal.label.coachList"            : "Liste des entraîneurs",
    "account.members.gir.addModal.label.coach.option.other"   : "Entraîneur non-listé",
    "account.members.gir.addModal.label.coach.other"          : "Nom entraîneur",
    "account.members.gir.addModal.label.manager"              : "Gérant",
    "account.members.gir.addModal.label.managerList"          : "Liste du personnel",
    "account.members.gir.addModal.label.manager.option.other" : "Gérant non listé",
    "account.members.gir.addModal.label.manager.other"        : "Nom gérant",
    "account.members.gir.addModal.label.team"                 : "Équipe",
    "account.members.gir.addModal.label.officials"            : "Officiels",
    "account.members.gir.addModal.label.incident"             : "Incident",
    "account.members.gir.addModal.label.player"               : "Joueur",
    "account.members.gir.addModal.label.period"               : "Période",
    "account.members.gir.addModal.label.time"                 : "Temps",
    "account.members.gir.addModal.label.minutes"              : "Minutes",
    "account.members.gir.addModal.label.seconds"              : "Secondes",
    "account.members.gir.addModal.label.infractionType"       : "Type de pénalité",
    "account.members.gir.addModal.label.infraction"           : "Pénalité",
    "account.members.gir.addModal.label.injury"               : "A eu une blessure",
    "account.members.gir.addModal.label.returned"             : "Retourné au jeu",
    "account.members.gir.addModal.label.notReturned"          : "N'est pas retourné au jeu",
    "account.members.gir.addModal.label.note"                 : "Note",
    "account.members.gir.addModal.label.jersey"               : "Numéro de chandail",
    "account.members.gir.addModal.label.date"                 : "Date",
    "account.members.gir.addModal.label.officialType"         : "Type d'officiel",

    "account.members.gir.addModal.label.gamePeriod.FIRST"          : "Première",
    "account.members.gir.addModal.label.gamePeriod.SECOND"         : "Seconde",
    "account.members.gir.addModal.label.gamePeriod.THIRD"          : "Troisième",
    "account.members.gir.addModal.label.gamePeriod.OVERTIME"       : "Prolongation",
    "account.members.gir.addModal.label.gamePeriod.SHOOTOUT"       : "Tirs au but",
    "account.members.gir.addModal.label.gamePeriod.PRE_POST_GAME"  : "Avant le jeu / Après le jeu",
    "account.members.gir.addModal.steps.officials.type.REFEREE"    : "Arbitre",
    "account.members.gir.addModal.steps.officials.type.LINESMAN"   : "Juge de ligne",
    "account.members.gir.addModal.steps.officials.type.SUPERVISOR" : "Superviseur",

    "account.members.gir.addModal.validation.player"        : "Veuillez sélectionner un joueur",
    "account.members.gir.addModal.validation.penaltyType"   : "Veuillez sélectionner un type de pénalité",
    "account.members.gir.addModal.validation.penalty"       : "Veuillez sélectionner une pénalité",
    "account.members.gir.addModal.validation.team"          : "Veuillez sélectionner une équipe",
    "account.members.gir.addModal.validation.season"        : "Veuillez sélectionner une saison",
    "account.members.gir.addModal.validation.division"      : "Veuillez sélectionner une division",
    "account.members.gir.addModal.validation.category"      : "Veuillez sélectionner une catégorie",
    "account.members.gir.addModal.validation.phone"         : "Le numéro de téléphone est requis pour cet officiel",
    "account.members.gir.addModal.validation.email"         : "L'adresse courriel est requise pour cet official",
    "account.members.gir.addModal.validation.date"          : "Veuillez saisir une date",
    "account.members.gir.addModal.validation.officialType"  : "Veuillez le type d'officiel",
    "account.members.gir.addModal.validation.date.valid"    : "Veuillez saisir une date valide",
    "account.members.gir.addModal.validation.date.future"   : "La date de l'incident ne peut être dans le futur",
    "account.members.gir.addModal.validation.jersey.max"    : "Veuillez choisir un numéro de chandail en dessous de 100",
    "account.members.gir.addModal.validation.time.min"      : "Veuillez entrer un nombre qui est pareil ou dessus de 0",
    "account.members.gir.addModal.validation.time.max"      : "Veuillez entrer un nombre en dessous de {max}",
    "account.members.gir.addModal.validation.teamWarning"   : "Attention",
    "account.members.gir.addModal.validation.twoOtherTeams" : "Une des deux équipes doit être une équipe existante",
    "account.members.gir.addModal.validation.twoSameTeams"  : "Veuillez choisir deux équipes différentes",
    "account.members.gir.addModal.validation.status"        : "Veuillez choisir un statut d'incident",
    "account.members.gir.addModal.validation.organisation"  : "Veuillez sélectionner une organisation",
    "account.members.gir.addModal.validation.teamName"      : "Veuillez indiquer le nom de l'équipe",
    "account.members.gir.addModal.validation.jerseyNb"      : "Le numéro de chandail doit être un chiffre",
    "account.members.gir.addModal.validation.coach.other"   : "Veuillez spécifier le nom du coach ou supprimer la valeur du menu déroulant",
    "account.members.gir.addModal.validation.manager.other" : "Veuillez spécifier le nom du manager ou supprimer la valeur du menu déroulant",

    "account.members.gir.addModal.teams.home.label" : "Équipe locale",
    "account.members.gir.addModal.teams.away.label" : "Équipe visite",

    "account.members.gir.addModal.officials.title"        : "Ajouter officiel",
    "account.members.gir.addModal.officials.btn.subtitle" : "Cliquez ici pour ajouter un officiel",

    "account.members.gir.addModal.incident.title"        : "Ajouter incident",
    "account.members.gir.addModal.incident.btn.subtitle" : "Cliquez ici pour ajouter un incident",

    // Clinics Management
    "account.members.clinicsManagement.noResults.title"                           : "Aucun stage",
    "account.members.clinicsManagement.noResults.msg"                             : "Il n'y a pas de stage à gérer pour cet instructeur",
    "account.members.clinicsManagement"                                           : "Gestion de stage",
    "account.members.clinicsManagement.attendees"                                 : "Participants",
    "account.members.clinicsManagement.attendees.count"                           : "{count, plural, =0 {Pas de participant} one {# participant} other {# participants}}",
    "account.members.clinicsManagement.attendees.label.passed"                    : "A passé",
    "account.members.clinicsManagement.attendees.label.paid"                      : "A payé",
    "account.members.clinicsManagement.backToList"                                : "Retour à la liste des stages",
    "account.members.clinicsManagement.attendees.label.attended"                  : "A participé",
    "account.members.clinicsManagement.attendees.sidePanel.selected"              : "{count, plural, =0 {Aucun participant sélectionné} one {# participant sélectionné} other {# participants sélectionnés}}",
    "account.members.clinicsManagement.attendees.attendeeInfo"                    : "Informations du participant",
    "account.members.clinicsManagement.attendees.attended.label"                  : "Présence",
    "account.members.clinicsManagement.attendees.paid"                            : "Payé",
    "account.members.clinicsManagement.attendees.attended"                        : "{count, plural, =0 {A assisté} one {A assisté} other {Ont assisté}}",
    "account.members.clinicsManagement.attendees.attended.required"               : "Veuillez sélectionner un statut de présence",
    "account.members.clinicsManagement.attendees.attended.not"                    : "{count, plural, =0 {N'a pas assisté} one {N'a pas assisté} other {N'ont pas assisté}}",
    "account.members.clinicsManagement.attendees.attended.unable"                 : "Le statut de présence ne peut pas être modifié si le participant a passé le stage",
    "account.members.clinicsManagement.attendees.attended.unable.multiple"        : "Le statut de présence ne peut pas être modifié si un des participants a passé le stage",
    "account.members.clinicsManagement.attendees.attended.date.required"          : "La date de présence est requise",
    "account.members.clinicsManagement.attendees.attended.date.before"            : "La date de présence ne peut pas être après la date de passage",
    "account.members.clinicsManagement.attendees.passed.label"                    : "Résultat",
    "account.members.clinicsManagement.attendees.passed"                          : "{count, plural, =0 {A réussi} one {A réussi} other {Ont réussi}}",
    "account.members.clinicsManagement.attendees.passed.not"                      : "N'a pas réussi",
    "account.members.clinicsManagement.attendees.passed.unable"                   : "Le statut de passage ne peut pas être modifié si le participant a été certifié pour le stage",
    "account.members.clinicsManagement.attendees.passed.unable.multiple"          : "Le statut de passage ne peut pas être modifié si un des participants a été certifié pour le stage",
    "account.members.clinicsManagement.attendees.passed.date.required"            : "La date de passage est requise",
    "account.members.clinicsManagement.attendees.passed.date.noPresence"          : "Le participant doit avoir assisté au stage pour avoir le statut de passage",
    "account.members.clinicsManagement.attendees.passed.date.noPresence.multiple" : "Tous les participants doivent avoir assisté au stage pour avoir le statut de passage",
    "account.members.clinicsManagement.attendees.passed.date.after"               : "La date de passage ne peut pas être avant la date de présence",
    "account.members.clinicsManagement.attendees.passed.date.before"              : "La date de passage ne peut pas être après la date de certification",
    "account.members.clinicsManagement.attendees.certified.label"                 : "Certification",
    "account.members.clinicsManagement.attendees.certified"                       : "Certifié",
    "account.members.clinicsManagement.attendees.certified.not"                   : "Non certifié",
    "account.members.clinicsManagement.attendees.certified.hint"                  : "Cette action créera une qualification pour ce membre. Pour spécifier une date différente, un numéro de certification ou autre information, veuillez utiliser l'action \"Certifier\".",
    "account.members.clinicsManagement.attendees.notSpecified"                    : "Non spécifié",
    "account.members.clinicsManagement.openStore"                                 : "Ouvrir le lien de la boutique",
    "account.members.clinicsManagement.copyStoreLink"                             : "Copier le lien de la boutique",
    "account.members.clinicsManagement.openMoodle"                                : "Ouvrir dans Moodle",

    // Official
    "account.members.coach.homeTeam.label"                                         : "Équipe locale",
    "account.members.coach.awayTeam.label"                                         : "Équipe visite",
    "account.members.coach.team.label"                                             : "Équipe",
    "account.members.coach.overallScore.label"                                     : "Ratio global",
    "account.members.coach.official.label"                                         : "Officiel",
    "account.members.coach.officiatingCoach.label"                                 : "Superviseur d'officiels",
    "account.members.coach.supervisionReport.label"                                : "Rapport de supervision",
    "account.members.coach.lastSent"                                               : "Dernier envoi",
    "account.members.coach.season.label"                                           : "Saison",
    "account.members.coach.allReportsBtn.short"                                    : "Tous les rapports",
    "account.members.coach.allReportsBtn.long"                                     : "Voir toutes les supervisions",
    "account.members.coach.privateComment.label"                                   : "Commentaire privé",
    "account.members.coach.areaOfImprovement.label"                                : "Faiblesses",
    "account.members.coach.comments.label"                                         : "Commentaires",
    "account.members.coach.strengths.label"                                        : "Forces",
    "account.members.coach.teamCategory.label"                                     : "Catégorie d'équipe",
    "account.members.coach.teamName.label"                                         : "Nom de l'équipe",
    "account.members.coach.teamName.home.label"                                    : "Nom de l'équipe locale",
    "account.members.coach.teamName.away.label"                                    : "Nom de l'équipe visite",
    "account.members.coach.supervisionType.categories.label"                       : "Catégories",
    "account.members.coach.supervisedBy.label"                                     : "Supervisé par",
    "account.members.coach.officialType.label"                                     : "Type d'officiel",
    "account.members.coach.officialType.REFEREE"                                   : "Arbitre",
    "account.members.coach.officialType.LINESPERSON"                               : "Juge de touche",
    "account.members.coach.officialType.OFFICIAL_SYSTEM"                           : "Système d'officiel",
    "account.members.coach.allMemberReports"                                       : "Toutes les supervisions sur {name}",
    "account.members.coach.allMemberReports.backToList"                            : "Retour à la liste",
    "account.members.coach.allMemberReports.noReports.title"                       : "Aucun rapport",
    "account.members.coach.allMemberReports.noReports.officiating.subtitle"        : "Vous n'avez aucun formulaire de supervision de créé.",
    "account.members.coach.allMemberReports.noReports.officiating.search.subtitle" : "Aucun formulaire de supervision n'a été trouvé avec cette recherche.",
    "account.members.coach.allMemberReports.noReports.subtitle"                    : "Il n'y a pas de rapports de supervision sur cet officiel.",
    "account.members.coach.allMemberReports.noReports.search.subtitle"             : "Aucun rapport de supervision n'a été trouvé.",

    "account.members.coach.mySupervisions"       : "Mes formulaires de supervision",
    "account.members.coach.myReports"            : "Mes rapports",
    "account.members.coach.sidePanel.delete.msg" : "Vous êtes sur le point de supprimer ce rapport de supervision",
    "account.members.coach.sidePanel.tab.info"   : "Info",
    "account.members.coach.sidePanel.tab.type"   : "Type",
    "account.members.coach.sidePanel.tab.notes"  : "Notes",


    "account.members.coach.addSupervision.existingTeam"          : "Équipe existante",
    "account.members.coach.addSupervision.existingTeam.required" : "Veuillez sélectionner si l'équipe est existante ou non",
    "account.members.coach.addSupervision.otherTeam"             : "Autre",
    "account.members.coach.addSupervision.branch.label"          : "Branche",
    "account.members.coach.addSupervision.org.label"             : "Organisation",

    "account.members.coach.addSupervision.teamId.required"       : "Veuillez sélectionner l'équipe",
    "account.members.coach.addSupervision.teamName.required"     : "Veuillez spécifier le nom de l'équipe",
    "account.members.coach.addSupervision.branch.required"       : "Veuillez sélectionner la branche",
    "account.members.coach.addSupervision.org.required"          : "Veuillez sélectionner l'organisation",
    "account.members.coach.addSupervision.teamCategory.required" : "Veuillez spécifier la catégorie de l'équipe",
    "account.members.coach.addSupervision.period.required"       : "Veuillez spécifier la saison",
    "account.members.coach.addSupervision.date.required"         : "Veuillez spécifier la date",
    "account.members.coach.addSupervision.officialType.required" : "Veuillez spécifier le type d'officiel",

    "account.members.coach.addSupervision.step.findOfficial"             : "Trouver l'officiel",
    "account.members.coach.addSupervision.step.global"                   : "Remplir l'information globale",
    "account.members.coach.addSupervision.step.supervisionType"          : "Choisir le type de supervision",
    "account.members.coach.addSupervision.step.selectSupervisionType"    : "Choisissez le type de supervision",
    "account.members.coach.addSupervision.step.selectLevels"             : "Sélectionner les niveaux",
    "account.members.coach.addSupervision.privateComment.helper"         : "Ne sera visible que pour les superviseurs et les administrateurs",
    "account.members.coach.addSupervision.not_listed"                    : "Non listée",
    "account.members.coach.addSupervision.notOfficial.label"             : "Pas un officiel",
    "account.members.coach.addSupervision.cantSuperviseThemselves.label" : "Vous ne pouvez pas être votre propre superviseur",
    "account.members.coach.addSupervision.league.label"                  : "Ligue",

    "account.members.coach.categories.overallRatio"      : "Ratio Global",
    "account.members.coach.categories.skating"           : "Patinage",
    "account.members.coach.categories.positioning"       : "Positionnement",
    "account.members.coach.categories.procedures"        : "Procédures",
    "account.members.coach.categories.mental_skills"     : "Compétences mentales",
    "account.members.coach.categories.presence"          : "Présence",
    "account.members.coach.categories.communication"     : "Communication",
    "account.members.coach.categories.judgement"         : "Jugement",
    "account.members.coach.categories.rule_application"  : "Application des règles",
    "account.members.coach.categories.penalty_standards" : "Standard de pénalité",
    "account.members.coach.categories.choice.BEGINNING"  : "À Améliorer",
    "account.members.coach.categories.choice.DEVELOPING" : "Acceptable",
    "account.members.coach.categories.choice.MEETING"    : "Bon",
    "account.members.coach.categories.choice.EXCEEDING"  : "Excellent",
    "account.members.coach.categories.required"          : "Veuillez sélectionner un choix pour {category}",

    "account.members.documents.document.label"             : "Document",
    "account.members.documents.documentType.label"         : "Type de document",
    "account.members.documents.documentType.required"      : "Veuillez choisir un type de document",
    "account.members.documents.note.required"              : "Veuillez ajouter une note au document",
    "account.members.documents.document.required"          : "Veuillez ajouter un document",
    "account.members.documents.filters.label"              : "Filtre(s)",
    "account.members.documents.documentType.default"       : "Type par défaut",
    "account.members.documents.document.add.btn"           : "Ajouter un fichier",
    "account.members.documents.document.add.btn.helper"    : "Cliquez ici pour ajouter un fichier",
    "account.members.documents.document.add.title"         : "Téléverser un fichier",
    "account.members.documents.document.add.success.title" : "Fichier téléversé !",
    "account.members.documents.document.add.success.msg"   : "Votre fichier a été téléversé avec succès.",

    "account.members.officiatingCoach.sendEmail"      : "Envoyer par courriel",
    "account.members.officiatingCoach.sendEmail.help" : "Envoyer le rapport maintenant par courriel",

    "account.members.contacts.addModal.title"                : "Création de contact",
    "account.members.contacts.addModal.toast.success"        : "Contact créé!",
    "account.members.contacts.addModal.contactType"          : "Type de contact",
    "account.members.contacts.addModal.contactType.required" : "Le type de contact est requis",
    "account.members.contacts.addModal.emergency"            : "Contact en cas d'urgence",
    "account.members.contacts.addModal.addPhone"             : "Ajouter un numéro",
    "account.members.contacts.add"                           : "Ajouter un contact",
}