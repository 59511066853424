import { FormikError } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext, useState } from "react"
import { Alert, Button, Card, CardBody, Collapse, Fade, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import useSWR from "swr"

import { object, string } from "yup"
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI"
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader"
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay"
import { AccountsContext, AuthContext } from "../../../../../../../contexts/contexts"
import { I18nContext } from "../../../../../../../contexts/I18nContext"
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper"
import PaymentConfirmation from "../../../../components/PaymentConfirmation"
import PaymentOverview from "./PaymentOverview"
import PaysafeCreateCard from "./PaysafeCreateCard"
import PaysafeViewCards from "./PaysafeViewCards"

const CrossFade = ({ isVisible, children, ...props }) => {
    return (
        <Collapse {...props} isOpen={isVisible}>
            <Fade in={isVisible}>
                {children}
            </Fade>
        </Collapse>
    )
}

/**
 * @description Shown view when transaction mode is Paysafe
 * @param {Object} props
 * @param {function} props.toggle
 * @returns {React.ReactNode}
 */
const PaysafeView = ({ toggle, invoiceItem, cardToken, updatePaymentCard, paymentMode, updateInvoices }) => {
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);
    const i18n = useContext(I18nContext);
    const [ view, setView ] = useState(1);
    const changeView = (nb) => setView(nb);

    const closeModal = () => {
        if(view === 4){
            updateInvoices();
            toggle?.();
        }else{
            toggle();
        }
    }

    const { data: paysafeCards, error: paysafeError, isValidating: paysafeCardsLoading, mutate } = useSWR(
        [ 'vaultCards', auth.userData.Username ],
        () => accountsContext.getVaultCards(auth.userData.Username),
        { // Settings that will make this call use the one from VaultCards.js
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        },
    );

    function preventSubmitEnter(keyEvent){
        if(view !== 1 && (keyEvent.charCode || keyEvent.keyCode) === 13){ // prevent submit enter when not clicking on enter key
            keyEvent.preventDefault();
        }
    }

    return (
        <Formik
            initialValues={{
                paymentToken: cardToken || '',
                address: { // Address is used in the add credit card modal only
                    streetNumber: '',
                    address: '',
                    city: '',
                    country: '',
                    state: '',
                    zip: '',
                    fullAddress: '',
                    mapsUrl: '',
                    origin: '',
                },
            }}
            validationSchema={object().shape({
                paymentToken: string().required(<Translate id='account.paymentMethods.changeCard.modal.validation.card.required' />).test({
                    name: 'card-isDifferent',
                    message: <Translate id='account.paymentMethods.changeCard.modal.validation.card.different' />,
                    test: (paymentToken) => paymentToken !== cardToken,
                }),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                if(paymentMode){
                    const card = Array.isArray(paysafeCards) ? paysafeCards.find((card) => card.paymentToken === values.paymentToken) : undefined
                    if(card){
                        const splittedLang = i18n.locale.split('-');
                        accountsContext.processPayment(invoiceItem.shopping_cart_id, {
                            payment_token: values.paymentToken,
                            holder_firstname: auth.userData.UserAttributes.name,
                            holder_lastname: auth.userData.UserAttributes.family_name,
                            holder_city: card.address.city,
                            holder_zip: card.address.zip,
                            holder_address: card.address.street,
                            holder_province: card.address.state,
                            holder_country: card.address.country,
                            locale: `${splittedLang[0]}_${splittedLang[1].toUpperCase()}`,
                        })
                            .then(() => {
                                changeView(4); // If has future installments
                                setSubmitting(false);
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    setSubmitting(false);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            });
                    }else{
                        return Promise.reject('Trying to pay without a card')
                    }
                }else{
                    Promise.all(
                        invoiceItem.installments.filter((ins) => ins.payment_type !== "INITIAL_PAYMENT" && (ins.status === 'PENDING' || ins.status === 'NEED_REVIEW'))
                            .map((ins) => accountsContext.partialUpdateInstallments(ins.invoice_payment_id, { payment_token: values.paymentToken })),
                    )
                        .then(() => {
                            changeView(3); // If has future installments
                            setSubmitting(false);
                        })
                        .then(updatePaymentCard)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                setStatus(<DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />);
                            }
                        });
                }
            }}
        >
            { (formik) => (
                <Form onKeyDown={preventSubmitEnter}>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={closeModal}><Translate id='account.paymentMethods.changeCard.modal.title' /></ModalHeader>
                        <CrossFade isVisible={view === 1}>
                            <ModalBody>
                                <PaymentOverview invoiceItem={invoiceItem} />
                                <Card className="card-shadow mb-0">
                                    <CardBody>
                                        {/* Cards list */}
                                        <div className="h4 font-medium">
                                            <Translate id='account.paymentMethods.label.card' values={{ amount: paysafeCards?.length || 0 }} />
                                        </div>

                                        <PaysafeViewCards paysafeCards={paysafeCards} paysafeCardsLoading={paysafeCardsLoading} paysafeError={paysafeError} edit={() => changeView(2)} />

                                        <UserDisplay onClick={() => changeView(2)} style={{ borderStyle: 'dashed' }} className="mb-1 d-flex text-link justify-content-center border-primary" card hover>
                                            <i className="mdi mdi-plus mr-1" /><Translate id='account.paymentMethods.btn.addCard' />
                                        </UserDisplay>

                                        <FormikError name='paymentToken' />

                                        {/* Errors */}
                                        <Collapse isOpen={!!formik.status}>
                                            <Alert color="danger" className="mb-0 mt-3" toggle={() => formik.setStatus(null)}>
                                                {formik.status}
                                            </Alert>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={formik.isSubmitting} color="primary" type="submit"><Translate id={paymentMode ? "account.paymentMethods.label.pay" : "misc.confirm"} /></Button>
                                <Button disabled={formik.isSubmitting} color="primary" outline type="button" onClick={toggle}><Translate id="misc.cancel" /></Button>
                            </ModalFooter>
                        </CrossFade>

                        {/* Add a new card  */}
                        <CrossFade isVisible={view === 2} unmountOnExit mountOnEnter>
                            {/* Get sutToken */}
                            <PaysafeCreateCard changeView={changeView} invoiceNumber={invoiceItem.invoice_number} invoicePaymentId={invoiceItem.invoice_payment_id} updateVaultCards={mutate} />
                        </CrossFade>

                        {/** If has future installments */}
                        <CrossFade isVisible={view === 3}>
                            <ModalBody>
                                <div className="h4 font-medium"><Translate id="misc.success" /><i className="mdi mdi-check-circle-outline ml-1" /></div>
                                <p><Translate id='account.paymentMethods.changeCard.sportPay.success.text' /></p>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={toggle} color="primary" type="button"><Translate id="misc.close" /></Button>
                            </ModalFooter>
                        </CrossFade>

                        {/** If has future installments */}
                        <CrossFade isVisible={view === 4}>
                            <PaymentConfirmation invoiceNumber={invoiceItem.invoice_number} toggle={closeModal} />
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default PaysafeView;