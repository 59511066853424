export default{
    "account.api.webhooks.sidePanelAccountWebhooks.tabs.details" : "Détails",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.title" : "Informations de webhook",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.summary"        : "Sommaire",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status"         : "Statut",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status.success" : "Réussi",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status.fail"    : "Échoué",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.type"           : "Type",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.id"             : "Id",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.date"           : "Date",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.request" : "Request {method} body",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.response" : "Response body",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.copy" : "Copier",
}