export default{
    "paysafe.error.duplicateCard" : "This card is already linked to your account.",
    "paysafe.error.9001"          : "No connection to server, please try again. If the problem remains, please contact our support.",
    "paysafe.error.9002"          : "Error communicating with server.",
    "paysafe.error.9003"          : "There were errors with the payment information. Please review the marked fields.",
    "paysafe.error.9004"          : "There was an error (tokenize: 9004), please contact our support.",
    "paysafe.error.9013"          : "Invalid api key (code: 9013), please contact our support.",
    "paysafe.error.9014"          : "There was an error (code: 9014). Please try again later.",
    "paysafe.error.9015"          : "There was an error (tokenize: 9015), please contact our support.",
    "paysafe.error.9028"          : "The service is currently unavailable. Please try again later. (code: 9028)",
    "paysafe.error.9034"          : "There was an error (tokenize: 9034), please contact our support.",
    "paysafe.error.9035"          : "There was an error (tokenize: 9035), please contact our support.",
    "paysafe.error.9036"          : "There was an error (tokenize: 9036), please contact our support.",
    "paysafe.error.9037"          : "Error communicating with the server (3DS).",
    "paysafe.error.9039"          : "Enrollment is not accepted. Try again with the same or another card.",
    "paysafe.error.9040"          : "Authentication not accepted. Try again with the same or another card.",
    "paysafe.error.9041"          : "There was an error (tokenize: 9041), please contact our support.",
    "paysafe.error.9042"          : "User aborted authentication.",
    "paysafe.error.9043"          : "Invalid name.",
    "paysafe.error.9044"          : "Invalid country.",
    "paysafe.error.9045"          : "Invalid zip code.",
    "paysafe.error.9046"          : "Invalid province.",
    "paysafe.error.9047"          : "Invalid city.",
    "paysafe.error.9048"          : "Invalid address.",
    "paysafe.error.9049"          : "There was an error (tokenize: 9049), please contact our support.",
    "paysafe.error.9050"          : "There was an error (tokenize: 9050), please contact our support.",
    "paysafe.error.9051"          : "There was an error (tokenize: 9051), please contact our support.",
    "paysafe.error.9052"          : "There was an error (tokenize: 9052), please contact our support.",
    "paysafe.error.9110"          : "There was an error (tokenize: 9110), please contact our support.",
    "paysafe.error.9111"          : "There was an error (tokenize: 9111), please contact our support.",
    "paysafe.error.9112"          : "There was an error (tokenize: 9112), please contact our support.",
    "paysafe.error.9113"          : "There was an error (tokenize: 9113), please contact our support.",
    "paysafe.error.9114"          : "There was an error (tokenize: 9114), please contact our support.",
    "paysafe.error.9125"          : "There was an error (tokenize: 9125), please contact our support.",
}