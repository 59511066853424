import { stringBuilder } from "@spordle/helpers";
import { Col, Row } from 'reactstrap';
import hcrStyles from '../../../../assets/scss/auth/hcr/style.module.scss';
import LangSwitcher from '../../../../components/LangSwitcher';
import { getLocalStorageItem } from '../../../../helpers/browserStorage';

/**
* Temporary component used to uniform all of our auth pages's layout
* we should eventually refactor this into something like FullLayout.
*/
const HcrLayout = ({
    children,
}) => {
    const appearance = getLocalStorageItem('platformLogin')?.toLowerCase();
    const getAppearance = () => {
        switch (appearance){
            case 'play':
                return hcrStyles[`hcr-background-${appearance}`];
            case 'clinic':
            default:
                return hcrStyles['hcr-background'];
        }
    }

    return (
        /**
        backgrounds:
            id          -> hcrStyles['hcr-background']
            clinic      -> hcrStyles['hcr-background-clinic']
            play        -> hcrStyles['hcr-background-play']
        */
        <div className={stringBuilder('auth-wrapper', getAppearance())}>
            <div className="p-3 position-absolute top-0 right-0">
                <LangSwitcher className={hcrStyles['lang-btn']} />
            </div>
            <div className="container-fluid">
                <Row>
                    <Col md={{ size: 6, offset: 6 }}>
                        <div className="auth-container">
                            {children}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default HcrLayout;