import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Container, Fade, Spinner } from "reactstrap"
import Spordle from '../../assets/images/logos/spordleMyAccount.svg';
import HC from '../../assets/images/logos/HC@2x.png';
import { AccountsContext, AuthContext } from "../../contexts/contexts";
import { I18nContext } from "../../contexts/I18nContext";
import { displayI18n, DisplayI18n } from "../../helpers/i18nHelper";
import { AuthenticatorContext } from "../authentication/AuthenticatorProvider";
import { Link } from "react-router-dom";
import { sendGAEvent } from "@spordle/ga4";
import { stringBuilder } from "@spordle/helpers";
import { fail } from '@spordle/toasts';
import { isMultiSport, isProdFishNChips } from "../../helpers/getReferer";

const TermsOfUse = ({ data, location, history, params, ...props }) => {
    const accountsContext = useContext(AccountsContext);
    const authenticatorContext = useContext(AuthenticatorContext);
    const authContext = useContext(AuthContext);
    const i18nContext = useContext(I18nContext);
    const [ isLoading, setIsLoading ] = useState(true);

    const redirect = () => {
        return authContext.getUserData(authContext.accessToken)
            .then(() => {
                history.replace('/dashboard');
            }).catch((error) => {
                fail();
                console.error(error);
            });
    }

    const acceptTerms = () => {
        setIsLoading(true);
        accountsContext.acceptTermsOfUse(authenticatorContext.userName, location.terms.term_of_use_id, i18nContext.getGenericLocale())
            .then(() => {
                sendGAEvent('sign_terms', {
                    status: 'ACCEPTED',
                });
            })
            .then(redirect)
            .catch((err) => {
                if(err.message === "3174"){ // Already signed
                    redirect();
                }else{
                    console.error(err.message);
                }
            })
    }

    useEffect(() => {
        if(!authenticatorContext.userName){
            history.replace('/login');
        }else{
            setIsLoading(false);
        }
    }, []);

    return (
        <div className="stac-container">
            <Container className="stac-container-inner bg-white rounded-lg p-3">
                <div className="text-center">
                    {isProdFishNChips() ?
                        <img width={90} src='/EIHA.png' alt="English Ice Hockey Association" />
                        : isMultiSport() ?
                            <img width={90} src='/spordle-256x256.png' alt="Spordle" />
                            :
                            <img width={90} src={HC} alt="Hockey Canada" />
                    }
                    <div className="h4 font-bold mb-4">
                        <Translate id='login.heading.title' />
                    </div>
                    <h1 className="font-medium h4"><Translate id='termsOfUse.title' /></h1>
                    <p className="text-muted"><Translate id='termsOfUse.subtitle' /></p>
                </div>
                <div className={stringBuilder('rounded-lg bg-light overflow-hidden', { 'text-muted': isLoading })}>
                    <div className="stac-inner p-3">
                        {location.terms &&
                            <Fade>
                                <h2 className="h4 font-medium">
                                    <DisplayI18n field='name' i18n={location.terms.i18n} defaultValue={location.terms.name} />
                                </h2>
                                <div dangerouslySetInnerHTML={{ __html: displayI18n('description', location.terms.i18n, location.terms.description, i18nContext.getGenericLocale())?.replace(/href/g, "rel='noopener noreferrer' target='_blank' href") }} />
                            </Fade>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-between py-2">
                    <Link className="btn btn-dark stac-btn" to={authenticatorContext.goToPage('LOGIN', true, true)}>{ isLoading ? <Spinner type="grow" size="sm" /> : <Translate id='termsOfUse.btn.decline' /> }</Link>
                    <button type="button" className="btn stac-btn btn-spordle" disabled={!location.terms || isLoading} onClick={acceptTerms}>
                        { isLoading ? <Spinner type="grow" size="sm" /> : <><Translate id='termsOfUse.btn.accept' /> <i className="mdi mdi-check" /></> }
                    </button>
                </div>
                <div className="text-center border-top pt-3">
                    <span className="text-muted mr-2">Powered by</span><img className="stac-spordle-account" src={Spordle} alt="Spordle My Account" />
                </div>
            </Container>
        </div>
    )
}

export default TermsOfUse;