import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Badge, Dropdown, DropdownMenu, DropdownToggle, Fade, ListGroup, Spinner } from "reactstrap";
import useSWR from 'swr';
import Notification from './Notification';
import EmptyNotifications from '../../../../assets/images/helpCenter/spordleCoach.png';
import { NotificationsContext } from "../../../../contexts/NotificationsContext";
import { AuthContext } from '../../../../contexts/contexts';
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import PreviewNotif from "./PreviewNotif";
import OverlayLoader from "../../../../components/loading/OverlayLoader";

/**
 * @description Notifications icons. Contains all the notifications handling & logic
 * @returns {JSX.Element}
 */
const HeaderNotifications = () => {
    const { deleteAllNotifications, deleteNotification, getNotifications, updateMarkAllAsRead } = useContext(NotificationsContext);
    const { userData } = useContext(AuthContext);
    const [ preview, setPreview ] = useState({
        isOpen: false,
        notif: null,
    });

    const [ unreadCount, setUnreadCount ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(false);
    const { data: notifs, error, isValidating, mutate } = useSWR(
        [ 'getNotifications', userData.Username ],
        () => getNotifications(userData.Username)
            .then((notifications) => notifications.sort((notifA, notifB) => new Date(notifA.created_at) - new Date(notifB.created_at))),
        {
            revalidateOnMount: true,
            onSuccess: countUnreadNotifs,
            shouldRetryOnError: false,
            refreshInterval: 300000, // Every 5 minutes
            onError: handleError,
        },
    );

    /**
     * @description handles errors
     * @param {Error} e
     */
    function handleError(e){
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
        }
    }

    /**
     * @description counts all notification unread
     * @param {array} myNotifs
     */
    function countUnreadNotifs(myNotifs){
        setUnreadCount(myNotifs.filter((n) => !n.read_by).length);
    }

    /**
     * @description Removes a notification count
     */
    function readNotification(){
        setUnreadCount((prev) => prev > 0 ? prev - 1 : 0);
    }

    /**
     * @description Marks all notifications as read
     */
    async function markAllAsRead(){
        setIsLoading(true);
        setUnreadCount(0);

        mutate((notifs) => {
            return notifs.map((n) => {
                n.read_by = "me";
                return n;
            })
        }, false);

        await updateMarkAllAsRead(userData?.Username)
            .catch(handleError);
        setIsLoading(false);
    }

    /**
     * @description Deletes a notification
     * @param {object} notif
     */
    async function deleteNotif(notif){
        setIsLoading(true);
        mutate((allNotifs) => {
            return allNotifs.filter((n) => n.notification_id !== notif.notification_id);
        }, false)

        await deleteNotification(notif.notification_id)
            .catch(console.error);
        setIsLoading(false);
    }

    /**
     * @description Deletes all notifications from the list
     */
    async function deleteAllNotifs(){
        setIsLoading(true);
        setUnreadCount(0);
        mutate(() => [], false);

        await deleteAllNotifications(userData?.Username)
            .catch(handleError)
        setIsLoading(false);
    }

    useEffect(() => {
        if(isOpen){
            mutate();
        }
    }, [ isOpen ])

    return (
        <>
            <Dropdown isOpen={isOpen} nav inNavbar toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle nav caret>
                    <div className="position-relative d-inline">
                        <i className="mdi mdi-bell font-18" />
                        { (unreadCount > 0) &&
                            <Badge className="notification-bell-pill" color="primary" pill>
                                {unreadCount > 9 ? '9+' : unreadCount}
                            </Badge>
                        }
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="mailbox">
                    <div className="d-flex align-items-center px-3 pt-3 border-bottom pb-3">
                        <div className="h4 font-medium mb-0 mr-1"><Translate id="header.notifications.title" /></div>
                        {isValidating &&
                            <Spinner color='primary' type='grow' size='sm' />
                        }
                    </div>
                    { notifs?.length > 0 ?
                        <OverlayLoader isLoading={isValidating || isLoading}>
                            <div className="text-right py-2 px-3">
                                { unreadCount > 0 ?
                                    <button type='button' disabled={isValidating || isLoading} className="notification-read-all" onClick={markAllAsRead}><Translate id='header.notifications.btn.clearAll' /></button>
                                    :
                                    <button type='button' disabled={isValidating || isLoading} className="notification-read-all" onClick={deleteAllNotifs}><Translate id='header.notifications.btn.deleteAll' /></button>
                                }
                            </div>
                            <ListGroup tag="div" flush className="message-center notification">
                                { notifs.sort((a, b) => {
                                    if(moment(a.created_at).isBefore(moment(b.created_at))){
                                        return 1;
                                    }else if(moment(a.created_at).isAfter(moment(b.created_at))){
                                        return -1;
                                    }
                                    return 0;

                                }).map((singleNotif) => (
                                    <Notification
                                        key={singleNotif.notification_id}
                                        isValidating={isValidating || isLoading}
                                        notifIsRead={!!singleNotif.read_by}
                                        readNotification={readNotification}
                                        deleteNotif={deleteNotif}
                                        mutate={mutate}
                                        notification={singleNotif}
                                        close={() => setIsOpen(false)}
                                        showPreview={(notif) => setPreview({ notif, isOpen: true })}
                                    />
                                ))}
                            </ListGroup>
                        </OverlayLoader>
                        :
                        !!notifs || !!error ?
                            <div className="notification px-3 py-4 text-center border-top-0">
                                <Fade>
                                    <img className="w-25 mb-2" src={EmptyNotifications} alt="No notification" />
                                    <div className="h4 font-bold mb-0"><Translate id='header.notifications.empty.title' /></div>
                                    <div><Translate id='header.notifications.empty.text' /></div>
                                </Fade>
                            </div>
                            :
                            <div className="pt-4" style={{ width: 500 }}>
                                <Skeleton count={3} height={100} />
                            </div>
                    }
                </DropdownMenu>
            </Dropdown>
            <PreviewNotif
                isOpen={preview.isOpen}
                notif={preview.notif}
                toggle={() => setPreview((prev) => ({ ...prev, isOpen: false }))}
            />
        </>
    )
}

export default HeaderNotifications;