import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Card, CardBody } from "reactstrap";
import DisplayCategory from "../../../../components/DisplayCategory";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

const RADIO_CHOICES = [
    "BEGINNING",
    "DEVELOPING",
    "MEETING",
    "EXCEEDING",
];

const FORM_CATEGORIES = [
    "skating",
    "positioning",
    "procedures",
    "presence",
    "communication",
];

const EXTRA_TYPE_CATEGORIES = {
    "grassroots": [
        "judgement",
    ],
    "development": [
        "rule_application",
        "penalty_standards",
    ],
    "high performance": [
        "mental_skills",
        "rule_application",
        "penalty_standards",
    ],
};

export const OTHER_TEAM = "OTHER";
export const getCategoryTypes = (formType) => [ ...FORM_CATEGORIES, ...(EXTRA_TYPE_CATEGORIES[(formType || "").toLowerCase()] || []) ];
export const getCategoryTypeChoices = (formType) => (formType || "").toLowerCase() === "grassroots" ? RADIO_CHOICES.filter((c) => c !== "BEGINNING") : RADIO_CHOICES


export const SupervisionSpordleTableMobileView = ({ spordleTable, panelProps, fromView = "official" }) => {
    const supervisions = spordleTable.getDisplayedData();

    const handleCardClick = (e, attendee) => {
        e.stopPropagation();
        e.preventDefault();
        panelProps.onMultiSelectChange(attendee);
    }

    return (
        <>
            {supervisions.length <= 0 ?
                <EmptyLayout hideArrow hideMsg />
                :
                supervisions.map((supervision, index) => (
                    <Card
                        id={spordleTable.generateId('table-body', 'row', supervision.supervision_id)}
                        onClick={(e) => handleCardClick(e, supervision)}
                        className={stringBuilder(
                            "card-shadow mb-2 clickable transition",
                            { "bg-light-inverse": index % 2 == 0 && !supervision.checked },
                            { "bg-primary-light": supervision.checked },
                        )}
                        key={supervision.supervision_id}
                    >
                        <CardBody className="d-flex align-items-center">
                            <div>
                                <div className="small text-muted mb-1">
                                    <div>
                                        {supervision.supervision_date} (
                                        <DisplayI18n
                                            field="name"
                                            i18n={supervision.period.i18n}
                                            defaultValue={supervision.period.name}
                                        />
                                        )
                                    </div>
                                </div>
                                <div className="font-bold h4 mb-0">
                                    <DisplayI18n
                                        field="name"
                                        i18n={supervision.supervision_type.i18n}
                                        defaultValue={supervision.supervision_type.name}
                                    />
                                </div>
                                <DisplayCategory category={supervision.team_category} short />
                                {fromView === "officiatingCoach" ?
                                    <div className="mt-3">
                                        <small className="text-muted">
                                            <Translate id='account.members.coach.official.label' />
                                        </small>
                                        <div>{`${supervision.official?.first_name} ${supervision.official?.last_name}`}</div>
                                        <div className="text-muted small">#{`${supervision.official?.unique_identifier}`}</div>
                                    </div>
                                    :
                                    <div>
                                        <Translate id='account.members.coach.supervisedBy.label' />
                                        <div>{`${supervision.officiating_coach?.first_name} ${supervision.officiating_coach?.last_name}`}</div>
                                    </div>
                                }
                            </div>
                            <div className="ml-auto">
                                <i className="text-muted font-20 mdi mdi-chevron-right" />
                            </div>
                        </CardBody>
                    </Card>
                ))
            }
        </>
    )
}