import { useContext, useState } from 'react';
import { Button, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { I18nContext } from '../../../../../contexts/I18nContext';
import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import { FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup';
import Translate from '@spordle/intl-elements';
import { LOCALES } from '../../../../../i18n/locales';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';

const AccountLanguage = () => {
    const auth = useContext(AuthContext);
    const i18n = useContext(I18nContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };
    const locale = auth.userData.UserAttributes.locale ? auth.userData.UserAttributes.locale.split('_')[0] : 'en';
    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='account.settings.profile.accountLanguage.preferredLang' />}
                value={<Translate id={'misc.lang.long.' + locale} />}
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountLanguage' isOpen={isOpen}>
                <ModalHeader toggle={toggleModal}>
                    <Translate id='account.settings.profile.accountLanguage.preferredLang' />
                </ModalHeader>
                <Formik
                    initialValues={{
                        language: locale,
                    }}
                    validationSchema={Yup.object().shape({
                        language: Yup.string().required(<Translate id='signup.signupForm.validation.email.required' />),
                    })}
                    onSubmit={({ language }, { setStatus, setSubmitting }) => {
                        setStatus();
                        auth.updateUserInfo({
                            ...auth.userData.UserAttributes,
                            locale: language === 'en' ? 'en_US' : 'fr_CA',
                            language_code: language,
                        }).then(() => {
                            setStatus();
                            i18n.setLocale(language === 'en' ? LOCALES.ENGLISH : LOCALES.FRENCH);
                            success({ msg: 'account.settings.profile.accountLanguage.update.toast.success' });
                            toggleModal();
                        })
                            .catch((error) => {
                                setStatus(error.message);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <FormikSelect
                                        id='language'
                                        name='language'
                                        label='account.settings.profile.accountLanguage.preferredLang'
                                        translateLabel
                                        search={false}
                                        defaultData={[
                                            { label: 'English', value: 'en' },
                                            { label: 'Français', value: 'fr' },
                                        ]}
                                        loadingStatus="success"
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    );
}

export default AccountLanguage;