export default{
    "sidebar.dashboard"      : "Accueil",
    "sidebar.members"        : "Membres",
    "sidebar.calendar"       : "Calendrier",
    "sidebar.purchases"      : "Achats",
    "sidebar.payments"       : "Paiements",
    "sidebar.communications" : "Communications",
    "sidebar.documents"      : "Documents",
    "sidebar.settings"       : "Réglages",
    "sidebar.api"            : "API",

    "sidebar.terms" : "Termes & conditions",
    "sidebar.about" : "À propos de nous",
}