import sidePanel from "./sidepanel";
import memberProfileTransactions from "./memberProfileTransactions";
import metaMemberSearch from "./metaMemberSearch";

export default{
    "account.transactions.status.confirmed"      : "Confirmed",
    "account.transactions.status.paid"           : "Paid",
    "account.transactions.status.partially_paid" : "Partially Paid",
    "account.transactions.status.pending"        : "Pending",
    "account.transactions.status.cancelled"      : "Cancelled",

    "account.transactions.transaction"                     : "Transaction",
    "account.transactions.transaction.order"               : "Order",
    "account.transactions.transaction.date"                : "Transaction Date",
    "account.transactions.transaction.registrationFee"     : "Registration Fee",
    "account.transactions.transaction.annualMembershipFee" : "Annual Membership Fee",
    "account.transactions.transaction.totalPrice"          : "Total Price",
    "account.transactions.transaction.balance"             : "Balance",

    "account.transactions.sendWaivers.title"               : "Send waivers",
    "account.transactions.sendWaivers.selectWaiver"        : "Select Waiver",
    "account.transactions.sendWaivers.selectContact"       : "Select Contact",
    "account.transactions.sendWaivers.waiversWillBeSendTo" : "waiver(s) will be send to :",
    "account.transactions.sendWaivers.submit"              : "Registrer",

    "account.transactions.confirmWaivers.title" : "Accept waivers",
    "account.transactions.confirmWaivers.hint"  : "* The selected waivers will be marked as \"recieved by me\"",

    "account.transactions.form.status"                     : "Status",
    "account.transactions.form.status.ONLINE"              : "Online",
    "account.transactions.form.status.ADMIN"               : "Manual",
    "account.transactions.form.status.ONLINE.registration" : "Online registration",
    "account.transactions.form.status.ADMIN.registration"  : "Manual registration",

    "account.transactions.form.confirm"               : "Confirm",
    "account.transactions.form.waivers"               : "Waivers",
    "account.transactions.form.member"                : "Member",
    "account.transactions.form.category"              : "Category",
    "account.transactions.form.registration"          : "Registration",
    "account.transactions.form.position"              : "Member Position",
    "account.transactions.form.memberType"            : "Member Type",
    "account.transactions.form.registrationDate"      : "Registration Date",
    "account.transactions.form.season"                : "Season",
    "account.transactions.form.wrongOrganization"     : "Oops! You must be managing {organization} to register this member.",
    "account.transactions.form.changeOrganization"    : "Click here to change organization.",
    "account.transactions.form.possibleRegistrations" : "{count} possible registrations",

    "account.transactions.form.signedBy" : "Signed by {name}",

    "account.transactions.table.emptyLayout.noResult" : "There are no registrations for this member in the current period.",

    "account.transactions.error.registrationAgeError" : "This registration does not apply to this member (wrong age)",
    "account.transactions.error.alreadyRegistered"    : "Already registered",
    "account.transactions.error.soldOut"              : "Sold out",

    ...memberProfileTransactions,
    ...sidePanel,
    ...metaMemberSearch,
}