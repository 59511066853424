import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import Translate from '@spordle/intl-elements';
import { object } from 'yup';
import { useContext, useState } from 'react';
import { AccountsContext, AuthContext } from '../../../../../contexts/contexts';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { FormikAddress, FormikInputText, getFormikAddressInitialValues } from '@spordle/formik-elements';
import { groupByAsArray } from '@spordle/helpers';
import CrossFade from '../../../members/gameIncidentReports/components/CrossFade';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import ChangeAddressModalMembers from './ChangeAddressModalMembers';

const ChangeAddressModal = ({ toggleModal, required }) => {
    const { getUserData, userData } = useContext(AuthContext);
    const { createIdentityAddress, deleteIdentityAddress, idMetaMembers } = useContext(AccountsContext);
    const [ showPublishMembers, setPublishMembers ] = useState(false);
    const [ showPublishMembersStep, setPublishMembersStep ] = useState(0);

    const closeModal = required ? undefined : toggleModal;

    return (
        <>
            <CrossFade isVisible={!showPublishMembers}>
                <Formik
                    initialValues={{
                        address: getFormikAddressInitialValues(),
                        address2: '',
                    }}
                    validationSchema={object().shape({
                        address: object().address(true, {
                            streetNumber: <Translate id='form.validation.streetNumber.required' />,
                            address: <Translate id='form.validation.address.required' />,
                            city: <Translate id='form.validation.city.required' />,
                            zip: <Translate id='form.validation.zip.required' />,
                            state: <Translate id='form.validation.province.required' />,
                            country: <Translate id='form.validation.country.required' />,
                        }),
                    })}
                    onSubmit={async({ address, ...values }, { setStatus }) => {
                        setStatus();
                        return createIdentityAddress(userData.Username, {
                            street_number: address.streetNumber,
                            street: address.address,
                            country_code: address.country,
                            province_code: address.state,
                            city: address.city,
                            zip_code: address.zipCode,
                            active: 1,
                            map_url: address.mapsUrl,
                            unit_number: values.address2,
                            origin_address: address.origin,
                        })
                            .then(async(membersToPublish) => {
                                // Delete all existing identity addresses
                                await Promise.all(userData.addresses.map((address) => deleteIdentityAddress(userData.Username, address.identity_address_id))).catch(console.error);
                                await getUserData();
                                if(membersToPublish.length){
                                    // TODO: REFACTOR
                                    setPublishMembers(groupByAsArray(idMetaMembers.meta_members.filter((mMember) => membersToPublish.findIndex((member) => mMember.members[0]?.member_id === member.member_id) !== -1).map((mMember) => ({ ...mMember, orgId: mMember.members[0]?.organisation.organisation_id })), 'orgId'));
                                }else{
                                    toggleModal();
                                }
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={closeModal}>
                                    <Translate id='account.settings.profile.accountAddress.modalTitle' />
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <FormikAddress
                                            id='address' name='address'
                                            required
                                            allowManualPlace
                                            label='account.settings.profile.accountAddress.label'
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="address2"><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                        <FormikInputText id='address2' name='address2' />
                                    </FormGroup>
                                    <Collapse isOpen={!!formik.status} unmountOnExit mountOnEnter>
                                        <Alert color='danger'>{formik.status}</Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                    {closeModal &&
                                        <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                                    }
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </CrossFade>
            <CrossFade isVisible={Array.isArray(showPublishMembers)} unmountOnExit mountOnEnter>
                <CrossFade isVisible={showPublishMembersStep === 0} unmountOnExit mountOnEnter>
                    <ModalHeader toggle={toggleModal}>
                        <Translate id='account.settings.profile.accountAddress.step1.title' values={{ membersCount: showPublishMembers.length }} />
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <CustomAnimatedIcon icon='checkmark' withCircle className='text-success mb-2' size={50} />
                        <div className='text-dark font-bold h4'>
                            <Translate id='account.settings.profile.accountAddress.step1.success' />
                        </div>
                        <p>
                            <Translate id='account.settings.profile.accountAddress.step1.helper' values={{ membersCount: showPublishMembers.length }} />
                            <br />
                            <Translate id='account.settings.profile.accountAddress.step1.helper2' values={{ membersCount: showPublishMembers.length }} />
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='button' onClick={() => { setPublishMembersStep(showPublishMembersStep + 1) }}>
                            <Translate id='account.settings.profile.accountAddress.step1.submit' values={{ membersCount: showPublishMembers.length }} />
                        </Button>
                        <Button color='primary' outline type='button' onClick={toggleModal}><Translate id='misc.close' /></Button>
                    </ModalFooter>
                </CrossFade>
                {Array.isArray(showPublishMembers) && showPublishMembers.map((publishMember, index) => (
                    <CrossFade key={publishMember.key} isVisible={showPublishMembersStep === index + 1} unmountOnExit mountOnEnter>
                        <ChangeAddressModalMembers
                            toggleModal={toggleModal}
                            showPublishMembers={publishMember.values}
                            orgId={publishMember.key}
                            seeNextMembers={() => {
                                setPublishMembersStep(showPublishMembersStep + 1)
                            }}
                        />
                    </CrossFade>
                ))}
                <CrossFade isVisible={showPublishMembersStep == showPublishMembers.length + 1} unmountOnExit mountOnEnter>
                    <ModalHeader toggle={closeModal}>
                        <Translate id='account.members.changeSingleAddress.modal.success.title' />
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <CustomAnimatedIcon icon='checkmark' withCircle className='text-success mb-2' size={50} />
                        <div className='text-dark font-bold h4'><Translate id='account.members.changeSingleAddress.modal.success' /></div>
                        <div className='text-dark h6'><Translate id='account.members.changeSingleAddress.modal.success.subText' /></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='button' onClick={toggleModal}><Translate id='misc.close' /></Button>
                    </ModalFooter>
                </CrossFade>
            </CrossFade>
        </>
    );
};

export default ChangeAddressModal;
