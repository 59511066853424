import submitTicket from "./submitTicket";
import userGuide from "./userGuide";

export default{
    ...submitTicket,
    ...userGuide,

    "helpCenter.title"        : "Besoin d'aide?",
    "helpCenter.userGuide"    : "Guide Utilisateur",
    "helpCenter.submitTicket" : "Demande de soutien",
    "helpCenter.chat"         : "Chat",
}