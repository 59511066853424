import { FormikDateTime, FormikError, FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { isMoment } from 'moment';
import { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { Alert, Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useSWR from 'swr';
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import Required from '../../../../../components/formik/Required';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import FileUpload from '../../../../../components/uploader/FileUpload';
import { mbToBytes } from '../../../../../components/uploader/uploadHelpers';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { AccountsContext, AuthContext } from '../../../../../contexts/contexts';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import CrossFade from '../../../members/gameIncidentReports/components/CrossFade';

const ChangeAddressModalMembers = ({ showPublishMembers, toggleModal, orgId, seeNextMembers }) => {
    const { userData } = useContext(AuthContext);
    const { createMemberAttachments, getDocumentTypes } = useContext(MembersContext);
    const { publishIdentityAddress, getAddressTypes } = useContext(AccountsContext);
    const { federationId, getOrganizationSettings } = useContext(OrganizationContext);

    function getAddressString(){
        const currentAddress = userData.addresses[0];
        if(currentAddress){
            return `${currentAddress.unit_number ? `${currentAddress.unit_number}-` : ''}${currentAddress.street_number} ${currentAddress.street}, ${currentAddress.city}, ${currentAddress.province_code}, ${currentAddress.country_code}, ${currentAddress.zip_code}`
        }
        return '';
    }

    const { data: documents, isValidating } = useSWR(
        [ 'getOrganizationSettings', 'getDocumentTypes', orgId ],
        () => Promise.all([
            getOrganizationSettings(orgId),
            getDocumentTypes(orgId),
        ]).then(([ orgSettings, documentTypes ]) => {
            return orgSettings.attachment_for_new_member_in_registration.value.map((documentTypeId) => (
                documentTypes.find(({ document_type_id }) => document_type_id === documentTypeId)
            )).filter((document) => !!document);
        }),
    )

    return (
        <Formik
            initialValues={{
                members: [],
                addressTypeId: '',
                yearsSameAddress: '',
                step: 1,
                documents: {},
            }}
            validationSchema={object().shape({
                members: array().min(1, <Translate id='account.settings.profile.accountAddress.validation.members.min' />),
                addressTypeId: string().when('step', {
                    is: 2,
                    then: string().required(<Translate id='account.settings.profile.accountAddress.validation.addressType.required' />),
                }),
                yearsSameAddress: mixed().when('step', {
                    is: 2,
                    then: mixed().required(<Translate id='account.settings.profile.accountAddress.validation.yearsSameAddress.required' />)
                        .isDate(<Translate id='form.validation.date.format' />)
                        .test({
                            name: 'isBefore',
                            message: <Translate id='account.settings.profile.accountAddress.validation.yearsSameAddress.isBefore' />,
                            test: (date) => {
                                if(isMoment(date)){
                                    return date.year() <= new Date().getFullYear();
                                }
                                return true;
                            },
                        }),
                })
                ,
            })}
            onSubmit={async({ members, step, ...values }, { setStatus, setFieldValue }) => {
                setStatus();
                if(step === 1){
                    setFieldValue('step', step + 1, false);
                }else{
                    return publishIdentityAddress(userData.Username, userData.addresses[0].identity_address_id, {
                        member_ids: members,
                        years_same_address: values.yearsSameAddress.year(),
                        address_type_id: values.addressTypeId,
                    })
                        .then(async() => {
                            await Promise.all(
                                Object.keys(values.documents).reduce((mappedDocuments, documentTypeId) => {
                                    for(let index = 0; index < values.documents[documentTypeId].length; index++){
                                        const document = values.documents[documentTypeId][index];
                                        mappedDocuments.pushArray(members.map((memberId) => createMemberAttachments(memberId, { attachment: document, document_type_id: documentTypeId })));
                                    }
                                    return mappedDocuments;
                                }, []),
                            )
                            seeNextMembers();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }
                        });
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={isValidating || formik.isSubmitting}>
                        <ModalHeader toggle={toggleModal}>
                            <Translate id='account.settings.profile.accountAddress.step2.title' />
                        </ModalHeader>
                        <CrossFade isVisible={formik.values.step === 1}>
                            <ModalBody>
                                <div className='text-center mb-3'>
                                    <Translate id='account.settings.profile.accountAddress.step2.description1' values={{ count: showPublishMembers.length }} />
                                    <div className='font-medium'>
                                        <DisplayI18n field='name' defaultValue={showPublishMembers[0].members[0].organisation.organisation_name} i18n={showPublishMembers[0].members[0].organisation.i18n} />
                                    </div>
                                </div>
                                <div className='text-center mb-3'>
                                    <Translate id='account.settings.profile.accountAddress.step2.description' />
                                    <div className='font-medium'>
                                        {getAddressString()}
                                    </div>
                                </div>
                                {showPublishMembers?.map?.(({ members, ...metaMember }) => {
                                    const name = `${metaMember.first_name} ${metaMember.last_name}`;
                                    const valueIndex = formik.values.members.indexOf(members[0].member_id);
                                    const isSelected = valueIndex !== -1;
                                    // TODO: hardcoded
                                    // Don't show the request address change if member is BLOCKED or INELIGIBLE
                                    const canPublishAddressToMember = members[0].member_status?.member_status_id !== '6c38a4a1-73e2-11ec-bed9-023f3d3ef136' && members[0].member_status?.member_status_id !== '8abea6d8-73e2-11ec-bed9-023f3d3ef136';
                                    return (
                                        <UserDisplay
                                            key={metaMember.meta_member_id}
                                            card hover={canPublishAddressToMember}
                                            className={stringBuilder("d-flex position-relative h-100 mb-2", { 'border-primary': isSelected, 'bg-light-inverse': !canPublishAddressToMember })}
                                            onClick={canPublishAddressToMember ? () => {
                                                if(isSelected){
                                                    formik.setFieldValue('members', formik.values.members.filter((m) => m !== members[0].member_id))
                                                }else{
                                                    formik.setFieldValue('members', [ ...formik.values.members, members[0].member_id ]);
                                                }
                                            } : undefined}
                                        >
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={metaMember.first_name[0] + metaMember.last_name[0]}
                                                    src={metaMember.attachment?.full_path}
                                                    filePos={metaMember.attachment?.file_position}
                                                    width={50}
                                                    alt={name}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className="min-w-0">
                                                <UserDisplay.Title className="text-truncate" title={name}>{name}</UserDisplay.Title>
                                                {metaMember.birthDate &&
                                                    <UserDisplay.Subtitle>
                                                        <DateFormat value={metaMember.birthDate} utc format={DateFormat.formats.longMonthDayYear} />
                                                    </UserDisplay.Subtitle>
                                                }
                                                <UserDisplay.Subtitle>
                                                    {metaMember.relation ?
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={metaMember.relation.name}
                                                            i18n={metaMember.relation.i18n}
                                                        />
                                                        :
                                                        <Translate id='form.fields.relation.OTHER' />
                                                    }
                                                </UserDisplay.Subtitle>
                                                <UserDisplay.Subtitle>HCR# {members[0].unique_identifier}</UserDisplay.Subtitle>
                                                {!canPublishAddressToMember &&
                                                    <UserDisplay.Subtitle className='text-danger'><DisplayI18n field='name' defaultValue={members[0].member_status.name} i18n={members[0].member_status.i18n} /></UserDisplay.Subtitle>
                                                }
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className='ml-auto'>
                                                {isSelected ?
                                                    <CustomAnimatedIcon icon='checkmark' className='text-primary' withCircle size={30} strokeWidth='8' />
                                                    :
                                                    <div className='border rounded-circle' style={{ width: 30, height: 30 }} />
                                                }
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                })}
                                <FormikError name='members' />
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='button' outline disabled={formik.isSubmitting} onClick={seeNextMembers}><Translate id='account.settings.profile.accountAddress.step2.skipMembers' values={{ count: showPublishMembers.length }} /></Button>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.next' /><i className='mdi mdi-arrow-right ml-1' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={formik.values.step === 2}>
                            <ModalBody>
                                <button className='reset-btn text-link mb-2' type='button' disabled={formik.isSubmitting} onClick={() => { formik.setFieldValue('step', formik.values.step - 1) }}><i className='mdi mdi-chevron-left' /><Translate id='account.settings.profile.accountAddress.step2.seeMemberSelection' /></button>
                                <Row form>
                                    <Col xs='12' sm='6'>
                                        <FormGroup>
                                            <Label for='yearsSameAddress'>
                                                <ReactTooltip id="move-in" effect="solid" getContent={(tip) => tip && <Translate id={tip} />} />
                                                <Translate id='account.settings.profile.accountAddress.step2.moveIn.label' />
                                                <i className='mx-1 text-primary mdi mdi-information-outline' data-tip='account.settings.profile.accountAddress.step2.moveIn.tip' data-for='move-in' />
                                                <Required />
                                            </Label>
                                            <FormikDateTime
                                                id='yearsSameAddress'
                                                name='yearsSameAddress'
                                                timeFormat={false}
                                                dateFormat='YYYY'
                                                initialViewMode='years'
                                                isValidDate={(date) => date.year() <= new Date().getFullYear()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs='12' sm='6'>
                                        <FormGroup>
                                            <Label for='addressTypeId'>
                                                <Translate id='account.settings.profile.accountAddress.step2.addressType.label' /> <Required />
                                            </Label>
                                            <FormikSelect
                                                id='addressTypeId'
                                                name='addressTypeId'
                                                renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            // TODO: REFACTOR
                                                            return getAddressTypes(federationId)
                                                                .then((addressTypes) => addressTypes.map((addressType) => ({
                                                                    value: addressType.address_type_id,
                                                                    label: addressType.name,
                                                                    i18n: addressType.i18n,
                                                                })));
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {documents?.map((documentType) => (
                                        <Col xs='12' key={documentType.document_type_id}>
                                            <FormGroup>
                                                <Label for={'documentType_' + documentType.document_type_id}><DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} /></Label>
                                                <FileUpload
                                                    id={'documentType_' + documentType.document_type_id}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        maxSize: documentType.file_size ? mbToBytes(parseFloat(documentType.file_size)) : undefined,
                                                        accept: ".jpg, .jpeg, .png, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                    onFileSelect={(files) => {
                                                        formik.setFieldValue(`documents.${documentType.document_type_id}`, files);
                                                    }}
                                                />
                                                <FormikError name={`documents.${documentType.document_type_id}`} />
                                                {documentType.description &&
                                                    <div className='small text-muted'>
                                                        <DisplayI18n field='description' defaultValue={documentType.description} i18n={documentType.i18n} />
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>

                                <Collapse isOpen={!!formik.status} unmountOnExit mountOnEnter>
                                    <Alert color='danger'>{formik.status}</Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='account.settings.profile.accountAddress.step2.submit' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default ChangeAddressModalMembers