import Translate from '@spordle/intl-elements';
import { useEffect } from 'react';
import Img from '../../assets/images/Maintenance.png';
import { getCurrentMaintenanceUrl } from '../../helpers/maintenance';

const Maintenance = () => {
    useEffect(() => {
        const oldTitle = document.title;
        document.title = 'Spordle Maintenance';

        const gStaticLink = document.createElement('link');
        gStaticLink.href = "https://fonts.gstatic.com";
        gStaticLink.rel = 'preconnect';
        const googleFontsLink = document.createElement('link');
        googleFontsLink.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap";
        googleFontsLink.rel = 'stylesheet';

        document.head.appendChild(gStaticLink);
        document.head.appendChild(googleFontsLink);

        const timeout = setTimeout(() => {
            window.location.href = getCurrentMaintenanceUrl();
        }, 60000);

        return () => {
            document.title = oldTitle;
            gStaticLink.remove();
            googleFontsLink.remove();
            clearTimeout(timeout);
        }
    }, []);

    return (
        <main className="maintenance-container">
            <div className="maintenance-container-inner">
                <img src={Img} alt="Website in maintenance" width="300" />
                <div className="text-center px-2 w-sm-75 w-lg-50 mx-auto">
                    <div className="maintenance-title font-medium h1 mb-4 mt-5">
                        <Translate id="maintenance.title" />
                    </div>
                    <div className="font-light text-body w-lg-75 h2 d-inline-block"><Translate id="maintenance.msg" /></div>
                </div>
            </div>
        </main>
    )
}

export default Maintenance;