import { useContext, useState } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { SupervisionsContext } from "../../../../../contexts/SupervisionsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { fail, success } from "@spordle/toasts";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SupervisionSidePanelSupervisionType from "./tabs/SupervisionSidePanelSupervisionType";
import SupervisionSidePanelNotes from "./tabs/SupervisionSidePanelNotes";
import SupervisionSidePanelInfo from "./tabs/SupervisionSidePanelInfo";
import { stringBuilder } from "@spordle/helpers";
import AllOfficialReportsModal from "../supervisionsOfficiatingCoach/allOfficialReportsModal/AllOfficialReportsModal";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";

const AccountMemberCoachSidePanel = ({ selectedRows, createNewValues, syncRows, forceCloseSidePanel, tableRef, mode = "officiatingCoach" }) => {
    const { partiallyUpdateSupervision, deleteSupervision, sendSupervisionEmail } = useContext(SupervisionsContext);
    const isOfficiatingCoach = mode === "officiatingCoach";
    const supervision = selectedRows[0];
    const [ isLoading, setIsLoading ] = useState(false);
    const [ currentTab, setCurrentTab ] = useState("1");
    const [ allReportsModalIsOpen, setAllReportsModalIsOpen ] = useState(false);

    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            setIsLoading(false);
            fail({ info: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />, skipInfoTranslate: true });
        }
    }

    const updateSupervisionReport = (apiVal, rowVal) => {
        setIsLoading(true);

        partiallyUpdateSupervision(supervision.supervision_id, apiVal)
            .then(() => {
                setIsLoading(false);
                success();
                syncRows(createNewValues(rowVal || apiVal))
            })
            .catch(handleCatch)
    }

    const handleOnSendEmail = () => {
        setIsLoading(true);
        sendSupervisionEmail(supervision.supervision_id)
            .then(() => {
                setIsLoading(false);
                success();
                syncRows(createNewValues({ sent_date: moment().toISOString() }))
            })
            .catch(handleCatch)
    }

    const deleteSupervisionReport = () => {
        return deleteSupervision(supervision.supervision_id)
            .then(() => {
                setIsLoading(false);
                success();
                tableRef.deleteRow(supervision.supervision_id).then(forceCloseSidePanel);
            })
            .catch(handleCatch)
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <AllOfficialReportsModal member={supervision.official} isOpen={allReportsModalIsOpen} toggle={() => setAllReportsModalIsOpen(false)} />
            <SidePanel.Header noBorder>
                <div className="mb-3 d-flex align-items-center">
                    <SidePanel.ToggleButton />
                    {isOfficiatingCoach &&
                        <SidePanel.ActionsMenu className="ml-auto">
                            <SidePanel.MenuAction onClick={() => setAllReportsModalIsOpen(true)}>
                                <Translate id='account.members.coach.allReportsBtn.long' />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction onClick={handleOnSendEmail}>
                                <Translate id="account.members.officiatingCoach.sendEmail" />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction divider />
                            <SidePanel.MenuDelete
                                modalMsg='account.members.coach.sidePanel.delete.msg'
                                translateModalMsg
                                onConfirm={deleteSupervisionReport}
                                modalContent={
                                    <>
                                        <div className="text-muted">({supervision.supervision_date})</div>
                                        <div className="font-bold h4">
                                            <DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} />
                                        </div>
                                        <UserDisplay card block className="mb-0">
                                            <UserDisplay.Container>
                                                <UserImg
                                                    width={45}
                                                    className="text-uppercase"
                                                    alt={`${supervision.official.first_name} ${supervision.official.last_name}`}
                                                    abbr={`${supervision.official.first_name.charAt(0)}${supervision.official.last_name.charAt(0)}`}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{supervision.official.first_name} {supervision.official.last_name}</UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <HoverableCopy toCopy={supervision.official.unique_identifier}>
                                                        #{supervision.official.unique_identifier}
                                                    </HoverableCopy>
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </>
                                }
                            />
                        </SidePanel.ActionsMenu>
                    }
                </div>
                <SidePanel.Subtitle>
                    <DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} /> ({supervision.supervision_date})
                </SidePanel.Subtitle>
                <SidePanel.Title>
                    <Translate id='account.members.coach.supervisionReport.label' />
                </SidePanel.Title>
                {supervision.sent_date && isOfficiatingCoach && <SidePanel.Subtitle className="small text-muted"><Translate id="account.members.coach.lastSent" />: <DateFormat format="YYYY-MM-DD HH:mm" value={supervision.sent_date} /></SidePanel.Subtitle>}
                <UserDisplay className="mb-0 flex-wrap flex-sm-nowrap mt-3" block>
                    {isOfficiatingCoach ?
                        <>
                            <UserDisplay.Container>
                                <UserImg
                                    width={45}
                                    className="text-uppercase"
                                    alt={`${supervision.official.first_name} ${supervision.official.last_name}`}
                                    abbr={`${supervision.official.first_name.charAt(0)}${supervision.official.last_name.charAt(0)}`}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title>{supervision.official.first_name} {supervision.official.last_name}</UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <HoverableCopy toCopy={supervision.official.unique_identifier}>
                                        #{supervision.official.unique_identifier}
                                    </HoverableCopy>
                                </UserDisplay.Subtitle>
                                {supervision.official.member_qualification &&
                                    <UserDisplay.Subtitle>
                                        <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification.name} i18n={supervision.official.member_qualification.qualification.i18n} />{(supervision.official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification_level.name} i18n={supervision.official.member_qualification.qualification_level.i18n} /></>}
                                    </UserDisplay.Subtitle>
                                }
                            </UserDisplay.Container>
                            <UserDisplay.Container className="ml-auto mt-2 mt-sm-0 w-100 w-sm-auto" onClick={() => setAllReportsModalIsOpen(true)}>
                                <Button className="border" size="sm" type="button" color="light">
                                    <Translate id='account.members.coach.allReportsBtn.short' />
                                </Button>
                            </UserDisplay.Container>
                        </>
                        :
                        <>
                            <UserDisplay.Container>
                                <UserImg
                                    width={45}
                                    className="text-uppercase"
                                    alt={`${supervision.officiating_coach.first_name} ${supervision.officiating_coach.last_name}`}
                                    abbr={`${supervision.officiating_coach.first_name.charAt(0)}${supervision.officiating_coach.last_name.charAt(0)}`}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Subtitle><Translate id='account.members.coach.officiatingCoach.label' /></UserDisplay.Subtitle>
                                <UserDisplay.Title>{supervision.officiating_coach.first_name} {supervision.officiating_coach.last_name}</UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <HoverableCopy toCopy={supervision.officiating_coach.unique_identifier}>
                                        #{supervision.officiating_coach.unique_identifier}
                                    </HoverableCopy>
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </>

                    }
                </UserDisplay>
            </SidePanel.Header>
            <Nav className="is-full text-center" tabs>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab("1")} className={stringBuilder({ "active": currentTab === "1" })}>
                        <Translate id="account.members.coach.sidePanel.tab.info" />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab("2")} className={stringBuilder({ "active": currentTab === "2" })}>
                        <Translate id="account.members.coach.sidePanel.tab.type" />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab("3")} className={stringBuilder({ "active": currentTab === "3" })}>
                        <Translate id="account.members.coach.sidePanel.tab.notes" />
                    </NavLink>
                </NavItem>
            </Nav>
            <SidePanel.Body>
                <TabContent activeTab={currentTab}>
                    <TabPane tabId="1">
                        <SupervisionSidePanelInfo isReadOnly={!isOfficiatingCoach} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                    <TabPane tabId="2">
                        <SupervisionSidePanelSupervisionType isReadOnly={!isOfficiatingCoach} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                    <TabPane tabId="3">
                        <SupervisionSidePanelNotes isReadOnly={!isOfficiatingCoach} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                </TabContent>
            </SidePanel.Body>
        </OverlayLoader>
    );
}

export default AccountMemberCoachSidePanel;