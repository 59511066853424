export default{
    "header.home"           : "Accueil",
    "header.account"        : "Mon Compte",
    "header.settings"       : "Paramètres",
    "header.myProfile"      : "Mon Profil",
    "header.signout"        : "Déconnexion",
    "header.supportRequest" : "Mes Demandes de Soutien",

    // Notifications
    "header.notifications.title"               : "Notifications",
    "header.notifications.empty.title"         : "Notifications à jour",
    "header.notifications.empty.text"          : "Vous n'avez aucune nouvelle notification.",
    "header.notifications.tabs.supportRequest" : "Support",
    "header.notifications.tabs.organization"   : "Organisation",
    "header.notifications.btn.clearAll"        : "Tout marquer comme lu",
    "header.notifications.btn.deleteAll"       : "Tout supprimer",
    "header.notifications.communication.toMe"  : "À moi",
    "header.notifications.item.title"          : "Demande de support",
    "header.notifications.item.tip.clear"      : "Supprimer la notification",
    "header.notifications.item.tip.read"       : "Marquer comme vue",
    "header.notifications.item.unread"         : "{count, plural, =0 {# message non lu} one {# message non lu} other {# messages non lus}}",
    "header.notifications.preinitInvoice.text" : "Vous avez une nouvelle inscription ! Vous pouvez payer à partir de l'onglet méthodes de paiement.",
    // Viewheader
    "header.viewHeader.uat"                    : "Vous êtes présentement en <b>UAT</b>",
}