import { useContext } from 'react';
import moment from 'moment';
import { ScheduleContext } from '../Schedule';
import Translate from '@spordle/intl-elements';

/**
 * @description Displays monthly calendar.
 *
 * @param {string} [className] className
 * @param {boolean} [shortDays=false] Label of weekdays will be short
 * @param {boolean} [showEvent=true] Shows Events as dots
 * @returns {JSX.Element}
 */
const ScheduleMonthly = ({ className, shortDays = false, showEvents = true }) => {
    const {
        events,
        today,
        calendar,
        week,
        selected,
        setNewDate,
    } = useContext(ScheduleContext);

    return (
        (calendar ?
            <div className={`schedule-monthly-calendar-container${className ? ' ' + className : ''}`}>
                <table className="schedule-monthly-calendar">
                    <thead>
                        <tr className="schedule-monthly-days-container">
                            {
                                week.map((day, i) => (
                                    <th key={day} id={`schedule-monthly-${i}`} className="schedule-monthly-days">
                                        { shortDays ?
                                            <span>{day.format('dd')}</span>
                                            :
                                            <>
                                                <span className="d-none d-xl-inline">{day.format('dddd')}</span>
                                                <span className="d-inline d-xl-none">{moment.weekdaysMin(day)}</span>
                                            </>
                                        }
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            calendar?.map((week, indexWeek) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr className="schedule-monthly-week" key={`monthly-week-${indexWeek}`}>
                                    {
                                        week?.days?.map((weekDay, indexDay) => {
                                            const selectedFormatted = selected?.selected?.format('L');
                                            const day = weekDay.format('D');
                                            const month = weekDay.format('MM');
                                            const year = weekDay.format('YYYY');
                                            const isSelected = selectedFormatted === weekDay.format('L');
                                            const isToday = today?.format('L') === weekDay.format('L');
                                            const isThisMonth = weekDay.month() === selected?.month;

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <td className={`schedule-monthly-item${isToday ? ' is-today' : ''}${isSelected ? ' is-selected' : ''}`} key={`monthly-day-${indexWeek}-${day}`}>
                                                    <div className="schedule-monthly-item-inner" onClick={() => setNewDate(weekDay)}>
                                                        <time dateTime={weekDay.format('YYYY-MM-DD')} className={`schedule-monthly-nb${isThisMonth ? '' : ' is-not-current-month'}`}>{day}</time>
                                                        {
                                                            showEvents && events?.[year]?.[month]?.[day] &&
                                                            <div className="schedule-monthly-events">
                                                                {
                                                                    events[year][month][day].map((event, i) => (
                                                                        i < 3 && <div key={`month-dot-${event.id}`} className={`schedule-monthly-dot bg-primary`} />
                                                                    ))
                                                                }
                                                                { events[year][month][day].length > 3 && <div className={`schedule-monthly-plus`}>+</div>}
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            :
            <div>
                <div className="h4 font-bold"><Translate id='schedule.error.title' /></div>
                <p>
                    <Translate id='schedule.error.message' />
                </p>
            </div>
        )
    );
}

export default ScheduleMonthly;