import Translate from "@spordle/intl-elements";
import { useHistory } from "react-router-dom"
import ConfirmModal from "../../confirmModal/ConfirmModal";

const DeclinedPaymentModal = () => {
    const history = useHistory();

    return (
        <ConfirmModal
            hideCancel defaultIsOpen hideToggler
            modalTitle={<div className="h1 font-bold text-danger"><Translate id='failedPaymentModal.modal.title' /></div>}
            modalContent={
                <>
                    <p className="font-bold">
                        <Translate id='failedPaymentModal.modal.subtitle' />
                    </p>
                    <p className="mb-0">
                        <Translate id='failedPaymentModal.modal.text' />
                    </p>
                </>
            }
            actionButtonText={<><Translate id='failedPaymentModal.modal.btn' /> <i className="ml-1 mdi mdi-arrow-right" /></>}
            onConfirm={() => history.push('/payment')}
        />
    )
}

export default DeclinedPaymentModal;