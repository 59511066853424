import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Collapse } from "reactstrap";
import { ScheduleContext } from "../Schedule";
import SharedEvent from "./SharedEvent";

const SharedEvents = () => {
    const { week, getSharedEvents, events, onEventClick } = useContext(ScheduleContext);
    const [ isOpen ] = useState(false);
    const [ sharedEvents, setSharedEvents ] = useState([]);

    function formatSharedEvents(){
        const shared = getSharedEvents(week[0], week[week.length - 1]);
        const sharedE = [];

        for(let s = 0; s < shared.length; s++){
            const sE = shared[s];
            const sStart = moment(sE.start_date);
            const sEnd = moment(sE.end_date);
            const isContinuing = sStart.isBefore(week[0]);

            sharedE.push(
                <SharedEvent
                    key={`shared-${sE.id}`}
                    start={isContinuing ? 0 : week.findIndex((d) => d.isSame(sStart, 'day'))}
                    onClick={() => onEventClick && onEventClick(sE)}
                    duration={Math.abs((isContinuing ? week[0] : sStart).diff(sEnd, 'days', false)) + 1}
                    isExceeding={sEnd.isAfter(week[week.length - 1])}
                    isContinuing={isContinuing}
                    dependency={week}
                >
                    <div data-for={`tooltip-event`} data-tip={s}>
                        {sE.title}
                    </div>
                </SharedEvent>,
            )

        }

        return sharedE;
    }

    useEffect(() => {
        setSharedEvents(formatSharedEvents());
    }, [ week, events ]);

    return (
        sharedEvents && sharedEvents.length > 0 &&
            <>
                {sharedEvents.length > 3 ?
                    <div className="d-flex">
                        <div className={`schedule-weekly-shared-inner ${isOpen ? 'is-open' : 'is-closed'}`}>
                            <div className={`schedule-weekly-shared-days border-bottom-0 pb-0`}>
                                {sharedEvents.slice(0, 3)}
                            </div>
                            <Collapse isOpen={isOpen}>
                                <div className="schedule-weekly-shared-days border-bottom-0 pt-0">
                                    {sharedEvents.slice(3, sharedEvents.length)}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    :
                    <div className="d-flex schedule-weekly-shared-inner border-bottom-0">
                        <div className="schedule-weekly-shared-days w-100">
                            {sharedEvents}
                        </div>
                    </div>
                }
                <ReactTooltip
                    place="top"
                    id={`tooltip-event`}
                    getContent={(i) => {
                        const sE = getSharedEvents(week[0], week[week.length - 1])[i];
                        if(i && sE){
                            return (
                                <div>
                                    <span className="font-bold">{sE.title}</span>
                                    <div><span className="font-medium"><Translate id='schedule.label.time.starts' /></span>: {moment(sE.start_date).format('YYYY-MM-DD')}</div>
                                    <div><span className="font-medium"><Translate id='schedule.label.time.ends' /></span>: {moment(sE.end_date).format('YYYY-MM-DD')}</div>
                                </div>
                            )
                        }
                    }}
                />
            </>
    )
}

export default SharedEvents;