import { useState } from "react";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanelAttendeeInfo from "./sidePanelComponents/SidePanelAttendeeInfo";
import SidePanelAttendeeMulti from "./sidePanelComponents/SidePanelAttendeeMulti";

const SidePanelAttendees = ({ exportAttendees, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            {props.selectedRows.length > 1 ?
                <SidePanelAttendeeMulti {...props} setLoading={(loading) => setIsLoading(loading)} />
                :
                <SidePanelAttendeeInfo {...props} setLoading={(loading) => setIsLoading(loading)} />
            }
        </OverlayLoader>
    );
}

export default SidePanelAttendees;