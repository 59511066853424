import React from 'react';
import { Link } from 'react-router-dom';
import {
    FormGroup,
    Fade,
    Spinner,
    Collapse,
    Button
} from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Contexts
import { I18nContext } from '../../contexts/I18nContext';
import { AuthContext } from '../../contexts/contexts';

// Language
import Translate from '@spordle/intl-elements';
import { LOCALES } from '../../i18n';

// Components
import AuthHeader from '../../components/tour/Authheader';
import { FormikInputText } from '@spordle/formik-elements';
import CapsLock from '@spordle/capslock';
import AntiSpam from '@spordle/anti-spam';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import GoToHomeBtn from '../../components/GoToHomeBtn';
import WithTourTheme from '../../components/HOC/WithTournamentTheme';
import { fire, success } from '@spordle/toasts';

class Signup extends React.Component{
    static contextType = AuthContext;

    state = {
        toggleDisplay: this.props.location.needsConfirmation ? 'confirmed' : 'signup', //signup, loading, error, confirmed
        displayData: this.props.location.needsConfirmation ? this.props.location.email : '', // data used for display, ex: email, error msg
    }

    sendConfirmationEmail = (email) => {
        this.context.resendEmail(email)
            .then((_response) => {
                success({ msg: 'forgotPassword.success.emailSent.toast' });
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message))
                    console.error(error.message)
            })
    }

    toggleDisplay = (display, data = null) => {
        switch (display){
            case "signup":
            case "error":
            case "confirmed":
            case "loading":
                this.setState({ displayData: data, toggleDisplay: display });
                break;

            default:
                console.error("toggleDisplay() accepts only 'signup', 'loading', 'error', 'confirmed");
                this.setState({ toggleDisplay: "signup" });
                break;
        }
    };

    componentDidMount(){
        if(this.props.location.needsConfirmation)
            this.sendConfirmationEmail(this.props.location.email);
    }

    componentWillUnmount(){
        AxiosCancelAll();

        if(this.emailTimeOut){
            clearTimeout(this.emailTimeOut);
            this.emailTimeOut = 0;
        }
        if(this.lazyLoadTimeOut){
            clearTimeout(this.lazyLoadTimeOut);
            this.lazyLoadTimeOut = 0;
        }
    }

    render(){
        return (
            <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                    <GoToHomeBtn />
                    <I18nContext.Consumer>
                        {(i18n) => {
                            const { locale, setLocale } = i18n;
                            return (
                                <>
                                    {(locale === 'en-ca') ?
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.FRENCH) }}>FR</Button></>
                                        :
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.ENGLISH) }}>EN</Button></>
                                    }
                                </>
                            )
                        }}
                    </I18nContext.Consumer>
                </div>
                <AuthHeader />

                <div className="auth-box my-0 mb-5">
                    <Fade>
                        <div className="ml-n2 mt-n2">
                            <Link to="/login" className="d-inline-block mb-2 text-dark">
                                <i className="mdi mdi-chevron-left" /><Translate id='signup.label.backToLogin' />
                            </Link>
                        </div>
                        <AuthContext.Consumer>
                            {(auth) => {
                                const { register } = auth;
                                return (
                                    <>
                                        {(this.state.toggleDisplay === 'signup') ?
                                            <Formik
                                                initialValues={{
                                                    first_name: '',
                                                    last_name: '',
                                                    email: '',
                                                    password_signup: '',
                                                    password_confirm: '',
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    first_name: Yup.string().required(<Translate id='signup.signupForm.validation.firstName.required' />),
                                                    last_name: Yup.string().required(<Translate id='signup.signupForm.validation.lastName.required' />),
                                                    email: Yup.string()
                                                        .required(<Translate id='signup.signupForm.validation.email.required' />)
                                                        .email(<Translate id='signup.signupForm.validation.email.email' />),
                                                    password_signup: Yup.string()
                                                        .required(<Translate id='login.form.validation.password.required' />)
                                                        .matches(/[^\s-]/, { message: <Translate id='signup.signupForm.validation.password.space' /> })
                                                        .matches(/(?=.*[0-9])/, { message: <Translate id='signup.signupForm.validation.password.number' /> })
                                                        .matches(/(?=.*[a-z, A-Z])/, { message: <Translate id='signup.signupForm.validation.password.letter' /> })
                                                        .matches(/(?=.*[A-Z])/, { message: <Translate id='signup.signupForm.validation.password.uppercase' /> })
                                                        .min(6, <Translate id='signup.signupForm.validation.password.lenght' />),
                                                    password_confirm: Yup.string()
                                                        .test('password_signup-match', <Translate id='signup.signupForm.validation.passwordConfirm.match' />, function(value){
                                                            return this.parent.password_signup === value;
                                                        }),
                                                })}
                                                onSubmit={(values, { setStatus }) => {
                                                    setStatus();
                                                    register(values.first_name, values.last_name, values.email, values.password_signup)
                                                        .then(() => this.toggleDisplay("confirmed", values.email))
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message))
                                                                this.toggleDisplay("error", error.message)
                                                        });

                                                    // -- LASY LOAD MAISON --
                                                    this.lazyLoadTimeOut = setTimeout(() => {
                                                        if(this.state.toggleDisplay !== "error" && this.state.toggleDisplay !== "confirmed"){
                                                            this.toggleDisplay("loading");
                                                            this.lazyLoadTimeOut = 0;
                                                        }
                                                    }, 500);// .5 secondes
                                                }}
                                            >
                                                {(signupFormik) => (
                                                    <>
                                                        <h2 className="h2 font-medium mb-3"><Translate id='signup.label.title' /></h2>
                                                        <Form className="mt-3" id="signup_form">
                                                            <FormGroup>
                                                                <FormikInputText
                                                                    label="signup.signupForm.label.firstName"
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    type="text"
                                                                    trim
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormikInputText
                                                                    label="signup.signupForm.label.lastName"
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    type="text"
                                                                    trim
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormikInputText
                                                                    label="signup.signupForm.label.email"
                                                                    id="email"
                                                                    name="email"
                                                                    type="text"
                                                                    trim
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormikInputText
                                                                    label="signup.signupForm.label.password"
                                                                    id="password_signup"
                                                                    name="password_signup"
                                                                    type="password"
                                                                    trim
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormikInputText
                                                                    label="signup.signupForm.label.passwordConfirm"
                                                                    id="password_confirm"
                                                                    name="password_confirm"
                                                                    type="password"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <button type="submit" className="btn btn-block btn-primary" disabled={signupFormik.isSubmitting}><Translate id='signup.action.signup' /></button>
                                                            </FormGroup>

                                                            <CapsLock>
                                                                {(isActive) => (
                                                                    <Collapse isOpen={isActive}>
                                                                        <div className={'alert alert-warning mb-0'}><i className="mr-1 mdi mdi-apple-keyboard-caps" /><Translate id='warning.capsLock' /></div>
                                                                    </Collapse>
                                                                )}
                                                            </CapsLock>

                                                        </Form>
                                                    </>
                                                )}
                                            </Formik>
                                            : ""}
                                        {(this.state.toggleDisplay === 'loading') ?
                                            <Fade in={this.state.toggleDisplay === 'loading'}>
                                                <div className="text-center mb-4 mt-2">
                                                    <div className="mb-4">
                                                        <Spinner color="info" style={{ width: '80px', height: '80px' }} type="grow" />
                                                    </div>
                                                    <div className="h2 font-medium mb-3"><Translate id='signup.loading.title' /></div>
                                                    <p><Translate id='signup.loading.description' /></p>
                                                </div>
                                            </Fade>
                                            : ""}
                                        {(this.state.toggleDisplay === 'error') ?
                                            <Fade in={this.state.toggleDisplay === 'error'}>
                                                <div className="text-center">
                                                    <div>
                                                        <i className="mdi mdi-alert-circle text-danger" style={{ fontSize: 60 }} /> <br />
                                                        <h2 className="h4 font-medium mb-3">
                                                            <Translate id={`signup.error.title.${this.state.displayData}`} />
                                                        </h2>
                                                    </div>
                                                    <p className="mb-3">
                                                        {/* detail de l'erreur */}
                                                        <Translate id={`signup.error.${this.state.displayData}`} />
                                                    </p>
                                                    <Button color="primary" onClick={() => this.toggleDisplay("signup")}><Translate id='signup.error.action' /></Button>
                                                </div>
                                            </Fade>
                                            : ""}
                                        {(this.state.toggleDisplay === 'confirmed') ?
                                            <Fade in={this.state.toggleDisplay === 'confirmed'}>
                                                <div className="">
                                                    <h2 className="h2 mb-3 font-medium text-success"><Translate id='signup.confirmed.title' /></h2>
                                                    <p className="mb-4"><Translate id='signup.confirmed.description' /></p>
                                                    <div className="border-top pt-3">
                                                        <span><Translate id='signup.confirmed.notReceived' /></span>
                                                        <AntiSpam
                                                            timeoutDuration={10000}
                                                            clicksForTimeout={2}
                                                            resetClicksDuration={30000}
                                                            isSpamming={() => {
                                                                fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                                                            }}
                                                        >
                                                            {(_, antiSpamCallback) => (
                                                                <button
                                                                    id="resendEmailButton"
                                                                    onClick={(e) => {
                                                                        antiSpamCallback(e);
                                                                        this.sendConfirmationEmail(this.state.displayData);
                                                                    }}
                                                                    type="button"
                                                                    className="ml-1 reset-btn text-link"
                                                                >
                                                                    <Translate id='signup.confirmed.resend' />
                                                                </button>
                                                            )}
                                                        </AntiSpam>
                                                    </div>
                                                </div>
                                            </Fade>
                                            : ""}
                                    </>

                                )
                            }}
                        </AuthContext.Consumer>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default WithTourTheme(Signup);
