import { useEffect, useState } from "react";

/**
 * Checks if a given html element is visible in the DOM. It takes into consideration the view port(between the top and bottom of the visible web page) and the visibility of the element(display: none - visibility: hidden)
 * @param {HTMLElement} ref
 * @returns {boolean} If the given html element is visible in the DOM
 * @hook
 */
export default function useIsVisible(ref){
    const [ isVisible, setIsVisible ] = useState(false);

    useEffect(() => {
        if(ref){
            const top = ref.getBoundingClientRect().top;
            setIsVisible(top >= 0 && top <= window.innerHeight);
        }else{
            setIsVisible(false);
        }
    });

    return isVisible
}