/*
@Copyrights Spordle 2023 - All rights reserved
*/
/**
 * Function to append the given extension to the phone number
 * @param {string|undefined} phoneString The phone-like string
 * @param {string} [extension] The extension to append
 * @returns {Promise.<string|undefined>}
 * @example
 * const phoneNumberWithExtension = await addExtension(phone, extension);
 * @copyright Spordle
 */
async function addExtension(phoneString, extension) {
    const phoneNumber = await formatPhoneNumber(phoneString, 'E164', false);
    if (phoneNumber) {
        if (extension) {
            return phoneNumber + '#' + extension;
        }
        return phoneNumber;
    }
    return phoneString;
}
/**
 * Validates if the given number is a valid number or not
 * @param {string|undefined} phoneString The phone-like string
 * @returns {Promise.<boolean>}
 * @example
 * const isValidPhoneNumber = await isValidPhoneNumber(phoneString);
 * @copyright Spordle
 */
async function isValidPhoneNumber(phoneString) {
    try {
        const { PhoneNumberUtil } = await import('google-libphonenumber');
        const phoneUtil = PhoneNumberUtil.getInstance();
        const parsedNumber = phoneUtil.parse(phoneString);
        return phoneUtil.isValidNumberForRegion(parsedNumber, phoneUtil.getRegionCodeForNumber(parsedNumber));
    }
    catch (error) {
        return false;
    }
}
/**
 * Parses the number to give a `libphonenumber.PhoneNumber` instance.
 * @param {string|undefined} phoneString The phone-like string
 * @returns {Promise.<libphonenumber.PhoneNumber|undefined>}
 * @example
 * const parsedNumber = await getParsedNumber(phoneString);
 * @see https://www.npmjs.com/package/google-libphonenumber
 * @copyright Spordle
 */
async function getParsedNumber(phoneString) {
    if (await isValidPhoneNumber(phoneString)) {
        const { PhoneNumberUtil } = await import('google-libphonenumber');
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil.parse(phoneString);
    }
}
/**
 * Formats the given string number to {@link https://datatracker.ietf.org/doc/html/rfc3966|RFC3966} standards
 * @param {string|undefined} phoneString The phone-like string
 * @returns {Promise.<string>} The {@link https://datatracker.ietf.org/doc/html/rfc3966|RFC3966} formatted string
 * @example
 * const telNumber = await getTelHref(phoneString)
 * @copyright Spordle
 */
async function getTelHref(phoneString) {
    // Can't use extension -> https://stackoverflow.com/a/67810744
    return `${await formatPhoneNumber(phoneString, 'RFC3966', false) || ''}`;
}
/**
 * Formats the given string number to {@link RFC3966} standards
 * @param {string|undefined} phoneString The phone-like string
 * @param {PhoneNumberFormats} format
 * @param {boolean} [withExtension=true] Format using the extension or not
 * @example
 * const formattedPhoneString = formatPhoneNumber(phoneString, 'E164');
 * const formattedPhoneString = formatPhoneNumber(phoneString, 'RFC3966', false);
 * const formattedPhoneString = formatPhoneNumber(phoneString, 'INTERNATIONAL');
 * const formattedPhoneString = formatPhoneNumber(phoneString, 'NATIONAL', true);
 * @returns {Promise.<string|undefined>}
 * @copyright Spordle
 */
async function formatPhoneNumber(phoneString, format, withExtension = true) {
    const parsedNumber = await getParsedNumber(phoneString);
    if (parsedNumber) {
        const { PhoneNumberUtil, PhoneNumberFormat } = await import('google-libphonenumber');
        if (!withExtension)
            parsedNumber.clearExtension();
        return PhoneNumberUtil.getInstance().format(parsedNumber, PhoneNumberFormat[format]);
    }
    return phoneString;
}export{addExtension,formatPhoneNumber,getParsedNumber,getTelHref,isValidPhoneNumber};
/*
@Copyrights Spordle 2023 - All rights reserved
*/