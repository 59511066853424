/* eslint-disable react/no-unused-class-component-methods */
import Translate from '@spordle/intl-elements';
import React, { createContext } from 'react';
import { ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import withContexts from '../../../../../../helpers/withContexts';
import TravelPermitAdd1 from './TravelPermitAdd1';
import TravelPermitAdd2 from './TravelPermitAdd2';
import TravelPermitAdd3 from './TravelPermitAdd3';

/** @type {React.Context<Omit<TravelPermitAddContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const TravelPermitAddContext = createContext()
TravelPermitAddContext.displayName = 'TravelPermitAddContext';

class TravelPermitAddContextProvider extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            isLoading: false,
            view: 1,
            formData: {},
            // eslint-disable-next-line react/no-unused-state
            settings: false,
        }
    }

    setData = (_data) => this.setState((prevState) => ({ formData: { ...prevState.formData, ..._data } }))

    clearData = () => this.setState(() => ({ formData: {} }))

    getTeamOrganizationSettings = (orgId) => {
        return this.props.OrganizationContext.getOrganizationSettings(orgId)
            .then((settings) => {
                // eslint-disable-next-line react/no-unused-state
                this.setState(() => ({ settings: settings }))
                return settings;
            })
            .catch(console.error)
    }

    render(){
        return (
            <TravelPermitAddContext.Provider value={{ ...this }}>
                <AnalyticsModal
                    analyticsName='TravelPermitAddContextProvider'
                    isOpen={this.props.isOpen}
                    onOpened={() => {
                        this.setState(() => ({ view: 1 }))
                        this.clearData();
                    }}
                    onClosed={() => {
                        this.clearData()
                    }}
                >
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <ModalHeader tag='div' toggle={this.props.toggle}>
                            <Translate id='account.members.travelPermits.modalTitle' />
                        </ModalHeader>
                        {this.state.view === 1 &&
                            <TravelPermitAdd1 next={() => this.setState(() => ({ view: 2 }))} toggle={this.props.toggle} member={this.props.member} />
                        }
                        {this.state.view === 2 &&
                            <TravelPermitAdd2 next={() => this.setState(() => ({ view: 3 }))} previous={() => this.setState(() => ({ view: 1 }))} toggle={this.props.toggle} />
                        }
                        {this.state.view === 3 &&
                            <TravelPermitAdd3 toggle={this.props.toggle} />
                        }
                    </OverlayLoader>
                </AnalyticsModal>
            </TravelPermitAddContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(TravelPermitAddContextProvider);