import { Component } from 'react';

import API from "../api/API";
import API_SPORDLE from '../api/API-Spordle';
import API_PUBLIC from '../api/API-Public';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import queryString from 'query-string';

import { AuthContext } from './contexts'
import { serverError } from '../api/CancellableAPI';

import moment from 'moment';

import images from '@spordle/ui-kit';
import { setUser } from '@sentry/react';
import { sendGAEvent, setGAUser, setGAUserProperties } from '@spordle/ga4';
import API_SPORDLE_SQ from '../api/API-Spordle-SQ';
import API_SPORDLE_BQ from '../api/API-Spordle-BQ';
import { removeSessionStorageItem } from '../helpers/browserStorage';
import { MULTISPORT_SESSION_STORAGE_KEY } from '../helpers/getReferer';
const noProfileLogo = images.noprofile;

class AuthContextProvider extends Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {
            // Spordle Accounts (cognito)
            type: null,
            accessToken: null,
            userData: null,
            userMFASettings: null,
        }
        this.state = this._initState;
    }

    componentDidUpdate(){
        if(this.state.userData){
            const attributes = { ...this.state.userData.UserAttributes };
            delete attributes.logo;
            setUser({
                email: this.state.userData.UserAttributes?.email,
                id: this.state.userData.Username,
                username: this.state.userData.Username,
                mfaSettings: this.state.userData.UserMFASettingList,
                attributes: attributes,
            });
            setGAUser(this.state.userData.Username);
            setGAUserProperties(attributes)
        }else{
            setUser(null);
            setGAUser(null);
            setGAUserProperties(null);
        }
    }

    /**
     * Check if the user exists
     * @param {string} email The email to check
     * @returns {Promise}
     */
    verifyEmailExistance = (email) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/authentication/users/list',
            query: {
                filter_name: 'email',
                filter_value: email,
                filter_type: '=',
            },
        }, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(response.data.result.Users.length !== 0){
                        // Cognito UserStatus -> https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html
                        switch (response.data.result.Users[0].UserStatus){
                            case 'UNCONFIRMED':
                            case 'COMPROMISED':
                            case 'RESET_REQUIRED':
                                throw new Error(response.data.result.Users[0].UserStatus);
                            case 'FORCE_CHANGE_PASSWORD':
                            default:
                                return {
                                    name: response.data.result.Users[0].Attributes.find((attribute) => attribute.Name === 'name')?.Value,
                                    userName: response.data.result.Users[0].Username,
                                    mustReactivate: response.data.result.Users[0].UserStatus === 'FORCE_CHANGE_PASSWORD' && moment().diff(response.data.result.Users[0].UserLastModifiedDate, 'days', true) >= 6.9,
                                    userAttributes: response.data.result.Users[0].Attributes,
                                    isLocked: !!response.data.result.Users[0].signin_lock_date,
                                    userStatus: response.data.result.Users[0].UserStatus,
                                };
                        }
                    }
                    return false;
                }

                throw new Error('Error');
            }, serverError)
    }

    /**
     * Login the user into the platform
     * @param {string} email
     * @param {string} password
     * @param {boolean} _rememberMe
     * @returns {Promise}
     */
    login = (email, password, _rememberMe) => {
        const loginForm = new URLSearchParams();
        loginForm.append('email', email);
        loginForm.append('password', password);
        loginForm.append('auth_flow', 'USER_PASSWORD_AUTH');

        return API.post("authentication/users/auth", loginForm)
            .then(async(response) => {
                //handle success
                if(response.data.status){
                    sendGAEvent('login', {
                        method: 'USER_PASSWORD_AUTH',
                    });
                    return response.data.result;
                }
                if(response.data.errors.code === 'NotAuthorizedException'){
                    return Promise.reject({ // Follows the same structure as throw new Error(response.data.errors.code)
                        message: {
                            code: response.data.errors.code,
                            attempsLeft: response.data.remaining_attempts,
                        },
                    })
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Register a new user into the Cognito user pool
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} password
     * @param {string} language
     * @returns {Promise}
     */
    register = (firstName, lastName, email, password, language, phoneNumber) => {
        const data = new URLSearchParams();
        data.append('name', firstName);
        data.append('family_name', lastName);
        data.append('email', email);
        data.append('password', password);
        data.append('locale', language);
        if(phoneNumber)
            data.append('phone_number', phoneNumber);

        return API_SPORDLE.post("authentication/users/sign-up", data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw new Error(response.data.errors.code);

            }, serverError);
    }

    /**
     * Resend a confirmation email the the given email
     * @param {string} email
     */
    resendEmail = (email) => {
        const data = new URLSearchParams();
        data.append('username', email);

        return API_SPORDLE.post("authentication/users/resend-confirm", data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw new Error(response.data.errors.code);

            }, serverError);
    }

    /**
     * User sign-up confirmation
     * @param {string} email
     * @param {number} confirmCode 6 digit number
     * @returns {Promise}
     */
    confirm = (email, confirmCode) => {
        const searchParams = new URLSearchParams();
        searchParams.append('username', email);
        searchParams.append('confirmation_code', confirmCode);

        return API_SPORDLE.patch("authentication/users/confirm-sign-up", searchParams)
            .then((response) => {
                if(response.data.status){
                    return response.data.account_id;
                }
                throw new Error(response.data.errors.code);

            }, serverError)
    }

    /**
     * Makes a call to Cognito to send a recovery email to the user
     * @param {string} email
     * @returns {Promise}
     */
    sendRecoveryEmail = (email) => {
        const data = new URLSearchParams();
        data.append('username', email);

        return API_SPORDLE.post("/authentication/users/forgot", data)
            .then((response) => {
                //handle success
                if(response.data.status){
                    return true;
                }
                throw new Error(response.data.errors.code);

            }, serverError);
    }

    /**
     * Resets the user password
     * @param {string} email
     * @param {string} password
     * @param {number} code 6 digit number
     * @returns {Promise}
     */
    resetPassword = (email, password, code) => {
        const data = new URLSearchParams();
        data.append('username', email);
        data.append('confirmation_code', code);
        data.append('new_password', password);

        return API_SPORDLE.patch("authentication/users/confirm-forgot", data)
            .then((response) => {
                //handle success
                if(response.data.status){
                    return true;
                }
                throw new Error(response.data.errors.code);

            }, serverError);
    }

    /**
     * Checks if the user is already connected or not. This function uses the REFRESH_TOKEN set by Cognito
     * @returns {Promise}
     */
    checkForConnexion = () => {
        if(process.env.NODE_ENV === 'development' && window.sessionStorage.getItem('accessToken')){ // localhost only
            return Promise.resolve({
                AuthenticationResult: {
                    AccessToken: window.sessionStorage.getItem('accessToken'),
                    TokenType: 'Bearer',
                },
            })
        }

        const data = new URLSearchParams();
        data.append('auth_flow', 'REFRESH_TOKEN');

        return API.post('authentication/users/auth', data)
            .then((response) => {
                if(response.data.status){
                    sendGAEvent('login', {
                        method: 'REFRESH_TOKEN',
                    });
                    return response.data.result;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Gets the current users info
     * @param {string} [accessToken]
     * @returns {Promise}
     */
    getUserData = (accessToken = this.state.accessToken) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: 'authentication/users',
            query: {
                access_token: accessToken,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    const user = response.data.result;
                    let tempObject = {};
                    // Formatting UserAttributes
                    user.UserAttributes?.forEach((attribute) => {
                        tempObject[attribute.Name] = attribute.Value;
                    })
                    tempObject.logo = noProfileLogo; // TODO: Replace with database logo
                    user.UserAttributes = tempObject;
                    // Formatting UserMFASettingList
                    tempObject = {
                        sms: false,
                        totp: false,
                    };
                    user.UserMFASettingList?.forEach((setting) => {
                        switch (setting){
                            case 'SMS_MFA':
                                tempObject.sms = true;
                                break;
                            case 'SOFTWARE_TOKEN_MFA':
                                tempObject.totp = true;
                                break;
                            default:
                                break;
                        }
                    })
                    user.UserMFASettingList = tempObject;
                    this.setState(() => ({ userData: user }));

                    return user;
                }
                throw new Error(response.data.errors.code);

            }, serverError);
    }

    /**
     * Updated the user informations in Cognito
     * @param {object} newInfo
     * @returns {Promise}
     */
    updateUserInfo = (newInfo) => {
        const data = new URLSearchParams();
        data.append('access_token', this.state.accessToken);
        data.append('email', newInfo.email);
        data.append('name', newInfo.name);
        data.append('family_name', newInfo.family_name);
        // Optional params
        if(newInfo.gender)
            data.append('gender', newInfo.gender);
        if(newInfo.birthdate)
            data.append('birthdate', newInfo.birthdate);
        if(newInfo.phone_number)
            data.append('phone_number', newInfo.phone_number);
        if(newInfo.locale)
            data.append('locale', newInfo.locale);
        if(newInfo.language_code)
            data.append('language_code', newInfo.language_code);

        return API_SPORDLE.put('authentication/users', data)
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ userData: {
                        ...prevState.userData,
                        UserAttributes: {
                            ...prevState.userData.UserAttributes,
                            ...newInfo,
                        },
                    } }));
                    return response.data;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Confirms the new user password
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise}
     */
    submitNewPassword = (oldPassword, newPassword) => {
        const data = new URLSearchParams();
        data.append('access_token', this.state.accessToken);
        data.append('old_password', oldPassword);
        data.append('new_password', newPassword);

        return API_SPORDLE.patch('authentication/users/password', data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Set the logged in user 2FA settings
     * @param {boolean} [sms] If we want to activate 2FA by sms
     * @param {boolean} [totp] If we want to activate 2FA with Google Authentificator
     * @param {'SMS'|'SOFTWARE_TOKEN'} [preference] Preference for the 2FA
     * @returns {Promise}
     */
    setMFA = (sms, totp, preference) => {
        const data = new URLSearchParams();
        data.append('access_token', this.state.accessToken);

        // Converting true -> 1 and false -> 0
        // https://stackoverflow.com/a/14787812
        if(sms !== undefined)
            data.append('sms_enabled', !!sms >>> 0);
        if(totp !== undefined)
            data.append('software_enabled', !!totp >>> 0);


        switch (preference){
            case 'SMS_MFA':
                preference = 'SMS';
                break;
            case 'SOFTWARE_TOKEN_MFA':
                preference = 'SOFTWARE';
                break;
            default:
                if(data.get('sms_enabled') == 1 && !this.state.userData.UserMFASettingList.sms){ // just activated SMS
                    preference = 'SMS';
                }else if(data.get('software_enabled') == 1 && !this.state.userData.UserMFASettingList.totp){ // just activated software
                    preference = 'SOFTWARE'
                }else if(data.get('sms_enabled') == 0 && this.state.userData.UserMFASettingList.sms && this.state.userData.UserMFASettingList.totp){ // just deactivated SMS -> falling back on software
                    preference = 'SOFTWARE'
                }else if(data.get('software_enabled') == 0 && this.state.userData.UserMFASettingList.totp && this.state.userData.UserMFASettingList.sms){ // just deactivated software -> falling back on sms
                    preference = 'SMS'
                }else{ // deactivated something and the other one is also deactivated so no preference
                    preference = ''
                }
                break;
        }

        data.append('prefered_method', preference);

        return API_SPORDLE.post('authentication/users/mfa', data)
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ userData: { ...prevState.userData, PreferredMfaSetting: preference, UserMFASettingList: { sms: sms ?? prevState.userData.UserMFASettingList.sms, totp: totp ?? prevState.userData.UserMFASettingList.totp } } }))
                    return response.data;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Get the Secret code to link the totp 2FA to an app like Google Authentificator
     * @returns {Promise}
     */
    getTOTPToken = () => {
        return API_SPORDLE.get('authentication/users/software?' + queryString.stringify({ access_token: this.state.accessToken }))
            .then((response) => {
                if(response.data.status){
                    return response.data.result.SecretCode;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * Confirm the linking between the totp app(Google Auth) and the account.
     * This is to confirm the user has the right totp setup
     * @param {number} code 6 digits code
     * @returns {Promise}
     */
    confirmTOTP = (code) => {
        const data = new URLSearchParams();
        data.append('access_token', this.state.accessToken);
        data.append('user_code', code);

        return API_SPORDLE.post('authentication/users/software', data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw new Error(response.data.errors.code);
            }, serverError);
    }

    /**
     * When a login is challenged(login with user that has 2FA setup), a challenge with happen. To confirm this challenge, call this function with the corresponding params.
     * @param {string} session Session given by the login call
     * @param {string} username Username given by the login call
     * @param {'SMS_MFA'|'SOFTWARE_TOKEN_MFA'|'NEW_PASSWORD_REQUIRED'} challengeName The type of challenge
     * @param {string|number} challengeAnswer The corresponding value to confirm 2FA login.
     * @returns {Promise}
     */
    challengeLogin = (session, username, challengeName, challengeAnswer) => {
        const data = new URLSearchParams();
        data.append('session', session);
        data.append('username', username);
        data.append('challenge_name', challengeName);

        switch (challengeName){
            case 'SMS_MFA':
                data.append('sms_code', challengeAnswer);
                break;
            case 'SOFTWARE_TOKEN_MFA':
                data.append('software_code', challengeAnswer);
                break;
            case 'NEW_PASSWORD_REQUIRED':
                data.append('new_password', challengeAnswer);
                break;
            default:
                break;
        }

        return API.post('authentication/users/auth-challenge', data)
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError);
    }

    /**
     * Set up thee platform with a logged in user
     * @param {object} connectionData The login data
     * @param {function} callback Function to execute after the setState that is in this function
     */
    setUpInternalConnection = (connectionData, callback) => {
        API_SPORDLE.defaults.headers.common['X-Access-Token'] = connectionData.accessToken;
        API_SPORDLE_BQ.defaults.headers.common['X-Access-Token'] = connectionData.accessToken;
        API_SPORDLE_SQ.defaults.headers.common['X-Access-Token'] = connectionData.accessToken;
        API_PUBLIC.defaults.headers.common['X-Access-Token'] = connectionData.accessToken;
        API_PUBLIC_LOGGEDIN.defaults.headers.common['X-Access-Token'] = connectionData.accessToken;

        this.setState(() => connectionData, callback);
    }

    /**
     * Sign out and revoke all refresh tokens issued to current user
     * @param {function} [accessToken]
     * @returns {Promise}
     */
    signOut = (accessToken = this.state.accessToken) => {
        const deleteParam = new URLSearchParams();
        deleteParam.append('access_token', accessToken);
        return API_SPORDLE.delete('/authentication/users/sign-out', { data: deleteParam })
            .then((response) => {
                if(response.data.status){
                    sendGAEvent('log_out');
                    removeSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY);
                    if(process.env.NODE_ENV === 'development'){ // localhost only
                        window.sessionStorage.removeItem('accessToken');
                    }
                    this.setState(() => this._initState);
                }
            });
    }

    /**
     * Get's all the user's IdentityRoles
     * @param {string|undefined} userId The user we want to get the IdentityRoles from. If undefined then uses the userId in the state
     * @returns {Promise}
     */
    getUserIdentityRole = (userId = this.state.userData?.Username) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/authentication/users/${userId}/roles` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_roles;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Get's all the user's IdentityRoles
     * @param {string|undefined} userId The user we want to get the IdentityRoles from. If undefined then uses the userId in the state
     * @returns {Promise}
     */
    getUserIdentityRoleSQ = (userId = this.state.userData?.Username) => {
        return API_SPORDLE_SQ.get(queryString.stringifyUrl({ url: `/authentication/users/${userId}/roles` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_roles;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Get's all the user's IdentityRoles
     * @param {string|undefined} userId The user we want to get the IdentityRoles from. If undefined then uses the userId in the state
     * @returns {Promise}
     */
    getUserIdentityRoleBQ = (userId = this.state.userData?.Username) => {
        return API_SPORDLE_BQ.get(queryString.stringifyUrl({ url: `/authentication/users/${userId}/roles` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_roles;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Will send a verification code to the user with the [attributeName] method
     * @param {'email'|'phone_number'} attributeName
     * @returns {Promise}
     */
    sendAttributeCode = (attributeName) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/authentication/users/attribute-codes',
            query: {
                access_token: this.state.accessToken,
                attribute_name: attributeName,
            },
        })).then((response) => {
            if(response.data.status){
                return response.data;
            }
            throw new Error(response.data.errors[0].code);
        }, serverError)
    }

    /**
     * Will make an api call to verify the [attributeName]
     * @param {'email'|'phone_number'} attributeName
     * @param {string} code The code that was sent with this.sendAttributeCode
     * @returns {Promise}
     */
    verifyAttributeCode = (attributeName, code) => {
        const params = new URLSearchParams();
        params.append('access_token', this.state.accessToken);
        params.append('attribute_name', attributeName);
        params.append('code', code);
        return API_SPORDLE.post(queryString.stringifyUrl({ url: 'authentication/users/attribute-codes' }), params)
            .then((response) => {
                if(response.data.status){
                    return;
                }
                throw new Error(response.data.errors.code);
            }, serverError)
    }

    isPowerUser = () => {
        return this.state.userData?.power_user == 1;
    }

    render(){
        return (
            <AuthContext.Provider value={{
                ...this.state,
                login: this.login,
                register: this.register,
                resendEmail: this.resendEmail,
                confirmAccount: this.confirm,
                sendRecoveryEmail: this.sendRecoveryEmail,
                resetPassword: this.resetPassword,
                verifyEmailExistance: this.verifyEmailExistance,
                checkForConnexion: this.checkForConnexion,
                setUpInternalConnection: this.setUpInternalConnection,
                getUserData: this.getUserData,
                updateUserInfo: this.updateUserInfo,
                submitNewPassword: this.submitNewPassword,
                setMFA: this.setMFA,
                getTOTPToken: this.getTOTPToken,
                confirmTOTP: this.confirmTOTP,
                challengeLogin: this.challengeLogin,
                signOut: this.signOut,
                getUserIdentityRole: this.getUserIdentityRole,
                getUserIdentityRoleSQ: this.getUserIdentityRoleSQ,
                getUserIdentityRoleBQ: this.getUserIdentityRoleBQ,
                sendAttributeCode: this.sendAttributeCode,
                verifyAttributeCode: this.verifyAttributeCode,
                isPowerUser: this.isPowerUser,
            }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;