import Translate from "@spordle/intl-elements";
import { useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, Col, ListGroup, Row } from "reactstrap";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import PaymentFilter from "./components/PaymentFilter";
import PaymentItem from "./components/PaymentItem";
import PaymentPagination from "./components/PaymentPagination";
import PreInitPayment from "./components/PreInitPayment";
import UpcomingPayment from "./components/UpcomingPayment";

const Payments = ({ invoicesIsLoading, installments, error, updatePaymentList }) => {
    const pagination = 6;
    const isLoading = invoicesIsLoading || !Array.isArray(installments);
    const [ currentPage, setCurrentPage ] = useState(0);
    const firstItemIndex = currentPage * pagination;

    const [ onlyPendings, setOnlyPendings ] = useState(true);

    const getFilterType = () => {
        if(onlyPendings === true){
            return "PENDINGS";
        }else if(onlyPendings === false){
            return "COMPLETED";
        }
        return "ALL";
    };

    const setFilter = (filter) => {
        setCurrentPage(0);
        setOnlyPendings(filter);
    };

    const getOldestDecline = () => {
        if(!Array.isArray(installments)){
            return;
        }
        for(let index = installments.length - 1; index >= 0; index--){
            const installment = installments[index];
            if(installment.status === "NEED_REVIEW"){
                return installment;
            }
        }

        return installments.find((ins) => ins.isPending && !ins.isPreInit);
    };

    const oldestDecline = getOldestDecline();

    const filteredInstallments =
        getFilterType() === "ALL"
            ? installments
            : installments?.filter((installment) => {
                if(onlyPendings){
                    return (
                        installment.status !== "COMPLETED" &&
                            (installment.isPending === true ||
                                installment.status === "NEED_REVIEW")
                    );
                }
                return installment.status === "COMPLETED";

            }) || [];

    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                    <div className="h4 mb-0 font-bold card-title"><Translate id='account.paymentMethods.transactions.title' /></div>
                </div>
                {!error &&
                    <div className="mb-2">
                        <PaymentFilter activeFilter={getFilterType()} setFilter={setFilter} />
                    </div>
                }
                {error ?
                    <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />
                    :
                    <Row form>
                        <Col sm="8">
                            {isLoading ?
                                <Skeleton height={71} count={pagination} />
                                :
                                filteredInstallments.length === 0 ?
                                    <EmptyLayout hideArrow hideMsg />
                                    :
                                    <ListGroup className="rounded-lg shadow-sm overflow-hidden">
                                        {filteredInstallments.slice(firstItemIndex, firstItemIndex + pagination).map((installment) => (
                                            <PaymentItem
                                                key={installment.invoice_payment_id}
                                                date={installment.due_date}
                                                desc={installment.InvoiceItem}
                                                updatePaymentList={updatePaymentList}
                                                amount={installment.amount / 100}
                                                status={installment.status.toLowerCase()}
                                                invoiceNumber={installment.invoice_number}
                                                isPaysafe={installment.provider === "PAYSAFE"}
                                                invoiceItem={installment}
                                            />
                                        ))}
                                    </ListGroup>
                            }

                            {!isLoading && filteredInstallments.length > pagination &&
                                <PaymentPagination totalItems={filteredInstallments.length} currentPage={currentPage} setCurrentPage={setCurrentPage} pagination={pagination} />
                            }
                        </Col>
                        <Col sm="4">
                            {isLoading ?
                                <Skeleton count={2} height={210} className='mb-3' />
                                :
                                <>
                                    <UpcomingPayment updatePaymentList={updatePaymentList} invoiceItem={oldestDecline} isDeclined={oldestDecline?.status === "NEED_REVIEW"} />
                                    <PreInitPayment updatePaymentList={updatePaymentList} invoiceItem={installments.find((ins) => ins.canPayNow)} />
                                </>
                            }
                        </Col>
                    </Row>
                }
            </CardBody>
        </Card>
    )
}

export default Payments;