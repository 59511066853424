import { useContext } from 'react';
import { fail, fire } from '@spordle/toasts';
import { AccountsContext, AuthContext } from '../../../../contexts/contexts';
import getBranchName from '../../../../helpers/getBranch';
import { DB } from '../../../../helpers/constants';
import getReferrer from '../../../../helpers/getReferer';
import CheckboxStar from '../../../../components/checkboxStar/CheckboxStar';
import queryString from 'query-string';
import Antispam from '@spordle/anti-spam';
import { Button, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import Translate from '@spordle/intl-elements';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import ReactTooltip from "react-tooltip";
import { useTooltip } from "../../../../components/customHooks/useTooltip";
import { I18nContext } from '../../../../contexts/I18nContext';

const AccountDashboardOrgs = ({ idRoles, setIdRoles }) => {
    const accountsContext = useContext(AccountsContext);
    const i18nContext = useContext(I18nContext);

    const getDomain = () => {
        switch (getBranchName()){
            case 'hcr':
                return "hcr-demo"; // Control url when on hcr.account.spordle.com
            default:
                return getBranchName();
        }
    }

    const enablePrimary = (identityId, identityRoleId, identityRole) => {
        accountsContext.enablePrimaryRole(identityId, identityRoleId)
            .then(() => {
                // logic is simple enough to avoid making another call to fetch the Identity Roles
                // so we do it here to update the state
                const newIdentityRoles = idRoles;
                newIdentityRoles.forEach((ir) => {
                    ir.is_primary = "0";
                })
                newIdentityRoles.find((identityRole) => identityRole.identity_role_id === identityRoleId).is_primary = "1";
                setIdRoles(newIdentityRoles)

                fire({
                    msg: 'account.dashboard.accountDashboard.primaryEnable.toast.success',
                    skipInfoTranslate: true,
                    icon:
                    <UserImg
                        className="shadow-sm"
                        alt={identityRole.organisation.organisation_name}
                        abbr={identityRole.organisation.abbreviation}
                        src={identityRole.organisation.logo?.full_path}
                        filePos={identityRole.organisation.logo?.file_position}
                        width={40}
                    />,
                    info: <div className="font-medium">{identityRole.role.title}: {identityRole.organisation.organisation_name}</div>,
                });

                // success({ msg: <Translate id='account.dashboard.accountDashboard.primaryEnable.toast.success' values={{ roleTitle: roleTitle, orgName: orgName }} />, skipMsgTranslate: true });
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                }
            });
    }

    const disablePrimary = (identityId, identityRoleId, orgName, roleTitle) => {
        accountsContext.disablePrimaryRole(identityId, identityRoleId)
            .then(() => {
                const newIdentityRoles = idRoles;
                // no need for a loop through all the identity roles, only 1 should have primary
                newIdentityRoles.find((identityRole) => identityRole.identity_role_id === identityRoleId).is_primary = "0";

                setIdRoles(newIdentityRoles)

                // success({ msg: <Translate id='account.dashboard.accountDashboard.primaryDisable.toast.success' values={{ roleTitle: roleTitle, orgName: orgName }} />, skipMsgTranslate: true });
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message)
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                }
            });
    }

    return (
        <>
            <div>
                <div className="h4 font-medium d-inline-block"><Translate id='account.dashboard.accountDashboard.organizations' /></div>
                {/* <button className="btn btn-reset btn-link d-inline-block"><i className="ti-plus"></i></button> */}
            </div>
            <ReactTooltip
                id="additionalRolesTip"
                getContent={(tip) => {
                    return (
                        tip ?
                            <div>
                                <label className="mb-0"><u><Translate id="account.dashboard.accountDashboard.additional_roles" />:</u></label>
                                <ul className='mb-0' style={{ 'paddingInlineStart': "15px" }}>
                                    {tip.split(';').map((addRole) => (
                                        <li key={addRole}>{addRole}</li>
                                    ))}
                                </ul>
                            </div>
                            : null
                    )
                }}
                effect="solid"
                place="top"
            />
            <Collapse isOpen={Array.isArray(idRoles)}>
                <Row>
                    <AuthContext.Consumer>
                        {(auth) => (
                            idRoles?.map((identityRole) => (
                                'identity_role_id' in identityRole ?// Is identityRole
                                    <Col md="6" key={'identityRole-' + identityRole.identity_role_id}>
                                        <OrganizationCard
                                            logo={identityRole.organisation?.logo?.full_path ?? "https://hcrstorage.s3.ca-central-1.amazonaws.com/UAT/HC%402x.31e033b6.png"}
                                            name={identityRole.organisation.organisation_name}
                                            abbr={identityRole.organisation.abbreviation}
                                            isPrimary={identityRole.is_primary}
                                            enablePrimary={() => { enablePrimary(auth.userData.Username, identityRole.identity_role_id, identityRole) }}
                                            disablePrimary={() => { disablePrimary(auth.userData.Username, identityRole.identity_role_id, identityRole.organisation.organisation_name, identityRole.role.title) }}
                                            roleTitle={identityRole.role.title}
                                            identityRoleId={identityRole.identity_role_id}
                                            additional_role={identityRole.additional_role}
                                            domain={getDomain()}
                                            pageLink={queryString.stringifyUrl({
                                                url: identityRole.organisation.deploy_on == 'PAGE' ?
                                                    `${getReferrer('PAGE-VERCEL-2')}/${identityRole.organisation.short_name}`
                                                    : identityRole.organisation.deploy_on == 'VERCEL' ?
                                                        `${getReferrer('PAGE-VERCEL', identityRole.from)}/${identityRole.organisation.short_name}`
                                                        :
                                                        `${getReferrer('PAGE', identityRole.from)}/page/${identityRole.organisation.short_url}`,
                                                query: {
                                                    accessToken: auth.accessToken,
                                                    type: auth.type,
                                                    lang: i18nContext.locale,
                                                },
                                            })} // Page URL, to place with correct url
                                            localHostAuth={queryString.stringifyUrl({
                                                url: identityRole.organisation.deploy_on == 'PAGE' || identityRole.organisation.deploy_on == 'VERCEL' ?
                                                    `http://localhost:3000/${identityRole.organisation.short_name}`
                                                    :
                                                    `http://localhost:3000/page/${identityRole.organisation.short_url}`,
                                                query: {
                                                    accessToken: auth.accessToken,
                                                    type: auth.type,
                                                    lang: i18nContext.locale,
                                                },
                                            })}
                                            manageOptions={[
                                                {
                                                    platform: identityRole.from || 'SID',
                                                    url: queryString.stringifyUrl({
                                                        url: getReferrer('HCR', identityRole.from),
                                                        query: {
                                                            accessToken: auth.accessToken,
                                                            idToken: auth.idToken,
                                                            type: auth.type,
                                                            identityRoleId: identityRole.identity_role_id,
                                                            organizationId: identityRole.organisation.organisation_id,
                                                            db: DB,
                                                        },
                                                    }, {
                                                        skipNull: true,
                                                        skipEmptyString: true,
                                                    }),
                                                },
                                            ]}
                                        />
                                    </Col>
                                    :// Is a metaMember org
                                    <Col md="6" key={'member-' + identityRole.organisation_id}>
                                        <MemberCardOrg
                                            org={identityRole}
                                            domain={getDomain()}
                                            pageLink={queryString.stringifyUrl({
                                                url: identityRole.deploy_on == 'PAGE' ?
                                                `${getReferrer('PAGE-VERCEL-2')}/${identityRole.short_name}`
                                                    : identityRole.deploy_on == 'VERCEL' ?
                                                    `${getReferrer('PAGE-VERCEL', identityRole.from)}/${identityRole.short_name}`
                                                        :
                                                    `${getReferrer('PAGE', identityRole.from)}/page/${identityRole.short_url}`,
                                                query: {
                                                    accessToken: auth.accessToken,
                                                    type: auth.type,
                                                    lang: i18nContext.locale,
                                                },
                                            })}
                                            localHostAuth={queryString.stringifyUrl({
                                                url: `http://localhost:3000/page/${identityRole.short_url}`,
                                                query: {
                                                    accessToken: auth.accessToken,
                                                    type: auth.type,
                                                    lang: i18nContext.locale,
                                                },
                                            })}
                                        />
                                    </Col>
                            ))
                        )}
                    </AuthContext.Consumer>
                </Row>
            </Collapse>
        </>
    )
}

export default AccountDashboardOrgs;

/*--------------------------------------------------------------------------------*/
/* Organization Card used in the dashboard                                        */
/*--------------------------------------------------------------------------------*/
const OrganizationCard = ({ logo, name, isPrimary, enablePrimary, disablePrimary, identityRoleId, domain, pageLink, localHostAuth, manageOptions, roleTitle, additional_role }) => {
    const getPlatformName = (platform) => {
        switch (platform){
            case 'SID':
            case 'BQ':
            case 'SQ':
            case 'UK':
                return 'Spordle iD';
            default:
                break;
        }
    }

    const getPlatformDescription = (platform) => {
        switch (platform){
            case 'UK':
                if(process.env.REACT_APP_ENVIRONMENT !== 'stage')
                    return 'Hockey United Kingdom';
            case 'SID':
                return <Translate id='account.dashboard.accountDashboard.organizations.hcr' />;
            case 'BQ':
                return 'Baseball Quebec';
            case 'SQ':
                return 'Soccer Quebec';
            default:
                break;
        }
    }

    return (
        <UserDisplay card className="flex-wrap d-flex">
            <UserDisplay.Container>
                {/* NEED_ABBR */}
                <UserImg
                    abbr={''}
                    src={logo}
                    width={50}
                    height={50}
                    alt={name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="min-w-100">
                <UserDisplay.Title className="text-truncate">
                    {roleTitle}
                    {additional_role &&
                        <RoleCardAdditionalRoles additional_role={additional_role} />
                    }
                </UserDisplay.Title>
                <UserDisplay.Subtitle>{name}</UserDisplay.Subtitle>
            </UserDisplay.Container>
            <div className='position-absolute' style={{ top: '15px', right: '15px' }}>
                <Antispam
                    id={identityRoleId}
                    timeoutDuration={5000}
                    clicksForTimeout={3}
                    resetClicksDuration={10000}
                    isSpamming={() => {
                        fire({ msg: 'login.toast.warning.spam', theme: 'warning' });
                    }}
                >
                    {(spamming, antiSpamCallback) => (
                        <CheckboxStar
                            checked={isPrimary == '1'}
                            onClick={(e) => {
                                antiSpamCallback(e);
                                e.target.checked ? enablePrimary() : disablePrimary();
                            }}
                            id={'checkbox-' + identityRoleId}
                            disabled={spamming || isPrimary == '1'}
                        />
                    )}
                </Antispam>
            </div>
            <div className="mt-3 w-100">
                <Row form>
                    <Col sm="6" className="mb-2 mb-sm-0">
                        {process.env.REACT_APP_ENVIRONMENT === 'int' ?
                            <UncontrolledDropdown>
                                <DropdownToggle outline color="primary" size="sm" className="w-100">
                                    <div><Translate id='account.dashboard.accountDashboard.organizations.register' /> <i className="ti-angle-down ml-1 font-12" style={{ verticalAlign: "middle" }} /></div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag="a" href={pageLink} target="_blank" rel="noreferrer noopener">
                                        <span><Translate id='account.dashboard.accountDashboard.organizations.register' /> ({domain})</span>
                                    </DropdownItem>
                                    <DropdownItem tag="a" href={localHostAuth} target="_blank" rel="noreferrer noopener">
                                        <span>localhost</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            :
                            <Button outline color="primary" size="sm" className="w-100" tag='a' href={pageLink} rel="noreferrer noopener">
                                <Translate id='account.dashboard.accountDashboard.organizations.register' />
                            </Button>
                        }
                    </Col>
                    {manageOptions.length !== 0 &&
                        <Col sm="6">
                            <UncontrolledDropdown>
                                <DropdownToggle outline color="primary" size="sm" className="w-100">
                                    <div><Translate id='account.dashboard.accountDashboard.organizations.manage' /><i className="ti-angle-down ml-1 font-12" style={{ verticalAlign: "middle" }} /></div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {manageOptions.map((option) => (
                                        <DropdownItem tag="a" href={option.url} key={option.platform}>
                                            <div className="font-medium">{getPlatformName(option.platform)}</div>
                                            <div className="font-12">{getPlatformDescription(option.platform)}</div>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    }
                </Row>
            </div>
        </UserDisplay>
    );
}

const MemberCardOrg = ({ org, domain, pageLink, localHostAuth }) => (
    <UserDisplay card className="flex-wrap d-flex">
        <UserDisplay.Container>
            {/* NEED_ABBR */}
            <UserImg
                abbr={org.organisation_name.substring(0, 1)}
                src={org.logo?.full_path}
                width={50}
                height={50}
                alt={org.organisation_name}
            />
        </UserDisplay.Container>
        <UserDisplay.Container className="min-w-100">
            <UserDisplay.Title className="text-truncate" title={org.organisation_name}>
                <DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} />
            </UserDisplay.Title>
        </UserDisplay.Container>
        <div className="mt-3 w-100">
            {process.env.REACT_APP_ENVIRONMENT === 'int' ?
                <UncontrolledDropdown>
                    <DropdownToggle outline color="primary" size="sm" className="w-100">
                        <div><Translate id='account.dashboard.accountDashboard.organizations.register' /> <i className="ti-angle-down ml-1 font-12" style={{ verticalAlign: "middle" }} /></div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag="a" href={pageLink} target="_blank" rel="noreferrer noopener">
                            <span><Translate id='account.dashboard.accountDashboard.organizations.register' /> ({domain})</span>
                        </DropdownItem>
                        <DropdownItem tag="a" href={localHostAuth} target="_blank" rel="noreferrer noopener">
                            <span>localhost</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                :
                <Button outline color="primary" size="sm" className="w-100" tag='a' href={pageLink} rel="noreferrer noopener">
                    <Translate id='account.dashboard.accountDashboard.organizations.register' />
                </Button>
            }
        </div>
    </UserDisplay>
)

const RoleCardAdditionalRoles = ({ additional_role }) => {
    const tooltipProps = useTooltip(
        "additionalRolesTip",
        additional_role.map((addRole) => addRole.role.title).join(';'),
    );

    return (
        <div {...tooltipProps} className="clickable d-inline-block ml-1"><i className="font-16 text-primary mdi mdi-cards-outline" /></div>
    )
}
