import { useDisclosure } from '@mantine/hooks'
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables'
import Translate from '@spordle/intl-elements'
import moment from 'moment'
import { useContext } from 'react'
import { Badge, Button, Card } from 'reactstrap'
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel'
import UserDisplay from '../../../../components/userDisplay/UserDisplay'
import UserImg from '../../../../components/UserImg'
import { AccountsContext, AuthContext } from '../../../../contexts/contexts'
import { I18nContext } from '../../../../contexts/I18nContext'
import { DisplayI18n } from '../../../../helpers/i18nHelper'
import TravelPermitAddContext from './components/addModal/TravelPermitAddContext'
import TravelPermitsSidepanel from './components/TravelPermitsSidepanel'

const AccountMemberTravelPermits = ({ member }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const accountContext = useContext(AccountsContext);
    const authContext = useContext(AuthContext);

    const [ isOpen, { toggle } ] = useDisclosure(false);


    return (
        <Card body className='card-shadow'>
            <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                <div className="card-title font-bold h4 mb-0"><Translate id='account.members.travelPermits.title' /></div>
            </div>
            <SpordlePanelTable
                dataIndex='travel_permit_id'
                allowOutsideClick
                sidePanel={(props) => <TravelPermitsSidepanel {...props} member={member} />}
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id='travelPermits'
                            key={member.member_id}
                            ref={panelProps.spordleTableRef}
                            tableHover bordered striped
                            pagination={10}
                            desktopWhen
                            dataIndex='travel_permit_id'
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return accountContext.getTravelPermits(authContext.userData.Username, member.member_id);
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={[
                                'travel_event_name',
                                'team.name',
                                'location_name',
                                `organisation.i18n.${getGenericLocale()}.name`,
                                'organisation.organisation_name',
                                'organisation.abbreviation',
                                'travel_permit_status.name',
                                'start_date',
                                'end_date',
                            ]}
                            columns={[
                                {
                                    label: <Translate id='account.members.travelPermits.column.team' />,
                                    key: 'team',
                                    sortKey: 'team.name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.travelPermits.column.event' />,
                                    key: 'event',
                                    sortKey: 'travel_event_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.travelPermits.column.location' />,
                                    key: 'location',
                                    sortKey: 'location_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.travelPermits.column.startDate' />,
                                    key: 'start_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.travelPermits.column.endDate' />,
                                    key: 'end_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.travelPermits.column.status' />,
                                    key: 'status',
                                    sortKey: 'travel_permit_status.name',
                                    sortable: true,
                                },
                            ]}
                            renderRow={(columnKey, data) => {
                                switch (columnKey){
                                    case 'team':
                                        return (
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={data.team.name}
                                                        src={data.team.logo?.full_path || null}
                                                        filePos={data.team.logo?.file_position || null}
                                                        width={40}
                                                        height={40}
                                                        alt={data.team.name}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{data.team.name}</UserDisplay.Title>
                                                    <UserDisplay.Subtitle>{data.team.organisation.organisation_name}</UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        )
                                    case 'event':
                                        return (
                                            <>
                                                <div className="font-medium text-dark">{data.travel_event_name}</div>
                                                <div className="text-muted small">{data.travel_game_type}</div>
                                            </>
                                        )
                                    case 'location':
                                        return (
                                            <>
                                                <div className="font-medium text-dark">{data.location_name}</div>
                                                <div className="text-muted small">{data.country_code}</div>
                                            </>
                                        )
                                    case 'start_date':
                                        return (
                                            <div className="font-medium text-dark">{moment(data.start_date).format('YYYY-MM-DD')}</div>
                                        )
                                    case 'end_date':
                                        return (
                                            <div className="font-medium text-dark">{data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : '-'}</div>
                                        )
                                    case 'status':
                                        switch ((data.travel_permit_status?.name || '').toLowerCase()){
                                            case 'pending':
                                            case 'draft':
                                                return (
                                                    <Badge color='warning'>
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={data.travel_permit_status.i18n}
                                                            defaultValue={data.travel_permit_status.name}
                                                        />
                                                    </Badge>
                                                )
                                            case 'approved':
                                                return (
                                                    <Badge color='success'>
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={data.travel_permit_status.i18n}
                                                            defaultValue={data.travel_permit_status.name}
                                                        />
                                                    </Badge>
                                                )
                                            case 'declined':
                                                return (
                                                    <Badge color='danger'>
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={data.travel_permit_status.i18n}
                                                            defaultValue={data.travel_permit_status.name}
                                                        />
                                                    </Badge>
                                                )
                                            case 'submitted':
                                                return (
                                                    <Badge color='info'>
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={data.travel_permit_status.i18n}
                                                            defaultValue={data.travel_permit_status.name}
                                                        />
                                                    </Badge>
                                                )
                                            default:
                                                return data.travel_permit_status?.name || '-'
                                        }

                                    default:
                                        break;
                                }
                            }}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                        >
                            <div className='mb-2'>
                                <div className='d-flex flex-wrap justify-content-between'>
                                    <SearchInput />
                                    <div className='d-flex ml-auto text-right'>
                                        <Refresh />
                                        <Button className='ml-2' color='primary' onClick={toggle}><i className='ti-plus' /> <Translate id='misc.add' /></Button>
                                    </div>
                                    <TravelPermitAddContext toggle={toggle} isOpen={isOpen} member={member} />
                                </div>
                            </div>
                            <SpordleTableView />
                        </SpordleTableProvider>
                    )
                }}
            />
        </Card>
    )
}

export default AccountMemberTravelPermits
