import Translate from "@spordle/intl-elements";
import { createContext, useState } from "react";
import StepComments from "../stepComments/StepComments";
import StepFillSelectedForm from "../stepFillSelectedForm/StepFillSelectedForm";
import StepFindOfficial from "../stepFindOfficial/StepFindOfficial";
import StepGlobalForm from "../stepGlobalForm/StepGlobalForm";
import StepSelectFormType from "../stepSelectFormType/StepSelectFormType";

/** @type {React.Context<Omit<Schedule, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const AccountMemberAddCoachContext = createContext();
AccountMemberAddCoachContext.displayName = 'AccountMemberAddCoachContext';

function AccountMemberAddCoachContextProvider(props){
    const [ isLoading, setIsLoading ] = useState(false);
    const [ step, setStep ] = useState(0);
    const [ formData, setFormData ] = useState({
        type: {},
        viewMode: "steps",
    });

    /**
     * @description Adds data to the complaint
     * @param {string} field Object property to add to complaint data
     * @param {any} data Data to add to this property
     */
    const updateFormData = (field, data) => {
        setFormData((prev) => ({ ...prev, [field]: data }))
    };

    /**
     * @typedef {object} StepView
     * @prop {string} id
     * @prop {React.ReactNode} title
     * @prop {React.ReactElement} component
     * @prop {(providerProps:{}) => bool} onAccessView function called when view is trying to be accessed - returns false if it shouldn't be
    */

    /**
     * @type {StepView[]} views
     */
    const views = [
        {
            id: "findOfficial",
            title: <Translate id='account.members.coach.addSupervision.step.findOfficial' />,
            component: StepFindOfficial,
        },
        {
            id: "global",
            title: <Translate id='account.members.coach.addSupervision.step.global' />,
            component: StepGlobalForm,
        },
        {
            id: "formType",
            onAccessView: (providerProps) => {
                if(providerProps.types.length == 1){
                    if(formData.type.supervision_type_id !== providerProps.types[0].supervision_type_id){
                        updateFormData('type', providerProps.types[0]);
                    }
                    return false;
                }
                return true;
            },
            title: <Translate id='account.members.coach.addSupervision.step.supervisionType' />,
            component: StepSelectFormType,
        },
        {
            id: "fillFormType",
            title: <Translate id='account.members.coach.addSupervision.step.selectLevels' />,
            component: StepFillSelectedForm,
        },
        {
            id: "comments",
            title: 'Comments',
            component: StepComments,
        },
    ];

    /**
     * @description Changes step of the modal
     * @param {views.length} nb
     */
    const changeStep = (nb) => {
        if(!views[nb]?.onAccessView || views[nb]?.onAccessView(props)){
            setStep(nb);
        }else{
            changeStep(step > nb ? (nb - 1 || 0) : (nb + 1 <= views.length - 1 ? nb + 1 : views.length - 1));
        }
    };

    const next = () => {
        changeStep(step + 1);
    };

    const previous = () => {
        changeStep(step - 1);
    };

    return (
        <AccountMemberAddCoachContext.Provider
            value={{
                isLoading: isLoading,
                activeStep: step,
                steps: views,
                changeStep: changeStep,
                setLoading: (bool) => setIsLoading(bool ?? !isLoading),
                updateFormData: updateFormData,
                formData: formData,
                next: next,
                previous: previous,
                ...props,
            }}
        >
            {props.children}
        </AccountMemberAddCoachContext.Provider>
    )
}

export default AccountMemberAddCoachContextProvider;