export default{
    "account.paymentMethods.otherItems" : "{amount, plural, =0 {Other item} one {Other item} other {Other items}}",

    // toasts
    "account.paymentMethods.toast.success.payment"         : "Your payment has been successfully processed.",
    "account.paymentMethods.toast.success.payment.paysafe" : "Your payment will be processed in the following 24-48 hours.",

    // btns
    "account.paymentMethods.btn.payNow"              : "Pay Now",
    "account.paymentMethods.btn.tryAgain"            : "Retry payment",
    "account.paymentMethods.btn.changeCard"          : "Change Card",
    "account.paymentMethods.btn.addCard"             : "Add Card",
    "account.paymentMethods.btn.allTransactions"     : "All",
    "account.paymentMethods.btn.pendingTransactions" : "Pending",
    "account.paymentMethods.btn.pastTransactions"    : "Completed",
    "account.paymentMethods.btn.editCard"            : "Edit Card",

    // Titles
    "account.paymentMethods.title"                 : "Payment Methods",
    "account.paymentMethods.vault.title"           : "Vault",
    "account.paymentMethods.vault.hint.ariaLabel"  : "What is Paysafe vault?",
    "account.paymentMethods.transactions.title"    : "Payments",
    "account.paymentMethods.nextPayment.title"     : "Next Payment",
    "account.paymentMethods.declinedPayment.title" : "Declined payment",
    "account.paymentMethods.preinit.title"         : "New Registration",


    // Labels
    "account.paymentMethods.label.pay"                           : "Pay",
    "account.paymentMethods.label.payNow"                        : "Pay Now",
    "account.paymentMethods.label.card"                          : "{amount, plural, =0 {Card} one {Card} other {Cards}}",
    "account.paymentMethods.label.cardExpired"                   : "This card is expired",
    "account.paymentMethods.label.card.accessibility.endingWith" : "Card ending with {lastDigits}",
    "account.paymentMethods.label.creditCard"                    : "Credit Card",
    "account.paymentMethods.label.creditCard.failed"             : "We could not retrieve the credit card's informations",
    "account.paymentMethods.label.validUntil"                    : "Valid Until",
    "account.paymentMethods.label.transactionInfo"               : "Transaction Info",
    "account.paymentMethods.label.paymentMode"                   : "Payment Mode",
    "account.paymentMethods.label.amountDue"                     : "Amount Due",
    "account.paymentMethods.label.invoiceNumber"                 : "Invoice Number",
    "account.paymentMethods.label.dueDate"                       : "Due Date",
    "account.paymentMethods.label.description"                   : "Description",
    "account.paymentMethods.label.total"                         : "Invoice Total",
    "account.paymentMethods.label.payment"                       : "{amount, plural, =0 {Payment} one {Payment} other {Payments}}",
    "account.paymentMethods.label.initPayment"                   : "Initial {amount, plural, =0 {payment} one {payment} other {payments}}",
    "account.paymentMethods.label.installments"                  : "{amount, plural, =0 {Installment} one {Installment} other {Installments}}",
    "account.paymentMethods.label.upcomingPaymentHint"           : "All upcoming payments will be updated",
    "account.paymentMethods.label.status.PENDING"                : "Pending",
    "account.paymentMethods.label.status.COMPLETED"              : "Completed",
    "account.paymentMethods.label.status.FAILED"                 : "Failed",
    "account.paymentMethods.label.status.DECLINED"               : "Declined",
    "account.paymentMethods.label.status.SUSPENDED"              : "Suspended",
    "account.paymentMethods.label.status.PARTIALLY_PAID"         : "Partially Paid",
    "account.paymentMethods.label.status.next"                   : "Next payment",

    // Modal specific
    "account.paymentMethods.editCard.modal.label.cardNb"               : "Card Number",
    "account.paymentMethods.editCard.modal.label.firstName.required"   : "First name is required",
    "account.paymentMethods.editCard.modal.label.lastName.required"    : "Last name is required",
    "account.paymentMethods.editCard.modal.label.address.invalid"      : "Invalid address",
    "account.paymentMethods.editCard.modal.label.exp"                  : "Expiration Date",
    "account.paymentMethods.editCard.modal.label.cvv"                  : "CVV",
    "account.paymentMethods.editCard.modal.label.newCardAdded.message" : "New card added to your account",
    "account.paymentMethods.editCard.modal.label.newCardAdded.info"    : "Select the new card if you wish to pay with it",

    "account.paymentMethods.removeCard.modal.title"       : "You are about to delete this card from your vault",
    "account.paymentMethods.removeCard.modal.success"     : "Card deleted",
    "account.paymentMethods.removeCard.modal.errors.6027" : "This card is linked to a payment and cannot be deleted.",

    "account.paymentMethods.changeCard.cards.title"                     : "Cards",
    "account.paymentMethods.changeCard.modal.title"                     : "Manage Transaction",
    "account.paymentMethods.changeCard.modal.validation.card.required"  : "Please select a card",
    "account.paymentMethods.changeCard.modal.validation.card.different" : "Please select a different card then the currently used card",
    "account.paymentMethods.changeCard.sportPay.text"                   : "<pa>This transaction is labelled as a SportsPay transaction. To change the payment credit card, you will be redirected to SportPay's payment gateway to process the change.</pa> <pa>Once completed, you will be redirected here with a confirmation message.</pa> <pa>Please click on accept to change your card on SportPay's payment gateway.</pa>",
    "account.paymentMethods.changeCard.sportPay.btn.manage"             : "Manage on SportsPay",
    "account.paymentMethods.changeCard.sportPay.success.text"           : "The payment method of this transaction and its future installments have been changed.",

    "account.paymentMethods.sportPayReturnModal.success.title"               : "Success!",
    "account.paymentMethods.sportPayReturnModal.success.text"                : "Card has been changed.",
    "account.paymentMethods.sportPayReturnModal.success.initialPayment.text" : "Payment successful.",
    "account.paymentMethods.sportPayReturnModal.success.helper"              : "The payment will be processed on the scheduled date.",
    "account.paymentMethods.sportPayReturnModal.success.btn"                 : "Got it!",

    "account.paymentMethods.sportPayReturnModal.pending.title" : "Processing",
    "account.paymentMethods.sportPayReturnModal.pending.text"  : "Your changes have been submitted and are currently pending. Please come back later.",

    "account.paymentMethods.sportPayReturnModal.declined.title" : "Declined",
    "account.paymentMethods.sportPayReturnModal.declined.text"  : "Your changes have been declined. Please contact our support team or try again later.",

    "account.paymentMethods.sportPayReturnModal.error.title" : "Something went wrong",
    "account.paymentMethods.sportPayReturnModal.error.text"  : "We have trouble checking the status of your changes. You can manually check if your changes have been processed or you can contact our support team.",

    "account.paymentMethods.sportPayReturnModal.loading.title" : "Loading...",
    "account.paymentMethods.sportPayReturnModal.loading.text"  : "Please wait while we process the changes you made.",

    "account.paymentMethods.paymentConfirmation.title" : "Congratulations!",
    "account.paymentMethods.paymentConfirmation.text"  : "The invoice {invoiceNumber} is now paid.",
}