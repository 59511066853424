import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const GoToHomeBtn = () => (
    process.env.REACT_APP_ENVIRONMENT === 'int' &&
        <Button to='/home' outline color='danger' tag={Link}>
            Go to home <span className='small'>(dev only button)</span>
        </Button>
)

export default GoToHomeBtn;