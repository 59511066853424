// relation
// gender
// primary lang
// citizenship
// birth country
// email
// identify indigenous
// (indigenous group)
// ethnicity
// (ethnicity note)

import { FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { Col, Collapse, Fade, FormGroup, Label, Row } from "reactstrap";
import Required from "../../../../../components/formik/Required";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";

const MemberRelationField = ({ fieldName }) => {
    const utilsContext = useContext(UtilsContext)

    return (
        <Col md='6'>
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-select'}><Translate id='form.fields.relation' /> <Required /></Label>
                <FormikSelect
                    id={fieldName + '-select'} name={fieldName}
                    loadData={(from) => {
                        switch (from){
                            case 'CDM':
                                return utilsContext.getRelations()
                                    .then((relations) => {
                                        return relations.map((relation) => ({
                                            value: relation.relation_id,
                                            label: relation.name,
                                            i18n: relation.i18n,
                                        }))
                                    })
                            default:
                                break;
                        }
                    }}
                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                />
            </FormGroup>
        </Col>
    )
}

const MemberGenderField = ({ fieldName }) => {
    return (
        <Col sm="6">
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-select'}><Translate id='misc.gender' /> <Required /></Label>
                <FormikSelect
                    id={fieldName + '-select'}
                    name={fieldName}
                    search={false}
                    defaultData={[
                        { label: 'misc.male', value: 'MALE' },
                        { label: 'misc.female', value: 'FEMALE' },
                        { label: 'misc.nonSpecified', value: 'PREFER_NOT_TO_SAY' },
                        { label: 'misc.preferToDescribe', value: 'PREFER_TO_SELF_DESCRIBE' },
                    ]}
                    renderOption={(option) => <Translate id={option.option.label} />}
                    loadingStatus="success"
                />
            </FormGroup>
        </Col>
    )
}

const MemberPrimaryLanguageField = ({ onOptionSelected, fieldName }) => {
    return (
        <Col sm="6">
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-select'}><Translate id='form.fields.primaryLanguage.placeholder' /> <Required /></Label>
                <FormikSelect
                    id={fieldName + '-select'} name={fieldName}
                    search={false}
                    defaultData={[
                        { label: 'misc.en', value: 'en' },
                        { label: 'misc.fr', value: 'fr' },
                    ]}
                    renderOption={(option) => <Translate id={option.option.label} />}
                    loadingStatus="success"
                    onOptionSelected={onOptionSelected}
                />
            </FormGroup>
        </Col>
    )
}

const MemberCitizenshipField = ({ fieldName }) => {
    const utilsContext = useContext(UtilsContext)
    const i18nContext = useContext(I18nContext)

    return (
        <Col sm="6">
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-select'}><Translate id='form.fields.nationality' /> <Required /></Label>
                <FormikSelect
                    id={fieldName + '-select'} name={fieldName}
                    multi clearable hideSelectAll
                    textWhenSetting={{ count: 2, label: "form.fields.nationality.selected" }}
                    loadData={(from) => {
                        switch (from){
                            case 'CDM':
                                return utilsContext.getCountries()
                                    .then((countries) => {
                                        const collator = new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' });
                                        return formatSelectData(
                                            countries.sort((a, b) => {
                                                if(a.code === 'ZZ')return 1
                                                if(b.code === 'ZZ')return -1
                                                const countryA = displayI18n('name', a.i18n, a.code, i18nContext.getGenericLocale());
                                                const countryB = displayI18n('name', b.i18n, b.code, i18nContext.getGenericLocale());

                                                return collator.compare(countryA, countryB);
                                            }),
                                            {
                                                getGroupId: (c) => [ 'CA', 'US' ].includes(c.code) ? 'suggested' : undefined,
                                                getValue: (c) => c.code,
                                                getLabel: (c) => c.code,
                                                newGroupIndexes: {
                                                    0: {
                                                        groupId: 'suggested',
                                                        label: 'misc.select.suggested',
                                                    },
                                                },
                                            },
                                        )
                                    })
                            default:
                                break;
                        }
                    }}
                    renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='citizenship_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                />
            </FormGroup>
        </Col>
    )
}

const MemberBirthCountryField = ({ fieldName }) => {
    const utilsContext = useContext(UtilsContext)
    const i18nContext = useContext(I18nContext)

    return (
        <Col sm="6">
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-select'}><Translate id='form.fields.birthCountry' /> <Required /></Label>
                <FormikSelect
                    id={fieldName + '-select'} name={fieldName}
                    loadData={(from) => {
                        switch (from){
                            case 'CDM':
                                return utilsContext.getCountries()
                                    .then((countries) => {
                                        return formatSelectData(countries.filter((c) => !!c?.i18n?.[i18nContext.getGenericLocale()]?.citizenship_name).map((country) => {
                                            return {
                                                value: country.code,
                                                label: country.i18n[i18nContext.getGenericLocale()].citizenship_name,
                                                i18n: country.i18n,
                                            }
                                        }), {
                                            newGroupIndexes: {
                                                0: {
                                                    label: "misc.select.suggested",
                                                    groupId: "suggested",
                                                },
                                            },
                                            getGroupId: (option) => (option.value === 'CA' || option.value === 'US') ? 'suggested' : undefined,
                                        })
                                    })
                            default:
                                break;
                        }
                    }}
                    renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                />
            </FormGroup>
        </Col>
    )
}

const MemberEmailField = ({ fieldName }) => {
    return (
        <Col sm="6">
            <FormGroup>
                <Label id={fieldName} for={fieldName + '-input'}><Translate id='form.fields.email' /> <Required /></Label>
                <FormikInputText id={fieldName + '-input'} name={fieldName} trim />
            </FormGroup>
        </Col>
    )
}

const MemberIdentifyAsIndigenousField = ({ identifyAsIndigenousOnSelected, fieldName, secondaryFieldName }) => {
    const utilsContext = useContext(UtilsContext)
    const formik = useFormikContext();

    return (
        <Col sm='12'>
            <Row form>
                <Col sm="12">
                    <hr />
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Label id={fieldName} for={fieldName + "-select"}><Translate id='form.fields.identifyAsIndigenous' /> <Required /></Label>
                        <FormikSelect
                            id={fieldName + "-select"}
                            name={fieldName}
                            search={false}
                            defaultData={[
                                { label: 'misc.yes', value: 'YES' },
                                { label: 'misc.no', value: 'NO' },
                                { label: 'misc.nonSpecified', value: 'PREFER_NOT_TO_SAY' },
                            ]}
                            renderOption={(option) => <Translate id={option.option.label} />}
                            loadingStatus="success"
                            onOptionSelected={identifyAsIndigenousOnSelected}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <Fade in={formik.values[fieldName] === 'YES'}>
                        <FormGroup>
                            <Label id={secondaryFieldName} for={secondaryFieldName + '-select'}><Translate id='form.fields.indigenousGroup' /> <Required /></Label>
                            <FormikSelect
                                id={secondaryFieldName + '-select'} name={secondaryFieldName}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return utilsContext.getIndigenousGroups()
                                                .then((groups) => {
                                                    return groups.sort((a, b) => a.display_order - b.display_order).map((group) => ({
                                                        value: group.indigenous_group_id,
                                                        label: group.name,
                                                        i18n: group.i18n,
                                                    }))
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                            />
                        </FormGroup>
                    </Fade>
                </Col>
            </Row>
        </Col>
    )
}

const MemberEthnicityField = ({ ethnicityOnSelected, showNote, fieldName, secondaryFieldName }) => {
    const utilsContext = useContext(UtilsContext)

    return (
        <Col sm='12'>
            <Row form>
                <Col sm="12">
                    <hr />
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Label id={fieldName} for={fieldName + '-select'}><Translate id='form.fields.ethnicity' /> <Required /></Label>
                        <FormikSelect
                            id={fieldName + '-select'} name={fieldName}
                            loadData={(from) => {
                                switch (from){
                                    case 'CDM':
                                        return utilsContext.getEthnicities()
                                            .then((ethnicities) => {
                                                return ethnicities.sort((a, b) => a.display_order - b.display_order).map((ethnicity) => ({
                                                    value: ethnicity.ethnicity_id,
                                                    label: ethnicity.name,
                                                    i18n: ethnicity.i18n,
                                                    withNote: ethnicity.with_note == 1,
                                                }))
                                            })
                                    default:
                                        break;
                                }
                            }}
                            renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                            onOptionSelected={ethnicityOnSelected}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <Collapse isOpen={showNote}>
                        <Fade in={showNote}>
                            <FormGroup>
                                <Label id={secondaryFieldName} for={secondaryFieldName + '-input'}><Translate id='form.fields.ethnicityNote' /> <Required /></Label>
                                <FormikTextArea id={secondaryFieldName + '-input'} name={secondaryFieldName} rows={1} trim />
                            </FormGroup>
                        </Fade>
                    </Collapse>
                </Col>
            </Row>
        </Col>
    )
}

export{
    MemberRelationField,
    MemberGenderField,
    MemberPrimaryLanguageField,
    MemberCitizenshipField,
    MemberBirthCountryField,
    MemberEmailField,
    MemberIdentifyAsIndigenousField,
    MemberEthnicityField,
}