/**
 *  Allows us to round money values while maintaining as much precision as possible.
 *  Number.EPSILON allows us to round to 2 decimals without rounding to the unit.
 *  @see https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
*/
export const roundForMoney = (number) => {
    return Math.round((parseInt(number) + Number.EPSILON) * 100) / 100 / 100;
}

/**
 * Function to get all the HTTP headers according to the current environment
 */
export function getVersionHeaders(){
    if(process.env.REACT_APP_ENVIRONMENT !== 'stage' && process.env.REACT_APP_VERSION_ID && process.env.REACT_APP_VERSION_PRODUCT && process.env.REACT_APP_VERSION_CLIENT && process.env.REACT_APP_VERSION_ENVIRONMENT)
        return {
            'X-Version': process.env.REACT_APP_VERSION_ID,
            'X-Product': process.env.REACT_APP_VERSION_PRODUCT,
            'X-Client': process.env.REACT_APP_VERSION_CLIENT,
            'X-Environment': process.env.REACT_APP_VERSION_ENVIRONMENT,
        }
    if(process.env.NODE_ENV !== 'development'){
        console.warn('Version variables are not set');
    }
}

/**
 * Helper function that can be used to make a pause
 * @param {number} time Ms
 * @returns {Promise}
 */
export async function delay(time){
    return new Promise((resolve) => setTimeout(resolve, time));
}