export default{
    "misc.add"                                  : "Ajouter",
    "misc.all"                                  : "Tous",
    "misc.required"                             : "Requis",
    "misc.none"                                 : "Aucun",
    "misc.other"                                : "Autre",
    "misc.after"                                : "Après",
    "misc.back"                                 : "Retour",
    "misc.before"                               : "Avant",
    "misc.birthdate.format"                     : "AAAA-MM-JJ",
    "misc.cancel"                               : "Annuler",
    "misc.results"                              : "Résultats",
    "misc.result"                               : "Résultat",
    "misc.clear"                                : "Effacer",
    "misc.close"                                : "Fermer",
    "misc.confirm"                              : "Confirmer",
    "misc.submit"                               : "Soumettre",
    "misc.create"                               : "Créer",
    "misc.edit"                                 : "Modifier",
    "misc.en"                                   : "Anglais",
    "misc.english"                              : "Anglais",
    "misc.gotIt"                                : "Compris",
    "misc.error"                                : "Une erreur s'est produite",
    "misc.female"                               : "Femme",
    "misc.fr"                                   : "Français",
    "misc.show"                                 : "Afficher",
    "misc.french"                               : "Français",
    "misc.gender"                               : "Identité de genre",
    "misc.googlePlaces.open"                    : "Ouvrir dans Google Maps",
    "misc.googlePlaces.placeholder"             : "Indiquez un emplacement",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Entrer l'adresse manuellement",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indiquer l'adresse avec l'assistant Google",
    "misc.lang.en"                              : "EN",
    "misc.lang.fr"                              : "FR",
    "misc.lang.long.en"                         : "Anglais",
    "misc.lang.long.fr"                         : "Français",
    "misc.link"                                 : "Lier",
    "misc.lessFilter"                           : "Masquer les filtres",
    "misc.loading"                              : "Chargement...",
    "misc.male"                                 : "Homme",
    "misc.more"                                 : "Plus",
    "misc.moreFilter"                           : "Afficher les filtres",
    "misc.viewMore"                             : "Voir plus",
    "misc.viewCountMore"                        : "Voir {count} de plus",
    "misc.viewLess"                             : "Voir moins",
    "misc.next"                                 : "Suivant",
    "misc.yes"                                  : "Oui",
    "misc.no"                                   : "Non",
    "misc.nonBinary"                            : "Non Binaire",
    "misc.nonSpecified"                         : "Je préfère ne pas répondre",
    "misc.preferToDescribe"                     : "Je préfère me décrire moi-même",
    "misc.noResults"                            : "Aucun résultat",
    "misc.noSelection"                          : "Aucune selection possible",
    "misc.noTranslation"                        : "Aucune traduction disponible",
    "misc.ok"                                   : "Ok",
    "misc.optional"                             : "Optionnel",
    "misc.mandatory"                            : "Obligatoire",
    "misc.or"                                   : "Ou",
    "misc.previous"                             : "Précédent",
    "misc.price"                                : "Prix",
    "misc.priceValue"                           : "{price} $",
    "misc.remove"                               : "Retirer",
    "misc.status"                               : "Statut",
    "misc.save"                                 : "Enregistrer",
    "misc.search"                               : "Rechercher",
    "misc.searchResults"                        : "Résultat(s) de recherche",
    "misc.select.placeholder"                   : "Sélectionner...",
    "misc.select"                               : "Sélectionner",
    "misc.success"                              : "Succès",
    "misc.warning"                              : "Avertissement",
    "misc.danger"                               : "Erreur",
    "misc.info"                                 : "Information",
    "misc.select.suggested"                     : "Suggéré",
    "misc.SimpleSearchTable.search.placeholder" : "Rechercher",
    "misc.update"                               : "Mettre à jour",
    "misc.yrs"                                  : "ans",
    "misc.export"                               : "Exporter",
    "misc.excel"                                : "Excel",
    "misc.pdf"                                  : "PDF",
    "misc.print"                                : "Imprimer",
    "misc.actions"                              : "Actions",
    "misc.action"                               : "Action",
    "misc.delete"                               : "Supprimer",
    "misc.active"                               : "Actif",
    "misc.inactive"                             : "Inactif",
    "misc.fatalError.goback"                    : "Revenir sur la plateforme",
    "misc.download"                             : "Télécharger",
    "misc.between"                              : "entre",
    "misc.and"                                  : "et",
    "misc.new"                                  : "Nouveau",

    "misc.notSupported.title"  : "Ce fureteur n'est pas supporté",
    "misc.notSupported.text.1" : "La version de votre fureteur n'est pas supportée par notre plateforme",
    "misc.notSupported.text.2" : "SVP, veuillez le mettre à jour ou essayez un différent fureteur",

    "misc.copy.1" : "Copier",
    "misc.copy.2" : "Contenu copié !",
    "misc.copy.3" : "Impossible de copier",

    "misc.daysAgo"  : "Il y a {days, plural, =0 {# jour} one {# jour} other {# jours}}",
    "misc.hoursAgo" : "Il y a {hours, plural, =0 {# heure} one {# heure} other {# heures}}",
    "misc.yearsOld" : "{age} ans",

    // Clipboard helper
    "misc.clipboard.success"         : "Copié dans le presse-papier!",
    "misc.clipboard.address.success" : "Adresse copiée!",
    "misc.clipboard.copy"            : "Copier",
    "misc.clipboard.error"           : "Impossible de copier dans le presse-papiers",
    "misc.addressClipboard.success"  : "Adresse copiée dans le presse-papiers! ",
    "misc.addressClipboard.error"    : "Impossible de copier l'adresse.",

    // Search
    "misc.search.empty.title"         : "Aucun résultat...",
    "misc.search.empty.message"       : "Vous pouvez effectuer une recherche ou utiliser la recherche avancée en haut de cette page.",
    "misc.search.empty.message.short" : "Vous pouvez utilisez la recherche en haut de cette page.",

    // file upload
    "misc.fileUpload.text.1"  : "Ajouter un document",
    "misc.fileUpload.text.2"  : "ou glissez le ici",
    "misc.fileUpload.maxSize" : "Taille maximale du fichier",

    // i18nReview
    "misc.i18nReview.correctionLogged"   : "Correction a été enregistrée!",
    "misc.i18nReview.correctionAccepted" : "La correction a été acceptée",
}