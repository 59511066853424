import { Redirect } from 'react-router-dom';
import SpordleConfirmAccount from '../../views/spordle/confirm';
import HCRConfirmAccount from '../../views/hcr/confirm';
import TourConfirmAccount from '../../views/tournaments/confirm';
import { LOGINTYPE } from '../../helpers/constants';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import { isMultiSport } from '../../helpers/getReferer';

const ConfirmationPicker = (props) => {
    switch (getLocalStorageItem('platformLogin') || LOGINTYPE.SPORDLE){
        case LOGINTYPE.HCR:
        case LOGINTYPE.PLAY:
        case LOGINTYPE.CLINIC:
            if(!isMultiSport())
                return <HCRConfirmAccount {...props} />;
        case LOGINTYPE.SPORDLE:
            return <SpordleConfirmAccount {...props} />;
        case 'TOUR':
            return <TourConfirmAccount {...props} />;

        default:
            return <Redirect to='/404' />
    }
}

export default ConfirmationPicker;