import { FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import FormikEditable from "../../../../../../components/formik/FormikEditable";

const SupervisionSidePanelNotes = ({ supervision, isReadOnly, updateSupervisionReport }) => {
    return (
        <>
            <div className="h4 font-bold"><Translate id="account.members.coach.sidePanel.tab.notes" /></div>
            <div className="text-muted"><Translate id='account.members.coach.strengths.label' /></div>
            <div className="mb-3 p-3 rounded-lg border bg-light-inverse">
                <FormikEditable
                    id="strengths"
                    disabled={isReadOnly}
                    className="editable-transparent"
                    initialValues={{
                        strengths: supervision.strengths || "",
                    }}
                    onSubmit={(values) => {
                        if(values.strengths !== supervision.strengths){
                            updateSupervisionReport(values);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium">{supervision.strengths || "-"}</div>
                            )
                        }
                        return <FormikTextArea name="strengths" id="strengths" />
                    }}
                </FormikEditable>
            </div>
            <div className="text-muted"><Translate id='account.members.coach.areaOfImprovement.label' /></div>
            <div className="mb-3 p-3 border bg-light-inverse rounded-lg">
                <FormikEditable
                    id="areas_of_improvements"
                    className="editable-transparent"
                    disabled={isReadOnly}
                    initialValues={{
                        areas_of_improvements: supervision.areas_of_improvements || "",
                    }}
                    onSubmit={(values) => {
                        if(values.areas_of_improvements !== supervision.areas_of_improvements){
                            updateSupervisionReport(values);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium">
                                    {supervision.areas_of_improvements || "-"}
                                </div>
                            )
                        }
                        return <FormikTextArea name="areas_of_improvements" id="areas_of_improvements" />
                    }}
                </FormikEditable>
            </div>
            <div className="text-muted"><Translate id='account.members.coach.comments.label' /></div>
            <div className="mb-3 p-3 border bg-light-inverse rounded-lg">
                <FormikEditable
                    id="comments"
                    className="editable-transparent"
                    disabled={isReadOnly}
                    initialValues={{
                        comments: supervision.comments || "",
                    }}
                    onSubmit={(values) => {
                        if(values.comments !== supervision.comments){
                            updateSupervisionReport(values);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium">
                                    {supervision.comments || "-"}
                                </div>
                            )
                        }
                        return <FormikTextArea name="comments" id="comments" />
                    }}
                </FormikEditable>
            </div>
            {!isReadOnly &&
                <>
                    <div className="text-muted"><Translate id='account.members.coach.privateComment.label' /></div>
                    <div className="mb-3 p-3 border bg-light-inverse rounded-lg">
                        <FormikEditable
                            id="private_comment"
                            className="editable-transparent"
                            disabled={isReadOnly}
                            initialValues={{
                                private_comment: supervision.private_comment || "",
                            }}
                            onSubmit={(values) => {
                                if(values.private_comment !== supervision.private_comment){
                                    updateSupervisionReport(values);
                                }
                            }}
                        >
                            {(isEditing, _options, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className="font-medium">
                                            {supervision.private_comment || "-"}
                                        </div>
                                    )
                                }
                                return <FormikTextArea name="private_comment" id="private_comment" />
                            }}
                        </FormikEditable>
                    </div>
                </>
            }
        </>
    );
}

export default SupervisionSidePanelNotes;