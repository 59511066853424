import moment from "moment";
import { AxiosIsCancelled } from "../api/CancellableAPI";
import { triggerDownload } from "../components/uploader/uploadHelpers";
import { exportToCsv } from "./export";
import { roundForMoney } from "./helper";
import { DisplayI18n, displayI18n } from "./i18nHelper";
import { fail } from '@spordle/toasts';

export const MONEY_FIELDS = [
    'affiliation_paid',
    'affiliation_to_paid',
    'amount_paid',
    'amount_to_paid',
    'rebate_amount',
    'registration_amount',
    'registration_early_amount',
    'registration_late_amount',
    'amount_owed',
    'late_amount',
    'scheduled_installment',
    'balance',
    'credit_amount',
    'clinic_additional_fee',
    'clinic_amount',
    'due_amount',
    'owed_amount',
    'paid_amount',
    'rebate_amount',
    'total_amount',
    'refund_amount',
];

export const exportReportsToCSV = (reports, intl, columns) => {
    const columnHeaders = columns.map((column) => (intl.formatMessage({ id: `reports.column.header.${column.key}` })));

    const data = reports.map((report) => columns.map((column) => {
        if(report[column.key] == '-' || report[column.key] == null || report[column.key] == undefined){
            return '';
        }else if(!isNaN(report[column.key]) && MONEY_FIELDS.includes(column.key)){
            return roundForMoney(report[column.key]) || 0;
        }else if(column.key === 'unique_identifier' || column.key === 'invoice_number' || column.key === 'street_number'){
            return `="${report[column.key]}"`;
        }else if(column.key === 'invoice_date'){
            return report[column.key] ? moment(report[column.key]).format('YYYY-MM-DD') : '';
        }else if(column.key === 'status'){
            return intl.formatMessage({ id: `reports.column.status.${report[column.key]}` });
        }else if(column.key === 'origin'){
            return intl.formatMessage({ id: `reports.column.origin.${report[column.key]}` });
        }else if(column.key === 'fee_type'){
            return intl.formatMessage({ id: `reports.registration.type.${report[column.key]}` });
        }
        return report[column.key] || '';

    }));

    exportToCsv(`SpordleID-Reports_${moment().format('YYYY-MM-DD H:mm')}.csv`, [ columnHeaders, ...data ]);
};

export const exportQuestionnaireReportsToCSV = (reports, formatMessage, columns, formGroups, locale) => {
    const columnHeaders = columns.map((column) => {
        if(column.isCustomField){
            let formGroup = false
            let formField = false
            for(let groupIndex = 0; groupIndex < formGroups.length; groupIndex++){
                if(!formField){
                    formField = formGroups[groupIndex].fields.find((e) => e.custom_form_field_id === column.key);
                    formGroup = formGroups[groupIndex]
                }
            }
            return displayI18n('title', formField?.i18n, formField?.title, locale) + ' (' + displayI18n('name', formGroup?.i18n, formGroup?.name, locale) + ')'
        }
        return formatMessage({ id: `reports.column.header.${column.key}` })

    });

    const data = reports.map((report) => columns.map((column) => {
        if(report[column.key] == '-' || report[column.key] == null || report[column.key] == undefined){
            return '';
        }else if(!isNaN(report[column.key]) && MONEY_FIELDS.includes(column.key)){
            return roundForMoney(report[column.key]) || 0;
        }else if(column.key === 'unique_identifier' || column.key === 'invoice_number' || column.key === 'street_number'){
            return `="${report[column.key]}"`;
        }
        return report[column.key] || '';

    }));

    exportToCsv(`SpordleID-QuestionnaireReports_${moment().format('YYYY-MM-DD H:mm')}.csv`, [ columnHeaders, ...data ]);
};

/**
 * The helper that handles exporting excel reports
 * @param {object} createQuery
 * @param {object} reportsQuery
 * @param {function} createQuery.setIsLoading the function to set loading when using an isLoading for checking if export is currently available (sets to false or true)
 * @param {string} createQuery.reportType the string representing the type of report
 * @param {object} createQuery.filters the filters used on the call that gets the data, must be pre formatted in the way they would be used for the original call
 * @param {number} createQuery.totalCount total count
 * @param {object} createQuery.reportsContext reportsContext
 * @param {string} createQuery.identity_role_id identity_role_id
 * @param {string} createQuery.identity_id identity_id
 * @param {string} createQuery.genericLocale genericLocale
 * @param {string} createQuery.organizationId organizationId from the organization context, the current org.
 */
export const handleExcelExport = (createQuery, reportsQuery = {}) => {
    const {
        setIsLoading,
        reportType,
        filters = {},
        totalCount,
        reportsContext,
        identity_id,
        genericLocale,
        organizationId,
    } = createQuery;

    setIsLoading?.(true);

    // The function below is to get the uuid of the report_type (report_id)
    reportsContext.getReports(reportsQuery)
        .then((report_type) => {
            const reportTypeId = report_type.find((type) => type.code === reportType).report_id;

            // This call trigger waiting toast and requests the report from API
            reportsContext.createExportableReport({
                organisation_id: organizationId,
                total_count: totalCount,
                language_code: genericLocale,
                report_id: reportTypeId,
                requested_by: identity_id,
                request_parameter: JSON.stringify(filters),
                output: 'EXCEL',
            })
                .then((data) => {
                    if(data.download_link){
                        triggerDownload(data.download_link)
                    }
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e.message);
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }).catch((e) => {
            if(!AxiosIsCancelled(e.message)){
                console.error(e.message);
            }
        })

    setIsLoading?.(false);
}