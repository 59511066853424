export default{
    "recovery.title.accountRecovery" : "Account Recovery",

    "recovery.label.enterEmail"         : "Please enter your email address",
    "recovery.label.noEmail"            : "Didn't receive an email?",
    "recovery.label.enterNewPassword"   : "Please enter your new password",
    "recovery.label.confirmNewPassword" : "Confirm your new password",

    "recovery.validation.password.required"        : "Password is required",
    "recovery.validation.password.space"           : "Password cannot have spaces",
    "recovery.validation.password.letter"          : "Must contain one letter",
    "recovery.validation.password.number"          : "Must contain 1 number",
    "recovery.validation.password.uppercase"       : "Must contain one uppercase",
    "recovery.validation.password.lenght"          : "Must be at least 6 characters long",
    "recovery.validation.passwordConfirm.required" : "Please confirm your password",
    "recovery.validation.passwordConfirm.match"    : "Passwords must match",

    "recovery.action.backToLogin"        : "Back to login",
    "recovery.action.sendEmail"          : "Send an email",
    "recovery.action.confirmNewPassword" : "Submit my new password",

    "recovery.success.emailSent"   : "An email has been sent to the following email address. Please click the link in the email to reset your password.",
    "recovery.success.newPassword" : "Your password has been changed!",

    "recovery.error.genericError"  : "A problem occured. Please refresh the page and try again.",
    "recovery.error.requiredEmail" : "An email address is required.",
    "recovery.error.invalidEmail"  : "Please enter a valid email.",

    "recovery.error.server"                 : "A problem occured. Please refresh the page and try again.",
    "recovery.error.ExpiredCodeException"   : "Verification code has expired.",
    "recovery.error.CodeMismatchException"  : "The verification code is invalid. Try Again.",
    "recovery.error.LimitExceededException" : "Attempt limit exceeded, please try again later.",
    "recovery.error.300"                    : "The email provided is not linked to any account.",
    "recovery.error.204"                    : "There was a problem. Please contact technical support.",
}