import Translate from '@spordle/intl-elements';
import { Link } from 'react-router-dom';

// account's 404 page not found
const PageNotFound404 = () => {
    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                    */}
            {/*--------------------------------------------------------------------------------*/}
            <header className="p-3 shadow-sm bg-white">
                <div className="flex-grow-0 h3 font-medium text-primary mb-0"><Translate id='account.custom.pageNotFound404.pageTitle' /></div>
            </header>
            {/* <ViewHeader>
                <ViewHeaderTitle>404</ViewHeaderTitle>
            </ViewHeader> */}

            <div className="page-content container-fluid">
                <div className="text-center">
                    <div className="display-4 mb-5"><Translate id='account.custom.pageNotFound404.title' /></div>
                    <Link to={'/'} className="btn btn-primary"><Translate id='account.custom.pageNotFound404.backToDashboard' /></Link>
                </div>
            </div>
        </>
    );
}

export default PageNotFound404;