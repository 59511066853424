import { Component } from 'react';
import {
    Button,
    Card,
    Alert,
    Spinner
} from 'reactstrap';
import { AuthContext } from '../../contexts/contexts';

// Components
import Translate from '@spordle/intl-elements';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';

import SpordleLogo from '../../assets/images/logos/spordleMyAccount.svg';
import Antispam from '@spordle/anti-spam';

import PinField from 'react-pin-field';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { AuthenticatorContext } from '../../components/authentication/AuthenticatorProvider';
import withContexts from '../../helpers/withContexts';
import AuthLayout from '../../layouts/layout-components/auth/AuthLayout';
import { fail, fire, success } from '@spordle/toasts';

class ConfirmAccount extends Component{
    static contextType = AuthenticatorContext;

    componentDidMount(){
        this.pinRef[0].focus();
        if(!this.props.AuthenticatorContext.email){
            this.props.AuthenticatorContext.goToPage('LOGIN');
        }else if(this.props.location.from === 'UNCONFIRMED'){
            this.sendEmail()
        }else if(this.props.location.from !== 'SIGNUP')
            this.sendEmailConfirmationCode();
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    sendEmailConfirmationCode = () => {
        this.props.AuthContext.sendAttributeCode('email')
            .then(() => {
                success({ msg: 'login.form.action.send_email.success' });
            }, (error) => {
                if(!AxiosIsCancelled(error.message)){
                    fail({ msg: 'confirm-account.error.resendEmail' });
                }
            })
    }

    sendEmail = () => {
        this.props.AuthContext.resendEmail(this.props.AuthenticatorContext.email)
            .then(() => {
                success({ msg: 'login.form.action.send_email.success' });
            }, (error) => {
                if(!AxiosIsCancelled(error.message)){
                    fail({ msg: 'confirm-account.error.resendEmail' });
                }
            })
    }

    render(){
        return (
            <>
                <AuthLayout>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        onSubmit={({ code }, { setStatus, setSubmitting }) => {
                            setStatus();

                            if(this.props.location.from === 'UNCONFIRMED' || this.props.location.from === 'SIGNUP'){
                                this.props.AuthenticatorContext.confirmAccount(code)
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            setStatus(error.message);
                                            setSubmitting(false);
                                        }
                                    });
                            }else{
                                this.props.AuthenticatorContext.verifyEmail(code, this.props.location.result)
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            setStatus(error.message);
                                            setSubmitting(false);
                                        }
                                    });
                            }
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Card body>
                                    <div className="ml-n2 mt-n2">
                                        <Link to={this.props.AuthenticatorContext.goToPage('LOGIN', true, true)} className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id='forgotPassword.action.backToLogin' /></Link>
                                    </div>
                                    <div className="text-center">
                                        <img className="mb-3 mt-2" src={SpordleLogo} width="80%" alt="Hockey Canada" />
                                        <div className="h5 mb-3"><Translate id='confirm-account.title' /></div>
                                        <div className="h6 text-muted mb-3">{this.props.AuthenticatorContext.email}</div>
                                    </div>
                                    <div className='mb-3 mx-n2 d-flex'>
                                        <PinField
                                            autoFocus ref={(r) => this.pinRef = r} className="min-w-0 form-control mx-2 text-center"
                                            validate="0123456789" disabled={formik.isSubmitting} length={6}
                                            onChange={(code) => {
                                                formik.setFieldValue('code', code)
                                            }}
                                        />
                                    </div>

                                    {formik.status &&
                                        <Alert color='danger'><Translate id={`confirm-account.error.${formik.status}`} defaultMessageId='confirm-account.error.server' /></Alert>
                                    }

                                    <div>

                                        <Button block color="spordle" type='submit' disabled={formik.isSubmitting || formik.values.code.length !== 6}>
                                            {formik.isSubmitting ?
                                                <Spinner size="sm" type='grow' color='light' />
                                                :
                                                <Translate id='confirm-account.action.verify' />
                                            }
                                        </Button>
                                    </div>
                                </Card>
                            </Form>
                        )}
                    </Formik>

                    <div className='text-center'>
                        <span><Translate id='signup.confirmed.notReceived' /></span>
                        <Antispam
                            timeoutDuration={10000}
                            clicksForTimeout={2}
                            resetClicksDuration={30000}
                            isSpamming={() => {
                                fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                            }}
                        >
                            {(_, antiSpamCallback) => (
                                <button
                                    id="resendEmailButton"
                                    onClick={(e) => {
                                        antiSpamCallback(e);
                                        if(this.props.location.from === 'UNCONFIRMED' || this.props.location.from === 'SIGNUP'){
                                            this.sendEmail();
                                        }else{
                                            this.sendEmailConfirmationCode();
                                        }
                                    }}
                                    type="button"
                                    className="ml-1 reset-btn text-link"
                                >
                                    <Translate id='signup.confirmed.resend' />
                                </button>
                            )}
                        </Antispam>
                    </div>
                </AuthLayout>
            </>
        );
    }
}

export default withContexts(AuthContext, AuthenticatorContext)(ConfirmAccount);