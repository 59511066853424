import { useContext, useState } from 'react';
import { Button, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import { FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup';
import Translate from '@spordle/intl-elements';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';

const AccountGender = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };
    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='form.fields.gender' />}
                value={(auth.userData.UserAttributes.gender) ? <Translate id={'form.fields.gender.' + auth.userData.UserAttributes.gender.toUpperCase()} /> : ''}
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountGender' isOpen={isOpen}>
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.gender' />
                </ModalHeader>
                <Formik
                    initialValues={{
                        gender: auth.userData.UserAttributes.gender ?? '',
                    }}
                    validationSchema={Yup.object().shape({
                        gender: Yup.string().required(<Translate id='signup.signupForm.validation.email.required' />),
                    })}
                    onSubmit={({ gender }, { setStatus, setSubmitting }) => {
                        setStatus();
                        auth.updateUserInfo({
                            ...auth.userData.UserAttributes,
                            gender: gender,
                        }).then(() => {
                            setStatus();
                            success({ msg: 'account.settings.profile.accountGender.update.toast.success' });
                            toggleModal();
                        })
                            .catch((error) => {
                                setStatus(error.message);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <FormikSelect
                                        id='gender'
                                        name='gender'
                                        label='form.fields.gender'
                                        translateLabel
                                        search={false}
                                        defaultData={[
                                            { label: 'form.fields.gender.MALE', value: 'male' },
                                            { label: 'form.fields.gender.FEMALE', value: 'female' },
                                            { label: 'form.fields.gender.prefer_not_to_say', value: 'other' },
                                        ]}
                                        renderOption={(option) => <Translate id={option.option.label} />}
                                        loadingStatus="success"
                                    />
                                </FormGroup>
                                <div className='d-flex align-items-center bg-light-inverse p-1 mt-4 rounded'>
                                    <i className='text-primary mdi mdi-information-outline mx-2' />
                                    <div className='small'>
                                        <Translate id='account.settings.profile.accountGender.private' />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    );
}

export default AccountGender;