export default{
    // tabs
    "account.members.ineligibleWarning"           : "This profile has been frozen by HCR Administrators.",
    "account.members.ineligibleWarning.potential" : "This profile has been flagged by HCR Administrators.",
    "account.members.ineligibleWarning.txt"       : "Please directly contact your provincial member of Hockey Canada for support.",
    "account.members.tabs.general"                : "General",
    "account.members.tabs.schedule"               : "Schedule",
    "account.members.tabs.memberCards"            : "Registrations",
    "account.members.tabs.certificates"           : "Qualifications",
    "account.members.tabs.waivers"                : "Waivers",
    "account.members.tabs.suspensions"            : "Suspensions",
    "account.members.tabs.crc"                    : "Background check",
    "account.members.tabs.gameincident"           : "Game Report",
    "account.members.tabs.documents"              : "Documents",
    "account.members.tabs.coach"                  : "Official",
    "account.members.tabs.travelPermits"          : "Travel permits",
    "account.members.tabs.contacts"               : "Contacts",

    "account.members.gameIncidentReport.title"  : "Game Incident Report",
    "account.members.schedule.title"            : "Schedule",
    "account.members.personalInfo.title"        : "Personal Information",
    "account.members.address.title"             : "Address",
    "account.members.otherInfo.title"           : "Other Information",
    "account.members.medicalInfo.title"         : "Medical Information",
    "account.members.crc.title"                 : "Background check",
    "account.members.linked.accounts"           : "Accounts linked to this member",
    "account.members.linked.accounts.personnel" : "Hockey Canada Personnel",
    "account.members.linked.accounts.other"     : "Spordle Accounts",
    "account.members.linked.accounts.no.linked" : "No linked accounts",
    "account.members.travelPermits.title"       : "Travel permits",

    "account.members.addMember.tooltip" : "Add a member",

    // Change single member address modal
    "account.members.header.changeAddress"                      : "Submit an address change",
    "account.members.changeSingleAddress.modal.success"         : "The new address has been published to the member's registration profile.",
    "account.members.changeSingleAddress.modal.success.subText" : "Please allow for 2-3 business days for changes to be applied.",
    "account.members.changeSingleAddress.modal.success.title"   : "Address published successfully",

    // member status badges
    "account.members.unsignedCriticalWaiver.tooltip" : "Mandatory waiver needs to be signed",
    "account.members.missingFields.tooltip"          : "Required fields are missing",
    "account.members.missingFields.organization"     : "Your organization requires these fields:",
    "account.members.unconfirmed.address.tooltip"    : "New address to be confirmed.",
    "account.members.incomplete_profile.tooltip"     : "Member Profile Incomplete",
    "account.members.missingAddressDocument.tooltip" : "Missing documents",

    // missing fields modal
    "account.members.missingFieldsModal.title"    : "Missing information",
    "account.members.missingFieldsModal.text"     : "The system needs this information for this member.",
    "account.members.missingFieldsModal.text2"    : "Please fill out the fields below.",
    "account.members.missingFieldsModal.required" : "This field is required",

    // Img uploads
    "account.members.img.dropdown.delete"   : "Delete picture",
    "account.members.img.dropdown.upload"   : "Upload picture",
    "account.members.img.deleteModal.title" : "Delete profile picture",
    "account.members.img.deleteModal.msg"   : "You are about to delete {name}'s profile picture",

    // Global
    "account.members.loading.error.title" : "Oops!",
    "account.members.loading.error.text"  : "Something went wrong. Please come back later or contact our support team.",

    // Registrations
    "account.members.registration.noRegistration.title" : "No registrations",
    "account.members.registration.noRegistration.text"  : "No registrations were found for {name}.",
    "account.members.registration.memberCreated"        : "Member creation!",

    // Qualifications
    "account.members.qualifications.certification.number"          : "Certification #",
    "account.members.qualifications.noQualifications.title"        : "No qualifications",
    "account.members.qualifications.noQualifications.text"         : "No qualifications were found for {name}.",
    "account.members.qualifications.qualificationCard.season"      : "Season",
    "account.members.qualifications.qualificationCard.validFromTo" : "Valid {from} to {to}",
    "account.members.qualifications.qualificationCard.validSince"  : "Valid since {date}",
    "account.members.qualifications.qualificationCard.willBeValid" : "Will be valid on {date}",

    // CRC
    "account.members.crc.column.createdBy.label"       : "Created by",
    "account.members.crc.column.createdBy.on"          : "On",
    "account.members.crc.column.createdBy.editedOn"    : "Last updated",
    "account.members.crc.column.crc_type.label"        : "Type",
    "account.members.crc.column.issue_date.label"      : "Validity date",
    "account.members.crc.column.expiration_date.label" : "Expires on",
    "account.members.crc.column.status.label"          : "Status",
    "account.members.crc.empty"                        : "No background check found",
    "account.members.crc.status.expired"               : "Expired",

    // TRAVEL PERMITS
    "account.members.travelPermits.travelId"                            : "Reference Number",
    "account.members.travelPermits.fee"                                 : "Travel Permit Fee",
    "account.members.travelPermits.print"                               : "Print (PDF)",
    "account.members.travelPermits.modalTitle"                          : "Add travel permit",
    "account.members.travelPermits.column.team"                         : "Team",
    "account.members.travelPermits.column.event"                        : "Event",
    "account.members.travelPermits.column.location"                     : "Location",
    "account.members.travelPermits.column.startDate"                    : "Start date",
    "account.members.travelPermits.column.endDate"                      : "End date",
    "account.members.travelPermits.column.status"                       : "Status",
    "account.members.travelPermits.addModal.teamLabel"                  : "Team",
    "account.members.travelPermits.addModal.titleLabel"                 : "Title",
    "account.members.travelPermits.addModal.titleLabel.tip"             : "Please enter the name of the event or a description of the reason for the travel permit",
    "account.members.travelPermits.addModal.eventTypeLabel"             : "Event Type",
    "account.members.travelPermits.addModal.travelIdLabel"              : "Travel Type",
    "account.members.travelPermits.addModal.hostWebsiteLabel"           : "Host Website",
    "account.members.travelPermits.addModal.hostNameLabel"              : "Host Team Name",
    "account.members.travelPermits.addModal.nbGamesLabel"               : "Number of games",
    "account.members.travelPermits.addModal.hostDivision"               : "Host Division",
    "account.members.travelPermits.addModal.hostClass"                  : "Host Class",
    "account.members.travelPermits.addModal.hostCategory"               : "Host Category",
    "account.members.travelPermits.addModal.hostCountry"                : "Host Country",
    "account.members.travelPermits.addModal.hostProvinceState"          : "Host Province / State",
    "account.members.travelPermits.addModal.hostCity"                   : "Host City",
    "account.members.travelPermits.addModal.hostOrganization"           : "Host Organization",
    "account.members.travelPermits.addModal.hostArena"                  : "Host Arena",
    "account.members.travelPermits.addModal.teamRequired"               : "Team is required.",
    "account.members.travelPermits.addModal.titleRequired"              : "Title is required.",
    "account.members.travelPermits.addModal.eventTypeRequired"          : "Event type is required.",
    "account.members.travelPermits.addModal.travelIdRequired"           : "Travel ID is required.",
    "account.members.travelPermits.addModal.hostNameRequired"           : "Host arena is required.",
    "account.members.travelPermits.addModal.hostCountryRequired"        : "Host country is required.",
    "account.members.travelPermits.addModal.hostProvinceRequired"       : "Host province is required.",
    "account.members.travelPermits.addModal.hostCityRequired"           : "Host city is required.",
    "account.members.travelPermits.addModal.hostDivisionRequired"       : "Host division is required",
    "account.members.travelPermits.addModal.startDate"                  : "Start Date",
    "account.members.travelPermits.addModal.endDate"                    : "End Date",
    "account.members.travelPermits.addModal.notes"                      : "Notes",
    "account.members.travelPermits.addModal.attachments"                : "Attachments",
    "account.members.travelPermits.addModal.additionalAttachments"      : "Additional Attachments",
    "account.members.travelPermits.addModal.startDateRequired"          : "Start date is required.",
    "account.members.travelPermits.addModal.endDateRequired"            : "End date is required.",
    "account.members.travelPermits.panelHeader.travelId"                : "Travel Id",
    "account.members.travelPermits.sidePanelGeneral.tabTitle"           : "General",
    "account.members.travelPermits.sidePanelGeneral.HCRNumber"          : "Team HCR#",
    "account.members.travelPermits.sidePanelGeneral.status"             : "Status",
    "account.members.travelPermits.sidePanelGeneral.createdBy"          : "Created by",
    "account.members.travelPermits.sidePanelGeneral.location"           : "Location",
    "account.members.travelPermits.sidePanelGeneral.arena"              : "Arena",
    "account.members.travelPermits.sidePanelGeneral.city"               : "City",
    "account.members.travelPermits.sidePanelGeneral.countryProvince"    : "Country and Province/State",
    "account.members.travelPermits.sidePanelGeneral.dates"              : "Dates",
    "account.members.travelPermits.sidePanelGeneral.startDate"          : "Start Date",
    "account.members.travelPermits.sidePanelGeneral.startDate.format"   : "Start Date must be of a valid format",
    "account.members.travelPermits.sidePanelGeneral.startDate.required" : "Start Date is required",
    "account.members.travelPermits.sidePanelGeneral.startDate.before"   : "The Start Date must be before the End Date",
    "account.members.travelPermits.sidePanelGeneral.endDate"            : "End Date",
    "account.members.travelPermits.sidePanelGeneral.endDate.format"     : "End Date must be of a valid format",
    "account.members.travelPermits.sidePanelGeneral.endDate.required"   : "End Date is required",
    "account.members.travelPermits.sidePanelGeneral.endDate.after"      : "End Date must be after the Start Date",
    "account.members.travelPermits.sidePanelGeneral.note"               : "Note",
    "account.members.travelPermits.sidePanelGeneral.noOptions"          : "No options",
    "account.members.travelPermits.sidePanelGeneral.attachments"        : "Attachments",
    "account.members.travelPermits.sidePanelHost.tabTitle"              : "Host",
    "account.members.travelPermits.sidePanelHost.name"                  : "Name",
    "account.members.travelPermits.sidePanelHost.travelType"            : "Travel type",
    "account.members.travelPermits.sidePanelHost.eventType"             : "Event type",
    "account.members.travelPermits.sidePanelHost.division"              : "Division",
    "account.members.travelPermits.sidePanelHost.category"              : "Category",
    "account.members.travelPermits.sidePanelHost.class"                 : "Class",
    "account.members.travelPermits.sidePanelHost.website"               : "Website",
    "account.members.travelPermits.sidePanelHost.hostTeamName"          : "Host team name",
    "account.members.travelPermits.sidePanelHost.nbGames"               : "Nb Games",
    "account.members.travelPermits.toast.success"                       : "Travel permit updated.",
    "account.members.travelPermits.toast.fail"                          : "An error occurred during the Travel Permit update, please refresh the page et try again.",
    "account.members.travelPermits.deleteModal.message"                 : "You are about to remove this travel permit.",
    "account.members.travelPermits.submit.message"                      : "You are about to submit this travel permit.",
    "account.members.travelPermits.submit"                              : "Submit",
    "account.members.travelPermits.addModal.submit"                     : "Submit permit",
    "account.members.travelPermits.addModal.submit.subtitle"            : "The permit will be available for approbation",
    "account.members.travelPermits.addModal.notSubmit"                  : "Submit later",
    "account.members.travelPermits.addModal.notSubmit.subtitle"         : "You can bring changes to the permit and submit it later",

    // GIR
    "account.members.gir.title"                     : "Game Incident Report",
    "account.members.gir.search.alreadyAdded"       : "Already Added",
    "account.members.gir.sidePanel.delete.incident" : "You are about to delete this player incident",
    "account.members.gir.sidePanel.delete.official" : "You are about to delete this official from the game incident report",
    "account.members.gir.sidePanel.submit.btn"      : "Submit the report",
    "account.members.gir.sidePanel.submit.msg"      : "You are about to submit this game incident report",
    "account.members.gir.sidePanel.resubmit.btn"    : "Resubmit the report",
    "account.members.gir.sidePanel.resubmit.msg"    : "You are about to submit this game incident report again",
    "account.members.gir.sidePanel.lastUpdated"     : "This report was last updated on {date}",

    "account.members.gir.sidePanel.export.pdf"              : "Export to PDF",
    "account.members.gir.sidePanel.export.error.3382"       : "Something happened while generating the pdf",
    "account.members.gir.sidePanel.export.toast.info"       : "If your file wasn't automatically downloaded, try again by <a>clicking here.</a>",
    "account.members.gir.sidePanel.export.toast.generating" : "Generating...",
    "account.members.gir.sidePanel.export.toast.download"   : "Download!",

    "account.members.gir.addModal.title"                       : "Add Game Incident Report (Step {step} of {stepNb})",
    "account.members.gir.addModal.steps.officials.title"       : "Officials",
    "account.members.gir.addModal.steps.officials.add.title"   : "Adding official",
    "account.members.gir.addModal.steps.teams.title"           : "Teams",
    "account.members.gir.addModal.steps.teams.other"           : "Other",
    "account.members.gir.addModal.steps.teams.existing"        : "Existing",
    "account.members.gir.addModal.steps.match.title"           : "Game Info",
    "account.members.gir.addModal.steps.incident.title"        : "Incident",
    "account.members.gir.addModal.steps.status.title"          : "Do you want to submit this game incident report now?",
    "account.members.gir.addModal.steps.status.btn.yes"        : "Yes",
    "account.members.gir.addModal.steps.status.btn.yes.helper" : "I want to submit this game incident report",
    "account.members.gir.addModal.steps.status.btn.no"         : "No",
    "account.members.gir.addModal.steps.status.btn.no.helper"  : "I do not want to submit this report yet",

    "account.members.gir.addModal.label.season"               : "Season",
    "account.members.gir.addModal.label.class"                : "Class",
    "account.members.gir.addModal.label.division"             : "Division",
    "account.members.gir.addModal.label.org"                  : "Organization",
    "account.members.gir.addModal.label.category"             : "Category",
    "account.members.gir.addModal.label.league"               : "League",
    "account.members.gir.addModal.label.location"             : "Location",
    "account.members.gir.addModal.label.game"                 : "Game #",
    "account.members.gir.addModal.label.coach"                : "Coach",
    "account.members.gir.addModal.label.coachList"            : "Coach List",
    "account.members.gir.addModal.label.branch"               : "Branch",
    "account.members.gir.addModal.label.coach.option.other"   : "Non listed coach",
    "account.members.gir.addModal.label.coach.other"          : "Other Coach Name",
    "account.members.gir.addModal.label.manager"              : "Manager",
    "account.members.gir.addModal.label.managerList"          : "Staff List",
    "account.members.gir.addModal.label.manager.option.other" : "Non listed manager",
    "account.members.gir.addModal.label.manager.other"        : "Other Manager Name",
    "account.members.gir.addModal.label.team"                 : "Team",
    "account.members.gir.addModal.label.officials"            : "Officials",
    "account.members.gir.addModal.label.incident"             : "Incident",
    "account.members.gir.addModal.label.player"               : "Player",
    "account.members.gir.addModal.label.period"               : "Period",
    "account.members.gir.addModal.label.time"                 : "Time",
    "account.members.gir.addModal.label.minutes"              : "Minutes",
    "account.members.gir.addModal.label.seconds"              : "Seconds",
    "account.members.gir.addModal.label.infractionType"       : "Penalty Type",
    "account.members.gir.addModal.label.infraction"           : "Penalty",
    "account.members.gir.addModal.label.injury"               : "Sustained an injury",
    "account.members.gir.addModal.label.returned"             : "Returned to game",
    "account.members.gir.addModal.label.notReturned"          : "Didn't return to game",
    "account.members.gir.addModal.label.note"                 : "Note",
    "account.members.gir.addModal.label.jersey"               : "Jersey",
    "account.members.gir.addModal.label.date"                 : "Incident date",
    "account.members.gir.addModal.label.officialType"         : "Official Type",

    "account.members.gir.addModal.label.gamePeriod.FIRST"          : "First",
    "account.members.gir.addModal.label.gamePeriod.SECOND"         : "Second",
    "account.members.gir.addModal.label.gamePeriod.THIRD"          : "Third",
    "account.members.gir.addModal.label.gamePeriod.OVERTIME"       : "Overtime",
    "account.members.gir.addModal.label.gamePeriod.SHOOTOUT"       : "Shootout",
    "account.members.gir.addModal.label.gamePeriod.PRE_POST_GAME"  : "Pre-game / Post-game",
    "account.members.gir.addModal.steps.officials.type.REFEREE"    : "Referee",
    "account.members.gir.addModal.steps.officials.type.LINESMAN"   : "Linesperson",
    "account.members.gir.addModal.steps.officials.type.SUPERVISOR" : "Supervisor",

    "account.members.gir.addModal.validation.player"        : "Please select a player",
    "account.members.gir.addModal.validation.penaltyType"   : "Please select a penalty type",
    "account.members.gir.addModal.validation.penalty"       : "Please select a penalty",
    "account.members.gir.addModal.validation.team"          : "Please select a team",
    "account.members.gir.addModal.validation.season"        : "Please select a season",
    "account.members.gir.addModal.validation.division"      : "Please select a division",
    "account.members.gir.addModal.validation.category"      : "Please select a category",
    "account.members.gir.addModal.validation.phone"         : "The phone number is required for this official",
    "account.members.gir.addModal.validation.email"         : "The email is required for this official",
    "account.members.gir.addModal.validation.date"          : "Please enter an incident date",
    "account.members.gir.addModal.validation.date.valid"    : "Please enter a valid date",
    "account.members.gir.addModal.validation.officialType"  : "Please specify the official type",
    "account.members.gir.addModal.validation.date.future"   : "Date cannot be set in the future",
    "account.members.gir.addModal.validation.jersey.max"    : "Please enter a jersey number that is under 100",
    "account.members.gir.addModal.validation.time.min"      : "Please enter a number that is 0 or over",
    "account.members.gir.addModal.validation.time.max"      : "Please enter a number under {max}",
    "account.members.gir.addModal.validation.teamWarning"   : "Warning",
    "account.members.gir.addModal.validation.twoOtherTeams" : "One of the two teams must be an existing one.",
    "account.members.gir.addModal.validation.twoSameTeams"  : "Please select two different teams",
    "account.members.gir.addModal.validation.status"        : "Please select an game incident status",
    "account.members.gir.addModal.validation.organisation"  : "Please select an organization",
    "account.members.gir.addModal.validation.teamName"      : "Please specify the team name",
    "account.members.gir.addModal.validation.jerseyNb"      : "The jersey number must be a number",
    "account.members.gir.addModal.validation.coach.other"   : "Please specify the coach's name or clear the coach select.",
    "account.members.gir.addModal.validation.manager.other" : "Please specify the manager's name or clear the manager select.",

    "account.members.gir.addModal.teams.home.label" : "Home team",
    "account.members.gir.addModal.teams.away.label" : "Away team",

    "account.members.gir.addModal.officials.title"        : "Add Official",
    "account.members.gir.addModal.officials.btn.subtitle" : "Click here to add an official",

    "account.members.gir.addModal.incident.title"        : "Add Incident",
    "account.members.gir.addModal.incident.btn.subtitle" : "Click here to add an incident",

    // Clinics Management
    "account.members.clinicsManagement.noResults.title"                           : "No clinics",
    "account.members.clinicsManagement.noResults.msg"                             : "There are no clinics to manage for this instructor.",
    "account.members.clinicsManagement"                                           : "Clinic Management",
    "account.members.clinicsManagement.attendees"                                 : "Attendees",
    "account.members.clinicsManagement.attendees.count"                           : "{count, plural, =0 {No attendee} one {# attendee} other {# attendees}}",
    "account.members.clinicsManagement.attendees.label.passed"                    : "Passed",
    "account.members.clinicsManagement.attendees.label.paid"                      : "Paid",
    "account.members.clinicsManagement.backToList"                                : "Back to clinic's list",
    "account.members.clinicsManagement.attendees.label.attended"                  : "Attended",
    "account.members.clinicsManagement.attendees.sidePanel.selected"              : "{count, plural, =0 {No attendee} one {# attendee} other {# attendees}} selected",
    "account.members.clinicsManagement.attendees.attendeeInfo"                    : "Attendee Info",
    "account.members.clinicsManagement.attendees.attended.label"                  : "Presence",
    "account.members.clinicsManagement.attendees.paid"                            : "Paid",
    "account.members.clinicsManagement.attendees.attended"                        : "Attended",
    "account.members.clinicsManagement.attendees.attended.required"               : "Please select a present status",
    "account.members.clinicsManagement.attendees.attended.not"                    : "Did not attend",
    "account.members.clinicsManagement.attendees.attended.unable"                 : "The presence status cannot be changed if the attendee has passed the clinic",
    "account.members.clinicsManagement.attendees.attended.unable.multiple"        : "The presence status cannot be changed if one of the attendees has passed the clinic",
    "account.members.clinicsManagement.attendees.attended.date.required"          : "Presence date is required",
    "account.members.clinicsManagement.attendees.attended.date.before"            : "Presence date cannot be after the passed date",
    "account.members.clinicsManagement.attendees.passed.label"                    : "Result",
    "account.members.clinicsManagement.attendees.passed"                          : "Passed",
    "account.members.clinicsManagement.attendees.passed.not"                      : "Did not pass",
    "account.members.clinicsManagement.attendees.passed.unable"                   : "The passage status cannot be changed if the attendee has been certified for the clinic",
    "account.members.clinicsManagement.attendees.passed.unable.multiple"          : "The passage status cannot be changed if one of the attendees has been certified for the clinic",
    "account.members.clinicsManagement.attendees.passed.date.required"            : "Passed date is required",
    "account.members.clinicsManagement.attendees.passed.date.noPresence"          : "The participant must have attended the clinic to have passage status",
    "account.members.clinicsManagement.attendees.passed.date.noPresence.multiple" : "The participant must have all attended the clinic to have passage status",
    "account.members.clinicsManagement.attendees.passed.date.after"               : "Passed date cannot be before the presence date",
    "account.members.clinicsManagement.attendees.passed.date.before"              : "Passed date cannot be after the certification date",
    "account.members.clinicsManagement.attendees.certified.label"                 : "Certification",
    "account.members.clinicsManagement.attendees.certified"                       : "Certified",
    "account.members.clinicsManagement.attendees.certified.not"                   : "Not certified",
    "account.members.clinicsManagement.attendees.certified.hint"                  : "This action will create a qualification for this member. To specify a different date, a certification number or any other information, please use the \"Certify\" action.",
    "account.members.clinicsManagement.attendees.notSpecified"                    : "Not specified",
    "account.members.clinicsManagement.openStore"                                 : "Open clinic store",
    "account.members.clinicsManagement.copyStoreLink"                             : "Copy link to clinic store",
    "account.members.clinicsManagement.openMoodle"                                : "Open in Moodle",


    // Official
    "account.members.coach.homeTeam.label"                   : "Home Team",
    "account.members.coach.awayTeam.label"                   : "Away Team",
    "account.members.coach.team.label"                       : "Team",
    "account.members.coach.overallScore.label"               : "Overall ratio",
    "account.members.coach.official.label"                   : "Official",
    "account.members.coach.officiatingCoach.label"           : "Officiating Coach",
    "account.members.coach.supervisionReport.label"          : "Supervision Report",
    "account.members.coach.lastSent"                         : "Last Sent",
    "account.members.coach.season.label"                     : "Season",
    "account.members.coach.allReportsBtn.short"              : "All reports",
    "account.members.coach.allReportsBtn.long"               : "See all supervisions on this official",
    "account.members.coach.privateComment.label"             : "Private Comment",
    "account.members.coach.areaOfImprovement.label"          : "Area of improvements",
    "account.members.coach.comments.label"                   : "Comments",
    "account.members.coach.strengths.label"                  : "Strengths",
    "account.members.coach.teamCategory.label"               : "Team Category",
    "account.members.coach.teamName.label"                   : "Team Name",
    "account.members.coach.teamName.home.label"              : "Home Team Name",
    "account.members.coach.teamName.away.label"              : "Away Team Name",
    "account.members.coach.supervisionType.categories.label" : "Categories",
    "account.members.coach.supervisedBy.label"               : "Supervised by",
    "account.members.coach.officialType.label"               : "Official Type",
    "account.members.coach.officialType.REFEREE"             : "Referee",
    "account.members.coach.officialType.LINESPERSON"         : "Linesperson",
    "account.members.coach.officialType.OFFICIAL_SYSTEM"     : "Official System",

    "account.members.coach.mySupervisions"                                         : "My supervision forms",
    "account.members.coach.myReports"                                              : "My reports",
    "account.members.coach.allMemberReports"                                       : "All {name} supervisions",
    "account.members.coach.allMemberReports.backToList"                            : "Back to list",
    "account.members.coach.allMemberReports.noReports.title"                       : "No supervision reports",
    "account.members.coach.allMemberReports.noReports.subtitle"                    : "There is no supervision report on this official",
    "account.members.coach.allMemberReports.noReports.officiating.subtitle"        : "You currently have no supervision form filled",
    "account.members.coach.allMemberReports.noReports.search.subtitle"             : "No supervision report was found with this search",
    "account.members.coach.allMemberReports.noReports.officiating.search.subtitle" : "No supervision form was found with this search",

    "account.members.coach.sidePanel.delete.msg" : "You are about to delete this supervision report",
    "account.members.coach.sidePanel.tab.info"   : "Info",
    "account.members.coach.sidePanel.tab.type"   : "Type",
    "account.members.coach.sidePanel.tab.notes"  : "Notes",

    "account.members.coach.addSupervision.existingTeam"          : "Existing team",
    "account.members.coach.addSupervision.existingTeam.required" : "Please select if the team is existing or not",
    "account.members.coach.addSupervision.otherTeam"             : "Other",
    "account.members.coach.addSupervision.branch.label"          : "Branch",
    "account.members.coach.addSupervision.org.label"             : "Organisation",

    "account.members.coach.addSupervision.teamId.required"       : "Please select the team",
    "account.members.coach.addSupervision.teamName.required"     : "Please specify the team name",
    "account.members.coach.addSupervision.branch.required"       : "Please specify the branch",
    "account.members.coach.addSupervision.org.required"          : "Please specify the organization",
    "account.members.coach.addSupervision.teamCategory.required" : "Please specify the team category",
    "account.members.coach.addSupervision.period.required"       : "Please specify the season",
    "account.members.coach.addSupervision.date.required"         : "Please specify the date",
    "account.members.coach.addSupervision.officialType.required" : "Please specify the official type",

    "account.members.coach.addSupervision.step.findOfficial"          : "Find an official to supervise",
    "account.members.coach.addSupervision.step.global"                : "Fill out the global info",
    "account.members.coach.addSupervision.step.supervisionType"       : "Select the type of supervision",
    "account.members.coach.addSupervision.step.selectSupervisionType" : "Please select the type of supervision",
    "account.members.coach.addSupervision.step.selectLevels"          : "Select the levels",

    "account.members.coach.addSupervision.notOfficial.label"             : "Not an official",
    "account.members.coach.addSupervision.cantSuperviseThemselves.label" : "You cannot be your own officiating coach",
    "account.members.coach.addSupervision.league.label"                  : "League",
    "account.members.coach.addSupervision.privateComment.helper"         : "Will only be shown the the supervisors and administrators",
    "account.members.coach.addSupervision.not_listed"                    : "Not listed",

    "account.members.coach.categories.overallRatio"      : "Overall Ratio",
    "account.members.coach.categories.skating"           : "Skating",
    "account.members.coach.categories.positioning"       : "Positioning",
    "account.members.coach.categories.procedures"        : "Procedures",
    "account.members.coach.categories.mental_skills"     : "Mental Skills",
    "account.members.coach.categories.presence"          : "Teamwork & Coachability",
    "account.members.coach.categories.communication"     : "Communication",
    "account.members.coach.categories.judgement"         : "Judgement",
    "account.members.coach.categories.rule_application"  : "Rule Application",
    "account.members.coach.categories.penalty_standards" : "Standard",
    "account.members.coach.categories.choice.BEGINNING"  : "Beginning",
    "account.members.coach.categories.choice.DEVELOPING" : "Developing",
    "account.members.coach.categories.choice.MEETING"    : "Meeting",
    "account.members.coach.categories.choice.EXCEEDING"  : "Exceeding",

    "account.members.coach.categories.required" : "Please select the level of {category}",

    "account.members.documents.document.label"             : "Document",
    "account.members.documents.documentType.label"         : "Document Type",
    "account.members.documents.documentType.required"      : "Please select a document type",
    "account.members.documents.note.required"              : "Please add a note on the document",
    "account.members.documents.document.required"          : "Please add a document",
    "account.members.documents.filters.label"              : "Filter(s)",
    "account.members.documents.document.add.btn"           : "Add document",
    "account.members.documents.documentType.default"       : "Default File Type",
    "account.members.documents.document.add.btn.helper"    : "Click here to add a document",
    "account.members.documents.document.add.title"         : "Upload file",
    "account.members.documents.document.add.success.title" : "Document uploaded!",
    "account.members.documents.document.add.success.msg"   : "Your document has successfully been uploaded.",


    "account.members.officiatingCoach.sendEmail"      : "Send by email",
    "account.members.officiatingCoach.sendEmail.help" : "Send report now by email",

    "account.members.contacts.addModal.title"                : "Contact creation",
    "account.members.contacts.addModal.toast.success"        : "Contact created!",
    "account.members.contacts.addModal.contactType"          : "Contact type",
    "account.members.contacts.addModal.contactType.required" : "Contact type is required",
    "account.members.contacts.addModal.emergency"            : "Emergency contact",
    "account.members.contacts.addModal.addPhone"             : "Add phone number",
    "account.members.contacts.add"                           : "Add a contact",
}