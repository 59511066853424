import { CurrencyFormat } from "@spordle/intl-elements";

// function lerp(start, end, amt){
//     return ((1 - amt) * start + amt * end) / 10
// }

/**
 * @description Animation of an incrementing number
 * @param {Object} props
 * @param {number} props.amount
 * @returns {FormattedNumber}
 */
const LerpAmount = ({ amount }) => {
    // const [count, setCount] = useState(0.00);

    // function increment() {
    //     setCount(prev => {
    //         return prev + 1 <= amount ? prev + 1.00 : prev+0.01 <= amount ? prev + 0.01 : amount;
    //     });
    // }

    // useEffect(() => {
    //     if(count < amount) {
    //         const timeout = setTimeout(increment, lerp(0, amount, count));
    //         return () => clearTimeout(timeout);
    //     }
    // }, [count])

    return (
        <CurrencyFormat value={amount} currencyDisplay='symbol' />
    )
}

export default LerpAmount;