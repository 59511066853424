import { Suspense, useCallback } from 'react';
import { Fade, Spinner } from 'reactstrap';
import I18nReview from '../../views/Custom/I18nReview';
import ApiTester from '../../views/Custom/API_Tester';
import Home from '../../views/home';
import NotFound from '../../views/NotFound';

const BundledDevRoutes = ({ routeProps, ...props }) => {
    const getComponentFromKey = useCallback(() => {
        switch (props.bundleComponentKey){
            case 'home':
                return <Home {...routeProps} />;
            case 'api-tester':
                return <ApiTester {...routeProps} />;
            case 'i18n-review':
                return <I18nReview {...routeProps} />;
            default:
                return <NotFound />;
        }
    }, [ props.bundleComponentKey ]);
    return (
        <Suspense fallback={
            <div className="auth-wrapper">
                <Fade className='mx-auto'>
                    <Spinner color="primary" type="grow" />
                </Fade>
            </div>
        }
        >
            {getComponentFromKey()}
        </Suspense>
    )
}

export default BundledDevRoutes;