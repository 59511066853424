export default{
    // header / footer
    "signup.header.hcr"                : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Registre de Hockey Canada",
    "signup.header.poweredBy"          : "Propulsée par",
    "signup.header.alreadyHaveAccount" : "Vous avez déjà un compte?",
    "signup.header.signIn"             : "Se connecter",

    // General
    "signup.label.backToLogin" : "Retour à la connexion",
    "signup.label.title"       : "Inscription",
    "signup.fields.helper"     : "Tous les champs sont requis.",
    "signup.label.thisEmail"   : "Ce courriel sera utilisé pour l'inscription",
    "signup.action.signup"     : "S'inscrire",
    "signup.action.next"       : "Continuer",
    "signup.action.previous"   : "Précédent",

    // Sign up form
    "signup.signupForm.label.firstName"       : "Prénom",
    "signup.signupForm.label.lastName"        : "Nom de famille",
    "signup.signupForm.label.email"           : "Adresse courriel",
    "signup.signupForm.label.password"        : "Mot de passe",
    "signup.signupForm.label.passwordConfirm" : "Confirmez le mot de passe",
    "signup.signupForm.label.country"         : "Pays *",
    "signup.signupForm.label.province"        : "Province *",
    "signup.signupForm.label.language"        : "Langage",

    "signup.signupForm.validation.firstName.required"    : "Prénom requis",
    "signup.signupForm.validation.lastName.required"     : "Nom de famille requis",
    "signup.signupForm.validation.email.required"        : "Email requis",
    "signup.signupForm.validation.email.email"           : "Email invalide",
    "signup.signupForm.validation.password.required"     : "Mot de passe requis",
    "signup.signupForm.validation.password.space"        : "Le mot de passe ne peut pas contenir d'espaces",
    "signup.signupForm.validation.password.number"       : "Doit contenir 1 chiffre",
    "signup.signupForm.validation.password.letter"       : "Doit contenir une lettre",
    "signup.signupForm.validation.password.uppercase"    : "Doit contenir une majuscule",
    "signup.signupForm.validation.password.lowercase"    : "Doit contenir une minuscule",
    "signup.signupForm.validation.password.lenght"       : "Doit contenir au moins 6 caractères",
    "signup.signupForm.validation.password.lenght.max"   : "Doit contenir moins de 99 caractères",
    "signup.signupForm.validation.password.specialChar"  : "Le mot de passe doit avoir un caractère spécial",
    "signup.signupForm.validation.passwordConfirm.match" : "Les mots de passe doivent correspondre",
    "signup.signupForm.validation.birthdate.format"      : "La date doit etre un format valide",
    "signup.signupForm.validation.birthdate.invalid"     : "Votre date de naissance est invalide",

    "signup.signupForm.tooltip.password.title" : "Conditions",
    "signup.signupForm.tooltip.password.1"     : "Doit avoir 8 lettres",
    "signup.signupForm.tooltip.password.2"     : "Doit avoir un chiffre",
    "signup.signupForm.tooltip.password.3"     : "Doit avoir une majuscule",
    "signup.signupForm.tooltip.password.4"     : "Doit avoir une minuscule",
    "signup.signupForm.tooltip.password.5"     : "Doit avoir un caractère spécial",

    // Error
    "signup.error.title"                    : "Oups, une erreur s'est produite !",
    "signup.error.action"                   : "Retour à l'inscription",
    "signup.error.server"                   : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "signup.error.306"                      : "Un compte existe déjà avec cette adresse courriel",
    "signup.error.InvalidPasswordException" : "Le mot de passe est invalide",
    "signup.error.UsernameExistsException"  : "Un compte avec le email donné existe déjà",

    // Loading
    "signup.loading.title"       : "Création en cours !",
    "signup.loading.description" : "Une fois terminé, vous allez reçevoir un courriel de confirmation.",

    // Confirmed
    "signup.confirmed.title"       : "Confirmez votre courriel",
    "signup.confirmed.description" : "Nous avons envoyé un courriel, veuillez confirmer votre compte pour vous connecter.",
    "signup.confirmed.notReceived" : "Vous n'avez pas reçu de courriel?",
    "signup.confirmed.resend"      : "Envoyer courriel",
}