import failedPaymentModal from "./notificationPopups";
import hcrSearch from "./hcrSearch";
import helpCenter from "./helpCenter";
import invoice from "./invoice";
import schedule from "./schedule";
import termsOfUse from "./termsOfUse";
import uploader from "./uploader";
import sidePanel from "./sidePanel";
import fileViewer from "./fileViewer";

export default{
    ...hcrSearch,
    ...helpCenter,
    ...termsOfUse,
    ...invoice,
    ...schedule,
    ...fileViewer,
    ...sidePanel,
    ...uploader,
    ...failedPaymentModal,
}