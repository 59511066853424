import { addExtension, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Fade, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import Required from "../../../../../../../../components/formik/Required";
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../components/UserImg";
import { GameIncidentsContext } from "../../../../../../../../contexts/GameIncidentsContext";
import { MembersContext } from "../../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import CrossFade from "../../../CrossFade";
import { BC_ID, OFFICIAL_TYPES } from "../../../gameIncidentHelper";
import StepFooter from "../../../modal/components/StepFooter";
import StepTitle from "../../../modal/components/StepTitle";

const GameIncidentAddOfficial = (props) => {
    return (
        <AnalyticsModal analyticsName="GameIncidentSidePanelAddOfficial" isOpen={props.isOpen}>
            <GameIncidentAddOfficialInner {...props} />
        </AnalyticsModal>
    );
}

const GameIncidentAddOfficialInner = ({ toggle, refreshTable, gameIncident }) => {
    const { getPublicMembers } = useContext(MembersContext);
    const { createGameIncidentOfficial } = useContext(GameIncidentsContext);
    const [ step, setStep ] = useState(1);
    const [ results, setResults ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    /**
     * @param {boolean} isVisible
     * @returns {JSX.Element}
     */
    const FieldRequired = ({ isVisible }) => (
        <Fade className="d-inline" in={isVisible}><Required /></Fade>
    );

    /**
     * @description Search schema, only used when officialsStep is set to 2
     * @return {MixedSchema}
     */
    const searchSchema = ({
        first_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.firstName' /> }),
            }),
        last_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.lastName' /> }),
            }),
        unique_identifier: string(),
    });

    return (
        <Formik
            initialValues={{
                search: {
                    first_name: '',
                    last_name: '',
                    unique_identifier: '',
                    birthdate: '',
                },
                official:
                    {
                        member: {},
                        official_type: '',
                        email: '',
                        phone_number: '',
                    },
            }}
            validationSchema={object().shape({
                search: object().when('_', (_, schema) => step === 1 ? schema.shape(searchSchema) : schema),
                official: object().shape({
                    member_id: string(),
                    official_type: string().test({
                        name: 'isRequired',
                        message: <Translate id='account.members.gir.addModal.validation.officialType' />,
                        test: function(off){
                            return step !== 2 || !!off;
                        },
                    }),
                    email: string().email(<Translate id='form.validation.email.valid' />),
                    phone_number: string().when((_, schema) => step === 1 ? schema : schema.isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)),
                    phoneExt: string(),
                }),
            })}
            onSubmit={async({ search, official }, { setStatus }) => {
                setIsLoading(true);
                if(step === 1){
                    const apiValues = {
                        member_type_id: 'OFFICIAL',
                        organisation_id: BC_ID,
                        limit_hcr_number_search: 1,
                    };

                    if(search.unique_identifier){
                        apiValues.unique_identifier = search.unique_identifier;
                    }else{
                        apiValues.first_name = search.first_name;
                        apiValues.last_name = search.last_name;
                    }

                    getPublicMembers(apiValues)
                        .then((members) => {
                            setResults(members);
                            setIsLoading(false);
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                setIsLoading(false);
                                console.error(e);
                            }
                        })
                }else{
                    const apiValues = {
                        email: official.email || '',
                        member_id: official.member.member_id,
                        official_type: official.official_type,
                    };
                    const phone = await addExtension(official.phone_number, official.phoneExt);
                    apiValues.phone_number = phone || "";

                    createGameIncidentOfficial(gameIncident.game_incident_id, apiValues)
                        .then((officialId) => {
                            refreshTable?.({
                                game_incident_officials: [
                                    ...gameIncident.game_incident_officials,
                                    {
                                        ...official,
                                        game_incident_official_id: officialId,
                                        member_id: official.member.member_id,
                                        filled_request: "0",
                                        phone_number: apiValues.phone_number,
                                    },
                                ],
                            })
                            toggle();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);
                                setIsLoading(false);
                            }
                        })
                }
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={isLoading}>
                    <Form>
                        <ModalHeader toggle={toggle}><Translate id='account.members.gir.addModal.officials.title' /></ModalHeader>
                        <CrossFade isVisible={step === 2}>
                            <ModalBody>
                                {formik.values.official?.member?.member_id &&
                                    <UserDisplay card block>
                                        <UserDisplay.Container>
                                            <UserImg
                                                className="text-uppercase"
                                                width={50}
                                                abbr={formik.values.official.member.first_name.charAt(0) + formik.values.official.member.last_name.charAt(0)}
                                                alt={formik.values.official.member.first_name + ' ' + formik.values.official.member.last_name}
                                                src={formik.values.official.member.picture?.full_path}
                                                filePos={formik.values.official.member.picture?.file_position}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{formik.values.official.member.first_name} {formik.values.official.member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle>#{formik.values.official.member.unique_identifier}</UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                }
                                <Row form>
                                    <Col className="mb-3" lg="6">
                                        <Label className="text-muted" for='official.official_type'><Translate id='account.members.gir.addModal.label.officialType' /> <Required /></Label>
                                        <FormikSelect
                                            id='official.official_type' name='official.official_type'
                                            className="w-100"
                                            search={false}
                                            options={OFFICIAL_TYPES?.map((type) => ({
                                                label: 'account.members.gir.addModal.steps.officials.type.' + type,
                                                value: type,
                                            }))}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                        />
                                    </Col>
                                    <Col className="mb-3" lg="6">
                                        <Label className="text-muted" for='official.email'>
                                            <Translate id='form.fields.email' />
                                        </Label>
                                        <FormikInputText placeholder="example@email.ca" translatePlaceholder={false} trim name='official.email' />
                                    </Col>
                                    <Col xs="12">
                                        <Label className="text-muted" for='officials.phone_number'>
                                            <Translate id='form.fields.phone' />
                                        </Label>
                                        <div className="d-flex">
                                            {/* PHONE NUMBER */}
                                            <div className="w-100">
                                                <FormikPhoneInput
                                                    name="official.phone_number"
                                                    id="official.phone_number"
                                                />
                                            </div>
                                            <FormikInputNumber
                                                style={{
                                                    flexBasis: '33%',
                                                }}
                                                prefix="#"
                                                placeholder="Ext"
                                                translatePlaceholder={false}
                                                name='officials.phoneExt'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="mr-auto" onClick={() => setStep(1)} color="primary" type="button" outline>
                                    <Translate id="misc.previous" />
                                </Button>
                                <Button type="submit" color="primary">
                                    <Translate id="misc.confirm" />
                                </Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade
                            onExited={() => {
                                setResults(null);
                                formik.setFieldValue('search', {
                                    first_name: '',
                                    last_name: '',
                                    unique_identifier: '',
                                    birthdate: '',
                                });
                            }}
                            isVisible={step === 1}
                        >
                            <ModalBody>
                                <StepTitle title='account.members.gir.addModal.steps.officials.add.title' />
                                <Row form>
                                    <Col sm="6" className="form-group">
                                        <Label for="search.first_name" className="text-muted"><Translate id='form.fields.firstName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                        <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.first_name" />
                                    </Col>
                                    <Col sm="6" className="form-group">
                                        <Label for="search.last_name" className="text-muted"><Translate id='form.fields.lastName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                        <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.last_name" />
                                    </Col>
                                    <Col className="mb-3" sm="12">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <hr className="flex-fill" /> <span className="px-2 font-bold"><Translate id='addMemberModal.formik.label.search.or' /></span> <hr className="flex-fill" />
                                        </div>
                                    </Col>
                                    <Col sm="12" className="form-group">
                                        <Label for="search.unique_identifier" className="text-muted"><Translate id='addMemberModal.formik.label.uniqueIdentifier' /></Label>
                                        <FormikInputText name="search.unique_identifier" />
                                    </Col>
                                </Row>
                                <CrossFade isVisible={!!formik.values.search.unique_identifier}>
                                    <span className="small text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='addMemberModal.formik.warning.filters.ignored' /></span>
                                </CrossFade>
                                <Collapse isOpen={!!results}>
                                    { results &&
                                        <div className="pt-3 border-top">
                                            { results?.length > 0 ?
                                                <>
                                                    <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.existing' /></div>
                                                    <Row form>
                                                        { results.map((result) => {
                                                            const isAlreadyInList = gameIncident.game_incident_officials.some((official) => official.member_id === result.member_id);

                                                            return (
                                                                <Col sm="12" key={result.member_id}>
                                                                    <UserDisplay card className="w-100 mb-2">
                                                                        <UserDisplay.Container>
                                                                            <UserImg
                                                                                abbr={result.first_name.substring(0, 1) + result.last_name.substring(0, 1)}
                                                                                src={result.photo?.full_path}
                                                                                filePos={result.photo?.file_position}
                                                                                width={50}
                                                                                alt={result.first_name}
                                                                            />
                                                                        </UserDisplay.Container>
                                                                        <UserDisplay.Container>
                                                                            <div className="align-items-center d-flex">
                                                                                <UserDisplay.Title className="mr-2">{result.first_name} {result.last_name}</UserDisplay.Title>
                                                                                <UserDisplay.Subtitle>({result.age} <Translate id='misc.yrs' />)</UserDisplay.Subtitle>
                                                                            </div>
                                                                            <UserDisplay.Subtitle>#{result.unique_identifier}</UserDisplay.Subtitle>
                                                                            <UserDisplay.Subtitle>
                                                                                <DisplayI18n
                                                                                    field="name"
                                                                                    i18n={result.organisation.i18n}
                                                                                    defaultValue={result.organisation.organisation_name}
                                                                                />
                                                                            </UserDisplay.Subtitle>
                                                                        </UserDisplay.Container>
                                                                        <UserDisplay.Container className="ml-auto">
                                                                            <Button
                                                                                size="sm"
                                                                                disabled={isAlreadyInList}
                                                                                type="button"
                                                                                onClick={async() => {
                                                                                    await formik.setFieldValue('official', { phone_number: '', member: result, official_type: '' });
                                                                                    setStep(2);
                                                                                }}
                                                                                color="primary"
                                                                                outline
                                                                            >
                                                                                {isAlreadyInList ?
                                                                                    <Translate id='account.members.gir.search.alreadyAdded' />
                                                                                    :
                                                                                    <Translate id='misc.add' />
                                                                                }
                                                                            </Button>
                                                                        </UserDisplay.Container>
                                                                    </UserDisplay>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </>
                                                :
                                                <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.noResult' /></div>
                                            }
                                        </div>
                                    }
                                </Collapse>
                            </ModalBody>
                            <StepFooter disabled={isLoading} submitLabel="misc.search" />
                        </CrossFade>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

export default GameIncidentAddOfficial;