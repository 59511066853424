import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import UserImg from "../../../../components/UserImg";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n, displayI18n } from "../../../../helpers/i18nHelper";

const PreviewNotif = ({ toggle, isOpen, notif }) => {
    const { getGenericLocale } = useContext(I18nContext);

    const getNotifDate = (theNotif) => {
        const days = moment().diff(moment(theNotif.created_at), 'days')

        return (
            <>
                <DateFormat value={theNotif.created_at} />
                {days < 30 &&
                    <span className="inline-block ml-1">
                        ({days > 0 ?
                            <Translate id="misc.daysAgo" values={{ days: days }} />
                            :
                            <Translate id="misc.hoursAgo" values={{ hours: moment().diff(moment(theNotif.created_at), 'hours') }} />})
                    </span>

                }
            </>
        );
    }

    return (
        notif ?
            <AnalyticsModal isOpen={isOpen} analyticsName="NotificationSeeAllModal">
                <ModalHeader toggle={toggle}>
                    <DisplayI18n
                        field="title"
                        defaultValue={notif.title}
                        i18n={notif.i18n}
                    />
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-items-center mb-3">
                        <UserImg
                            className="text-uppercase mr-2"
                            width={35}
                            src={notif.from_organisation?.logo?.full_path}
                            filePos={notif.from_organisation?.logo?.file_position}
                            alt={notif.from_organisation?.organisation_name}
                            abbr={notif.from_organisation?.organisation_name}
                        />
                        <div className="w-100">
                            <div className="line-height-1 font-medium text-dark mb-0">
                                <DisplayI18n
                                    field="name"
                                    defaultValue={notif.from_organisation?.organisation_name}
                                    i18n={notif.from_organisation?.i18n}
                                />
                            </div>
                            <div className="small text-muted d-flex align-items-center ">
                                <div className="text-truncate mr-2"><Translate id='header.notifications.communication.toMe' />: {'<' + notif.to_identity?.email + '>'}</div>
                                <div className="ml-auto flex-shrink-0">{getNotifDate(notif)}</div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="p-2 border rounded-lg overflow-hidden"
                        dangerouslySetInnerHTML={{ __html: displayI18n("description", notif.i18n, notif.description, getGenericLocale()) }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle} type="button" color="primary" outline>
                        <Translate id="misc.close" />
                    </Button>
                </ModalFooter>
            </AnalyticsModal>
            :
            null
    );
}

export default PreviewNotif;