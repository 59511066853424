import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Alert, Button, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, object, string } from "yup";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { MembersContext } from '../../../../../contexts/MembersContext';
import { MemberBirthCountryField, MemberCitizenshipField, MemberEmailField, MemberEthnicityField, MemberGenderField, MemberIdentifyAsIndigenousField, MemberPrimaryLanguageField, MemberRelationField } from "./memberFields";

const MissingFieldsModal = ({ isOpen, toggle, metaMember, refreshMetaMember }) => {

    return (
        <AnalyticsModal analyticsName='AccountMemberMissingFields' isOpen={isOpen}>
            <MissingFieldsModalInner metaMember={metaMember} toggle={toggle} refreshMetaMember={refreshMetaMember} />
        </AnalyticsModal>
    );
}

const MissingFieldsModalInner = ({ toggle, metaMember, refreshMetaMember }) => {
    const [ selectedEthnicity, setSelectedEthnicity ] = useState(null);
    const membersContext = useContext(MembersContext);

    const getInitialValues = () => {
        const temp = {};
        metaMember.members[0].missing_required_fields.forEach((field) => {
            switch (field){
                case 'relation_id':
                case 'gender':
                case 'primary_language':
                case 'email':
                    temp[field] = '';
                    break;
                case 'birth_country':
                    temp[field] = 'CA';
                    break;
                case 'identify_as_indigenous':
                case 'indigenous_group_id':
                    temp.identify_as_indigenous = '';
                    temp.indigenous_group_id = '';
                    break;
                case 'ethnicity_id':
                case 'ethnicity_note':
                    temp.ethnicity_id = '';
                    temp.ethnicity_note = '';
                    break;
                case 'citizenships':
                    temp[field] = [];
                    break;
            }
        })
        return temp
    }

    const getValidationSchema = () => {
        const temp = {};
        metaMember.members[0].missing_required_fields.forEach((field) => {
            switch (field){
                case 'relation_id':
                case 'gender':
                case 'primary_language':
                case 'birth_country':
                case 'email':
                    temp[field] = string().required(<Translate id='account.members.missingFieldsModal.required' />);
                    break;
                case 'identify_as_indigenous':
                case 'indigenous_group_id':
                    temp.identify_as_indigenous = string().required(<Translate id='account.members.missingFieldsModal.required' />);
                    temp.indigenous_group_id = string().when('identify_as_indigenous', {
                        is: 'YES',
                        then: string().required(<Translate id='account.members.missingFieldsModal.required' />),
                    });
                    break;
                case 'ethnicity_id':
                case 'ethnicity_note':
                    temp.ethnicity_id = string().required(<Translate id='account.members.missingFieldsModal.required' />);
                    temp.ethnicity_note = string().when('doesNotMatter', {
                        is: () => !!selectedEthnicity?.withNote,
                        then: string().required(<Translate id='account.members.missingFieldsModal.required' />),
                    });
                    break;
                case 'citizenships':
                    temp[field] = array().min(1, <Translate id='account.members.missingFieldsModal.required' />);
                    break;
            }
        })
        return object().shape(temp);
    }

    const getField = (fieldId, key, formik) => {
        switch (fieldId){
            case 'relation_id':
                return <MemberRelationField key={key} fieldName='relation_id' />
            case 'gender':
                return <MemberGenderField key={key} fieldName='gender' />
            case 'primary_language':
                return <MemberPrimaryLanguageField key={key} fieldName='primary_language' />
            case 'citizenship':
                return <MemberCitizenshipField key={key} fieldName='citizenship' />
            case 'birth_country':
                return <MemberBirthCountryField key={key} fieldName='birth_country' />
            case 'email':
                return <MemberEmailField key={key} fieldName='email' />
            case 'identify_as_indigenous':
            case 'indigenous_group_id':
                return (
                    <MemberIdentifyAsIndigenousField
                        key={key}
                        fieldName='identify_as_indigenous'
                        secondaryFieldName='indigenous_group_id'
                        identifyAsIndigenousOnSelected={(values) => {
                            if(values[0] === 'YES')
                                // TODO: HARDCODED
                                // set ethnicity as indigenous
                                formik.setFieldValue('ethnicity_id', '07615f60-af8e-11ec-bed9-023f3d3ef136')
                            else
                                formik.setFieldValue('indigenous_group_id', '')
                        }}
                    />
                )
            case 'ethnicity_id':
            case 'ethnicity_note':
                return (
                    <MemberEthnicityField
                        key={key}
                        fieldName='ethnicity_id'
                        secondaryFieldName='ethnicity_note'
                        ethnicityOnSelected={(values, spordleSelect) => {
                            setSelectedEthnicity(spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]))
                        }}
                        showNote={!!selectedEthnicity?.withNote}
                    />
                )
        }
    }

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                membersContext.updateMemberMissingFields(metaMember.members?.[0]?.member_id, values)
                    .then(async() => {
                        await refreshMetaMember(metaMember.meta_member_id)
                        setSubmitting(false);
                        toggle();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false);
                            console.error(error);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='account.members.missingFieldsModal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <p className='text-center mb-0'>
                                <Translate id='account.members.missingFieldsModal.text' />
                            </p>
                            <p className='text-center font-weight-bold'>
                                <Translate id='account.members.missingFieldsModal.text2' />
                            </p>
                            <Row form>
                                {metaMember.members[0].missing_required_fields.map((field, index) => getField(field, index, formik))}
                            </Row>

                            {formik.status &&
                                <Alert color='danger' toggle={() => formik.setStatus()}>
                                    {formik.status}
                                </Alert>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id='memberMissingFieldsCancelButton' type="button" onClick={toggle} color="primary" outline className="mr-auto"><Translate id='misc.cancel' /></Button>
                            <Button id='memberMissingFieldsSubmitButton' type="submit" color="primary"><Translate id='misc.confirm' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default MissingFieldsModal