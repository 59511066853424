import { stringBuilder } from "@spordle/helpers";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { ScheduleContext } from "../../../Schedule";
import topVar from '../../../components/Schedule.module.scss';
import { classPrefix } from "../../../components/helpers";
import Event from "../../../events/Event";

function formatSharedEvents(shared, week){
    const sharedE = [];
    let rows = 0;

    for(let s = 0; s < shared.length; s++){
        const sE = shared[s];
        const sStart = sE.start_date ? moment(sE.start_date) : sE.date;
        const sEnd = sE.end_date ? moment(sE.end_date) : sE.date;
        const isContinuing = sStart.isBefore(week[0], 'days');
        const isExceeding = sEnd.isAfter(week[week.length - 1], 'days');
        const duration = Math.abs((isContinuing ? week[0] : sStart).diff(sEnd, 'days', false)) + 1;

        // TO DO : Find a better way to count the rows for the collapse
        // This is two loops in a loop
        if(s === 0){
            rows = 1;
        }else{
            for(let d = 0; d < week.length; d++){
                const weekDay = week[d];
                const dayEvents = shared.filter((filterEvent) => {
                    if(!filterEvent.start_date)return filterEvent.date.isSame(weekDay, 'days');

                    return weekDay.isSameOrAfter(moment(filterEvent.start_date), 'days') && weekDay.isSameOrBefore(moment(filterEvent.end_date), 'days');
                });

                if(dayEvents.length > rows) rows = dayEvents.length;
            }
        }

        sharedE.push(
            <Event.Compact
                className={`${classPrefix}-weekly__shared-event`}
                key={`shared-${sE.id}`}
                start={(isContinuing ? 0 : week.findIndex((d) => d.isSame(sStart, 'day'))) + 1}
                event={sE}
                duration={duration}
                isExceeding={isExceeding}
                isExtending={isContinuing}
            >
                {sE.title}
            </Event.Compact>,
        )
    }

    return { shared: sharedE, rowCount: rows };
}

/**
 * @description Events that can't be placed in a timezone (events multiple days, events all day)
 * @returns {JSX.Element}
 */
const ScheduleWeeklyTopEvents = () => {
    const { week, getSharedEvents, events } = useContext(ScheduleContext);
    const [ formattedEvents, setFormattedEvents ] = useState([]);
    const [ rowCount, setRowCount ] = useState(0);
    const [ isExtended, setIsExtended ] = useState(false);

    /**
     * @description Calcs the max height depending on the amount of rows
     * @param {integer} rows
     * @returns {string} maxHeight in rem
     */
    const calcMaxHeight = (rows) => (parseFloat(topVar.lineHeight) * rows) + (parseFloat(topVar.margin) * 2) + 'rem';

    useMemo(() => {
        const data = formatSharedEvents(getSharedEvents(week[0], week[week.length - 1]), week);

        setRowCount(data.rowCount);
        setFormattedEvents(data.shared);

    }, [ week, events ]);

    return (
        (formattedEvents.length > 0 || formattedEvents.length > 0) ?
            <section className={`${classPrefix}-weekly__shared-container`}>
                {rowCount > 4 ?
                    <>
                        <div className={`${classPrefix}-weekly__shared-expand`}>
                            <button
                                type="button"
                                onClick={() => setIsExtended(!isExtended)}
                                className={`${classPrefix}-weekly__shared-expand-btn`}
                            >
                                {isExtended ? 'Less' : `More`}
                                <i className={`${classPrefix}-weekly__shared-expand-icon mdi mdi-chevron-down${isExtended ? ' is-extended' : ""}`} />
                            </button>
                        </div>
                        <section style={isExtended ? { maxHeight: calcMaxHeight(rowCount) } : undefined} className={stringBuilder(`${classPrefix}-weekly__shared is-collapse`)}>
                            {formattedEvents}
                        </section>
                    </>
                    :
                    <>
                        <div className={`${classPrefix}-weekly__shared-expand`} />
                        <section className={`${classPrefix}-weekly__shared`}>
                            {formattedEvents}
                        </section>
                    </>
                }
            </section>
            :
            null
    )
}

export default ScheduleWeeklyTopEvents;