import Translate from "@spordle/intl-elements";
import { success, fail } from "@spordle/toasts";
import moment from "moment";
import { Fragment, useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Badge, FormGroup, Label } from "reactstrap";
import useSWR from "swr";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { AccountsContext } from "../../../../../contexts/contexts";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { displayField, getInitVal, getLabelHelper, getValidationSchema } from "../../../../../helpers/answerHelper";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";

const TransactionRegistrationForms = ({ selectedRows, ...props }) => {
    const { getInvoiceForms } = useContext(AccountsContext);

    const { data: customForms, isValidating, mutate } = useSWR(
        [ 'getInvoiceForms-TransactionRegistrationForms', selectedRows[0].invoice_number ],
        () => getInvoiceForms(selectedRows[0].invoice_number)
            .then((forms) => forms.filter((form) => form.active == '1')),
    );

    return (
        <div>
            {isValidating && !customForms ?
                <Fragment>
                    <Skeleton count={1} height={20} width="75%" />
                    <Skeleton count={1} height={19} width="50%" className="mb-2" />
                    <Skeleton count={1} height={70} width="100%" />
                </Fragment>
                : Array.isArray(customForms) && customForms.length > 0 ?
                    customForms.map((customForm, index) => {
                        const registration = selectedRows[0].invoice_items.find((invoiceItem) => customForm.invoice_item_id === invoiceItem.invoice_item_id)
                        return (
                            <Fragment key={customForm.custom_form_id + customForm.member.member_id}>
                                <div className="mb-2">
                                    <div className="h5 font-medium mb-1">{customForm.name}</div>
                                    <div className="mb-1">{customForm.member.first_name} {customForm.member.last_name}</div>
                                    <div className="small">
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={registration.registration_fee?.fee.name}
                                            i18n={registration.registration_fee?.fee.i18n}
                                        />
                                    </div>
                                </div>
                                {customForm.groups?.map((group, index) => (
                                    <CustomFormGroup
                                        key={group.custom_form_group_id + customForm.member.member_id}
                                        index={index}
                                        registration={registration}
                                        group={group}
                                        selectedRows={selectedRows}
                                        onSuccess={mutate}
                                        formId={customForm.custom_form_id}
                                        customForm={customForm}
                                        {...props}
                                    />
                                ))}
                                {index !== customForms.length - 1 && <hr />}
                            </Fragment>
                        )
                    })
                    :
                    <>
                        <div className="h5 font-medium mb-1">
                            <Translate id='account.transactions.sidePanel.forms.noForms.title' />
                        </div>
                        <p>
                            <Translate id='account.transactions.sidePanel.forms.noForms.text' />
                        </p>
                    </>
            }
        </div>
    )
}

export const CustomFormGroup = ({ group, index, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    // If this group only has deleted question with no answer, return null
    if(!group.fields.some((field) => ((field.deleted_at && field.answer) || !field.deleted_at)))return null;

    return (
        <OverlayLoader className="mb-2" isLoading={isLoading}>
            <CollapsibleCard
                title={<DisplayI18n field="name" i18n={group.i18n} defaultValue={group.name} />}
                initOpen
            >
                { group.fields.map((field, i) => (
                    <FormField
                        key={field.custom_form_field_id + group.custom_form_group_id + props.customForm.member.member_id}
                        setIsLoading={setIsLoading}
                        groupIndex={index}
                        field={field}
                        index={i}
                        {...props}
                    />
                ))}
            </CollapsibleCard>
        </OverlayLoader>
    )
}

const FormField = ({ field, index, groupIndex, setIsLoading, registration, onSuccess, formId, customForm }) => {
    const fieldName = `group_${groupIndex}_fields_${index}`;
    const { getGenericLocale } = useContext(I18nContext);
    const { updateForm } = useContext(AccountsContext);

    // If this field is a deleted question with no answer, return null
    if(!((field.deleted_at && field.answer) || !field.deleted_at))return null;

    return (
        <FormGroup>
            <Label for={fieldName} className='mb-0'>
                <DisplayI18n
                    field='title'
                    defaultValue={field?.title || '-Question-'}
                    i18n={field?.i18n}
                />
                {getLabelHelper(field)}
                {field.seen == 0 &&
                    <sup className="small">
                        <small>
                            <Badge color='primary' pill className="ml-1 text-uppercase"><Translate id='misc.new' /></Badge>
                        </small>
                    </sup>
                }
            </Label>
            { field.deleted_at ?
                <div className='font-medium text-muted'>
                    <div className="bg-light-inverse rounded p-1">
                        {field.form_field_code == "DATETIME" ? (moment(field?.answer).format('HH:mm') || '-') : (field?.answer || '-')}
                    </div>
                    <div className="text-danger small"><i className="mdi mdi-alert-outline mr-1" />This question has been deleted</div>
                </div>
                :
                <FormikEditable
                    className='editable-transparent'
                    noConfirmation={field.form_field_code == "SELECTBOX" || field.form_field_code == "SELECTBOX_MULTI"}
                    id={field.custom_form_field_id}
                    initialValues={getInitVal(field, fieldName)}
                    validationSchema={getValidationSchema(field, fieldName)}
                    onSubmit={(values) => {
                        setIsLoading(true);

                        const newVal = { custom_form_field_id: field.custom_form_field_id };
                        const apiVal = [];

                        if(field.options){
                            newVal.answer = field.options.reduce((arr, _opt) => {
                                if(Array.isArray(values[fieldName])){
                                    if(values[fieldName].some((_selected) => _selected === _opt.custom_form_field_option_id)){
                                        arr.pushArray([ displayI18n('field_option', _opt.i18n, _opt.field_option, getGenericLocale()) ]);
                                    }
                                }else if(values[fieldName] === _opt.custom_form_field_option_id){
                                    arr.pushArray([ displayI18n('field_option', _opt.i18n, _opt.field_option, getGenericLocale()) ]);
                                }
                                return arr;
                            }, [])?.join(',');
                            newVal.custom_form_field_option_selected = Array.isArray(values[fieldName]) ? String(values[fieldName]) : values[fieldName];
                        }else if(field.form_field_code == "DATE"){ // DATE, YYYY-MM-DD format
                            newVal.answer = values[fieldName] ? moment.isMoment(values[fieldName]) ? values[fieldName].format('YYYY-MM-DD') : moment(values[fieldName]).format('YYYY-MM-DD') : '';
                        }else if(field.form_field_code == "DATETIME"){ // DATETIME, ISO format
                            newVal.answer = moment.isMoment(values[fieldName]) ? values[fieldName].toISOString() : moment(values[fieldName]).toISOString();
                        }else{
                            newVal.answer = values[fieldName];
                        }

                        customForm?.groups.forEach((gr) => {
                            gr.fields.forEach((_f) => {
                                if(!_f.deleted_at){
                                    if(_f.custom_form_field_id === field.custom_form_field_id){
                                        apiVal.pushArray([ newVal ]);
                                    }else{
                                        apiVal.pushArray([ _f ]);
                                    }
                                }
                            });
                        })

                        return updateForm(
                            registration.member.member_id,
                            formId,
                            {
                                fields: apiVal,
                                invoice_item_id: customForm.invoice_item_id,
                            },
                        )
                            .then(() => {
                                onSuccess?.();
                                success();
                                setIsLoading(false);
                            })
                            .catch((err) => {
                                if(!AxiosIsCancelled(err.message)){
                                    console.error(err);
                                    setIsLoading(false);
                                    fail();
                                }
                            })
                    }}
                >
                    {(isEditing, _stopEditing, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {field.form_field_code == "DATETIME" ? (field.answer ? moment(field.answer).format('HH:mm') : '-') : (field?.answer || '-')}
                                </div>
                            )
                        }
                        return formik && displayField({ ...field, options: field.options?.filter((opt) => !opt.deleted_at) }, fieldName, formik)

                    }}
                </FormikEditable>
            }
        </FormGroup>
    )
}

export default TransactionRegistrationForms;