import PropTypes from 'prop-types';
import HcrLayout from './layouts/HcrLayout';
import SpordleLayout from './layouts/SpordleLayout';

/**
* Component that picks the proper layout
*
* Temporary component used to uniform all of our auth pages's layout
* we should eventually refactor this into something like FullLayout.
* @param {'spordle'|'hcr'} [layout] spordle || hcr
*/
const AuthLayout = ({
    layout,
    ...props
}) => {

    switch (layout){
        case 'hcr':
            return <HcrLayout {...props} />

        case 'spordle':
        default:
            return <SpordleLayout {...props} />
    }

};

export default AuthLayout;

AuthLayout.propTypes = {
    layout: PropTypes.string,
};

AuthLayout.defaultProps = {
    layout: 'spordle',
};