import React from "react";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";

// contexts
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

class TransactionsSidepanelCredit extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3 border-bottom">
                    <div className='d-flex mb-2'>
                        <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                    </div>
                    <div>
                        <div className='font-bold h4 mb-0'><Translate id='account.transactions.memberProfileTransactions.credit' /></div>
                        <div className="text-muted"><Translate id='account.transactions.memberProfileTransactions.sidePanel.credit.creationDate' /> : <DateFormat value={this.props.selectedRows[0].invoiceDate} /> <DateFormat value={this.props.selectedRows[0].invoiceDate} format={'h:mm a'} /></div>
                    </div>
                </div>

                <div className="p-3">

                    {/* STATUS */}
                    <div className='mb-3'>
                        <div className="text-muted"><Translate id='account.transactions.memberProfileTransactions.addModal.status' /></div>
                        <div className='font-medium'><Translate id={`misc.${this.props.selectedRows[0].active == '1' ? 'active' : 'inactive'}`} /></div>
                    </div>

                    {/* CREDIT TYPE */}
                    <div className='mb-3'>
                        <div className='text-muted'><Translate id='account.transactions.memberProfileTransactions.addModal.creditType' /></div>
                        <div className='font-medium'><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].credit_type.name} i18n={this.props.selectedRows[0].credit_type.i18n} /></div>
                    </div>

                    {/* AMOUNT */}
                    <div className='mb-3'>
                        <div className='text-muted'><Translate id='account.transactions.memberProfileTransactions.addModal.amount' /></div>
                        <div className='font-medium'><CurrencyFormat value={this.props.selectedRows[0].amount / 100} /></div>
                    </div>

                    {/* BALANCE */}
                    <div className='mb-3'>
                        <div className='text-muted'><Translate id='account.transactions.memberProfileTransactions.sidePanel.credit.balance' /></div>
                        <div className='font-medium text-success'><CurrencyFormat value={this.props.selectedRows[0].balance / 100} /></div>
                    </div>

                    {/* ITEMS */}
                    {/* <div className='mb-3'>
                        <div className="h4 font-bold"><Translate id='account.transactions.memberProfileTransactions.items'/></div>
                        {this.props.selectedRows[0].values.map(item => {
                            if(item.item.type === 'OTHER'){
                                return(
                                    <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                        <UncontrolledDropdown tag="span" className='position-absolute top-0 right-0'>
                                            <DropdownToggle tag="button" className="btn btn-link">
                                                <i className="mdi mdi-dots-vertical font-22"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem><Translate id='account.transactions.memberProfileTransactions.sidePanel.actions.refund'/></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <CardBody>
                                            <div className='h6 mb-3'>
                                                <span className='font-bold'>
                                                    <DisplayI18n
                                                        field='name'
                                                        i18n={item.item.i18n}
                                                        defaultValue={item.item.name}
                                                    />
                                                </span>
                                                {parseInt(item.invoice_item_payments[0].amount) / item.amount > 1 &&
                                                    <>
                                                        <span className="mx-1"><i className="mdi mdi-close"></i></span>
                                                        <span>{parseInt(item.invoice_item_payments[0].amount) / item.amount}</span>
                                                    </>
                                                }
                                            </div>
                                            <Row>
                                                <Col md='6'>
                                                    <div className='text-muted'><Translate id='account.transactions.memberProfileTransactions.sidePanel.items.status'/></div>
                                                    <Badge color={item.invoice_item_payments[0].status === 'PAID' ? 'success' : item.invoice_item_payments[0].status === 'PENDING' || item.invoice_item_payments[0].status === 'NEED_REVIEW' ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                        <Translate id={'account.transactions.memberProfileTransactions.sidePanel.status.' + item.invoice_item_payments[0].status}/>
                                                    </Badge>
                                                </Col>
                                                <Col md='6'>
                                                    <div className='text-muted'><Translate id='account.transactions.memberProfileTransactions.sidePanel.items.amount'/></div>
                                                    <div><CurrencyFormat value={parseInt(item.invoice_item_payments[0].amount) / 100}/></div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                )
                            }
                        })}
                    </div> */}
                </div>
            </OverlayLoader>
        )
    }
}

export default TransactionsSidepanelCredit;