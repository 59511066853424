import { useCallback, useContext, useState } from 'react';
import { Alert, Button, Fade, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import { FormikInputText } from '@spordle/formik-elements';
import * as Yup from 'yup';
import Translate from '@spordle/intl-elements';
import PinField from 'react-pin-field';

import SpordleMyAccountLogo from '../../../../../assets/images/logos/spordleMyAccount.svg';
import Antispam from '@spordle/anti-spam';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { fail, fire, success } from '@spordle/toasts';

const AccountEmail = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ mustVerify, setVerify ] = useState(auth.userData.UserAttributes.email_verified !== 'true');
    const resendEmail = useCallback(() => {
        auth.sendAttributeCode('email')
            .then(() => {
                success({ msg: 'account.settings.profile.accountEmail.email.toast.success' });
            })
            .catch(() => {
                fail({ msg: 'account.settings.profile.accountEmail.email.toast.fail' });
            })
    });

    const toggleModal = () => { setOpen(!isOpen) };

    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='form.fields.email' />}
                value={auth.userData.UserAttributes.email_verified !== 'true' ?
                    <div>
                        {auth.userData.UserAttributes.email}
                        <div className='small text-warning'>
                            <i className='mdi mdi-alert-outline mr-1' />
                            <Translate id='account.settings.profile.accountEmail.emailNotVerified' />
                        </div>
                    </div>
                    :
                    auth.userData.UserAttributes.email
                }
                onAction={toggleModal}
            />
            <AnalyticsModal
                analyticsName='AccountEmail' isOpen={isOpen}
                onClosed={() => {
                    setEmail('');
                    setVerify(auth.userData.UserAttributes.email_verified !== 'true')
                }}
            >
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.email' />
                </ModalHeader>
                <Fade in={!email && !mustVerify} className={!email && !mustVerify ? undefined : 'd-none'} mountOnEnter unmountOnExit>
                    <Formik
                        initialValues={{
                            currentEmail: '',
                            newEmail: '',
                            confirmEmail: '',
                        }}
                        validationSchema={Yup.object().shape({
                            currentEmail: Yup.string().test({
                                name: 'currentEmail-email',
                                message: <Translate id='form.validation.email.matchCurrent' />,
                                test: (currentEmail) => currentEmail === auth.userData.UserAttributes.email,
                            }),
                            newEmail: Yup.string()
                                .required(<Translate id='form.validation.email.required' />)
                                .email(<Translate id='form.validation.email.valid' />)
                                .test({
                                    name: 'current-email-not',
                                    message: <Translate id='form.validation.email.matchCurrent.not' />,
                                    test: (newEmail) => newEmail !== auth.userData.UserAttributes.email,
                                }),
                            confirmEmail: Yup.string().test({
                                name: 'confirm-email',
                                message: <Translate id='form.validation.email.matchConfirm' />,
                                test: function(confirmEmail){
                                    return this.parent.newEmail === confirmEmail
                                },
                            }),
                        })}
                        onSubmit={({ newEmail }, { setStatus, setSubmitting }) => {
                            setStatus();
                            auth.updateUserInfo({
                                ...auth.userData.UserAttributes,
                                email: newEmail,
                                email_verified: 'false',
                            }).then(() => {
                                setEmail(newEmail)
                            })
                                .catch((error) => {
                                    setStatus(error.message);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <ModalBody>
                                    <FormGroup>
                                        <FormikInputText id='currentEmail' name='currentEmail' label='form.fields.currentEmail' trim type='email' autoComplete='email' />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikInputText id='newEmail' name='newEmail' label='form.fields.newEmail' trim type='email' autoComplete='new-email' />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikInputText id='confirmEmail' name='confirmEmail' label='form.fields.confrimEmail' trim type='email' autoComplete='new-email' />
                                    </FormGroup>
                                    {formik.status &&
                                        <Alert color='danger' className='mb-0'><Translate id={`confirm-account.error.${formik.status}`} defaultMessageId='confirm-account.error.server' /></Alert>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                    <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fade>
                <Fade in={!!email} className={!email ? 'd-none' : undefined} mountOnEnter unmountOnExit>
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        onSubmit={({ code }, { setStatus, setSubmitting }) => {
                            setStatus();
                            auth.verifyAttributeCode('email', code)
                                .then(() => {
                                    setStatus();
                                    auth.getUserData()
                                        .then(() => {
                                            success({ msg: 'account.settings.profile.accountEmail.code.toast.success' });
                                            toggleModal();
                                        }, () => {
                                            fail({ msg: 'account.settings.profile.accountEmail.code.toast.fail' });
                                        })
                                })
                                .catch((error) => {
                                    setStatus(error.message);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <ModalBody>
                                    <div className="text-center">
                                        <img className="mb-2" src={SpordleMyAccountLogo} width="150px" alt="Spordle My Account" />
                                        <div className="h5 mb-3"><Translate id='confirm-account.title' /></div>
                                        <div className="h6 text-muted mb-3">{email}</div>
                                    </div>
                                    <FormGroup>
                                        <div className='mb-3 mx-n2 d-flex'>
                                            <PinField
                                                autoFocus className="min-w-0 form-control mx-2 text-center" validate="0123456789"
                                                disabled={formik.isSubmitting} length={6}
                                                onChange={(code) => {
                                                    formik.setFieldValue('code', code)
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                    <Antispam
                                        timeoutDuration={5000}
                                        clicksForTimeout={2}
                                        resetClicksDuration={10000}
                                        isSpamming={() => {
                                            fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                                        }}
                                    >
                                        {(spamming, antiSpamCallback) => (
                                            <button
                                                onClick={(e) => {
                                                    antiSpamCallback(e);
                                                    resendEmail();
                                                }}
                                                id="resendEmailButton"
                                                type="button"
                                                className="ml-1 reset-btn text-link"
                                                disabled={spamming}
                                            >
                                                <Translate id='signup.confirmed.resend' />
                                            </button>
                                        )}
                                    </Antispam>
                                    {formik.status &&
                                        <Alert color='danger' className='mb-0'><Translate id={`confirm-account.error.${formik.status}`} defaultMessageId='confirm-account.error.server' /></Alert>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting || formik.values.code.length !== 6}><Translate id='misc.confirm' /></Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Fade>
                {auth.userData.UserAttributes.email_verified !== 'true' &&
                    <Fade in={mustVerify} className={mustVerify ? undefined : 'd-none'} mountOnEnter unmountOnExit>
                        <ModalBody>
                            <div>
                                <Translate id='account.settings.profile.accountEmail.email.current.notVerified' />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='primary' outline type='button'
                                onClick={() => {
                                    setVerify(false);
                                }}
                            >
                                <Translate id='account.settings.profile.accountEmail.email.current.change' />
                            </Button>
                            <Button
                                color='warning' type='button' className='ml-1'
                                onClick={() => {
                                    setEmail(auth.userData.UserAttributes.email);
                                    resendEmail();
                                    setVerify(false)
                                }}
                            >
                                <Translate id='account.settings.profile.accountEmail.email.current.verify' />
                            </Button>
                        </ModalFooter>
                    </Fade>
                }
            </AnalyticsModal>
        </>
    );
}

export default AccountEmail;