import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { DisplayI18n } from '../../helpers/i18nHelper';

/**
* Badge that will follow api status logic using i18n & color code (0,1,2,3,4,5)
* @param {object} props
* @param {object} [props.status]
* @param {React.ComponentType} [props.icon]
* @example <StatusBadge status={member_qualification_status}/>
*/
const StatusBadge = ({ status, icon }) => {
    // Color codes defined with API (same concept as clinic color but we don't have the classes plugged)
    const getColor = (color) => {
        switch (color){
            case 0:
                return 'danger'
            case 1:
                return 'warning'
            case 2:
                return 'success'
            case 3:
                return 'light'
            case 4:
                return 'info'
            default:
                break;
        }
    }

    return (
        <Badge color={getColor(status.color || parseInt(status.color_code))}>{icon ?? ''}<DisplayI18n field='name' defaultValue={'-'} i18n={status.i18n} /></Badge>
    )
};

export default StatusBadge;

StatusBadge.propTypes = {
    status: PropTypes.object,
    icon: PropTypes.node,
};