export default{
    "account.settings.2fa.update.2fa.success" : "Préférences pour la vérification en 2 étapes mises à jour!",
    "account.settings.2fa.update.2fa.fail"    : "Les préférences pour la vérification en 2 étapes n'ont pas pu être mises à jour",

    "account.settings.2fa.title"          : "Vérification en 2 étapes",
    "account.settings.2fa.subtitle.text0" : "Atteignez un niveau de sécurité supérieur!",
    "account.settings.2fa.subtitle.text1" : "Activer la vérification en 2 étapes pour ajouter une autre couche de sécurité pour protéger votre compte.",
    "account.settings.2fa.subtitle.text2" : "À chaque connexion, vous devrez confirmer votre connexion à l'aide d'un SMS.",

    "account.settings.2fa.yes"     : "Oui",
    "account.settings.2fa.no"      : "Non",
    "account.settings.2fa.confirm" : "Confirmer",
    "account.settings.2fa.next"    : "Suivant",

    "account.settings.2fa.sms"                   : "Téléphone mobile",
    "account.settings.2fa.sms.active"            : "La vérification en 2 étapes via SMS est activée!",
    "account.settings.2fa.sms.inactive"          : "La vérification en 2 étapes via SMS n'est pas activée",
    "account.settings.2fa.sms.needPhone"         : "Vous devez avoir un numéro de téléphone vérifié pour activer ce paramètre",
    "account.settings.2fa.sms.preferedMethod"    : "Méthode de vérification en 2 étapes préférée",
    "account.settings.2fa.sms.activate"          : "Êtes-vous certain de vouloir activer la vérification en 2 étapes via SMS?",
    "account.settings.2fa.sms.activate.button"   : "Activer",
    "account.settings.2fa.sms.activated"         : "Vérification en 2 étapes via SMS activée",
    "account.settings.2fa.sms.deactivate"        : "Êtes-vous certain de vouloir désactiver la vérification en 2 étapes via SMS?",
    "account.settings.2fa.sms.deactivate.button" : "Désactiver",
    "account.settings.2fa.sms.deactivated"       : "Vérification en 2 étapes via SMS désactivée",
    "account.settings.2fa.sms.error"             : "Une erreur est survenue, veuillez rafraichir la page",
    "account.settings.2fa.sms.errorUpdate"       : "La vérification en 2 étapes via SMS n'a pas pu être mise à jour",

    "account.settings.2fa.software"                   : "Logiciel",
    "account.settings.2fa.software.active"            : "La vérification en 2 étapes via logiciel est activée!",
    "account.settings.2fa.software.inactive"          : "La vérification en 2 étapes via logiciel n'est pas activée",
    "account.settings.2fa.software.preferedMethod"    : "Méthode de vérification en 2 étapes préférée",
    "account.settings.2fa.software.anyApp"            : "Utilisez n'importe quelle application d'authentification",
    "account.settings.2fa.software.googleApp"         : "Nous recommandons l'utilisation de l'application Google Authentication",
    "account.settings.2fa.software.key"               : "Clé de configuration",
    "account.settings.2fa.software.key.message"       : "Entrez la clé de configuration ou scannez le code QR dans votre application d'authentification si vous désirez activer ou ré-activer la vérification en 2 étapes via logiciel.",
    "account.settings.2fa.software.copy"              : "Copier",
    "account.settings.2fa.software.copied"            : "Copié",
    "account.settings.2fa.software.or"                : "OU",
    "account.settings.2fa.software.qr"                : "Code QR",
    "account.settings.2fa.software.confirm"           : "Veuillez confirmer",
    "account.settings.2fa.software.activated"         : "Vérification en 2 étapes via logiciel activée!",
    "account.settings.2fa.software.deactivated"       : "Vérification en 2 étapes via logiciel désactivée",
    "account.settings.2fa.software.error"             : "Erreur: {error}",
    "account.settings.2fa.software.error.noMatch"     : "Le code ne correspond pas, veuillez réessayer.",
    "account.settings.2fa.software.errorUpdate"       : "La vérification en 2 étapes via logiciel n'a pas pu être mise à jour",
    "account.settings.2fa.software.remove"            : "Êtes-vous certain de vouloir retirer la vérification en 2 étapes via logiciel?",
    "account.settings.2fa.software.activate.button"   : "Activer",
    "account.settings.2fa.software.deactivate.button" : "Désactiver",
}