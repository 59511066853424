export default{
    "account.settings.profile.accountAddress.label" : "Nouvelle addresse",

    "account.settings.profile.accountAddress.modalTitle" : "Soumettre une demande de changement d'adresse",

    "account.settings.profile.accountAddress.popUp.newAddress.title"        : "Ajouter une adresse",
    "account.settings.profile.accountAddress.popUp.newAddress.description0" : "Votre compte n'a pas d'addresse",
    "account.settings.profile.accountAddress.popUp.newAddress.description1" : "Notre système a détecté que votre compte n'a pas d'adresse.",
    "account.settings.profile.accountAddress.popUp.newAddress.description2" : "Veuillez ajouter une adresse pour compléter votre compte.",

    "account.settings.profile.accountAddress.popUp.verifyAddress.title"                  : "Vérifier l'adresse",
    "account.settings.profile.accountAddress.popUp.verifyAddress.description1"           : "Ça fait un moment que vous n'avez pas vérifié votre adresse.",
    "account.settings.profile.accountAddress.popUp.verifyAddress.description2"           : "Veuillez vérifier que votre adresse actuelle est valide:",
    "account.settings.profile.accountAddress.popUp.verifyAddress.valid.title"            : "Mon adresse est valide",
    "account.settings.profile.accountAddress.popUp.verifyAddress.valid.subtitle"         : "Je vis toujours à cette adresse",
    "account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.title"    : "Changer mon adresse",
    "account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.subtitle" : "Cette adresse n'est plus valide",

    // Publish members step
    "account.settings.profile.accountAddress.validation.members.min"               : "Veuillez sélectionner le ou les membres pour lesquels vous souhaitez publier votre nouvelle adresse",
    "account.settings.profile.accountAddress.validation.addressType.required"      : "Veuillez sélectionner un type d'adresse",
    "account.settings.profile.accountAddress.validation.yearsSameAddress.required" : "Veuillez remplir l'année de déménagement",
    "account.settings.profile.accountAddress.validation.yearsSameAddress.isBefore" : "L'année ne peut pas être dans le futur",

    "account.settings.profile.accountAddress.step1.title"   : "Mettre à jour {membersCount, plural, one {ue membre} other {les membres}}",
    "account.settings.profile.accountAddress.step1.success" : "Votre adresse de compte a été mise à jour avec succès ",
    "account.settings.profile.accountAddress.step1.helper"  : "{membersCount, plural, one {Un membre n'a} other {Certains membres n'ont}} pas la même adresse.",
    "account.settings.profile.accountAddress.step1.helper2" : "Voulez-vous soumettre une demande de changement d'adresse pour {membersCount, plural, one {votre membre} other {vos membres}}?",
    "account.settings.profile.accountAddress.step1.submit"  : "Modifier {membersCount, plural, one {le membre} other {les membres}}",

    "account.settings.profile.accountAddress.step2.title"                      : "Publier la nouvelle adresse",
    "account.settings.profile.accountAddress.step2.description1"               : "Vous avez {count, plural, one {un membre} other {# membres}} dans:",
    "account.settings.profile.accountAddress.step2.description"                : "Sélectionnez le ou les membres pour lesquels vous souhaitez définir la nouvelle adresse",
    "account.settings.profile.accountAddress.step2.moveIn.label"               : "Année de déménagement",
    "account.settings.profile.accountAddress.step2.moveIn.tip"                 : "Date depuis laquelle les participants résident à l'adresse actuelle",
    "account.settings.profile.accountAddress.step2.addressType.label"          : "Type d'adresse",
    "account.settings.profile.accountAddress.step2.proofOfResistency"          : "Preuve de résidence",
    "account.settings.profile.accountAddress.step2.proofOfResistency.required" : "Veuillez ajouter une preuve de résidence",
    "account.settings.profile.accountAddress.step2.submit"                     : "Soumettre demande de changement d'adresse",
    "account.settings.profile.accountAddress.step2.skipMembers"                : "Ignorer {count, plural, one {ce membre} other {ces membres}}",
    "account.settings.profile.accountAddress.step2.seeMemberSelection"         : "Voir la sélection de membres",

    "account.settings.profile.accountAddress.step3.title"   : "Demande de changement soumise",
    "account.settings.profile.accountAddress.step3.success" : "Demande de changement d'adresse soumise avec succès",
}