import { useContext, useState } from 'react';
import { Button, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import moment from 'moment';

import { I18nContext } from '../../../../../contexts/I18nContext';
import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';
import Translate from '@spordle/intl-elements';

import { Form, Formik } from 'formik';
import { FormikDateTime } from '@spordle/formik-elements';
import * as Yup from 'yup';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';

const AccountBirthdate = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };
    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='form.fields.dateOfBirth' />}
                value={auth.userData.UserAttributes.birthdate || ''}
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountBirthdate' isOpen={isOpen}>
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.dateOfBirth' />
                </ModalHeader>
                <Formik
                    initialValues={{
                        birthdate: auth.userData.UserAttributes.birthdate ? moment(auth.userData.UserAttributes.birthdate) : '',
                    }}
                    validationSchema={Yup.object().shape({
                        birthdate: Yup.mixed().test('birthdate-validFormat', <Translate id='signup.signupForm.validation.birthdate.format' />, function(value){
                            return moment.isMoment(value);
                        }).test('birthdate-beforeToday', <Translate id='signup.signupForm.validation.birthdate.invalid' />, function(value){
                            return moment(value).isBefore(new Date());
                        }),
                    })}
                    onSubmit={({ birthdate }, { setStatus, setSubmitting }) => {
                        setStatus();
                        auth.updateUserInfo({
                            ...auth.userData.UserAttributes,
                            birthdate: moment(birthdate).format('YYYY-MM-DD'),
                        }).then(() => {
                            setStatus();
                            success({ msg: 'account.settings.profile.accountBirthdate.update' });
                            toggleModal();
                        })
                            .catch((error) => {
                                setStatus(error.message);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <I18nContext.Consumer>
                                        {({ getGenericLocale }) => (
                                            <FormikDateTime
                                                name='birthdate' label='form.fields.dateOfBirth' translateLabel
                                                timeFormat={false} locale={getGenericLocale()}
                                                dateFormat='YYYY-MM-DD'
                                                isValidDate={(current) => current.isBefore(new Date())}
                                                helper='misc.birthdate.format'
                                            />
                                        )}
                                    </I18nContext.Consumer>
                                </FormGroup>
                                <div className='d-flex align-items-center bg-light-inverse p-1 mt-4 rounded'>
                                    <i className='text-primary mdi mdi-information-outline mx-2' />
                                    <div className='small'>
                                        <Translate id='account.settings.profile.accountBirthdate.private' />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    );
}

export default AccountBirthdate;