/* eslint-disable react/no-unused-class-component-methods */
import { jsObjectToApi } from '@spordle/helpers';
import queryString, { stringifyUrl } from 'query-string';
import React from 'react';
import API_PUBLIC from '../api/API-Public';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import withContexts from '../helpers/withContexts';
import { AccountsContext, AuthContext } from './contexts';
import { I18nContext } from './I18nContext';

const INIT_STATE = {
    primaryMetaMember: null,
    idMetaMembers: null,
}

class AccountsContextProvider extends React.Component{
    constructor(props){
        super(props);

        this.state = INIT_STATE;
    }

    componentDidUpdate(){
        if(this.props.AuthContext.userData?.Username && !this.state.idMetaMembers){
            this.getIdentityMetaMembers(this.props.AuthContext.userData.Username)
                .catch((error) => {
                    console.error(error.message);
                });
        }
    }

    _isDeclinedPayment(item){
        return item.payments.some((p) => p.status === "NEED_REVIEW");
    }

    _isPreinitPayment(item){
        return item.payments.some((p) => p.status === "PENDING");
    }

    resetAccount = () => {
        this.setState(() => (INIT_STATE));
    }

    /**
     * @param {obj} newValues New Values to set for the primary member
     */
    updateMembers = (member, newValues) => {
        this.setState((prev) => {
            const newMeta = { ...prev.idMetaMembers };
            const newMetaM = { meta_members: [ ...newMeta.meta_members ] };

            for(let i = 0; i < newMetaM.meta_members.length; i++){
                const meta = newMetaM.meta_members[i];
                if(meta?.meta_member_id == member.meta_member_id){
                    newMetaM.meta_members[i] = { ...meta, ...newValues };
                    break;
                }
            }

            return {
                idMetaMembers: { ...newMeta, ...newMetaM },
                primaryMetaMember: {
                    ...prev.primaryMetaMember,
                    ...member.is_primary == "1" && newValues,
                },
            }
        });
    }

    /**
     * Gets all the Meta Members from a specified Identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @returns {Promise}
     */
    getIdentityMetaMembers = (identityId, queryParams = {}, fromApi = false) => {
        if(!fromApi && this.state.idMetaMembers){
            return Promise.resolve(this.state.idMetaMembers);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/meta-members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    this.setState({
                        primaryMetaMember: response.data.meta_members.find((m) => m.is_primary == "1"),
                        idMetaMembers: {
                            meta_members: response.data.meta_members,
                            organisations: response.data.organisations,
                            teams: response.data.teams,
                        },
                    });

                    return response.data;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getIdentityMetaMember = (identityId, metaMemberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members[0]
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getMetaMemberCrc = (memberId, queryParams) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/members/${memberId}/crc`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_crcs;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    createMetaMember = (identityId, firstName, lastName, birthdate, relation, email, memberId) => {
        const params = new URLSearchParams();
        params.append('first_name', firstName);
        params.append('last_name', lastName);
        params.append('relation', relation);
        if(email) params.append('email', email);
        if(birthdate) params.append('birthdate', birthdate);
        if(memberId) params.append('member_id', memberId);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_member_id
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Links an Identity to a Meta Member
     * @param {string} identityId ID of the Identity to link to a Meta Member
     * @param {string} metaMemberId ID of the Meta Member to link an Identity to
     * @param {Array} members
     * @returns {Promise}
     */
    linkMemberToMetaMember = (identityId, metaMemberId, members) => {
        const params = new URLSearchParams();
        members.forEach((memberId, index) => {
            params.append(`members[${index}]`, memberId)
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data
                }
                throw response.data.errors[0]
            })
    }

    /**
     * [POST] Adds an attachment to a meta member
     * @param {string} identityId
     * @param {string} metaMemberId
     * @param {object} values
     * @returns {Promise}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AupdateMetaMemberAttachment|documentation}
     */
    createMetaMemberPicture = (identityId, metaMemberId, values) => {
        const params = new FormData();

        params.append('attachment', values.attachment);
        if(values.file_position) params.append('file_position', values.file_position);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/pictures` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.picture
                }
                throw response.data.errors[0]
            }, serverError)
    }

    deleteMetaMemberPicture = (identityId, metaMemberId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/pictures` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Sets an Identity Role as Primary
     * @param {string} identityId ID of the Identity Role to set as Primary
     * @param {string} identityRoleId ID of the Identity Role to set Primary
     */
    enablePrimaryRole = (identityId, identityRoleId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${identityId}/identity-roles/${identityRoleId}/enable-primary-role` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Removes the Primary property of an Identity Role
     * @param {string} identityId ID of the Identity to remove Primary property
     * @param {string} identityRoleId ID of the Identity Role to remove Primary
     */
    disablePrimaryRole = (identityId, identityRoleId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${identityId}/identity-roles/${identityRoleId}/disable-primary-role` })) ///accounts/{{identity_id}}/identity-roles/{{identity_role_id}}/disable-primary-role
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes the members from a meta member
     * @param {string} identityId ID of the Identity to delete the members of the meta member from
     * @param {string} metaMemberId ID of the Meta Member to delete members from
     * @returns {Promise}
     */
    unlinkMemberFromMetaMember = (identityId, metaMemberId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/members` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific meta member
     * @param {string} identityId ID of the Identity to delete the meta member from
     * @param {string} metaMemberId ID of the Meta Member to delete
     * @returns {Promise}
     */
    deleteMetaMember = (identityId, metaMemberId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get's all of Spordle's terms of use
     * @returns {Promise}
     */
    getTermsOfUse = () => {
        return API_PUBLIC.get('/term-of-use')
            .then((response) => {
                if(response.data.status){
                    return response.data.term_of_use;
                }
                throw new Error(response.data.errors.code);
            }, serverError)
    }

    /**
     * [POST] Accept Spordle's terms of use
     * @param {string} userId The identity id of the user who accepts the terms of use
     * @param {string} termOfUseId The id of the accepted term of use
     * @param {string} language Language code of the term of use
     * @returns {Promise}
     */
    acceptTermsOfUse = (userId, termOfUseId, language) => {
        const params = new URLSearchParams({
            term_of_use_id: termOfUseId,
            language: language,
        });

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${userId}/term-of-use` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }

                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Gets all purchases related to the logged in user
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetAccountPurchases|documentation}
     * @returns {Promise.<Array>}
     */
    getAccountPurchases = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${this.props.AuthContext.userData.Username}/purchases`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { invoice_items: response.data.invoice_items, credits: response.data.credits };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getAccountInvoices = (identityId, queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/invoices`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    return response.data.invoices;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    getAccountMemberCredits = (identityId, queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/member-credits`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_credits
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [GET] Gets all events of an account
     * @param {string} identityId
     * @param {object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetAccountCalendars}
     * @returns {Promise.<object>}
     */
    getAccountCalendar = (identityId, queryParams = {}) => (
        API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/calendars`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    return response.data.calendars
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Gets all events of a member
     * @param {string} identityId
     * @param {string} memberId
     * @param {object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMemberCalendars}
     * @returns {Promise.<object>}
     */
    getAccountMemberCalendar = (identityId, memberId, queryParams) => (
        API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/members/${memberId}/calendars`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then(async(response) => {
                if(response.data.status){
                    return response.data.calendars
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * Gets the user,s vault cards
     * @param {string} identityId
     * @returns {Promise.<Array>}
     * @see {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetVaultCardByIdentityID documentation}
     */
    getVaultCards = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/vault-card`,
            query: {
                test_mode: process.env.REACT_APP_ENVIRONMENT !== 'prod' ? 1 : null,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.vault_cards;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update installment payment information and push them to the provider
     * @param {string} invoicePaymentId
     * @param {object} params The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/e193daa15157df3511addeec97ef4439 document}
     * @returns
     */
    partialUpdateInstallments = (invoicePaymentId, params) => {
        const apiParams = new URLSearchParams();
        jsObjectToApi(params, apiParams);

        return API_PUBLIC_LOGGEDIN.patch(queryString.stringifyUrl({
            url: `/payments/${invoicePaymentId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), apiParams)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get paysafe sut_token
     * @param {string} invoiceNumber
     * @returns {Promise<string>}
     */
    getSutToken = (invoiceNumber) => {
        return API_PUBLIC_LOGGEDIN.get(`/invoices/${invoiceNumber}/payment-data`)
            .then((response) => {
                if(response.data.status){
                    return response.data.sut_token;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get payment data
     * @param {string} shoppingCartId
     * @returns {Promise<string>}
     */
    getPaymentData = (shoppingCartId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/carts/${shoppingCartId}/payment-data`,
            query: {
                test_mode: process.env.REACT_APP_ENVIRONMENT !== 'prod' ? 1 : null,
                language_code: this.props.I18nContext.getGenericLocale(),
                redirect_url: queryString.stringifyUrl({
                    url: window.location.href,
                    fragmentIdentifier: 'payment-confirmation',
                    query: {
                        shoppingCartId: shoppingCartId,
                    },
                }),
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.payment_data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the credit card's information
     * @param {string} invoicePaymentId
     * @returns {Promise}
     */
    getPaymentCard = (invoicePaymentId) => {
        return API_PUBLIC_LOGGEDIN.get(`/payments/${invoicePaymentId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data.installments[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Adds a credit card to the
     * @param {string} identityId
     * @param {string} params
     * @returns {Promise}
     */
    addPaysafeCard = (identityId, params) => {
        const apiParams = new URLSearchParams();
        jsObjectToApi(params, apiParams)
        return API_PUBLIC_LOGGEDIN.post(`/accounts/${identityId}/vault-card`, apiParams)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                return Promise.reject(response.data.provider_return);
            }, serverError)
    }

    /**
     * Deletes a credit card
     * @param {string} identityId
     * @param {string} permanentToken
     * @returns {Promise}
     */
    deletePaysafeCard = (identityId, permanentToken) => {
        return API_PUBLIC_LOGGEDIN.delete(`/accounts/${identityId}/vault-card/${permanentToken}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Process a payment
     * @param {string} shoppingCardId
     * @param {object} params The params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/600208631397c644db6e35b85a712c85 document}
     * @returns {Promise}
     */
    processPayment = (shoppingCardId, params = {}) => {
        if(process.env.REACT_APP_ENVIRONMENT !== 'prod'){
            params.test_mode = true
        }
        const apiParams = new URLSearchParams();
        jsObjectToApi(params, apiParams);
        return API_PUBLIC_LOGGEDIN.post(`/carts/${shoppingCardId}/process-payment`, apiParams)
            .then((response) => {
                if(response.data.status){
                    return response.data.provider_data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get invoice initial payment status
     * @param {string} shoppingCardId
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/600208631397c644db6e35b85a712c85|documentation}
     * @returns {Promise}
     */
    getInvoiceInitialPaymentStatus = (shoppingCardId) => {
        return API_PUBLIC_LOGGEDIN.get(`/carts/${shoppingCardId}/initial-payment-status`)
            .then((response) => {
                if(response.data.status){
                    return response.data.invoice_initial_payment_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get clinic and clinic attendee info by clinic instructor access
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/ff52b6d064273617f3ef8636e7d38517|documentation}
     * @param {string} identityId
     * @param {string} memberId
     * @returns {object[]}
     * @throws {Error}
     */
    getClinicInstructorInfo = (identityId, memberId) => {
        return API_PUBLIC_LOGGEDIN.get(`/accounts/${identityId}/members/${memberId}/clinic-instructors`)
            .then(({ data }) => {
                if(data.status){
                    return data.clinics.map((clinic) => ({
                        ...clinic,
                        attendees: data.clinic_attendees[clinic.clinic_id] || [],
                    }));
                }
                throw data.errors[0];
            }, serverError)
    }

    cancelWaitingListItem = (identityId, waitingListItemId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_PUBLIC_LOGGEDIN.patch(`/accounts/${identityId}/waiting-list-items/${waitingListItemId}/cancel`, params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * This call allow identity to create an address in their account
     * @param {string} identityId
     * @param {Object} address
     * @returns {Promise.<Array>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/70e5d99dfbf36b12f67302f3635fd55e
     */
    createIdentityAddress = (identityId, address) => {
        const params = new URLSearchParams();
        jsObjectToApi(address, params);

        return API_PUBLIC_LOGGEDIN.post(`/accounts/${identityId}/addresses`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_to_publish_identity_address;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a specific address link to an identity account
     * @returns {Promise}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/5d57fb4e55adc312e0a1f8e52c9e1961
     */
    deleteIdentityAddress = (identityId, identityAddressId) => {
        return API_PUBLIC_LOGGEDIN.delete(`/accounts/${identityId}/addresses/${identityAddressId}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Publish an identity address to member(s)
     * @param {string} identityId
     * @param {string} identityAddressId
     * @param {Object} values
     * @returns {Promise.<string>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/508434f46eeca8487ec75ccba71e5e3a
     */
    publishIdentityAddress = (identityId, identityAddressId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);
        return API_PUBLIC_LOGGEDIN.post(`/accounts/${identityId}/addresses/${identityAddressId}/publish`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_address_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * This call allows an identity to publish an unconfirmed address directly to a member (without adding it to the identity)
     * @param {string} identityId
     * @param {Object} address
     * @returns {Promise.<Array>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/70e5d99dfbf36b12f67302f3635fd55e
     */
    publishAddressToMember = (identityId, apiValues) => {
        const params = new URLSearchParams();
        jsObjectToApi(apiValues, params);

        return API_PUBLIC_LOGGEDIN.put(`/accounts/${identityId}/addresses/publish`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_to_publish_identity_address;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get address types by organisation id in parameter
     * @param {string} orgId
     * @returns {Promise.<Array>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Address%20Types/0000b592acae1bfaacb971567996839e
     */
    getAddressTypes = (orgId) => {
        return API_PUBLIC_LOGGEDIN.get(stringifyUrl({
            url: '/address-types',
            query: {
                active: 1,
                organisation_id: orgId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.address_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific address link to an identity account
     * @returns {Promise.<Array>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/ce1570b7e12151d48810d82bf9fb5e42
     */
    getAccountAddresses = () => {
        return API_PUBLIC_LOGGEDIN.get(stringifyUrl({
            url: `/accounts/${this.props.AuthContext.userData.Username}/addresses`,
            query: {
                active: 1,
            },
        }))
            .then(async(response) => {
                if(response.data.status){
                    return response.data.addresses;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Will mark the address a reviewed for another year
     * @param {string} identityAddressId
     * @param {string} [userName]
     * @returns {Promise}
     * @see https://api.id.dev.spordle.dev/documentations/#/Accounts/f871d0913cfc7812cc1ada61b64389d5
     */
    validateCurrentAddress = (identityAddressId, userName = this.props.AuthContext.userData.Username) => {
        return API_PUBLIC_LOGGEDIN.patch(`/accounts/${userName}/addresses/${identityAddressId}`, new URLSearchParams({ reviewed: 1 }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets customs forms for an invoice
     * @param {string} [invoiceNumber]
     * @returns {Promise.<Array>}
     */
    getInvoiceForms = (invoiceNumber, queryParams) => {
        return API_PUBLIC_LOGGEDIN.get(stringifyUrl({
            url: `/invoices/${invoiceNumber}/forms`,
            query: queryParams,
        }, {
            skipNull: true,
            skipEmptyString: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.invoice_custom_forms;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [PUT] - Updates a custom form
     * @param {string} memberId Id of the member
     * @param {string} formId Id of the custom form to update
     * @param {object} values The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Custom%20Forms/Apicontroller%5CMembers%5CCustomforms%3A%3AgetCustomFormAnswers|documentation}
     * @returns {Promise.<Array>}
     */
    updateForm = (memberId, formId, values) => {
        const params = new URLSearchParams();

        params.append('invoice_item_id', values.invoice_item_id);

        // jsObjectToApi(postData, params, {skipNull: true, skipEmptyString: true});

        values.fields.forEach((field, i) => {
            params.append(`fields[${i}][custom_form_field_id]`, field.custom_form_field_id);

            if(field.custom_form_field_option_selected){
                params.append(`fields[${i}][custom_form_field_option_selected]`, field.custom_form_field_option_selected);
            }

            params.append(`fields[${i}][answer]`, field.answer || ''); // this is probably the culprit
            params.append(`fields[${i}][active]`, 1);
        })

        return API_PUBLIC_LOGGEDIN.put(queryString.stringifyUrl({ url: `/members/${memberId}/custom-forms/${formId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a list of the account's uploaded documents
     * @promise
     * @param {string} identityId Identity Id
     * @param {Object} queryParams Query - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/43ba6177dfefcfe18fbfc9933886bf62|documentation}
     * @returns {Promise.<object[]>}
     * @throws {object}
     */
    getAccountDocuments = (identityId, queryParams) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/attachments`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_attachments;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get the download link for an attachment
     * @param {string} identityId ID of the identity to download attachments from
     * @param {string} memberAttachmentId ID of the member attachment
     * @returns {Promise}
     */
    downloadAccountDocument = (identityId, memberAttachmentId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/attachments/${memberAttachmentId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get travel types
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/b0472f82965609d82e2425db87a7a20e|documentation}
     * @returns {Promise.<Array>}
     */
    getTravelTypes = (queryParams) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/travel-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] - Get the identity's travel permits
     * @param {string} identityId Identity ID
     * @param {string} memberId Member ID
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/e60541c28dc66669c269ea2fa1c0a59e|documentation}
     * @returns {Promise}
     */
    getTravelPermits = (identityId, memberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/travel-permits`,
            query: {
                member_id: memberId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permits;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Create Team travel permit
     * @param {object} values to create Team contact type
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AcreateTeamContacttype|documentation}
     * @returns {Promise}
     */
    createTeamTravelPermit = ({ attachments, ...values }, org_id, periodId) => {
        const params = new FormData();
        params.append('identity_id', this.props.AuthContext.userData.Username)
        params.append('organisation_id', org_id)
        params.append('period_id', periodId)

        jsObjectToApi(values, params, {
            skipEmptyString: true,
        })

        if(attachments){
            let index = 0;
            Object.keys(attachments).forEach((documentTypeId) => {
                attachments[documentTypeId].forEach((document) => {
                    params.append(`attachments[${index}][attachment]`, document)
                    params.append(`attachments[${index}][active]`, 1)
                    if(documentTypeId != 'null')
                        params.append(`attachments[${index}][document_type_id]`, documentTypeId)
                    index++
                })
            })
        }

        return API_PUBLIC.post(queryString.stringifyUrl({ url: `/accounts/${this.props.AuthContext.userData.Username}/travel-permits` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team travel permit
     * @param {string} travel_permit_id
     * @param {string} team_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AupdateTeamContacttype|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamTravelPermitPartial = (travel_permit_id, team_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
            // case 'active':
            //     params.append('active', (values[key] == '1') >>> 0);
            //     break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${this.props.AuthContext.userData.Username}/travel-permits/${travel_permit_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] - Submit travel permit
     * @param {string} identityId Identity Id
     * @param {string} travelPermitId Travel permit Id
     * @returns {Promise}
     */
    submitTravelPermit = (identityId, travelPermitId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/accounts/${identityId}/travel-permits/${travelPermitId}/submit`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete specific travel permit
     * @param {string} team_id
     *  @param {string} travel_permit_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AdeleteTeamContacttypeDetail|documentation}
     * @returns {Promise}
     */
    deleteTravelPermit = (team_id, travel_permit_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/accounts/${this.props.AuthContext.userData.Username}/travel-permits/${travel_permit_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the downloadable link for the attachment
     * @param {string} travelPermitId
     * @param {string} travelPermitAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/0ef80ff6e534b0915965e6501d79980a|documentation}
     * @returns {Promise.<string>}
     */
    downloadTravelPermitAttachment = (travelPermitId, travelPermitAttachmentId) => {
        return API_PUBLIC.get(`travel-permits/${travelPermitId}/attachments/${travelPermitAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a travel permit attachment
     * @param {string} travelPermitId
     * @param {Array} attachments
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/b492f1fe291d6b90e88242b35e1765c7|documentation}
     * @returns {Promise.<Array>}
     */
    createTravelPermitAttachment = (travelPermitId, attachments, documentTypeId) => {
        const params = new FormData();
        attachments.forEach((file, index) => {
            params.append(`attachments[${index}][attachment]`, file.attachment);
            params.append(`attachments[${index}][active]`, file.active);
            if(documentTypeId)
                params.append(`attachments[${index}][document_type_id]`, documentTypeId);
        })

        return API_PUBLIC.post(queryString.stringifyUrl({ url: `travel-permits/${travelPermitId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_attachment_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a travel permit attachment
     * @param {string} travelPermitId
     * @param {string} travelPermitAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/b9f4e179637580a68ae9c597327037aa|documentation}
     * @returns {Promise.<Array>}
     */
    deleteTravelPermitAttachment = (travelPermitId, travelPermitAttachmentId) => {
        return API_PUBLIC.delete(queryString.stringifyUrl({ url: `travel-permits/${travelPermitId}/attachments/${travelPermitAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [GET] - external link for a clinic session (moodle)
     * @param {string} identityId Identity ID
     * @param {string} memberId Member ID
     * @param {string} clinicId Member ID
     * @returns {Promise}
     */
    getExternalLink = (identityId, memberId, clinicId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/members/${memberId}/clinics/${clinicId}/external-url`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.external_link;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <AccountsContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </AccountsContext.Provider>
        )
    }
}

export default withContexts(AuthContext, I18nContext)(AccountsContextProvider)