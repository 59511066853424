import React from 'react';
import {
    Nav,
    Navbar,
    NavbarBrand,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    NavLink
} from 'reactstrap'

// I18N
import { LOCALES } from '../../../i18n';
import Translate from '@spordle/intl-elements';
import spordleMyAccount from '../../../assets/images/logos/spordleMyAccount.svg';
import spordleMonCompte from '../../../assets/images/logos/spordleMonCompte.svg';
import spordleIcon from '../../../assets/images/logos/spordle_icon.svg';

// Context Providers
import { I18nContext } from '../../../contexts/I18nContext';
import { AuthContext, AccountsContext } from '../../../contexts/contexts';
import { withRouter } from 'react-router';
import MainPhoto from '../../../components/MainPhoto';
import HeaderApps from './headerApps/HeaderApps';
import HeaderNotifications from './headerNotifications/HeaderNotifications';
import withContexts from '../../../helpers/withContexts';
import { Link } from 'react-router-dom';

class Header extends React.Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.searchtoggle = this.searchtoggle.bind(this);
        this.showMobilemenu = this.showMobilemenu.bind(this);
        this.sidebarHandler = this.sidebarHandler.bind(this);
        this.state = {
            isOpen: false,
            collapse: false,
        };
    }

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/
    toggle(){
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
    }

    searchtoggle(){
        this.setState((state) => ({
            collapse: !state.collapse,
        }))
    }

    /*--------------------------------------------------------------------------------*/
    /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
    /*--------------------------------------------------------------------------------*/
    showMobilemenu(){
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    sidebarHandler = () => {
        const element = document.getElementById('main-wrapper');
        const activeSidebarType = "full";
        switch (activeSidebarType){
            case 'full':
            case 'iconbar':
                element.classList.toggle('mini-sidebar');
                if(element.classList.contains('mini-sidebar')){
                    element.setAttribute('data-sidebartype', 'mini-sidebar');
                }else{
                    element.setAttribute(
                        'data-sidebartype',
                        activeSidebarType,
                    );
                }
                break;

            case 'overlay':
            case 'mini-sidebar':
                element.classList.toggle('full');
                if(element.classList.contains('full')){
                    element.setAttribute('data-sidebartype', 'full');
                }else{
                    element.setAttribute(
                        'data-sidebartype',
                        activeSidebarType,
                    );
                }
                break;
            default:
        }
    };

    render(){
        return (
            <AuthContext.Consumer>
                {({ accessToken, userData, signOut, updateUserInfo }) => {
                    const user = userData?.UserAttributes;

                    return (
                        <I18nContext.Consumer>
                            {({ locale, setLocale, getGenericLocale }) => {
                                const mainLogo = getGenericLocale() === 'fr' ? spordleMonCompte : spordleMyAccount;
                                return (
                                    <header className="topbar navbarbg" data-navbarbg={this.props.themeValues.activeNavbarBg}>
                                        <Navbar className={"top-navbar " + (this.props.themeValues.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
                                            <div className="navbar-header" id="logobg" data-logobg={this.props.themeValues.activeLogoBg}>
                                                {/*--------------------------------------------------------------------------------*/}
                                                {/* Mobile View Toggler  [visible only before 768px screen]                         */}
                                                {/*--------------------------------------------------------------------------------*/}
                                                <span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
                                                    <i className="ti-menu ti-close" />
                                                </span>

                                                {/*--------------------------------------------------------------------------------*/}
                                                {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                                                {/*--------------------------------------------------------------------------------*/}
                                                <NavbarBrand href="/">
                                                    <span className="logo-icon">
                                                        <img
                                                            src={mainLogo}
                                                            alt="Spordle My Account"
                                                            className="mw-100 dark-logo full-logo"
                                                            width="240"
                                                        />
                                                        <img
                                                            src={spordleIcon}
                                                            alt="Spordle My Account"
                                                            className="reduced-logo"
                                                            height={40}
                                                        />
                                                        <img
                                                            src={mainLogo}
                                                            alt="Spordle My Account"
                                                            className="mw-100 light-logo full-logo"
                                                            width="240"
                                                        />
                                                    </span>
                                                </NavbarBrand>

                                                {/*--------------------------------------------------------------------------------*/}
                                                {/* Mobile Collapse toggler  [visible only before 768px screen]                    */}
                                                {/*--------------------------------------------------------------------------------*/}
                                                <span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
                                                    <i className="ti-more" />
                                                </span>
                                            </div>
                                            <Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.themeValues.activeNavbarBg}>
                                                <Nav className="float-left" navbar>
                                                    <NavItem>
                                                        <NavLink href="#" className="d-none d-md-block" onClick={this.sidebarHandler}>
                                                            <i className="ti-menu" />
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <Nav className="ml-auto" navbar>

                                                    {/* Notifications */}
                                                    <HeaderNotifications />

                                                    {/* Messages */}
                                                    {/* <HeaderMessages /> */}

                                                    {/* Apps menu */}
                                                    <HeaderApps />

                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {/* Start Languages Dropdown                                                      */}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle
                                                            nav
                                                            onClick={() => {
                                                                if(locale === 'fr-ca'){
                                                                    setLocale(LOCALES.ENGLISH);
                                                                    updateUserInfo({
                                                                        ...user,
                                                                        locale: 'en_US',
                                                                        language_code: 'en',
                                                                    }).catch(console.error)
                                                                }else{
                                                                    setLocale(LOCALES.FRENCH)
                                                                    updateUserInfo({
                                                                        ...user,
                                                                        locale: 'fr_CA',
                                                                        language_code: 'fr',
                                                                    }).catch(console.error)
                                                                }
                                                            }}
                                                        >
                                                            <span className="font-medium small">{locale === 'fr-ca' ? 'EN' : 'FR'}</span>
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {/* End Languages Dropdown                                                        */}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {/* Start Profile Dropdown                                                         */}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {(accessToken && userData) ?
                                                        <UncontrolledDropdown nav inNavbar>
                                                            <DropdownToggle nav caret className="pro-pic d-flex align-items-center">
                                                                <MainPhoto />
                                                                {/* <img
																	src={user.logo}
																	alt={user.name + ' ' + user.family_name}
																	className="rounded-circle"
																	width="30"
																	style={{width: 30, height: 30, objectFit: 'contain'}} //fix this later
																/> */}
                                                            </DropdownToggle>
                                                            <DropdownMenu right className="user-dd">
                                                                <div className="d-flex align-items-center border-bottom p-3 mb-2">
                                                                    <div>
                                                                        <MainPhoto width={70} />
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <div className="mb-0 h4">{user.name + ' ' + user.family_name}</div>
                                                                        <p className="mb-0">{user.email}</p>
                                                                    </div>
                                                                </div>
                                                                {process.env.REACT_APP_ENVIRONMENT === 'uat' &&
                                                                    <DropdownItem tag={Link} role="menuitem" to="/supportRequest">
                                                                        <i className="mdi mdi-wrench mx-1" /><Translate id='header.supportRequest' />
                                                                    </DropdownItem>
                                                                }
                                                                <DropdownItem onClick={() => signOut().then(() => { this.props.AccountsContext.resetAccount(); this.props.history.push('/auth/SPORDLE') }).catch(console.error)}>
                                                                    <i className="mdi mdi-logout mx-1" /><Translate id='header.signout' />
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        : ''}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                    {/* End Profile Dropdown                                                           */}
                                                    {/*--------------------------------------------------------------------------------*/}
                                                </Nav>
                                            </Collapse>
                                        </Navbar>
                                    </header>
                                )
                            }}
                        </I18nContext.Consumer>
                    )
                }}
            </AuthContext.Consumer>
        );
    }
}
export default withRouter(withContexts(AccountsContext)(Header));
