import Translate from "@spordle/intl-elements";
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

/**
 * Top right actions menu of the sidepanel
 * @param {Object} props
 * @param {string} props.children
 * @param {string} [props.label=misc.actions]
 * @param {string} [props.skipTranslateLabel=false]
 * @returns {JSX.Element}
 * @example
 * <SidePanelActionsMenu>
 *      <SidePanelMenuAction>Delete stuff</SidePanelMenuAction>
 * </SidePanelActionsMenu>
 */
const SidePanelActionsMenu = ({ children, label = 'misc.actions', skipTranslateLabel = false, ...props }) => {
    return (
        // inNavbar to disable popper (not useful in sidepanel)
        <UncontrolledDropdown inNavbar {...props}>
            <DropdownToggle caret color="primary">
                {skipTranslateLabel ? label : <Translate id={label} />}
            </DropdownToggle>
            <DropdownMenu right>
                {children}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default SidePanelActionsMenu;

SidePanelActionsMenu.propTypes = {
    label: PropTypes.string,
    skipTranslateLabel: PropTypes.bool,
}

SidePanelActionsMenu.defaultProps = {
    skipTranslateLabel: false,
    label: 'misc.actions',
}