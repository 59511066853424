export default{
    "account.api.logs.accountWebhooks.table.status"  : "Statut",
    "account.api.logs.accountWebhooks.table.type"    : "Type",
    "account.api.logs.accountWebhooks.table.id"      : "Id",
    "account.api.logs.accountWebhooks.table.date"    : "Date",
    "account.api.logs.accountWebhooks.table.actions" : "Actions",

    "account.api.logs.accountWebhooks.table.status.success" : "Réussi",
    "account.api.logs.accountWebhooks.table.status.fail"    : "Échoué",

    "account.api.logs.accountWebhooks.table.actions.retry" : "Réessayer",
}