import Invoice from '../../components/invoice/Invoice';
import Receipt from '../../components/invoice/Receipt';
import PageNotFound404 from '../../views/account/custom/PageNotFound404';
import SupportRequestProfile from '../../views/account/supportRequests/profile/SupportRequestProfile';
import SupportRequestSearch from '../../views/account/supportRequests/search/SupportRequestSearch';
import sidebarRoutes from './sidebarRoutes';

const supportRequestRoutes = process.env.REACT_APP_ENVIRONMENT === 'uat' ?
    [
        {
            path: '/supportRequest/:issueKey',
            component: SupportRequestProfile,
        },
        {
            path: '/supportRequest',
            component: SupportRequestSearch,
        },
    ]
    : [];

const accountRoutes = [
    //add all subroutes here
    ...sidebarRoutes,
    {
        exact: true,
        path: '/purchases/invoice/:invoiceNumber',
        component: Invoice,
    },
    {
        exact: true,
        path: '/purchases/receipt',
        component: Receipt,
    },
    ...supportRequestRoutes,

    //redirects
    { path: '/',			pathTo: '/dashboard', redirect: true, exact: true },


    // fallback to page not found
    { path: '/', component: PageNotFound404 },
]

export default accountRoutes;