import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useTooltip } from "../../../../../components/customHooks/useTooltip";
import { FormatHour } from "../../../../../components/schedule/components/helpers";
import UserImg from "../../../../../components/UserImg";
import { AuthContext, AccountsContext } from "../../../../../contexts/contexts";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import getReferrer from "../../../../../helpers/getReferer";
import queryString from 'query-string';

const ClinicEventModal = (props) => (
    <AnalyticsModal analyticsName='ClinicEventModal' isOpen={props.isOpen} toggle={props.toggle} backdrop="static" unmountOnClose>
        {props.event && <ModalInner {...props} />}
    </AnalyticsModal>
)

const ModalInner = ({ event, toggle }) => {
    const [ moodleLoading, setMoodleLoading ] = useState(false);

    const authContext = useContext(AuthContext);
    const accountsContext = useContext(AccountsContext);

    const getLocation = (type, name) => {
        let icon = "text-body font-20 mr-2 mdi mdi-";
        let label;

        switch (type){
            case 'ONLINE_LIVE':
                label = <Translate id='schedule.format.ONLINE_LIVE' />;
                icon += "access-point";
                break;
            case 'ONLINE':
                label = <Translate id='schedule.format.ONLINE' />;
                icon += "play";
                break;
            case 'ONSITE':
            default:
                label = name;
                icon += "map-marker";
                break;
        }

        return <><i style={{ lineHeight: 1 }} className={icon} />{label}</>
    }

    const getDate = () => {
        if(!event.start_date || !event.end_date){
            return event.date.format('YYYY-MM-DD');
        }

        const start = moment(event.start_date);
        const end = moment(event.end_date);

        return (
            <>
                {start.format('YYYY-MM-DD')}
                {Math.abs(start.diff(end, 'days', false)) > 0 &&
                    ` - ${end.format('YYYY-MM-DD')}`
                }
                {event.timeZoneAbr && ` (${event.timeZoneAbr})`}
            </>
        )
    }

    const openExternalLink = (memberId, clinicId) => {
        setMoodleLoading(true)
        accountsContext.getExternalLink(authContext.userData.Username, memberId, clinicId)
            .then((externalLink) => {
                if(externalLink)
                    window.open(externalLink);
            })
            .catch((error) => { console.error(error.message) })
            .finally(() => setMoodleLoading(false));
    }

    return (
        <>
            <ModalHeader tag="div" toggle={toggle}>
                <div className="d-flex align-items-center">
                    {/* <div className="rounded-lg mr-2" style={{height: 20, width: 20, backgroundColor: event.color ?? 'blue'}} /> */}
                    {event.title}
                </div>
            </ModalHeader>
            <ModalBody>
                { event.members?.length > 0 &&
                    <>
                        <div className="font-medium h5"><Translate id='schedule.label.members' /></div>
                        <div className="px-0 pb-3 event-item-members">
                            {event.members.map((member) => (
                                <div key={member.member_id}>
                                    <div className="d-flex align-items-center">
                                        <UserImg
                                            className="event-item-member-pill"
                                            alt={member.first_name + ' ' + member.last_name}
                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                            filePos={member.attachment?.file_position}
                                            src={member.attachment?.full_path}
                                            width={36}
                                        />
                                        <div className="h6 font-medium mb-0 ml-3">{member.first_name} {member.last_name}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="font-medium mb-2 h5">Info</div>
                            {event.clinic && <ClinicStoreBtns clinic={event.clinic} />}
                        </div>
                    </>
                }
                {event.clinic?.clinic_reference &&
                    <div className="d-flex justify-content-between">
                        <div className="mb-2 d-flex">
                            <i style={{ lineHeight: 1 }} className="font-20 mdi mdi-pound mr-2" />
                            <HoverableCopy toCopy={event.clinic.clinic_reference}> {event.clinic.clinic_reference}</HoverableCopy>
                        </div>
                        {(event.members?.length <= 0 && event.clinic) && <ClinicStoreBtns clinic={event.clinic} />}
                    </div>
                }
                <div className="mb-2 d-flex">
                    <i style={{ lineHeight: 1 }} className="font-20 mdi mdi-calendar mr-2" />
                    {getDate()}
                </div>
                <div className="mb-2 d-flex">
                    <i style={{ lineHeight: 1 }} className="font-20 mdi mdi-clock-outline mr-2" />
                    <FormatHour date={event.date.format('YYYY-MM-DD')} start={event.startTime} end={event.endTime} />
                </div>
                {event.location &&
                    <div className="mb-2 d-flex">
                        {event.locationLink ?
                            <a style={{ lineHeight: 1 }} href={event.locationLink} target="_blank" rel="noopener noreferrer">
                                {getLocation(event.session_format, event.location)}
                            </a>
                            :
                            <span className="text-body" style={{ lineHeight: 1 }}>{getLocation(event.session_format, event.location)}</span>
                        }
                    </div>
                }
                <div className="mb-2 d-flex">
                    <i style={{ lineHeight: 1 }} className="font-20 mdi mdi-calendar-text mr-2" />
                    {event.desc}
                </div>
            </ModalBody>
            <ModalFooter>
                {!!event.hasExternalUrl &&
                    <Button color="primary" outline disabled={moodleLoading} onClick={() => openExternalLink(event.member.member_id, event.clinic.clinic_id)}><Translate id="account.members.clinicsManagement.openMoodle" /> <i className="mdi mdi-launch" /></Button>
                }
                <Button color="primary" onClick={toggle}><Translate id="misc.close" /></Button>
            </ModalFooter>
        </>
    )
}


const ClinicStoreBtns = ({ clinic }) => {
    const { accessToken } = useContext(AuthContext);
    const { formatMessage } = useIntl();
    const openStoreTooltip = useTooltip('claendar_event_clinic_tip', formatMessage({ id: 'account.members.clinicsManagement.openStore' }));
    const storeLink = `${getReferrer('HCR-SITE')}/clinic-details/${clinic.clinic_id}`;

    return (
        <span className="d-flex align-items-center ml-auto line-height-1">
            <a
                {...openStoreTooltip}
                className="reset-btn px-2 mr-2"
                href={queryString.stringifyUrl({
                    url: storeLink,
                    query: {
                        accessToken: accessToken,
                    },
                })}
                target="_blank"
                rel="noopener noreferrer"
                title={formatMessage({ id: 'account.members.clinicsManagement.openStore' })}
            >
                <i className="font-22 mdi mdi-cart-outline" />
            </a>
        </span>
    )
}


export default ClinicEventModal;