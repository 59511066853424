import AccountDashboard from '../../views/account/dashboard/AccountDashboard';
import AccountSettings from '../../views/account/settings/AccountSettings';
import Transactions from '../../views/account/purchases/Transactions';
import AccountMembers from '../../views/account/members/AccountMembers';
import AccountCalendar from '../../views/account/calendar/AccountCalendar';
import PaymentMethodNavLink from '../../views/account/paymentMethods/PaymentMethodNavLink';
import AccountPaymentMethods from '../../views/account/paymentMethods/AccountPaymentMethods';

// used by sidebar and accountRoutes
const sidebarRoutes = [
    {
        name: 'sidebar.dashboard',
        icon: 'ti-home',

        exact: true,
        path: '/dashboard',
        component: AccountDashboard,
    },
    {
        name: 'sidebar.members',
        icon: 'far fa-user',
        exact: true,
        path: '/members',
        component: AccountMembers,
        // component: ComingSoon
    },
    {
        name: 'sidebar.calendar',
        icon: 'far fa-calendar-alt',
        exact: true,
        path: '/calendar',
        component: AccountCalendar,
        // component: ComingSoon
    },
    {
        name: 'sidebar.payments',
        icon: 'mdi mdi-credit-card',

        exact: true,
        path: '/payment',
        customNavLink: PaymentMethodNavLink,
        component: AccountPaymentMethods,
    },
    {
        name: 'sidebar.purchases',
        icon: 'mdi mdi-cart-outline',
        exact: true,
        path: '/purchases',
        component: Transactions,
    },
    // {
    // 	name: 'sidebar.communications',
    // 	icon: 'far fa-envelope',

    //     exact: true,
    // 	path: '/communications',
    // 	component: ComingSoon
    // },
    // {
    // 	name: 'sidebar.documents',
    // 	icon: 'far fa-file',

    //     exact: true,
    // 	path: '/documents',
    // 	component: ComingSoon
    // },
    {
        name: 'sidebar.settings',
        icon: 'icon-settings',

        exact: true,
        path: '/settings',
        component: AccountSettings,
    },
    // {
    // 	sidebarRoute: true,
    // 	divider:true
    // },
    // {
    // 	name: 'sidebar.api',
    // 	icon: 'mdi mdi-server',
    //     exact: true,
    // 	path: '/api',
    // 	component: AccountAPI
    // },
];
export default sidebarRoutes;