import Translate from "@spordle/intl-elements";
import { parseUrl, stringifyUrl } from "query-string";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Collapse, Fade, ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import { MembersContext } from "../../../../contexts/MembersContext";
import { useQuery } from "../../../../helpers/useQuery";
import { AccountsStatusContext } from "../../../../contexts/AccountsStatusContext";
import { AccountsContext } from "../../../../contexts/contexts";

const allStatus = (status, paymentId) => {
    const statusObj = {
        "completed": {
            color: "success",
            icon: "mdi-check-circle-outline",
            title: <Translate id='account.paymentMethods.sportPayReturnModal.success.title' />,
            paymentId: paymentId,
            text: (
                <>
                    <Translate id={paymentId ? 'account.paymentMethods.sportPayReturnModal.success.text' : 'account.paymentMethods.sportPayReturnModal.success.initialPayment.text'} />
                    {paymentId &&
                    <div className='font-bold'>
                        <Translate id='account.paymentMethods.sportPayReturnModal.success.helper' />
                    </div>
                    }
                </>
            ),
            button: <><Translate id='account.paymentMethods.sportPayReturnModal.success.btn' /> <i className="mdi mdi-check" /></>,
        },
        "pending": {
            color: "primary",
            icon: "mdi-clock-outline",
            title: <Translate id='account.paymentMethods.sportPayReturnModal.pending.title' />,
            text: <Translate id='account.paymentMethods.sportPayReturnModal.pending.text' />,
            button: <Translate id="misc.close" />,
        },
        "declined": {
            color: "danger",
            icon: "mdi-close-circle-outline",
            title: <Translate id='account.paymentMethods.sportPayReturnModal.declined.title' />,
            text: <Translate id='account.paymentMethods.sportPayReturnModal.declined.text' />,
            button: <Translate id="misc.close" />,
        },
        "need_review": {
            color: "danger",
            icon: "mdi-close-circle-outline",
            title: <Translate id='account.paymentMethods.sportPayReturnModal.declined.title' />,
            text: <Translate id='account.paymentMethods.sportPayReturnModal.declined.text' />,
            button: <Translate id="misc.close" />,
        },
        "error": {
            color: "dark",
            icon: "mdi-alert-circle-outline",
            title: <Translate id='account.paymentMethods.sportPayReturnModal.error.title' />,
            text: <Translate id='account.paymentMethods.sportPayReturnModal.error.text' />,
            button: <Translate id="misc.close" />,
        },
    }

    if(statusObj[status]){
        return statusObj[status];
    }
    return statusObj.error;


}

/**
 * @description Modal popped when back from SportPay with query card-attach
 *
 * @param {Object} props
 * @returns {JSX|null}
 */
const SportPayReturnModal = (props) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(true);
    const [ param, setParam ] = useState(false);
    const [ shoppingCartId, setShoppingCartId ] = useState('');
    const [ cardAtt, setCardAtt ] = useState('');
    const querySportPay = useQuery();
    const history = useHistory();
    const location = useLocation();
    const accountsStatusContext = useContext(AccountsStatusContext);

    useEffect(() => {
        const cardAtt = querySportPay.get("card-attach");
        const shoppingCartId = querySportPay.get("shoppingCartId");

        const parsedUrl = parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
        delete parsedUrl.query.shoppingCartId;
        delete parsedUrl.query['card-attach'];

        if(cardAtt){
            setCardAtt(cardAtt);
            accountsStatusContext.changeShowPopups(false);
            setParam(true);
            history.replace(stringifyUrl(parsedUrl))
        }else if(shoppingCartId){
            setShoppingCartId(shoppingCartId);
            accountsStatusContext.changeShowPopups(false);
            setParam(true);
            history.replace(stringifyUrl(parsedUrl))
        }

    }, []);

    const toggle = () => {
        setModalIsOpen(false)
        accountsStatusContext.changeShowPopups(true);
    }

    return (
        param ?
            <AnalyticsModal isOpen={modalIsOpen} analyticsName="sportsPayReturnModal">
                <SportPayReturnModalInner paymentCardId={cardAtt} shoppingCartId={shoppingCartId} toggle={toggle} {...props} />
            </AnalyticsModal>
            :
            null
    )
}

/**
 * @description Inner modal. Will reset state when parent is unmounted
 *
 * @param {Object} props
 * @param {function} props.toggle If is open by default
 * @param {string} props.paymentCardId id of the payment card changed
 * @returns {JSX}
 */
const SportPayReturnModalInner = ({ paymentCardId, shoppingCartId, toggle }) => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ status, setStatus ] = useState(null);
    const { getCardAttachmentStatus } = useContext(MembersContext);
    const { getInvoiceInitialPaymentStatus } = useContext(AccountsContext);

    useEffect(() => {
        if(paymentCardId){
            getCardAttachmentStatus(paymentCardId)
                .then((card) => {
                    setStatus(allStatus(card.status.toLowerCase(), card.invoice_payment_id));
                    setIsLoading(false);
                })
                .catch((err) => {
                    if(!AxiosIsCancelled(err.message)){
                        console.error(err);
                        setStatus(allStatus("error"));
                        setIsLoading(false);
                    }
                })
        }else if(shoppingCartId){
            getInvoiceInitialPaymentStatus(shoppingCartId)
                .then((status) => {
                    const statusForModal = status === 'COMPLETED' ? status.toLowerCase() : 'declined'; // we want to display an error if the paymet is still pending
                    setStatus(allStatus(statusForModal));
                    setIsLoading(false);
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setIsLoading(false);
                    }
                })
        }
    }, []);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader className="position-absolute right-0 border-0 z-index-1" toggle={toggle} />
            <Collapse isOpen={isLoading}>
                <Fade in={isLoading}>
                    <ModalBody className="text-center">
                        <div className="h3 font-bold mt-3">
                            <Translate id='account.paymentMethods.sportPayReturnModal.loading.title' />
                        </div>
                        <p>
                            <Translate id='account.paymentMethods.sportPayReturnModal.loading.text' />
                        </p>
                    </ModalBody>
                </Fade>
            </Collapse>
            {!isLoading && status &&
                <Collapse isOpen appear>
                    <Fade>
                        <ModalBody className="text-center">
                            <i style={{ fontSize: "3.5rem" }} className={`text-${status.color} mdi ${status.icon}`} />
                            <div className={`h3 font-bold text-${status.color}`}>{status.title}</div>
                            <p>{status.text}</p>
                            <Button onClick={toggle} color={status.color} type="button">
                                {status.button}
                            </Button>
                        </ModalBody>
                    </Fade>
                </Collapse>
            }
        </OverlayLoader>
    )
}

export default SportPayReturnModal;