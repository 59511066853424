import FadeIn from "../../animations/FadeIn";

const SharedEvent = ({ start, duration, isContinuing = false, isExceeding = false, children, ...props }) => {

    const getClasses = () => {
        const arr = [ 'schedule-weekly-shared-event', `duration-${duration ?? '2'}` ];

        if(isExceeding) arr.push('is-exceeding');
        if(isContinuing) arr.push('is-continuing');
        if(start !== 0) arr.push(`is-offset is-offset-${start ?? 0}`);

        return arr.join(' ');
    }

    return (
        <FadeIn
            distance={50}
            direction='left'
            style={{ opacity: 0 }}
            className={getClasses()}
            {...props}
        >
            <div className="schedule-weekly-shared-event-inner">
                {children}
            </div>
        </FadeIn>
    )
}

export default SharedEvent;