import React from 'react';
import images from '@spordle/ui-kit';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import { I18nContext } from '../../../contexts/I18nContext'
const spordleLogo = images.logospordlewhite;

class Footer extends React.PureComponent{
    state = {
        dropdown: false,
    }

    toggleDropdown = () => this.setState((state) => ({ dropdown: !state.dropdown }));

    render(){
        return (
            <footer className="footer p-3 mt-auto">
                <div className="d-flex align-items-center">
                    <a className="hide-menu" href="https://spordle.com" target="_blank" rel="noopener noreferrer"><img src={spordleLogo} alt="Powered By Spordle" width="75" /></a>
                    <div className="ml-auto">

                        <UncontrolledDropdown isOpen={this.state.dropdown} toggle={this.toggleDropdown}>
                            <DropdownToggle color="link" className="mr-n3">
                                <i className="mdi mdi-dots-horizontal mdi-36px text-white" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <I18nContext.Consumer>
                                    {({ getGenericLocale }) => (
                                        <>
                                            <DropdownItem href={getGenericLocale() === 'en' ? "https://www.spordle.com/terms/" : "https://www.spordle.com/fr/termes/"} target="_blank">
                                                <Translate id='sidebar.terms' />
                                            </DropdownItem>
                                            <DropdownItem href={getGenericLocale() === 'en' ? "https://www.spordle.com/about/" : "https://www.spordle.com/fr/a-propos/"} target="_blank">
                                                <Translate id='sidebar.about' />
                                            </DropdownItem>
                                        </>
                                    )}
                                </I18nContext.Consumer>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                {(process.env.REACT_APP_ENVIRONMENT !== "prod") &&
                    <div className='text-truncate'>Version: {process.env.REACT_APP_ENVIRONMENT} {process.env.REACT_APP_BUILD_DATE}</div>
                }
            </footer>
        );
    }
}
export default Footer;
