import {
    Row,
    Container,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    UncontrolledDropdown
} from 'reactstrap';

import HCLogo from '../assets/images/logos/HC@2x.png';
import SpordleLogo from '../assets/images/logos/spordle.png';
import LangSwitcher from '../components/LangSwitcher';
import WithDefaultTheme from '../components/HOC/WithDefaultTheme';

const Home = (props) => {
    return (
        <div className="auth-wrapper">
            <div className="p-3 position-absolute top-0 right-0">
                <LangSwitcher />
            </div>

            <Container>
                <div className="text-center mb-5">
                    <div className="h3 font-bold">Spordle Accounts</div>
                    <div className="text-danger mt-2">**This page is dev/stage only <small>(for now at least :-))</small>**</div>
                </div>
                <Row className="text-center">
                    <Col md='4'>
                        <Card>
                            <CardBody>
                                <div>
                                    <div className="my-4 d-flex justify-content-center" style={{ height: 100 }}>
                                        <div className="shadow round bg-transparent d-flex justify-content-center align-items-center border" style={{ width: "100px", height: "100px", overflow: "hidden" }}>
                                            <img className="rounded-0" src={SpordleLogo} width="100px" alt="SpordleLogo" />
                                        </div>
                                    </div>
                                    <div className="h4 font-bold text-dark">Spordle Account</div>
                                    <div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret>
                                                Go to
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { props.history.push(`/auth/SPORDLE`) }}>Login</DropdownItem>
                                                <DropdownItem onClick={() => { props.history.push(`/auth/SPORDLE`) }}>My account</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='4'>
                        <Card>
                            <CardBody>
                                <div>
                                    <div className="my-4 d-flex justify-content-center" style={{ height: 100 }}>
                                        <div className="shadow round bg-transparent d-flex justify-content-center align-items-center border" style={{ width: "100px", height: "100px", overflow: "hidden" }}>
                                            <img className="rounded-0" src={HCLogo} width="90px" alt="HCLogo" />
                                        </div>
                                    </div>
                                    <div className="h4 font-bold text-dark">HCR</div>
                                    <div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret>
                                                Go to
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { props.history.push(`/auth/HCR`) }}>Login</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {/*<Col md='4'>
                        <Card>
                            <CardBody>
                                <div>
                                    <div className="my-4 d-flex justify-content-center" style={{height: 100}}>
                                        <div className="shadow round bg-transparent d-flex justify-content-center align-items-center border" style={{width:"100px", height:"100px", overflow:"hidden"}}>
                                            <img className="rounded-0" src={"https://www.publicationsports.com/vProd/theme/ms/509/images/logo.png"} width="80px" alt="Logo TOUR" />
                                        </div>
                                    </div>
                                    <div className="h4 font-bold text-dark">Tournament</div>
                                    <div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret>
                                                Go to
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => {props.history.push(`/auth/TOUR?referrer=${getReferrer('TOUR')}`)}}>Login</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>*/}
                </Row>
            </Container>
        </div>
    );
}

export default WithDefaultTheme(Home);