import { Card, ListGroup } from 'reactstrap';
import Translate from '@spordle/intl-elements';

import AccountName from './components/AccountName';
import AccountEmail from './components/AccountEmail';
import AccountBirthdate from './components/AccountBirthdate';
import AccountGender from './components/AccountGender';
import AccountPhone from './components/AccountPhone';
import AccountPassword from './components/AccountPassword';
import AccountLanguage from './components/AccountLanguage';
import AccountAddess from './components/AccountAddess';

const AccountProfile = ({ updateAttributes }) => {
    return (
        <Card body className="card-shadow">
            <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                <div className="h4 mb-0 font-bold card-title"><Translate id='account.settings.profile.accountProfile.title' /></div>
            </div>
            <ListGroup flush>
                <AccountName updateAttributes={updateAttributes} />
                <AccountEmail updateAttributes={updateAttributes} />
                <AccountBirthdate updateAttributes={updateAttributes} />
                <AccountGender updateAttributes={updateAttributes} />
                <AccountLanguage updateAttributes={updateAttributes} />
                <AccountPhone updateAttributes={updateAttributes} />
                <AccountAddess updateAttributes={updateAttributes} />
                <AccountPassword updateAttributes={updateAttributes} />
            </ListGroup>
        </Card>
    )
}

export default AccountProfile;