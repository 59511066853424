import auth from "./auth";
import components from "./components";
import contexts from "./contexts";
import global from "./global";
import views from "./views";

export default{
    ...auth,
    ...components,
    ...contexts,
    ...global,
    ...views,
}