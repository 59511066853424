import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Spinner
} from "reactstrap";
import UserGuide from './UserGuide';
import userGuideIcon from '../../assets/images/helpCenter/UserGuide.svg';
import spordleCoach from '../../assets/images/helpCenter/spordleCoach.png'
import { HelpCenterContext } from '../../contexts/HelpCenterContext';
import { useIntl } from 'react-intl';
import SubmitTicket from './submitTicket/SubmitTicket';
import { AuthContext } from '../../contexts/contexts';

const HelpCenter = (props) => {
    const intl = useIntl();
    const helpContext = useContext(HelpCenterContext);
    const authContext = useContext(AuthContext);

    const [ submitTicket, setSubmitTicket ] = useState(false)
    const [ help, setHelp ] = useState(null);
    const [ helpLoading, setHelpLoading ] = useState(false);

    const [ userGuide, setUserGuide ] = useState(false)
    const toggleUserGuide = () => setUserGuide(!userGuide)

    const [ chatIsOpen, setChatIsOpen ] = useState(false);
    const [ , setChatDisabled ] = useState(false);

    const [ menuIsOpen, setMenuIsOpen ] = useState(false)
    const toggleMenu = () => setMenuIsOpen(!menuIsOpen);

    useEffect(() => {
        if(menuIsOpen){
            const newPath = props.location.pathname.split('/');
            let path = '/my-account';

            newPath.forEach((_part) => {
                if(_part.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/)){
                    path += '/{ID}';
                }else if(_part) path += '/' + _part
            })

            if(help?.url !== path + (props.location.hash || '')){
                setHelpLoading(true);
                helpContext.getHelpCenter(path + props.location.hash || '')
                    .then((_help) => {
                        setHelp(_help.length > 0 ? { url: path + props.location.hash || '', guide: _help } : null);
                    })
                    .catch(console.error)
                    .finally(() => setHelpLoading(false))
            }
        }
    }, [ props.location, menuIsOpen ])

    /**
    * this can be used to open the submitTicket modal with a default type
    * @param {string} [type=null]
    */
    const toggleSubmitTicket = (defaultType = null) => {
        setSubmitTicket((prev) => ({
            isOpen: !prev.isOpen,
            defaultType: defaultType,
        }))
    }

    // useEffect(() => {
    //     if(menuIsOpen && !chatIsInit){
    //         initChat();

    //         const observer = new MutationObserver((mutations, observer) => {
    //             mutations.forEach(mutation => {
    //                 mutation.addedNodes.forEach(addedNode => {
    //                     if(addedNode.id === 'chat-container'){
    //                         setChatIsInit(true);
    //                         setChatLoading(false);
    //                     }
    //                 })
    //             })
    //         })

    //         // observe on body, it finds a lot of mutations, but I couldn't find a way to make it work on a div
    //         // the callback above shouldn't take too long to do, so it's not that bad
    //         observer.observe(document.body, {childList: true})

    //         return () => observer.disconnect()
    //     }
    // }, [menuIsOpen]);

    // eslint-disable-next-line unused-imports/no-unused-vars
    const initChat = () => {
        // IMPORTANT: Leave the follwing functions as standard functions because we need the `this` fonctionality
        function listenToSpartez(){
            // Gets the default XMLHttpRequest settings -> Will need it later
            const origOpen = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function(){
                const xmlThis = this;
                function spartezHandler(){
                    // Will be called when an api call returns something...
                    // We will do something only if the call is the init-chat call
                    if(this.responseURL.startsWith('https://chat-api.spartez-software.com/init-chat')){
                        const chatSettings = JSON.parse(this.responseText);
                        // if we should hideWhenOffline and if there is no aggents online or the chat is disabled
                        setChatDisabled(chatSettings.portalConfig.hideWhenOffline && (chatSettings.onlineAgentsCount === 0 || chatSettings.portalConfig.enabled === 0))// Sets the chatDisabled state here

                        // This call will happen only once during the user's session -> Stop listening when we are done with it
                        xmlThis.removeEventListener('load', spartezHandler);// Stops listening
                        XMLHttpRequest.prototype.open = origOpen;// Sets back the original XML settings
                    }
                }
                this.addEventListener('load', spartezHandler);// Adding the listener
                origOpen.apply(this, arguments);// Use the default settings to continue like normal
            };
        }
        listenToSpartez();// Add an event listener on chat init

        // variable in the window object containing the initial config for the chat
        // documentation: https://confluence.spartez-software.com/display/CFSD/Configuring+Chat+Widget
        window.spartezSupportChat = {
            portal: 4,
            cloud: {
                jiraId: 'ad5f3b31-a73e-3876-85fc-d274148c86b9',
                jiraUrl: 'https://spordle.atlassian.net',
                urls: {
                    rest: 'https://chat-api.spartez-software.com',
                    ws: 'wss://chat-ws.spartez-software.com',
                },
            },
            container: 'chat-container',
            iconClass: 'd-none',
            chatClass: 'help-dropdown-menu',
        }

        // script tag doing API calls to get the complete config for the chat
        const supportChat = document.createElement('script')
        supportChat.type = 'text/javascript'
        supportChat.src = "https://chat-api.spartez-software.com/chat.js";
        supportChat.async = true;
        document.head.appendChild(supportChat);
    }

    const toggleChat = () => {
        // support-chat-icon is the id from the support chat script thingy
        // when we click the "Chat" dropdown item, we "relay" the click to that button to open the chat
        // same process for closing it
        document.getElementsByClassName('support-chat-icon')?.[0]?.click();
    }

    const hasSupportRequests = process.env.REACT_APP_ENVIRONMENT === 'uat' && authContext.accessToken

    return (
        <>
            {hasSupportRequests &&
                <SubmitTicket isOpen={submitTicket.isOpen} toggle={toggleSubmitTicket} defaultType={submitTicket.defaultType} />
            }
            <UserGuide
                guide={help?.guide[0]}
                isOpen={userGuide}
                toggle={toggleUserGuide}
                toggleSubmitTicket={toggleSubmitTicket}
                hasSupportRequests={hasSupportRequests}
            />
            <Dropdown className="help-dropdown-container" isOpen={menuIsOpen} toggle={toggleMenu}>
                {!chatIsOpen ?
                    <DropdownToggle tag="button" type="button" className="help-dropdown-toggle btn btn-primary">
                        <i className={menuIsOpen ? "mdi mdi-close" : "mdi mdi-help"} />
                    </DropdownToggle>
                    :
                    <button type="button" className="help-dropdown-toggle btn btn-primary" onClick={() => { setChatIsOpen(false); toggleChat() }}>
                        <i className="mdi mdi-close" />
                    </button>
                }
                <DropdownMenu right className="help-dropdown-menu">
                    <DropdownItem type="button" className="help-dropdown-header" header tag="div"><Translate id='helpCenter.title' /></DropdownItem>
                    <DropdownItem type="button" className="help-dropdown-item" onClick={toggleUserGuide} disabled={!help || helpLoading}>
                        <div className='d-flex align-items-center'>
                            <img className="align-middle" src={userGuideIcon} alt={intl.formatMessage({ id: 'helpCenter.userGuide' })} height="30" width="30" />
                            <span className="align-middle ml-1"><Translate id='helpCenter.userGuide' /></span>
                            {helpLoading &&
                                <div className='ml-auto'>
                                    <Spinner color='primary' type='grow' style={{ height: '30px', width: '30px' }} />
                                </div>
                            }
                        </div>
                    </DropdownItem>
                    {/* <DropdownItem type="button" className="help-dropdown-item" disabled={chatLoading || chatIsDisabled} onClick={() => {setChatIsOpen(true); toggleChat()}}>
                        <div className='d-flex align-items-center'>
                            <img className="align-middle" src={chatIcon} alt={intl.formatMessage({id: 'helpCenter.chat'})} height="30" width="30" />
                            <span className="align-middle ml-1"><Translate id='helpCenter.chat'/>{chatIsDisabled && <> - <Translate id='helpCenter.chat.offline'/></>}</span>
                            {chatLoading &&
                                <div className='ml-auto'>
                                    <Spinner color='primary' type='grow' style={{height: '30px', width: '30px'}}/>
                                </div>
                            }
                        </div>
                    </DropdownItem> */}
                    { hasSupportRequests &&
                        <DropdownItem className="help-dropdown-item" onClick={() => toggleSubmitTicket()}>
                            <img className="align-middle" src={spordleCoach} alt={intl.formatMessage({ id: 'helpCenter.submitTicket' })} height="30" width="30" />
                            <span className="align-middle ml-1"><Translate id='helpCenter.submitTicket' /></span>
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default withRouter(HelpCenter);