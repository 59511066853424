import { Collapse, ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import CrossFade from "../../../gameIncidentReports/components/CrossFade";
import AccountMemberAddCoachContextProvider, { AccountMemberAddCoachContext } from "./components/AccountMemberAddCoachContext";
import OfficialInfo from "./components/OfficialInfo";

const AccountMemberAddCoachReport = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="AddOfficiatingCoachReport">
            <AccountMemberAddCoachReportInner {...props} />
        </AnalyticsModal>
    );
}

const AccountMemberAddCoachReportInner = ({ types = [], toggle, member }) => {
    return (
        <AccountMemberAddCoachContextProvider types={types}>
            <AccountMemberAddCoachContext.Consumer>
                {({ isLoading, steps, activeStep, formData }) => (
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={toggle}>{steps[activeStep].title}</ModalHeader>
                        {/* {(activeStep !== 0) && */}
                        <Collapse isOpen={!!formData.official?.member_id && activeStep !== 0}>
                            <ModalBody className="pb-0">
                                <OfficialInfo official={formData.official} />
                            </ModalBody>
                        </Collapse>
                        {steps.map((step, index) => {
                            const View = step.component;

                            return (
                                <CrossFade key={step.id} isVisible={index === activeStep && formData.viewMode == "steps"}>
                                    <View member={member} toggle={toggle} />
                                </CrossFade>
                            )
                        })}
                    </OverlayLoader>
                )}
            </AccountMemberAddCoachContext.Consumer>
        </AccountMemberAddCoachContextProvider>
    )
}

export default AccountMemberAddCoachReport;