import Translate from "@spordle/intl-elements";
import MemberAlertsIcon from "../../../../components/memberBadges/MemberAlertsIcon";
import MissingFieldsModal from './missingFields/MissingFieldsModal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const MemberStatusBadges = ({ currentMember, refreshMetaMember }) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const history = useHistory();

    return (
        <>
            <MissingFieldsModal
                isOpen={modalIsOpen}
                metaMember={currentMember}
                toggle={() => setModalIsOpen(!modalIsOpen)}
                refreshMetaMember={refreshMetaMember}
            />
            <div className="d-flex">
                {/* MISSING DOCUMENT ADDRESS */}
                {
                    currentMember?.members[0]?.need_address_document == 1 &&
                        <div className={`text-center text-md-left mb-4 mr-2`}>
                            <div
                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                onClick={() => {
                                    history.push(`#documents-open`)
                                }}
                            >
                                <MemberAlertsIcon icon="documents" />
                                <span className="align-middle ml-2"><Translate id="account.members.missingAddressDocument.tooltip" /></span>
                            </div>
                        </div>
                }

                {/* missing required fields */}
                {
                    currentMember?.members[0]?.missing_required_fields?.length > 0 && // if missing required fields
                        <div className={`text-center text-md-left mb-4 mr-2`}>
                            <div
                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                onClick={() => {
                                    setModalIsOpen(true)
                                }}
                            >
                                <MemberAlertsIcon icon="fields" />
                                <span className="align-middle ml-2"><Translate id='account.members.missingFields.tooltip' /></span>
                            </div>
                        </div>
                }

                {/* unsigned waivers */}
                {
                    currentMember?.members[0]?.unsigned_critical_waivers == 1 && // if unsigned critical waivers
                        <div className={`text-center text-md-left mb-4 mr-2`}>
                            <div
                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                onClick={() => {
                                    history.push(`#waivers-open`)
                                }}
                            >
                                <MemberAlertsIcon icon="waivers" />
                                <span className="align-middle ml-2"><Translate id='account.members.unsignedCriticalWaiver.tooltip' /></span>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

export default MemberStatusBadges