import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedPlural } from 'react-intl';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { Card, Collapse } from 'reactstrap';

import { I18nContext } from '../../../../../../contexts/I18nContext';
import { useHistory } from 'react-router-dom';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { stringBuilder } from '@spordle/helpers';

const TransactionPaymentReceptions = ({ paymentReceptions, invoiceNumber }) => {

    const i18nContext = useContext(I18nContext);

    const sortPaymentReception = (paymentA, paymentB) => {
        return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(paymentA.received_at, paymentB.received_at) ||
               new Intl.Collator(i18nContext.getGenericLocale(), { numeric: true }).compare(paymentA.amount, paymentB.amount)
    }

    return (
        (paymentReceptions && paymentReceptions.length > 0) &&
            <div className='mb-3'>
                <div className="h4 font-bold">
                    <FormattedPlural
                        value={paymentReceptions.length}
                        one={<Translate id='account.transactions.sidePanel.items.paymentReception' />}
                        other={<Translate id='account.transactions.sidePanel.items.paymentReceptions' />}
                    />
                </div>
                <Card className='card-shadow mb-3 p-3'>
                    {paymentReceptions.sort(sortPaymentReception).map((paymentReception) => (
                        <PaymentReceptionItem
                            key={paymentReception.payment_reception_id}
                            paymentReception={paymentReception}
                            invoiceNumber={invoiceNumber}
                        />
                    ))}
                    <div className='text-right pr-2'>
                        <span className='font-bold text-dark'>
                            <Translate id='account.transactions.total' />
                            <span className='mx-1'>:</span>
                        </span>
                        <CurrencyFormat value={paymentReceptions.reduce((totalAmount, payment) => totalAmount + parseInt(payment.amount), 0) / 100} />
                    </div>
                    {/* <Table size="sm" responsive className="mb-0">
                        <thead>
                            <tr>
                                <td className='td-shrink border-top-0'><Translate id='account.transactions.sidePanel.items.date' /></td>
                                <td className='text-right border-top-0'><Translate id='account.transactions.sidePanel.items.amount' /></td>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="text-right w-100">
                                    <div className="font-bold"><Translate id='account.transactions.total' /> : </div>
                                </td>
                                <td className="text-right">
                                    <div className="text-nowrap"><CurrencyFormat value={paymentReceptions.reduce((totalAmount, payment) => totalAmount + parseInt(payment.amount), 0) / 100} /></div>
                                </td>
                            </tr>
                        </tfoot>
                    </Table> */}
                </Card>
            </div>

    );
}

// const PaymentReceptionItem = ({ paymentReception }) => {
//     const [ noteIsOpen, setNoteOpen ] = useState(false);
//     return (
//         <>
//             <tr onClick={paymentReception.note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={paymentReception.note ? 'clickable' : undefined}>
//                 <td className='td-shrink'>
//                     {paymentReception.note &&
//                         <span className='position-absolute left-0'>
//                             <i className='font-20 mdi mdi-chevron-down d-inline-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '0' : '-90'}deg)` }} />
//                         </span>
//                     }
//                     <DateFormat value={paymentReception.received_at} utc />
//                     <div className='small'>
//                         <Translate id={`account.transactions.memberProfileTransactions.paymentMethods.${paymentReception.payment_method?.code || 'MEMBER_CREDIT'}`} />
//                     </div>
//                 </td>
//                 <td className='align-middle text-right'>
//                     <CurrencyFormat value={paymentReception.amount / 100} />
//                 </td>
//             </tr>
//             {paymentReception.note &&
//                 <tr>
//                     <td colSpan='2' className='border-0 p-0'>
//                         <Collapse isOpen={noteIsOpen}>
//                             <div className='mx-2 mb-2'>
//                                 {paymentReception.note}
//                             </div>
//                         </Collapse>
//                     </td>
//                 </tr>
//             }
//         </>
//     )
// }

const PaymentReceptionItem = ({ paymentReception, invoiceNumber }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const history = useHistory();

    return (
        <Card className='border p-2 mb-2 card-shadow'>
            <div onClick={paymentReception.note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!paymentReception.note })}>
                <div className='d-flex justify-content-between'>
                    <DateFormat value={paymentReception.received_at} utc />
                    <button type='button' className='reset-btn text-link' onClick={() => history.push('/purchases/receipt?invoiceNumber=' + invoiceNumber + '&paymentReceptionId=' + paymentReception.payment_reception_id)}>
                        <i className='mdi mdi-printer font-18' />
                    </button>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        <DisplayI18n field='name' defaultValue={paymentReception.payment_method?.name} i18n={paymentReception.payment_method?.i18n} />
                    </div>
                    <CurrencyFormat value={paymentReception.amount / 100} />
                </div>
                {paymentReception.note &&
                    <>
                        <Collapse isOpen={noteIsOpen}>
                            <div className='bg-light p-1 rounded'>
                                {paymentReception.note}
                            </div>
                        </Collapse>
                        <div className='d-flex justify-content-center mb-n3 mt-n2'>
                            <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                        </div>
                    </>
                }
            </div>
        </Card>
    )
}

TransactionPaymentReceptions.propTypes = {
    paymentReceptions: PropTypes.array.isRequired,
};

export default TransactionPaymentReceptions;