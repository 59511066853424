import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
// Formik
import { FieldArray, Form, Formik } from "formik";
import { useContext } from 'react';
import { Button, Card, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, bool, object, string } from 'yup';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CustomAlert from '../../../../components/CustomAlert';
import Required from "../../../../components/formik/Required";
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../helpers/i18nHelper";

const AddContactModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='AddContactModal'>
            <AddContactInner {...props} />
        </AnalyticsModal>
    )
}

const AddContactInner = ({ toggle, refreshTable, member }) => {
    const isRequired = (value, emergency) => (emergency || !value);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id='account.members.contacts.addModal.title' />
            </ModalHeader>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    active: true,
                    emergency: false,
                    contactTypeId: '',
                    phones: [ {
                        phoneNumber: '',
                        phoneExtension: '',
                        phoneTypeId: '',
                    } ],
                    email: '',
                }}
                validationSchema={object().shape({
                    firstName: string().required(<Translate id='form.validation.firstName.required' />),
                    lastName: string().required(<Translate id='form.validation.lastName.required' />),
                    active: bool().required(),
                    emergency: bool().required(),
                    contactTypeId: string().required(<Translate id='account.members.contacts.addModal.contactType.required' />),
                    phones: array().of(object().shape({
                        phoneNumber: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).test({
                            name: 'email-phone-required',
                            message: <Translate id='form.validation.phone.required' />,
                            test: function(value){
                                if(this.path === 'phones[0].phoneNumber'){ // index 0
                                    // If is an emergency contact, email and phone is mandatory
                                    // If the contact isn't, the contact need to have either an email OR a phone number
                                    const base = this.from[this.from.length - 1]?.value;
                                    return base?.emergency ? !!value : (!!base?.email || !!value)
                                }
                                return !!value

                            },
                        }),
                        phoneExtension: string(),
                        phoneTypeId: string().test({
                            name: 'email-phone-id-required',
                            message: <Translate id='form.validation.phoneType.required' />,
                            test: function(value){
                                if(this.parent.phoneNumber)return !!value;

                                if(this.path === 'phones[0].phoneTypeId'){ // index 0
                                    const base = this.from[this.from.length - 1]?.value;
                                    // Phone is required if emergency
                                    // Else check if there is an email, if there isn't, check if this has a value
                                    return base?.emergency ? !!value : (!!base?.email || !!value);
                                }
                                return !!value

                            },
                        }),
                    })),
                    email: string().email(<Translate id='form.validation.email.valid' />)
                        .test({
                            name: 'isRequired',
                            message: <Translate id='form.validation.email.required' />,
                            test: function(email){
                                return !!this.parent.phones[0].phoneNumber || !!email;
                            },
                        }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const newValues = {
                        first_name: values.firstName,
                        last_name: values.lastName,
                        member_contact_type_id: values.contactTypeId,
                        email: values.email,
                        emergency: values.emergency,
                        phones: values.phones.reduce((newArray, phone) => {
                            if(phone.phoneNumber){
                                newArray.push({
                                    phone_type_id: phone.phoneTypeId,
                                    phone_number: phone.phoneExtension ? `${phone.phoneNumber}#${phone.phoneExtension}` : phone.phoneNumber,
                                })
                            }
                            return newArray
                        }, []),
                    }

                    membersContext.createMemberContacts(member.member_id, { contacts: [ newValues ] })
                        .then(() => {
                            refreshTable();
                            setSubmitting(false)
                            toggle();
                            success({ msg: 'account.members.contacts.addModal.toast.success' });
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false)
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form className='position-relative'>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='contactTypeId' className="text-muted">
                                        <Translate id='account.members.contacts.addModal.contactType' /> <Required />
                                    </Label>
                                    <FormikSelect
                                        id='contactTypeId'
                                        name='contactTypeId'
                                        renderOption={({ option }) => (
                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                        )}
                                        searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return membersContext.getMemberContactTypes({ organisation_id: member.organisation?.organisation_id })
                                                        .then((contactTypes) => contactTypes.filter((c) => c.active == 1).map((contactType) => ({
                                                            value: contactType.member_contact_type_id,
                                                            label: contactType.name,
                                                            i18n: contactType.i18n,
                                                        })))
                                            }
                                        }}
                                    />
                                </FormGroup>

                                <Row className="mb-3">
                                    <Col md='6'>
                                        <Label for='firstName' className="text-muted"><Translate id='form.fields.firstName' /> <Required /></Label>
                                        <FormikInputText id='firstName' name='firstName' trim />
                                    </Col>
                                    <Col md='6'>
                                        <Label for='lastName' className="text-muted"><Translate id='form.fields.lastName' /> <Required /></Label>
                                        <FormikInputText id='lastName' name='lastName' trim />
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for='email' className="text-muted"><Translate id='form.fields.email' /> {!formik.values.phones[0].phoneNumber && !formik.values.emergency && <Required />}</Label>
                                    <FormikInputText id='email' name='email' trim />
                                </FormGroup>
                                <Collapse isOpen>
                                    <div className="pb-1">
                                        <FieldArray name='phones'>
                                            {(arrayHelpers) => (
                                                <div className='mb-3'>
                                                    {formik.values.phones.map((phone, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <Collapse appear isOpen key={index}>
                                                            <Card body className='card-shadow mb-3'>
                                                                {index > 0 &&
                                                                    <div className="position-absolute top-0 right-0 z-index-1">
                                                                        <button type="button" onClick={() => arrayHelpers.remove(index)} className="btn btn-link text-danger fas fa-trash" />
                                                                    </div>
                                                                }
                                                                <FormGroup>
                                                                    <Label for={`phones.${index}.phoneNumber`} className='text-muted'><Translate id='form.fields.phone' /> {((isRequired(formik.values.email, formik.values.emergency) && index === 0) || index !== 0) && <Required />}</Label>
                                                                    <FormikPhoneInput name={`phones.${index}.phoneNumber`} />
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col md='6'>
                                                                        <Label for={`phones.${index}.phoneExtension`} className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                                        <FormikInputNumber id={`phones.${index}.phoneExtension`} name={`phones.${index}.phoneExtension`} allowNegative={false} decimalSeparator={false} />
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <Label for={`phones.${index}.phoneTypeId`} className='text-muted'><Translate id='form.fields.phoneType' /> {((isRequired(formik.values.email, formik.values.emergency) && index === 0) || index !== 0) && <Required />}</Label>
                                                                        <FormikSelect
                                                                            id={`phones.${index}.phoneTypeId`}
                                                                            name={`phones.${index}.phoneTypeId`}
                                                                            renderOption={({ option }) => (
                                                                                <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                                            )}
                                                                            searchKeys={[
                                                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                                            ]}
                                                                            loadData={(from) => {
                                                                                switch (from){
                                                                                    case 'CDM':
                                                                                        return membersContext.getPhoneTypes({ organisation_id: member.organisation?.organisation_id })
                                                                                            .then((phoneTypes) => phoneTypes.filter((p) => p.active == 1).map((phoneType) => ({
                                                                                                value: phoneType.phone_type_id,
                                                                                                label: phoneType.name,
                                                                                                i18n: phoneType.i18n,
                                                                                            })))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Collapse>
                                                    ))}
                                                    <button className='reset-btn text-primary' type='button' onClick={() => arrayHelpers.push({ phoneNumber: '', phoneExtension: '', phoneTypeId: '', phoneType: null })}><i className='mdi mdi-plus' /> <Translate id='account.members.contacts.addModal.addPhone' /></button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                </Collapse>

                                <FormGroup>
                                    <FormikCheckedButton id='emergency' name='emergency' label='account.members.contacts.addModal.emergency' translateLabel checked={formik.values.emergency} />
                                </FormGroup>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert color='danger' text={formik.status} translateText={false} withTitle toggle={() => formik.setStatus(null)} />
                                    }
                                </Collapse>
                            </ModalBody>
                        </OverlayLoader>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                            <Button className="ml-2" color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddContactModal;