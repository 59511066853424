export default{
    "misc.add"                                  : "Add",
    "misc.required"                             : "Required",
    "misc.all"                                  : "All",
    "misc.none"                                 : "None",
    "misc.other"                                : "Other",
    "misc.after"                                : "After",
    "misc.back"                                 : "Back",
    "misc.before"                               : "Before",
    "misc.birthdate.format"                     : "YYYY-MM-DD",
    "misc.cancel"                               : "Cancel",
    "misc.clear"                                : "Clear",
    "misc.close"                                : "Close",
    "misc.results"                              : "Results",
    "misc.result"                               : "Result",
    "misc.confirm"                              : "Confirm",
    "misc.submit"                               : "Submit",
    "misc.create"                               : "Create",
    "misc.edit"                                 : "Edit",
    "misc.en"                                   : "English",
    "misc.english"                              : "English",
    "misc.error"                                : "An error occurred",
    "misc.female"                               : "Female",
    "misc.fr"                                   : "French",
    "misc.show"                                 : "Show",
    "misc.french"                               : "French",
    "misc.gender"                               : "Gender Identity",
    "misc.googlePlaces.open"                    : "Open in Google Maps",
    "misc.googlePlaces.placeholder"             : "Indicate a location",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Enter address manually",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indicate address with Google helper",
    "misc.lang.en"                              : "EN",
    "misc.lang.fr"                              : "FR",
    "misc.lang.long.en"                         : "English",
    "misc.lang.long.fr"                         : "French",
    "misc.lessFilter"                           : "Hide filters",
    "misc.link"                                 : "Link",
    "misc.loading"                              : "Loading...",
    "misc.male"                                 : "Male",
    "misc.more"                                 : "More",
    "misc.moreFilter"                           : "Show filters",
    "misc.viewMore"                             : "View more",
    "misc.viewCountMore"                        : "View {count} more",
    "misc.viewLess"                             : "View less",
    "misc.next"                                 : "Next",
    "misc.yes"                                  : "Yes",
    "misc.no"                                   : "No",
    "misc.nonBinary"                            : "Non Binary",
    "misc.nonSpecified"                         : "Prefer not to say",
    "misc.preferToDescribe"                     : "Prefer to self-describe",
    "misc.noResults"                            : "No result",
    "misc.noSelection"                          : "No selection possible",
    "misc.noTranslation"                        : "No translation available",
    "misc.ok"                                   : "Ok",
    "misc.optional"                             : "Optional",
    "misc.mandatory"                            : "Mandatory",
    "misc.or"                                   : "Or",
    "misc.previous"                             : "Previous",
    "misc.price"                                : "Price",
    "misc.priceValue"                           : "$ {price}",
    "misc.remove"                               : "Remove",
    "misc.status"                               : "Status",
    "misc.save"                                 : "Save",
    "misc.search"                               : "Search",
    "misc.searchResults"                        : "Search Result(s)",
    "misc.select.placeholder"                   : "Select...",
    "misc.select"                               : "Select",
    "misc.success"                              : "Success",
    "misc.warning"                              : "Warning",
    "misc.danger"                               : "Error",
    "misc.info"                                 : "Info",
    "misc.select.suggested"                     : "Suggested",
    "misc.SimpleSearchTable.search.placeholder" : "Search",
    "misc.gotIt"                                : "Got it",
    "misc.update"                               : "Update",
    "misc.yrs"                                  : "yrs.",
    "misc.export"                               : "Export",
    "misc.excel"                                : "Excel",
    "misc.pdf"                                  : "PDF",
    "misc.print"                                : "Print",
    "misc.actions"                              : "Actions",
    "misc.action"                               : "Action",
    "misc.delete"                               : "Delete",
    "misc.active"                               : "Active",
    "misc.inactive"                             : "Inactive",
    "misc.fatalError.goback"                    : "Return to the platform",
    "misc.download"                             : "Download",
    "misc.between"                              : "between",
    "misc.and"                                  : "and",
    "misc.new"                                  : "New",

    "misc.notSupported.title"  : "Browser not supported",
    "misc.notSupported.text.1" : "The version of this browser is not supported by our platform.",
    "misc.notSupported.text.2" : "Please check for updates or try another browser",

    "misc.copy.1" : "Copy",
    "misc.copy.2" : "Copied!",
    "misc.copy.3" : "Unable to copy",

    "misc.daysAgo"  : "{days, plural, =0 {# day} one {# day} other {# days}} ago",
    "misc.hoursAgo" : "{hours, plural, =0 {# hour} one {# hour} other {# hours}} ago",
    "misc.yearsOld" : "{age} years old",

    // Clipboard helper
    "misc.clipboard.success"         : "Copied to clipboard!",
    "misc.clipboard.address.success" : "Address copied!",
    "misc.clipboard.copy"            : "Copy",
    "misc.clipboard.error"           : "Could not copy to clipboard",
    "misc.addressClipboard.success"  : "Address copied to clipboard!",
    "misc.addressClipboard.error"    : "Could not copy address.",

    // file upload
    "misc.fileUpload.text.1"  : "Add document",
    "misc.fileUpload.text.2"  : "or drop it here",
    "misc.fileUpload.maxSize" : "Maximum upload file size",

    // Search
    "misc.search.empty.title"         : "No results...",
    "misc.search.empty.message"       : "You can search or use the advanced search at the top of this page.",
    "misc.search.empty.message.short" : "You can use the search at the top of this page.",

    // i18nReview
    "misc.i18nReview.correctionLogged"   : "Correction has been logged!",
    "misc.i18nReview.correctionAccepted" : "Correction has been accepted",
}