import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Footer from '../footer/footer';

// Language
import Translate from '@spordle/intl-elements';

// Context Providers
import { AuthContext } from '../../../contexts/contexts';

import sidebarRoutes from '../../../routes/accountRoutes/sidebarRoutes';
import MainPhoto from '../../../components/MainPhoto';
import { stringBuilder } from "@spordle/helpers";
import ReactTooltip from 'react-tooltip';

export default class Sidebar extends React.Component{
    constructor(props){
        super(props);
        this.expandLogo = this.expandLogo.bind(this);
        this.activeRoute.bind(this);
        this.state = {
            // userCollapse: false,
        };
    }

    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    expandLogo(){
        document.getElementById("logobg").classList.toggle("expand-logo");
    }

    // toggleUserCollapse = () => {
    // 	this.setState(state => ({ userCollapse: !state.userCollapse }));
    // }
    /*--------------------------------------------------------------------------------*/
    /*Verifies if routeName is the one active (in browser input)                      */
    /*--------------------------------------------------------------------------------*/
    activeRoute(routeName, childPath, exact){ return ((exact && this.props.location.pathname === routeName) || (!exact && this.props.location.pathname.indexOf(routeName) > -1 || this.props.location.pathname.indexOf(childPath) > -1)) ? 'selected' : ''; }

    innerLink = (sidebarItem) => {
        const Inside = () => {
            return (
                <>
                    <i className={sidebarItem.icon} />
                    <span className={"hide-menu"}>{(sidebarItem.skipTranslate && sidebarItem.skipTranslate === true) ? sidebarItem.name : <Translate id={sidebarItem.name} />}</span>
                </>
            )
        }

        return (
            sidebarItem.powerUserOnly ?
                <span data-for={"tooltip-sidebar-" + sidebarItem.path} data-tip="Power User Only" className={stringBuilder({ "text-purple": sidebarItem.powerUserOnly })}>
                    <Inside />
                    <ReactTooltip id={"tooltip-sidebar-" + sidebarItem.path} />
                </span>
                :
                <Inside />
        )
    }

    render(){
        return (
            <aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.themeValues.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
                <div className="scroll-sidebar">
                    <PerfectScrollbar className="sidebar-nav">
                        <AuthContext.Consumer>
                            {({ accessToken, userData, isPowerUser }) => {
                                const user = userData?.UserAttributes;
                                return (
                                    <ul id="sidebarnav" className="pt-3">
                                        {/*--------------------------------------------------------------------------------*/}
                                        {/* Sidebar User Menu                                                */}
                                        {/*--------------------------------------------------------------------------------*/}
                                        {(accessToken && userData) ?
                                            <>
                                                <li className="sidebar-item sidebar-item-header">
                                                    <Link to={'/members'} className="sidebar-link">
                                                        <MainPhoto className="mr-2" />
                                                        {/* <img src={user.logo} alt={user.name+' '+user.family_name + ' logo'} /> */}
                                                        <span className="hide-menu">{user.name + ' ' + user.family_name}</span>
                                                    </Link>
                                                </li>
                                                <li className="sidebar-separator" />
                                            </>
                                            : null}

                                        {/*--------------------------------------------------------------------------------*/}
                                        {/* Sidebar Menus will go here                                                */}
                                        {/*--------------------------------------------------------------------------------*/}

                                        {sidebarRoutes.map((sidebarItem, key) => {
                                            if((sidebarItem.redirect) || (sidebarItem.powerUserOnly && !isPowerUser())){
                                                return null;
                                            }else if(sidebarItem.navlabel){
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <li className="nav-small-cap" key={key}>
                                                        <i className={sidebarItem.icon} />
                                                        <span className="hide-menu">{(sidebarItem.skipTranslate && sidebarItem.skipTranslate === true) ? sidebarItem.name : <Translate id={sidebarItem.name} />}</span>
                                                    </li>
                                                );
                                            }else if(sidebarItem.divider){
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <li className="sidebar-separator" key={key} />
                                                );
                                            }

                                            return (
                                            /*--------------------------------------------------------------------------------*/
                                            /* Sidebar Item                                                            */
                                            /*--------------------------------------------------------------------------------*/
                                                <li className={this.activeRoute(sidebarItem.path, sidebarItem.childPath, sidebarItem.exact) + (sidebarItem.pro ? ' active active-pro' : '') + ' sidebar-item'} key={sidebarItem.path}>
                                                    {sidebarItem.customNavLink ?
                                                        <sidebarItem.customNavLink icon={sidebarItem.icon} to={sidebarItem.path} className="sidebar-link" activeClassName="active" exact={sidebarItem.exact}>
                                                            {sidebarItem.powerUserOnly ?
                                                                <span data-for={"tooltip-sidebar-" + sidebarItem.path} data-tip="Power User Only" className={stringBuilder({ "text-purple": sidebarItem.powerUserOnly })}>
                                                                    <span className="hide-menu">{(sidebarItem.skipTranslate && sidebarItem.skipTranslate === true) ? sidebarItem.name : <Translate id={sidebarItem.name} />}</span>
                                                                    <ReactTooltip id={"tooltip-sidebar-" + sidebarItem.path} />
                                                                </span>
                                                                :
                                                                <span className="hide-menu">{(sidebarItem.skipTranslate && sidebarItem.skipTranslate === true) ? sidebarItem.name : <Translate id={sidebarItem.name} />}</span>
                                                            }
                                                        </sidebarItem.customNavLink>
                                                        :
                                                        <NavLink to={sidebarItem.path} className="sidebar-link" activeClassName="active" exact={sidebarItem.exact}>
                                                            {this.innerLink(sidebarItem)}
                                                        </NavLink>
                                                    }
                                                </li>
                                            );


                                        })}
                                    </ul>
                                )
                            }}
                        </AuthContext.Consumer>

                        <Footer />
                    </PerfectScrollbar>
                </div>
            </aside>
        );
    }
}
