import { Component, createContext } from 'react';

export const ThemeContext = createContext();
ThemeContext.displayName = 'ThemeContext';

class ThemeContextProvider extends Component{
    state = {
        theme: '',
    }

    _getTheme = (platform) => {
        switch (platform){
            case 'HCR':
                return 'theme-id';
            case 'TOUR':
                return 'theme-tournaments';
            default:
                return '';
        }
    }

    setTheme = (theme) => {
        this.setState((prevTheme) => {
            const newTheme = this._getTheme(theme);

            // Prevents rerendering if the theme doesn't change
            if(prevTheme.theme === newTheme)
                return null;

            return { theme: newTheme };
        });
    }

    getTheme = () => this.state.theme;

    render(){
        return (
            <ThemeContext.Provider value={{
                ...this.state,
                setTheme: this.setTheme,
                getTheme: this.getTheme,
            }}
            >
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}
export default ThemeContextProvider;