import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { NavLink } from "react-router-dom"
import ReactTooltip from "react-tooltip";
import { AccountsStatusContext } from "../../../contexts/AccountsStatusContext";

const PaymentMethodNavLink = ({ icon, children, className, ...props }) => {
    const { declinedPayments, preInits } = useContext(AccountsStatusContext);

    const hasDeclinedPayments = declinedPayments?.length > 0;
    const hasPreInits = preInits?.length > 0;

    function getNavLinkProps(){
        const linkProps = {
            className: stringBuilder(className, "payment-method-nav-link"),
            ...props,
        }

        if(hasDeclinedPayments || hasPreInits){
            linkProps['data-tip'] = 'missingPayment.tooltip';
            linkProps['data-for'] = 'missing_payment_tip';
        }

        return linkProps;
    }

    return (
        <NavLink {...getNavLinkProps()}>
            <i className={icon + " flex-shrink-0"} />
            {
                hasDeclinedPayments || hasPreInits ?
                    <>
                        <span style={{ fontSize: 14 }} className="payment-method-nav-link-icon mini-sidebar mdi mdi-alert-circle-outline" />
                        <span className="d-block text-truncate">
                            {children}
                        </span>
                        <i style={{ fontSize: 14 }} className="mdi mdi-alert-circle-outline ml-auto mb-auto" />
                        <ReactTooltip id='missing_payment_tip' getContent={(tip) => tip && <Translate id={tip} />} place='right' effect='solid' />
                    </>
                    :
                    children
            }
        </NavLink>
    )
}

export default PaymentMethodNavLink;