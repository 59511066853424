/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {PureComponent}from'react';import ReactToPrint,{PrintContextConsumer}from'react-to-print';import html2canvas from'html2canvas';import jsPDF from'jspdf';/**
 * @description This class prints a section of a page with the help of a button. The Ctrl + P shortcut is also handled
 * @prop {Function|Ref} contentRef Content to print
 * @prop {Function} children The button used for printing
 * @example
 * <ToPrint contentRef={printContainer.current}>
 *     {handlePrint => (
 *         <Button onClick={handlePrint} color="primary" className={classnames("ml-2", {'d-none': isPrinting})}><Translate id='misc.print'/></Button>
 *     )}
 * </ToPrint>
 * @access default
 * @see https://www.npmjs.com/package/react-to-print
 * @copyright Spordle
 */
class ToPrint extends PureComponent {
    static propTypes = {
        contentRef: PropTypes.element.isRequired,
        children: PropTypes.func.isRequired,
    };
    render() {
        return (jsx(ReactToPrint, { ...this.props, content: () => this.props.contentRef, children: jsx(PrintContextConsumer, { children: ({ handlePrint }) => this.props.children(handlePrint) }) }));
    }
}/**
 * @description This class generates a PDF file with a section of the page as its content and gets downloaded
 * @prop {string} fileName Name of the downloadable PDF file
 * @prop {Func|Ref} pdfContent Section of the page that needs to be added to the PDF file
 * @prop {Func} children A function that receives another function by parameter. The received function must be called in the onClick event of a button
 * @example
 * <ToPDF fileName={"invoice.pdf"}>
 *     { pdfOnClick => (
 *         <Button color="link" onClick={pdfOnClick} className="text-dark"><i className="fas fa-file-pdf"></i></Button>
 *     )}
 * </ToPDF>
 * @see https://medium.com/@shivekkhurana/how-to-create-pdfs-from-react-components-client-side-solution-7f506d9dfa6d Tutorial step-by-step
 * @see http://html2canvas.hertzen.com/ html2canvas documentation
 * @see https://github.com/MrRio/jsPDF jsPDF documentation
 * @copyright Spordle
 */
class ToPDF extends PureComponent {
    static propTypes = {
        fileName: PropTypes.string.isRequired,
        pdfContent: PropTypes.element.isRequired,
        children: PropTypes.func.isRequired,
    };
    generatePDF = () => {
        return html2canvas(this.props.pdfContent)
            .then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1);
            const pdf = new jsPDF("p", "mm");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imageWidth = canvas.width;
            const imageHeight = canvas.height;
            const ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight;
            pdf.addImage(imgData, 'PNG', 0, 0, ratio === pageWidth / imageWidth ? imageWidth * ratio : pageWidth, imageHeight * ratio);
            pdf.save(this.props.fileName);
        });
    };
    render() {
        return this.props.children(this.generatePDF);
    }
}export{ToPDF,ToPrint,ToPrint as default};
/*
@Copyrights Spordle 2022 - All rights reserved
*/