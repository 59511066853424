import { addExtension, DisplayPhoneNumber, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useIntl } from "react-intl";
import { Label } from "reactstrap";
import { object, string } from "yup";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import { useTooltip } from "../../../../../../../../components/customHooks/useTooltip";
import FormikEditable from "../../../../../../../../components/formik/FormikEditable";
import CollapsibleCard from "../../../CollapsibleCard";
import { OFFICIAL_TYPES } from "../../../gameIncidentHelper";

const GameIncidentOfficialCard = ({ member, official, index, updateValues, handleDelete }) => {
    const { formatMessage } = useIntl();
    const tooltipProps = useTooltip('react-tooltip-gameincident', formatMessage({ id: 'misc.delete' }));

    return (
        <CollapsibleCard
            initOpen={index === 0}
            className="mb-3"
            title={official.member?.first_name + ' ' + official.member?.last_name}
            subtitle={'#' + official.member?.unique_identifier}
            deleteBtn={
                official.member.member_id === member.member_id || index === 0 ?
                    null
                    :
                    <ConfirmModal
                        modalMsg='account.members.gir.sidePanel.delete.official'
                        translateModalMsg
                        modalTitle={official.member?.first_name + ' ' + official.member?.last_name}
                        toggler={(fn) => (
                            <button
                                {...tooltipProps}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    fn();
                                }}
                                className="ml-auto p-3 m-n3 align-self-start reset-btn text-danger"
                                type="button"
                            >
                                <small><i className="fas fa-trash" /></small>
                            </button>
                        )}
                        onConfirm={handleDelete}
                    />
            }
        >
            <Label className="d-block mb-0 text-muted"><Translate id='account.members.gir.addModal.label.officialType' /></Label>
            <FormikEditable
                id="official_type"
                noConfirmation
                initialValues={{
                    official_type: official.official_type || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    official_type: string().required(<Translate id='account.members.gir.addModal.validation.officialType' />),
                })}
                onSubmit={(values) => {
                    if(values.official_type !== official.official_type){
                        updateValues(official.game_incident_official_id, values);
                    }
                }}
            >
                {(isEditing, editableOptions) => {
                    if(!isEditing){
                        return (
                            <div>
                                <Translate id={'account.members.gir.addModal.steps.officials.type.' + official.official_type} />
                            </div>
                        )
                    }
                    return (
                        <FormikSelect
                            id='official_type' name='official_type'
                            autoFocus menuIsDefaultOpen search={false}
                            options={OFFICIAL_TYPES?.map((type) => ({
                                label: 'account.members.gir.addModal.steps.officials.type.' + type,
                                value: type,
                            }))}
                            renderOption={(option) => <Translate id={option.option.label} />}
                            onOptionSelected={editableOptions.stopEditing}
                        />
                    )

                }}
            </FormikEditable>
            <Label className="d-block mt-2 mb-0 text-muted"><Translate id='form.fields.email' /></Label>
            <FormikEditable
                id="email"
                initialValues={{
                    email: official.email || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    email: string().email(<Translate id='form.validation.email.valid' />).test({
                        name: 'isRequired',
                        message: <Translate id='account.members.gir.addModal.validation.email' />,
                        test: function(email){
                            return official.member.member_id !== member.member_id || !!email
                        },
                    }),
                })}
                onSubmit={(values) => {
                    if(values.email !== official.email){
                        updateValues(official.game_incident_official_id, values);
                    }
                }}
            >
                {(isEditing, options) => {
                    if(!isEditing){
                        return (
                            <div>{official.email || '-'}</div>
                        )
                    }
                    return (
                        <FormikInputText trim name="email" />
                    )

                }}
            </FormikEditable>
            <Label className="d-block mt-2 mb-0 text-muted"><Translate id='form.fields.phone' /></Label>
            <FormikEditable
                id="phone"
                initialValues={{
                    phone_number: official.phone_number?.split('#')?.[0] || '',
                    phoneExt: official.phone_number?.split('#')?.[1] || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    phone_number: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                        .test({
                            name: 'isRequired',
                            message: <Translate id='account.members.gir.addModal.validation.phone' />,
                            test: function(phone){
                                return official.member.member_id !== member.member_id || !!phone
                            },
                        }),
                    phoneExt: string(),
                })}
                onSubmit={async(values) => {
                    const phone = await addExtension(values.phone_number, values.phoneExt);
                    if(phone !== official.phone_number){
                        updateValues(official.game_incident_official_id, { phone_number: phone || "" });
                    }
                }}
            >
                {(isEditing, _options) => {
                    if(!isEditing){
                        return (
                            <DisplayPhoneNumber
                                phoneString={official.phone_number}
                                format='INTERNATIONAL'
                                emptyValue='-'
                            />
                        )
                    }
                    return (
                        <>
                            <div className="d-flex">
                                {/* PHONE NUMBER */}
                                <div>
                                    <FormikPhoneInput
                                        name="phone_number"
                                        id='phone_number'
                                    />
                                </div>
                                <FormikInputNumber
                                    style={{
                                        flexBasis: '33%',
                                    }}
                                    prefix="#"
                                    placeholder="Ext"
                                    translatePlaceholder={false}
                                    name='phoneExt'
                                />
                            </div>
                        </>
                    )

                }}
            </FormikEditable>
        </CollapsibleCard>
    );
}

export default GameIncidentOfficialCard;