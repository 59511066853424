import { Component } from 'react';
import { Formik, Form } from 'formik';
import { FormGroup, Label, Fade, Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import axios from 'axios';
import { current_url } from '../../api/API';
import { FormikSelect, FormikInputText, FormikRadioButton, FormikTextArea, FormikGroup } from '@spordle/formik-elements';
import { getLocalStorageItem } from '../../helpers/browserStorage';

export default class ApiTester extends Component{
    getBaseUrl = (selectedAPI) => {
        switch (selectedAPI){
            case 'ACCESS':
                return current_url;
            default:
                return '';
        }
    }

    getAPI = (baseUrl, encoding, selectedAPI, headers) => {
        headers['Content-Type'] = encoding !== 'x-www-form-urlencoded' ? 'application/json' : 'application/x-www-form-urlencoded';

        switch (selectedAPI){
            case 'ACCESS':
                return axios.create({
                    baseURL: baseUrl,
                    headers: headers,
                    validateStatus: (status) => true,
                });
            default:
                return null; // Should never come here
        }
    }

    render(){
        return (
            <div className="text-center mt-5 mb-3 w-75 m-auto">
                <Fade>
                    <Formik
                        initialValues={{
                            api: 'ACCESS',
                            method_Type: 'GET',
                            base_URL: this.getBaseUrl('ACCESS'),
                            end_point: '',
                            encoding: 'x-www-form-urlencoded',
                            headers: `{\n\t"token": "${getLocalStorageItem('token')}"\n}`,
                            body: '{\n\t"email": "some.email@gmail.com"\n}',
                        }}
                        validationSchema={Yup.object().shape({
                            base_URL: Yup.string().required('Required'),
                            end_point: Yup.string().required('Required'),
                            encoding: Yup.string().required('Required'),
                            method_Type: Yup.string().required('Required'),
                            headers: Yup.string().required('Required').test('test-headers', 'This must match a JSON format', (value) => {
                                try{
                                    JSON.parse(value);
                                }catch(error){
                                    return false;
                                }
                                return true;
                            }),
                            body: Yup.string().required('Required').test('test-body', 'This must match a JSON format', (value) => {
                                try{
                                    JSON.parse(value);
                                }catch(error){
                                    return false;
                                }
                                return true;
                            }),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            const stringifySpacing = 4;

                            const testApi = this.getAPI(values.base_URL, values.encoding, values.api, JSON.parse(values.headers));

                            const data = JSON.parse(values.body);

                            switch (values.method_Type){
                                case 'GET':
                                    testApi.get(values.end_point, data)
                                        .then((response) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(response.data, null, stringifySpacing);
                                        }).catch((error) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(error, null, stringifySpacing);
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                    break;
                                case 'POST':
                                    testApi.post(values.end_point, data)
                                        .then((response) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(response.data, null, stringifySpacing);
                                        }).catch((error) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(error, null, stringifySpacing);
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                    break;
                                case 'PATCH':
                                    testApi.patch(values.end_point, data)
                                        .then((response) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(response.data, null, stringifySpacing);
                                        }).catch((error) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(error, null, stringifySpacing);
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                    break;
                                case 'PUT':
                                    testApi.put(values.end_point, data)
                                        .then((response) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(response.data, null, stringifySpacing);
                                        }).catch((error) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(error, null, stringifySpacing);
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                    break;
                                case 'DELETE':
                                    testApi.delete(values.end_point, data)
                                        .then((response) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(response.data, null, stringifySpacing);
                                        }).catch((error) => {
                                            document.getElementById('api_response').innerHTML = JSON.stringify(error, null, stringifySpacing);
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                    break;
                                default:
                                    document.getElementById('api_response').innerHTML = "Erreur dans le code du front end...";
                                    setSubmitting(false);
                                    break;
                            }
                        }}
                    >
                        {(formik) => (
                            <Form className="text-center mt-5 mb-3">
                                <h2>Config</h2>
                                <FormGroup>
                                    <Row>
                                        <Col sm='6'>
                                            <FormikSelect
                                                name="api"
                                                id="api_select"
                                                label="Api"
                                                translateLabel={false}
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('base_URL', this.getBaseUrl(values[0]));
                                                }}
                                                defaultData={[
                                                    { label: 'ACCESS', value: 'ACCESS' },
                                                ]}
                                                loadingStatus="success"
                                            />
                                        </Col>
                                        <Col sm='6'>
                                            <FormikSelect
                                                name="method_Type"
                                                id="method_Type"
                                                label="Method type"
                                                translateLabel={false}
                                                defaultData={[
                                                    { label: 'Get', value: 'GET' },
                                                    { label: 'Post', value: 'POST' },
                                                    { label: 'Patch', value: 'PATCH' },
                                                    { label: 'Put', value: 'PUT' },
                                                    { label: 'Delete', value: 'DELETE' },
                                                ]}
                                                loadingStatus="success"
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col lg='8'>
                                            <FormikInputText label="Base URL" type="text" name="base_URL" id="base_URL" translateLabel={false} trim />
                                        </Col>
                                        <Col lg='4'>
                                            <FormikInputText label="End point" type="text" name="end_point" id="end_point" translateLabel={false} trim />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="encoding">Encoding</Label>
                                    <FormikGroup name="encoding">
                                        <Col mb='6'>
                                            <FormikRadioButton name="encoding" label="x-www-form-urlencoded" id="url_encoded" value="x-www-form-urlencoded" translateLabel={false} defaultChecked />
                                        </Col>
                                        <Col md='6'>
                                            <FormikRadioButton name="encoding" label="form-data" id="form_data_encoded" translateLabel={false} value="form-data" />
                                        </Col>
                                    </FormikGroup>
                                </FormGroup>
                                <div>
                                    <h2>Headers</h2>
                                    <FormGroup>
                                        <FormikTextArea id="headers" name="headers" rows='10' style={{ width: '100%' }} translateLabel={false} trim />
                                    </FormGroup>
                                </div>
                                <div>
                                    <h2>Body</h2>
                                    <FormGroup>
                                        <FormikTextArea id="body" name="body" rows='10' style={{ width: '100%' }} translateLabel={false} trim />
                                    </FormGroup>
                                </div>
                                <Button type='submit' block disabled={formik.isSubmitting}>Send request</Button>
                            </Form>
                        )}
                    </Formik>
                    <div>
                        <h2>Response</h2>
                        <pre id="api_response" style={{ textAlign: 'start' }}>API&apos;s response will appear here</pre>
                    </div>
                </Fade>
            </div>
        )
    }
}
