import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { useContext } from 'react';
import { classPrefix } from '../../../components/helpers';
import { ScheduleContext } from '../../../Schedule';

/**
 * @description top part of the full weekly schedule
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.shortDays
 * @param {moment} props.day
 * @returns {JSX.Element}
 */
const WeeklyHeaderDay = ({ className, shortDays, day, ...props }) => {
    const { selected } = useContext(ScheduleContext);

    return (
        <div className={stringBuilder(className, { 'is-selected': day.format('L') === selected?.selected?.format('L') })} {...props}>
            <h3 className={`${classPrefix}-weekly__header-day-name`}>
                { shortDays ?
                    moment.weekdaysMin(day)
                    :
                    <>
                        <span className="d-none d-xl-inline">{day.format('dddd')}</span>
                        <span className="d-inline d-xl-none">{moment.weekdaysMin(day)}</span>
                    </>
                }
            </h3>
            <time dateTime={day.format('YYYY-MM-DD')} className={`${classPrefix}-weekly__header-day-nb`}>{day.format('D')}</time>
        </div>
    )
}

export default WeeklyHeaderDay;