import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { ScheduleContext } from "../Schedule";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

/**
 * @param {('days'|'weeks'|'months'|'years')} timeUnit
 * @param {string} [className]
 * @param {boolean} [showTodayBtn]
 * @returns {JSX.Element}
 */
const Navigation = ({ timeUnit, className, showTodayBtn = true }) => {
    const { selected, setNewDate, today, navigateDate } = useContext(ScheduleContext);
    const [ datetimeIsOpen, setDatetimeIsOpen ] = useState(false);

    const toggleDatetime = (bool) => setDatetimeIsOpen(bool ?? !datetimeIsOpen);
    const changeSelectedDate = (momentObj) => {
        toggleDatetime(false);
        momentObj.isValid() && setNewDate(momentObj);
    }

    return (
        <div className={`schedule-datetime-container${className ? ' ' + className : ''}`}>
            <div className="d-flex align-items-center">
                <button type="button" className="btn btn-light border btn-circle ti-angle-left mr-2" onClick={() => navigateDate(timeUnit, true)} />
                <button type="button" className="btn btn-light border btn-circle ti-angle-right" onClick={() => navigateDate(timeUnit, false)} />
                <div className="text-capitalize">
                    <h2 className="small pl-2 mb-0">{selected.selected.format('dddd DD MMMM YYYY')}</h2>
                    <button type="button" className="btn pl-2" onClick={() => toggleDatetime()}>
                        <span className="h3 text-capitalize">
                            {selected.selected.format('MMMM YYYY')}
                            <i className="ml-2 fas fa-caret-down text-body text-sm" />
                        </span>
                    </button>
                </div>
                { showTodayBtn &&
                    <button type="button" className="btn btn-today btn-primary" onClick={() => setNewDate(today)}>
                        <Translate id='schedule.btn.today' />
                    </button>
                }
            </div>
            { datetimeIsOpen &&
                <Datetime
                    id="date-picker"
                    className="schedule-filters-datetime position-absolute"
                    input={false}
                    timeFormat={false}
                    onChange={changeSelectedDate}
                />
            }
        </div>
    )
}

export default Navigation;