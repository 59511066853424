/* eslint-disable react/no-unused-class-component-methods */
import { Component, createContext } from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { AuthContext, AccountsContext } from '../../contexts/contexts';
import getReferrer, { getMultisportApiUrl, getMultisportCode, isMultiSport, isSidMultiSport, MULTISPORT_SESSION_STORAGE_KEY } from '../../helpers/getReferer';
import queryString from 'query-string';
import withContexts from '../../helpers/withContexts';
import { I18nContext } from '../../contexts/I18nContext';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { spordleOAuthKey } from '../../app';
import { LOGINTYPE } from '../../helpers/constants';
import { addBreadcrumb, setContext, Severity } from '@sentry/react';
import { sendGAEvent } from '@spordle/ga4';
import { fail } from '@spordle/toasts';
import API_SPORDLE from '../../api/API-Spordle';
import API from '../../api/API';
import { setSessionStorageItem } from '../../helpers/browserStorage';

/** @type {React.Context<Omit<AuthenticatorProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & AuthenticatorProvider['state']>} */
export const AuthenticatorContext = createContext();
AuthenticatorContext.displayName = 'AuthenticatorContext';

class AuthenticatorProvider extends Component{
    constructor(props){
        super(props);

        this.state = {
            show: props.location.show ?? this.initState.show,
            email: props.location.email ?? this.initState.email,
            userName: props.location.userName ?? this.initState.userName,
            name: props.location.name ?? this.initState.name,
            session: props.location.session ?? this.initState.session,
            verifyEmail: props.location.verifyEmail ?? this.initState.verifyEmail,
            isLocked: props.location.isLocked ?? this.initState.isLocked,
            showForgotPassword: props.location.showForgotPassword ?? this.initState.showForgotPassword,
        }

        if(process.env.REACT_APP_VERSION_CLIENT !== 'EIHA' && isMultiSport()){
            if(getMultisportCode())
                setSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY, getMultisportCode());
            this.demoInterceptor = API_SPORDLE.interceptors.request.use((config) => {
                config.baseURL = getMultisportApiUrl();
                return config;
            });
            this.demo2Interceptor = API.interceptors.request.use((config) => {
                config.baseURL = getMultisportApiUrl();
                return config;
            });
        }
    }

    initState = {
        show: 'email', // Password, pin
        email: '',
        userName: '',
        name: '',
        session: '',
        verifyEmail: false,
        isLocked: false,
        showForgotPassword: true,
    }

    componentDidUpdate(){
        setContext('Login', {
            ...this.state,
            session: this.state.session ? 'SESSION_TOKEN_IS_SET' : null,
        });
    }

    componentWillUnmount(){
        API_SPORDLE.interceptors.request.eject(this.demoInterceptor);
        API.interceptors.request.eject(this.demo2Interceptor);
        setContext('Login', null);
    }

    /**
     * this.setState but asynchronous
     * @private
     */
    _setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    /**
     * Set up redirection to MyAccount
     * @param {Object} result The result from the login call
     * @private
     */
    _goToSpordle = (result) => {
        const parsedQuery = queryString.parse(this.props.location.search);
        let newPage = false;

        if(parsedQuery.referrer){ // Has referrer -> go to that referrer
            try{
                new URL(parsedQuery.referrer);// Is external link
                var redirectQuery = { ...parsedQuery };
                redirectQuery.accessToken = result.AuthenticationResult.AccessToken;
                redirectQuery.type = result.AuthenticationResult.TokenType;
                delete redirectQuery.referrer; // Not necessary to send to new url

                window.location.replace(queryString.stringifyUrl({
                    url: parsedQuery.referrer,
                    query: redirectQuery,
                }));
                newPage = true;
            }catch(_){
                // Is not external link ex: /purchases/123123
            }
        }

        if(!newPage){
            this.props.AuthContext.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, () => {
                this.props.AuthContext.getUserData()
                    .then(() => {
                        this.props.history.replace(parsedQuery.referrer || "/dashboard");
                    }).catch((error) => {
                        fail();
                        console.error(error);
                    });
            });
        }

        // This is needed for logic in _goToHCR and in AuthController
        throw new Error('Cancelled');
    }

    /**
     * Set up redirection to HCR
     * @param {Object} result The result from the login call
     * @private
     */
    _goToHCR = (result) => {
        const locationSearch = queryString.parse(this.props.location.search);
        var redirectQuery = { ...locationSearch };
        redirectQuery.accessToken = result.AuthenticationResult.AccessToken;
        redirectQuery.type = result.AuthenticationResult.TokenType;

        delete redirectQuery.referrer; // Not necessary to send to new url
        const refererParam = queryString.parse((locationSearch.referrer?.split('?')[1] ?? {}));

        const setUpInternal = () => {
            this.props.AuthContext.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, () => {
                this.props.history.replace(queryString.stringifyUrl({
                    url: '/' + refererParam.goto,
                    query: {
                        ...locationSearch,
                        referrer: refererParam.referrer ?? getReferrer('HCR'),
                    },
                }));
            });
        }

        this.props.AuthContext.getUserIdentityRole(this.state.userName)
            .then((identityRoles) => {
                if(refererParam.goto){
                    setUpInternal();
                }else{
                    if(identityRoles.length === 0){
                        throw new Error('Cannot preselect -> go to account')
                    }else if(identityRoles.length === 1){
                        redirectQuery.identityRoleId = identityRoles[0].identity_role_id;
                        redirectQuery.organizationId = identityRoles[0].organisation.organisation_id;
                    }else{ // Look for primary identity-role
                        const primaryIdentityRole = identityRoles.find((ir) => ir.is_primary === '1' && ir.active === '1');
                        if(primaryIdentityRole){
                            redirectQuery.identityRoleId = primaryIdentityRole.identity_role_id;
                            redirectQuery.organizationId = primaryIdentityRole.organisation.organisation_id;
                        }else{
                            // TODO: Not legit for dev db, waiting for integration db
                            //redirectQuery.identityRoleId = identityRoles[0].identity_role_id;
                            //redirectQuery.organizationId = identityRoles[0].organisation.organisation_id;

                            return this._goToSpordle(result);
                        }
                    }

                    const url = queryString.stringifyUrl({
                        url: locationSearch.referrer ?? getReferrer('HCR'),
                        query: redirectQuery,
                    });
                    if(location.hostname === "localhost"){
                        // localhost only
                        // When developping, send to another tab for testing.
                        // Comment the next line if you don't want to open a new tab everytime this is executed
                        window.open(url, undefined, 'noopener,noreferrer');
                    }else{
                        window.location.replace(url);
                    }
                }
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    if(refererParam.goto)
                        setUpInternal();
                    else
                        this.props.history.replace('/dashboard')
                }
            })
    }

    /**
     * Handles the redirection logic after a login
     * @param {Object} result The result from the login call
     * @param {'SPORDLE'|'HCR'} [platformToGo='SPORDLE'] Where we want to redirect the user
     * @private
     */
    _handleRedirection = (result, platformToGo = LOGINTYPE.SPORDLE) => {
        if(process.env.NODE_ENV === 'development'){ // localhost only
            window.sessionStorage.setItem('accessToken', result.AuthenticationResult.AccessToken);
        }

        if(spordleOAuthKey){
            this.props.AuthContext.getUserData(result.AuthenticationResult.AccessToken)
                .then((user) => {
                    window.parent.postMessage({
                        'func': 'onSpordleLogin',
                        'loginData': result.AuthenticationResult,
                        'userData': {
                            attributes: user.UserAttributes,
                            userName: user.Username,
                        },
                    }, '*');
                }).catch((error) => {
                    window.parent.postMessage({
                        'func': 'onLoginFailed',
                        'error': error.message,
                    }, '*');
                    fail();
                    console.error(error);
                });
        }else{
            switch (platformToGo){
                default:
                case LOGINTYPE.CLINIC:
                case LOGINTYPE.PLAY:
                case LOGINTYPE.SPORDLE:
                    if(!isSidMultiSport())
                        return this._goToSpordle(result);
                case LOGINTYPE.HCR:
                    return this._goToHCR(result);
            }
        }
    }

    /**
     * Function that handles redirection between authentification pages
     * @param {'LOGIN'|'SIGNUP'|'CONFIRMATION'|'RECOVERY'|'FORGOT-PASSWORD'|'TERMS'} target The auth page we want to go to
     * @param {boolean} [returnData=false] If it is for a Link element or not
     * @param {Object|boolean} [params] Extra parameters to send to route. Give `true` to reset the state.
     */
    goToPage = (target, returnData = false, params) => {
        const newPathData = {
            ...this.state,
            ...(params === true ? this.initState : params),
            search: this.props.location.search,
        };

        switch (target){
            case 'SIGNUP':
                newPathData.pathname = '/signup';
                break;
            case 'LOGIN':
                newPathData.pathname = '/login';
                break;
            case 'FORGOT-PASSWORD':
                newPathData.pathname = '/forgot-password';
                break;
            case 'RECOVERY':
                newPathData.pathname = '/account-recovery';
                break;
            case 'CONFIRMATION':
                newPathData.pathname = '/signup-confirmation';
                break;
            case 'TERMS':
                newPathData.pathname = '/terms';
                break;
            default:
                console.error(`Could to go to page with the given target(${target}). This target is not handled.`);
                return;
        }

        if(returnData){
            return newPathData;
        }
        this.props.history.push(newPathData);

    }

    /**
     *
     * @param {string} show
     * @param {boolean} reset If we want to reset the state like email, name and username
     */
    changeViewTo = (show, reset = false) => {
        addBreadcrumb({
            message: 'Changing view...',
            level: Severity.Info,
            category: 'login-navigation',
            type: 'navigation',
            data: {
                from: this.state.show,
                to: show,
            },
        });

        this.setState((prevState) => {
            if(reset){
                sendGAEvent('login_view', {
                    ...this.initState,
                    show: show,
                });
                return {
                    ...this.initState,
                    show: show,
                }
            }
            sendGAEvent('login_view', {
                ...prevState,
                show: show,
            });
            return {
                show: show,
            }
        });
    }

    /**
     * Function that checks if the user can login
     * @param {string} email The user's email to check
     * @returns {Promise.<any>}
     */
    checkForUser = (email) => {
        return this.props.AuthContext.verifyEmailExistance(email)
            .then(async(data) => {
                if(typeof data === 'object'){
                    data.isSuccess = !!data.name;
                    if(data.isLocked){
                        this.changeViewTo('locked');
                        throw new Error('locked out account');
                    }else if(data.isSuccess){ // User exists
                        const lang = data.userAttributes.find((attribute) => attribute.Name === 'locale')?.Value;
                        if(lang)
                            this.props.I18nContext.setLocale(lang);
                        await this._setStateAsync(() => ({
                            show: data.mustReactivate ? "reactivate" : "password",
                            email: email,
                            name: data.name,
                            userName: data.userName,
                            verifyEmail: data.userAttributes.find((attribute) => attribute.Name === 'email_verified')?.Value === "false",
                            isLocked: data.isLocked,
                            showForgotPassword: data.userStatus !== 'FORCE_CHANGE_PASSWORD',
                        }));
                    }
                }else{
                    await this._setStateAsync(() => ({ email: email }))
                }
                return data;
            }, async(error) => {
                await this._setStateAsync(() => ({ email: email }))
                throw new Error(error.message)
            })
    }

    /**
     * Will procceed to challenge the login with the given code
     * @param {string} challengeAnswer
     * @param {string} [platformToGo='SPORDLE'] Where we want to redirect the user after a successfull login
     * @returns {Promise}
     */
    challengeLogin = (challengeAnswer, platformToGo = LOGINTYPE.SPORDLE) => {
        // At this point, this.state.show should be set to the challenge name, this.state.session should be set and the username too
        return this.props.AuthContext.challengeLogin(this.state.session, this.state.userName, this.state.show, challengeAnswer)
            .then((result) => {
                if(!result.term_of_use){
                    return this.props.AccountsContext.getTermsOfUse()
                        .then((terms) => {
                            if(terms){
                                sendGAEvent('sign_terms', {
                                    status: 'PENDING',
                                });
                                this.props.AuthContext.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, () => {
                                    this.goToPage('TERMS', false, { terms: terms });
                                });
                                throw new Error('Cancelled');
                            }
                            return result;
                        }).catch((err) => {
                            throw new Error(err.message);
                        });
                }
                return result;
            })
            .then((result) => this._handleRedirection(result, platformToGo))
    }

    /**
     * This function handles the Login proccess and verification
     * @param {string} email
     * @param {string} password
     * @param {string} [platformToGo='SPORDLE'] Where we want to redirect the user after a successfull login
     * @returns {Promise}
     */
    handleLogin = (email, password, platformToGo = LOGINTYPE.SPORDLE) => {
        return this.props.AuthContext.login(email, password)
            .then((result) => {
                if(result.ChallengeName){ // Checking for 2FA OR requires new password
                    sendGAEvent('login_challenged', {
                        challengeType: result.ChallengeName,
                    });
                    switch (result.ChallengeName){
                        // Both required a 6 digits number
                        case 'SMS_MFA':
                        case 'SOFTWARE_TOKEN_MFA':
                            this.setState(() => ({ session: result.Session, challengeInfo: result.ChallengeParameters }));
                            this.changeViewTo(result.ChallengeName);
                            throw new Error('Cancelled');
                        case 'NEW_PASSWORD_REQUIRED':
                            this.setState(() => ({ session: result.Session, show: 'NEW_PASSWORD_REQUIRED' }));
                            this.goToPage('FORGOT-PASSWORD', false, { newPasswordRequired: result });
                            throw new Error('Cancelled');
                        case 'MFA_SETUP':
                            // Go to my account page
                            // TODO
                            break;
                    }
                }
                return result;
            })
            .then((result) => { // Checking if has terms of use, if not, redirect to the page of terms and conditions
                if(!result.term_of_use){
                    return this.props.AccountsContext.getTermsOfUse()
                        .then((terms) => {
                            if(terms){
                                sendGAEvent('sign_terms', {
                                    status: 'PENDING',
                                });
                                this.props.AuthContext.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, () => {
                                    this.goToPage('TERMS', false, { terms: terms });
                                });
                                throw new Error('Cancelled');
                            }
                            return result;
                        }).catch((err) => {
                            throw new Error(err.message);
                        });
                }
                return result;
            })
            // .then((result) => {
            //     if(this.state.verifyEmail){
            //         this.props.AuthContext.setUpInternalConnection({accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType}, () => {
            //             this.goToPage('CONFIRMATION', false, {result: result});
            //         });
            //         throw new Error('Cancelled');
            //     }
            //     return result;
            // })
            // Actual login
            .then((result) => this._handleRedirection(result, platformToGo));
    }

    /**
     * Register a new user into the Cognito user pool
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} password
     * @param {string} language
     * @param {string} [phoneNumber]
     * @param {Object} [address]
     * @returns {Promise}
     */
    handleSignUp = (firstName, lastName, email, password, language, phoneNumber, address) => {
        return this.props.AuthContext.register(firstName, lastName, email, password, language, phoneNumber)
            .then(async(data) => {
                sendGAEvent('sign_up');
                if(address){
                    // Don't block the signup if this fails as the user will have successfully signed in at this point
                    // MyAccount's homepage will show a pop up to fill in an address when this call fails
                    this.props.AccountsContext.createIdentityAddress(data.result.UserSub, address).catch(console.error);
                }
                this.goToPage('CONFIRMATION', false, { name: firstName, email: email, userName: data.result.UserSub, show: 'password', from: 'SIGNUP' });
                throw new Error('Cancelled');
            })
    }

    /**
     * Proceeds to confirm the account
     * @param {string} code A 6 digit code
     * @returns {Promise}
     */
    confirmAccount = (code) => {
        return this.props.AuthContext.confirmAccount(this.state.email, code)
            .then(() => {
                sendGAEvent('confirm_account');
                this.goToPage('LOGIN', false, { show: 'password' });
                throw new Error('Cancelled');
            });
    }

    /**
     * Will make an api call to verify the email
     * @param {string} code The code that was sent with this.sendAttributeCode
     * @param {string} result The result from the login
     * @param {string} [platformToGo='SPORDLE'] Where we want to redirect the user after a successfull login
     * @returns {Promise}
     */
    verifyEmail = (code, result, platformToGo = 'SPORDLE') => {
        return this.props.AuthContext.verifyAttributeCode('email', code)
            .then(() => {
                sendGAEvent('email_verified');
            })
            .then(() => this._handleRedirection(result, platformToGo))
    }

    /**
     * @returns {Object} Gets the default state value
     */
    getInitValues = () => this.initState

    render(){
        return (
            <AuthenticatorContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </AuthenticatorContext.Provider>
        );
    }
}

AuthenticatorProvider.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

AuthenticatorProvider.displayName = 'AuthenticatorProvider';

export default withContexts(I18nContext, AuthContext, AccountsContext)(withRouter(AuthenticatorProvider));