export default{
    "account.settings.profile.accountPhone.update"             : "Your account has been updated",
    "account.settings.profile.accountPhone.update.fail"        : "An error occurred, please refresh the page",
    "account.settings.profile.accountPhone.update.sms.success" : "SMS sent!",
    "account.settings.profile.accountPhone.update.sms.fail"    : "The SMS could not be sent",

    "account.settings.profile.accountPhone.phone"                     : "Mobile phone",
    "account.settings.profile.accountPhone.phone.2faOnly"             : "This info is private. Having a mobile phone number will enable to possibility to add SMS 2-Step Verification to your account.",
    "account.settings.profile.accountPhone.phone.notVerified"         : "Your phone number is not verified",
    "account.settings.profile.accountPhone.phone.current.notVerified" : "Your current phone number is not verified",
    "account.settings.profile.accountPhone.phone.current.change"      : "Change phone number",
    "account.settings.profile.accountPhone.verify"                    : "Verify",

    "account.settings.profile.accountPhone.2fa.bySms" : "2 step verification is activated by SMS",

    "account.settings.profile.accountPhone.2fa.enterCode" : "Please enter the code sent to",
}