import { useContext, useEffect, useState } from 'react';
import FadeIn from '../../animations/FadeIn';
import PropTypes from 'prop-types';
import { ScheduleContext } from '../Schedule';
import Translate from '@spordle/intl-elements';

/**
 * @description Repetitive html in event list
 * @param {Object} props
 * @param {Element|string} props.title
 * @param {string} props.type
 * @param {any} props.dep
 * @param {import('moment').Moment} props.momentObj
 * @param {JSX.Element} props.emptyLayout
 * @returns {React.ReactNode}
 */
const List = ({ title, events, type, dep, momentObj, emptyLayout }) => {
    const { eventComponent: Component, onEventClick } = useContext(ScheduleContext);

    return (
        <>
            {type === "daily" && <h3 className="d-block text-muted text-uppercase mb-2 small">{title}</h3>}
            {events.length > 0 ?
                <ul className="list-unstyled mb-4">
                    {
                        events.map((event, i) => (
                            <li key={event.id}>
                                <FadeIn
                                    distance={50}
                                    direction='bottom'
                                    style={{ opacity: 0 }}
                                    dependency={dep}
                                    delay={0.25 + (i / 10)}
                                >
                                    <Component
                                        onClick={() => onEventClick && onEventClick(event)}
                                        eventData={event}
                                        momentObj={momentObj}
                                        itemType={type}
                                    />
                                </FadeIn>
                            </li>
                        ))
                    }
                </ul>
                :
                emptyLayout ?? null

            }
        </>
    )
}

/**
 * @description Contains a list of EventItem.
 *
 * @param {object} momentObj A moment Object.
 * @param {string} [year] Year selected to render the list of events.
 * @param {string} [month] Month selected to render the list of events.
 * @param {string} [date] Date selected to render the list of events.
 * @param {string} [type] Type of event items to be rendered.
 * @param {function} [onItemClick] Callback when EventItem is clicked by user
 * @param {any} [dep] Dependency to trigger fade animation
 * @param {JSX.Element} [emptyLayout]
 * @returns {JSX.Element|null} Returns a list of events
 */
const EventList = ({
    momentObj,
    year,
    month,
    date,
    type,
    dep,
    showRecurrent = false,
    showDaily = true,
    emptyLayout,
}) => {
    const { getDailyEvents, getSharedEvents, events } = useContext(ScheduleContext);
    const [ allEvents, setAllEvents ] = useState([]);
    const [ shared, setShared ] = useState([]);
    const eventYear = year ?? momentObj.format('YYYY');
    const eventMonth = month ?? momentObj.format('MM');
    const eventDate = date ?? momentObj.format('D');

    useEffect(() => {
        setAllEvents(getDailyEvents(eventYear, eventMonth, eventDate));
        setShared(getSharedEvents(momentObj, momentObj))
    }, [ dep, events ]);

    return (
        momentObj && ((allEvents && allEvents.length > 0) || (showRecurrent && shared.length > 0)) ?
            <>
                {showRecurrent && shared.length > 0 && <List emptyLayout={emptyLayout} type={type} dep={dep} momentObj={momentObj} title={<Translate id='schedule.daily.recurrent.title' />} events={shared} />}
                {showDaily && <List emptyLayout={emptyLayout} type={type} momentObj={momentObj} dep={dep} title={momentObj.format('D MMMM YYYY')} events={allEvents} />}
            </>
            :
            <>
                {type === "daily" && <h3 className="d-block text-muted text-uppercase mb-2 small">{momentObj.format('D MMMM YYYY')}</h3>}
                {emptyLayout ?? null}
            </>
    );
}

export default EventList;

EventList.propTypes = {
    momentObj: PropTypes.object.isRequired,
    month: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    showRecurrent: PropTypes.bool,
    itemClickCallback: PropTypes.func,
    dep: PropTypes.any,
};