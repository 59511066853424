import { PaginationItem, PaginationLink } from 'reactstrap';

const PaymentPagination = ({ totalItems, setCurrentPage, currentPage, pagination }) => {
    const pageLength = Math.ceil(totalItems / pagination - 1);
    const firstPagination = currentPage - (currentPage - 1 <= 0 ? currentPage : currentPage === pageLength ? 2 : 1);

    const paginationData = [ firstPagination, firstPagination + 1 ];
    if(pageLength >= 2)
        paginationData.pushArray([ firstPagination + 2 ]);


    const setPage = (targetPage) => () => {
        if(targetPage >= 0 || targetPage <= pageLength - 1){
            setCurrentPage(targetPage);
        }
    }

    return (
        <div className='d-flex mb-3 mb-sm-0'>
            <nav className='mt-3 ml-auto' aria-label='Pagination'>
                <ul className='pagination mb-0'>
                    <PaginationItem disabled={currentPage === 0}>
                        <PaginationLink type="button" onClick={setPage(0)} first id='pagination-goTo-first' />
                    </PaginationItem>
                    {paginationData?.map((page) => (
                        <PaginationItem key={page} active={currentPage === page}>
                            <PaginationLink type="button" onClick={setPage(page)} id={`pagination-goTo-${page}`}>
                                {page + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === pageLength}>
                        <PaginationLink type="button" onClick={setPage(pageLength)} last id='pagination-goTo-last' />
                    </PaginationItem>
                </ul>
            </nav>
        </div>
    )
}

export default PaymentPagination
