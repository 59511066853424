import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import { GameIncidentsContext } from "../../../../../../../../contexts/GameIncidentsContext";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import generatePassword from "../../../../../../../../helpers/passwordGenerator";
import IncidentForm from "../../../IncidentForm";
import { incidentInit, incidentSchema } from "../../../modal/steps/stepIncident/AddMemberGameIncidentStepIncident";

const GameIncidentAddIncident = (props) => {
    return (
        <AnalyticsModal analyticsName="GameIncidentSidePanelAddIncident" isOpen={props.isOpen}>
            <GameIncidentAddIncidentInner {...props} />
        </AnalyticsModal>
    );
}

const GameIncidentAddIncidentInner = ({
    home_team,
    away_team,
    refreshTable,
    game_incident_players,
    game_incident_id,
    toggle,
    suspensions,
    suspensionTypes,
}) => {
    const { getTeamMembers } = useContext(TeamsContext);
    const { createGameIncidentPlayerIncident } = useContext(GameIncidentsContext);
    const [ players, setPlayers ] = useState({
        home: [],
        away: [],
    });

    const getTeamPlayers = (teamId) => {
        return getTeamMembers(teamId)
            .then((members) => {
                return members
                    .sort((a, b) => {
                        const aName = a.member.first_name + ' ' + a.member.last_name;
                        const bName = b.member.first_name + ' ' + b.member.last_name;
                        return aName.localeCompare(bName);
                    })
                    .reduce((data, mem) => {
                        if(mem.position?.position_group?.position_type === "PLAYER"){
                            data.push({ ...mem, team_id: teamId })
                        }
                        return data;
                    }, []);
            })
            .catch(console.error)
    }

    const getAllPlayers = async() => {
        const allMembers = {
            home: [],
            away: [],
        };

        if(home_team.team_id){
            allMembers.home = await getTeamPlayers(home_team.team_id);
        }
        if(away_team.team_id){
            allMembers.away = await getTeamPlayers(away_team.team_id);
        }

        return setPlayers(allMembers);
    }

    useEffect(() => {
        getAllPlayers()
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }, []);

    return (
        <Formik
            initialValues={{
                ...incidentInit(generatePassword()),
            }}
            validationSchema={incidentSchema}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const apiValues = {
                    game_period: values.game_period,
                    period_minutes: values.minutes,
                    period_seconds: values.seconds,
                    description: values.description,
                    suspension_id: values.suspension_id,
                    suspension_type_id: values.suspension_type_id,
                    team_id: values.team_id,
                    member_id: values.member_id,
                    jersey_number: values.jersey_number || "",
                };

                if(values.injured){
                    apiValues.injured = "1";
                    apiValues.returned_to_game = !!values.returned_to_game;
                }else{
                    apiValues.injured = "0";
                }

                createGameIncidentPlayerIncident(game_incident_id, apiValues)
                    .then((gameIncidentPlayerId) => {
                        refreshTable?.({
                            game_incident_players: [
                                ...game_incident_players,
                                {
                                    ...apiValues,
                                    game_incident_player_id: gameIncidentPlayerId,
                                    suspension: suspensions.find((s) => s.suspension_id === values.suspension_id),
                                    suspension_type: suspensionTypes.find((s) => s.suspension_type_id === values.suspension_type_id),
                                    team: values.team_id === home_team.team_id ? home_team : away_team,
                                    member: players[values.team_id === home_team.team_id ? 'home' : 'away']?.find((p) => p.member.member_id === values.member_id)?.member,
                                },
                            ],
                        });
                        toggle();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);
                        }
                    })

            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={toggle}><Translate id='account.members.gir.addModal.incident.title' /></ModalHeader>
                        <ModalBody>
                            <IncidentForm
                                incident={formik.values}
                                players={players}
                                suspensions={suspensions}
                                suspensionTypes={suspensionTypes}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={formik.isSubmitting} color="primary" type="submit" className={stringBuilder(`d-block w-100 w-md-auto d-md-inline-block`)}>
                                <Translate id="misc.confirm" />
                            </Button>
                            <Button disabled={formik.isSubmitting} onClick={toggle} color="primary" type="button" outline className={stringBuilder(`d-block w-100 w-md-auto d-md-inline-block`)}>
                                <Translate id="misc.cancel" />
                            </Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

export default GameIncidentAddIncident;