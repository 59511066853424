import { useContext, useState } from 'react';

import ListGroupItemLayout from './ListGroupItemLayout';

import Translate from '@spordle/intl-elements';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import ChangeAddressModal from './ChangeAddressModal';
import { AuthContext } from '../../../../../contexts/contexts';

const AccountAddess = () => {
    const { userData } = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };

    function getAddressString(){
        const currentAddress = userData.addresses[0];
        if(currentAddress){
            return `${currentAddress.unit_number ? `${currentAddress.unit_number}-` : ''}${currentAddress.street_number} ${currentAddress.street}, ${currentAddress.city}, ${currentAddress.province_code}, ${currentAddress.country_code}, ${currentAddress.zip_code}`
        }
        return '';
    }

    return (
        <>
            <ListGroupItemLayout
                title={<Translate id='form.fields.address' />}
                value={getAddressString()}
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountAddress' isOpen={isOpen}>
                <ChangeAddressModal toggleModal={toggleModal} />
            </AnalyticsModal>
        </>
    );
}

export default AccountAddess;