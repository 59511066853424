export default{
    "hcrSearch.fields.organization" : "Organization",

    "hcrSearch.search"   : "Search",
    "hcrSearch.noResult" : "No Results",
    "hcrSearch.select"   : "Select",

    "addMemberModal.title"                 : "Link an HCR member",
    "addMemberModal.searchResult.existing" : "Existing profiles",
    "addMemberModal.searchResult.already"  : "Already linked",
    "addMemberModal.searchResult.noResult" : "Oops! It looks like the member you are trying to find isn't in our records.",
    "addMemberModal.confirm.text"          : "You are about to link this member to your account",
    "addMemberModal.confirm.orgSearch"     : "Organization Search",

    "addMemberModal.button.add"  : "Link this member",
    "addMemberModal.button.link" : "Link a member",

    "addMemberModal.formik.label.uniqueIdentifier"         : "HCR Number",
    "addMemberModal.formik.label.search.title"             : "Search a member in HCR",
    "addMemberModal.formik.label.search.or"                : "or",
    "addMemberModal.formik.warning.filters.ignored"        : "The other filters will be ignored.",
    "addMemberModal.formik.validation.dateOfBirth.minDate" : "Date of birth must be before today",
    "addMemberModal.formik.validation.dateOfBirth.minAge"  : "A member must be at least 4 years old to participate. However, you may still create this member.",
    "addMemberModal.formik.validation.firstName"           : "First name must be of a valid format",
    "addMemberModal.formik.validation.lastName"            : "Last name must be of a valid format",

    "addMemberModal.unsignedWaivers" : "This member has unsigned waivers",
    "addMemberModal.goToSign"        : "Sign the waivers now",

    "addMemberModal.error.2000.btn" : "Click here to edit this member's first name and last name.",
}