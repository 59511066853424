import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import moment from "moment";
import 'moment-timezone';
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import FadeIn from "../../../../components/animations/FadeIn";
import ClinicCategory from "../../../../components/ClinicCategory";
import EventItem from "../../../../components/schedule/events/EventItem";
import Schedule from "../../../../components/schedule/Schedule";
import { AccountsContext, AuthContext } from "../../../../contexts/contexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { getFutureDate, getPastDate } from "../../../../helpers/dateHelper";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import ClinicEventModal from "./components/ClinicEventModal";

const AccountMemberSchedule = ({ member }) => {
    const { getAccountMemberCalendar } = useContext(AccountsContext);
    const { userData } = useContext(AuthContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ modalInfo, setModalInfo ] = useState(null);
    const [ activeTab, setActiveTab ] = useState('day');
    const [ events, setEvents ] = useState(null);

    const toggleEvent = (event) => setModalInfo({ event: event, isOpen: true });
    const getExternalLink = (arr) => arr[getGenericLocale()];

    const toggleTab = (tab) => {
        if(activeTab !== tab){
            setActiveTab(tab);
        }
    }

    const getTimeUnit = () => {
        switch (activeTab){
            case 'week':
                return 'weeks';
            case 'month':
                return 'months';
            case 'day':
            default:
                return 'days';
        }
    }

    useEffect(() => {
        if(member.members?.[0]?.member_id){
            getAccountMemberCalendar(
                userData.Username,
                member.members[0].member_id,
                {
                    search_by: 'CLINIC_SESSION,INSTALLMENT',
                    start_date: getPastDate(),
                    end_date: getFutureDate(),
                },
            )
                .then((calendars) => {
                    const theEvents = [];

                    if(calendars.clinic_sessions?.length > 0){
                        calendars.clinic_sessions.forEach((session) => {
                            const date = moment(session.start_date);
                            const endDate = moment(session.end_date);
                            let location;
                            let locationLink;

                            if(!!session.hockey_university_link || !!session.hcop_link){
                                location = <Translate id={`schedule.label.link.${session.hcop_link ? 'hcop' : 'hu'}`} />;
                                locationLink = getExternalLink(session.hockey_university_link || session.hcop_link);
                            }else if(session.session_format == "ONLINE_LIVE" || session.session_format == "ONLINE"){
                                location = session.url_link;
                                locationLink = session.url_link || session.map_url;
                            }else{
                                location = session.location_name;
                                locationLink = session.map_url || session.url_link;
                            }

                            const event = {
                                ...session,
                                id: 'session-' + session.clinic_session_id,
                                title: <DisplayI18n field='name' defaultValue={session.clinic?.name} i18n={session.clinic?.i18n} />, //<ClinicCategoryBuilder showDot={false} qualification={session.clinic?.qualification} />,
                                date: date,
                                location: location,
                                locationLink: locationLink,
                                timeZoneAbr: moment.tz(session.clinic.timezone).format('z'),
                                startTime: date.format('HH:mm'),
                                endTime: endDate.format('HH:mm'),
                                eventType: 'session',
                                // this approach will probably change
                                desc: (
                                    <div>
                                        <span className="font-medium d-flex align-items-center"><DisplayI18n field='name' defaultValue={session.clinic?.name} i18n={session.clinic?.i18n} /></span>
                                        <span className="small d-flex align-items-center"><ClinicCategory.Builder qualification={session.clinic?.qualification} /></span>
                                        <span className="d-block"><span className="font-medium"><Translate id='form.fields.format' /></span>: <Translate id={`schedule.format.${session.session_format}`} /></span>
                                        <span className="d-block"><span className="font-medium"><Translate id='schedule.label.duration' /></span>: {session.duration}</span>
                                    </div>
                                ),
                                hasExternalUrl: session.clinic.has_external_url, // this is mainly used for moodle (v1)
                            }

                            theEvents.push(event);
                        });
                    }

                    if(calendars.payment_schedules.length > 0){
                        calendars.payment_schedules.forEach((pay) => {
                            const date = moment(pay.due_date);
                            const event = {
                                ...pay,
                                id: 'payment-' + pay.invoice_payment_id,
                                date: date,
                                title: <Translate id='schedule.label.payment' />,
                                eventType: 'payment',
                                total: pay.amount,
                                // this approach will probably change
                                desc: (
                                    <p>
                                        <span className="d-block font-medium"><Translate id='schedule.label.scheduledPayment' /></span>
                                        <span className="d-block"><Translate id='schedule.label.amount' />: <CurrencyFormat value={pay.amount / 100} /></span>
                                        <span className="d-block"><Translate id='schedule.label.invoice' />: <Link to={`/purchases/invoice/${pay.invoice.invoice_number}`}>{pay.invoice.invoice_number}</Link></span>
                                    </p>
                                ),
                            }

                            theEvents.push(event);
                        })
                    }

                    setEvents(theEvents);
                })
                .catch((err) => {
                    if(!AxiosIsCancelled(err.message)){
                        console.error(err);
                    }
                });
        }
    }, [ member?.meta_member_id ]);

    return (
        <Card body className="card-shadow">
            <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                <div className="card-title font-bold h4 mb-0"><Translate id='account.members.schedule.title' /></div>
            </div>
            <Schedule eventComponent={EventItem} onEventClick={toggleEvent} events={events}>
                <section>
                    <header className="mb-3">
                        <Schedule.Navigation timeUnit={getTimeUnit()} />
                        <nav className="schedule-nav">
                            <Nav pills fill>
                                <NavItem active={activeTab === 'day'}>
                                    <NavLink onClick={() => toggleTab('day')}><Translate id='schedule.tabs.day' /></NavLink>
                                </NavItem>
                                <NavItem active={activeTab === 'week'}>
                                    <NavLink onClick={() => toggleTab('week')}><Translate id='schedule.tabs.week' /></NavLink>
                                </NavItem>
                                <NavItem active={activeTab === 'month'}>
                                    <NavLink onClick={() => toggleTab('month')}><Translate id='schedule.tabs.month' /></NavLink>
                                </NavItem>
                                <NavItem active={activeTab === 'full'}>
                                    <NavLink onClick={() => toggleTab('full')}><Translate id='schedule.tabs.full' /></NavLink>
                                </NavItem>
                            </Nav>
                        </nav>
                    </header>
                    <TabContent activeTab={activeTab} className='schedule-tab-content'>
                        <TabPane tag="section" tabId="day">
                            <Schedule.Daily showRecurrent />
                        </TabPane>
                        <TabPane tag="section" tabId="week">
                            <FadeIn>
                                <Schedule.WeeklyFull className="mb-4" />
                                <Schedule.Daily className="d-lg-none" />
                            </FadeIn>
                        </TabPane>
                        <TabPane tag="section" tabId="month">
                            <FadeIn className="row">
                                <Col md="8">
                                    <Schedule.Monthly className="mb-4" />
                                </Col>
                                <Col md="4">
                                    <Schedule.Daily />
                                </Col>
                            </FadeIn>
                        </TabPane>
                        <TabPane tag="section" tabId="full">
                            <Schedule.Upcoming />
                        </TabPane>
                    </TabContent>
                </section>
            </Schedule>
            <ClinicEventModal event={modalInfo?.event} isOpen={modalInfo?.isOpen} toggle={() => setModalInfo((prev) => ({ ...prev, isOpen: false }))} />
        </Card>
    )
}

export default AccountMemberSchedule;