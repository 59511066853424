import Translate from '@spordle/intl-elements';
import { Component } from 'react';
import { AxiosCancelAll } from '../../../api/CancellableAPI';
import GoToHomeBtn from '../../../components/GoToHomeBtn';
import UATWarning from '../../../components/UATWarning';
import AccountPaymentMethodsContent from './AccountPaymentMethodsContent';

class AccountPaymentMethods extends Component{
    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <header className="p-3 mb-0 card card-border card-shadow">
                    <div className="d-flex align-items-center justify-content-between">
                        {/* Title */}
                        <div className="h3 font-medium text-primary mb-0">
                            <Translate id='account.paymentMethods.title' />
                        </div>
                        <div>
                            <GoToHomeBtn />
                        </div>
                    </div>
                </header>
                <UATWarning />

                <div className="page-content container-fluid">
                    <AccountPaymentMethodsContent />
                </div>
            </>
        );
    }
}

export default AccountPaymentMethods;