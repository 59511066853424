import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardBody
} from "reactstrap";
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import AccountMemberWaiversSidepanel from './components/AccountMemberWaiversSidepanel';
import { Link, useHistory } from 'react-router-dom';
import { WaiverSignatureDisplay, waiverNotSigned } from '../../../../helpers/waiversHelper';
import MemberAlertsIcon from '../../../../components/memberBadges/MemberAlertsIcon';
import WaiversModal from '../../purchases/components/Sidepanel/waivers/WaiversModal';
import { mutate } from 'swr';
import { AuthContext } from '../../../../contexts/contexts';

const AccountMemberWaivers = ({ member, openModal, refreshMetaMember }) => {
    const i18nContext = useContext(I18nContext);
    const memberContext = useContext(MembersContext);
    const history = useHistory();
    const auth = useContext(AuthContext);

    const [ selectedWaiver, setSelectedWaiver ] = useState(null);
    const mandatoryWaivers = useRef([]);
    const openModalRef = useRef(false);

    /**  @type {React.MutableRefObject<SpordleTableProvider>} */
    const spordleTable = useRef();

    const updateMandatoryWaivers = (spordleTableData) => {
        mandatoryWaivers.current = spordleTableData.filter((waiver) => waiver.mandatory == 1 && waiver.answer === waiverNotSigned)
    }

    const closeModal = () => {
        history.replace('#waivers')
        openModalRef.current = false
        spordleTable.current.refreshTable();
        setSelectedWaiver(null);
    }

    useEffect(() => {
        if(mandatoryWaivers.current.length && openModal){ // the mandatory waivers could possibly not be fetched yet
            setSelectedWaiver(mandatoryWaivers.current[0])
        }else if(!mandatoryWaivers.current.length && openModal){
            openModalRef.current = true
        }
    }, [ openModal ])

    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0"><Translate id='account.members.tabs.waivers' /></div>
                </div>
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='id'
                    sidePanel={(props) => <AccountMemberWaiversSidepanel {...props} updateMandatoryWaivers={updateMandatoryWaivers} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='waivers'
                                ref={(r) => { spordleTable.current = r; panelProps.spordleTableRef(r); }}
                                key={member.member_id}
                                tableHover bordered striped
                                clickable
                                desktopWhen
                                pagination={20}
                                dataIndex='id'
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                loadData={(from, filter, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'CDM':
                                            return memberContext.getWaivers(member.member_id)
                                                .then((waivers) => {
                                                    const formattedWaivers = waivers.map((waiver) => ({
                                                        ...waiver,
                                                        waiver: waiver,
                                                        invoiceItem: waiver,
                                                        id: `${waiver.waiver_id}-${waiver.invoice_number}`,
                                                    }))
                                                    updateMandatoryWaivers(formattedWaivers)

                                                    if(openModalRef.current && from === 'CDM'){ // very specific case where we would have openModal as true before the waivers are fetched
                                                        setSelectedWaiver(mandatoryWaivers.current[0])
                                                    }

                                                    return formattedWaivers
                                                });
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.season' />,
                                        key: 'season',
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.reference' />,
                                        key: 'invoice_number',
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.waiver' />,
                                        key: 'waiver',
                                        sortKey: `i18n.${i18nContext.getGenericLocale()}.name`,
                                        fallbackSortKey: 'name',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.signed' />,
                                        key: 'signed',
                                        sortKey: 'answer',
                                        fallbackSortKey: 'updated_at',
                                        sortable: true,
                                        mobile: true,
                                    },
                                ]}
                                searchKeys={[
                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                    'name',
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'organisation.name',
                                    'invoice_number',
                                    'identity.family_name',
                                    'identity.name',
                                    `period.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'period.name',
                                    'updated_at',
                                ]}
                                renderRow={(columnKey, waiver) => {
                                    switch (columnKey){
                                        case 'season':
                                            return (
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={waiver.period?.name}
                                                    i18n={waiver.period?.i18n}
                                                />
                                            )
                                        case 'invoice_number':
                                            return <Link to={'/purchases/invoice/' + waiver.invoice_number}>#{waiver.invoice_number}</Link>
                                        case 'waiver':
                                            return (
                                                <div style={{ maxWidth: '250px' }}>
                                                    {waiver.mandatory == 1 &&
                                                        <div className='text-danger small d-flex align-items-center'>
                                                            <MemberAlertsIcon icon="waivers" className="pr-1" />
                                                            <span><Translate id='misc.mandatory' /></span>
                                                        </div>
                                                    }
                                                    <div className="font-medium text-dark">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={waiver.name}
                                                            i18n={waiver.i18n}
                                                        />
                                                    </div>
                                                    <div className="small">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={waiver.organisation?.name}
                                                            i18n={waiver.organisation?.i18n}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        case 'signed':
                                            return (
                                                <WaiverSignatureDisplay waiver={waiver} />
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(waiver) => !!waiver.checked}
                                emptyLayout={<EmptyLayout translateMsg msg='members.profile.waiver.table.empty' />}
                            >
                                {(spordleTable) => (
                                    <>
                                        <WaiversModal
                                            signedBy={{ ...selectedWaiver?.identity, invoice_date: selectedWaiver?.updated_at }}
                                            waiver={selectedWaiver}
                                            answer={selectedWaiver?.answer}
                                            invoiceNumber={selectedWaiver?.invoice_number}
                                            period={selectedWaiver?.period}
                                            closeModal={closeModal}
                                            onSubmit={async() => {
                                                const index = mandatoryWaivers.current.map((waiver) => waiver.id).indexOf(selectedWaiver.id); // get index of current waiver
                                                if(index + 1 < mandatoryWaivers.current.length){ // check if there are any waivers left
                                                    setSelectedWaiver(mandatoryWaivers.current[index + 1]) // reopen modal with next waiver
                                                }else{
                                                    await mutate([ 'accountStatus', auth.userData.Username ]);
                                                    closeModal();
                                                    refreshMetaMember(member.meta_member_id);
                                                }
                                            }}
                                        />

                                        <div className='d-flex justify-content-between mb-2'>
                                            <SpordleTableProvider.SearchInput />

                                            <div>
                                                <SpordleTableProvider.Refresh />
                                                <Button
                                                    color='primary'
                                                    type='button'
                                                    onClick={() => {
                                                        setSelectedWaiver(mandatoryWaivers.current[0]);
                                                    }}
                                                    className='ml-2'
                                                    disabled={!mandatoryWaivers.current.length}
                                                >
                                                    <Translate id='members.profile.waiver.table.signAll' />
                                                </Button>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default AccountMemberWaivers;