import React from 'react';
import { Link } from 'react-router-dom';
import {
    FormGroup,
    Input,
    Fade,
    Button,
    Collapse
} from 'reactstrap';

import * as Yup from 'yup';

// Context Providers
import { I18nContext } from '../../contexts/I18nContext';
import { AuthContext } from "../../contexts/contexts";

// Language
import { LOCALES } from '../../i18n';
import Translate from '@spordle/intl-elements';

// Components
import AuthHeader from '../../components/tour/Authheader';
import AntiSpam from '@spordle/anti-spam'
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import GoToHomeBtn from '../../components/GoToHomeBtn';
import WithTourTheme from '../../components/HOC/WithTournamentTheme';
import { fire, success } from '@spordle/toasts';

class ForgotPassword extends React.Component{
    static contextType = AuthContext;

    constructor(props){
        super(props);
        this.state = {
            recoveryEmail: "",
            accountRecoveryForm: "start", // start, sending, success, error
            errorCode: "",
        };
        this.first = true;
    }

    handleChange = (e) => {
        this.setState({ recoveryEmail: e.target.value });
    }

    sendRecoveryEmail = (e) => {
        if(this.state.accountRecoveryForm !== "sending"){

            //Validate value
            const schema = Yup.string().email('invalidEmail').required('requiredEmail');
            schema.validate(this.state.recoveryEmail)
                .then((_response) => {
                    this.context.sendRecoveryEmail(this.state.recoveryEmail)
                        .then((_response) => {
                            this.showEmailSent();
                            if(!this.first){
                                success({ msg: 'forgotPassword.success.emailSent.toast' });
                            }
                            this.first = false;
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message))
                                this.showError(error.message)
                        });
                })
                .catch((error) => {
                    if(error.errors && error.errors[0])
                        this.showError(error.errors[0]);
                });
        }

    }

    showEmailSent = () => {
        this.setState({ accountRecoveryForm: "success" });
    }

    showError = (errorCode) => {
        this.setState({ accountRecoveryForm: "error", errorCode: errorCode });
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                    <GoToHomeBtn />
                    <I18nContext.Consumer>
                        {(i18n) => {
                            const { locale, setLocale } = i18n;
                            return (
                                <>
                                    {(locale === 'en-ca') ?
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.FRENCH) }}>FR</Button></>
                                        :
                                        <><Button color="link" onClick={() => { setLocale(LOCALES.ENGLISH) }}>EN</Button></>
                                    }
                                </>
                            )
                        }}
                    </I18nContext.Consumer>
                </div>
                <AuthHeader />

                <div className="auth-box my-0">
                    <Fade>
                        <div className="ml-n2 mt-n2">
                            <Link to="/login" className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id="forgotPassword.action.backToLogin" /></Link>
                        </div>
                        { this.state.accountRecoveryForm !== 'success' ?
                            <>
                                <h2 className="h2 font-medium mb-3"><Translate id="forgotPassword.title.accountRecovery" /></h2>
                                <FormGroup className="mb-4">
                                    <label className="control-label" htmlFor="recoveryEmail">
                                        <Translate id="forgotPassword.label.enterEmail" />
                                    </label>
                                    <Input
                                        type="text"
                                        id="recoveryEmail"
                                        name="recoveryEmail"
                                        value={this.state.recoveryEmail}
                                        onChange={this.handleChange}
                                        className={'form-control'}
                                    />
                                </FormGroup>
                                <button onClick={this.sendRecoveryEmail} type="button" className="btn btn-block btn-primary"><Translate id="forgotPassword.action.sendEmail" /></button>

                                <Collapse isOpen={this.state.accountRecoveryForm === 'error'}>
                                    { this.state.accountRecoveryForm === 'error' ?
                                        <div id="" className='alert alert-danger mt-4'><Translate id={`forgotPassword.error.${this.state.errorCode}`} /></div>
                                        : '' }
                                </Collapse>
                            </>
                            : '' }
                        { this.state.accountRecoveryForm === 'success' ?
                            <Fade in={this.state.accountRecoveryForm === 'success'}>
                                <div className="mt-4 mb-4 text-center">
                                    <span className="d-block h3 mb-3 text-success strong">{this.state.recoveryEmail}</span>
                                    <p className="mb-4"><Translate id='forgotPassword.success.emailSent' /></p>
                                </div>
                                <div className="border-top pt-3">
                                    <span><Translate id='forgotPassword.label.noEmail' /></span>
                                    <AntiSpam
                                        timeoutDuration={10000}
                                        clicksForTimeout={2}
                                        resetClicksDuration={30000}
                                        isSpamming={() => {
                                            fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                                        }}
                                    >
                                        {(_, antiSpamCallback) => (
                                            <button
                                                id="resendEmailButton"
                                                onClick={(e) => {
                                                    antiSpamCallback(e);
                                                    this.sendRecoveryEmail();
                                                }}
                                                type="button"
                                                className="ml-1 reset-btn text-link"
                                            >
                                                <Translate id="forgotPassword.action.sendEmail" />
                                            </button>
                                        )}
                                    </AntiSpam>
                                </div>
                            </Fade>
                            : '' }
                    </Fade>
                </div>
            </div>
        );
    }
}

export default WithTourTheme(ForgotPassword);
