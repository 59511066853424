export default{
    "account.transactions.registrations" : "Registration(s)",
    "account.transactions.items"         : "Item(s)",
    "account.transactions.paidBy"        : "Paid by",
    "account.transactions.total"         : "Total",
    "account.transactions.viewReceipt"   : "View receipt",
    "account.transactions.due"           : "Due",
    "account.transactions.add"           : "Add a credit",
    "account.transactions.credit"        : "Credit",
    "account.transactions.appliedCredit" : "Applied Credit",

    "account.transactions.sidePanel.status.PENDING"        : "Pending",
    "account.transactions.sidePanel.status.PAID"           : "Paid",
    "account.transactions.sidePanel.status.COMPLETED"      : "Completed",
    "account.transactions.sidePanel.status.NEED_REVIEW"    : "In review",
    "account.transactions.sidePanel.status.SUSPENDED"      : "Suspended",
    "account.transactions.sidePanel.status.DECLINED"       : "Declined",
    "account.transactions.sidePanel.status.PARTIALLY_PAID" : "Partially Paid",
    "account.transactions.sidePanel.status.CANCELED"       : "Cancelled",
    "account.transactions.sidePanel.status.VOID"           : "VOID",
    "account.transactions.sidePanel.paymentType.manual"    : "Manual Transaction",
    "account.transactions.sidePanel.paymentType.online"    : "Online Transaction",

    "account.transactions.sidePanel.header.invoice"            : "Invoice",
    "account.transactions.sidePanel.header.initialPaymentDate" : "Initial payment date",
    "account.transactions.sidePanel.header.viewInvoice"        : "View Invoice",

    "account.transactions.sidePanel.actions.refund" : "Refund",

    "account.transactions.sidePanel.items.payments"          : "Payments",
    "account.transactions.sidePanel.items.date"              : "Date",
    "account.transactions.sidePanel.items.status"            : "Status",
    "account.transactions.sidePanel.items.amount"            : "Amount",
    "account.transactions.sidePanel.items.price"             : "Price",
    "account.transactions.sidePanel.items.due"               : "Due",
    "account.transactions.sidePanel.items.credit"            : "Credit",
    "account.transactions.sidePanel.items.refund"            : "Refund",
    "account.transactions.sidePanel.items.refunds"           : "Refunds",
    "account.transactions.sidePanel.items.paymentReception"  : "Received payment",
    "account.transactions.sidePanel.items.paymentReceptions" : "Received payments",

    "account.transactions.sidePanel.clinic"            : "Clinic",
    "account.transactions.sidePanel.clinic.fee"        : "Clinic fee(s)",
    "account.transactions.sidePanel.clinic.fee.single" : "Clinic fee",

    "account.transactions.sidePanel.credit.delete.message" : "You are about to delete this credit.",
    "account.transactions.sidePanel.credit.updated"        : "Credit updated!",
    "account.transactions.sidePanel.credit.creationDate"   : "Creation date",
    "account.transactions.sidePanel.credit.balance"        : "Balance",

    "account.transactions.sidePanel.tabs.summary" : "Summary",
    "account.transactions.sidePanel.tabs.forms"   : "Questionnaires",
    "account.transactions.sidePanel.tabs.waivers" : "Waivers",

    "account.transactions.sidePanel.forms.noForms.title"         : "No questionnaire",
    "account.transactions.sidePanel.forms.noForms.text"          : "There is no questionnaire associated with this transaction.",
    "account.transactions.sidePanel.forms.label.min"             : "Min",
    "account.transactions.sidePanel.forms.label.max"             : "Max",
    "account.transactions.sidePanel.forms.label.between"         : "Between",
    "account.transactions.sidePanel.forms.label.and"             : "and",
    "account.transactions.sidePanel.forms.label.deletedQuestion" : "This question has been deleted.",

    "account.transactions.sidePanel.waivers.title"        : "No waivers",
    "account.transactions.sidePanel.waivers.text"         : "There are no waivers associated with this transaction.",
    "account.transactions.sidePanel.waivers.accept"       : "I have read and accept the waiver",
    "account.transactions.sidePanel.waivers.accepted"     : "Accepted by {name}",
    "account.transactions.sidePanel.waivers.declined"     : "Declined by {name}",
    "account.transactions.sidePanel.waivers.required"     : "This waiver is mandatory",
    "account.transactions.sidePanel.waivers.notMandatory" : "This waiver is not mandatory",

    "members.profile.waiver.tab" : "Waivers",

    "members.profile.waiver.table.empty"            : "No waiver for this member for the current period",
    "members.profile.waiver.table.signAll"          : "Sign all mandatory waivers",
    "members.profile.waiver.table.column.invoice"   : "Invoice",
    "members.profile.waiver.table.column.reference" : "Reference",
    "members.profile.waiver.table.column.waiver"    : "Waiver",
    "members.profile.waiver.table.column.season"    : "Season",
    "members.profile.waiver.table.column.signed"    : "Signed",

    "members.profile.waiver.signed.by"                : "Signed by",
    "members.profile.waiver.signed.by.YES"            : "Accepted by",
    "members.profile.waiver.signed.by.NO"             : "Declined by",
    "members.profile.waiver.signed.not"               : "Not signed",
    "members.profile.waiver.sidePanel.sign.label"     : "I agree with this waiver",
    "members.profile.waiver.signed.apiPartner"        : "API Partner",
    "members.profile.waiver.signed.confirmationLabel" : "Confirmation label: ",
    "members.profile.waiver.signed.declineLabel"      : "Declination label: ",


    "members.profile.waiver.csv.header.organisation"          : "Organisation",
    "members.profile.waiver.csv.header.signedOn"              : "Signed on",
    "members.profile.waiver.csv.header.answer.YES"            : "Yes",
    "members.profile.waiver.csv.header.answer.NO"             : "No",
    "members.profile.waiver.csv.header.answer.NOT_SIGNED_YET" : "Not signed",
}