import { stringBuilder } from "@spordle/helpers";
import HoverableComponent from "../components/HoverableComponent";
import { fail, fire } from '@spordle/toasts';

/**
 * @description Fallback of copy function when navigator isn't supported
 * @Refer {@see https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript}
 *
 * @param {string} text Text to copy to clipboard
 *
 * @throws {Error} Will throw an error if copy wasn't successful
 * @returns {Promise<boolean>}
 */
async function _fallbackCopy(text){
    const textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
    // May cease to work, but it still is a fallback for some browsers
    const successful = document.execCommand('copy');

    document.body.removeChild(textArea);

    if(!successful){
        throw new Error('Could not copy');
    }

    return Promise.resolve(successful);
}

/**
 * @description Helper function to copy a test to the clipboard
 *
 * @param {string} text
 * @param {string} [successMsg='misc.clipboard.success'] Success msg displayed in toast, will be translated
 * @param {string} [failMsg='misc.clipboard.error'] Fail msg displayed in toast, will be translated
 */
async function copyToClipBoard(text, successMsg = 'misc.clipboard.success', failMsg = 'misc.clipboard.error', noToast){
    try{
        if(navigator?.clipboard && window.isSecureContext){ // copy api https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
            await navigator.clipboard.writeText(text);
        }else{ // fallback
            await _fallbackCopy(text);
        }
        if(!noToast)
            fire({ msg: successMsg, info: text.length < 75 ? text : null, skipInfoTranslate: true, theme: 'success' });
    }catch(err){
        console.error(err);

        if(!noToast)
            fail({ msg: failMsg });
    }
}


export const HoverableCopy = ({ className, children, toCopy }) => (
    <HoverableComponent>
        {(isHovering) => (
            <span
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyToClipBoard(toCopy);
                }}
                className={stringBuilder(className, "d-inline-block px-1 mx-n1 rounded-lg clickable", { "bg-light": isHovering })}
            >
                {children}
                {toCopy &&
                    <i
                        className={
                            stringBuilder(
                                'mdi mdi-content-copy ml-1 clickable',
                                { 'invisible': !isHovering },
                            )
                        }
                    />
                }
            </span>
        )}
    </HoverableComponent>
)
export{ copyToClipBoard };
