import { useRef, useEffect } from "react";
import gsap from "gsap/gsap-core";
import CSSPlugin from "gsap/CSSPlugin";
import PropTypes from 'prop-types';
gsap.registerPlugin(CSSPlugin);

/**
 * @description Fade In animation made with gsap.
 *
 * @param {Number} [speed] Speed of the animation in seconds. Default is 0.5.
 * @param {React.ElementType} [tag] Tag of the element on which the animation is performing. Default is div.
 * @param {String} [direction] Animation direction. 'up', 'left', 'down' or 'right', else it's just a fade in.
 * @param {Number} [delay] Delay before animation triggers. Default is 0.
 * @param {Integer} [distance] Distance of the animation direction. If not direction specified, it's just fade in. Default is 200.
 * @param {Any} [dependency] Used for the useEffect.
 */
const FadeIn = ({ speed,
                  tag: Tag = 'div',
                  direction,
                  delay,
                  distance,
                  dependency,
                  ...props }) => {

    const fadeDirection = {
        x: direction ? (direction === 'left' ? -distance : direction === 'right' ? distance : 0) : 0,
        y: direction ? (direction === 'up' ? -distance : direction === 'bottom' ? distance : 0) : 0,
    }

    const compRef = useRef(null);
    const dependencies = [ compRef, direction, delay, dependency ];

    useEffect(() => {
        gsap.fromTo(compRef.current,
                    {
                        ...fadeDirection,
                        opacity: 0,
                    },
                    {
                        x: 0,
                        y: 0,
                        opacity: 1,
                        duration: speed,
                        delay: delay,
                        ease: 'power4.out',
                    })

        return () => {
            gsap.killTweensOf(compRef.current);
        }
    }, dependencies);

    return (
        <Tag ref={compRef} {...props} />
    );
};

export default FadeIn;

FadeIn.propTypes = {
    speed: PropTypes.number,
    tag: PropTypes.elementType,
    direction: PropTypes.oneOf([ 'up', 'bottom', 'left', 'right' ]),
    delay: PropTypes.number,
    distance: PropTypes.number,
    dependency: PropTypes.any,
};

FadeIn.defaultProps = {
    speed: 0.5,
    tag: 'div',
    delay: 0,
    distance: 200,
};