import { FormikDateTime, FormikInputText } from "@spordle/formik-elements";
import { Formik, Form } from "formik";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import moment from 'moment';
import Required from '../../formik/Required';
import { useContext, useState } from "react";
import { MembersContext } from "../../../contexts/MembersContext";
import UserDisplay from "../../userDisplay/UserDisplay";
import * as Yup from 'yup';
import Translate from "@spordle/intl-elements";
import UserImg from "../../UserImg";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const AddMemberStep1 = ({ metaMembers, isLoading, changeView, toggleLoading, closeModal, setData, data }) => {
    const membersContext = useContext(MembersContext);
    const [ searchResults, setSearchResults ] = useState(false);

    return (
        <Formik
            initialValues={{
                firstName: data?.firstName || '',
                lastName: data?.lastName || '',
                dateOfBirth: data?.dateOfBirth ? moment(data.dateOfBirth) : '',
                unique_identifier: '',
            }}
            validationSchema={Yup.object().shape({
                firstName: Yup.string()
                    .when('unique_identifier', {
                        is: (id) => !id,
                        then: Yup.string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.firstName' /> }),
                    }),
                lastName: Yup.string()
                    .when('unique_identifier', {
                        is: (id) => !id,
                        then: Yup.string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.lastName' /> }),
                    }),
                dateOfBirth: Yup.mixed()
                    .when('unique_identifier', {
                        is: (id) => !id,
                        then: Yup.mixed()
                            .test({
                                name: 'isValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return moment.isMoment(date);
                                },
                            }).test({
                                name: 'dateOfBirthNotToday',
                                message: <Translate id='addMemberModal.formik.validation.dateOfBirth.minDate' />,
                                test: function(dateOfBirth){
                                    return moment(dateOfBirth).isBefore(moment());
                                },
                            }),
                    }),
                unique_identifier: Yup.string(),
            })}
            onSubmit={(values) => {
                toggleLoading(true);

                const apiVal = !values.unique_identifier ? {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    birthdate: values.dateOfBirth.clone().format('YYYY-MM-DD'),
                } : { unique_identifier: values.unique_identifier };

                membersContext.getPublicMembers(apiVal, true)
                    .then(setSearchResults)
                    .catch(console.error)
                    .finally(() => toggleLoading(false));
            }}
        >
            { (formik) => (
                <Form>
                    <ModalBody>
                        <div className="h4 mb-3 font-weight-bold"><Translate id='addMemberModal.formik.label.search.title' /></div>
                        <Row form className="mb-3">
                            <Col sm="6" className="mb-3">
                                <Label for="firstName"><Translate id='form.fields.firstName' /> <Required /></Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} id='firstName' name='firstName' trim />
                            </Col>
                            <Col sm="6" className="mb-3">
                                <Label for="lastName"><Translate id='form.fields.lastName' /> <Required /></Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} id='lastName' name='lastName' trim />
                            </Col>
                            <Col sm="6" className="mb-2">
                                <Label for="dateOfBirth"><Translate id='form.fields.dateOfBirth' /> <Required /></Label>
                                <FormikDateTime
                                    inputProps={{
                                        disabled: !!formik.values.unique_identifier,
                                    }}
                                    name='dateOfBirth'
                                    id='dateOfBirth'
                                    translatePlaceholder={false}
                                    initialViewDate={moment().subtract(10, "years").toDate()}
                                    timeFormat={false}
                                    dateFormat={'YYYY-MM-DD'}
                                    isValidDate={(current) => moment(current).isBefore(moment())} // Must be born before current date
                                />
                            </Col>
                            <Col xs="12" className="mb-2">
                                <div className="d-flex align-items-center justify-content-center">
                                    <hr className="flex-fill" /> <span className="px-2 font-bold"><Translate id='addMemberModal.formik.label.search.or' /></span> <hr className="flex-fill" />
                                </div>
                            </Col>
                            <Col sm="12" className="mb-2">
                                <Label for="unique_identifier"><Translate id='addMemberModal.formik.label.uniqueIdentifier' /></Label>
                                <FormikInputText name="unique_identifier" id="unique_identifier" placeholder="ex: 4563485965" translatePlaceholder={false} trim />
                                <Collapse isOpen={!!formik.values.unique_identifier}>
                                    <span className="small text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='addMemberModal.formik.warning.filters.ignored' /></span>
                                </Collapse>
                            </Col>
                        </Row>
                        <Collapse isOpen={!!searchResults}>
                            { searchResults &&
                                <div className="pt-3 border-top">
                                    { searchResults?.length > 0 ?
                                        <>
                                            <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.existing' /></div>
                                            <Row form>
                                                { searchResults.map((result) => {
                                                    const isMeta = metaMembers?.find((_m) => _m.members?.[0]?.member_id === result.member_id);

                                                    return (
                                                        <Col sm="12" key={result.member_id}>
                                                            <UserDisplay card className="w-100 mb-2">
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        abbr={result.first_name.substring(0, 1) + result.last_name.substring(0, 1)}
                                                                        src={null}
                                                                        width={40}
                                                                        height={40}
                                                                        alt={result.first_name}
                                                                    />
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container>
                                                                    <div className="d-flex">
                                                                        <UserDisplay.Title className="mr-2">{result.first_name} {result.last_name}</UserDisplay.Title>
                                                                        <UserDisplay.Subtitle>({result.age} <Translate id='misc.yrs' />)</UserDisplay.Subtitle>
                                                                    </div>
                                                                    <UserDisplay.Subtitle>HCR#{result.unique_identifier}</UserDisplay.Subtitle>
                                                                    {!!result.organisation && <UserDisplay.Subtitle><DisplayI18n field='organisation_name' defaultValue={result.organisation.organisation_name} i18n={result.organisation.i18n} /></UserDisplay.Subtitle>}
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container className="ml-auto">
                                                                    { !isMeta ?
                                                                        <Button
                                                                            size="sm"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                if(formik.values.firstName && formik.values.unique_identifier){
                                                                                    formik.setFieldValue('firstName', result.first_name);
                                                                                    formik.setFieldValue('lastName', result.last_name);
                                                                                    formik.setFieldValue('dateOfBirth', result.birthdate);
                                                                                }

                                                                                setData({
                                                                                    firstName: result.first_name,
                                                                                    lastName: result.last_name,
                                                                                    member_id: result.member_id,
                                                                                    dateOfBirth: result.birthdate,
                                                                                });
                                                                                changeView(3)
                                                                            }}
                                                                            color="primary"
                                                                            outline
                                                                        >
                                                                            <Translate id='addMemberModal.button.add' />
                                                                        </Button>
                                                                        :
                                                                        <small className="text-muted">
                                                                            <Translate id='addMemberModal.searchResult.already' />
                                                                        </small>
                                                                    }
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </>
                                        :
                                        <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.noResult' /></div>
                                    }
                                </div>
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={isLoading} className="ml-auto" type="submit" color="primary"><Translate id='misc.search' /></Button>
                        <Button disabled={isLoading} type="button" color="primary" outline onClick={() => closeModal()}><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default AddMemberStep1;