import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Fade
} from "reactstrap";
import Required from '../../../../../../components/formik/Required';
import { Form, Formik, useFormikContext } from 'formik';
import { FormikTextArea, FormikDateTime, FormikError } from '@spordle/formik-elements';
import moment from 'moment';

import { TravelPermitAddContext } from './TravelPermitAddContext';
import { mixed, object } from 'yup';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import FileUpload from '../../../../../../components/uploader/FileUpload';
import { AccountsContext } from '../../../../../../contexts/contexts';
import { PeriodsContext } from '../../../../../../contexts/PeriodsContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { mbToBytes } from '../../../../../../components/uploader/uploadHelpers';
import { MembersContext } from '../../../../../../contexts/MembersContext';

const TravelPermitAdd2 = ({ previous, next, toggle }) => {

    const travelPermitAddContext = useContext(TravelPermitAddContext);
    const accountsContext = useContext(AccountsContext);
    const periodsContext = useContext(PeriodsContext);
    const orgContext = useContext(OrganizationContext);

    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <Formik
                initialValues={{
                    start_date: travelPermitAddContext.state.formData.start_date ? moment(travelPermitAddContext.state.formData.start_date) : '',
                    end_date: travelPermitAddContext.state.formData.end_date ? moment(travelPermitAddContext.state.formData.end_date) : '',
                    note: travelPermitAddContext.state.formData.note || '',
                    attachments: {},
                }}
                onSubmit={async(submitData) => {
                    setIsLoading(true);
                    travelPermitAddContext.setData({
                        start_date: submitData.start_date,
                        end_date: submitData.end_date,
                        note: submitData.note,
                        attachments: submitData.attachments,
                    })

                    periodsContext.getPeriods(orgContext.federation.organisation_id)
                        .then((periods) => {
                            accountsContext.createTeamTravelPermit(
                                { ...travelPermitAddContext.state.formData, ...submitData, start_date: submitData.start_date.format('YYYY-MM-DD'), end_date: submitData.end_date ? submitData.end_date.format('YYYY-MM-DD') : '' },
                                travelPermitAddContext.state.formData.teamOrgId,
                                periods.find((period) => period.active == 1 && period.current == 1)?.period_id,
                            )
                                .then((travelPermitId) => {
                                    travelPermitAddContext.setData({
                                        travelPermitId: travelPermitId,
                                    })
                                    setIsLoading(false);
                                    next();
                                })
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }}
                validationSchema={
                    object().shape({
                        start_date: mixed()
                            .required(<Translate id='account.members.travelPermits.addModal.startDateRequired' />)
                            .test({
                                name: 'start_date_format',
                                message: <Translate id='account.members.travelPermits.sidePanelGeneral.startDate.format' />,
                                test: moment.isMoment,
                            }),
                        end_date: mixed()
                            .test({
                                name: 'end_date_format',
                                message: <Translate id='account.members.travelPermits.sidePanelGeneral.endDate.format' />,
                                test: function(end_date){
                                    return end_date ? moment.isMoment(end_date) : true
                                },
                            }).test({
                                name: 'end_date_start_date_test',
                                message: <Translate id='account.members.travelPermits.sidePanelGeneral.startDate.before' />,
                                test: function(end_date){
                                    return moment(end_date).isAfter(moment(this.parent.start_date).subtract(1, 'day'))
                                },
                            }),
                    })}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Row form>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='start_date'><Translate id='account.members.travelPermits.addModal.startDate' /> <Required /></Label>
                                        <FormikDateTime name={`start_date`} id='start_date' timeFormat={false} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='end_date'><Translate id='account.members.travelPermits.addModal.endDate' /></Label>
                                        <FormikDateTime name={`end_date`} id='end_date' timeFormat={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for='note'><Translate id='account.members.travelPermits.addModal.notes' /></Label>
                                <FormikTextArea id='note' name='note' rows='3' />
                            </FormGroup>

                            <TravelPermitAttachmentsFromSettings />

                            <FormGroup>
                                <Label for='attachments'><Translate id='account.members.travelPermits.addModal.additionalAttachments' /> </Label>
                                <FileUpload
                                    defaultFiles={[]}
                                    onFileSelect={(files) => {
                                        formik.setFieldValue('attachments.null', files);
                                    }}
                                    dropzoneProps={{
                                        multiple: true,
                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                    }}
                                />
                                <FormikError name='attachments.null' />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={previous} outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button color='primary' type='submit' disabled={isLoading}>
                                <Translate id='misc.add' />
                            </Button>
                            <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </OverlayLoader>
    );
}

const TravelPermitAttachmentsFromSettings = () => {
    const { getDocumentTypes } = useContext(MembersContext);
    const travelPermitAddContext = useContext(TravelPermitAddContext);
    const formik = useFormikContext();

    const [ documentTypes, setDocumentTypes ] = useState([]);

    useEffect(() => {
        const settingDocumentTypes = travelPermitAddContext.state.settings?.attachment_for_travel_permit?.value || [];
        getDocumentTypes(travelPermitAddContext.state.formData.teamOrgId)
            .then((documents) => {
                const docs = settingDocumentTypes.map((documentTypeId) => (
                    documents.find(({ document_type_id }) => document_type_id === documentTypeId)
                )).filter((document) => !!document)
                setDocumentTypes(docs)
            })
            .catch(console.error);
    }, [])

    return (
        <Fade in={Array.isArray(documentTypes) && documentTypes.length > 0} mountOnEnter unmountOnExit>
            <div className="h5 font-medium"><Translate id='account.members.travelPermits.addModal.attachments' /></div>
            {documentTypes?.map((documentType) => (
                <FormGroup key={documentType.document_type_id}>
                    <Label for={'documentType_' + documentType.document_type_id}><DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} /></Label>
                    <FileUpload
                        id={'documentType_' + documentType.document_type_id}
                        dropzoneProps={{
                            multiple: true,
                            maxSize: documentType.file_size ? mbToBytes(parseFloat(documentType.file_size)) : undefined,
                            accept: "image/*, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                        }}
                        onFileSelect={(files) => {
                            formik.setFieldValue(`attachments.${documentType.document_type_id}`, files);
                        }}
                    />
                    {documentType.description &&
                        <div className='small text-muted'>
                            <DisplayI18n field='description' defaultValue={documentType.description} i18n={documentType.i18n} />
                        </div>
                    }
                </FormGroup>
            ))}
        </Fade>
    )
};

export default TravelPermitAdd2;