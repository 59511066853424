import Translate from "@spordle/intl-elements";
import GameIncidentTeamCard from "./components/GameIncidentTeamCard";

const GameIncidentTeamsTab = ({ gameIncident, updateValues }) => {
    return (
        <>
            <div className="font-bold h5">
                <Translate id='account.members.gir.addModal.steps.teams.title' />
            </div>
            <div className="mb-1">
                <Translate id='account.members.gir.addModal.teams.home.label' />
            </div>
            <GameIncidentTeamCard updateValues={updateValues} gameIncident={gameIncident} team={gameIncident.home_team} />

            <div className="mb-1">
                <Translate id='account.members.gir.addModal.teams.away.label' />
            </div>
            <GameIncidentTeamCard updateValues={updateValues} pos="away" gameIncident={gameIncident} team={gameIncident.away_team} />
        </>
    );
}

export default GameIncidentTeamsTab;