import { jsObjectToApi } from '@spordle/helpers';
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_PUBLIC from '../api/API-Public';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { serverError } from '../api/CancellableAPI';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<import('./MembersContextProvider').default, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const MembersContext = createContext();
MembersContext.displayName = 'MembersContext';

class MembersContextProvider extends React.Component{
    /**
     * [GET] Public call, gets all the members of an org
     * @returns {Promise}
     */
    getPublicMembers = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: '/public/members',
            query: Object.assign({
                organisation_id: queryParams.organisation_id ?? this.props.OrganizationContext.federation.organisation_id, // put fed Id here
                target_organisation_id: queryParams.organisation_id ?? this.props.OrganizationContext.federation.organisation_id, // put fed Id here
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data.members
                }
                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * Creates a Member under an Organization with specified values
     * @param {object} values Values to create a Member
     * @returns {Promise}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AcreateMember|documentation}
     */
    createMember = (values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', values.organizationId);
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('address2', values.address2);
        params.append('email', values.email);
        params.append('birthdate', values.dateOfBirth);
        params.append('gender', values.gender);
        params.append('source', "MANUAL");
        params.append('citizenship', values.nationality.toString());

        // Languages
        params.append('primary_language', values.primary_language);
        values.secondary_languages?.map((lang, key) => {
            params.append(`secondary_languages[${key}]`, lang);
        });

        // Google places
        params.append('address[0][default_address]', "1");
        params.append('address[0][address_type_id]', values.addressTypeId || ''); // Domicile, secondaire, appartement, bureau, etc
        params.append('address[0][street_number]', values.address.streetNumber || '');
        params.append('address[0][street]', values.address.address || '');
        params.append('address[0][city]', values.address.city || '');
        params.append('address[0][zip_code]', values.address.zip || '');
        params.append('address[0][country_division_code]', values.address.state || '');
        params.append('address[0][country_code]', values.address.country || 'CA');
        params.append('address[0][map_url]', values.address.mapsUrl || '');
        params.append('address[0][unit_number]', values.address2 || '');
        params.append('address[0][origin_address]', 'GOOGLE');

        return API_PUBLIC.post(queryString.stringifyUrl({ url: `members` }), params)
            .then((response) => {
                if(response.data.status){
                    return { member_id: response.data.member_id, unique_identifier: response.data.hcr_number }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AsearchMember|documentation}
     * @returns {Promise.<Array>}
     */
    getMembers = (orgId, queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: '/members',
            query: Object.assign({
                organisation_id: orgId,
                all: 1,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a list of the member's qualification
     * @param {string} memberId Member Id - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Qualifications/Apicontroller%5CMembers%5CMemberqualifications%3A%3AgetAllMemberQualifications|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberQualifications = (memberId) => (
        API_PUBLIC.get(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_qualifications;
                }
                throw response.data.errors[0]
            }, serverError)
    )

    /**
     * Get a list of the member's attachments
     * @promise
     * @param {string} memberId Member Id - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/68e1bd1275181fefdaeaac98e4c6dd9d|documentation}
     * @returns {Promise.<object[]>}
     * @throws {object}
     */
    getMemberAttachments = (memberId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/members/${memberId}/attachments`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getDocumentTypes = (orgId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/document-types`,
            query: {
                organisation_id: orgId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types;
                }
                throw response.data.errors[0]
            }, serverError)

    }

    /**
     * [POST] Create member attachments
     * @param {string} [memberId] ID of the member
     * @param {object} [values] Query params
     * @returns {Promise.<object[]>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AgetAllMemberAttachments|documentation}
     */
    createMemberAttachments = (memberId, values) => {
        const params = new FormData();

        params.append('attachments[0][attachment]', values.attachment)
        if(values.document_type_id) params.append('attachments[0][document_type_id]', values.document_type_id);
        if(values.note) params.append('attachments[0][note]', values.note);


        return API_PUBLIC_LOGGEDIN.post(`members/${memberId}/attachments`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get a list of the member's attachments
     * @promise
     * @param {string} memberId Member Id - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/400967945703d20a29481bf15a0356ad|documentation}
     * @param {string} memberAttachmentId
     * @returns {Promise.<string>}
     * @throws {object}
     */
    downloadMemberAttachments = (memberId, memberAttachmentId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/members/${memberId}/attachments/${memberAttachmentId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.full_path;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Delete a specific member attachment
     * @promise
     * @param {string} memberId Member Id - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/ff3c5d0fe02c6d60f2350eaf5b7bfd01|documentation}
     * @param {string} memberAttachmentId
     * @returns {Promise.<boolean>}
     * @throws {object}
     */
    deleteMemberAttachment = (memberId, memberAttachmentId) => {
        return API_PUBLIC_LOGGEDIN.delete(queryString.stringifyUrl({
            url: `/members/${memberId}/attachments/${memberAttachmentId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get a list of the member types
     * @param {string} memberId Member Id - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/0a577d6bcc221fd48b8536aea4318016|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberTypes = (memberId) => (
        API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/members/${memberId}/member-types`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_member_types;
                }
                throw response.data.errors[0]
            }, serverError)
    )

    /**
     * Retry an installment
     * @param {string} invoicePaymentId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/Apicontroller%5CFinances%5CPayments%3A%3AupdateExternalInstallmentRetry|documentation}
     * @returns {Promise.<boolean>}
     */
    retryInvoicePayment = (invoicePaymentId) => {
        return API_PUBLIC.patch(queryString.stringifyUrl({ url: `/payments/${invoicePaymentId}/retry` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Process a payment
     * @param {string} invoicePaymentId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/f940cf8bce85a804f4ace4320660bcc6|documentation}
     * @returns {Promise.<boolean>}
     */
    processPayment = (invoicePaymentId) => {
        return API_PUBLIC.patch(queryString.stringifyUrl({ url: `/payments/${invoicePaymentId}/process-payment` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get the status of changed cards in SportPay
     * @param {string} invoicePaymentCardId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/db42464f7f675a85b72b917d9e1aef2f|documentation}
     * @returns {Promise.<object>}
     */
    getCardAttachmentStatus = (invoicePaymentCardId) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/card-attachments/${invoicePaymentCardId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.card_attachments[0]
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Change SportPay cards
     * @param {string} invoicePaymentId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/78ec07bb6c978b714699510de199939c|documentation}
     * @param {string} url The referrer, will point back to this url when coming back from SportPay
     * @returns {Promise.<object>}
     */
    attachCardsPayment = (invoicePaymentId, url) => {
        const params = new URLSearchParams();
        params.append('redirect_url', url);

        return API_PUBLIC.patch(queryString.stringifyUrl({
            url: `/payments/${invoicePaymentId}/attach-cards`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.provider_return?.returned
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Update a member's missing fields
     * @param {object} memberId Member ID to update
     * @param {object} values Values to update a Member
     * @returns {Promise}
     */
    updateMemberMissingFields = (memberId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params, {
            skipEmptyString: true,
        });

        return API_PUBLIC.patch(queryString.stringifyUrl({ url: `members/${memberId}/missing-fields` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getWaivers = (memberId, queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `members/${memberId}/waivers`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waivers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getMemberContactTypes = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/member-contact-types`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_contact_types
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getPhoneTypes = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/phone-types`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.phone_types
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getMemberContacts = (memberId) => {
        return API_PUBLIC.get(`members/${memberId}/contacts`)
            .then((response) => {
                if(response.data.status){
                    const contacts = response.data.member_contacts?.map((contact) => ({
                        ...contact,
                        phones: contact.member_contact_phone,
                    }))
                    return contacts
                }
                throw response.data.errors[0];
            }, serverError)
    }

    createMemberContacts = (memberId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        // contacts.forEach((contact, index) => {
        //     params.append(`contacts[${index}][member_contact_type_id]`, contact.contactType?.memberContactTypeId || '');
        //     params.append(`contacts[${index}][first_name]`, contact.firstName);
        //     params.append(`contacts[${index}][last_name]`, contact.lastName);
        //     params.append(`contacts[${index}][email]`, contact.email || '');
        //     params.append(`contacts[${index}][emergency]`, !!contact.emergency >>> 0);

        //     contact.phones?.forEach((phone, indexPhone) => {
        //         params.append(`contacts[${index}][phones][${indexPhone}][phone_type_id]`, phone.phoneType.phoneTypeId);
        //         params.append(`contacts[${index}][phones][${indexPhone}][phone_number]`, phone.phoneNumber);
        //     })
        // })

        return API_PUBLIC.post(`members/${memberId}/contacts`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_contact_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets  suspension types
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Suspensions/cdf88716585cfab55de71c8df836bbba|documentation}
     * @returns {Promise<Array>}
     */
    getMemberSuspensions = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `members/${queryParams.member_id}/member-suspensions`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_suspensions
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets  suspension types
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Suspensions/cdf88716585cfab55de71c8df836bbba|documentation}
     * @returns {Promise<Array>}
     */
    getSuspensionTypes = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/suspensions`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_suspensions
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <MembersContext.Provider value={{ ...this }}>
                {this.props.children}
            </MembersContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(MembersContextProvider)