import Translate, { DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import HoverableComponent from '../../../../../../components/HoverableComponent';
import { copyToClipBoard } from '../../../../../../helpers/clipboardHelper';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const TransactionHeader = ({ hideHeader, transaction, ...props }) => {
    const history = useHistory();
    return (
        <>
            {(!hideHeader) &&
                <div className='d-flex mb-2 align-items-center justify-content-between'>
                    <button type='button' className='reset-btn p-2 text-dark' onClick={props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                    <div>
                        <Button color='primary' outline onClick={() => history.push(`/purchases/invoice/${transaction.invoice_number}`)}><Translate id='account.transactions.sidePanel.header.viewInvoice' /></Button>
                    </div>
                </div>
            }
            <div>
                <div className="d-flex">
                    <div className='font-bold h4 mb-0 mr-1'><Translate id='account.transactions.sidePanel.header.invoice' /></div>
                    <Badge color={(transaction.status === 'COMPLETED') ? 'success' : (transaction.status === 'PENDING' || transaction.status === 'NEED_REVIEW' || transaction.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                        <Translate id={'account.transactions.sidePanel.status.' + transaction.status} />
                    </Badge>
                </div>
                <div className="text-link d-block">
                    <HoverableComponent>
                        {(isHovering) => <div><Link to={`/purchases/invoice/${transaction.invoice_number}`}>#{transaction.invoice_number}</Link> <i className={stringBuilder('mdi mdi-content-copy clickable ml-1', { 'invisible': !isHovering })} onClick={() => { copyToClipBoard(transaction.invoice_number) }} /></div>}
                    </HoverableComponent>
                </div>
                <div className="text-muted">
                    <Translate id='account.transactions.memberProfileTransactions.tableView.header.paymentMethod' />: <DisplayI18n field='name' defaultValue={transaction.invoice_payment_method?.name} i18n={transaction.invoice_payment_method?.i18n} />
                </div>
                <div className="text-muted">
                    <div>
                        <Translate id={`account.transactions.sidePanel.paymentType.${transaction.origin === "ADMIN" ? 'manual' : 'online'}`} />
                    </div>
                    <div>
                        <Translate id='account.transactions.sidePanel.header.initialPaymentDate' />: <DateFormat value={transaction.invoice_date} />
                    </div>
                    {transaction.organisation &&
                        <div>
                            <DisplayI18n field='name' defaultValue={transaction.organisation.organisation_name} i18n={transaction.organisation.i18n} />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

TransactionHeader.propTypes = {
    hideHeader: PropTypes.bool,
    transaction: PropTypes.object.isRequired,
};

export default TransactionHeader;