import { useContext } from 'react';
import EventList from '../events/EventList';
import moment from 'moment';
import { ScheduleContext } from '../Schedule';
import SharedEvents from '../events/SharedEvents';

const WeeklyHeaderDay = ({ shortDays, day, ...props }) => {
    return (
        <div {...props}>
            <h3 className="schedule-weekly-weekday">
                { shortDays ?
                    <span>{moment.weekdaysMin(day)}</span>
                    :
                    <>
                        <span className="d-none d-xl-inline">{day.format('dddd')}</span>
                        <span className="d-inline d-xl-none">{moment.weekdaysMin(day)}</span>
                    </>
                }
            </h3>
            <time dateTime={day.format('YYYY-MM-DD')} className="schedule-weekly-day-nb">
                {day.format('D')}
            </time>
        </div>
    )
};

/**
 * @description Displays weekly calendar.
 *
 * @param {string} [className] className of container
 * @param {boolean} [shortDays=false] Will only display short days labels
 * @returns {JSX.Element}
 */
const ScheduleWeekly = ({ className, hideShared = false, shortDays = false }) => {
    const { week, selected, today, onEventClick, setNewDate } = useContext(ScheduleContext);

    function filterWeek(){
        const headers = [];
        const sections = [];

        for(let i = 0; i < week.length; i++){
            const day = week[i];
            const dayString = day.format('L');
            const date = day.format('D');
            const commonClasses = `${today.format('L') === dayString ? 'is-today ' : ''}${dayString === selected?.selected?.format('L') ? 'is-selected ' : ''}schedule-weekly-`;

            headers.push(
                <WeeklyHeaderDay
                    onClick={() => setNewDate(day)}
                    day={day}
                    shortDays={shortDays}
                    key={`weekly-weekday-${date}`}
                    className={`${commonClasses}days`}
                />,
            )

            sections.push(
                <section
                    tabIndex="0"
                    key={`weekly-${dayString}`}
                    className={`${commonClasses}item`}
                >
                    <div className="schedule-weekly-events-container d-none d-lg-block">
                        <EventList
                            showRecurrent={false}
                            momentObj={day}
                            date={date}
                            type='weekly'
                            itemClickCallback={onEventClick}
                            dep={selected.week}
                        />
                    </div>
                </section>,
            )
        }

        return { sections: sections, headers: headers };
    }

    const { sections, headers } = filterWeek();

    return (
        <section className={`schedule-weekly-weekdays-section ${className || ''}`}>
            <header className="schedule-weekly-weekdays-container">
                {headers}
            </header>
            <div className="schedule-weekly-sections-container min-height flex-grow-1">
                {!hideShared && <SharedEvents />}
                <div className="schedule-weekly-days-container h-100">
                    {sections}
                </div>
            </div>
        </section>
    );
}

export default ScheduleWeekly;