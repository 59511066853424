import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

const WithHcrTheme = (Component) => (props) => {
    const themeContext = useContext(ThemeContext)
    useEffect(() => {
        themeContext.setTheme('HCR');
    }, [])
    return <Component {...props} />
}

export default WithHcrTheme;