export default{
    "failedPaymentModal.modal.title"    : "Oops!...",
    "failedPaymentModal.modal.subtitle" : "One of your payment has failed.",
    "failedPaymentModal.modal.text"     : "To avoid any disruption of your inscription, please update your billing information or contact us.",
    "failedPaymentModal.modal.btn"      : "Manage Payment",
    "failedPaymentModal.tooltip"        : "Declined payment",
    "missingPayment.tooltip"            : "Missing payment",

    "preinitModal.modal.title"           : "New registration!",
    "preinitModal.modal.text"            : "<b>{org}</b> has registered <b>{name}</b> to <b>{registration}</b>. Please pay.",
    "preinitModal.modal.text.otherItems" : "Other items have been purchased for <b>{name}</b> by <b>{org}</b>. Please pay.",
    "preinitModal.modal.btn"             : "Pay Now",

    "unsignedWaiverModal.modal.title"    : "Warning!",
    "unsignedWaiverModal.modal.subTitle" : "You have {waiversAmount} unsigned waiver(s) for the invoice #{invoiceNumber}",
    "unsignedWaiverModal.modal.btn"      : "Sign waiver",

    "waitingListItemsModal.modal.title"                 : "Congratulations!",
    "waitingListItemsModal.modal.text1.clinic"          : "Your member has been selected to participate in the following clinic",
    "waitingListItemsModal.modal.text1.registration"    : "Your member has been selected to participate in",
    "waitingListItemsModal.modal.text2"                 : "You can continue and complete the registration for {firstName} {lastName} or cancel it.",
    "waitingListItemsModal.modal.btn"                   : "Complete registration",
    "waitingListItemsModal.modal.header"                : "Cancellation",
    "waitingListItemsModal.modal.cancelReason"          : "Cancellation reason",
    "waitingListItemsModal.modal.cancelReason.required" : "Cancellation reason is required",
    "waitingListItemsModal.modal.toast"                 : "Registration cancelled",

    "unansweredFormsModal.modal.title"                 : "Unanswered questionnaires(s)",
    "unansweredFormsModal.modal.subTitle"              : "Your member(s) have {transactionCount, plural, one {# transaction} other {# transactions}} with unanswered questionnaires",
    "unansweredFormsModal.modal.answer"                : "Answer",
    "unansweredFormsModal.modal.forms.transaction"     : "Transaction",
    "unansweredFormsModal.modal.confirmation.title"    : "Confirmation",
    "unansweredFormsModal.modal.confirmation.subtitle" : "All questionnaires answered",

    "missingInformationModal.title" : "Required information is missing for the following {amount, plural, one {member} other {members}}.",
    "missingInformationModal.view"  : "View",
}
