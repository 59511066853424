import { addBreadcrumb, Severity } from "@sentry/react";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import ToPrint from '@spordle/print';
import queryString from 'query-string';
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Button, Card, CardBody, Col, Collapse, Container, Fade, Row, Spinner, Table } from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import HCLogo from '../../assets/images/logos/HC.png';
import SpordleLogo from '../../assets/images/spordleSuite/GraySpordle.png';
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { UtilsContext } from "../../contexts/UtilsContext";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { fail } from "@spordle/toasts";
import { groupByAsArray } from "@spordle/helpers";
import moment from "moment";
import { AccountsContext, AuthContext } from "../../contexts/contexts";

const Receipt = ({ ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ invoice, setInvoice ] = useState(false);
    const [ org, setOrg ] = useState(false);

    const utilsContext = useContext(UtilsContext);
    const accountsContext = useContext(AccountsContext);
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);

    // print
    const printContainer = useRef(null);

    const location = useLocation();
    const parsedSearch = queryString.parse(location.search)
    const invoiceNb = parsedSearch.invoiceNumber;
    const paymentReceptionId = parsedSearch.paymentReceptionId;
    const paymentId = parsedSearch.paymentId;

    const formatInvoice = (inv) => {
        const { selected, alreadyPaidAmount } = getAlreadyPaidAmount(inv)
        return ({
            ...inv,
            affiliationFees: inv.invoice_items.filter((item) => item.affiliation_fee !== null),
            creditAmount: calcAmount(inv.credits, 'amount'),
            rebateAmount: calcAmount(inv.invoice_items, 'rebate_amount'),
            rebateData: inv.invoice_items.find((item) => item.rebate !== null)?.rebate,
            members: groupByAsArray(inv.invoice_items.map((item) => ({ ...item, memberId: item.member.member_id })), 'memberId'),
            receiptDate: selected.date,
            receiptPaymentMethod: selected.paymentMethod,
            paidAmount: selected.amount,
            alreadyPaidAmount: alreadyPaidAmount,
            balance: parseInt(inv.total_amount) - alreadyPaidAmount - selected.amount,
        })
    };

    const getAlreadyPaidAmount = (invoice) => {
        const receiptPayments = [];
        invoice.payment_receptions?.forEach((paymentReception) => {
            receiptPayments.push({
                amount: parseInt(paymentReception.amount),
                date: paymentReception.received_at,
                selected: paymentReception.payment_reception_id === paymentReceptionId,
                paymentMethod: paymentReception.payment_method?.code,
            })
        })

        invoice.invoice_items.forEach((invoiceItem) => {
            if(!invoiceItem.affiliation_fee){
                invoiceItem.payments_to_display.forEach((paymentToDisplay) => {
                    if(paymentToDisplay.payment_method?.code === 'CREDITCARD' && paymentToDisplay.status === 'COMPLETED'){
                        // yeah, idk
                        // does it look like I know what im doing
                        receiptPayments.push({
                            amount: parseInt(paymentToDisplay.amount),
                            date: paymentToDisplay.payment_date,
                            selected: paymentToDisplay.invoice_payment_id === paymentId,
                            paymentMethod: paymentToDisplay.payment_method,
                        })
                    }
                })
            }
        })

        invoice.refunds?.forEach((refund) => {
            if(refund.status === 'COMPLETED'){
                receiptPayments.push({
                    amount: parseInt(refund.amount) * -1, // because we want to subtract the refund amount to the already paid amount
                    date: refund.refund_date,
                    selected: false,
                    paymentMethod: refund.refund_method,
                })
            }
        })

        receiptPayments.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? -1 : 1)
        const selectedIndex = receiptPayments.findIndex((p) => p.selected)
        const selected = receiptPayments[selectedIndex]
        const alreadyPaidAmount = receiptPayments.slice(0, selectedIndex).reduce((sum, payment) => {
            if(payment)
                return sum += payment.amount;
            return sum
        }, 0)

        return { selected: selected, alreadyPaidAmount: alreadyPaidAmount + getCreditAmount(invoice) }
    }

    const getCreditAmount = (invoice) => {
        return invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);
    }

    /**
     * Returns the sum of a field in an array
     * @param {array} arr
     * @param {string} field
     * @returns {number}
     */
    const calcAmount = (arr, field) => (
        arr.reduce((sum, item) => sum += parseInt(item[field]), 0)
    );

    useEffect(() => {
        if(invoiceNb){
            getInvoice()
        }
    }, [ invoiceNb ]);

    const getInvoice = () => {
        setIsLoading(true);
        return accountsContext.getAccountInvoices(authContext.userData.Username)
            .then(async(invoices) => {
                const invoice = invoices.find((inv) => inv.invoice_number === invoiceNb)
                if(invoice){
                    const formattedInvoice = formatInvoice(invoice)
                    setInvoice(formattedInvoice);

                    const promises = await Promise.all([
                        //orgContext.getOrganization(invoice.invoice_items.find(item => item.registration_fee != null || item.clinic != null || item.other_fee != null)?.organisation?.organisation_id),
                        invoice.country_id
                            ?
                            utilsContext.getProvinces(invoice.country_id)
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        });
                                    }
                                })
                            :
                            Promise.resolve(),
                        utilsContext.getPaymentMethods().catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        }),
                        orgContext.setCurrentOrg(invoice.organisation.organisation_id),
                    ]);

                    setOrg(orgContext.state);

                    setInvoice((prev) => ({
                        ...prev,
                        ...(invoice.country_id && { country: promises[0]?.[0]?.code }),
                        paymentMethod: promises[1].find((m) => m.code === formattedInvoice.receiptPaymentMethod),
                    }));

                }else{
                    setInvoice('none');
                }
                setIsLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setInvoice('none');
                    setIsLoading(false);
                }
            })
    }

    return (
        <div className="page-content" ref={printContainer}>
            {/* start receipt */}
            <Container>
                <Card className="card-shadow">
                    <CardBody>
                        {!isLoading ?
                            <Collapse isOpen={!isLoading}>
                                { invoice && invoice !== "none" &&
                                    <Fade>
                                        <div className="clearfix">
                                            {org.logo && org.abbreviation !== "HC" && <img className="float-left mr-2" src={org.logo?.full_path} alt={org.organisation_name} width="50" />}
                                            <img className="float-left" src={HCLogo} alt="Hockey Canada" width="50" />
                                            <ToPrint
                                                contentRef={printContainer.current}
                                                documentTitle={invoice.invoice_number}
                                                onBeforeGetContent={() => {
                                                    addBreadcrumb({ message: 'Before invoice print', level: Severity.Info, category: 'info', type: 'info' })
                                                }}
                                            >
                                                {(handlePrint) => (
                                                    <Button onClick={handlePrint} color="primary" className='ml-2 float-right d-print-none'><Translate id='misc.print' /></Button>
                                                )}
                                            </ToPrint>
                                        </div>
                                        <div className="border-top border-bottom py-3 my-3">
                                            <div className='clearfix'>
                                                <div className='float-left'>
                                                    <span className="font-bold text-dark"><Translate id='invoice.date' />: </span>
                                                    <span>
                                                        <DateFormat value={invoice.receiptDate} />
                                                    </span>
                                                </div>
                                                <div className='invoice-info-right'>
                                                    <span className="font-bold text-dark"><Translate id='invoice.invoiceNo' />: </span>
                                                    <span>{invoice.invoice_number}</span>
                                                    {invoice.paymentMethod &&
                                                        <div>
                                                            <span className="font-bold text-dark"><Translate id='invoice.paymentMethod' />: </span>
                                                            <DisplayI18n field="name" i18n={invoice.paymentMethod.i18n} defaultValue={invoice.paymentMethod.name} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='float-left mb-3'>
                                            <div className="mb-2">
                                                <div className="mb-2">
                                                    <span className='font-bold text-dark'>
                                                        <Translate id='invoice.payedBy' />
                                                    </span>
                                                </div>
                                                {invoice.identity ?
                                                    <div>{invoice.identity.name} {invoice.identity.family_name}</div>
                                                    :
                                                    invoice.invoice_items[0]?.member && <div>{invoice.invoice_items[0]?.member.first_name} {invoice.invoice_items[0]?.member.last_name}</div>
                                                }
                                                <div>
                                                    {invoice.street_number && <span>{invoice.street_number}, </span>}
                                                    {invoice.street && <span>{invoice.street}</span>}
                                                </div>
                                                {invoice.unit_number && <div><span>{invoice.unit_number}</span></div>}
                                                <div>
                                                    {invoice.city && <span>{invoice.city}, </span>}
                                                    {invoice.province_id && <span>{invoice.province_id}, </span>}
                                                    {invoice.country && <span>{invoice.country}</span>}
                                                </div>
                                                {invoice.zip_code && <div>{invoice.zip_code}</div>}
                                                <DisplayPhoneNumber phoneString={invoice.phone_number} format='INTERNATIONAL' />
                                                {invoice.invoice_items[0]?.member && <div><a href={`mailto:${invoice.invoice_items[0]?.member.email}`}>{invoice.invoice_items[0]?.member.email}</a></div>}
                                            </div>

                                            <div className="font-bold text-dark mb-2"><Translate id='invoice.receipt.members' /></div>
                                            {invoice.members.map((member) => (
                                                <div key={member.values[0].member.member_id}>{member.values[0].member.first_name} {member.values[0].member.last_name} #{member.values[0].member.unique_identifier}</div>
                                            ))}
                                        </div>
                                        <div className='invoice-info-right mb-3'>
                                            <div className="font-bold text-dark mb-2"><Translate id='invoice.organization' /></div>
                                            <div>
                                                <DisplayI18n field="name" i18n={org.i18n} defaultValue={org.organisation_name} />
                                            </div>
                                            {org.street && <div>{org.street_number} {org.street}</div>}
                                            {org.unit_number && <div><span>{org.unit_number}</span></div>}
                                            {org.city && <div>{org.city}, {org.province_code}, {org.country_code} </div>}
                                            {org.zip_code && <div>{org.zip_code}</div>}
                                            {org.organisation_email && <div><a href={`mailto:${org.organisation_email}`}>{org.organisation_email}</a></div>}

                                            <DisplayPhoneNumber phoneString={org.phone} format='INTERNATIONAL' emptyValue='-' />
                                        </div>

                                        <Table size="sm" className="mb-5">
                                            <tbody>
                                                <tr>
                                                    <td className="font-bold text-dark border-top-0"><Translate id='invoice.receipt.total' /></td>
                                                    <td className="text-right border-top-0"><CurrencyFormat value={parseInt(invoice.total_amount) / 100} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold text-dark"><Translate id='invoice.receipt.alreadyPaid' /></td>
                                                    <td className="text-right"><CurrencyFormat value={invoice.alreadyPaidAmount / 100} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold text-dark"><Translate id='invoice.receipt.paid' /></td>
                                                    <td className="text-right"><CurrencyFormat value={invoice.paidAmount / 100} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold text-dark border-bottom"><Translate id='invoice.receipt.balance' /></td>
                                                    <td className="text-right border-bottom"><CurrencyFormat value={invoice.balance / 100} /></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Fade>
                                }
                                { invoice && invoice === "none" &&
                                    <div>
                                        <div className="font-bold h4 mb-2"><Translate id='invoice.error.title' /></div>
                                        <p><Translate id='invoice.error.message' /></p>
                                    </div>
                                }
                                <Row className="align-items-center">
                                    <Col sm="6" className="mb-2">
                                        <span className="small"><Translate id='invoice.table.poweredBy' values={{ year: new Date().getFullYear() }} /></span>
                                    </Col>
                                    <Col sm="6" className="mb-2 text-right">
                                        <img src={SpordleLogo} alt="Spordle" />
                                    </Col>
                                </Row>
                            </Collapse>
                            :
                            <div className="text-center py-4"><Spinner type="grow" color="primary" /></div>
                        }
                    </CardBody>
                </Card>
            </Container>
            {/* end receipt */}
        </div>
    )
}

export default Receipt;