import { useContext, useEffect, useState } from "react";
import AccountMemberSupervisionsOfficiatingCoach from "./supervisionsOfficiatingCoach/AccountMemberSupervisionsOfficiatingCoach";
import AccountMemberSupervisionsOfficial from "./supervisionsOfficial/AccountMemberSupervisionsOfficial";
import { OfficiatingCoachContext } from "../../../../contexts/OfficiatingCoachContext";
import { SupervisionsContext } from "../../../../contexts/SupervisionsContext";

const AccountMemberCoach = ({ member }) => {
    const { getMemberOfficiatingCoachList } = useContext(OfficiatingCoachContext);
    const { getSupervisionsList } = useContext(SupervisionsContext);
    const [ officiatingData, setOfficiatingData ] = useState({
        officiatingCoachList: null,
        officialList: null,
        reports: {},
    });

    function fetchAll(){
        setOfficiatingData({
            officiatingCoachList: null,
            officialList: null,
            reports: {},
        });

        Promise.all([
            getSupervisionsList({ member_id: member.member_id }),
            getMemberOfficiatingCoachList(member.member_id, { current_period_only: 1 }),
        ])
            .then(([ supervisionsList, [ officiatingInfo ] ]) => {
                const { coach, official } = supervisionsList.reduce((filtered, supervision) => {
                    if(supervision.official.member_id === member.member_id){
                        filtered.official.push(supervision);
                    }else{
                        filtered.coach.push(supervision);
                    }

                    return filtered;

                }, { coach: [], official: [] })

                setOfficiatingData((prev) => ({
                    ...prev,
                    officiatingCoachList: coach,
                    officialList: official,
                    reports: {
                        ...officiatingInfo,
                    },
                }))
            })
            .catch(console.error)
    }

    useEffect(() => {
        fetchAll();
    }, [ member.member_id ])

    return (
        <>
            <AccountMemberSupervisionsOfficial refreshAll={fetchAll} supervisions={officiatingData.officialList} member={member} />
            {Array.isArray(officiatingData.reports?.supervision_types) && officiatingData.reports.supervision_types.length > 0 &&
                <AccountMemberSupervisionsOfficiatingCoach refreshAll={fetchAll} supervisions={officiatingData.officiatingCoachList} reports={officiatingData.reports} member={member} />
            }
        </>
    );
}

export default AccountMemberCoach;