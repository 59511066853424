import Translate from "@spordle/intl-elements";
import { useHistory } from "react-router-dom"
import ConfirmModal from "../../confirmModal/ConfirmModal";

const UnsignedWaiverModal = ({ unsignedWaiver }) => {
    const history = useHistory();

    return (
        <ConfirmModal
            hideCancel defaultIsOpen hideToggler
            color='warning'
            modalTitle={<div className="h1 font-bold text-warning"><Translate id='unsignedWaiverModal.modal.title' /></div>}
            modalContent={
                <p className="font-bold">
                    <Translate id='unsignedWaiverModal.modal.subTitle' values={{ waiversAmount: unsignedWaiver.values.length, invoiceNumber: unsignedWaiver.key }} />
                </p>
            }
            actionButtonText={<><Translate id='unsignedWaiverModal.modal.btn' /> <i className="ml-1 mdi mdi-arrow-right" /></>}
            onConfirm={() => history.push(`/purchases?search=${unsignedWaiver.key}&tab=waivers`)}
        />
    )
}

export default UnsignedWaiverModal;