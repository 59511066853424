import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import AddMemberModal from "../../../../components/addMember/AddMemberModal";
import UserImg from "../../../../components/UserImg";

const MembersNav = ({ changeMember, members, getMetaMembers, currentMember, teams, getCurrentMemberTeams }) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    return (
        <>
            <div className="mb-5 d-flex justify-content-start align-items-center">
                <ul className="list-unstyled mb-0 d-flex justify-content-start align-items-center flex-wrap">
                    <li className="mr-2 mb-2 clickable">
                        <Tooltip
                            label={<Translate id='account.members.addMember.tooltip' />}
                        >
                            <button
                                type="button"
                                onClick={() => setModalIsOpen(true)}
                                className="border-primary rounded-circle reset-btn fas fa-user-plus font-22 text-primary"
                                style={{ height: 50, width: 50, borderWidth: 1, borderStyle: 'dashed' }}
                            />
                        </Tooltip>
                    </li>
                    {members === null ?
                        <>
                            <li className="mr-2 mb-2 clickable">
                                <Skeleton circle height={50} width={50} className="shadow-sm border" />
                            </li>
                            <li className="mr-2 mb-2 clickable">
                                <Skeleton circle height={50} width={50} className="shadow-sm border" />
                            </li>
                        </>
                        :
                        members?.map((member) => {
                            const missingFields = member.members?.[0]?.missing_required_fields?.length > 0;
                            const missingDocuments = member.members?.[0]?.need_address_document == 1;
                            const unsignedWaivers = member.members?.[0]?.unsigned_critical_waivers == 1;
                            const isMissing = missingFields || missingDocuments || unsignedWaivers

                            return (
                                <li
                                    title={member.first_name + ' ' + member.last_name}
                                    className='mr-2 mb-2 clickable'
                                    onClick={() => {
                                        changeMember({ ...member, teams: getCurrentMemberTeams(member) })
                                    }}
                                    key={member.meta_member_id}
                                >
                                    <Tooltip
                                        label={
                                            <>
                                                {member.first_name + ' ' + member.last_name}
                                                {isMissing &&
                                                    <ul className="mb-0">
                                                        {missingFields &&
                                                            <li><Translate id='account.members.missingFields.tooltip' /></li>
                                                        }
                                                        {missingDocuments &&
                                                            <li><Translate id='account.members.missingAddressDocument.tooltip' /></li>
                                                        }
                                                        {unsignedWaivers &&
                                                            <li><Translate id='account.members.unsignedCriticalWaiver.tooltip' /></li>
                                                        }
                                                    </ul>
                                                }
                                            </>
                                        }
                                        zIndex={2000}
                                        style={{ display: 'inherit' }} // because image would go nuts as a child of anything but display list-item
                                    >
                                        <div className="position-relative">
                                            { currentMember?.meta_member_id == member.meta_member_id ?
                                                <UserImg
                                                    width={50}
                                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                    className='border-primary shadow-primary'
                                                    src={currentMember.attachment?.full_path}
                                                    filePos={currentMember.attachment?.file_position}
                                                    alt={member.first_name + ' ' + member.last_name}
                                                />
                                                :
                                                <UserImg
                                                    width={50}
                                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                    className='shadow-sm'
                                                    src={member.attachment?.full_path}
                                                    filePos={member.attachment?.file_position}
                                                    alt={member.first_name + ' ' + member.last_name}
                                                />
                                            }
                                            {isMissing &&
                                                <i
                                                    style={{ height: 16, width: 16 }}
                                                    className="mdi border border-white d-flex flex-center mdi-alert-circle-outline font-16 text-danger position-absolute top-0 right-0 bg-light-danger rounded-circle"
                                                />
                                            }
                                        </div>
                                    </Tooltip>
                                </li>
                            )
                        })}
                </ul>
            </div>
            <AddMemberModal
                getMetaMembers={getMetaMembers}
                metaMembers={members}
                isOpen={modalIsOpen}
                toggle={() => setModalIsOpen(false)}
                changeMember={changeMember}
            />
        </>
    );
}

export default MembersNav;