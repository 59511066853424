export default{
    "confirm-account.title" : "Veuillez entrez le code de vérification envoyé à",

    // Action
    "confirm-account.action.verify" : "Verifier",

    // Loading
    "confirm-account.loading.title"       : "Confirmation",
    "confirm-account.loading.description" : "Vous serez redirigé sous peu ...",

    // Success
    "confirm-account.success.title" : "Compte confirmé",

    // Failed
    "confirm-account.failed.title"                 : "Oups, une erreur s'est produite!",
    "confirm-account.failed.action"                : "Retour à l'accueil",
    "confirm-account.error.resendEmail"            : "Oups, impossible d'envoyer un e-mail!",
    "confirm-account.error.CodeMismatchException"  : "Le code de vérification est invalide. Veuillez réessayer.",
    "confirm-account.error.ExpiredCodeException"   : "Le code de vérification est expiré. Veuillez réessayer.",
    "confirm-account.error.LimitExceededException" : "La limite de tentatives a été atteinte. Veuillez réessayer plus tard.",
    "confirm-account.error.server"                 : "Un problème est survenu. Veuillez contacter le support technique.",
    "confirm-account.error.204"                    : "Un problème est survenu. Veuillez contacter le support technique.",
    "confirm-account.error.2000"                   : "Un problème est survenu. Veuillez contacter le support technique.",
    "confirm-account.error.AliasExistsException"   : "Un compte avec ce courriel existe déjà.",
}