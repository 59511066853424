/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsx,Fragment}from'react/jsx-runtime';import {useState,useEffect,memo}from'react';import {getTelHref,formatPhoneNumber}from'./phoneUtils.js';/**
 * @typedef {'E164'|'RFC3966'|'NATIONAL'|'INTERNATIONAL'} PhoneNumberFormats
 */
/**
 * @hook useTelHref
 * @description Custom react hook to use a href tel action phone number format
 * @param {string|undefined} phoneString The phone-like string
 * @returns {string} The {@link https://datatracker.ietf.org/doc/html/rfc3966|RFC3966} formatted string
 * @example
 * const telValue = useTelHref(phoneString);
 * @copyright Spordle
 */
const useTelHref = (phoneString) => {
    const [value, setValue] = useState('');
    useEffect(() => {
        getTelHref(phoneString).then((telString) => {
            setValue(telString || '');
        }, () => {
            setValue('');
        });
    }, [phoneString]);
    return value;
};
/**
 * @hook useFormatPhoneNumber
 * @description Custom react hook to get the formatted phone number string
 * @param {string|undefined} phoneString The phone-like string
 * @param {PhoneNumberFormats} [format='E164']
 * @param {boolean} [withExtension] Display using the extension or not
 * @returns {string} The string formatted according to the requested format
 * @example
 * const formattedPhoneString = useFormatPhoneNumber(phoneString, 'E164');
 * const formattedPhoneString = useFormatPhoneNumber(phoneString, 'RFC3966', false);
 * const formattedPhoneString = useFormatPhoneNumber(phoneString, 'INTERNATIONAL');
 * const formattedPhoneString = useFormatPhoneNumber(phoneString, 'NATIONAL', true);
 * @copyright Spordle
 */
const useFormatPhoneNumber = (
/**
 * The phone-like string
 */
phoneString, 
/**
 * The format to show
 * @default 'E164'
 */
format = 'E164', 
/**
 * Format using the extension or not
 */
withExtension) => {
    const [value, setValue] = useState('');
    useEffect(() => {
        formatPhoneNumber(phoneString, format, withExtension).then((phoneValue) => {
            setValue(phoneValue || '');
        }, () => {
            setValue('');
        });
    }, [phoneString]);
    return value;
};
/**
 * @member TelHref
 * @description React component to display a anchor tag (`<a></a>`) with the `href` formatted for `tel:`
 * @prop {string|undefined} phoneString The phone-like string
 * @prop {React.ElementType} [tag]
 * @example
 * <TelHref phoneString={this.props.OrganizationContext.phone}>
 *     Call the number
 * </TelHref>
 * @example
 * <TelHref phoneString={this.props.OrganizationContext.phone}>
 *     <DisplayPhoneNumber phoneString={this.props.OrganizationContext.phone} format='INTERNATIONAL' emptyValue='-' />
 * </TelHref>
 * @copyright Spordle
 */
const TelHref = memo(({ phoneString, tag, ...props }) => {
    const telValue = useTelHref(phoneString);
    const Tag = tag || 'a';
    return (jsx(Tag, { ...props, href: telValue, children: props.children }));
});
TelHref.displayName = 'TelHref';
/**
 * @member DisplayPhoneNumber
 * @description React component to display the formatted phone number according to the requested format
 * @prop {string|undefined} phoneString The phone-like string
 * @prop {PhoneNumberFormats} [format='E164']
 * @prop {boolean} [withExtension] Display using the extension or not
 * @prop {React.ReactNode} [emptyValue] The react element to display if the string is empty
 * @example
 * <DisplayPhoneNumber phoneString={this.props.OrganizationContext.phone} format='INTERNATIONAL' />
 * @copyright Spordle
 */
const DisplayPhoneNumber = memo(({ phoneString, format = 'E164', emptyValue, withExtension }) => {
    const phone = useFormatPhoneNumber(phoneString, format, withExtension);
    return jsx(Fragment, { children: phone || emptyValue });
});
DisplayPhoneNumber.displayName = 'DisplayPhoneNumber';export{DisplayPhoneNumber,TelHref,useFormatPhoneNumber,useTelHref};
/*
@Copyrights Spordle 2023 - All rights reserved
*/