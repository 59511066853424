// Components
import AntiSpam from '@spordle/anti-spam';
import CapsLock from '@spordle/capslock';
// Language
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import React from 'react';
import PinField from 'react-pin-field';
import { Link } from 'react-router-dom';
import {
    Alert, Button, Card,
    CardBody, Collapse, FormGroup, Spinner
} from 'reactstrap';
import * as Yup from 'yup';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import SpordleLogo from '../../assets/images/logos/spordleMyAccount.svg';
import { AuthenticatorContext } from '../../components/authentication/AuthenticatorProvider';
import WithHcrTheme from '../../components/HOC/WithHcrTheme';
import FormikInputAuth from '../../components/loginInputs/FormikInputAuth';
import { fail, fire, success } from '@spordle/toasts';
// Context Providers
import { AuthContext } from "../../contexts/contexts";
import withContexts from '../../helpers/withContexts';
import AuthLayout from '../../layouts/layout-components/auth/AuthLayout';


class ForgotPassword extends React.Component{
    componentDidMount(){
        if(!this.props.location.newPasswordRequired){
            if(!this.props.AuthenticatorContext.email){
                this.props.history.replace(this.props.AuthenticatorContext.goToPage('LOGIN', true, true));
            }else{
                this.sendRecoveryEmail();
            }
        }
    }


    componentWillUnmount(){
        AxiosCancelAll();
    }

    sendRecoveryEmail = () => {
        if(!this.props.location.newPasswordRequired)
            this.props.AuthContext.sendRecoveryEmail(this.props.AuthenticatorContext.email)
                .then(() => {
                    success({ msg: 'login.form.action.send_email.success' });
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        fail({ msg: `recovery.error.${error.message}` });
                    }
                })
    }

    render(){
        return (
            <AuthLayout>
                <Card className="card-shadow">
                    <CardBody>
                        <div className="ml-n2 mt-n2">
                            <Link to={this.props.AuthenticatorContext.goToPage('LOGIN', true, { show: 'password' })} className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id='forgotPassword.action.backToLogin' /></Link>
                        </div>

                        <div className="text-center">
                            <img className="mb-3 mt-2" src={SpordleLogo} width="80%" alt="Hockey Canada" />
                            <h1 className="h2 font-medium mb-3"><Translate id='forgotPassword.title.accountRecovery' /></h1>
                            {!this.props.location.newPasswordRequired &&
                                <div className="text-muted mb-3">
                                    <div><Translate id='forgotPassword.success.emailWasSent' />:</div>
                                    <div>{this.props.AuthenticatorContext.email}</div>
                                </div>
                            }
                        </div>
                        <Formik
                            initialValues={{
                                code: '',
                                recoveryPassword: '',
                                confirmPassword: '',
                            }}
                            validationSchema={Yup.object().shape({
                                recoveryPassword: Yup.string().cognitoPassword(),
                                confirmPassword: Yup.string()
                                    .test('recoveryPassword-match', <Translate id='recovery.validation.passwordConfirm.match' />, function(value){
                                        return this.parent.recoveryPassword === value;
                                    }),
                            })}
                            onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                                setStatus();
                                if(this.props.location.newPasswordRequired){
                                    this.props.AuthenticatorContext.challengeLogin(values.recoveryPassword)
                                        .then((result) => {
                                            this.props.AuthContext.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, idToken: result.AuthenticationResult.IdToken, type: result.AuthenticationResult.TokenType }, () => {
                                                this.props.AuthContext.getUserData()
                                                    .then(() => {
                                                        this.props.history.push({ pathname: "/dashboard" });
                                                    }).catch((error) => {
                                                        fail();
                                                        console.error(error);
                                                    });
                                            });
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                setFieldError('recoveryPassword', <Translate id={`login.error.${error.message}`} />);
                                                setSubmitting(false);
                                            }
                                        })
                                }else{
                                    this.props.AuthContext.resetPassword(this.props.AuthenticatorContext.email, values.recoveryPassword, values.code)
                                        .then(() => {
                                            this.props.AuthenticatorContext.goToPage('LOGIN', false, { show: 'password' })
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                setStatus(error.message);
                                                setSubmitting(false);
                                            }
                                        });
                                }
                            }}
                        >
                            {(formik) => (
                                <Form id="recoveryForm">
                                    {!this.props.location.newPasswordRequired &&
                                        <div className='mb-3 mx-n2 d-flex'>
                                            <PinField
                                                autoFocus className="min-w-0 form-control mx-2 text-center" validate="0123456789"
                                                disabled={formik.isSubmitting} length={6}
                                                onChange={(code) => {
                                                    formik.setFieldValue('code', code)
                                                }}
                                            />
                                        </div>
                                    }
                                    <Collapse className='mt-3' isOpen={formik.values.code.length === 6 || !!this.props.location.newPasswordRequired}>
                                        <FormGroup>
                                            <FormikInputAuth
                                                label="forgotPassword.label.enterNewPassword"
                                                id="recoveryPassword"
                                                name="recoveryPassword"
                                                type="password"
                                                icon={'ti-lock'}
                                                trim
                                            />
                                        </FormGroup>
                                        <FormGroup className="mt-3">
                                            <FormikInputAuth
                                                label="forgotPassword.label.confirmNewPassword"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                icon={'ti-lock'}
                                                trim
                                            />
                                        </FormGroup>

                                        <Button color="spordle" block type='submit' disabled={formik.isSubmitting}>
                                            {formik.isSubmitting ?
                                                <Spinner size="sm" type='grow' color='light' />
                                                :
                                                <Translate id='forgotPassword.action.confirmNewPassword' />
                                            }
                                        </Button>

                                        <CapsLock>
                                            {(isActive) => (
                                                <Collapse isOpen={isActive}>
                                                    <div className='alert alert-warning mb-0'><i className="mr-1 mdi mdi-apple-keyboard-caps" /><Translate id='warning.capsLock' /></div>
                                                </Collapse>
                                            )}
                                        </CapsLock>
                                        {formik.status &&
                                            <Alert color='danger' className='mb-0 mt-3'>
                                                <Translate id={`recovery.error.${formik.status}`} />
                                                {formik.status === 'ExpiredCodeException' &&
                                                    <button
                                                        id="resendEmailButton"
                                                        onClick={this.sendRecoveryEmail}
                                                        type="button"
                                                        className="reset-btn text-link"
                                                    >
                                                        <Translate id='forgotPassword.action.resendCode' />
                                                    </button>
                                                }
                                            </Alert>
                                        }
                                    </Collapse>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
                <div className='text-center'>
                    <span><Translate id='signup.confirmed.notReceived' /></span>
                    <AntiSpam
                        timeoutDuration={10000}
                        clicksForTimeout={2}
                        resetClicksDuration={30000}
                        isSpamming={() => {
                            fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                        }}
                    >
                        {(_, antiSpamCallback) => (
                            <button
                                id="resendEmailButton"
                                onClick={(e) => {
                                    antiSpamCallback(e);
                                    this.sendRecoveryEmail();
                                }}
                                type="button"
                                className="ml-1 reset-btn text-link"
                            >
                                <Translate id='signup.confirmed.resend' />
                            </button>
                        )}
                    </AntiSpam>
                </div>
            </AuthLayout>
        );
    }
}

export default WithHcrTheme(withContexts(AuthContext, AuthenticatorContext)(ForgotPassword));
