export default{
    // general
    "account.transactions.memberProfileTransactions.paymentMethods.CHEQUE"              : "Chèque",
    "account.transactions.memberProfileTransactions.paymentMethods.CASH"                : "Argent comptant",
    "account.transactions.memberProfileTransactions.paymentMethods.CREDITCARD"          : "Carte de crédit",
    "account.transactions.memberProfileTransactions.paymentMethods.PAYMENTPROXY"        : "Carte de crédit (paiement proxy)",
    "account.transactions.memberProfileTransactions.paymentMethods.INTERAC"             : "Transfert interac",
    "account.transactions.memberProfileTransactions.paymentMethods.undefined"           : "N/A",
    "account.transactions.memberProfileTransactions.paymentMethods.ORGANISATION_CREDIT" : "Crédité (Gratuit)",
    "account.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT"       : "Crédit de membre",
    "account.transactions.memberProfileTransactions.paymentMethods.FREE"                : "Gratuit",
    "account.transactions.memberProfileTransactions.paymentMethods.EXTERNALPAYMENT"     : "Paiement externe",
    "account.transactions.memberProfileTransactions.paymentMethods.FUNDING"             : "Financement",
    "account.transactions.memberProfileTransactions.paymentMethods.OFFLINECREDIT"       : "Crédit hors ligne",

    "account.transactions.memberProfileTransactions.registrationType"              : "Type",
    "account.transactions.memberProfileTransactions.registrationType.REGISTRATION" : "Inscription",
    "account.transactions.memberProfileTransactions.registrationType.OTHER"        : "Article",
    "account.transactions.memberProfileTransactions.registrationType.CLINIC"       : "Stage",

    "account.transactions.memberProfileTransactions.registrations" : "Inscription(s)",
    "account.transactions.memberProfileTransactions.items"         : "Article(s)",
    "account.transactions.memberProfileTransactions.paidBy"        : "Payé par",
    "account.transactions.memberProfileTransactions.total"         : "Total",
    "account.transactions.memberProfileTransactions.add"           : "Ajouter un crédit",
    "account.transactions.memberProfileTransactions.credit"        : "Crédit",

    "account.transactions.memberProfileTransactions.noResults" : "Aucune transaction trouvée",

    "account.transactions.memberProfileTransactions.csv.invoiceDate"   : "Date de facturation",
    "account.transactions.memberProfileTransactions.csv.invoiceNumber" : "Numéro de facture",
    "account.transactions.memberProfileTransactions.csv.total"         : "Total ($)",
    "account.transactions.memberProfileTransactions.csv.paid"          : "Payé ($)",
    "account.transactions.memberProfileTransactions.csv.due"           : "Dû ($)",

    // table view
    "account.transactions.memberProfileTransactions.tableView.header.transaction"         : "Transaction",
    "account.transactions.memberProfileTransactions.tableView.header.transaction.tooltip" : "{waiverCount, plural, one {Une dispense doit être signée} other {Des dispense doivent être signées}}",
    "account.transactions.memberProfileTransactions.tableView.header.members"             : "Membre",
    "account.transactions.memberProfileTransactions.tableView.header.status"              : "Statut",
    "account.transactions.memberProfileTransactions.tableView.header.paymentMethod"       : "Méthode de paiement",
    "account.transactions.memberProfileTransactions.tableView.header.items"               : "Articles",
    "account.transactions.memberProfileTransactions.tableView.header.waivers"             : "Dispenses",
    "account.transactions.memberProfileTransactions.tableView.header.paid"                : "Payé",
    "account.transactions.memberProfileTransactions.tableView.header.refunded"            : "Remboursé",
    "account.transactions.memberProfileTransactions.tableView.header.due"                 : "Dû",


    // sidepanel
    "account.transactions.memberProfileTransactions.sidePanel.status.PENDING"     : "En attente",
    "account.transactions.memberProfileTransactions.sidePanel.status.PAID"        : "Payé",
    "account.transactions.memberProfileTransactions.sidePanel.status.COMPLETED"   : "Complété",
    "account.transactions.memberProfileTransactions.sidePanel.status.NEED_REVIEW" : "En révision",
    "account.transactions.memberProfileTransactions.sidePanel.status.SUSPENDED"   : "Suspendu",
    "account.transactions.memberProfileTransactions.sidePanel.status.DECLINED"    : "Refusé",
    "account.transactions.memberProfileTransactions.sidePanel.status.CANCELED"    : "Annulé",

    "account.transactions.memberProfileTransactions.sidePanel.header.invoice"            : "Facture",
    "account.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate" : "Date de paiement initial",

    "account.transactions.memberProfileTransactions.sidePanel.actions.refund" : "Rembourser",

    "account.transactions.memberProfileTransactions.sidePanel.items.payments" : "Paiements",
    "account.transactions.memberProfileTransactions.sidePanel.items.date"     : "Date",
    "account.transactions.memberProfileTransactions.sidePanel.items.status"   : "Statut",
    "account.transactions.memberProfileTransactions.sidePanel.items.amount"   : "Montant",
    "account.transactions.memberProfileTransactions.sidePanel.items.price"    : "Prix",
    "account.transactions.memberProfileTransactions.sidePanel.items.due"      : "Dû",
    "account.transactions.memberProfileTransactions.sidePanel.items.credit"   : "Crédit",

    "account.transactions.memberProfileTransactions.sidePanel.credit.delete.message" : "Vous êtes sur le point de supprimer ce crédit.",
    "account.transactions.memberProfileTransactions.sidePanel.credit.updated"        : "Crédit mis à jour!",
    "account.transactions.memberProfileTransactions.sidePanel.credit.creationDate"   : "Date de création",
    "account.transactions.memberProfileTransactions.sidePanel.credit.balance"        : "Balance",


    // add modal
    "account.transactions.memberProfileTransactions.addModal.creditType"     : "Type de crédit",
    "account.transactions.memberProfileTransactions.addModal.amount"         : "Montant",
    "account.transactions.memberProfileTransactions.addModal.expirationDate" : "Date d'expiration",
    "account.transactions.memberProfileTransactions.addModal.status"         : "Statut",

    "account.transactions.memberProfileTransactions.addModal.validation.creditType.required"       : "Le type de crédit est requis",
    "account.transactions.memberProfileTransactions.addModal.validation.amount.required"           : "Le montant est requis",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.required"   : "La date d'expiration est requise",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.valid"      : "La date d'expiration doit être d'un bon format",
    "account.transactions.memberProfileTransactions.addModal.validation.expirationDate.validAfter" : "La date d'expiration ne peut pas être avant aujourd'hui",
}