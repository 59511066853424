import { FormikCurrencyInput, FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { formatSelectData } from '@spordle/spordle-select';
import { Form, Formik } from 'formik';
import { useContext, useRef } from 'react';
import {
    Button, Col, FormGroup,
    Label, ModalBody,
    ModalFooter, Row
} from "reactstrap";
import { object, string, number } from 'yup';
import Required from '../../../../../../components/formik/Required';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
// contexts
import UserImg from '../../../../../../components/UserImg';
import { TravelPermitAddContext } from './TravelPermitAddContext';
import { AccountsContext } from '../../../../../../contexts/contexts';
import { Tooltip } from '@mantine/core';

const TravelPermitAdd1 = ({ next, toggle, member }) => {
    const travelPermitAddContext = useContext(TravelPermitAddContext);
    const accountsContext = useContext(AccountsContext)
    const utilsContext = useContext(UtilsContext);
    const { getGenericLocale } = useContext(I18nContext);

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const provinceSelectRef = useRef();

    return (
        <Formik
            initialValues={{
                team_id: travelPermitAddContext.state.formData.team_id || '',
                travel_id: travelPermitAddContext.state.formData.travel_id || '',
                travel_event_name: travelPermitAddContext.state.formData.travel_event_name || '',
                travel_game_type: travelPermitAddContext.state.formData.travel_game_type || '',
                travel_type_id: travelPermitAddContext.state.formData.travel_type_id || '',
                host_website: travelPermitAddContext.state.formData.host_website || '',
                host_team_name: travelPermitAddContext.state.formData.host_team_name || '',
                nb_games: travelPermitAddContext.state.formData.nb_games || '',
                host_division: travelPermitAddContext.state.formData.host_division || '',
                host_category: travelPermitAddContext.state.formData.host_category || '',
                city: travelPermitAddContext.state.formData.city || '',
                host_organisation: travelPermitAddContext.state.formData.host_organisation || '',
                location_name: travelPermitAddContext.state.formData.location_name || '',
                country_code: travelPermitAddContext.state.formData.country_code || '',
                country_id: travelPermitAddContext.state.formData.country_id || '', // needed for provinces select
                province_code: travelPermitAddContext.state.formData.province_code || '',
                host_class: travelPermitAddContext.state.formData.host_class || '',
                travel_permit_fee: travelPermitAddContext.state?.formData?.travel_permit_fee ? (travelPermitAddContext.state?.formData?.travel_permit_fee / 100) : '',
            }}
            validationSchema={
                object().shape({
                    team_id: string().required(<Translate id='account.members.travelPermits.addModal.teamRequired' />),
                    travel_event_name: string().required(<Translate id='account.members.travelPermits.addModal.titleRequired' />),
                    travel_type_id: string().required(<Translate id='account.members.travelPermits.addModal.travelIdRequired' />),
                    travel_game_type: string().required(<Translate id='account.members.travelPermits.addModal.eventTypeRequired' />),
                    city: string().required(<Translate id='account.members.travelPermits.addModal.hostCityRequired' />),
                    country_code: string().required(<Translate id='account.members.travelPermits.addModal.hostCountryRequired' />),
                    province_code: string().required(<Translate id='account.members.travelPermits.addModal.hostProvinceRequired' />),
                    host_division: string().required(<Translate id='account.members.travelPermits.addModal.hostDivisionRequired' />),
                    travel_permit_fee: number().nullable(),
                })
            }
            onSubmit={(submitData) => {
                travelPermitAddContext.setData({
                    teamOrgId: member.teams.find((team) => team.team_id === submitData.team_id)?.organisation.organisation_id,
                    travel_id: submitData.travel_id,
                    team_id: submitData.team_id,
                    travel_event_name: submitData.travel_event_name,
                    travel_game_type: submitData.travel_game_type,
                    travel_type_id: submitData.travel_type_id,
                    host_website: submitData.host_website,
                    host_team_name: submitData.host_team_name,
                    nb_games: submitData.nb_games,
                    host_division: submitData.host_division,
                    host_category: submitData.host_category,
                    country_code: submitData.country_code,
                    country_id: submitData.country_id, // needed for provinces select
                    city: submitData.city,
                    host_organisation: submitData.host_organisation,
                    location_name: submitData.location_name,
                    province_code: submitData.province_code === 'null' ? null : submitData.province_code,
                    host_class: submitData.host_class,
                    travel_permit_fee: (submitData.travel_permit_fee * 100).toFixed(0),
                })
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <FormGroup>
                            <Label for='team_id'><Translate id='account.members.travelPermits.addModal.teamLabel' /> <Required /></Label>
                            <FormikSelect
                                name='team_id'
                                id='team_id'
                                onOptionSelected={(values) => {
                                    travelPermitAddContext.getTeamOrganizationSettings(member.teams.find((team) => (values[0] === team.team_id)).organisation.organisation_id)
                                        .then((settings) => {
                                            formik.setFieldValue('team_id', values[0], false)
                                            formik.setFieldValue('travel_permit_fee', settings?.organisation_travel_permit_fee?.value ? ((parseInt(settings?.organisation_travel_permit_fee?.value)) / 100) : '', false)
                                        })
                                    return true
                                }}
                                renderOption={({ option }, fromSelectedOption) => (
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex justify-content-center align-items-center mr-2">
                                            <UserImg
                                                abbr={option.short_name || option.label || ''}
                                                src={option.logo?.full_path}
                                                filePos={option.logo?.file_position}
                                                width={!fromSelectedOption ? '40' : '20'}
                                                height={!fromSelectedOption ? '40' : '20'}
                                                alt={option.label + ' logo'}
                                                className="p-1"
                                            />
                                        </div>
                                        <div>
                                            <span className='font-medium'>{option.label}</span>
                                            {option.short_name &&
                                                <span className='text-muted ml-1'>({option.short_name})</span>
                                            }
                                            {option.team_category && !fromSelectedOption &&
                                                <div className='small text-muted text-truncate'>
                                                    { option.team_category.division && <DisplayI18n i18n={option.team_category.division.i18n} defaultValue={option.team_category.division.short_name} field='short_name' /> }
                                                    { option.team_category.class &&
                                                        <>{' - '}<DisplayI18n i18n={option.team_category.class.i18n} defaultValue={option.team_category.class.short_name} field='short_name' /></>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                                searchKeys={[
                                    'short_name',
                                    'team_category.class.name',
                                    'team_category.class.short_name',
                                    `team_category.class.i18n.${getGenericLocale()}.name`,
                                    `team_category.class.i18n.${getGenericLocale()}.short_name`,
                                    'team_category.division.name',
                                    'team_category.division.short_name',
                                    `team_category.division.i18n.${getGenericLocale()}.name`,
                                    `team_category.division.i18n.${getGenericLocale()}.short_name`,
                                ]}
                                options={member.teams.map((team) => ({
                                    value: team.team_id,
                                    label: team.name,
                                    short_name: team.short_name,
                                    logo: team.logo,
                                    team_category: team.team_category,
                                }))}
                                loadingStatus='success'

                            />
                        </FormGroup>
                        <Row form>
                            <Col sm="12">
                                <FormGroup>
                                    <Label for='travel_event_name'><Translate id='account.members.travelPermits.addModal.titleLabel' /> <Required /> <Tooltip withArrow wrapLines width={400} label={<Translate id="account.members.travelPermits.addModal.titleLabel.tip" />} zIndex={9999}><i className='mdi mdi-information-outline text-primary' /></Tooltip></Label>
                                    <FormikInputText id='travel_event_name' name='travel_event_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label for='travel_id'><Translate id='account.members.travelPermits.travelId' /></Label>
                                    <FormikInputText id='travel_id' name='travel_id' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='travel_type_id'><Translate id='account.members.travelPermits.addModal.travelIdLabel' /> <Required /></Label>
                                    <FormikSelect
                                        name='travel_type_id'
                                        id='travel_type_id'
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return accountsContext.getTravelTypes({ organisation_id: member.organisation.organisation_id })
                                                        .then((travelTypes) => {
                                                            if(!travelTypes)return [];

                                                            return travelTypes.map((travelType) => ({
                                                                value: travelType.travel_type_id,
                                                                label: travelType.name,
                                                                i18n: travelType.i18n,
                                                            }))
                                                        });
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='travel_game_type'><Translate id='account.members.travelPermits.addModal.eventTypeLabel' /> <Required /></Label>
                                    <FormikSelect
                                        name='travel_game_type'
                                        id='travel_game_type'
                                        search={false}
                                        defaultData={[
                                            {
                                                label: 'Home',
                                                value: 'HOME',
                                            },
                                            {
                                                label: 'Away',
                                                value: 'AWAY',
                                            },
                                            {
                                                label: 'N/A',
                                                value: 'N/A',
                                            },
                                        ]}
                                        loadingStatus="success"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for='host_website'><Translate id='account.members.travelPermits.addModal.hostWebsiteLabel' /></Label>
                            <FormikInputText id='host_website' name='host_website' />
                        </FormGroup>
                        <Row form>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='host_team_name'><Translate id='account.members.travelPermits.addModal.hostNameLabel' /></Label>
                                    <FormikInputText id='host_team_name' name='host_team_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='nb_games'><Translate id='account.members.travelPermits.addModal.nbGamesLabel' /></Label>
                                    <FormikInputNumber
                                        allowLeadingZeros={false} allowNegative={false}
                                        decimalSeparator={false}
                                        id='nb_games' name='nb_games'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md="4">
                                <FormGroup>
                                    <Label for='host_division'><Translate id='account.members.travelPermits.addModal.hostDivision' /> <Required /></Label>
                                    <FormikInputText id='host_division' name='host_division' />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for='host_class'><Translate id='account.members.travelPermits.addModal.hostClass' /></Label>
                                    <FormikInputText id='host_class' name='host_class' />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for='host_category'><Translate id='account.members.travelPermits.addModal.hostCategory' /></Label>
                                    <FormikInputText id='host_category' name='host_category' />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr className="mt-0 mb-3" />

                        <Row form>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='country_code'><Translate id='account.members.travelPermits.addModal.hostCountry' /> <Required /></Label>
                                    <FormikSelect
                                        name="country_code"
                                        id="country_code"
                                        dataIndex="code"
                                        search
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        onOptionSelected={([ select ], spordleSelect) => {
                                            const country = spordleSelect.getSpordleTable().getData().find((d) => d.code === select);

                                            if(country){
                                                formik.setFieldValue('province_code', "");
                                                formik.setFieldValue('country_id', country.country_id);
                                                provinceSelectRef.current?.getSpordleTable().filterChange('countryCode', country.country_id);
                                            }
                                        }}
                                        renderOption={(opt) => (
                                            !opt.option.isGroup ?
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={opt.option.label}
                                                    i18n={opt.option.i18n}
                                                />
                                                :
                                                <Translate id={opt.option.label} />
                                        )}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return utilsContext.getCountries()
                                                        .then((countries) => {
                                                            return formatSelectData(
                                                                countries,
                                                                {
                                                                    getValue: (country) => country.code,
                                                                    getGroupId: (country) => [ "CA", "US" ].includes(country.code) ? 'suggested' : undefined,
                                                                    newGroupIndexes: {
                                                                        0: {
                                                                            code: 'SuggestedGroup',
                                                                            groupId: "suggested",
                                                                            label: 'misc.select.suggested',
                                                                            isGroup: true,
                                                                        },
                                                                    },
                                                                },
                                                            );
                                                        });
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='province_code'><Translate id='account.members.travelPermits.addModal.hostProvinceState' /> <Required /></Label>
                                    <FormikSelect
                                        ref={provinceSelectRef}
                                        name='province_code'
                                        id='province_code'
                                        search
                                        disabled={!formik.values.country_code || formik.values.province_code == "null"}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                        initFilter={{
                                            countryCode: formik.values.country_id || '',
                                        }}
                                        loadData={(from, { filters }, spordleTable) => {
                                            switch (from){
                                                case 'FILTER':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    if(filters.countryCode){
                                                        return utilsContext.getProvinces(filters.countryCode)
                                                            .then((countries) => {
                                                                if(!countries[0].sub_divisions || countries[0].sub_divisions.length == 0){
                                                                    formik.setFieldValue('province_code', "null")
                                                                }
                                                                return countries[0].sub_divisions?.map((state) => ({
                                                                    value: state.code,
                                                                    label: state.code,
                                                                    i18n: state.i18n,
                                                                })) || [];
                                                            })
                                                    }
                                                    return Promise.resolve([]);
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='city'><Translate id='account.members.travelPermits.addModal.hostCity' /> <Required /></Label>
                                    <FormikInputText id='city' name='city' />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='host_organisation'><Translate id='account.members.travelPermits.addModal.hostOrganization' /></Label>
                                    <FormikInputText id='host_organisation' name='host_organisation' />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='location_name'><Translate id='account.members.travelPermits.addModal.hostArena' /></Label>
                                    <FormikInputText id='location_name' name='location_name' />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr className="mt-0 mb-3" />

                        <Row form>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='travel_permit_fee'><Translate id='account.members.travelPermits.fee' /></Label>
                                    <FormikCurrencyInput
                                        disabled
                                        id='travel_permit_fee'
                                        name='travel_permit_fee'
                                        className="mb-1"
                                        allowNegative={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                    </ModalFooter>

                </Form>
            )}
        </Formik>
    );
}

export default TravelPermitAdd1;