import { stringBuilder } from "@spordle/helpers";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { classPrefix } from "../../../components/helpers";
import Event from "../../../events/Event";
import { ScheduleContext } from "../../../Schedule";

// TO DO : useMediaQuery to render some calcs / not render them depending on screen width
const ScheduleDailyFull = ({
    className,
    showShared = false,
    date,
}) => {
    const { getDailyEvents, getSharedEvents, events } = useContext(ScheduleContext);
    const [ allEvents, setAllEvents ] = useState([]);
    const [ sharedEvents, setSharedEvents ] = useState([]);

    function renderEvent(event){
        const sStart = event.start_date ? moment(event.start_date) : event.date;
        const sEnd = event.end_date ? moment(event.end_date) : event.date;

        return (
            <Event.Compact event={event} isExtending={sStart.isBefore(date, 'days')} isExceeding={sEnd.isAfter(date, 'days')} key={`daily-full-shared-${event.id}`}>
                {event.title}
            </Event.Compact>
        )
    }

    useEffect(() => {
        setAllEvents(() => {
            const allEvents = getDailyEvents(date.format('YYYY'), date.format('MM'), date.format('D'));

            // NEEDS A BETTER SOLUTION, (+ two loops) BUT IT WORKS FOR THE MOMENT BEING
            return allEvents.reduce((newArr, anEvent) => {
                if(anEvent.startTime && anEvent.endTime){
                    const overlapping = newArr.filter((prevEvent) => {
                        if(!prevEvent.startTime && !prevEvent.endTime){
                            return false;
                        }

                        // const compare = (a, aa, b, bb) => (a <= b && a >= bb) || (a >= b && aa <= bb);
                        const prevStart = parseFloat(prevEvent.startTime)
                        // const prevEnd = parseFloat(prevEvent.endTime)
                        const start = parseFloat(anEvent.startTime)
                        const end = parseFloat(anEvent.endTime)

                        return (prevStart <= start && prevStart >= end) || (prevStart >= start && prevStart <= end)
                    });

                    if(overlapping.length > 0) anEvent.overlapGap = overlapping.length * 15;
                }

                newArr.push(anEvent);
                return newArr;
            }, []);
        });
        setSharedEvents(getSharedEvents(date, date));

    }, [ events, date ]);


    return (
        <>

            {showShared &&
                <section className={`${classPrefix}__events--timeless`}>
                    {sharedEvents.map((event) => renderEvent(event))}
                </section>
            }
            <section
                tabIndex="0"
                className={stringBuilder(`${classPrefix}__events`, className)}
            >

                {allEvents.map((event, index) => <Event delay={index} className={`with-timeline`} key={'daily-full' + event.id} event={event} />)}
            </section>
        </>
    )
}

export default ScheduleDailyFull;