import React, { createContext } from 'react';
import { serverError } from '../api/CancellableAPI';
import API_PUBLIC_LOGGED_IN from '../api/API-Public-LoggedIn';

/** @type {React.Context<Omit<WaiversContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const WaiversContext = createContext();
WaiversContext.displayName = 'WaiversContext';

class WaiversContextProvider extends React.Component{

    /**
     * Signs a waiver
     * @param {string} invoiceNumber
     * @param {string} invoiceItemId
     * @param {string} waiverId
     * @param {object} answer
     * @param {'YES'|'NO'} answer.answer
     * @param {string} answer.language_code
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Waiver%20Response/Apicontroller%5CInvoices%5CInvoiceitemwaivers%3A%3ApatchWaiverResponse|documentation}
     * @returns {Promise.<boolean>}
     */
    signWaiver = (invoiceNumber, invoiceItemId, waiverId, answer = {}) => {
        const data = new URLSearchParams(answer);
        return API_PUBLIC_LOGGED_IN.patch(`invoices/${invoiceNumber}/items/${invoiceItemId}/waivers/${waiverId}`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <WaiversContext.Provider value={{ ...this }}>
                {this.props.children}
            </WaiversContext.Provider>
        )
    }
}

export default WaiversContextProvider;