var currentMaintenanceUrl = '';

/**
 * Get the current maintenance url
 * @returns {string}
 */
export const getCurrentMaintenanceUrl = () => currentMaintenanceUrl;

/**
 * Sets the current maintenance url if it hasn't been set before
 * @param {string} value
 */
export const setCurrentMaintenanceUrl = (value) => {
    if(!currentMaintenanceUrl)
        currentMaintenanceUrl = value;
};