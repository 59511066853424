import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import FadeIn from "../../animations/FadeIn";
import UserImg from "../../UserImg";
import { classPrefix } from "../components/helpers";
import { ScheduleContext } from "../Schedule";

const Event = ({ className, delay, event }) => {
    if(!event.startTime || !event.endTime)return null;

    const { onEventClick } = useContext(ScheduleContext);
    const start = event.startTime.match(/^.*(?=(:))/);
    const end = event.endTime.match(/^.*(?=(:))/);

    const getLocation = (type, name, link) => {
        let icon = "mr-1 mdi mdi-";

        switch (type){
            case 'ONLINE_LIVE':
                icon += "access-point";
                break;
            case 'ONLINE':
                icon += 'play'
                break;
            case 'ONSITE':
            default:
                icon += "map-marker"
                break;
        }

        return link ? <a href={link} target="_blank" rel="noopener noreferrer"><i className={icon} />{name}</a> : <span className="text-body"><i className={icon} />{name}</span>
    }

    return (
        <FadeIn
            style={{ opacity: 0, ...event.overlapGap ? { marginLeft: `${event.overlapGap}px` } : undefined }}
            direction="bottom"
            delay={delay / 10}
            distance={30}
            tag="article"
            onClick={() => onEventClick(event)}
            className={stringBuilder(`${classPrefix}__single-event`, className, `start-${parseInt(start[0])} end-${parseInt(end[0]) + 1}`)}
        >
            <time className="small font-bold">
                {`${event.startTime} - ${event.endTime}`}
            </time>
            <h4 className={`${classPrefix}__single-event-title`}>
                {event.title}
            </h4>
            { event.location &&
                    <div className={`${classPrefix}__single-event-location`}>
                        {getLocation(event.session_format, event.location, event.locationLink)}
                    </div>
            }
            { event.members?.length > 0 &&
                    <ul className="event-item-members list-unstyled pl-0">
                        {event.members.map((member, i) => (
                            i <= 1 &&
                                <li key={member.member_id} className='event-item-member-container'>
                                    <UserImg
                                        className="event-item-member-pill"
                                        alt={member.first_name + ' ' + member.last_name}
                                        abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                        filePos={member.attachment?.file_position}
                                        src={member.attachment?.full_path}
                                        width={30}
                                    />
                                    <div className="event-item-member-info" style={{ lineHeight: 1 }}>
                                        <div>{member.first_name}</div>
                                        <div>{member.last_name}</div>
                                    </div>
                                </li>
                        ))}
                        {(event.members.length > 2) &&
                            <li className='event-item-member-container'>
                                <UserImg
                                    className="event-item-member-pill bg-primary text-white"
                                    alt='Other members'
                                    abbr={`+${event.members.length - 2}`}
                                    width={30}
                                />
                            </li>
                        }
                    </ul>
            }
        </FadeIn>
    )
}

const EventCompact = ({ className, isExtending, event, isExceeding, start, duration, ...props }) => {
    const dur = start + duration > 7 ? 8 - start : duration ?? 1;
    const { onEventClick } = useContext(ScheduleContext);

    return (
        <article
            {...props}
            onClick={() => event && onEventClick?.(event)}
            className={
                stringBuilder(
                    className,
                    `${classPrefix}__single-event--compact`,
                    start ? `start-${start}` : '',
                    dur ? `end-${dur}` : '',
                    { 'is-exceeding': isExceeding },
                    { 'is-extending': isExtending },
                    { 'is-all-day': dur <= 1 && !isExceeding && !isExtending },
                )
            }
        />
    )
}

Event.Compact = EventCompact;

export default Event;