import { CurrencyFormat } from '@spordle/intl-elements'
import { stringBuilder } from "@spordle/helpers";
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const PaymentOtherItem = ({ values, isFirst }) => {
    const fee = values[0].other_fee.fee;
    return (
        <tr>
            <td className={stringBuilder('pl-0 w-50', { 'border-top-0': isFirst })} style={{ maxWidth: '1px' }}>
                <div className='text-truncate'>
                    <DisplayI18n field='name' defaultValue={fee.name} i18n={fee.i18n} />
                </div>
            </td>
            <td className={stringBuilder('text-nowrap', { 'border-top-0': isFirst })}>
                <i className='mdi mdi-close mr-1' />{values.length}
            </td>
            <td className={stringBuilder('font-bold text-right', { 'border-top-0': isFirst })}>
                <CurrencyFormat value={values[0].new_amount / 100} currencyDisplay='symbol' />
            </td>
        </tr>
    )
}

export default PaymentOtherItem
