import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment';
import { I18nContext } from '../../contexts/I18nContext';
import ScheduleDaily from './scheduleParts/ScheduleDaily';
import ScheduleMonthly from './scheduleParts/ScheduleMonthly';
import ScheduleWeekly from './scheduleParts/ScheduleWeekly';
import Navigation from './components/Navigation';
import ScheduleUpcoming from './scheduleParts/ScheduleUpcoming';
import ScheduleWeeklyFull from './scheduleParts/full/weekly/ScheduleWeeklyFull';
import { isObject } from './components/helpers';

/** @type {React.Context<Omit<Schedule, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const ScheduleContext = createContext();
ScheduleContext.displayName = 'ScheduleContext';

/**
 * @typedef {Object} Event
 * @prop {string} id
 * @prop {string} start_date
 * @prop {string} end_date
 * @prop {string} date
 */

/**
 * @param {Object} props
 * @param {Event[]} props.events
 * @param {function} props.onEventClick
 * @param {JSX.Element} props.eventComponent
 * @returns {JSX.Element}
 */
const Schedule = ({ events, onEventClick, eventComponent, ...props }) => {
    // ---------------------------------- //
    // --------- Properties ------------- //
    // ---------------------------------- //
    const today = moment();
    const i18n = useContext(I18nContext);
    const [ selected, setSelected ] = useState({
        selected: today,
        day: today.date(),
        week: today.week(),
        month: today.month(),
        year: today.year(),
    });
    const [ week, setWeek ] = useState(null);
    const [ calendar, setCalendar ] = useState(null);
    const [ , setDir ] = useState(null);

    const [ sharedEvents, setSharedEvents ] = useState([]);
    const [ dailyEvents, setDailyEvents ] = useState({});

    // --------------------------------- //
    // -------- Functions -------------- //
    // --------------------------------- //
    /**
     * Formats the key of an event
     * @param {import('moment').Moment} date
     * @param {object} obj Object contained in the event
     * @param {string} obj.id Object needs to have an id
     * @returns {object} Formatted event
     * @example Example of usage
     * _formatEvent(
     *      moment(2021-07-17T05:00:00Z),
     *      {
     *          id: 'unique-id',
     *          title: 'title',
     *          startTime: '01:00',
     *          endTime: '03:00'
     *      }
     * )
     * @example Example of returned value
     * { '2021' : {
     *      '03' : {
     *          '20': [{
     *              id: 'unique-id',
     *              ...obj
     *          }]
     *      }
     * }}
     * @private
     */
    function _formatEvent(date, obj){
        return {
            [date.format('YYYY')]: { // year key
                [date.format('MM')]: { // month key
                    [date.format('D')]: [ { // day key
                        ...obj,
                    } ],
                },
            },
        }
    }

    /**
     * @description Will merge events formatted by formatEvent function
     * @param {object} target All events
     * @param {object} source Array containing object
     * @returns {object} Merged events
     * @private
     */
    function _mergeEvents(target, source){
        const output = Object.assign({}, target);

        if(isObject(target) && isObject(source)){
            Object.keys(source).forEach((key) => {
                if(isObject(source[key])){
                    !(key in target) ? Object.assign(output, { [key]: source[key] }) : output[key] = _mergeEvents(target[key], source[key]);
                }else if(Array.isArray(target[key])){
                    target[key].pushArray(source[key]);
                }else{
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }

        return output;
    }

    /**
     * @description used to separate events in daily events and shared events
     * @param {Event[]} toFilter
     * @private
     */
    function _filterEvents(toFilter){
        const shared = [];
        let dailys = {};

        for(let e = 0; e < toFilter.length; e++){
            const event = toFilter[e];
            const mStart = event.date || moment(event.start_date);
            const mEnd = moment(event.end_date);
            const diff = Math.abs(mStart.diff(mEnd, 'days', false));

            if(!event.startTime || !event.endTime){
                shared.push(event);
                dailys = _mergeEvents(dailys, _formatEvent(event.date || moment(event.start_date), event));
            }else if(!!event.end_date && diff > 0){
                if(!shared.some((e) => e.id === event.id)){
                    shared.push(event);
                }
            }else{
                dailys = _mergeEvents(dailys, _formatEvent(event.date || moment(event.start_date), event));
            }
        }

        setSharedEvents(shared);
        setDailyEvents(dailys);
    }

    /**
     * @description Changes the selected date
     * @param {import('moment').Moment} momentObj
     */
    const setNewDate = (momentObj) => {
        setSelected({
            selected: momentObj,
            day: momentObj.date(),
            week: momentObj.week(),
            month: momentObj.month(),
            year: today.year(),
        })
    }

    /**
     * @param {('years'|'days'|'weeks'|'months')} timeUnit
     * @param {boolean} [toSubtract=false] If set to true, will substract, else will add
     */
    const navigateDate = (timeUnit, toSubtract = false) => {
        const newDate = selected.selected.clone();

        if(toSubtract){
            newDate.subtract(1, timeUnit);
            setDir('left');
        }else{
            newDate.add(1, timeUnit);
            setDir('right');
        }

        newDate.startOf(timeUnit);
        setNewDate(newDate);
    }

    /**
     * @description Creates a calendar of the current month
     */
    const makeCalendar = () => {
        const startDay = selected.selected.clone().startOf('month').startOf('week');
        const endDay = selected.selected.clone().endOf('month').endOf('week');
        const newCalendar = [];
        const date = startDay.clone().subtract(1, 'day');

        while(date.isBefore(endDay, 'day')){
            newCalendar.push({
                days: Array(7).fill(0).map(() => date.add(1, 'day').clone()),
            })
        }

        setCalendar(newCalendar);
    }

    /**
     * @description Creates a weekly calendar of selected week
     */
    const makeWeek = () => {
        const startDay = selected.selected.clone().startOf('week');
        const endDay = selected.selected.clone().endOf('week');

        const newWeek = [];
        const date = startDay.clone().subtract(1, 'day');

        while(!date.isSame(endDay, 'day')){
            newWeek.push(date.add(1, 'day').clone());
        }

        setWeek(newWeek);
    }

    /**
     * @description returns events based on the received date
     * @param {string} year
     * @param {string} month
     * @param {string} day
     * @returns {Event[]}
     */
    const getDailyEvents = (year, month, day) => {
        return dailyEvents?.[year]?.[month]?.[day] ?? [];
    }

    /**
     * @description Gets a list of events lasts all day or longer than a day and that occur between two specified dates
     * @param {moment} startDate
     * @param {moment} endDate
     * @returns {Event[]}
     */
    const getSharedEvents = (startDate, endDate) => {
        if(sharedEvents){
            const returnedEvents = [];

            // eslint-disable-next-line no-inner-declarations
            function max(a, b){
                return a.isSameOrAfter(b, 'day') ? a : b;
            }

            // eslint-disable-next-line no-inner-declarations
            function min(a, b){
                return a.isSameOrBefore(b, 'day') ? a : b;
            }

            // eslint-disable-next-line no-cond-assign
            for(let e = 0, sharedEvent; sharedEvent = sharedEvents[e]; e++){
                if(!(sharedEvent.start_date || sharedEvent.end_date)){
                    if(((sharedEvent.date).isSameOrAfter(startDate, 'day') && (sharedEvent.date).isSameOrBefore(endDate, 'day'))){
                        returnedEvents.push(sharedEvent);
                    }
                }else{
                    const sharedStart = moment(sharedEvent.start_date);
                    const sharedEnd = moment(sharedEvent.end_date);

                    if(max(startDate, sharedStart).isSameOrBefore(min(endDate, sharedEnd), 'day')){
                        returnedEvents.push(sharedEvent);
                    }
                }
            }

            return returnedEvents;
        }
        return [];

    }

    // ------- Component updates ------- //
    moment.locale(i18n.getGenericLocale());
    selected.selected.locale(i18n.getGenericLocale());

    // useLayoutEffect(() => {
    //     makeWeek();
    //     makeCalendar();
    // }, []);

    useEffect(() => {
        if(events){
            _filterEvents(events);
        }
    }, [ events ]);

    useMemo(makeCalendar, [ selected.month ]);
    useMemo(makeWeek, [ selected.week, i18n ]);

    // ------- Render ------- //
    return (
        <ScheduleContext.Provider
            value={{
                selected: selected,
                events: dailyEvents,
                eventComponent: eventComponent,
                onEventClick: onEventClick,
                getDailyEvents: getDailyEvents,
                navigateDate: navigateDate,
                today: today,
                calendar: calendar,
                setNewDate: setNewDate,
                week: week,
                getSharedEvents: getSharedEvents,
            }}
        >
            {props.children}
        </ScheduleContext.Provider>
    );
};

Schedule.Navigation = Navigation;
Schedule.Weekly = ScheduleWeekly;
Schedule.WeeklyFull = ScheduleWeeklyFull;
Schedule.Monthly = ScheduleMonthly;
Schedule.Daily = ScheduleDaily;
Schedule.Upcoming = ScheduleUpcoming;

export default Schedule;