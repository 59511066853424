import { useSpordleTable } from "@spordle/datatables";
import { FormikError, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import FileUpload from "../../../../../components/uploader/FileUpload";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { object, mixed, string } from "yup";
import Required from "../../../../../components/formik/Required";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { useContext, useState } from "react";
import CrossFade from "../../gameIncidentReports/components/CrossFade";
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { DROPZONE_PROPS } from "../accountMemberDocumentsHelper";
import { mbToBytes } from "../../../../../components/uploader/uploadHelpers";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";

export const OTHER_VALUE = 'OTHER';

const MemberAddDocumentModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.modalInfo.isOpen} analyticsName="memberAddDocumentModal">
            <MemberAddDocumentModalInner {...props} />
        </AnalyticsModal>
    );
}

const MemberAddDocumentModalInner = ({ toggle, documentTypes = [], modalInfo, memberId, memberOrgId, refreshMetaMember }) => {
    const { createMemberAttachments } = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext)
    const [ isCreated, setIsCreated ] = useState(false);
    const { refreshTable } = useSpordleTable();

    const [ selectedType, setSelectedType ] = useState(null);

    return (
        <Formik
            initialValues={{
                document_type_id: "",
                attachment: null,
                note: "",
                ...modalInfo.initValues,
            }}
            validationSchema={object().shape({
                document_type_id: string().required(<Translate id="account.members.documents.documentType.required" />),
                attachment: mixed().test({
                    name: 'isFile',
                    message: <Translate id='account.members.documents.document.required' />,
                    test: function(val){
                        return val instanceof File
                    },
                }),
                note: string().when('document_type_id', {
                    is: (val) => val === OTHER_VALUE || selectedType?.with_note == 1,
                    then: string().required(<Translate id="account.members.documents.note.required" />),
                }),
            })}
            onSubmit={(values, { setStatus }) => {
                const apiVal = {
                    attachment: values.attachment,
                    document_type_id: values.document_type_id === OTHER_VALUE ? "" : values.document_type_id,
                };

                if(values.document_type_id === OTHER_VALUE || selectedType.with_note == 1){
                    apiVal.note = values.note;
                }

                return createMemberAttachments(memberId, apiVal)
                    .then(async() => {
                        await refreshMetaMember()
                        refreshTable();
                        setIsCreated(true);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setStatus({
                                isVisible: true,
                                msg: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}><Translate id='account.members.documents.document.add.title' /></ModalHeader>
                        <CrossFade isVisible={!isCreated}>
                            <ModalBody>
                                <div className="mb-3">
                                    <Label className="mb-1 text-muted" for="document_type_id"><Translate id='account.members.documents.documentType.label' /> <Required /></Label>
                                    <FormikSelect
                                        id="document_type_id"
                                        name="document_type_id"
                                        search={false}
                                        clearable
                                        renderOption={({ option }) => (
                                            option.value === OTHER_VALUE ?
                                                <Translate id={option.label} />
                                                :
                                                <div>
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={option.i18n}
                                                        defaultValue={option.label}
                                                    />
                                                    {option.required &&
                                                        <div className="small text-danger"><Translate id='misc.required' /></div>
                                                    }
                                                </div>
                                        )}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return orgContext.getOrganizationSettings(memberOrgId)
                                                        .then((settings) => {
                                                            return documentTypes.reduce((types, type, index) => {

                                                                types.push({
                                                                    label: type.name,
                                                                    i18n: type.i18n,
                                                                    value: type.document_type_id,
                                                                    description: type.description,
                                                                    required: settings.attachment_for_new_member_in_registration.value.indexOf(type.document_type_id) !== -1,
                                                                });

                                                                if(index === documentTypes.length - 1){
                                                                    types.push({
                                                                        value: OTHER_VALUE,
                                                                        label: 'misc.other',
                                                                        translateLabel: true,
                                                                    })
                                                                }

                                                                return types;
                                                            }, [])
                                                        })
                                            }
                                        }}
                                        onOptionSelected={([ val ], spordleSelect) => {
                                            setSelectedType(documentTypes.find((type) => type.document_type_id === val));
                                        }}
                                    />
                                    <Collapse isOpen={(!!selectedType && selectedType?.description)} className='small text-muted mt-2'>
                                        <DisplayI18n field='description' defaultValue={selectedType?.description} i18n={selectedType?.i18n} />
                                    </Collapse>
                                </div>
                                <Label className="mb-1 text-muted" for="document-file"><Translate id='account.members.documents.document.label' /> <Required /></Label>
                                <FileUpload
                                    defaultFiles={formik.values.attachment ? [ formik.values.attachment ] : undefined}
                                    name="attachment"
                                    id="document-file"
                                    onFileSelect={(files) => {
                                        formik.setFieldValue('attachment', files[0] || null);
                                    }}
                                    dropzoneProps={{ ...DROPZONE_PROPS, maxSize: selectedType?.file_size ? mbToBytes(parseFloat(selectedType.file_size)) : DROPZONE_PROPS.maxSize || undefined }}
                                />
                                <FormikError name="attachment" />
                                <Collapse isOpen={formik.values.document_type_id === OTHER_VALUE || selectedType?.with_note == 1}>
                                    <div className="pt-3">
                                        <Label className="mb-1 text-muted" for="note">Note <Required /></Label>
                                        <FormikTextArea
                                            name="note"
                                            id="note"
                                        />
                                    </div>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" disabled={formik.isSubmitting} color="primary">
                                    <Translate id="misc.confirm" />
                                </Button>
                                <Button disabled={formik.isSubmitting} color="primary" type="button" outline onClick={toggle}>
                                    <Translate id="misc.cancel" />
                                </Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={isCreated}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon className="text-success mb-2" icon="checkmark" withCircle size={50} />
                                <div className="h5 font-bold"><Translate id='account.members.documents.document.add.success.title' /></div>
                                <p><Translate id='account.members.documents.document.add.success.msg' /></p>
                                <Button onClick={toggle} color="primary" type="button"><Translate id="misc.close" /></Button>
                            </ModalBody>
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default MemberAddDocumentModal;