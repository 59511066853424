import { DisplayI18n } from "../helpers/i18nHelper";

/**
 *
 * @param {Object} props
 * @param {object} props.category Category that contains class + division
 * @param {boolean} [props.short=false] Will display short name if set to true
 * @param {string} [props.field="short_name"] Will be name if short to true
 * @returns {JSX.Element|string}
 */
const DisplayCategory = ({ category, short = false, field = short ? "short_name" : "name" }) => {
    return (
        category ?
            <>
                { category.division && <DisplayI18n i18n={category.division.i18n} defaultValue={category.division[field]} field={field} /> }
                { category.class &&
                    <>{' - '}<DisplayI18n i18n={category.class.i18n} defaultValue={category.class[field]} field={field} /></>
                }
            </>
            :
            '-'
    )
}

export default DisplayCategory;