import React from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import TravelPermitSidepanelGeneral from "./TravelPermitsSidepanelGeneral";
import TravelPermitSidepanelHost from "./TravelPermitsSidepanelHost";
import withContexts from "../../../../../helpers/withContexts";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Translate from "@spordle/intl-elements";
import { fail } from "@spordle/toasts";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import SidePanelMenuAction from "../../../../../components/sidePanel/components/SidePanelMenuAction";
import { AccountsContext, AuthContext } from "../../../../../contexts/contexts";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { PeriodsContext } from "../../../../../contexts/PeriodsContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { triggerDownload } from "../../../../../components/uploader/uploadHelpers";

class TravelPermitSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggleTab = this.toggleTab.bind(this);
        this.state = {
            activeTab: '1',
            settings: false,
        };
    }

    componentDidMount(){
        this.setTeamOrganizationSettings();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].team.organisation.organisation_id !== this.props.selectedRows[0].team.organisation.organisation_id){
            this.setTeamOrganizationSettings();
        }
    }

    setTeamOrganizationSettings = () => {
        this.props.OrganizationContext.getOrganizationSettings(this.props.selectedRows[0].team.organisation.organisation_id)
            .then((settings) => {
                this.setState(() => ({ settings: settings }));
            })
            .catch(console.error)
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleAndRefresh = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    // handles PDF exporting
    printPDF = async() => {
        const periodId = await this.props.PeriodsContext.getActivePeriod(this.props.OrganizationContext.federationId)
        const reportId = 'c33bcec9-4347-11ed-aaa6-02b0a8cb2ee2';
        const data = await this.props.ReportsContext.createExportableReport({
            output: 'PDF',
            organisation_id: this.props.OrganizationContext.federationId,
            total_count: 1,
            language_code: this.props.I18nContext.getGenericLocale(),
            report_id: reportId,
            requested_by: this.props.AuthContext.userData.Username, //identity_id
            request_parameter: JSON.stringify({ travel_permit_id: this.props.selectedRows[0].travel_permit_id, organisation_id: this.props.OrganizationContext.federationId, period_id: periodId, report_type: 'PDF' }),
        }, true).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })

        if(data.download_link){
            triggerDownload(data.download_link);
        }
    }

    render(){
        const data = this.props.selectedRows[0];

        return (
            <>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton />
                        <div className="ml-auto d-flex align-items-center">
                            <SidePanel.ActionsMenu>
                                <SidePanelMenuAction onClick={this.printPDF}>
                                    <i className='fas fa-file-pdf mr-1' /><Translate id='account.members.travelPermits.print' />
                                </SidePanelMenuAction>

                                {data.travel_permit_status?.system_travel_permit_status === 'DRAFT' &&
                                    <>
                                        <ConfirmModal
                                            translateModalMsg
                                            modalMsg='account.members.travelPermits.submit.message'
                                            modalTitle={data.travel_event_name}
                                            color="primary"
                                            icon='mdi mdi-information-outline'
                                            actionButtonText='misc.submit'
                                            translateActionButtonText
                                            onConfirm={() => {
                                                return this.props.AccountsContext.submitTravelPermit(this.props.AuthContext.userData.Username, data.travel_permit_id)
                                                    .then(() => {
                                                        this.props.tableRef.refreshTable();
                                                        this.props.toggle();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                            toggler={(fn) => (
                                                <>
                                                    <SidePanelMenuAction divider />
                                                    <SidePanelMenuAction
                                                        onClick={fn}
                                                    >
                                                        <Translate id='account.members.travelPermits.submit' />
                                                    </SidePanelMenuAction>
                                                </>
                                            )}
                                        />

                                        <SidePanel.MenuDelete
                                            translateModalMsg
                                            modalMsg='account.members.travelPermits.deleteModal.message'
                                            modalTitle={data.travel_event_name}
                                            onConfirm={() => {
                                                return this.props.AccountsContext.deleteTravelPermit(data.team.team_id, data.travel_permit_id)
                                                    .then(() => {
                                                        this.props.tableRef.deleteRow(this.props.getCommonValue('travel_permit_id')).then(this.props.toggle);
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    </>
                                }
                            </SidePanel.ActionsMenu>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="h3 font-bold">{<Translate id='account.members.travelPermits.title' />}</div>
                    </div>
                    <div className='mb-3'>
                        <UserDisplay to="/teams" className="d-inline-flex">
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={data.team?.abbreviation || data.team.name}
                                    src={data.team.image}
                                    width={50}
                                    height={50}
                                    alt={data.team.name}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title className="font-bold h4 mb-0">{data.team.name}</UserDisplay.Title>
                                <UserDisplay.Subtitle>{data.team.organisation.organisation_name}</UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </div>
                </SidePanel.Header>

                <Nav tabs>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            <Translate id='account.members.travelPermits.sidePanelGeneral.tabTitle' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}
                        >
                            <Translate id='account.members.travelPermits.sidePanelHost.tabTitle' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="p-3">
                            <TravelPermitSidepanelGeneral data={{ ...data }} member={this.props.member} syncRows={this.props.syncRows} createNewValues={this.props.createNewValues} settings={this.state.settings} />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="p-3">
                            <TravelPermitSidepanelHost data={{ ...data }} syncRows={this.props.syncRows} createNewValues={this.props.createNewValues} toggleAndRefresh={this.toggleAndRefresh} settings={this.state.settings} />
                        </div>
                    </TabPane>
                </TabContent>
            </>
        )
    }
}

export default withContexts(AccountsContext, AuthContext, ReportsContext, OrganizationContext, I18nContext, PeriodsContext)(TravelPermitSidepanel);