import { useContext } from 'react';
import { Alert, Button, Col, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { Formik, Form } from "formik";
import Translate from '@spordle/intl-elements';
import { FormikSelect } from '@spordle/formik-elements';
import Required from '../../formik/Required';
import moment from 'moment';
import { object, string } from "yup";

// Contexts
import { AccountsContext, AuthContext } from "../../../contexts/contexts";
import UserDisplay from '../../userDisplay/UserDisplay';
import UserImg from '../../UserImg';
import { I18nContext } from '../../../contexts/I18nContext';
import { UtilsContext } from '../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { VIEW_UNSIGNED, VIEW_WARNING, VIEW_WARNING_UNSIGNED } from '../AddMemberModal';

const ConfirmMemberCreation = ({ data, closeModal, changeView, setWarnings, toggleLoading, isLoading, getMetaMembers, setData }) => {
    const accountsContext = useContext(AccountsContext);
    const authContext = useContext(AuthContext);
    const utilsContext = useContext(UtilsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const linkMember = (values) => {
        return accountsContext.createMetaMember(authContext.userData.Username, values.firstName, values.lastName, values.dateOfBirth, values.relation, values.email, values.member_id)
            .then((metaId) => {
                return accountsContext.linkMemberToMetaMember(authContext.userData.Username, metaId, [ values.member_id ])
            })
    }

    return (
        <Formik
            initialValues={{
                relation: '',
            }}
            validationSchema={object().shape({
                relation: string().required(),
            })}
            onSubmit={(values, { setStatus }) => {
                toggleLoading(true);

                const newValues = {
                    ...data,
                    organizationId: values.organizationId,
                    relation: values.relation,
                    dateOfBirth: (moment.isMoment(data.dateOfBirth)) ? data.dateOfBirth.format("YYYY-MM-DD") : moment(data.dateOfBirth).format("YYYY-MM-DD"),
                };

                return linkMember({ ...newValues, member_id: data?.member_id })
                    .then(async(res) => {
                        const newMetaMember = (await getMetaMembers(true)).meta_members.find((metaMember) => metaMember.members?.[0]?.member_id === data?.member_id);
                        const warnings = Array.isArray(res.warnings) && res.warnings.length > 0 ? res.warnings : [];

                        if(warnings.length > 0){
                            setWarnings(warnings);
                        }

                        if(newMetaMember && newMetaMember?.members[0].unsigned_critical_waivers == 1){
                            toggleLoading(false);
                            setData(newMetaMember)
                            changeView(warnings.length > 0 ? VIEW_WARNING_UNSIGNED : VIEW_UNSIGNED);
                        }else if(warnings.length > 0){
                            toggleLoading(false);
                            changeView(VIEW_WARNING);
                        }else{
                            closeModal();
                        }
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus({
                                code: e.code,
                                error: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                            })
                            toggleLoading(false);
                        }
                    })

            }}
        >
            { (formik) => (
                <Form>
                    <ModalBody>
                        <UserDisplay card>
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={data.firstName.charAt(0) + data.lastName.charAt(0)}
                                    src={null}
                                    alt={data.firstName + ' ' + data.lastName}
                                    height={40}
                                    width={40}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title>{data?.firstName} {data?.lastName}</UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    {moment(data?.dateOfBirth).locale(getGenericLocale()).format('LL')} (<Translate id='misc.yearsOld' values={{ age: moment().diff(data?.dateOfBirth, 'years') }} />)
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                        <div className="h5 font-medium mb-4"><Translate id='addMemberModal.confirm.text' /></div>
                        <Row form>
                            <Col md='12'>
                                <FormGroup>
                                    <Label for='relation'><Translate id='form.fields.relation' /> <Required /></Label>
                                    <FormikSelect
                                        id='relation'
                                        name='relation'
                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return utilsContext.getRelations()
                                                        .then((relations) => (
                                                            relations.map((relation) => ({
                                                                id: relation.relation_id,
                                                                value: relation.relation_id,
                                                                label: relation.name,
                                                                i18n: relation.i18n,
                                                            }))
                                                        ))
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {formik.status &&
                                <Alert color='danger'>
                                    {formik.status.error}
                                    {formik.status.code == 2000 &&
                                        <Button
                                            type="button"
                                            color="link"
                                            className="reset-btn mt-1 text-decoration-underline alert-danger text-left d-block font-bold"
                                            disabled={formik.isSubmitting}
                                            onClick={() => changeView(1)}
                                        >
                                            <Translate id='addMemberModal.error.2000.btn' />
                                        </Button>
                                    }
                                </Alert>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={() => changeView(1)} color="primary" outline><Translate id='misc.previous' /></Button>
                        <Button type="submit" className="ml-auto" color="primary" disabled={formik.isSubmitting || isLoading}><Translate id='misc.link' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default ConfirmMemberCreation;