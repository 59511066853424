import Translate, { DateFormat } from "@spordle/intl-elements";

/**
 * @description prefix of schedule classes
 */
export const classPrefix = "spdle-calendar";

/**
 * @description checks if param is an object
 * @param {any} item
 * @returns {boolean} Returns true if param is object, else returns false
 */
export const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item));

/**
 * @description Returns formatted hour
 * @param {string} start
 * @param {string} end
 */
export function formatHour(start, end){
    if(start && end)return start + ' - ' + end;

    if(start)return <Translate id='schedule.label.time.startsAt' /> + ' ' + start;

    if(end)return <><Translate id='schedule.label.time.until' /> <DateFormat value={end} format={'h:mm a'} /></>;

    return <Translate id='schedule.label.time.allDay' />;
}

/**
 * @description Component that returns formatted hour
 * @param {string} date YYYY-MM-DD
 * @param {string} [start] HH:mm
 * @param {string} [end] HH:mm
 * @param {string} [startClassName]
 * @param {string} [endClassName]
 * @returns {JSX.Element}
 */
export const FormatHour = ({ date, start, end, startClassName, endClassName }) => (
    end ?
        <>
            {
                start ?
                    <>
                        <time className={startClassName} dateTime={`${date}T${start}`}>{start}</time>
                        {` - `}
                    </>
                    :
                    <Translate id='schedule.label.time.until' />
            }
            <time className={endClassName} dateTime={`${date}T${end}`}>{end}</time>
        </>
        :
        start ?
            <><Translate id='schedule.label.time.startsAt' /> <time className={startClassName} dateTime={`${date}T${start}`}>{start}</time></>
            :
            <Translate id='schedule.label.time.allDay' />
);