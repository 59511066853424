import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Alert, Button, Card, CardBody, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { AccountsContext } from "../../../../../../../contexts/contexts";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import PaymentOverview from "./PaymentOverview";

const SportPayView = ({ toggle, invoiceItem, paymentMode }) => {
    const [ isGettingUrl, setIsGettingUrl ] = useState(false);
    const [ error, setError ] = useState(false);

    const { attachCardsPayment } = useContext(MembersContext);
    const { getPaymentData } = useContext(AccountsContext);

    const manageOnSportPay = () => {
        setIsGettingUrl(true);

        if(paymentMode){
            getPaymentData(invoiceItem.shopping_cart_id)
                .then((paymentData) => {
                    window.location.replace(paymentData.url);
                })
                .catch((err) => {
                    if(!AxiosIsCancelled(err.message)){
                        setIsGettingUrl(false);
                        console.error(err);
                        setError(<DisplayI18n field="message" i18n={err.i18n} defaultValue={err.message} />);
                    }
                });
        }else{
            attachCardsPayment(invoiceItem.invoice_payment_id, window.location.href)
                .then((data) => {
                    window.location.replace(data.URL);
                })
                .catch((err) => {
                    if(!AxiosIsCancelled(err.message)){
                        setIsGettingUrl(false);
                        console.error(err);
                        setError(<DisplayI18n field="message" i18n={err.i18n} defaultValue={err.message} />);
                    }
                });
        }
    }

    return (
        <OverlayLoader isLoading={isGettingUrl}>
            <ModalHeader toggle={toggle}><Translate id='account.paymentMethods.changeCard.modal.title' /></ModalHeader>
            <ModalBody>
                <PaymentOverview invoiceItem={invoiceItem} />
                <Card className="card-shadow mb-0">
                    <CardBody>
                        <div className="font-medium h4">SportsPay</div>
                        <Translate id='account.paymentMethods.changeCard.sportPay.text' values={{ pa: (chunks) => <p>{chunks}</p> }} />
                        <Collapse isOpen={!!error} appear>
                            <Alert className="mb-0" color="danger" toggle={() => setError(null)}>
                                {error}
                            </Alert>
                        </Collapse>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button type="button" disabled={isGettingUrl} onClick={manageOnSportPay} color="primary"><Translate id='account.paymentMethods.changeCard.sportPay.btn.manage' /> <i className="mdi mdi-launch" /></Button>
                <Button type="button" disabled={isGettingUrl} onClick={toggle} color="primary" outline><Translate id="misc.cancel" /></Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default SportPayView;