import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill'; // This has en internal validation for using the native exists or not

import WithHcrTheme from "../components/HOC/WithHcrTheme";

// Components
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';

// Routes
import accountRoutes from '../routes/accountRoutes/accountRoutes'

// Context Providers
import { AccountsContext, AuthContext } from '../contexts/contexts';
import { OrganizationContext } from '../contexts/OrganizationContext';
import withContexts from '../helpers/withContexts';
import NotificationPopups from '../components/notificationPopupModal/NotificationPopups';
import { mobileView, smallDesktopView } from '../helpers/constants';
import setDefaultComponentProps from '../helpers/setDefaultComponentProps';

const themeValues = {
    activeDir: "ltr",
    activeThemeLayout: "vertical",
    activeTheme: "light",
    activeSidebarType: "full",
    activeLogoBg: "skin6",
    activeNavbarBg: "skin6",
    activeSidebarBg: "skin5",
    activeSidebarPos: "fixed",
    activeHeaderPos: "fixed",
    activeLayout: "full",
}
class Fulllayout extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isSmall: window.innerWidth < smallDesktopView,
        };

        this.reziseObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const isSmall = entry.contentRect.width < smallDesktopView;
                if(isSmall !== this.state.isSmall){
                    this.setState({ isSmall: isSmall });
                }
            });
        });
        this.historyListener = this.props.history.listen(() => {
            if(window.innerWidth < mobileView){
                this.mainWrapper?.classList.remove("show-sidebar");
            }
        });
        setDefaultComponentProps();
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        this.props.OrganizationContext.setFederation();
        this.props.AccountsContext.getAccountInvoices(this.props.AuthContext.userData.Username).catch(console.error);
        this.reziseObserver.observe(this.mainWrapper)
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook                                                                 */
    /*--------------------------------------------------------------------------------*/
    componentWillUnmount(){
        this.historyListener();
        this.reziseObserver.disconnect();
    }

    render(){
        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options will be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        return (
            <div
                ref={(r) => this.mainWrapper = r}
                id="main-wrapper"
                dir={themeValues.activeDir}
                data-theme={themeValues.activeTheme}
                data-layout={themeValues.activeThemeLayout}
                data-sidebar-position={themeValues.activeSidebarPos}
                data-header-position={themeValues.activeHeaderPos}
                data-boxed-layout={themeValues.activeLayout}
                data-sidebartype={this.state.isSmall ? 'mini-sidebar' : themeValues.activeSidebarType}
                className={this.state.isSmall ? 'mini-sidebar' : undefined}
            >

                {/*--------------------------------------------------------------------------------*/}
                {/* Header                                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <Header
                    {...this.props}
                    themeValues={themeValues}
                />

                {/*--------------------------------------------------------------------------------*/}
                {/* Sidebar                                                                        */}
                {/*--------------------------------------------------------------------------------*/}
                <Sidebar
                    {...this.props}
                    themeValues={themeValues}
                />

                {/*--------------------------------------------------------------------------------*/}
                {/* Page Main-Content                                                              */}
                {/*--------------------------------------------------------------------------------*/}
                <div className="page-wrapper min-vh-100 d-block">
                    <NotificationPopups />

                    {/* <FailedPaymentModal /> */}
                    <Switch>
                        {accountRoutes.map((prop) => {
                            if(prop.navlabel || prop.divider){
                                return null;
                            }else if(prop.redirect){
                                return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} exact={(prop.exact)} />;
                            }

                            if(!prop.powerUserOnly || (!!prop.powerUserOnly && this.props.AuthContext.isPowerUser())){
                                return (
                                    <Route path={prop.path} component={prop.component} key={prop.path} exact={(prop.exact)} />
                                );
                            }
                            return null;

                        })}
                    </Switch>
                </div>
            </div>
        )
    }
}
export default withContexts(OrganizationContext, AccountsContext, AuthContext)(WithHcrTheme(Fulllayout));