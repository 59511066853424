import { FormikError, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Col, Collapse, Label, Row } from "reactstrap";
import Required from "../../../../../../../../components/formik/Required";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../components/UserImg";
import CollapseIcon from "../../../CollapseIcon";
import { OFFICIAL_TYPES } from "../../../gameIncidentHelper";

const OfficialListItem = ({ official, index, getSelectProps, emailPhoneRequired }) => {
    const { errors, touched, setFieldValue, values } = useFormikContext();
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(true);
    const [ remove, setRemove ] = useState(false);

    const hasError = !remove && !collapseIsOpen && !!errors.officials?.[index] && !!touched.officials?.[index];
    const handleRemove = () => setFieldValue('officials', values.officials.filter((_, i) => i !== index));

    return (
        <Collapse onExited={handleRemove} isOpen={!remove} className={stringBuilder({ "border-danger": hasError }, "bg-light-inverse border transition rounded-lg mb-3")}>
            <UserDisplay
                onClick={() => setCollapseIsOpen(!collapseIsOpen)}
                className={stringBuilder("clickable mb-0 transition flex-wrap border-0 flex-sm-nowrap", { "bg-light-danger": hasError })}
                card
                block
            >
                <UserDisplay.Container>
                    <CollapseIcon isOpen={collapseIsOpen} />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserImg
                        width={50}
                        className="text-uppercase"
                        src={official.attachment?.full_path}
                        filePos={official.attachment?.file_position}
                        alt={official.first_name + ' ' + official.last_name}
                        abbr={official.first_name.charAt(0) + official.last_name.charAt(0)}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserDisplay.Title>{official.first_name} {official.last_name}</UserDisplay.Title>
                    <UserDisplay.Subtitle>#{official.unique_identifier}</UserDisplay.Subtitle>
                </UserDisplay.Container>
                <UserDisplay.Container className="ml-auto">
                    {index !== 0 &&
                        <button onClick={() => setRemove(true)} type="button" className="reset-btn text-danger">
                            <i className="mdi mdi-delete" />
                        </button>
                    }
                </UserDisplay.Container>
            </UserDisplay>
            <Collapse isOpen={collapseIsOpen}>
                <div className="p-3">
                    <Row form>
                        <Col className="mb-3 mb-lg-0" lg="3">
                            <Label for={'officials.' + index + '.official_type'}><Translate id='account.members.gir.addModal.label.officialType' /> <Required /></Label>
                            <FormikSelect
                                id={'officials.' + index + '.official_type'} name={'officials.' + index + '.official_type'}
                                className="w-100"
                                search={false}
                                options={OFFICIAL_TYPES?.map((type) => ({
                                    label: 'account.members.gir.addModal.steps.officials.type.' + type,
                                    value: type,
                                }))}
                                renderOption={(option) => <Translate id={option.option.label} />}
                            />
                        </Col>
                        <Col className="mb-3 mb-lg-0" lg="3">
                            <Label for={'officials.' + index + '.email'}>
                                <Translate id='form.fields.email' /> {emailPhoneRequired && <Required />}
                            </Label>
                            <FormikInputText placeholder="example@email.ca" translatePlaceholder={false} trim name={'officials.' + index + '.email'} />
                        </Col>
                        <Col lg="6">
                            <Label for={'officials.' + index + '.phone_number'}>
                                <Translate id='form.fields.phone' /> {emailPhoneRequired && <Required />}
                            </Label>
                            <div className="d-flex">
                                {/* PHONE NUMBER */}
                                <FormikPhoneInput
                                    name={'officials.' + index + '.phone_number'}
                                    id={'officials.' + index + '.phone_number'}
                                    manualError
                                />
                                <FormikInputNumber
                                    style={{
                                        flexBasis: '33%',
                                    }}
                                    prefix="#"
                                    placeholder="Ext"
                                    translatePlaceholder={false}
                                    name={'officials.' + index + '.phoneExt'}
                                />
                            </div>
                            <FormikError name={'officials.' + index + '.phone_number'} />
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </Collapse>
    );
}

export default OfficialListItem;