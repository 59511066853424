import PropTypes from 'prop-types';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { DisplayI18n } from '../../helpers/i18nHelper';

const InvoiceItemsRegistration = ({ invoice, item }) => {
    return (
        <tr>
            <td className="border-top-0 pt-0 ">
                <span className="font-medium"><Translate id='invoice.table.registration' /></span>: <DisplayI18n field="name" i18n={item.registration_fee.fee.i18n} defaultValue={item.registration_fee.fee.name} />
                <small className="d-block">
                    <span className="font-medium"><Translate id='invoice.table.season' /></span>: <DisplayI18n field='name' defaultValue={invoice.period.name} i18n={invoice.period.i18n} />
                </small>
            </td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={(parseInt(item.amount) + parseInt(item.affiliation_fee_amount)) / 100} />
            </td>
            <td className="border-top-0 pt-0">X</td>
            <td className="border-top-0 pt-0">1</td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={(parseInt(item.amount) + parseInt(item.affiliation_fee_amount)) / 100} />
            </td>
        </tr>
    );
}

InvoiceItemsRegistration.propTypes = {
    invoice: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default InvoiceItemsRegistration;