/* eslint-disable react/no-array-index-key */
/*eslint indent: "off"*/
import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import { history } from './helpers';
import queryString from 'query-string';
import HelpCenter from './components/helpCenter/HelpCenter';

// Routes
import PrivateRoute from './routes/PrivateRoutes';
import PublicRoute from './routes/PublicRoutes';
import DynamicRoute from './routes/DynamicRoute';
import routes from './routes/routes';

// Context Providers
import I18nContextProvider, { I18nContext } from './contexts/I18nContext';
import ThemeContextProvider, { ThemeContext } from './contexts/ThemeContext';
import AuthContextProvider from './contexts/AuthContext';
import AccountsContextProvider from './contexts/AccountsContext';
import MembersContextProvider from './contexts/MembersContext';
import NotificationsContextProvider from './contexts/NotificationsContext';
import AddressTypesContextProvider from './contexts/AddressTypesContext';
import OrganizationContextProvider from './contexts/OrganizationContext';
import OnlineStoreContextProvider from './contexts/OnlineStoreContext';
import UtilsContextProvider, { UtilsContext } from './contexts/UtilsContext';
import AccountsStatusProvider from './contexts/AccountsStatusContext';
import WaiversContextProvider from './contexts/WaiversContext';
import ReportsContextProvider from './contexts/ReportsContext';
import OfficiatingCoachContextProvider from './contexts/OfficiatingCoachContext';
import GameIncidentsContextProvider from './contexts/GameIncidentsContext';
import ClinicsContextProvider from './contexts/ClinicsContext';
import TeamCategoriesContextProvider from './contexts/TeamCategoriesContext';
import JiraContextProvider from './contexts/JiraContext';

// Language
import { I18nProvider } from './i18n';

import Spinner from 'reactstrap/es/Spinner';
import Fade from 'reactstrap/es/Fade';

import { ErrorBoundary as SentryErrorBoundary, withProfiler as withSentryProfiler } from '@sentry/react';
import FatalError from './FatalError';
import HelpCenterContextProvider from './contexts/HelpCenterContext';

import './helpers/yupValidations';// Imports all custom Yup validations for the entire project NOTE: import here and not in index.js to preserve dynamic imports for Yup

import { captureGAError, GApageView } from '@spordle/ga4';
import SpordleToast from '@spordle/toasts';
import { SWRConfig } from 'swr';

import BrowserNotSupported from './BrowserNotSupported';
import SupervisionsProvider from './contexts/SupervisionsContext';
import PeriodsContextProvider from './contexts/PeriodsContext';
import TeamsContextProvider from './contexts/TeamsContext';
import { FormikElementsGlobalConfigs } from '@spordle/formik-elements';
import { isProdFishNChips } from './helpers/getReferer';

export const spordleOAuthKey = queryString.parseUrl(window.location.href).query.spordleOAuth;

// Force build 5

class App extends React.Component{
    constructor(props){
        super(props);
        this.isSupportedBrowser = new RegExp(process.env.REACT_APP_USERAGENT_REGEX.substring(1, process.env.REACT_APP_USERAGENT_REGEX.length - 1)).test(window.navigator.userAgent);
    }

    render(){
        return (
            <>
                <SentryErrorBoundary
                    fallback={FatalError} showDialog
                    onError={(error) => {
                        captureGAError(error.message);
                    }}
                >
                <SWRConfig value={{
                    shouldRetryOnError: false,
                    revalidateOnFocus: false,
                    onError: (error) => { console.error(error); },
                }}
                >
                    <ThemeContextProvider>
                        <ThemeContext.Consumer>
                            {({ getTheme }) => (
                                <I18nContextProvider>
                                <AuthContextProvider>
                                <AccountsContextProvider>
                                <AccountsStatusProvider>
                                <OrganizationContextProvider>
                                <MembersContextProvider>
                                <AddressTypesContextProvider>
                                <OnlineStoreContextProvider>
                                <WaiversContextProvider>
                                <GameIncidentsContextProvider>
                                <HelpCenterContextProvider>
                                <ClinicsContextProvider>
                                <OfficiatingCoachContextProvider>
                                <SupervisionsProvider>
                                <PeriodsContextProvider>
                                <TeamCategoriesContextProvider>
                                <TeamsContextProvider>
                                <NotificationsContextProvider>
                                <JiraContextProvider>
                                <ReportsContextProvider>
                                <UtilsContextProvider>
                                    <UtilsContext.Consumer>
                                        {(utilsContext) => (
                                            <FormikElementsGlobalConfigs
                                                formikPhoneInput={{
                                                    suggestedCountries: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                                    defaultSelectedCountry: isProdFishNChips() ? 'gb' : 'ca',
                                                }}
                                                formikDateTime={{
                                                    dateFormat: 'YYYY-MM-DD',
                                                }}
                                                formikAddress={{
                                                    getCountries: utilsContext.getCountries,
                                                    getProvinces: utilsContext.getProvinces,
                                                    countryRestrictions: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                                }}
                                            >
                                                <I18nContext.Consumer>
                                                    {({ locale }) => (
                                                        <I18nProvider locale={locale}>
                                                            <SpordleToast config={{ position: { top: 60 } }} />
                                                            <div id='theme-container' className={getTheme()}>

                                                                <Router basename="/" history={history}>
                                                                    <GA4Routing />
                                                                    <HelpCenter />
                                                                    <Suspense fallback={
                                                                        <div className="auth-wrapper">
                                                                            <Fade className='mx-auto'>
                                                                                <Spinner color="primary" type="grow" />
                                                                            </Fade>
                                                                        </div>
                                                                    }
                                                                    >
                                                                        <Switch>
                                                                            {!this.isSupportedBrowser &&
                                                                                <Route component={BrowserNotSupported} />
                                                                            }
                                                                            {routes.map((prop, key) => {
                                                                                if(prop.redirect){
                                                                                    return <Redirect from={prop.path} to={prop.pathTo} key={key} exact={prop.exact === true} />;
                                                                                }else if(process.env.REACT_APP_ENVIRONMENT === 'prod' && prop.devOnly){
                                                                                    return <Redirect from={prop.path} to='/404' key={key} />;
                                                                                }else if(prop.dynamicComponent){
                                                                                    return <DynamicRoute {...prop} key={key} />
                                                                                }else if(prop.bundleRoute){
                                                                                    return (
                                                                                        <Route
                                                                                            path={prop.path} key={key} render={(routeProps) => (
                                                                                                <prop.bundleRoute routeProps={routeProps} {...prop} />
                                                                                            )}
                                                                                        />
                                                                                    )
                                                                                }else if(prop.privateRoute){
                                                                                    return <PrivateRoute path={prop.path} key={key} component={prop.component} bundleRoute={prop.bundleRoute} />;
                                                                                }

                                                                                    return <PublicRoute path={prop.path} key={key} component={prop.component} bundleRoute={prop.bundleRoute} />;

                                                                            })}
                                                                        </Switch>
                                                                    </Suspense>
                                                                </Router>
                                                            </div>
                                                        </I18nProvider>
                                                    )}
                                                </I18nContext.Consumer>
                                            </FormikElementsGlobalConfigs>
                                        )}
                                    </UtilsContext.Consumer>
                                </UtilsContextProvider>
                                </ReportsContextProvider>
                                </JiraContextProvider>
                                </NotificationsContextProvider>
                                </TeamsContextProvider>
                                </TeamCategoriesContextProvider>
                                </PeriodsContextProvider>
                                </SupervisionsProvider>
                                </OfficiatingCoachContextProvider>
                                </ClinicsContextProvider>
                                </HelpCenterContextProvider>
                                </GameIncidentsContextProvider>
                                </WaiversContextProvider>
                                </OnlineStoreContextProvider>
                                </AddressTypesContextProvider>
                                </MembersContextProvider>
                                </OrganizationContextProvider>
                                </AccountsStatusProvider>
                                </AccountsContextProvider>
                                </AuthContextProvider>
                                </I18nContextProvider>
                            )}
                        </ThemeContext.Consumer>
                    </ThemeContextProvider>
                </SWRConfig>
                </SentryErrorBoundary>
            </>
        )
    }
}

const GA4Routing = () => {
    const routerHistory = useHistory();
    useEffect(() => {
        GApageView(routerHistory.location.pathname);// App load
        const historyListener = routerHistory.listen((location) => {
			GApageView(location.pathname);
        });
        return historyListener;
    }, [])
    return null;
}

export default withSentryProfiler(App);
