/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';/**
 * Displays a red star for required fields
 * @returns {ReactNode}
 */
const Required = () => jsx("span", { className: 'text-danger', children: "*" });
/**
 * Function that returns the necessary Formik initial values for FormikAddress
 * @param {Record<string, any>} [initialValues] Initial address that comes directly from the API or that has been pre-formatted to the FormikAddress format
 * @returns {Record<string, string>}
 */
const getFormikAddressInitialValues = (initialValues) => ({
    streetNumber: initialValues?.streetNumber || initialValues?.street_number || '',
    address: initialValues?.address || initialValues?.street || '',
    city: initialValues?.city || '',
    state: initialValues?.state || initialValues?.province_code || initialValues?.province_id || '',
    country: initialValues?.country || initialValues?.country_code || initialValues?.country_id || '',
    zipCode: initialValues?.zipCode || initialValues?.zip_code || '',
    fullAddress: initialValues ? getFullAddress(initialValues) : '',
    origin: initialValues?.origin || initialValues?.origin_address || '',
    mapsUrl: initialValues?.mapsUrl || initialValues?.map_url || '',
});
const findInFullAddress = (fullAddress, string) => {
    return (fullAddress.includes(string)) && string;
};
/**
 * Function that builds an address string from data directly from the API or that has been pre-formatted to the FormikAddress format
 * @param {Record<string, any>} data
 * @returns {string} Built address
 */
const getFullAddress = (data) => {
    const address = [];
    if (data.streetNumber || data.street_number)
        address.push(data.streetNumber || data.street_number);
    if (data.address || data.street)
        address.push(data.address || data.street);
    if (data.city)
        address.push(data.city);
    if (data.state || data.province_code || data.province_id)
        address.push(data.state || data.province_code || data.province_id);
    if (data.zipCode || data.zip_code)
        address.push(data.zipCode || data.zip_code);
    if (data.country || data.country_code || data.country_id)
        address.push(data.country || data.country_code || data.country_id);
    return address.length > 0 ? address.join(', ') : '';
};export{Required,findInFullAddress,getFormikAddressInitialValues,getFullAddress};
/*
@Copyrights Spordle 2023 - All rights reserved
*/