import React, { createContext } from 'react';
import API_PUBLIC from '../api/API-Public';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

// Contexts
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<NotificationsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const NotificationsContext = createContext();
NotificationsContext.displayName = 'NotificationsContext';

class NotificationsContextProvider extends React.Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {
            hasDeclinedPayment: false,
        }

        this.state = this._initState;
    }

    /**
     * Changes the status of the declined payment
     * @param {boolean} bool
     */
    setDeclinedPayment = (bool) => {
        this.setState({ hasDeclinedPayment: bool });
    }

    /**
     * Gets all notifications
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AgetAllNotifications|documentation}
     * @returns {Promise.<Array>}
     */
    getNotifications = (identityId) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/platform-notifications`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(response.data.notifications.some((notif) => notif.notification_type.code === "installment-failed")){
                        this.setDeclinedPayment(true);
                    }

                    return response.data.notifications;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Mark all notifications in account ass read
     * @returns {Promise.<Array>}
     */
    updateMarkAllAsRead = (identityId) => {
        return API_PUBLIC_LOGGEDIN.patch(`/accounts/${identityId}/notifications/mark-as-read`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Mark a notification as read
     * @param {object} [notification_id] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AgetAllNotifications|documentation}
     * @returns {Promise.<Array>}
     */
    updateMarkAsRead = (notification_id) => {
        return API_PUBLIC_LOGGEDIN.patch(`/notifications/${notification_id}/mark-as-read`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete notifications
     * @param {string} [notification_id]  Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AdeleteNotification|documentation}
     * @returns {Promise.<Boolean>}
     */
    deleteNotification = (notification_id) => {
        return API_PUBLIC_LOGGEDIN.delete(`/notifications/${notification_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes all notifications of an identity
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AdeleteToIdentity|documentation}
     * @returns {Promise.<Boolean>}
     */
    deleteAllNotifications = (identityId) => (
        API_PUBLIC_LOGGEDIN.delete(`/accounts/${identityId}/notifications`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    )


    render(){
        return (
            <NotificationsContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </NotificationsContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(NotificationsContextProvider);