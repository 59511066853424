import React from 'react';
import { Link } from 'react-router-dom';
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Fade,
    Label,
    Button,
    Collapse,
    Spinner
} from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import queryString from 'query-string';

// Context Providers
import { AuthContext } from "../../contexts/contexts";

// Components
import AuthHeader from '../../components/tour/Authheader';

// Language
import Translate from '@spordle/intl-elements';
import CapsLock from '@spordle/capslock';
import { FormikInputText, FormikCheckedButton } from '@spordle/formik-elements';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import GoToHomeBtn from '../../components/GoToHomeBtn';
import LangSwitcher from '../../components/LangSwitcher';
import WithTourTheme from '../../components/HOC/WithTournamentTheme';

class Login extends React.PureComponent{
    static contextType = AuthContext;

    constructor(props){
        super(props);
        this.params = queryString.parse(this.props.location.search);
        this.admintoken = this.params.admintoken;

        if(!this.params.referrer){
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    // TODO
                    this.params.referrer = 'Some prod url';
                    break;
                case 'stage':
                case 'dev':
                    this.params.referrer = 'https://dev.d22wymmaco0ct.amplifyapp.com/auth';
                    break;

                default:
                    break;
            }
        }

        this.state = {
            withAdminToken: this.admintoken !== undefined,
        }
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                    <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                        <GoToHomeBtn />
                        <LangSwitcher />
                    </div>
                    <AuthHeader />

                    <div className="auth-box my-0">
                        <Fade>
                            {this.state.withAdminToken === true ?
                                <div className="text-center">
                                    <Spinner color="info" style={{ width: '80px', height: '80px' }} type="grow" />
                                    <div className="h4"><Translate id='login.adminConnexion.loading' /></div>
                                </div>
                                :
                                <>
                                    <h2 className="h2 font-medium mb-3"><Translate id='login.form.label.title' /></h2>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: '',
                                            rememberMe: '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .required(<Translate id='login.form.validation.email.required' />)
                                                .email(<Translate id='login.form.validation.email.email' />),
                                            password: Yup.string()
                                                .required(<Translate id='login.form.validation.password.required' />),
                                        })}
                                        onSubmit={({ email, password, rememberMe }, { setStatus, setSubmitting }) => {
                                            setStatus();

                                            this.context.login(email, password, rememberMe)
                                                .then((result) => {
                                                    const url = `${this.params.referrer}?accessToken=${result.AuthenticationResult.AccessToken}&idToken=${result.AuthenticationResult.IdToken}&type=${result.AuthenticationResult.TokenType}`;
                                                    if(process.env.REACT_APP_ENVIRONMENT === 'int'){
                                                        // When developping, send to another tab for testing.
                                                        // Comment the next line if you don't want to open a new tab everytime this is executed
                                                        window.open(url, undefined, 'noopener,noreferrer');
                                                        setSubmitting(false);
                                                    }else{
                                                        window.location.replace(url);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        setStatus(error.message);
                                                        setSubmitting(false);
                                                    }
                                                });
                                        }}
                                    >
                                        {(formik) => (
                                            <Form id="login_form">
                                                <FormGroup>
                                                    <Label for="email">
                                                        <Translate id='login.form.label.email' />
                                                    </Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ti-user" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>

                                                        <FormikInputText
                                                            id="email"
                                                            name="email"
                                                            type="text"
                                                            trim
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="password">
                                                        <Translate id='login.form.label.password' />
                                                    </Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ti-lock" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <FormikInputText
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            trim
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                <div className="d-flex no-block align-items-center mb-3">
                                                    <FormikCheckedButton id="rememberMe" label="login.form.label.rememberMe" name="rememberMe" />
                                                </div>
                                                <div className="mb-2">
                                                    <Button type="submit" block color='primary' disabled={formik.isSubmitting}><Translate id='login.form.action.submit' /></Button>
                                                </div>
                                                <FormGroup>
                                                    <Link
                                                        id="startRecover"
                                                        to="/forgot-password"
                                                        className="forgot text-link ml-auto"
                                                    >
                                                        <Translate id='login.form.action.forgotPassword' />
                                                    </Link>
                                                </FormGroup>
                                                <CapsLock>
                                                    {(isActive) => (
                                                        <Collapse isOpen={isActive}>
                                                            <div className='alert alert-warning mb-0'><i className="mr-1 mdi mdi-apple-keyboard-caps" /><Translate id='warning.capsLock' /></div>
                                                        </Collapse>
                                                    )}
                                                </CapsLock>
                                                {formik.status &&
													<div className={'alert alert-danger'}><Translate id={`login.error.${formik.status}`} /></div>
                                                }
                                                <div className="border-top pt-4 mt-4">
                                                    <div className="mb-1">
                                                        <Translate id='login.form.label.signup' />
                                                        <Link to="/signup" className="ml-1">
                                                            <Translate id='login.form.action.signup' />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            }
                        </Fade>
                    </div>
                </div>
            </>
        );
    }
}

export default WithTourTheme(Login);
