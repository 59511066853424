import { captureMessage, withScope, Severity, addBreadcrumb } from '@sentry/react';
import Translate from '@spordle/intl-elements'
import { useFormikContext } from 'formik'
import { useContext, useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Alert, Button, Col, Collapse, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap'
import useSWR from 'swr'
import { object, string } from 'yup'
import Required from '../../../../../../../components/formik/Required'
import { success } from '@spordle/toasts'
import { AccountsContext, AuthContext } from '../../../../../../../contexts/contexts'
import { FormikAddress } from '@spordle/formik-elements';

const PAYSAFE_OPTIONS = {
    style: {
        input: {
            "font": "400 14px Arial",
            "font-family": "Montserrat, sans-serif",
            "line-height": "1.5",
            "color": "#525f7f",
        },
    },
    environment: process.env.REACT_APP_ENVIRONMENT === 'prod' ? "LIVE" : "TEST",
    fields: {
        cardNumber: {
            selector: "#add-card-cardNumber",
        },
        expiryDate: {
            selector: "#add-card-expirationDate",
        },
        cvv: {
            selector: "#add-card-cvv",
        },
    },
};

const PaysafeCreateCard = ({ changeView, invoiceNumber, invoicePaymentId, updateVaultCards }) => {
    const auth = useContext(AuthContext);
    const accountsContext = useContext(AccountsContext);
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const paysafeScriptContainer = useRef();
    const paysafeInstance = useRef();
    const paysafeSetupCount = useRef(0);

    const formik = useFormikContext();

    const [ paysafeLoading, setPaysafeLoading ] = useState(true);
    const [ formError, setError ] = useState();
    const [ firstName, setFirstName ] = useState(auth.userData.UserAttributes.name);
    const [ lastName, setLastName ] = useState(auth.userData.UserAttributes.family_name);

    const { data: sutToken, isValidating } = useSWR(
        [ 'getSutToken', invoiceNumber ],
        () => accountsContext.getSutToken(invoiceNumber),
    );

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://hosted.paysafe.com/js/v1/latest/paysafe.min.js";
        script.type = 'text/javascript';
        script.onload = () => { setPaysafeLoading(false) }

        paysafeScriptContainer.current.appendChild(script);
        return () => {
            script.remove();
        }
    }, []);

    const isLoading = isValidating || paysafeLoading;

    useEffect(() => {
        if(!isLoading){
            const setupPaysafeJs = () => {
                window.paysafe.fields.setup(sutToken, PAYSAFE_OPTIONS, (newPaysafeInstance, error) => {
                    if(error){
                        console.error(error.code + " " + error.detailedMessage);
                        if(paysafeSetupCount.current >= 2){
                            setError(<Translate id={`paysafe.error.${error.code}`} />);
                            withScope((scope) => {
                                scope.addBreadcrumb({
                                    message: 'Tried paysafe setup 3 times and failed all 3 times',
                                    level: Severity.Debug,
                                    category: 'paysafe init',
                                });
                                scope.setExtra('setup-errors', 'https://developer.paysafe.com/en/sdks/paysafejs/setup/#callback-errors');
                                captureMessage(`Paysafe.js failed to load with code: ${error.code}`);
                            })
                        }else{
                            paysafeSetupCount.current++;
                            setPaysafeLoading(true);
                            addBreadcrumb({
                                message: `Tried paysafe setup ${paysafeSetupCount.current} time(s). Retrying in 5 seconds...`,
                                level: Severity.Debug,
                                category: 'paysafe init',
                            });
                            setTimeout(setupPaysafeJs, 5000);
                        }
                    }else{
                        setPaysafeLoading(false);

                        paysafeInstance.current = newPaysafeInstance;

                        newPaysafeInstance.fields("cvv cardNumber expiryDate").on("Invalid Valid Blur FieldValueChange", (_instance, event) => {
                            switch (event.type){
                                case "Invalid":
                                    event.target.containerElement.classList.add("is-invalid");
                                    break;
                                case "Valid":
                                    event.target.containerElement.classList.remove("is-invalid");
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                });
            }
            setupPaysafeJs();
        }
    }, [ isLoading ]);

    /**
     * Function that emulates the same object structure as the Yup schema validation
     * @param {string} [msg='misc.error'] The error message to be translated
     * @returns Emulating the same object structure as the Yup schema validation
     */
    const buildError = (msg = 'misc.error') => {
        return { // Emulating the same object structure as the Yup schema validation
            errors: [ <Translate id={msg} key={msg} /> ],
        }
    }

    const tokenize = (values) => {
        return new Promise((resolve, reject) => {
            paysafeInstance.current.tokenize({
                vault: {
                    holderName: `${values.firstName} ${values.lastName}`,
                    billingAddress: {
                        country: values.address.country,
                        state: values.address.state,
                        zip: values.address.zipCode,
                        city: values.address.city,
                        street: values.address.address,
                    },
                },
            },
                                             (_paysafeInstance, error, result) => {
                                                 if(error){
                                                     if(error.code === "9003"){ // Invalid fields
                                                         error.fieldErrors.forEach((prop) => {
                                                             switch (prop.field){
                                                                 case "card number":
                                                                     document.getElementById("add-card-cardNumber")?.classList.add("is-invalid");
                                                                     break;
                                                                 case "cvv":
                                                                     document.getElementById("add-card-cvv")?.classList.add("is-invalid");
                                                                     break;
                                                                 case "expiry date":
                                                                 case "expiry year":
                                                                 case "expiry month":
                                                                     document.getElementById("add-card-expirationDate")?.classList.add("is-invalid");
                                                                     break;
                                                                 default:
                                                                     break;
                                                             }
                                                         });
                                                     }
                                                     reject(buildError(`paysafe.error.${error.code}`))
                                                 }else{
                                                     resolve(result.token);
                                                 }
                                             })
        });
    }

    const onSubmit = () => {
        formik.setSubmitting(true);
        const schema = object().shape({
            firstName: string().required(<Translate id='account.paymentMethods.editCard.modal.label.firstName.required' />),
            lastName: string().required(<Translate id='account.paymentMethods.editCard.modal.label.lastName.required' />),
            address: object().address(true, {
                streetNumber: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
                address: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
                city: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
                country: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
                state: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
                zipCode: <Translate id='account.paymentMethods.editCard.modal.label.address.invalid' />,
            }),
        });
        schema.validate({
            firstName: firstName,
            lastName: lastName,
            address: formik.values.address,
        })
            .then(async(values) => await tokenize(values))
            .then(async(paymentToken) => {
                await accountsContext.addPaysafeCard(auth.userData.Username, {
                    payment_token: paymentToken,
                    invoice_payment_id: invoicePaymentId,
                })
                    .catch((e) => {
                        try{
                            const paysafeResponse = JSON.parse(e.return);
                            if(paysafeResponse.links.findIndex((link) => link.rel === "existing_entity") !== -1){
                                return Promise.reject(buildError('paysafe.error.duplicateCard'));
                            }
                        }catch(error){
                            // Do nothing. The misc.error message will be displayed with the next line
                        }
                        return Promise.reject(buildError());
                    });
            })
            .then(() => {
                success({ msg: 'account.paymentMethods.editCard.modal.label.newCardAdded.message', info: 'account.paymentMethods.editCard.modal.label.newCardAdded.info' });
                updateVaultCards();
                changeView(1);
                formik.setSubmitting(false);
            })
            .catch((error) => {
                try{
                    console.error(JSON.stringify(error.errors));
                }catch(_){ // Here just in case the JSON.stringify fails
                }
                formik.setSubmitting(false);
                setError(error.errors[0]);
            })
    }

    return (
        <div ref={paysafeScriptContainer}>
            <ModalBody>
                <Row form>
                    <Col sm="12">
                        <FormGroup>
                            <Label className="text-muted">
                                <Translate id='account.paymentMethods.editCard.modal.label.cardNb' /> <Required />
                            </Label>
                            <LoadingPaysafeField isLoading={isLoading} id='add-card-cardNumber' />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="text-muted">
                                <Translate id='form.fields.firstName' /> <Required />
                            </Label>
                            <Input name='firstName' id='add-card-firstName' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="text-muted">
                                <Translate id='form.fields.lastName' /> <Required />
                            </Label>
                            <Input name='lastName' id='add-card-lastName' value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="text-muted">
                                <Translate id='account.paymentMethods.editCard.modal.label.exp' /> <Required />
                            </Label>
                            <LoadingPaysafeField isLoading={isLoading} id='add-card-expirationDate' />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="text-muted">
                                <Translate id='account.paymentMethods.editCard.modal.label.cvv' /> <Required />
                            </Label>
                            <LoadingPaysafeField isLoading={isLoading} id='add-card-cvv' />
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormikAddress
                            id='address' name='address'
                            allowManualPlace
                            required
                            label='form.fields.address'
                        />
                    </Col>
                </Row>
                <Collapse isOpen={!!formError} unmountOnExit mountOnEnter>
                    <Alert color='danger' className='mb-0' toggle={() => { setError() }}>{formError}</Alert>
                </Collapse>
            </ModalBody>
            <ModalFooter>
                <Button className="mr-auto" color="primary" outline type="button" onClick={() => changeView(1)} disabled={formik.isSubmitting}><Translate id="misc.previous" /></Button>
                <Button color="primary" type="button" onClick={onSubmit} disabled={formik.isSubmitting}><Translate id="misc.confirm" /></Button>
            </ModalFooter>
        </div>
    )
}

const LoadingPaysafeField = ({ isLoading, ...props }) => {
    return (isLoading ?
        <Skeleton height={35} />
        :
        <div {...props} className='form-control' />
    )
}

export default PaysafeCreateCard
