import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Fade, Label, ModalBody, Row } from "reactstrap";
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import Required from "../../../../../../../../components/formik/Required";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../components/UserImg";
import { MembersContext } from "../../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import BtnDisplay from "../../../btnDisplay/BtnDisplay";
import CrossFade from "../../../CrossFade";
import { BC_ID } from "../../../gameIncidentHelper";
import { useSelectProps } from "../../../useSelectProps";
import StepFooter from "../../components/StepFooter";
import StepTitle from "../../components/StepTitle";
import OfficialListItem from "./OfficialListItem";

const AddMemberGameIncidentStepOfficials = ({ member, next, setIsLoading, updateGirData, isLoading, previous }) => {
    const [ officialsStep, setOfficialsStep ] = useState(1);
    const { getPublicMembers } = useContext(MembersContext);
    const [ results, setResults ] = useState(null);
    const { getSelectProps } = useSelectProps();

    /**
     * @param {boolean} isVisible
     * @returns {JSX.Element}
     */
    const FieldRequired = ({ isVisible }) => (
        <Fade className="d-inline" in={isVisible}><Required /></Fade>
    );

    /**
     * @description Search schema, only used when officialsStep is set to 2
     * @return {MixedSchema}
     */
    const searchSchema = ({
        first_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.firstName' /> }),
            }),
        last_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='addMemberModal.formik.validation.lastName' /> }),
            }),
        unique_identifier: string(),
    });

    return (
        <Formik
            initialValues={{
                search: {
                    first_name: '',
                    last_name: '',
                    unique_identifier: '',
                },
                officials: [
                    {
                        ...member,
                        official_type: '',
                        email: '',
                        phone_number: '',
                    },
                ],
            }}
            validationSchema={object().shape({
                search: object().when('_', (_, schema) => officialsStep === 1 ? schema : schema.shape(searchSchema)),
                officials: array().of(
                    object().shape({
                        member_id: string(),
                        official_type: string().test({
                            name: 'isRequired',
                            message: <Translate id='account.members.gir.addModal.validation.officialType' />,
                            test: function(off){
                                return officialsStep !== 1 || !!off;
                            },
                        }),
                        email: string().email(<Translate id='form.validation.email.valid' />).test({
                            name: 'isRequired',
                            message: <Translate id='account.members.gir.addModal.validation.email' />,
                            test: function(email){
                                return officialsStep !== 1 || this.parent.member_id !== member.member_id || !!email;
                            },
                        }),
                        phone_number: string()
                            .when((_, schema) => officialsStep === 2 ?
                                schema
                                :
                                schema.isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).test({
                                    name: 'isRequired',
                                    message: <Translate id='account.members.gir.addModal.validation.phone' />,
                                    test: function(phone){
                                        return this.parent.member_id !== member.member_id || !!phone;
                                    },
                                })),
                        phoneExt: string(),
                    }),
                )
                    .min(1),
            })}
            onSubmit={({ search, officials }, { setTouched }) => {
                if(officialsStep === 1){
                    updateGirData({ officials: officials });
                    next();
                }else{
                    setIsLoading(true);
                    const apiValues = {
                        member_type_id: 'OFFICIAL',
                        organisation_id: BC_ID,
                        limit_hcr_number_search: 1,
                    };

                    if(search.unique_identifier){
                        apiValues.unique_identifier = search.unique_identifier;
                    }else{
                        apiValues.first_name = search.first_name;
                        apiValues.last_name = search.last_name;
                    }

                    getPublicMembers(apiValues)
                        .then((members) => {
                            setResults(members);
                            setIsLoading(false);
                            setTouched({
                                'officials': false,
                            }, false);

                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                setIsLoading(false);
                                console.error(e);
                            }
                        })
                }
            }}
        >
            {(formik) => (
                <Form>
                    <CrossFade isVisible={officialsStep === 1}>
                        <ModalBody>
                            <StepTitle title='account.members.gir.addModal.steps.officials.title' />
                            {formik.values.officials.map((official, index) => (
                                <OfficialListItem
                                    index={index}
                                    official={official}
                                    key={official.member_id}
                                    getSelectProps={getSelectProps}
                                    emailPhoneRequired={member.member_id === official.member_id}
                                />
                            ))}
                            <BtnDisplay
                                onClick={() => setOfficialsStep(2)}
                                iconSize={50}
                                icon="addMember"
                                svgProps={{ viewBox: "0 0 50 50" }}
                                title={<Translate id='account.members.gir.addModal.officials.title' />}
                                subtitle={<Translate id='account.members.gir.addModal.officials.btn.subtitle' />}
                            />
                        </ModalBody>
                        <StepFooter previous={previous} />
                    </CrossFade>
                    <CrossFade
                        onExited={() => {
                            setResults(null);
                            formik.setFieldValue('search', {
                                first_name: '',
                                last_name: '',
                                unique_identifier: '',
                                birthdate: '',
                            });
                        }}
                        isVisible={officialsStep === 2}
                    >
                        <ModalBody>
                            <StepTitle title='account.members.gir.addModal.steps.officials.add.title' />
                            <Row form>
                                <Col sm="6" className="form-group">
                                    <Label for="search.first_name" className="text-muted"><Translate id='form.fields.firstName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                    <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.first_name" />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="search.last_name" className="text-muted"><Translate id='form.fields.lastName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                    <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.last_name" />
                                </Col>
                                <Col className="mb-3" sm="12">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <hr className="flex-fill" /> <span className="px-2 font-bold"><Translate id='addMemberModal.formik.label.search.or' /></span> <hr className="flex-fill" />
                                    </div>
                                </Col>
                                <Col sm="12" className="form-group">
                                    <Label for="search.unique_identifier" className="text-muted"><Translate id='addMemberModal.formik.label.uniqueIdentifier' /></Label>
                                    <FormikInputText name="search.unique_identifier" />
                                </Col>
                            </Row>
                            <CrossFade isVisible={!!formik.values.search.unique_identifier}>
                                <span className="small text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='addMemberModal.formik.warning.filters.ignored' /></span>
                            </CrossFade>
                            <Collapse isOpen={!!results}>
                                { results &&
                                    <div className="pt-3 border-top">
                                        { results?.length > 0 ?
                                            <>
                                                <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.existing' /></div>
                                                <Row form>
                                                    { results.map((result) => {
                                                        const isAlreadyInList = formik.values.officials.some((official) => official.member_id === result.member_id);

                                                        return (
                                                            <Col sm="12" key={result.member_id}>
                                                                <UserDisplay card className="w-100 mb-2">
                                                                    <UserDisplay.Container>
                                                                        <UserImg
                                                                            abbr={result.first_name.substring(0, 1) + result.last_name.substring(0, 1)}
                                                                            src={result.photo?.full_path}
                                                                            filePos={result.photo?.file_position}
                                                                            width={50}
                                                                            alt={result.first_name}
                                                                        />
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container>
                                                                        <div className="align-items-center d-flex">
                                                                            <UserDisplay.Title className="mr-2">{result.first_name} {result.last_name}</UserDisplay.Title>
                                                                            <UserDisplay.Subtitle>({result.age} <Translate id='misc.yrs' />)</UserDisplay.Subtitle>
                                                                        </div>
                                                                        <UserDisplay.Subtitle>#{result.unique_identifier}</UserDisplay.Subtitle>
                                                                        <UserDisplay.Subtitle>
                                                                            <DisplayI18n
                                                                                field="name"
                                                                                i18n={result.organisation.i18n}
                                                                                defaultValue={result.organisation.organisation_name}
                                                                            />
                                                                        </UserDisplay.Subtitle>
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container className="ml-auto">
                                                                        <Button
                                                                            disabled={isAlreadyInList}
                                                                            size="sm"
                                                                            type="button"
                                                                            onClick={async() => {
                                                                                await formik.setFieldValue('officials', [ ...formik.values.officials, { phone_number: '', email: '', ...result, official_type: '' } ]);
                                                                                setOfficialsStep(1);
                                                                            }}
                                                                            color="primary"
                                                                            outline
                                                                        >
                                                                            {isAlreadyInList ?
                                                                                <Translate id='account.members.gir.search.alreadyAdded' />
                                                                                :
                                                                                <Translate id='misc.add' />
                                                                            }
                                                                        </Button>
                                                                    </UserDisplay.Container>
                                                                </UserDisplay>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </>
                                            :
                                            <div className="h6 font-medium mb-3"><Translate id='addMemberModal.searchResult.noResult' /></div>
                                        }
                                    </div>
                                }
                            </Collapse>
                        </ModalBody>
                        <StepFooter disabled={isLoading} previous={() => setOfficialsStep(1)} submitLabel="misc.search" />
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default AddMemberGameIncidentStepOfficials;