import { Tooltip } from "@mantine/core";
import { FormikDateTime, FormikPhoneInput, FormikCurrencyInput } from "@spordle/formik-elements";
import { CurrencyFormat } from "@spordle/intl-elements";
import { isProdFishNChips } from "./getReferer";

/**
 * Function used to set default props for the entire platform
 */
function setDefaultComponentProps(){
    FormikDateTime.defaultProps = {
        ...FormikDateTime.defaultProps,
        closeOnSelect: true,
    }

    if(isProdFishNChips()){
        FormikPhoneInput.defaultProps = {
            ...FormikPhoneInput.defaultProps,
            defaultSelectedCountry: 'gb',
            suggestedCountries: [ 'gb' ],
        }

        FormikCurrencyInput.defaultProps = {
            ...FormikCurrencyInput.defaultProps,
            currencySign: '£',
        }

        CurrencyFormat.defaultProps = {
            ...CurrencyFormat.defaultProps,
            currency: 'GBP',
        }
    }

    Tooltip.defaultProps = {
        ...Tooltip.defaultProps,
        transition: 'fade',
        withArrow: true,
    }
}

export default setDefaultComponentProps;