import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import UserDisplay from "../../../../../../../../../components/userDisplay/UserDisplay";
import CollapseIcon from "../../../../../components/CollapseIcon";
import CrossFade from "../../../../../components/CrossFade";
import IncidentForm from "../../../../../components/IncidentForm";

/**
 * @param {Object} props
 * @param {function} props.previous
 * @returns {JSX.Element}
 */
const IncidentListItem = ({ helper, index, ...props }) => {
    const [ remove, setRemove ] = useState(false);
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(true);
    const { values, errors, touched } = useFormikContext();
    const fieldsPrefix = `${helper.name}.${index}.`;

    useEffect(() => {
        if(index + 1 !== values.incidents.length){
            setCollapseIsOpen(false);
        }
    }, [ values.incidents.length ]); // Everytime an incident is added

    const globalError = !collapseIsOpen && !!errors.incidents?.[index] && !!touched.incidents?.[index];

    const handleRemove = () => {
        helper.remove(index);
    }

    return (
        <CrossFade
            onExited={handleRemove}
            isVisible={!remove}
        >
            <div
                className={
                    stringBuilder(
                        "mb-3 transition border rounded-lg",
                        { "border-danger": globalError },
                    )
                }
            >
                <UserDisplay
                    card
                    block
                    onClick={() => setCollapseIsOpen(!collapseIsOpen)}
                    className={stringBuilder("clickable mb-0 border-0 transition", { "bg-light-danger": globalError })}
                >
                    <UserDisplay.Container>
                        <CollapseIcon isOpen={collapseIsOpen} />
                    </UserDisplay.Container>
                    <UserDisplay.Container className="font-medium h5 mb-0">
                        <Translate id='account.members.gir.addModal.label.incident' /> #{index + 1}
                    </UserDisplay.Container>
                    <UserDisplay.Container className="ml-auto">
                        {index !== 0 &&
                            <button onClick={() => setRemove(true)} type="button" className="reset-btn text-danger">
                                <i className="mdi mdi-delete" />
                            </button>
                        }
                    </UserDisplay.Container>
                </UserDisplay>
                <Collapse className="bg-light-inverse" isOpen={collapseIsOpen}>
                    <div className="p-3">
                        <IncidentForm
                            fieldsPrefix={fieldsPrefix}
                            incident={values.incidents[index]}
                            {...props}
                        />
                    </div>
                </Collapse>
            </div>
        </CrossFade>
    )
}

export default IncidentListItem;