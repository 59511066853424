import { Component } from 'react';
import {
    Spinner,
    Button,
    Fade
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/contexts';
import queryString from 'query-string';

import { I18nContext } from '../../contexts/I18nContext';
import { LOCALES } from '../../i18n';
// Components
import AuthHeader from '../../components/tour/Authheader';
import Translate from '@spordle/intl-elements';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import GoToHomeBtn from '../../components/GoToHomeBtn';
import WithTourTheme from '../../components/HOC/WithTournamentTheme';
import { success } from '@spordle/toasts';

class ConfirmAccount extends Component{
    static contextType = AuthContext;

    state = {
        display: false,
        error: '',
    };

    componentDidMount(){
        // Get token in url
        const params = queryString.parse(this.props.location.search);
        if(!(this.props.location.pathname === "/signup-confirmation" && params.tok)){
            this.props.history.push('/signup');
            return;
        }

        // Confirm account API request (redirect and auto login)
        this.context.confirmAccount(params.tok)
            .then(() => {
                success({ msg: 'confirm-account.success.title' });
                this.props.history.push("/");
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message))
                    this.setState({ error: error.message, display: true })
            });

        // Show loading after 0.5 seconds to prevent flicker on good connection
        setTimeout(() => {
            this.setState({ display: true });
        }, 500);
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                {this.state.display &&
                    <Fade>
                        <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                            <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                                <GoToHomeBtn />
                                <I18nContext.Consumer>
                                    {(i18n) => {
                                        const { locale, setLocale } = i18n;
                                        return (
                                            <>
                                                {(locale === 'en-ca') ?
                                                    <><Button color="link" onClick={() => { setLocale(LOCALES.FRENCH) }}>FR</Button></>
                                                    :
                                                    <><Button color="link" onClick={() => { setLocale(LOCALES.ENGLISH) }}>EN</Button></>
                                                }
                                            </>
                                        )
                                    }}
                                </I18nContext.Consumer>
                            </div>

                            <AuthHeader />

                            <div className="auth-box my-0">

                                {this.state.error === '' ?
                                    <div className="text-center">
                                        <Spinner color="info" style={{ width: '80px', height: '80px' }} type="grow" />
                                        <div className="h1 font-medium"><Translate id='confirm-account.loading.title' /></div>
                                        <p className="text-dark"><Translate id='confirm-account.loading.description' /></p>
                                    </div>
                                    :
                                    <div className="text-center">
                                        <div>
                                            <i className="mdi mdi-alert-circle text-danger" style={{ fontSize: 60 }} />
                                            <div className="h4 font-medium mb-3">
                                                <Translate id='confirm-account.failed.title' />
                                            </div>
                                        </div>
                                        <p className="mb-3">
                                            {/* detail de l'erreur */}
                                            <Translate id={`confirm-account.error.${this.state.error}`} defaultMessageId='confirm-account.error.server' />
                                        </p>
                                        <Link to="/login" className="btn btn-primary"><Translate id='confirm-account.failed.action' /></Link>
                                    </div>
                                }

                            </div>
                        </div>
                    </Fade>
                }
            </>
        );
    }
}

export default WithTourTheme(ConfirmAccount);