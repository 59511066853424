import queryString from 'query-string';

export const LOGINTYPE = {
    HCR: 'HCR',
    SPORDLE: 'SPORDLE',
    CLINIC: 'CLINIC',
    PLAY: 'PLAY',
}

export const DB = process.env.REACT_APP_ENVIRONMENT === 'int' ? queryString.parse(window.location.search).db : undefined;

export const apiUrlEnv = process.env.REACT_APP_ENVIRONMENT === 'int' ? DB || process.env.REACT_APP_ENVIRONMENT : process.env.REACT_APP_ENVIRONMENT;
export const mobileView = 767;
export const smallDesktopView = 1170;
export const HC_ID = '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9';
export const PROOF_OF_RESIDENCY_DOC_TYPE_ID = 'cf5bac6f-585b-11ec-bed9-023f3d3ef136';// TODO: hardcoded
