export default{
    "schedule.btn.today" : "Aujourd'hui",

    "schedule.filter.title" : "Filtrer les événements",

    "schedule.helmet.title"       : "Horaire de {org}",
    "schedule.helmet.description" : "Horaire complet de toutes les équipes de {org}, présenté sous forme de calendrier.",

    "schedule.page.name" : "Calendrier",

    "schedule.tabs.week"  : "Semaine",
    "schedule.tabs.day"   : "Jour",
    "schedule.tabs.month" : "Mois",
    "schedule.tabs.full"  : "Complet",

    "schedule.daily.recurrent.title" : "Récurrent",
    "schedule.daily.empty.title"     : "Aucun événement.",
    "schedule.daily.empty.text"      : "Nous n'avons pas trouvé d'événement à afficher. Passez une excellente journée !",

    "schedule.label.time.starts"      : "Commence",
    "schedule.label.time.ends"        : "Se termine",
    "schedule.label.time.startsAt"    : "Commence à",
    "schedule.label.time.until"       : "Jusqu'à",
    "schedule.label.time.allDay"      : "Toute la journée",
    "schedule.label.upcomingEvents"   : "Événements à venir",
    "schedule.label.payment"          : "Paiement",
    "schedule.label.members"          : "Membres",
    "schedule.label.scheduledPayment" : "Paiement programmé",
    "schedule.label.amount"           : "Montant",
    "schedule.label.invoice"          : "Facture",
    "schedule.label.duration"         : "Durée",
    "schedule.label.link.hu"          : "Hockey Université",
    "schedule.label.link.hcop"        : "Connexion HCOP",
    "schedule.format.ONLINE"          : "En ligne - Sur demande",
    "schedule.format.ONLINE_LIVE"     : "En ligne - En direct",
    "schedule.format.ONSITE"          : "En personne",

    "schedule.error.title"   : "Oups !",
    "schedule.error.message" : "Quelque chose d'inattendu s'est produit. Veuillez revenir plus tard ou contacter notre équipe de support.",
}