import { stringBuilder } from "@spordle/helpers";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import CollapseIcon from "./CollapseIcon";

const CollapsibleCard = ({ className, error = false, img, title, subtitle, initOpen = false, children, deleteBtn, iconColor }) => {
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(initOpen);
    const showError = error && !collapseIsOpen;

    useEffect(() => {
        if(initOpen !== collapseIsOpen) setCollapseIsOpen(initOpen);
    }, [ initOpen ]);

    return (
        <div className={stringBuilder(className, { "border-danger": showError }, "bg-light-inverse transition border rounded-lg shadow-sm")}>
            <UserDisplay
                card
                block
                onClick={() => setCollapseIsOpen(!collapseIsOpen)}
                className={stringBuilder("position-relative clickable mb-0 transition border-0", { "bg-light-danger": showError })}
            >
                <UserDisplay.Container className="d-flex align-items-center justify-content-center" style={{ width: 40 }}>
                    <CollapseIcon isOpen={collapseIsOpen} iconColor={showError ? 'white' : iconColor} />
                </UserDisplay.Container>
                { img &&
                    <UserDisplay.Container>
                        {img}
                    </UserDisplay.Container>
                }
                <UserDisplay.Container className="mr-auto">
                    <UserDisplay.Title>{title}</UserDisplay.Title>
                    {subtitle &&
                        <UserDisplay.Subtitle className={showError ? 'text-body' : undefined}>
                            {subtitle}
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                {deleteBtn}
            </UserDisplay>
            <Collapse isOpen={collapseIsOpen}>
                <div className="p-3">
                    {children}
                </div>
            </Collapse>
        </div>
    );
}

export default CollapsibleCard;