import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Card, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../../../api/CancellableAPI";
import DisplayCategory from "../../../../../../../../../components/DisplayCategory";
import Required from "../../../../../../../../../components/formik/Required";
import { I18nContext } from "../../../../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../../../../contexts/OrganizationContext";
import { TeamsContext } from "../../../../../../../../../contexts/TeamsContext";
import { displayI18n, DisplayI18n } from "../../../../../../../../../helpers/i18nHelper";
import { isCoach, isManager } from "../../../../../components/gameIncidentHelper";
import CrossFade from "../../../../CrossFade";
import { teamInit } from "../AddMemberGameIncidentStepTeam";

/**
 * @param {Object} props
 * @param {'away'|'home'} [props.teamPos='home']
 * @returns {JSX.Element}
 */
const TeamCard = ({ branches, categories, girData, teamPos = 'home' }) => {
    const { getOrganizationsByCategory } = useContext(OrganizationContext);
    const { getTeams, getTeamMembers } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { values, setFieldValue } = useFormikContext();

    const [ orgs, setOrgs ] = useState(null);
    const [ teams, setTeams ] = useState(null);
    const [ teamMembers, setTeamMembers ] = useState([]); //null = loading,
    const teamField = teamPos + '_team';
    const team = values[teamField];

    const getTeamOptionLabel = (option) => {
        const team = option.option.team;
        const selected = option.isSelected;

        return (
            <>
                <div className="font-medium">
                    <DisplayI18n
                        field="name"
                        i18n={team.i18n}
                        defaultValue={team.name}
                    />
                </div>
                {!selected &&
                    <>
                        <div className="font-14">
                            (
                            <DisplayI18n
                                field="short_name"
                                i18n={team.i18n}
                                defaultValue={team.short_name}
                            />
                            )
                        </div>
                        <div className="text-muted small">
                            #{team.unique_identifier}
                        </div>
                    </>
                }
                <div className="text-muted small">
                    <DisplayCategory short category={team.team_category} /> (<Translate id={'form.fields.gender.' + team.team_category?.gender} />)
                </div>
            </>
        )
    }

    const emptyFields = (toKeep) => {
        setFieldValue(teamPos + '_team', {
            ...teamInit,
            ...toKeep,
        })
    }

    const fetchOrganisations = async(id) => {
        emptyFields({ branch_id: id });
        setOrgs(null);
        setTeams([]);
        const orgCategories = categories.reduce((keptCatId, cat) => {
            if([ "mha", "region", "district", "team" ].includes((cat.default_name || "").toLowerCase())){
                keptCatId.push(cat.category_id);
            }
            return keptCatId;
        }, []);

        const orgs = await getOrganizationsByCategory(id, orgCategories);

        setOrgs(
            (orgs || []).sort((a, b) => {
                const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
                return aName.localeCompare(bName);
            }),
        )
    }

    const fetchTeams = async(id) => {
        setTeams(null);
        getTeams(id, girData.period_id)
            .then(setTeams)
            .catch(console.error);
    }

    useEffect(() => { // Everytime the organisation changes
        if(values[teamField].organisation_id){
            fetchTeams(values[teamField].organisation_id);
        }
    }, [ values[teamField].organisation_id ]);

    useEffect(() => { // Everytime the branch changes
        if(values[teamField].branch_id && !!categories){
            fetchOrganisations(values[teamField].branch_id).catch(console.error);
        }

    }, [ values[teamField].branch_id, categories ]);

    useEffect(() => {
        if(team.team_id && team.existing == "1"){
            setTeamMembers(null);
            getTeamMembers(team.team_id)
                .then((members) => {
                    const players = members
                        .sort((a, b) => {
                            const aName = a.member.first_name + ' ' + a.member.last_name;
                            const bName = b.member.first_name + ' ' + b.member.last_name;
                            return aName.localeCompare(bName);
                        })
                        .reduce((data, mem) => {
                            if(mem.position?.position_group?.position_type === "PLAYER" || isManager(mem.position?.position_group) || isCoach(mem.position?.position_group)){
                                data.push({ ...mem, team_id: team.team_id });
                            }

                            return data;
                        }, []);

                    setFieldValue(teamPos + '_team.members', players);
                    setTeamMembers(players);
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        setTeamMembers([]);
                        console.error(e);
                    }
                })
        }
    }, [ team?.team_id, team?.existing ]);

    return (
        <Card className="card-shadow mb-0 h-md-100">
            <Nav tabs className="is-full mb-2 pt-1">
                <NavItem className="flex-grow-1 text-center">
                    <NavLink
                        className={stringBuilder({ active: values[teamField].existing == "1" })}
                        onClick={() => setFieldValue(teamField + '.existing', '1')}
                    >
                        <Translate id='account.members.gir.addModal.steps.teams.existing' />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1 text-center">
                    <NavLink
                        className={stringBuilder({ active: values[teamField].existing == "0" })}
                        onClick={() => setFieldValue(teamField + '.existing', '0')}
                    >
                        <Translate id='account.members.gir.addModal.steps.teams.other' />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={values[teamField].existing}>
                <TabPane tabId="1">
                    <div className="p-3">
                        <FormGroup>
                            <Label for={`${teamField}.branch_id`} className="text-muted">
                                <Translate id='account.members.gir.addModal.label.branch' />
                            </Label>
                            <FormikSelect
                                id={`${teamField}.branch_id`} name={`${teamField}.branch_id`} clearable
                                searchKeys={[
                                    `organisation_name`,
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                options={branches?.map((org) => ({
                                    label: org.organisation_name,
                                    value: org.organisation_id,
                                    i18n: org.i18n,
                                }))}
                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.organisation_id`} className="text-muted">
                                <Translate id='account.members.gir.addModal.label.org' /> <Required />
                            </Label>
                            <FormikSelect
                                id={`${teamField}.organisation_id`} name={`${teamField}.organisation_id`} clearable
                                isLoading={orgs === null}
                                searchKeys={[
                                    `organisation_name`,
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                options={orgs?.map((org) => ({
                                    label: org.organisation_name,
                                    value: org.organisation_id,
                                    i18n: org.i18n,
                                }))}
                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                onOptionSelected={(options) => {
                                    if(options.length <= 0){
                                        setFieldValue(`${teamField}.team_id`, '')
                                        setFieldValue(`${teamField}.coach`, '')
                                        setFieldValue(`${teamField}.manager`, '')
                                    }
                                }}
                            />
                        </FormGroup>
                        <div
                            className="transition"
                            style={{
                                pointerEvents: !values[teamField].organisation_id ? 'none' : 'initial',
                                opacity: !values[teamField].organisation_id ? 0.4 : 1,
                            }}
                        >
                            <FormGroup>
                                <Label for={`${teamField}.team_id`} className="text-muted"><Translate id='account.members.gir.addModal.label.team' /> <Required /></Label>
                                <FormikSelect
                                    id={`${teamField}.team_id`} name={`${teamField}.team_id`} clearable
                                    isLoading={teams === null && !!values[teamField].organisation_id}
                                    searchKeys={[
                                        `organisation_name`,
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={teams?.map((team) => ({
                                        label: team.name,
                                        value: team.team_id,
                                        i18n: team.i18n,
                                        team: team,
                                    }))}
                                    renderOption={(option) => getTeamOptionLabel(option)}
                                    onOptionSelected={(options) => {
                                        if(options.length <= 0){
                                            setFieldValue(`${teamField}.coach`, '')
                                            setFieldValue(`${teamField}.manager`, '')
                                        }
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div
                            className="transition"
                            style={{
                                pointerEvents: !values[teamField].team_id ? 'none' : 'initial',
                                opacity: !values[teamField].team_id ? 0.4 : 1,
                            }}
                        >
                            <FormGroup>
                                <Label for={`${teamField}.coach`} className="text-muted"><Translate id='account.members.gir.addModal.label.coach' /></Label>
                                <CrossFade isVisible={team?.team_id !== "OTHER"}>
                                    <FormikSelect
                                        id={`${teamField}.coach`} name={`${teamField}.coach`} clearable
                                        isLoading={teamMembers === null}
                                        options={formatSelectData(teamMembers?.reduce((staffList, staff) => {
                                            // Push first option as "Other"
                                            if(staffList.length == 0){
                                                staffList.push({
                                                    value: "OTHER",
                                                    label: "account.members.gir.addModal.label.coach.option.other",
                                                })
                                            }
                                            if(isCoach(staff.position?.position_group)){
                                                staffList.push({
                                                    value: staff.member.member_id,
                                                    label: staff.member.first_name + ' ' + staff.member.last_name,
                                                })
                                            }
                                            return staffList;
                                        }, []) || [], {
                                            newGroupIndexes: {
                                                1: {
                                                    label: "account.members.gir.addModal.label.coachList",
                                                    groupId: "coachList",
                                                },
                                            },
                                            getGroupId: (option) => option.value != 'OTHER' ? 'coachList' : undefined,
                                        })}
                                        renderOption={(option) => (option.option.isGroup || option.option.value === 'OTHER') ? <Translate id={option.option.label} /> : option.option.label}
                                    />
                                </CrossFade>
                                <CrossFade isVisible={team?.coach === "OTHER"}>
                                    <FormikInputText
                                        className="mt-2"
                                        name={`${teamField}.coach_name`}
                                        placeholder='account.members.gir.addModal.label.coach.other'
                                        trim
                                    />
                                </CrossFade>
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <Label for={`${teamField}.manager`} className="text-muted"><Translate id='account.members.gir.addModal.label.manager' /></Label>
                                <FormikSelect
                                    id={`${teamField}.manager`} name={`${teamField}.manager`} clearable
                                    isLoading={teamMembers === null}
                                    options={formatSelectData(teamMembers?.reduce((staffList, staff) => {
                                        // Push first option as "Other"
                                        if(staffList.length == 0){
                                            staffList.push({
                                                value: "OTHER",
                                                label: "account.members.gir.addModal.label.manager.option.other",
                                            })
                                        }
                                        if(isManager(staff.position?.position_group)){
                                            staffList.push({
                                                value: staff.member.member_id,
                                                label: staff.member.first_name + ' ' + staff.member.last_name,
                                            })
                                        }
                                        return staffList;
                                    }, []) || [], {
                                        newGroupIndexes: {
                                            1: {
                                                label: "account.members.gir.addModal.label.managerList",
                                                groupId: "managerList",
                                            },
                                        },
                                        getGroupId: (option) => option.value != 'OTHER' ? 'managerList' : undefined,
                                    })}
                                    renderOption={(option) => (option.option.isGroup || option.option.value === 'OTHER') ? <Translate id={option.option.label} /> : option.option.label}
                                />
                                <CrossFade isVisible={team?.manager === "OTHER"}>
                                    <FormikInputText
                                        className="mt-2"
                                        name={`${teamField}.manager_name`}
                                        placeholder='account.members.gir.addModal.label.manager.other'
                                        trim
                                    />
                                </CrossFade>
                            </FormGroup>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="0">
                    <div className="p-3">
                        <FormGroup>
                            <Label for={`${teamField}.team_name`} className="text-muted"><Translate id='account.members.gir.addModal.label.team' /> <Required /></Label>
                            <FormikInputText
                                name={`${teamField}.team_name`}
                                id={`${teamField}.team_name`}
                                trim
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.coach_name`} className="text-muted"><Translate id='account.members.gir.addModal.label.coach' /></Label>
                            <FormikInputText
                                name={`${teamField}.coach_name`}
                                id={`${teamField}.coach_name`}
                                trim
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.manager_name`} className="text-muted"><Translate id='account.members.gir.addModal.label.manager' /></Label>
                            <FormikInputText
                                name={`${teamField}.manager_name`}
                                id={`${teamField}.manager_name`}
                                trim
                            />
                        </FormGroup>
                    </div>
                </TabPane>
            </TabContent>
        </Card>
    )
}

export default TeamCard;