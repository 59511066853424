export default{
    "header.home"           : "Accueil",
    "header.account"        : "My Account",
    "header.settings"       : "Settings",
    "header.myProfile"      : "My Profile",
    "header.signout"        : "Sign Out",
    "header.supportRequest" : "My Support Requests",

    // Notifications
    "header.notifications.title"               : "Notifications",
    "header.notifications.empty.title"         : "Up to date",
    "header.notifications.empty.text"          : "You have currently no new notification.",
    "header.notifications.tabs.supportRequest" : "Support requests",
    "header.notifications.tabs.organization"   : "Organization",
    "header.notifications.btn.clearAll"        : "Mark all as read",
    "header.notifications.btn.deleteAll"       : "Clear all",
    "header.notifications.communication.toMe"  : "To me",

    "header.notifications.item.title"     : "Support request",
    "header.notifications.item.tip.clear" : "Clear notification",
    "header.notifications.item.tip.read"  : "Mark as read",
    "header.notifications.item.unread"    : "{count, plural, =0 {# unread message} one {# unread message} other {# unread messages}}",

    "header.notifications.preinitInvoice.text" : "You have a new registration! You can pay from your payment methods tab.",

    "header.viewHeader.uat" : "You are currently in <b>UAT</b>",
}