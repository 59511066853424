export default{
    // headings
    "login.heading.title" : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Registre de Hockey Canada",
    "login.heading.intro" : "Bienvenue sur la page d'accueil du nouveau registre de Hockey Canada, le carrefour central pour l'inscription des participants et la plateforme nationale de gestion des affiliations pour tous les niveaux et activités (cliniques, formations, certifications) du hockey mineur à travers le pays.",
    "login.heading.cta"   : "En Savoir Plus",

    // login card
    "login.card.signIn"     : "Connectez-vous à votre compte",
    "login.card.welcome"    : "Bienvenue",
    "login.card.poweredBy"  : "Propulsée par",
    "login.card.changeUser" : "Changer d'utilisateur",

    // login modal
    "login.modal.text"     : "Ce compte n'existe pas",
    "login.modal.tryAgain" : "Réessayer",

    // Login form
    "login.form.label.title"                  : "Connexion",
    "login.form.label.email"                  : "Courriel",
    "login.form.label.password"               : "Mot de passe",
    "login.form.label.rememberMe"             : "Se souvenir de moi",
    "login.form.action.submit"                : "Connexion",
    "login.form.action.forgotPassword"        : "Mot de passe oublié?",
    "login.form.label.signup"                 : "Vous n'avez pas de compte? ",
    "login.form.action.signup"                : "S'inscrire",
    "login.form.validation.email.required"    : "Un courriel est requis ",
    "login.form.validation.email.email"       : "Il doit s'agir d'un courriel valide ",
    "login.form.validation.password.required" : "Mot de passe requis",

    "login.form.action.next"               : "Continuer",
    "login.form.action.send_email.success" : "Un courriel à été envoyé.",

    // Login 2FA / MFA
    "login.mfa.SMS_MFA.label"            : "Entrez le code que nous vous avons envoyé au numéro de téléphone se terminant par ",
    "login.mfa.SOFTWARE_TOKEN_MFA.label" : "Utilisez votre application de vérification en 2 étapes pour saisir le code de vérification ",

    // Login reactivate
    "login.reactivate.title"   : "Réactivez votre compte",
    "login.reactivate.message" : "Pour réactiver votre compte, veuillez contacter support à {email}.",

    // Login locked
    "login.locked.title"   : "Votre compte a été barré",
    "login.locked.message" : "Pour débarrer votre compte, veuillez contacter support à {email}.",

    // Admin login
    "login.adminConnexion.loading"       : "Connexion...",
    "login.adminConnexion.EXPIRED_TOKEN" : "Votre session a expiré. Veuillez vous reconnecter.",

    // AntiSpam
    "login.toast.warning.spam" : "Attention, veuillez attendre quelques secondes avant de poursuivre",

    // Error
    "login.error.server"                 : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "login.error.101"                    : "Mauvais mot de passe.",
    "login.error.300"                    : "Le courriel ou le mot de passe entré est incorrect.",
    "login.error.NotAuthorizedException" : "Le mot de passe entré est incorrect. {attempsLeft} {attempsLeft, plural, one {essai} other {essais}} {attempsLeft, plural, one {restant} other {restants}}.",
    "login.error.AliasExistsException"   : "Un compte avec le email donné existe déjà.",
    "login.error.LimitExceededException" : "Limite de changement atteinte, veuillez réessayer après quelques minutes.",
}