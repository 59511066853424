/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {CustomInput}from'reactstrap';import {useIntl}from'react-intl';import {useField}from'formik';import {memo,forwardRef}from'react';/**
 * @member FormikRadioButton
 * @description
 * FormikRadioButton will create a radio button that will work automagically with Fromik(yes it's a typo :))<br>
 * NOTE -> Validation do work but are not displayed here, check {@link FormikGroup} to display validation errors
 * @example
 * <FormikRadioButton name="encoding" label="form.label.formData" id="form_data_encoded" value="form-data"/>
 * <FormikRadioButton id="terms" name="terms" label={<>blabla<AnotherComponent/></>} translateLabel={false}/>
 *
 * @prop {string} name Used to make the FormikRadioButton
 * @prop {string} [label] The lang id to be translated
 * @prop {boolean} [translateLabel=true]
 * @returns {JSX.Element} Returns a radio button that will work with Formik
 *
 * @copyright Spordle
 */
const FormikRadioButton = forwardRef(({ label, translateLabel, ...props }, ref) => {
    // @ts-ignore
    const [field] = useField({ ...props, type: 'radio' });
    const intl = useIntl();
    if (translateLabel && label)
        label = intl.formatMessage({ id: label });
    if (!props.id)
        props.id = props.name;
    return (jsx("div", { children: jsx(CustomInput, { ...field, ...props, ref: ref, label: label, type: "radio" }) }));
});
FormikRadioButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateLabel: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
FormikRadioButton.defaultProps = {
    translateLabel: true,
};
FormikRadioButton.displayName = 'FormikRadioButton';
const FormikRadioButton$1 = memo(FormikRadioButton);export{FormikRadioButton$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/