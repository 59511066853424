import Translate, { DateFormat } from "@spordle/intl-elements";
import UserImg from "../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const RegistrationCard = ({ item }) => {
    return (
        <div>
            <div className="text-muted text-uppercase small mb-2"><DateFormat value={item.created_at} utc /></div>
            <div className="d-flex">
                <UserImg
                    className="mr-3 bg-white shadow-none"
                    abbr={item.organisation?.abbreviation || item.organisation?.organisation_name}
                    src={item.organisation.logo?.full_path}
                    filePos={item.organisation.logo?.file_position}
                    alt={item.organisation.organisation_name}
                />
                <div>
                    <div className="text-muted text-uppercase small">
                        <Translate id='invoice.table.season' />: <DisplayI18n field='name' defaultValue={item.period.name} i18n={item.period.i18n} />
                    </div>
                    <div className="h4 mb-0 font-bold text-dark">
                        <DisplayI18n
                            field="name"
                            i18n={item.registration_fee?.fee?.i18n}
                            defaultValue={item.registration_fee?.fee?.name}
                        />
                    </div>
                    <p className="font-normal text-dark mb-0 mt-1">
                        <DisplayI18n
                            field="name"
                            defaultValue={item.registration_fee.division.short_name}
                            i18n={item.registration_fee.division.i18n}
                        />
                        { item.registration_fee.team_category?.class &&
                            <>
                                <span className="mx-1">-</span>
                                <DisplayI18n
                                    field="name"
                                    defaultValue={item.registration_fee.team_category.class?.short_name}
                                    i18n={item.registration_fee.team_category.class?.i18n}
                                />
                            </>
                        }
                    </p>
                    <span>
                        <DisplayI18n
                            field="description"
                            defaultValue={item.registration_fee.description}
                            i18n={item.registration_fee.description}
                        />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default RegistrationCard;