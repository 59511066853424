export default{
    "failedPaymentModal.modal.title"    : "Oups !...",
    "failedPaymentModal.modal.subtitle" : "Un de vos paiements n'a pas été complété.",
    "failedPaymentModal.modal.text"     : "Pour éviter une annulation de votre inscription, veuillez mettre à jour vos informations de facturation.",
    "failedPaymentModal.modal.btn"      : "Gérer le paiement",
    "missingPayment.tooltip"            : "Paiement à compléter",

    "preinitModal.modal.title"           : "Nouvelle inscription !",
    "preinitModal.modal.text"            : "<b>{name}</b> a été inscrit sur <b>{registration}</b> par <b>{org}</b>. Veuillez payer.",
    "preinitModal.modal.text.otherItems" : "Des articles ont été commandés pour <b>{name}</b> par <b>{org}</b>. Veuillez payer.",
    "preinitModal.modal.btn"             : "Payer maintenant",

    "unsignedWaiverModal.modal.title"    : "Attention!",
    "unsignedWaiverModal.modal.subTitle" : "Vous avez {waiversAmount} dispense(s) non-signée(s) pour la facture #{invoiceNumber}",
    "unsignedWaiverModal.modal.btn"      : "Signer la dispense",

    "waitingListItemsModal.modal.title"                 : "Félicitations!",
    "waitingListItemsModal.modal.text1.clinic"          : "Votre membre a été sélectionné pour participer au stage suivant",
    "waitingListItemsModal.modal.text1.registration"    : "Votre membre a été sélectionné pour participer à",
    "waitingListItemsModal.modal.text2"                 : "Vous pouvez continuer et compléter l'inscription pour {firstName} {lastName} ou l'annuler.",
    "waitingListItemsModal.modal.btn"                   : "Continuer l'inscription",
    "waitingListItemsModal.modal.header"                : "Annulation",
    "waitingListItemsModal.modal.cancelReason"          : "Raison d'annulation",
    "waitingListItemsModal.modal.cancelReason.required" : "La raison d'annulation est requise",
    "waitingListItemsModal.modal.toast"                 : "Inscription annulée",

    "unansweredFormsModal.modal.title"                 : "Questionnaire(s) sans réponse",
    "unansweredFormsModal.modal.subTitle"              : "Vos membres ont {transactionCount, plural, one {# transaction} other {# transactions}} avec un/des questionnaire(s) sans réponse",
    "unansweredFormsModal.modal.answer"                : "Répondre",
    "unansweredFormsModal.modal.forms.transaction"     : "Transaction",
    "unansweredFormsModal.modal.confirmation.title"    : "Confirmation",
    "unansweredFormsModal.modal.confirmation.subtitle" : "Vous avez répondu à tous les questionnaires",

    "missingInformationModal.title" : "Des informations requises sont manquantes pour {amount, plural, one {le membre suivant} other {les membres suivants}}.",
    "missingInformationModal.view"  : "Voir",
}