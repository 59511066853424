import { useContext } from 'react';
import { Collapse } from 'reactstrap';
import useSWR from 'swr';
import { AccountsContext, AuthContext } from '../../../../contexts/contexts';
import VaultCards from './components/vaultCard/VaultCards';

const AccountVault = () => {
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    const { data, error, isValidating, mutate } = useSWR(
        [ 'vaultCards', auth.userData.Username ],
        () => accountsContext.getVaultCards(auth.userData.Username),
    );

    return (
        // This is a simple component but we want the getVaultCards to trigger only when the Collapse isOpen
        <Collapse isOpen={Array.isArray(data) && data.length > 0} unmountOnExit mountOnEnter>
            <VaultCards cards={data} error={error} isValidating={isValidating} mutate={mutate} />
        </Collapse>
    )
}

export default AccountVault;