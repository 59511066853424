import Translate from '@spordle/intl-elements';
import { useContext, useRef } from "react";
import images from '@spordle/ui-kit';
import { Card, CardBody } from 'reactstrap';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import AddContactModal from './AddContactModal';
import { stringBuilder } from '@spordle/helpers';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import { useDisclosure } from '@mantine/hooks';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import { I18nContext } from '../../../../contexts/I18nContext';

const noProfileLogo = images.noprofile;

const AccountMemberContacts = ({ member, contactsKey }) => {

    const [ isOpen, { toggle } ] = useDisclosure(false);
    const contactsTableRef = useRef();
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext)

    return (
        <>
            <AddContactModal
                isOpen={isOpen}
                toggle={toggle}
                refreshTable={contactsTableRef.current?.refreshTable}
                member={member}
            />
            <Card className="card-shadow">
                <CardBody>
                    <SpordleTableProvider
                        id='formMemberContact'
                        key={member.member_id + contactsKey}
                        tableHover clickable
                        viewMode='GRID'
                        ref={contactsTableRef}
                        dataIndex='id'
                        searchKeys={[
                            "firstName",
                            "lastName",
                            "email",
                            "phones.phoneNumber",
                            "contactType.name",
                            `contactType.i18n.${i18nContext.getGenericLocale()}.name`,
                        ]}
                        onColumnClick={(e, contact) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(contact.id === 'template'){
                                        toggle();
                                    }
                                    break;
                            }
                        }}
                        gridConfig={{
                            col: {
                                sm: 12,
                                md: 6,
                                lg: 4,
                                className: 'mb-3 member-contacts-col',
                            },
                            row: {
                                form: true,
                            },
                            gridRender: (contact) => {
                                if(contact.id === 'template'){
                                    return (
                                        <UserDisplay card hover className={stringBuilder('w-100 mb-0 border-primary h-100', { 'shadow-danger': contact.errors })} style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    src={contact.logo ?? noProfileLogo}
                                                    width={50}
                                                    alt={`${contact.firstName} ${contact.lastName}`}
                                                    className="p-2"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title tag="span" className='text-link'><i className='mdi mdi-plus' /> <Translate id='account.members.contacts.add' /></UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                }
                                return (
                                    <UserDisplay card className='w-100 mb-0 h-100' style={{ minWidth: 'unset' }}>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={`${contact.firstName.charAt(0)}${contact.lastName.charAt(0)}`}
                                                src={contact.logo}
                                                width={50}
                                                alt={`${contact.firstName} ${contact.lastName}`}
                                                className="p-2"
                                            />
                                            {/* <img className="object-fit-contain" src={contact.logo ?? noProfileLogo} width="40" height="40" alt={`${contact.firstName} ${contact.lastName}`}/> */}
                                        </UserDisplay.Container>
                                        <UserDisplay.Container className='text-truncate'>
                                            <UserDisplay.Title>{contact.firstName} {contact.lastName}</UserDisplay.Title>
                                            <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={contact.contactType?.name} i18n={contact.contactType?.i18n} /></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle className='text-truncate'>{contact.email}</UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle>
                                                <DisplayPhoneNumber phoneString={contact.phones[0]?.phoneNumber} format='INTERNATIONAL' />
                                            </UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                        {contact.emergency &&
                                            <UserDisplay.Container className='align-self-start ml-auto'>
                                                <div className='text-right'>
                                                    <i className='mdi mdi-ambulance text-danger font-16' />
                                                </div>
                                            </UserDisplay.Container>
                                        }
                                    </UserDisplay>
                                )

                            },
                        }}
                        emptyLayout={<EmptyLayout hideArrow />}
                        desktopWhen='md'
                        rowIsHighlighted={(contact) => !!contact.checked}
                        loadData={(from, _, spordleTable) => {
                            const formatContacts = (contacts) => {
                                const contactsArray = contacts.map((contact) => ({
                                    id: contact.member_contact_id,
                                    firstName: contact.first_name,
                                    lastName: contact.last_name,
                                    email: contact.email,
                                    emergency: contact.emergency === '1',
                                    contactType: contact.member_contact_type ? {
                                        i18n: contact.member_contact_type.i18n,
                                        memberContactTypeId: contact.member_contact_type.member_contact_type_id,
                                        name: contact.member_contact_type.name,
                                    } : null,
                                    phones: contact.phones?.map((phone) => ({
                                        phoneNumber: phone.phone_number,
                                        phoneType: {
                                            i18n: phone.phone_type.i18n,
                                            name: phone.phone_type.name,
                                            phoneTypeId: phone.phone_type.phone_type_id,
                                        },
                                        memberContactPhoneId: phone.member_contact_phone_id,
                                    })),
                                }));
                                contactsArray.push({ id: 'template' })
                                return contactsArray
                            }

                            switch (from){
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return membersContext.getMemberContacts(member.member_id)
                                        .then(formatContacts)
                                default:
                                    break;
                            }
                        }}
                    >
                        <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                            <div className="card-title font-bold h4 mb-0"><Translate id='account.members.tabs.contacts' /></div>
                        </div>
                        <div className='d-flex mb-2'>
                            <SearchInput />
                            <Refresh className='ml-auto' />
                        </div>
                        <SpordleTableView />
                    </SpordleTableProvider>
                </CardBody>
            </Card>
        </>
    )
}

export default AccountMemberContacts;