import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import MemberDocumentItem from "./components/MemberDocumentItem";
import { MembersContext } from "../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import MemberAddDocumentModal from "./components/MemberAddDocumentModal";
import Skeleton from "react-loading-skeleton";
import MemberDocumentFilters from "./components/MemberDocumentFilters";
import { I18nContext } from "../../../../contexts/I18nContext";
import MemberAddDocumentButton from "./components/MemberAddDocumentButton";
import { AccountsContext, AuthContext } from "../../../../contexts/contexts";
import { useHistory } from "react-router-dom";

const AccountMemberDocuments = ({ member, openModal, refreshMetaMember }) => {
    const [ modalInfo, setModalInfo ] = useState({
        isOpen: openModal,
        initValues: {},
    });
    const [ documentTypes, setDocumentTypes ] = useState([]);
    const { federationId } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getAccountDocuments } = useContext(AccountsContext);
    const auth = useContext(AuthContext);
    const { getDocumentTypes } = useContext(MembersContext);
    const history = useHistory();

    useEffect(() => {
        // TODO: REFACTOR
        getDocumentTypes(member?.organisation?.organisation_id || federationId)
            .then(setDocumentTypes)
            .catch(console.error)
    }, [ member?.member_id ])

    useEffect(() => {
        setModalInfo((prev) => ({ ...prev, isOpen: openModal }))
    }, [ openModal ])

    return (
        <>
            <SpordleTableProvider
                id='memberDocuments'
                key={member.member_id}
                tableHover bordered striped
                pagination={10}
                desktopWhen
                initFilter={{
                    documentTypes: [],
                }}
                dataIndex='member_attachement_id'
                defaultSorting="-issue_date"
                filterJSCallback={(document, filters) => {
                    if(filters.documentTypes.length > 0 && document.member_attachement_id !== "CREATE"){
                        return filters.documentTypes.some((docType) => docType.document_type_id === document.document_type?.document_type_id)
                    }

                    return true;
                }}
                loadData={(from, _, spordleTable) => {
                    switch (from){
                        case 'REFRESH':
                            spordleTable.setLoading();
                        case 'CDM':
                            return getAccountDocuments(auth.userData.Username, { member_id: member.member_id })
                                .then((documents) => {
                                    return [
                                        {
                                            member_attachement_id: "CREATE",
                                        },
                                        ...documents,
                                    ]
                                });
                        default:
                            break;
                    }
                }}
                fuseOptions={{
                    getSearchValue: (_normalizedValue, document, _searchKey, spordleTable) => document.member_attachement_id === "CREATE" ? spordleTable.getInputValue() : undefined,
                }}
                searchKeys={[
                    { name: 'attachment.file_name', weight: 2 },
                    `document_type.i18n.${getGenericLocale()}.name`,
                    'document_type.name',
                    'created_at',
                ]}
                viewMode="GRID"
                gridConfig={{
                    row: {
                        form: true,
                        className: "account-member-documents-row",
                    },
                    col: {
                        sm: 6,
                        lg: 4,
                        xl: 3,
                        className: "account-member-documents-col mb-3",
                    },
                    gridRender: (document) => (
                        document.member_attachement_id == "CREATE" ?
                            <MemberAddDocumentButton setModalInfo={setModalInfo} />
                            :
                            <MemberDocumentItem memberId={member.member_id} document={document} />
                    ),
                }}
                loadingMessage={
                    <Row form>
                        <Col sm="6" lg="4" xl="3" className="mb-3">
                            <Skeleton className="mb-0 h-0" style={{ paddingBottom: "120%" }} />
                        </Col>
                        <Col sm="6" lg="4" xl="3" className="mb-3">
                            <Skeleton className="mb-0 h-0" style={{ paddingBottom: "120%" }} />
                        </Col>
                        <Col sm="6" lg="4" xl="3" className="mb-3">
                            <Skeleton className="mb-0 h-0" style={{ paddingBottom: "120%" }} />
                        </Col>
                    </Row>
                }
            >
                <MemberAddDocumentModal
                    modalInfo={modalInfo}
                    memberId={member.member_id}
                    memberOrgId={member.organisation.organisation_id}
                    documentTypes={documentTypes}
                    toggle={() => {
                        setModalInfo((prev) => ({ ...prev, initValues: {}, isOpen: false }))
                        history.replace('#documents')
                    }}
                    refreshMetaMember={() => refreshMetaMember(member.meta_member_id)}
                />
                <MemberDocumentFilters documentTypes={documentTypes} />
                <div className="mb-phat">
                    <SpordleTableView />
                </div>
            </SpordleTableProvider>
        </>
    );
}

export default AccountMemberDocuments;