import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables'
import Translate, { DateFormat } from '@spordle/intl-elements'
import { useContext } from 'react'
import { Badge, Card } from 'reactstrap'
import EmptyLayout from '../../../../components/table/EmptyLayout'
import { AccountsContext } from '../../../../contexts/contexts'
import { I18nContext } from '../../../../contexts/I18nContext'
import { getStatusTheme } from '../../../../helpers/crcHelper'
import { DisplayI18n } from '../../../../helpers/i18nHelper'

const AccountMemberCrc = ({ member }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const accountContext = useContext(AccountsContext);

    return (
        <Card body className='card-shadow'>
            <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                <div className="card-title font-bold h4 mb-0"><Translate id='account.members.crc.title' /></div>
            </div>
            <SpordleTableProvider
                id='memberCrc'
                key={member.member_id}
                tableHover bordered striped
                pagination={10}
                desktopWhen
                dataIndex='crc_id'
                defaultSorting="-issue_date"
                loadData={(from, _, spordleTable) => {
                    switch (from){
                        case 'REFRESH':
                            spordleTable.setLoading();
                        case 'CDM':
                            return accountContext.getMetaMemberCrc(member.member_id);
                        default:
                            break;
                    }
                }}
                searchKeys={[
                    `organisation.i18n.${getGenericLocale()}.name`,
                    `organisation.organisation_name`,
                    `criminal_record_check_status.i18n.${getGenericLocale()}.name`,
                    `criminal_record_check_status.name`,
                    `created_by.family_name`,
                    `created_by.name`,
                    'crc_type',
                    'crc_long_type',
                    'created_at',
                    'updated_at',
                    'expiration_date',
                ]}
                columns={[
                    {
                        label: <Translate id='account.members.crc.column.createdBy.label' />,
                        key: 'createdBy',
                        sortKey: 'created_by.name',
                        fallbackSortKey: 'created_at',
                        sortable: true,
                    },
                    {
                        label: <Translate id='account.members.crc.column.crc_type.label' />,
                        key: 'crc_type',
                        sortable: true,
                    },
                    {
                        label: <Translate id='account.members.crc.column.issue_date.label' />,
                        key: 'issue_date',
                        sortable: true,
                    },
                    {
                        label: <Translate id='account.members.crc.column.expiration_date.label' />,
                        key: 'expiration_date',
                        sortable: true,
                    },
                    {
                        label: <Translate id="account.members.crc.column.status.label" />,
                        key: 'status',
                        sortKey: () => `criminal_record_check_status.${getGenericLocale()}.name`,
                        fallbackSortKey: 'criminal_record_check_status.name',
                        sortable: true,
                    },
                ]}
                renderRow={(columnKey, crc) => {
                    switch (columnKey){
                        case 'createdBy':
                            return (
                                <>
                                    <div className="font-medium line-height-1">{crc.created_by?.name} {crc.created_by?.family_name}</div>
                                    {crc.organisation &&
                                        <div className='small mb-1'>
                                            <DisplayI18n field='name' defaultValue={crc.organisation.organisation_name} i18n={crc.organisation.i18n} />
                                        </div>
                                    }
                                    <div className="small"><span className="font-medium mr-1"><Translate id='account.members.crc.column.createdBy.on' /></span>{crc.created_at && <DateFormat value={crc.created_at} utc />}</div>
                                    {crc.updated_at &&
                                        <div className="small"><span className="font-medium mr-1"><Translate id='account.members.crc.column.createdBy.editedOn' /></span><DateFormat value={crc.updated_at} utc /></div>
                                    }
                                </>
                            )
                        case 'crc_type':
                            return (
                                <DisplayI18n field='name' defaultValue={crc.background_check_type.name} i18n={crc.background_check_type.i18n} />
                            )
                        case 'expiration_date':
                            return crc.expiration_date && <DateFormat value={crc.expiration_date} utc />
                        case 'status':
                            return (
                                <CRCStatus crc={crc} />
                            )
                        default:
                            break;
                    }
                }}
                emptyLayout={<EmptyLayout title='account.members.crc.empty' hideArrow hideMsg translateTitle />}
            >
                <div className='mb-2'>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <SpordleTableProvider.SearchInput />
                        <div className='d-flex ml-auto text-right'>
                            <SpordleTableProvider.Refresh />
                        </div>
                    </div>
                </div>
                <SpordleTableView />
            </SpordleTableProvider>
        </Card>
    )
}

const CRCStatus = ({ crc }) => {
    return (
        <Badge color={getStatusTheme(crc.crc_is_expired == '1' ? 'EXPIRED' : crc.criminal_record_check_status?.name)}>
            {crc.crc_is_expired == '1' ?
                <Translate id='account.members.crc.status.expired' />
                :
                <DisplayI18n
                    field="name"
                    defaultValue={crc.criminal_record_check_status?.name}
                    i18n={crc.criminal_record_check_status?.i18n}
                />
            }
        </Badge>
    )
}

export default AccountMemberCrc
