import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { AccountsContext, AuthContext } from "../contexts/contexts";
import UserImg from "./UserImg";

const MainPhoto = ({ className, width = 40, height = width }) => {
    const { userData } = useContext(AuthContext);
    const { primaryMetaMember } = useContext(AccountsContext);
    const user = userData?.UserAttributes;

    return (
        user ?
            <UserImg
                className={stringBuilder('p-0', className)}
                width={width}
                height={height}
                alt={user.name + ' ' + user.family_name}
                abbr={user.name?.charAt(0) + user.family_name?.charAt(0)}
                src={primaryMetaMember?.attachment?.full_path || user.logo}
                filePos={primaryMetaMember?.attachment?.file_position}
            />
            :
            <div
                className={stringBuilder('bg-light rounded-circle', className)}
                style={{
                    width: width,
                    height: height,
                }}
            />
    )
}

export default MainPhoto;