export default{
    "supportRequests.profile.title"                       : "Demande de support",
    "supportRequests.profile.raisedOn"                    : "a envoyé cette demande le",
    "supportRequests.profile.status"                      : "Statut",
    "supportRequests.profile.status.2"                    : "À faire",
    "supportRequests.profile.status.3"                    : "Terminé",
    "supportRequests.profile.status.4"                    : "En attente",
    "supportRequests.profile.notification"                : "Notification",
    "supportRequests.profile.requestType"                 : "Type de demande",
    "supportRequests.profile.sharedWidth"                 : "Partagé avec",
    "supportRequests.profile.share"                       : "Partager",
    "supportRequests.profile.comment.placeholder"         : "Ajouter un commentaire...",
    "supportRequests.profile.comment.validation.required" : "Veuillez entrer votre commentaire",
    "supportRequests.profile.back"                        : "Retour à la liste",
    "supportRequests.profile.priority"                    : "Priorité",
    "supportRequests.profile.filter.newest"               : "Les plus récents en premier",
    "supportRequests.profile.filter.oldest"               : "Les plus vieux en premier",
    "supportRequests.profile.btn.seeAll"                  : "Voir toutes mes demandes",

    "supportRequests.profile.error.title" : "Oops ! Nous ne trouvons pas votre demande.",
    "supportRequests.profile.error.text"  : "Il est possible que cette demande n'existe pas ou qu'elle a été déplacée.",
    "supportRequests.profile.noComments"  : "Aucun commentaire",
    "supportRequests.profile.issueClosed" : "Cette demande est fermée.",
}