export default{
    "account.paymentMethods.otherItems" : "{amount, plural, =0 {Autre article} one {Autre article} other {Autres articles}}",

    // toasts
    "account.paymentMethods.toast.success.payment"         : "Votre paiement a été réussi.",
    "account.paymentMethods.toast.success.payment.paysafe" : "Votre paiement sera traité dans les 24 à 48 heures suivantes",


    // btns
    "account.paymentMethods.btn.payNow"              : "Payer maintenant",
    "account.paymentMethods.btn.tryAgain"            : "Réessayer le paiement",
    "account.paymentMethods.btn.changeCard"          : "Changer la carte",
    "account.paymentMethods.btn.addCard"             : "Ajouter une carte",
    "account.paymentMethods.btn.allTransactions"     : "Tous",
    "account.paymentMethods.btn.pendingTransactions" : "En attente",
    "account.paymentMethods.btn.pastTransactions"    : "Complétées",
    "account.paymentMethods.btn.editCard"            : "Modifier la carte",


    // Titles
    "account.paymentMethods.title"                 : "Méthodes de paiement",
    "account.paymentMethods.vault.title"           : "Portefeuille",
    "account.paymentMethods.vault.hint.ariaLabel"  : "Qu'est-ce que le coffre-fort Paysafe?",
    "account.paymentMethods.transactions.title"    : "Paiements",
    "account.paymentMethods.nextPayment.title"     : "Prochain paiement",
    "account.paymentMethods.declinedPayment.title" : "Paiement refusé",
    "account.paymentMethods.preinit.title"         : "Nouvelle inscription",


    // Labels
    "account.paymentMethods.label.pay"                           : "Payer",
    "account.paymentMethods.label.payNow"                        : "Payez maintenant",
    "account.paymentMethods.label.card"                          : "{amount, plural, =0 {Carte} one {Carte} other {Cartes}}",
    "account.paymentMethods.label.cardExpired"                   : "Cette carte est expirée",
    "account.paymentMethods.label.card.accessibility.endingWith" : "Carte finissant par {lastDigits}",
    "account.paymentMethods.label.creditCard"                    : "Carte de crédit",
    "account.paymentMethods.label.creditCard.failed"             : "Nous n'avons pas pu récupérer les informations de la carte de crédit",
    "account.paymentMethods.label.validUntil"                    : "Valide jusqu'au",
    "account.paymentMethods.label.transactionInfo"               : "Information sur la transaction",
    "account.paymentMethods.label.paymentMode"                   : "Mode de paiement",
    "account.paymentMethods.label.amountDue"                     : "Montant",
    "account.paymentMethods.label.invoiceNumber"                 : "No de facture",
    "account.paymentMethods.label.dueDate"                       : "Date d'échéance",
    "account.paymentMethods.label.description"                   : "Description",
    "account.paymentMethods.label.total"                         : "Total de la facture",
    "account.paymentMethods.label.payment"                       : "{amount, plural, =0 {Paiement} one {Paiement} other {Paiements}}",
    "account.paymentMethods.label.initPayment"                   : "{amount, plural, =0 {Paiement initial} one {Paiement initial} other {Paiements initiaux}}",
    "account.paymentMethods.label.installments"                  : "{amount, plural, =0 {Versement} one {Versement} other {Versements}}",
    "account.paymentMethods.label.upcomingPaymentHint"           : "Tous les paiements à venir seront mis à jour",
    "account.paymentMethods.label.status.PENDING"                : "En attente",
    "account.paymentMethods.label.status.COMPLETED"              : "Complété",
    "account.paymentMethods.label.status.FAILED"                 : "Échoué",
    "account.paymentMethods.label.status.DECLINED"               : "Refusé",
    "account.paymentMethods.label.status.SUSPENDED"              : "Suspendu",
    "account.paymentMethods.label.status.PARTIALLY_PAID"         : "Partiellement payé",
    "account.paymentMethods.label.status.next"                   : "Prochain paiement",

    // Modal specific
    "account.paymentMethods.editCard.modal.label.cardNb"               : "Numéro sur la carte",
    "account.paymentMethods.editCard.modal.label.firstName.required"   : "Le prénom est requis",
    "account.paymentMethods.editCard.modal.label.lastName.required"    : "Le nom de famille est requis",
    "account.paymentMethods.editCard.modal.label.address.invalid"      : "Adresse invalide",
    "account.paymentMethods.editCard.modal.label.exp"                  : "Date d'expiration",
    "account.paymentMethods.editCard.modal.label.cvv"                  : "CVC",
    "account.paymentMethods.editCard.modal.label.newCardAdded.message" : "Nouvelle carte ajoutée à votre compte",
    "account.paymentMethods.editCard.modal.label.newCardAdded.info"    : "Sélectionnez la nouvelle carte si vous souhaitez payer avec celle-ci",

    "account.paymentMethods.removeCard.modal.title"       : "Vous êtes sur le point de retirer cette carte de votre portefeuille",
    "account.paymentMethods.removeCard.modal.success"     : "Carte supprimée",
    "account.paymentMethods.removeCard.modal.errors.6027" : "Cette carte est liée à un paiement et ne peut pas être supprimée.",

    "account.paymentMethods.changeCard.cards.title"                     : "Cartes",
    "account.paymentMethods.changeCard.modal.title"                     : "Gérer la transaction",
    "account.paymentMethods.changeCard.modal.validation.card.required"  : "Veuillez sélectionner une carte",
    "account.paymentMethods.changeCard.modal.validation.card.different" : "Veuillez sélectionner une carte différente que la carte actuellement utilisée",
    "account.paymentMethods.changeCard.sportPay.title"                  : "Sportspay",
    "account.paymentMethods.changeCard.sportPay.text"                   : "<pa>Cette transaction est une transaction SportsPay. Pour changer la méthode de paiement, vous allez être redirigés vers la passerelle de paiement SportsPay.</pa> <pa>Une fois les changements complétés, vous serez redigirés ici avec un message de confirmation.</pa> <pa>Veuillez cliquer sur le bouton « Gérer sur SportPay » pour aller sur la passerelle de paiement SportPay.</pa>",
    "account.paymentMethods.changeCard.sportPay.btn.manage"             : "Gérer sur SportsPay",
    "account.paymentMethods.changeCard.sportPay.success.text"           : "La méthode de paiement de cette transaction ainsi que les versements futurs ont été mis à jour.",

    "account.paymentMethods.sportPayReturnModal.success.title"               : "Succès !",
    "account.paymentMethods.sportPayReturnModal.success.text"                : "La carte a été changée.",
    "account.paymentMethods.sportPayReturnModal.success.initialPayment.text" : "Paiement réussi.",
    "account.paymentMethods.sportPayReturnModal.success.helper"              : "Le paiement sera traité à la date prévue.",
    "account.paymentMethods.sportPayReturnModal.success.btn"                 : "Compris !",

    "account.paymentMethods.sportPayReturnModal.pending.title" : "En traitement",
    "account.paymentMethods.sportPayReturnModal.pending.text"  : "Les changements que vous avez faits sont en traitement. Veuillez revenir plus tard.",

    "account.paymentMethods.sportPayReturnModal.declined.title" : "Refus",
    "account.paymentMethods.sportPayReturnModal.declined.text"  : "Les changements que vous avez faits n'ont pas été approuvés. Veuillez contacter notre équipe de support ou réessayer plus tard.",

    "account.paymentMethods.sportPayReturnModal.error.title" : "Une erreur s'est produite",
    "account.paymentMethods.sportPayReturnModal.error.text"  : "Nous avons de la difficulté à vérifier l'état de vos changements. Vous pouvez regarder manuellement si vos changements ont été traités ou vous pouvez contacter notre équipe de support.",

    "account.paymentMethods.sportPayReturnModal.loading.title" : "Chargement...",
    "account.paymentMethods.sportPayReturnModal.loading.text"  : "Veuillez attendre pendant que nous vérifier l'état de vos changements.",

    "account.paymentMethods.paymentConfirmation.title" : "Félicitations!",
    "account.paymentMethods.paymentConfirmation.text"  : "La facture {invoiceNumber} est maintenant payée.",

}