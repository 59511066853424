import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Label } from "reactstrap";
import Required from "../../../../../../../components/formik/Required";

const TeamCard = ({ teamPos = "home" }) => {
    const teamField = `${teamPos}_team_name`;

    return (
        <>
            <Label className="text-muted" for={teamField}><Translate id={`account.members.coach.teamName.${teamPos}.label`} /> <Required /></Label>
            <FormikInputText
                id={`${teamField}`}
                name={`${teamField}`}
            />
        </>
    );
}

export default TeamCard;