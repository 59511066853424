import React from "react";
import Translate, { DateFormat } from "@spordle/intl-elements";

// contexts
import withContexts from "../../../../../../../helpers/withContexts";
import { ClinicsContext } from "../../../../../../../contexts/ClinicsContext";
import SidePanelAttendeeSharedInfo from "./SidePanelAttendeeSharedInfo";
import SidePanel from "../../../../../../../components/sidePanel/SidePanel";

class SidePanelAttendeeInfo extends React.Component{

    render(){
        return (
            <>
                <SidePanel.Header>
                    <SidePanel.ToggleButton />
                </SidePanel.Header>
                <SidePanel.Body>
                    <div className="h4 font-bold mb-3"><Translate id='account.members.clinicsManagement.attendees.attendeeInfo' /></div>
                    <SidePanelAttendeeSharedInfo {...this.props} />

                    <div className="mb-3">
                        <div className="text-muted"><Translate id='account.members.clinicsManagement.attendees.certified.label' /></div>
                        <div className="font-medium">
                            {this.props.getCommonValue('certified') == 1 ?
                                <>
                                    <i className='mdi mdi-check text-primary mr-2' /><Translate id='account.members.clinicsManagement.attendees.certified' /><span className='small'> - <DateFormat value={this.props.getCommonValue('certified_date')} utc /></span>
                                </>
                                :
                                <Translate id='account.members.clinicsManagement.attendees.certified.not' />
                            }
                        </div>
                    </div>
                </SidePanel.Body>
            </>
        )
    }
}

export default withContexts(ClinicsContext)(SidePanelAttendeeInfo)