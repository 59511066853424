import { Component, useContext, useState } from 'react';
import {
    Button,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    Fade,
    Spinner
} from 'reactstrap';
import moment from 'moment';
import images from '@spordle/ui-kit';

// Contexts
import { AxiosCancelAll, AxiosIsCancelled } from '../../../api/CancellableAPI';
import { AuthContext, AccountsContext } from '../../../contexts/contexts';
import { MembersContext } from '../../../contexts/MembersContext';
import withContexts from '../../../helpers/withContexts';

import GoToHomeBtn from '../../../components/GoToHomeBtn';

import UserImg from '../../../components/UserImg';

// lang
import Translate, { DateFormat, getTranslation } from '@spordle/intl-elements';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import AddMemberModal from '../../../components/addMember/AddMemberModal';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { success, fail } from '@spordle/toasts';
import ConfirmModal from '../../../components/confirmModal/ConfirmModal';
import AccountDashboardOrgs from './components/AccountDashboardOrgs';
import UATWarning from '../../../components/UATWarning';
import { UtilsContext } from '../../../contexts/UtilsContext';
import { isDemo } from '../../../helpers/getReferer';

class AccountDashboard extends Component{
    static contextType = AuthContext;

    state = {
        identityRoles: null,
        identityMetaMembers: null,
        relations: [],
        hcrSearchOpen: false,
    }

    toggleHcrSearch = () => {
        this.setState((prevState) => ({ hcrSearchOpen: !prevState.hcrSearchOpen }))
    }

    // selectMember = (member) => {
    //     this.toggleHcrSearch();
    //     this.member = member;
    // }

    getMetaMembers = (fromApi = false) => {
        return this.props.AccountsContext.getIdentityMetaMembers(this.context.userData.Username, {}, fromApi)
            .then((response) => {
                response.meta_members.sort((mmA) => { if(mmA.is_primary === '1'){ return -1 } })
                this.setState(() => ({ identityMetaMembers: response.meta_members }))
                return response
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                }
            })
    }

    // createMetaMember = async(values) => {
    //     const data = await this.props.AccountsContext.createMetaMember(this.context.userData.Username, values.firstName, values.lastName, values.birthDate, values.relation);
    //     const metaMember = await this.props.AccountsContext.getIdentityMetaMember(this.context.userData.Username, data);

    //     return this.props.AccountsContext.linkMemberToMetaMember(this.context.userData.Username, data, [ ...metaMember.members, this.member.member_id ])
    //         .then(() => {
    //             success({ msg: 'account.dashboard.accountDashboard.addMember.toast.success' });
    //             this.getMetaMembers();
    //         })
    // }

    componentDidMount(){
        Promise.all([
            this.context.getUserIdentityRole(), // DEMO
            process.env.REACT_APP_ENVIRONMENT === 'stage' && !isDemo() ? this.context.getUserIdentityRoleSQ().catch((e) => { console.error(e, 'Error getting identity roles for SQ'); return []; }) : Promise.resolve([]),
            process.env.REACT_APP_ENVIRONMENT === 'stage' && !isDemo() ? this.context.getUserIdentityRoleBQ().catch((e) => { console.error(e, 'Error getting identity roles for BQ'); return []; }) : Promise.resolve([]),
            this.props.AccountsContext.getIdentityMetaMembers(this.context.userData.Username, { active: 1 }),
            this.props.UtilsContext.getRelations(),
        ]).then(([ identityRoles, identityRolesSQ, identityRolesBQ, metaMembers, relations ]) => {
            const filterData = (ir) => {
                if(ir.active === undefined || ir.active === '0' || ir.role.active === undefined || ir.role.active === '0'){
                    return false;
                }else if(!ir.start_date && !ir.end_date){
                    // both fields are empty strings/null/undefined
                    return true;
                }else if(!!ir.start_date && !!ir.end_date && moment(moment.now()).isBetween(moment(ir.start_date), moment(ir.end_date), undefined, '[]')){
                    // both dates are valid and now is between them
                    return true;
                }
                const start = ir.start_date ? moment(ir.start_date).isBefore(moment.now()) : true;
                const end = ir.end_date ? moment(ir.end_date).isAfter(moment.now()) : true;

                // we want to return false if only one of them is false
                // start can be false only if moment.now() is before, so the start date is in the future
                // end can be false only if moment.now() is after, so the end date is in the past
                // any other cases where they both are ''/null/undefined or valid with moment.now() is a valid case and we return true
                return start && end;
            }

            this.setState(() => ({
                identityMetaMembers: metaMembers.meta_members.sort((mmA) => mmA.is_primary === '1' ? -1 : 0),
                identityRoles: [
                    ...identityRoles.filter(filterData),
                    ...identityRolesSQ.filter(filterData).map((ir) => ({ ...ir, from: 'SQ' })),
                    ...identityRolesBQ.filter(filterData).map((ir) => ({ ...ir, from: 'BQ' })),
                    ...metaMembers.organisations, // Adding metaMember to register them
                ],
                relations: relations || [],
            }))
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
            }
        });
    }

    componentWillUnmount(){
        this.setState({ identityMetaMembers: {} });
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <header className="p-3 mb-0 card card-border card-shadow">
                    <div className="d-flex align-items-center justify-content-between">
                        {/* Title */}
                        <div className="flex-grow-0 h3 font-medium text-primary mb-0"><Translate id='account.dashboard.accountDashboard.title' /></div>
                        <div>
                            <GoToHomeBtn />
                        </div>
                    </div>
                </header>
                <UATWarning />
                <div className="page-content container ml-0">

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Organization                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    {!!this.state.identityRoles &&
                        (this.state.identityRoles.length <= 0 ?
                            <>{/*<AccountDashboardWelcome linkMember={this.toggleHcrSearch} />*/}</>
                            :
                            <AccountDashboardOrgs setIdRoles={(roles) => this.setState(() => ({ identityRoles: roles }))} idRoles={this.state.identityRoles} />
                        )
                    }

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Members                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className='d-flex'>
                        <div className="h4 font-medium d-inline-block mb-2 align-self-center"><Translate id='account.dashboard.accountDashboard.members' /></div>
                        <button type='button' className="btn btn-reset btn-link" onClick={this.toggleHcrSearch}><Translate id='account.dashboard.accountDashboard.addMember.link.title' /></button>
                    </div>
                    <AddMemberModal getMetaMembers={this.getMetaMembers} metaMembers={this.state.identityMetaMembers} isOpen={this.state.hcrSearchOpen} toggle={this.toggleHcrSearch} />

                    <Collapse isOpen={Array.isArray(this.state.identityMetaMembers)}>
                        <Row>
                            {this.state.identityMetaMembers?.length > 0 ?
                                this.state.identityMetaMembers.map((member) => {
                                    if(member.is_primary === '1' || member.members[0]){
                                        return (
                                            <Col md="6" key={'col-member-' + member.meta_member_id} className="mb-4">
                                                <MemberCard
                                                    getMetaMembers={this.getMetaMembers}
                                                    identity={this.context.userData.Username}
                                                    meta={member}
                                                    member={member.members[0]}
                                                    primary={member.is_primary === '1'}
                                                    logo={member.attachment}
                                                    relation={member.relation}
                                                    allRelations={this.state.relations}
                                                    birthDate={member.birthdate}
                                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                    name={member.first_name + ' ' + member.last_name}
                                                />
                                            </Col>
                                        )
                                    }
                                })
                                :
                                <Col sm="6">
                                    <Translate id='account.dashboard.accountDashboard.members.none' />
                                </Col>
                            }
                            <Col md="6" key={'col-member-add-button'} className="mb-4">
                                <LinkMemberCard toggleHcrSearch={this.toggleHcrSearch} />
                            </Col>
                        </Row>
                    </Collapse>
                </div>
            </>
        );
    }
}

/*--------------------------------------------------------------------------------*/
/* Member Card used in the dashboard                                              */
/*--------------------------------------------------------------------------------*/
const MemberCard = ({ primary, meta, identity, logo, relation, allRelations, birthDate, name, abbr, member, getMetaMembers }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const accountsContext = useContext(AccountsContext);

    return (
        <UserDisplay card className="d-flex position-relative h-100 mb-0">
            { isLoading &&
                <div className="position-absolute top-0 bottom-0 left-0 right-0">
                    <div className='overlay-loader'>
                        <Fade appear in>
                            <Spinner color='primary' type='grow' />
                        </Fade>
                    </div>
                </div>
            }
            {!primary &&
                <UncontrolledDropdown className="position-absolute top-0 right-0" inNavbar>
                    <DropdownToggle color="link">
                        <i className="mdi mdi-dots-vertical" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem disabled>
                            <Translate id='misc.edit' />
                        </DropdownItem>
                        <ConfirmModal
                            modalTitle={`${getTranslation('account.dashboard.accountDashboard.deleteMember.swal')} : ${name}`}
                            translateModalTitle={false}
                            cancelButtonOutline
                            icon='mdi-alert-circle-outline'
                            onConfirm={() => {
                                setIsLoading(true);

                                accountsContext.unlinkMemberFromMetaMember(identity, meta.meta_member_id)
                                    .then(async() => {
                                        await accountsContext.deleteMetaMember(identity, meta.meta_member_id)
                                        getMetaMembers(true);
                                        success({ msg: 'account.dashboard.accountDashboard.deleteMember.toast.success' });
                                        setIsLoading(false);
                                    })
                                    .catch((err) => {
                                        if(!AxiosIsCancelled(err.message)){
                                            fail({ info: <DisplayI18n field="message" i18n={err.i18n} defaultValue={err.message} />, skipInfoTranslate: true });
                                            setIsLoading(false);
                                        }
                                    })
                            }}
                            toggler={(fn) => (
                                <DropdownItem tag={Button} onClick={fn}>
                                    <Translate id='misc.remove' />
                                </DropdownItem>
                            )}
                        />
                    </DropdownMenu>
                </UncontrolledDropdown>
            }
            <UserDisplay.Container>
                <UserImg
                    abbr={abbr}
                    src={meta.attachment?.full_path}
                    filePos={meta.attachment?.file_position}
                    width={50}
                    alt={name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="min-w-0">
                <UserDisplay.Title className="text-truncate" title={name}>{primary && <i className="mdi mdi-crown text-warning mr-1 font-18" />}{name}</UserDisplay.Title>
                {birthDate &&
                    <UserDisplay.Subtitle>
                        <DateFormat value={birthDate} utc format={DateFormat.formats.longMonthDayYear} />
                    </UserDisplay.Subtitle>
                }
                {!primary &&
                    <>
                        <UserDisplay.Subtitle>
                            { relation ?
                                <DisplayI18n
                                    field="name"
                                    defaultValue={relation.name}
                                    i18n={allRelations.find((rel) => rel.relation_id == relation.relation_id)?.i18n}
                                />
                                :
                                <Translate id={'form.fields.relation.OTHER'} />
                            }
                        </UserDisplay.Subtitle>
                        <UserDisplay.Subtitle>HCR# {member.unique_identifier}</UserDisplay.Subtitle>
                    </>
                }
            </UserDisplay.Container>
        </UserDisplay>
    );
}

const LinkMemberCard = ({ toggleHcrSearch }) => {
    return (
        <span onClick={toggleHcrSearch}>
            <UserDisplay hover card className="d-flex position-relative h-100 mb-0 border-primary" style={{ borderStyle: 'dashed' }}>
                <UserDisplay.Container>
                    <UserImg
                        abbr={''}
                        width={50}
                        src={images.noprofile}
                        alt='Default account image'
                    />
                </UserDisplay.Container>
                <UserDisplay.Container className="min-w-0">
                    <UserDisplay.Title className="text-truncate text-link"><Translate id='account.dashboard.accountDashboard.addMember.link.title' /></UserDisplay.Title>
                </UserDisplay.Container>
            </UserDisplay>
        </span>
    )
}


export default withContexts(AccountsContext, MembersContext, UtilsContext)(AccountDashboard);