import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

export const useSelectProps = () => {
    /**
     * @description Used for filtering selects. Acts as a global variable -> Will create filter only on initial mount
     */
    //  const filterSelect = useRef(
    //     createFilter({
    //         stringify: option => {
    //             if(option.value == "*") {
    //                 return '';
    //             }
    //             return `${option.data.translateLabel ?
    //                         formatMessage({ id: option.data.label })
    //                         :
    //                         displayI18n('name', option.data.i18n, option.data.label, getGenericLocale())
    //                     } ${option.value}`
    //         }
    //     })
    // );

    const getProps = (name) => ({
        id: name,
        name: name,
        // filterOption: filterSelect.current,
        menuShouldScrollIntoView: false,
        placeholder: <Translate id='misc.select.placeholder' />,
        noOptionsMessage: () => <Translate id='misc.noSelection' />,
        getOptionLabel: (option) => option.translateLabel ? <Translate id={option.label} /> : <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />,
    });

    return { getSelectProps: getProps };
}