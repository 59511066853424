import { stringBuilder } from "@spordle/helpers";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import UserDisplay from "../userDisplay/UserDisplay";
import CollapseIcon from "./CollapseIcon";

/**
 * @param {object} props
 * @param {React.ReactElement} props.extraInfo
 * @param {React.ReactElement} props.subtitle
 * @param {React.ReactElement} props.title
 * @param {React.ReactElement} props.img
 * @param {string} props.iconColor
 * @param {boolean} props.initOpen
 * @param {boolean} props.error
 * @param {string} props.innerClassName
 * @param {string} props.className
 * @param {boolean} props.isDisabled
 * @param {boolean} props.mountChildrenOnEnter
 * @param {boolean} props.withPreload
 * @returns {React.ReactElement}
 */
const CollapsibleCard = ({ isDisabled, className, innerClassName, error = false, title, img, subtitle, initOpen = false, children, extraInfo, iconColor, mountChildrenOnEnter, withPreload }) => {
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(!isDisabled && initOpen);
    const [ preload, setPreload ] = useState(false);
    const showError = error && !collapseIsOpen;

    useEffect(() => {
        if(initOpen !== collapseIsOpen) setCollapseIsOpen(initOpen);
    }, [ initOpen ]);

    return (
        <div className={stringBuilder(className, { "border-danger": showError }, "bg-light-inverse transition border rounded-lg shadow-sm")}>
            <UserDisplay
                card
                block
                onClick={() => {
                    if(!isDisabled){
                        setCollapseIsOpen(!collapseIsOpen);
                    }
                }}
                onMouseEnter={() => {
                    if(withPreload){
                        setPreload(true);
                    }
                }}
                onMouseLeave={() => {
                    if(withPreload){
                        setPreload(false);
                    }
                }}
                className={
                    stringBuilder(
                        innerClassName,
                        "position-relative mb-0 transition border-0",
                        isDisabled && !showError ? "bg-light" : "clickable",
                        { "bg-light-danger": showError },
                    )
                }
            >
                <UserDisplay.Container className="d-flex align-items-center justify-content-center" style={{ width: 40 }}>
                    <CollapseIcon isOpen={collapseIsOpen} iconColor={showError ? 'white' : iconColor} />
                </UserDisplay.Container>
                { img &&
                    <UserDisplay.Container>
                        {img}
                    </UserDisplay.Container>
                }
                <UserDisplay.Container className="mr-auto">
                    <UserDisplay.Title>{title}</UserDisplay.Title>
                    {subtitle &&
                        <UserDisplay.Subtitle className={showError ? 'text-body' : undefined}>
                            {subtitle}
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                {extraInfo}
            </UserDisplay>
            <Collapse isOpen={collapseIsOpen} mountOnEnter={withPreload ? undefined : mountChildrenOnEnter} unmountOnExit={withPreload ? undefined : mountChildrenOnEnter}>
                <div className="p-3">
                    {withPreload ? preload || collapseIsOpen ? children : undefined : children}
                </div>
            </Collapse>
        </div>
    );
}

export default CollapsibleCard;