export default{
    "components.uploader.imgUpload.modal.title"   : "Téléverser une image",
    "components.uploader.imgUpload.modal.drag"    : "glisser le fichier ici",
    "components.uploader.imgUpload.modal.drop"    : "Relâcher pour téléverser",
    "components.uploader.imgUpload.modal.maxSize" : "Taille de fichier maximal:",
    "components.uploader.imgUpload.modal.or"      : "ou",
    "components.uploader.imgUpload.modal.btn"     : "Ajouter image",
    "components.uploader.imgUpload.modal.noFile"  : "Aucun fichier.",

    "components.uploader.imgUpload.error.title"    : "Oups !",
    "components.uploader.imgUpload.error.tooMany"  : "Trop de fichiers",
    "components.uploader.imgUpload.error.max"      : "Le nombre de fichiers ne doit pas excéder {maxSize}",
    "components.uploader.imgUpload.error.noFile"   : "Veuillez ajouter un fichier.",
    "components.uploader.imgUpload.error.tooLarge" : "La taille du fichier ne doit pas excéder {maxSize, number, ::.## unit/megabyte}",
    "components.uploader.imgUpload.error.3331"     : "La taille du fichier excède la taille maximum",
    "components.uploader.imgUpload.error.3332"     : "Cette extension de fichier n'est pas acceptée",
    "components.uploader.imgUpload.error.3189"     : "Aucun paramètre de vérification d'antécédents trouvé",
    "components.uploader.imgUpload.error.fileType" : "Seuls ces types de fichiers sont acceptés: {accepted}",
    "components.uploader.imgUpload.error.exists"   : "Vous avez déjà ajouté ce fichier: {name}",

    "components.uploader.alreadyUploaded" : "est déjà téléchargé",

}