export default{
    "reports.header.registration"       : "Registration Report",
    "reports.header.membershipFees"     : "Membership Fees Report",
    "reports.header.waivers"            : "Waivers Report",
    "reports.header.multi_branch"       : "Branch Report",
    "reports.header.questionnaire"      : "Questionnaire Report",
    "reports.header.credits"            : "Credits Report",
    "reports.header.qualifications"     : "Qualifications Report",
    "reports.header.deficiency"         : "Qualification Deficiency Report",
    "reports.header.refunds"            : "Refunds Report",
    "reports.header.exports"            : "Exports",
    "reports.header.crc"                : "Background Check Report",
    "reports.header.branch_summary"     : "Branch Summary Report",
    "reports.header.registration_count" : "Registration Count Report",
    "reports.header.clinic_attendees"   : "Clinic Attendees Report",
    "reports.header.transfer"           : "Transfers Report",
    "reports.header.discrimination"     : "Discrimination Report",
    "reports.header.not_on_team"        : "Members Without Team Report",
    "reports.header.injuries"           : "Injuries Report",
    "reports.header.team_list"          : "Team List Report",
    "reports.header.game_incident"      : "Game Incident Report",
    "reports.header.membershipFee"      : "Membership Fee Report",
    "reports.header.team_roster"        : "Team Roster Report",
    "reports.header.suspensions"        : "Suspensions Report",

    "reports.filters.with_draft"                  : "With Draft",
    "reports.filters.gender"                      : "Gender Identity",
    "reports.filters.period"                      : "Period",
    "reports.filters.reference_period_id"         : "Reference Period",
    "reports.filters.origin"                      : "Origin",
    "reports.filters.online_store"                : "Online Store",
    "reports.filters.reg_type"                    : "Registration Type",
    "reports.filters.reg_status"                  : "Registration Status",
    "reports.filters.start_date"                  : "Start Date",
    "reports.filters.end_date"                    : "End Date",
    "reports.filters.created_start_date"          : "Created Start Date",
    "reports.filters.created_end_date"            : "Created End Date",
    "reports.filters.expiration_start_date"       : "Expiration Start Date",
    "reports.filters.expiration_end_date"         : "Expiration End Date",
    "reports.filters.custom_form_id"              : "Form",
    "reports.filters.registration_fee_id"         : "Registration",
    "reports.filters.expiration_date"             : "Expiration Date",
    "reports.filters.created_at"                  : "Created at",
    "reports.filters.active"                      : "Active credits only",
    "reports.filters.credit_type"                 : "Credit Type",
    "reports.filters.active_label"                : "Display",
    "reports.filters.crc_status"                  : "Expiration Status",
    "reports.filters.child_organisation_id"       : "Child Organizations",
    "reports.filters.child_organisation_id.count" : "{count} selected organizations",
    "reports.filters.divisions.count"             : "{count} selected divisions",
    "reports.filters.waivers.count"               : "{count} selected waivers",
    "reports.filters.teams.count"                 : "{count} selected teams",
    "reports.filters.qualifications.count"        : "{count} selected qualifications",
    "reports.filters.clinics.count"               : "{count} selected clinics",
    "reports.filters.qualification_id"            : "Qualifications",
    "reports.filters.division"                    : "Division",
    "reports.filters.member_type_id"              : "Member Type",
    "reports.filters.expiration_status"           : "Expiration Status",
    "reports.filters.status"                      : "Status",
    "reports.filters.with_balance"                : "With balance only",
    "reports.filters.attended_start_date"         : "Attended Start Date",
    "reports.filters.attended_end_date"           : "Attended End Date",
    "reports.filters.passed_start_date"           : "Passed Start Date",
    "reports.filters.passed_end_date"             : "Passed End Date",
    "reports.filters.certified_start_date"        : "Certified Start Date",
    "reports.filters.certified_end_date"          : "Certified End Date",
    "reports.filters.member_attendee_status"      : "Attendee Status",
    "reports.filters.clinic_id"                   : "Clinic",
    "reports.filters.transfer_type"               : "Transfer Type",
    "reports.filters.transfer_status"             : "Transfer Status",
    "reports.filters.transfer_reason_type_id"     : "Transfer Reason Type",
    "reports.filters.transfer_duration"           : "Transfer Duration",
    "reports.filters.refund_status"               : "Refund Status",
    "reports.filters.invoice_numbers"             : "Invoice Number(s)",
    "reports.filters.accident_start_date"         : "Accident Start Date",
    "reports.filters.accident_end_date"           : "Accident End Date",
    "reports.filters.delivered_start_date"        : "Delivered Start Date",
    "reports.filters.delivered_end_date"          : "Delivered End Date",
    "reports.filters.team_id"                     : "Team",
    "reports.filters.division_id"                 : "Division",
    "reports.filters.class_id"                    : "Class",
    "reports.filters.team_category_id"            : "Team Category",
    "reports.filters.position_group_id"           : "Position Group",
    "reports.filters.position_id"                 : "Position",
    "reports.filters.with_registration"           : "With Registration",
    "reports.filters.waiver_status"               : "Waiver Status",
    "reports.filters.waiver_id"                   : "Waivers",
    "reports.filters.period.required"             : "Period is required.",
    "reports.filters.waiver.required"             : "Waiver is required.",
    "reports.filters.clinic.required"             : "Clinic is required.",
    "reports.filters.form.required"               : "Form is required.",
    "reports.filters.division.required"           : "Division is required.",
    "reports.filters.start_date.required"         : "Start date is required.",
    "reports.filters.end_date.required"           : "End date is required.",
    "reports.filters.child_org"                   : "A maximum of 50 organizations can be selected. Leave empty to include all.",
    "reports.filters.multi_team_category"         : "A maximum of 50 team categories can be selected.",
    "reports.filters.multi_org"                   : "A maximum of 50 organizations can be selected.",
    "reports.filters.max_50"                      : "A maximum of 50 can be selected.",
    "reports.filters.multi_org_required"          : "Organization is required.",
    "reports.filters.start_date_required"         : "Start date is required.",
    "reports.filters.end_date_required"           : "End date is required.",
    "reports.filters.clinic_max"                  : "A maximum of 50 clinics can be selected individually, use the 'select all' option for more than 50.",
    "reports.filters.qualifications_max"          : "A maximum of 50 qualifications can be selected.",
    "reports.filters.qualifications_min"          : "You must select at least one qualification.",
    "reports.filters.period.is_after_reference"   : "Period must be after the reference period.",
    "reports.filters.previous_injury"             : "Previous Injury",
    "reports.filters.penalty_involved"            : "Penalty Involved",
    "reports.filters.submitted"                   : "Submitted",
    "reports.filters.team_status_id"              : "Team Status",
    "reports.filters.allSelected"                 : "All Selected",
    "reports.column.header.organisation_id"       : "Organizations",
    "reports.filters.verified"                    : "Verified",
    "reports.filters.ageOverUnder"                : "Age Range",
    "reports.filters.birthdate_start_date"        : "Birthdate Start Date",
    "reports.filters.birthdate_end_date"          : "Birthdate End Date",
    "reports.filters.specific_date"               : "Specific Date",
    "reports.filters.with_team"                   : "With Team",
    "reports.filters.suspension_type_id"          : "Suspension Type",
    "reports.filters.duration"                    : "Duration",

    "reports.filters.expired.EXPIRED" : "Expired",
    "reports.filters.expired.VALID"   : "Valid",
    "reports.filters.expired.BOTH"    : "Both",

    "reports.member_attendee.status.REGISTERED" : "Registered",
    "reports.member_attendee.status.ATTENDED"   : "Attended",
    "reports.member_attendee.status.PASSED"     : "Passed",
    "reports.member_attendee.status.CERTIFIED"  : "Certified",

    "reports.column.header.invoice_number"                            : "Invoice Number",
    "reports.column.header.invoice_date"                              : "Invoice Date",
    "reports.column.header.hcr_number"                                : "HCR Number",
    "reports.column.header.unique_identifier"                         : "HCR Number",
    "reports.column.header.member_unique_identifier"                  : "HCR Number",
    "reports.column.header.member_first_name"                         : "First Name",
    "reports.column.header.member_last_name"                          : "Last Name",
    "reports.column.header.first_name"                                : "First Name",
    "reports.column.header.last_name"                                 : "Last Name",
    "reports.column.header.email"                                     : "Email",
    "reports.column.header.birthdate"                                 : "Birthdate",
    "reports.column.header.gender"                                    : "Gender Identity",
    "reports.column.header.address"                                   : "Address",
    "reports.column.header.parent_name"                               : "Parent Name",
    "reports.column.header.parent_family_name"                        : "Parent Last Name",
    "reports.column.header.fee_type"                                  : "Fee Type",
    "reports.column.header.fee_name"                                  : "Fee Name",
    "reports.column.header.registration_amount"                       : "Registration Amount",
    "reports.column.header.registration_early_amount"                 : "Early Amount",
    "reports.column.header.early_amount_until"                        : "Early Pricing Cutoff",
    "reports.column.header.late_amount"                               : "Late Amount",
    "reports.column.header.registration_late_amount"                  : "Late Amount",
    "reports.column.header.late_amount_after"                         : "Late Pricing Cutoff",
    "reports.column.header.rebate_amount"                             : "Rebate",
    "reports.column.header.item_rebate_amount"                        : "Rebate",
    "reports.column.header.item_paid_amount"                          : "Amount Paid",
    "reports.column.header.item_amount"                               : "Item Amount",
    "reports.column.header.item_due_amount"                           : "Due Amount",
    "reports.column.header.item_owed_amount"                          : "Owed Amount",
    "reports.column.header.amount_paid"                               : "Amount Paid",
    "reports.column.header.amount_to_paid"                            : "Amount",
    "reports.column.header.status"                                    : "Status",
    "reports.column.header.origin"                                    : "Origin",
    "reports.column.header.balance"                                   : "Balance",
    "reports.column.header.note"                                      : "Note",
    "reports.column.header.credit_note"                               : "Note",
    "reports.column.header.created_by"                                : "Created by",
    "reports.column.header.created_at"                                : "Created at",
    "reports.column.header.amount"                                    : "Amount",
    "reports.column.header.amount_due"                                : "Amount Due",
    "reports.column.header.amount_owed"                               : "Amount Owed",
    "reports.column.header.credit_type"                               : "Credit Type",
    "reports.column.header.credit_type_name"                          : "Credit Type",
    "reports.column.header.father_name"                               : "Father Name",
    "reports.column.header.father_family_name"                        : "Father Family Name",
    "reports.column.header.father_email"                              : "Father Email",
    "reports.column.header.father_phone"                              : "Father Phone",
    "reports.column.header.mother_name"                               : "Mother Name",
    "reports.column.header.mother_family_name"                        : "Mother Family Name",
    "reports.column.header.mother_email"                              : "Mother Email",
    "reports.column.header.mother_phone"                              : "Mother Phone",
    "reports.column.header.scheduled_installment"                     : "Scheduled Installment",
    "reports.column.header.division_name"                             : "Division",
    "reports.column.header.class_name"                                : "Class",
    "reports.column.header.team_category_name"                        : "Team Category",
    "reports.column.header.payment_method"                            : "Payment Method",
    "reports.column.header.organisation_name"                         : "Organization",
    "reports.column.header.child_organisation"                        : "Child Organizations",
    "reports.column.header.cancelled_at"                              : "Cancelled Registration",
    "reports.column.header.confirmed_at"                              : "Confirmed Registration",
    "reports.column.header.street_number"                             : "Street Number",
    "reports.column.header.street"                                    : "Street",
    "reports.column.header.city"                                      : "City",
    "reports.column.header.province"                                  : "Province",
    "reports.column.header.zip_code"                                  : "Postal Code",
    "reports.column.header.unit_number"                               : "Unit Number",
    "reports.column.header.registration_name"                         : "Registration Name",
    "reports.column.header.crc_type"                                  : "Background Check Type",
    "reports.column.header.issue_date"                                : "Issue Date",
    "reports.column.header.registration_date_status"                  : "Last Updated",
    "reports.column.header.registration_organisation_name"            : "Registration Organization Name",
    "reports.column.header.registration_status "                      : "Registration Status",
    "reports.column.header.member_type_name"                          : "Member Type",
    "reports.column.header.affiliation_paid"                          : "Affilition Paid",
    "reports.column.header.affiliation_to_paid"                       : "Affilition Due",
    "reports.column.header.qualification"                             : "Qualification",
    "reports.column.header.certification_expiration"                  : "Certification Expiration",
    "reports.column.header.certification_number"                      : "Certification Number",
    "reports.column.header.member_organisation"                       : "Member Organization",
    "reports.column.header.province_id"                               : "Province",
    "reports.column.header.country_id"                                : "Country",
    "reports.column.header.country"                                   : "Country",
    "reports.column.header.qualification_name"                        : "Qualification Name",
    "reports.column.header.qualification_category"                    : "Qualification Category",
    "reports.column.header.qualification_level"                       : "Qualification Level",
    "reports.column.header.qualification_organisation"                : "Qualification Organization",
    "reports.column.header.qualification_location"                    : "Qualification Location",
    "reports.column.header.qualification_city"                        : "Qualification City",
    "reports.column.header.qualification_province"                    : "Qualification Province",
    "reports.column.header.memo"                                      : "Memo",
    "reports.column.header.taken_date"                                : "Taken Date",
    "reports.column.header.registration_status"                       : "Registration Status",
    "reports.column.header.primary_organisation_name"                 : "Primary Organization",
    "reports.column.header.province_code"                             : "Province",
    "reports.column.header.country_code"                              : "Country",
    "reports.column.header.waiver_name"                               : "Waiver",
    "reports.column.header.waiver_status"                             : "Signed",
    "reports.column.header.signed_by_identity_name"                   : "Signed by",
    "reports.column.header.signature_date"                            : "Signature Date",
    "reports.column.header.waiver_organisation_name"                  : "Signed With Organization",
    "reports.column.header.is_mandatory"                              : "Mandatory",
    "reports.column.header.credit_amount"                             : "Credit Amount",
    "reports.column.header.credit_type_description"                   : "Credit Type Description",
    "reports.column.header.active"                                    : "Active",
    "reports.column.header.registration_count"                        : "Registration Count",
    "reports.column.header.item_left"                                 : "Items Left",
    "reports.column.header.item_sold"                                 : "Items Sold",
    "reports.column.header.item_type"                                 : "Items Type",
    "reports.column.header.item_name"                                 : "Item Name",
    "reports.column.header.available_place"                           : "Available Places",
    "reports.column.header.clinic_name"                               : "Clinic Name",
    "reports.column.header.clinic_organisation_name"                  : "Clinic Organization Name",
    "reports.column.header.clinic_first_session_date"                 : "First Session Date",
    "reports.column.header.member_attendee_status"                    : "Member Attendee Status",
    "reports.column.header.attended"                                  : "Attended",
    "reports.column.header.attended_date"                             : "Attended Date",
    "reports.column.header.passed"                                    : "Passed",
    "reports.column.header.passed_date"                               : "Passed Date",
    "reports.column.header.certified"                                 : "Certified",
    "reports.column.header.certified_date"                            : "Certified Date",
    "reports.column.header.invoice_status"                            : "Invoice Status",
    "reports.column.header.total_amount"                              : "Total Amount",
    "reports.column.header.clinic_amount"                             : "Clinic Amount",
    "reports.column.header.clinic_additional_fee"                     : "Clinic Additional Fee",
    "reports.column.header.due_amount"                                : "Due Amount",
    "reports.column.header.paid_amount"                               : "Paid Amount",
    "reports.column.header.owed_amount"                               : "Owed Amount",
    "reports.column.header.organisation"                              : "Organization",
    "reports.column.header.target_organisation"                       : "Target Organization",
    "reports.column.header.updated_at"                                : "Updated At",
    "reports.column.header.expiration_date"                           : "Expiration Date",
    "reports.column.header.shared_expiration_date"                    : "Shared Expiration Date",
    "reports.column.header.transfer_duration"                         : "Transfer Duration",
    "reports.column.header.transfer_type"                             : "Transfer Type",
    "reports.column.header.transfer_reason_type"                      : "Transfer Reason",
    "reports.column.header.transfer_request_state"                    : "Transfer Request State",
    "reports.column.header.transfer_status"                           : "Transfer Status",
    "reports.column.header.current_step"                              : "Current Step",
    "reports.column.header.period"                                    : "Period",
    "reports.column.header.sequential_number"                         : "Sequential Number",
    "reports.column.header.incident_date"                             : "Incident Date",
    "reports.column.header.arena"                                     : "Arena",
    "reports.column.header.home_team_name"                            : "Home Team",
    "reports.column.header.away_team_name"                            : "Away Team",
    "reports.column.header.belongs_to"                                : "Belongs to",
    "reports.column.header.complaint_by"                              : "Complaint By",
    "reports.column.header.complaint_by_last_name"                    : "Complaint By Last Name",
    "reports.column.header.complaint_by_first_name"                   : "Complaint By First Name",
    "reports.column.header.complaint_by_email"                        : "Complaint By Email",
    "reports.column.header.complaint_by_fax"                          : "Complaint By Fax",
    "reports.column.header.complaint_by_address"                      : "Complaint By Address",
    "reports.column.header.complaint_by_city"                         : "Complaint By City",
    "reports.column.header.complaint_by_postal_code"                  : "Complaint By Postal Code",
    "reports.column.header.complaint_by_province_id"                  : "Complaint By Province",
    "reports.column.header.complaint_by_phone"                        : "Complaint By Phone",
    "reports.column.header.discrimination_reason"                     : "Discrimination Reason",
    "reports.column.header.role"                                      : "Role",
    "reports.column.header.identity_name"                             : "Identity Name",
    "reports.column.header.identity_email"                            : "Identity Email",
    "reports.column.header.discrimination_approbation_identity_name"  : "Discrimination Approbation Name",
    "reports.column.header.discrimination_approbation_identity_email" : "Discrimination Approbation Email",
    "reports.column.header.discrimination_approbation_status"         : "Discrimination Approbation Status",
    "reports.column.header.discrimination_status_name"                : "Discrimination Status",
    "reports.column.header.team_name"                                 : "Team Name",
    "reports.column.header.on_behalf_first_name"                      : "On Behalf of First Name",
    "reports.column.header.on_behalf_last_name"                       : "On Behalf of Last Name",
    "reports.column.header.on_behalf_birthdate"                       : "On Behalf of Birthdate",
    "reports.column.header.type"                                      : "Type",
    "reports.column.header.closed_reason"                             : "Closed Reason",
    "reports.column.header.detailed_summary"                          : "Detailed Summary",
    "reports.column.header.invoice_identity_name"                     : "Invoice Name",
    "reports.column.header.invoice_identity_email"                    : "Invoice Email",
    "reports.column.header.internal_note"                             : "Internal Note",
    "reports.column.header.external_note"                             : "External Note",
    "reports.column.header.refund_amount"                             : "Refunded Amount",
    "reports.column.header.refund_date"                               : "Refund date",
    "reports.column.header.position_group_name"                       : "Position Group",
    "reports.column.header.team_organisation_name"                    : "Team Organization",
    "reports.column.header.affiliate"                                 : "Affiliate",
    "reports.column.header.tryout"                                    : "Tryout",
    "reports.column.header.import"                                    : "Import",
    "reports.column.header.registration_date"                         : "Registration Date",
    "reports.column.header.position_name"                             : "Position",
    "reports.column.header.injury_case_number"                        : "Case Number",
    "reports.column.header.injury_type"                               : "Claim Type",
    "reports.column.header.participant_type"                          : "Participant Type",
    "reports.column.header.description"                               : "Description",
    "reports.column.header.guardian"                                  : "Guardian",
    "reports.column.header.accident_date"                             : "Accident Date",
    "reports.column.header.delivered_date"                            : "Delivered Date",
    "reports.column.header.body_part_name"                            : "Body Part",
    "reports.column.header.injury_nature_name"                        : "Injury Nature",
    "reports.column.header.injury_on_site_care_name"                  : "On site care",
    "reports.column.header.venue_name"                                : "Venue",
    "reports.column.header.injury_environment_other"                  : "Environment Other",
    "reports.column.header.injury_environment_name"                   : "Environment",
    "reports.column.header.injury_condition_name"                     : "Condition",
    "reports.column.header.injury_condition_other"                    : "Condition Other",
    "reports.column.header.correct_age"                               : "Correct Age",
    "reports.column.header.sanctionned_event"                         : "Sanctionned Event",
    "reports.column.header.injury_cause_name"                         : "Cause",
    "reports.column.header.event_type"                                : "Event Type",
    "reports.column.header.event_type_other"                          : "Event Type Other",
    "reports.column.header.injury_equipment_name"                     : "Equipment",
    "reports.column.header.previous_injury"                           : "Previous Injury",
    "reports.column.header.previous_injury_other"                     : "Previous Injury Other",
    "reports.column.header.penalty_involved"                          : "Penalty Involved",
    "reports.column.header.injury_absence_name"                       : "Absence",
    "reports.column.header.official_unique_identifier"                : "Official HCR Number",
    "reports.column.header.official_first_name"                       : "Official First Name",
    "reports.column.header.official_last_name"                        : "Official Last Name",
    "reports.column.header.official_birthdate"                        : "Official Birthdate",
    "reports.column.header.official_gender"                           : "Official Gender Identity",
    "reports.column.header.official_email"                            : "Official Email",
    "reports.column.header.injury_status_name"                        : "Injury Status",
    "reports.column.header.employed"                                  : "Employed",
    "reports.column.header.employer_name"                             : "Employer",
    "reports.column.header.provincial_coverage"                       : "Provincial Coverage",
    "reports.column.header.health_insurance_number"                   : "Health Insurance Number",
    "reports.column.header.other_insurance"                           : "Other Health Insurance",
    "reports.column.header.submitted"                                 : "Submitted",
    "reports.column.header.injury_pay_to_name"                        : "Pay To Name",
    "reports.column.header.injury_pay_to_other"                       : "Pay To Other",
    "reports.column.header.reserve"                                   : "Reserve",
    "reports.column.header.reported_by_name"                          : "Reported By Name",
    "reports.column.header.reported_by_email"                         : "Reported By Email",
    "reports.column.header.team_number"                               : "Team Number",
    "reports.column.header.team_organisation"                         : "Organization",
    "reports.column.header.team_status"                               : "Team Status",
    "reports.column.header.team_gender"                               : "Gender Identity",
    "reports.column.header.category_name"                             : "Category",
    "reports.column.header.player_count"                              : "Player Count",
    "reports.column.header.bench_staff_count"                         : "Bench Staff Count",
    "reports.column.header.other_staff_count"                         : "Other Staff Count",
    "reports.column.header.team_organisation_path"                    : "Team Organization Path",
    "reports.column.header.suspension_name"                           : "Suspension Name",
    "reports.column.header.suspension_type_name"                      : "Suspension Type",
    "reports.column.header.game_incident_number"                      : "Incident Number",
    "reports.column.header.primary_official_unique_identifier"        : "Primary Official HCR Number",
    "reports.column.header.primary_official_first_name"               : "Primary Official First Name",
    "reports.column.header.primary_official_last_name"                : "Primary Official Last Name",
    "reports.column.header.primary_official_email"                    : "Primary Official Email",
    "reports.column.header.game_officials"                            : "Game Officials",
    "reports.column.header.game_number"                               : "Game Number",
    "reports.column.header.home_team_coach_first_name"                : "Home Coach First Name",
    "reports.column.header.home_team_coach_last_name"                 : "Home Coach Last Name",
    "reports.column.header.home_team_manager_first_name"              : "Home Manager First Name",
    "reports.column.header.home_team_manager_last_name"               : "Home Manager Last Name",
    "reports.column.header.away_team_coach_first_name"                : "Away Coach First Name",
    "reports.column.header.away_team_coach_last_name"                 : "Away Coach Last Name",
    "reports.column.header.away_team_manager_first_name"              : "Away Manager First Name",
    "reports.column.header.away_team_manager_last_name"               : "Away Manager Last Name",
    "reports.column.header.game_incident_status_name"                 : "Incident Status",
    "reports.column.header.verified"                                  : "Verified",
    "reports.column.header.created_by_name"                           : "Created by",
    "reports.column.header.created_by_email"                          : "Created by Email",
    "reports.column.header.teams_staff"                               : "Team Staff",
    "reports.column.header.teams_player"                              : "Player for",
    "reports.column.header.teams_coach"                               : "Coach for",
    "reports.column.header.jersey_number"                             : "Jersey Number",
    "reports.column.header.team_short_name"                           : "Team Short Name",
    "reports.column.header.team_fee"                                  : "Team Fee",
    "reports.column.header.period_name"                               : "Period",
    "reports.column.header.organisation_path"                         : "Organization Path",
    "reports.column.header.overwrite_restriction"                     : "Overwrite Restriction",
    "reports.column.header.team_home_color"                           : "Team Home Colour",
    "reports.column.header.team_away_color"                           : "Team Away Colour",
    "reports.column.header.clinic_first_session_location"             : "First Session Location",
    "reports.column.header.team_organisation_id"                      : "Team Organization Id",
    "reports.column.header.release_date"                              : "Release Date",
    "reports.column.header.comments"                                  : "Comments",
    "reports.column.header.comment"                                   : "Comments",
    "reports.column.header.player_status"                             : "Player Status",
    "reports.column.header.team_member_status_id"                     : "Team Member Status Id",
    "reports.column.header.third_party_registration"                  : "Third Party Registration",
    "reports.column.header.member_primary_organisation"               : "Member Primary Organisation",
    "reports.column.header.region_fee"                                : "Region Fee",
    "reports.column.header.branch_fee"                                : "Branch Fee",
    "reports.column.header.federation_fee"                            : "Federation Fee",
    "reports.column.header.is_double_carded"                          : "Double Carded",
    "reports.column.header.double_carding_team"                       : "Double Carding Team",
    "reports.column.header.other_organisation_fee"                    : "Other Organization Fee",
    "reports.column.header.suspension_code"                           : "Suspension Code",
    "reports.column.header.other_infraction"                          : "Other Infraction",
    "reports.column.header.duration"                                  : "Duration",
    "reports.column.header.start_date"                                : "Start Date",
    "reports.column.header.end_date"                                  : "End Date",
    "reports.column.header.opposing_team_name"                        : "Opposing Team Name",
    "reports.column.header.penality_minutes"                          : "Penalty Minutes",
    "reports.column.header.game_suspended"                            : "Game Suspended",
    "reports.column.header.received_date"                             : "Received Date",
    "reports.column.header.printed_date"                              : "Printed Date",
    "reports.column.header.submited_by"                               : "Submitted By",
    "reports.column.header.location"                                  : "Location",
    "reports.column.header.official_name"                             : "Official Name",
    "reports.column.header.reference_number"                          : "Reference Number",
    "reports.column.header.submitted_email"                           : "Submitted Email",

    "reports.column.origin.ADMIN"  : "Admin",
    "reports.column.origin.ONLINE" : "Online Transaction",
    "reports.column.origin.IMPORT" : "Import",

    "reports.registration.transfer_status.OPEN"   : "Open",
    "reports.registration.transfer_status.CLOSED" : "Closed",

    "reports.column.misc.unit" : "Unit",

    "reports.column.status.PENDING"        : "Pending",
    "reports.column.status.COMPLETED"      : "Completed",
    "reports.column.status.PARTIALLY_PAID" : "Partially Paid",
    "reports.column.status.CANCELLED"      : "Cancelled",
    "reports.column.status.CONFIRMED"      : "Confirmed",
    "reports.column.status.PASSED"         : "Passed",
    "reports.column.status.REGISTERED"     : "Registered",

    "reports.column.clinic_status.REGISTERED" : "Registered",
    "reports.column.clinic_status.ATTENDED"   : "Attended",
    "reports.column.clinic_status.PASSED"     : "Passed",
    "reports.column.clinic_status.CERTIFIED"  : "Certified",

    "reports.registration.status.CONFIRMED" : "Confirmed",
    "reports.registration.status.CANCELLED" : "Cancelled",
    "reports.registration.status.PENDING"   : "Pending",

    "reports.registration.type.REGISTRATION" : "Registration",
    "reports.registration.type.OTHER"        : "Other",
    "reports.registration.type.CLINIC"       : "Clinic",

    "reports.with_registration.YES"     : "Yes",
    "reports.with_registration.NO"      : "No",
    "reports.with_registration.ALL"     : "Both",
    "reports.with_registration.NO_INFO" : "No Info",

    "reports.ageOverUnder.ALL"      : "All",
    "reports.ageOverUnder.overAge"  : "Overage",
    "reports.ageOverUnder.OVERAGE"  : "Overage",
    "reports.ageOverUnder.underAge" : "Underage",
    "reports.ageOverUnder.UNDERAGE" : "Underage",

    "reports.claim_type.CLAIM" : "Claim",
    "reports.claim_type.INFO"  : "Info",

    "reports.registration.transfer_duration.PERMANENT" : "Permanent",
    "reports.registration.transfer_duration.SHARE"     : "Share",

    "reports.registration.transfer_type.ASSOCIATION"   : "Association",
    "reports.registration.transfer_type.BRANCH"        : "Branch",
    "reports.registration.transfer_type.INTERNATIONAL" : "International",
    "reports.registration.transfer_type.USA"           : "USA",

    "reports.qualification.expiration_status.EXPIRED_ONLY" : "Expired Only",
    "reports.qualification.expiration_status.VALID_ONLY"   : "Valid Only",
    "reports.qualification.expiration_status.ALL"          : "All",

    "reports.waivers.status.SIGNED"     : "Signed",
    "reports.waivers.status.NOT_SIGNED" : "Not Signed",
    "reports.waivers.status.ALL"        : "All Waivers",

    "reports.filter.status.REGISTERED" : "Registered",
    "reports.filter.status.ATTENDED"   : "Attended",
    "reports.filter.status.PASSED"     : "Passed",
    "reports.filter.status.CERTIFIED"  : "Certified",
    "reports.filter.status.COMPLETED"  : "Completed",
    "reports.filter.status.TRAINED"    : "Trained",

    "reports.discrimination.reason.RACE"            : "Race",
    "reports.discrimination.reason.GENDER_IDENTITY" : "Gender Identity",
    "reports.discrimination.reason.MARITAL_STATUS"  : "Marital Status",
    "reports.discrimination.reason.RELIGION"        : "Religion",
    "reports.discrimination.reason.AGE"             : "Age",
    "reports.discrimination.reason.GENETIC"         : "Genetic",
    "reports.discrimination.reason.DISABILITY"      : "Disability",

    "reports.discrimination.type.ON_ICE" : "On Ice",
    "reports.discrimination.type.OTHER"  : "Other",

    "reports.discrimination.closed_reasons.WRITTEN_WARNING"          : "Written Warning",
    "reports.discrimination.closed_reasons.EDUCATION"                : "Education",
    "reports.discrimination.closed_reasons.PROBATION"                : "Probation",
    "reports.discrimination.closed_reasons.ELIGIBILITY_RESTRICTIONS" : "Eligibility Restrictions",
    "reports.discrimination.closed_reasons.OTHER_SANCTIONS"          : "Other Sanctions",
    "reports.discrimination.closed_reasons.COMPLAINT_DISMISSED"      : "Complaint Dismissed",

    "reports.discrimination.member_type.PLAYER"    : "Player",
    "reports.discrimination.member_type.BENCH"     : "Bench Staff",
    "reports.discrimination.member_type.VOLUNTEER" : "Volunteer",
    "reports.discrimination.member_type.PARENT"    : "Parent",
    "reports.discrimination.member_type.OFFICIAL"  : "Official",
    "reports.discrimination.member_type.EMPLOYEE"  : "Employee",
    "reports.discrimination.member_type.OTHER"     : "Other",

    "reports.discrimination.belongs.HOME" : "Home",
    "reports.discrimination.belongs.AWAY" : "AWAY",
    "reports.filter.period.allSelected"   : "All Selected",

    "reports.filter.with_registration.YES" : "Yes",
    "reports.filter.with_registration.NO"  : "No",
    "reports.filter.with_registration.ALL" : "All",

    "reports.refunds.status.PENDING"   : "Pending",
    "reports.refunds.status.COMPLETED" : "Completed",

    "reports.column.duration.ALL"        : "All",
    "reports.column.duration.DEFINITE"   : "Definite",
    "reports.column.duration.INDEFINITE" : "Indefinite",

    "reports.spordleTable.pagination.message" : "Rows {itemStartPos} to {itemEndPos} out of a total of {totalItems} rows.",

    "reports.customize.title"                  : "Report Customization",
    "reports.customize.fitResults"             : "Fit results to page",
    "reports.customize.allResults"             : "Show all results",
    "reports.customize.displayStyle"           : "Display style",
    "reports.customize.pagination"             : "Results per page",
    "reports.customize.columns"                : "Define Columns",
    "reports.customize.availableColumns"       : "Available Columns",
    "reports.customize.selectedColumns"        : "Selected Columns",
    "reports.customize.tooltip.fullscreen"     : "Fullscreen",
    "reports.customize.tooltip.quitFullscreen" : "Quit Fullscreen",
    "reports.customize.tooltip.refresh"        : "Refresh",
    "reports.customize.tooltip.pagination"     : "Number of items per page",

    "reports.exports.expires"           : "Exp.",
    "reports.exports.created"           : "Created",
    "reports.exports.pending_download"  : "Pending download link",
    "reports.exports.expired_download"  : "Expired",
    "reports.exports.download"          : "Download my report",
    "reports.exports.generating"        : "Report generating...",
    "reports.exports.generationSuccess" : "Report generated!",
    "reports.exports.generatingQueue"   : "You have {numberOfReports, plural, =0 {# reports} one {# report} other {# reports}} being generated.",

    "reports.exports.errors.3370"         : "Report is expired.",
    "reports.exports.errors.3385"         : "The request report status is invalid.",
    "reports.exports.errors.3386"         : "The link has not been generated yet for this request report.",
    "reports.exports.errors.3045"         : "Incompatible data.",
    "reports.exports.errors.3015"         : "User not found.",
    "reports.exports.errors.declined"     : "A report has failed",
    "reports.exports.errors.declinedText" : "One or more of your recently generated reports have failed. Please try again.",

    "reports.exports.noExports" : "You have no exported reports.",

    "reports.limit.message1" : "Your report is too heavy, ",
    "reports.limit.message2" : "click here",
    "reports.limit.message3" : "to download the complete report.",

    "reports.exports.messages.noData" : "This report is empty.",
}