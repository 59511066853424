import { groupByAsArray } from "@spordle/helpers";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import PaymentInstallment from "./PaymentInstallment";
import PaymentOtherItem from "./PaymentOtherItem";

const PaymentOverview = ({ invoiceItem }) => {
    const nextPayment = invoiceItem.installments.find((ins) => ins.status === "PENDING") || {};
    const installments = invoiceItem.installments.filter((ins) => ins.payment_type === "INSTALLMENT");
    const initialPayments = invoiceItem.installments.filter((ins) => ins.payment_type === "INITIAL_PAYMENT");
    const otherItems = groupByAsArray(invoiceItem.otherItems.map((oItem) => ({ ...oItem, otherFeeId: oItem.other_fee.other_fee_id })), 'otherFeeId');

    return (
        <>
            <Card className="card-shadow mb-3">
                <CardBody className="p-3">
                    <div className="h4 font-medium mb-3">
                        <Translate id='account.paymentMethods.label.transactionInfo' />
                    </div>
                    <Row form>
                        <Col className="form-group" sm="3">
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.invoiceNumber' />
                            </div>
                            <div className="font-medium">{invoiceItem.invoice_number}</div>
                        </Col>
                        <Col className="form-group" sm="3">
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.paymentMode' />
                            </div>
                            <div className="font-medium">{invoiceItem.provider === 'PAYSAFE' ? 'Paysafe' : 'SportsPay'}</div>
                        </Col>
                        <Col className="form-group" sm="3">
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.nextPayment.title' />
                            </div>
                            <div className="font-medium">
                                <CurrencyFormat value={nextPayment.amount / 100} currencyDisplay='symbol' />
                            </div>
                        </Col>
                        <Col className="form-group" sm="3">
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.total' />
                                {/* <Translate id='account.paymentMethods.label.amountDue' /> */}
                            </div>
                            <div className="font-medium">
                                {/* <CurrencyFormat value={invoiceItem.amount / 100} currencyDisplay='symbol'/> */}
                                <CurrencyFormat value={invoiceItem.totalAmount / 100} currencyDisplay='symbol' />
                            </div>
                        </Col>
                        {otherItems.length <= 0 &&
                            <Col sm="12">
                                <div className="text-muted">
                                    <Translate id='account.paymentMethods.label.description' />
                                </div>
                                <div className="font-medium">
                                    <invoiceItem.InvoiceItem />
                                </div>
                            </Col>
                        }
                        {otherItems.length > 0 &&
                            <Col sm="12">
                                <div className="text-muted">
                                    <Translate id='account.paymentMethods.otherItems' values={{ amount: invoiceItem.otherItems.length }} />
                                </div>
                                <Table className='font-medium mb-0 fixed' size='sm'>
                                    <tbody>
                                        {otherItems.map((otherItemGroup, index) => (
                                            <PaymentOtherItem
                                                key={otherItemGroup.key}
                                                values={otherItemGroup.values}
                                                isFirst={index === 0}
                                            />
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        }
                    </Row>
                </CardBody>
            </Card>
            <Card className="card-shadow mb-3">
                <CardBody>
                    <Row>
                        <Col sm="12">
                            <div className="h4 font-medium mb-2">
                                <Translate id='account.paymentMethods.label.payment' values={{ amount: installments.length + initialPayments.length }} />
                            </div>
                            <div className="text-muted">
                                <Translate id='account.paymentMethods.label.initPayment' values={{ amount: initialPayments.length }} />
                            </div>
                            <Table className="font-medium mb-2" size="sm">
                                <tbody>
                                    {initialPayments.length > 0 &&
                                        initialPayments.map(
                                            (installment, index) => (
                                                <PaymentInstallment
                                                    key={installment.invoice_payment_id}
                                                    amount={installment.amount}
                                                    installment={installment}
                                                    isFirst={index === 0}
                                                    isNextPayment={(nextPayment.invoice_payment_id === installment.invoice_payment_id)}
                                                />
                                            ),
                                        )
                                    }
                                </tbody>
                            </Table>
                            {installments.length > 0 &&
                                <>
                                    <div className="text-muted">
                                        <Translate id='account.paymentMethods.label.installments' values={{ amount: installments.length }} />
                                    </div>
                                    <Table className='font-medium mb-0' size='sm'>
                                        <tbody>
                                            {installments.map((installment, index) => (
                                                <PaymentInstallment
                                                    key={installment.invoice_payment_id}
                                                    amount={installment.amount}
                                                    installment={installment}
                                                    isFirst={index === 0}
                                                    isNextPayment={(nextPayment.invoice_payment_id === installment.invoice_payment_id)}
                                                />
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            }
                        </Col>
                    </Row>
                    <div className='d-flex align-items-center mt-2'>
                        <i className='text-primary mdi mdi-information-outline mr-1' />
                        <div className='small mb-0'>
                            <Translate id='account.paymentMethods.label.upcomingPaymentHint' />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default PaymentOverview;