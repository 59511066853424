import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Col,
    FormGroup, Row
} from "reactstrap";
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { I18nContext } from '../../../../../contexts/I18nContext';
import withContexts from '../../../../../helpers/withContexts';
import SidePanel from '../../../../../components/sidePanel/SidePanel';
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";


class MemberSuspensionsSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
        }
    }


    /**
     * @description Checks if the code of the suspension corresponds to the discrimination suspension
     * @param {object} suspension Id of the suspension
     * @returns {boolean}
     */
    isDiscriminatory = (suspension) => suspension?.code === "GRM21: 11.4";

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='mb-2'>
                        <SidePanel.ToggleButton />
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                        <div>
                            <SidePanel.Title><Translate id='members.profile.suspensions.sidepanel.title' /></SidePanel.Title>
                            <div className="font-medium text-dark">
                                <DateFormat value={this.props.selectedRows[0].received_date} utc />
                                <span className='mx-1'>-</span>
                                {this.props.selectedRows[0].duration === 'DEFINITE' ?
                                    this.props.selectedRows[0].game_suspended ?
                                        <>
                                            <Translate id='members.profile.suspensions.duration.games' values={{ games: this.props.selectedRows[0].game_suspended }} />
                                            { this.props.selectedRows[0].end_date &&
                                                <span className='small ml-1'>(<DateFormat value={this.props.selectedRows[0].end_date} utc />)</span>
                                            }
                                        </>
                                        :
                                        <DateFormat value={this.props.selectedRows[0].end_date} utc />
                                    :
                                    <Translate id='members.profile.suspensions.duration.indefinite' />
                                }
                            </div>
                        </div>
                        <div className="mb-3 ml-3">
                            {this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).render}
                        </div>
                    </div>
                    <div className="font-medium text-dark">
                        <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].organisation.name} i18n={this.props.selectedRows[0].organisation.i18n} />
                    </div>
                    {this.props.selectedRows[0].reference_number ?
                        <InlineCopy toCopy={this.props.selectedRows[0].reference_number}>
                            {this.props.selectedRows[0].reference_number}
                        </InlineCopy>
                        :
                        '-'
                    }
                </SidePanel.Header>
                <div className="p-3">
                    <Row>
                        <Col sm="12">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.infraction' /></div>
                                <div className="font-medium text-dark">
                                    {this.props.selectedRows[0].other_infraction ?
                                        this.props.selectedRows[0].other_infraction
                                        :
                                        <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].suspension.name} i18n={this.props.selectedRows[0].suspension.i18n} />
                                    }
                                </div>

                            </div>
                        </Col>
                        {this.isDiscriminatory(this.props.selectedRows[0].suspension) &&
                            <Col sm="12">
                                <FormGroup>
                                    <div className="text-muted">
                                        <Translate id='discrimination.global.discriminatoryGrounds' />
                                    </div>
                                    <Translate
                                        values={{ count: this.props.selectedRows[0].discrimination_reason.split(',').length }}
                                        id='discrimination.addModal.form.select.groundsSelected'
                                    />
                                    {this.props.selectedRows[0].discrimination_reason &&
                                        <div className="bg-light mt-2 p-2 border rounded">
                                            <div className="font-medium mb-1 text-dark">
                                                <Translate id='discrimination.sidePanel.list.groundsSelected' />
                                            </div>
                                            <ul className="mb-0 pl-3">
                                                {this.props.selectedRows[0].discrimination_reason.split(',').map((reason) => (
                                                    <li key={'list' + reason}><Translate id={'discrimination.global.discriminatoryGrounds.' + reason} /></li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </FormGroup>
                            </Col>
                        }
                        <Col sm="12">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.team' /></div>
                                <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].team.name} i18n={this.props.selectedRows[0].team.i18n} /></div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.opposingTeamName' /></div>
                                <div className="font-medium text-dark">{this.props.selectedRows[0].opposing_team_name || '-'}</div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.penaltyType' /></div>
                                <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].suspension_type.name} i18n={this.props.selectedRows[0].suspension_type.i18n} /></div>

                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.position' /></div>
                                <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].position.name} i18n={this.props.selectedRows[0].position.i18n} /></div>
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.suspensionType' /></div>
                                <div>
                                    <div className="font-medium text-dark"><Translate id={`members.profile.suspensions.duration.${this.props.selectedRows[0].duration.toLowerCase()}`} /></div>
                                    {this.props.selectedRows[0].duration === 'DEFINITE' ?
                                        this.props.selectedRows[0].game_suspended ?
                                            <>
                                                <Translate id='members.profile.suspensions.duration.games' values={{ games: this.props.selectedRows[0].game_suspended }} />
                                                { this.props.selectedRows[0].end_date &&
                                                    <span className='small ml-1'>(<DateFormat value={this.props.selectedRows[0].end_date} utc />)</span>
                                                }
                                            </>
                                            :
                                            <DateFormat value={this.props.selectedRows[0].end_date} utc />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.incidentDate' /></div>
                                <div className="font-medium text-dark"><DateFormat value={this.props.selectedRows[0].incident_date} utc /></div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.location' /></div>
                                <div className="font-medium text-dark">{this.props.selectedRows[0].location}</div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.submitted_by' /></div>
                                <div className="font-medium text-dark">{`${this.props.selectedRows[0].submitted_by.name} ${this.props.selectedRows[0].submitted_by.family_name}`}</div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.submitted_email' /></div>
                                <a href={`mailto:${this.props.selectedRows[0].submitted_email}`}>{this.props.selectedRows[0].submitted_email}</a>
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='members.profile.suspensions.notes' /></div>
                                <div className="font-medium text-dark">{this.props.selectedRows[0].comment || '-'}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </OverlayLoader>

        )
    }
}

export default withContexts(MembersContext, OrganizationContext, I18nContext)(MemberSuspensionsSidepanel);