import { useContext, useEffect, useRef } from 'react';
import { stringifyUrl, parse } from 'query-string';
import { ThemeContext } from '../contexts/ThemeContext';
import { AuthContext, AccountsContext } from '../contexts/contexts';
import getReferrer, { getMultisportApiUrl, getMultisportCode, isMultiSport, MULTISPORT_SESSION_STORAGE_KEY } from '../helpers/getReferer';
import jwtDecode from 'jwt-decode';
import { I18nContext } from '../contexts/I18nContext';
import { spordleOAuthKey } from '../app';
import { LOGINTYPE } from '../helpers/constants';
import { clearLocalStorage, setLocalStorageItem, setSessionStorageItem } from '../helpers/browserStorage';
import { addBreadcrumb, captureException, setTag, Severity, withScope } from '@sentry/react';
import API_SPORDLE from '../api/API-Spordle';
import API from '../api/API';

const AuthController = (props) => {
    const themeContext = useContext(ThemeContext);
    const auth = useContext(AuthContext);
    const i18n = useContext(I18nContext);
    const accountsContext = useContext(AccountsContext);
    const demoInterceptor = useRef();
    const demo2Interceptor = useRef();

    useEffect(() => {
        themeContext.setTheme(props.match.params.platform);
        const urlParams = parse(props.location.search);
        const defaultRedirectTo = '/login';
        var redirectTo = defaultRedirectTo;
        var buildedParams = {};

        if(process.env.REACT_APP_VERSION_CLIENT !== 'EIHA' && isMultiSport()){
            if(getMultisportCode())
                setSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY, getMultisportCode());
            demoInterceptor.current = API_SPORDLE.interceptors.request.use((config) => {
                config.baseURL = getMultisportApiUrl();
                return config
            });
            demo2Interceptor.current = API.interceptors.request.use((config) => {
                config.baseURL = getMultisportApiUrl();
                return config
            });
        }

        setTag('login-type', props.match.params.platform);

        if(urlParams.lang){
            i18n.setLocale(urlParams.lang);
            delete urlParams.lang;
        }

        const redirectCount = parseInt(urlParams.redirectCount) || 0;
        delete urlParams.redirectCount;

        // Building the url
        for(const key in urlParams){
            const param = urlParams[key];

            switch (key){
                case 'goto':// Used for internal connextion
                    redirectTo = `/${param}`;
                    break;
                default:
                    buildedParams[key] = param;
                    break;
            }
        }

        const goToAccount = () => {
            setLocalStorageItem('platformLogin', props.match.params.platform);
            auth.getUserData()
                .then(() => {
                    try{
                        new URL(urlParams.referrer);// Is external link
                        window.location.replace(urlParams.referrer);// Redirect to account referrer (if refreshed page and has connexion)
                    }catch(_){
                        // Is not external link ex: /purchases/123123
                        props.history.replace(urlParams.referrer || props.location.state?.from || '/dashboard');// Redirect to account referrer (if refreshed page and has connexion)
                    }
                })
                .catch(() => {
                    props.history.replace(stringifyUrl({
                        url: '/login',
                        query: buildedParams,
                    }));
                });
        }

        const goToNextPage = () => {
            setLocalStorageItem('platformLogin', props.match.params.platform);
            const url = stringifyUrl({
                url: redirectTo,
                query: buildedParams,
            }, {
                skipEmptyString: true,
                skipNull: true,
            });
            props.history.replace(url);
        }

        auth.checkForConnexion()
            .then((result) => {
                // Shallow copy to prevent exposing login tokens in access url
                if(redirectCount >= 2){ // Is about to be the third redirection
                    // Sentry logging
                    setTag('max-try', true);
                    addBreadcrumb({
                        category: 'auth',
                        type: 'debug',
                        message: 'Maximum login count reached',
                        level: Severity.Debug,
                    });
                    withScope((scope) => {
                        scope.setLevel(Severity.Debug);
                        captureException(new Error('Maximum login count reached'));
                    })

                    clearLocalStorage();
                    accountsContext.resetAccount();
                    auth.signOut(result.AuthenticationResult.AccessToken).catch(console.error).finally(goToAccount);
                    return;
                }

                var redirectQuery = { ...buildedParams };
                redirectQuery.accessToken = result.AuthenticationResult.AccessToken;
                redirectQuery.type = result.AuthenticationResult.TokenType;
                delete redirectQuery.referrer; // Not necessary to send to new url

                if(spordleOAuthKey){ // When iframe connexion
                    auth.getUserData(result.AuthenticationResult.AccessToken)
                        .then((user) => {
                            window.parent.postMessage({
                                'func': 'onSpordleLogin',
                                'loginData': result.AuthenticationResult,
                                'userData': {
                                    attributes: user.UserAttributes,
                                    userName: user.Username,
                                },
                            }, '*');
                        })
                        .catch((error) => {
                            window.parent.postMessage({
                                'func': 'onLoginFailed',
                                'error': error.message,
                            }, '*');
                        })
                    return;
                }

                switch (props.match.params.platform){
                    case LOGINTYPE.PLAY:// Stay in platform if SPORDLE connexion
                    case LOGINTYPE.CLINIC:// Stay in platform if SPORDLE connexion
                    case LOGINTYPE.SPORDLE:// Stay in platform if SPORDLE connexion
                    default:
                        if(urlParams.referrer){
                            try{
                                new URL(urlParams.referrer);// Is external link
                                // Don't go to default account -> go to referrer
                                window.location.replace(stringifyUrl({
                                    url: urlParams.referrer,
                                    query: redirectQuery,
                                }));
                                return;
                            }catch(_){
                                // Is not external link ex: /purchases/123123
                            }
                        }

                        auth.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, goToAccount);
                        break;
                    case LOGINTYPE.HCR:
                        // When HCR -> check for automatic login as primary IdentityRole
                        // Getting the username to we can get the user's identityRoles
                        auth.getUserIdentityRole(jwtDecode(result.AuthenticationResult.AccessToken).username)
                            .then((identityRoles) => {
                                // Follow up with redirect to product using accounts as login
                                if(identityRoles.length === 0){
                                    throw new Error('Cannot preselect -> go to account')
                                }else if(identityRoles.length === 1){
                                    redirectQuery.identityRoleId = identityRoles[0].identity_role_id;
                                    redirectQuery.organizationId = identityRoles[0].organisation.organisation_id;
                                }else{ // Look for primary identity-role
                                    const primaryIdentityRole = identityRoles.find((ir) => ir.is_primary === '1' && ir.active === '1');
                                    if(primaryIdentityRole){
                                        redirectQuery.identityRoleId = primaryIdentityRole.identity_role_id;
                                        redirectQuery.organizationId = primaryIdentityRole.organisation.organisation_id;
                                    }
                                }
                                redirectQuery.usePrevious = 1;// SID will prioritize ids in the storage -> ids in the url become default values
                                redirectQuery.redirectCount = redirectCount + 1;// SID will prioritize ids in the storage -> ids in the url become default values
                                const url = stringifyUrl({
                                    url: urlParams.referrer ?? getReferrer(props.match.params.platform),
                                    query: redirectQuery,
                                });
                                if(process.env.NODE_ENV === 'development'){
                                    // localhost only
                                    // When developping, send to another tab for testing.
                                    // Comment the next line if you don't want to open a new tab everytime this is executed
                                    window.open(url, undefined, 'noopener,noreferrer');
                                    goToNextPage();
                                }else{
                                    window.location.replace(url);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                switch (error.message){
                                    case '3088':// Identity not found
                                        if(process.env.NODE_ENV === 'development'){ // localhost only
                                            window.sessionStorage.removeItem('accessToken');
                                        }
                                        goToNextPage();
                                        break;
                                    default:
                                        auth.setUpInternalConnection({ accessToken: result.AuthenticationResult.AccessToken, type: result.AuthenticationResult.TokenType }, goToAccount);
                                        break;
                                }
                            })
                        break;
                }
            }, (error) => {
                if(props.match.params.platform === LOGINTYPE.SPORDLE){ // If we are within the spordle accounts and lost connexion, send to Spordle Account Login
                    if(spordleOAuthKey){ // When iframe connexion
                        window.parent.postMessage({
                            'func': 'onSpordleLoginFailed',
                            'error': error.message,
                        }, '*');
                    }else{
                        setLocalStorageItem('platformLogin', props.match.params.platform);
                        props.history.replace(stringifyUrl({
                            url: '/login',
                            query: buildedParams,
                        }));
                    }
                }else{
                    if(redirectTo !== defaultRedirectTo){ // Only redirect with referrer if we aren't trying to login to Spordle Account
                        redirectTo = defaultRedirectTo;
                        buildedParams.referrer = stringifyUrl({
                            url: window.location.origin + '/auth/' + props.match.params.platform,
                            query: {
                                referrer: buildedParams.referrer,
                                goto: urlParams.goto,
                            },
                        });
                    }
                    goToNextPage();
                }
            });

        return () => {
            API_SPORDLE.interceptors.request.eject(demoInterceptor.current);
            API.interceptors.request.eject(demo2Interceptor.current);
        }
    }, []);

    return <div />;
};

export default AuthController;