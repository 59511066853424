import Translate, { DateFormat } from '@spordle/intl-elements'
import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button, Col } from 'reactstrap'
import useSWR from 'swr'
import { AccountsContext, AuthContext } from '../../../../../contexts/contexts'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'
import ManagePaymentModal from './managePaymentModal/ManagePaymentModal'

const PaymentPaysafeCard = ({ invoiceItem, canChangeCard }) => {
    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    const [ changeIsOpen, setChangeIsOpen ] = useState(false);

    const { data, isValidating, error, mutate } = useSWR(
        [ 'getPaysafeCard', invoiceItem.invoice_payment_id ],
        () => accountsContext.getPaymentCard(invoiceItem.invoice_payment_id),
    );

    const { data: paysafeCards, isValidating: paysafeCardsLoading } = useSWR(
        [ 'vaultCards', auth.userData.Username ],
        () => accountsContext.getVaultCards(auth.userData.Username),
        { // Settings that will make this call use the one from VaultCards.js
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        },
    );

    const card = paysafeCards?.find((card) => card.paymentToken === data?.payment_token);

    const getCardBrand = () => {
        switch (card?.cardType){
            case 'VI':
                return 'Visa';
            case 'MC':
                return 'Mastercard';
            default:
                return;
        }
    }

    const isLoading = paysafeCardsLoading || isValidating;

    return (
        <Col sm="9">
            <div className="text-muted">
                {isLoading ?
                    <Skeleton className='w-25' />
                    :
                    getCardBrand()
                }
                {canChangeCard &&
                    <Button
                        disabled={isLoading}
                        id={`invoice-changeCard-${invoiceItem.invoice_payment_id}`}
                        type="button"
                        onClick={() => setChangeIsOpen(true)}
                        className="ml-1 py-0"
                        color="link"
                        size="sm"
                    >
                        <ManagePaymentModal
                            isOpen={changeIsOpen} toggle={() => setChangeIsOpen(false)}
                            invoiceItem={invoiceItem}
                            cardToken={card?.paymentToken}
                            updatePaymentCard={mutate}
                        />
                        <Translate id='account.paymentMethods.btn.changeCard' /><i className="mdi mdi-pencil ml-1" />
                    </Button>
                }
            </div>
            <div className="font-medium">
                {isLoading ?
                    <Skeleton />
                    : error ?
                        <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />
                        : !card ?
                            <Translate id='account.paymentMethods.label.creditCard.failed' />
                            :
                            <>
                                **** **** **** {card.lastDigits || '****'}
                                <span className='mx-1'>-</span>
                                {card.holderName}
                                <span className='mx-1'>-</span>
                                <DateFormat format='MM/YY' value={[ card.cardExpiry.year, card.cardExpiry.month - 1 ]} />
                            </>
                }
            </div>
        </Col>
    )
}

export default PaymentPaysafeCard
