import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { DROPZONE_PROPS } from "../accountMemberDocumentsHelper";

const MemberAddDocumentButton = ({ setModalInfo }) => {
    return (
        <Dropzone
            {...DROPZONE_PROPS}
            onDropAccepted={(file) => {
                setModalInfo((prev) => ({
                    ...prev,
                    isOpen: true,
                    initValues: {
                        attachment: file[0],
                    },
                }))
            }}
        >
            {(dropzone) => (
                <div {...dropzone.getRootProps()} className="h-100">
                    <UserDisplay
                        onClick={() => setModalInfo((prev) => ({ ...prev, isOpen: true }))}
                        className={stringBuilder("mb-0 h-100 text-center", dropzone.isDragActive ? "bg-primary-light" : "bg-transparent", { "border-primary": dropzone.isDragActive })}
                        style={{ minWidth: "100%", minHeight: 270, borderStyle: "dashed", borderWidth: 2 }}
                        card
                        block
                        hover
                    >
                        <UserDisplay.Container className="w-100">
                            <i className={stringBuilder("mdi fa-4x line-height-1", dropzone.isDragActive ? "text-primary mdi-library-plus" : "mdi-plus-box")} />
                            <div className={stringBuilder("font-medium h5 font-bold", dropzone.isDragActive ? "text-primary" : "text-body")}>
                                <Translate id='account.members.documents.document.add.btn' />
                            </div>
                            {!dropzone.isDragActive &&
                                <>
                                    <p className="small">
                                        <Translate id='account.members.documents.document.add.btn.helper' />
                                    </p>
                                    <Button size="sm" color="light" type="button"><Translate id="misc.add" /></Button>
                                </>
                            }
                        </UserDisplay.Container>
                    </UserDisplay>
                </div>
            )}
        </Dropzone>
    );
}

export default MemberAddDocumentButton;