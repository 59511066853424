import Translate from "@spordle/intl-elements";
import { Alert, Collapse } from "reactstrap";

const isUAT = process.env.REACT_APP_ENVIRONMENT === 'uat';

const UATWarning = () => {
    return (
        isUAT &&
            <Collapse unmountOnExit style={{ minHeight: "auto" }} className="page-content py-0" isOpen>
                <Alert color="danger" className="mb-0 mt-3 d-flex align-items-center">
                    <i className="mdi font-18 mdi-information mr-2" /> <Translate id='header.viewHeader.uat' values={{ b: (chunks) => <b className="ml-1">{chunks}</b> }} />
                </Alert>
            </Collapse>
    );
}

export default UATWarning;