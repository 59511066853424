export default{
    "account.api.webhooks.sidePanelAccountWebhooks.tabs.details" : "Details",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.title" : "Webhook Information",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.summary"        : "Summary",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status"         : "Status",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status.success" : "Success",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.status.fail"    : "Fail",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.type"           : "Type",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.id"             : "Id",
    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.date"           : "Date",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.request" : "Request {method} body",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.response" : "Response body",

    "account.api.webhooks.sidePanelAccountWebhooks.webhooks.copy" : "Copy",
}