import LangSwitcher from '../../../../components/LangSwitcher';

/**
* Temporary component used to uniform all of our auth pages's layout
* we should eventually refactor this into something like FullLayout.
*/
const SpordleLayout = ({
    children,
}) => {
    return (
        <div className='auth-wrapper'>
            <div className="p-3 position-absolute top-0 right-0">
                <LangSwitcher />
            </div>
            <div className="auth-container">
                {children}
            </div>
        </div>
    );
};

export default SpordleLayout;