import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { Col, Label, Row } from "reactstrap";
import Required from "../../../../../components/formik/Required";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { GAME_PERIODS } from "./gameIncidentHelper";
import CrossFade from "./CrossFade";

/**
 * @param {Object} props
 * @param {function} props.previous
 * @returns {JSX.Element}
 */
const IncidentForm = ({ fieldsPrefix = '', incident, suspensions, players, suspensionTypes }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { setFieldValue } = useFormikContext();

    return (
        <Row className="pt-2" form>
            <Col sm="12" lg="6" className="form-group">
                <Label for={fieldsPrefix + "member_id"} className="text-muted">
                    <Translate id='account.members.gir.addModal.label.player' /> <Required />
                </Label>
                <FormikSelect
                    id={fieldsPrefix + "member_id"} name={fieldsPrefix + "member_id"}
                    noOptionLayout={<Translate id='misc.noSelection' />}
                    options={formatSelectData(players.home.concat(players.away)?.map((tm) => ({
                        value: tm.member?.member_id,
                        label: tm.member?.first_name + ' ' + tm.member?.last_name,
                        member: tm,
                    })), {
                        newGroupIndexes: {
                            0: {
                                label: 'account.members.gir.addModal.teams.away.label',
                                groupId: 'away',
                                translateLabel: true,
                            },
                            1: {
                                label: 'account.members.gir.addModal.teams.home.label',
                                groupId: 'home',
                                translateLabel: true,
                            },
                        },
                        getGroupId: (option) => players.home.find((m) => m.member_id === option.member.member_id) ? 'home' : 'away',
                    })}
                    onOptionSelected={(val, selectRef) => {
                        setFieldValue(fieldsPrefix + 'jersey_number', selectRef.getSpordleTable().getData().find((d) => d.member?.member?.member_id === val[0])?.member?.jersey_number);
                        setFieldValue(fieldsPrefix + 'team_id', selectRef.getSpordleTable().getData().find((d) => d.member?.member?.member_id === val[0])?.member?.team_id);
                    }}
                />
            </Col>
            <Col sm="12" lg="6" className="form-group">
                <Label for={fieldsPrefix + "jersey_number"} className="text-muted">
                    <Translate id='account.members.gir.addModal.label.jersey' />
                </Label>
                <FormikInputText
                    autoFocus
                    autoComplete="new-password"
                    id={fieldsPrefix + "jersey_number"}
                    name={fieldsPrefix + "jersey_number"}
                    placeholder="#"
                    translatePlaceholder={false}
                />
            </Col>
            <Col xs="12" className="form-group">
                <Label className="text-muted"><Translate id='account.members.gir.addModal.label.infractionType' /> <Required /></Label>
                <FormikSelect
                    id={fieldsPrefix + "suspension_type_id"} name={fieldsPrefix + "suspension_type_id"}
                    search={false}
                    options={suspensionTypes?.map((suspension) => ({
                        label: suspension.name,
                        value: suspension.suspension_type_id,
                        i18n: suspension.i18n,
                    }))}
                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                />
            </Col>
            <Col xs="12" className="form-group">
                <Label className="text-muted" for={fieldsPrefix + "suspension_id"}><Translate id='account.members.gir.addModal.label.infraction' /> <Required /></Label>
                <FormikSelect
                    id={fieldsPrefix + "suspension_id"} name={fieldsPrefix + "suspension_id"}
                    searchKeys={[
                        `i18n.${getGenericLocale()}.name`,
                    ]}
                    options={suspensions?.map((suspension) => ({
                        label: suspension.name,
                        value: suspension.suspension_id,
                        i18n: suspension.i18n,
                    }))}
                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                />
            </Col>
            <Col xs="12" lg="6" className="form-group">
                <Label for={fieldsPrefix + "game_period"} className="text-muted"><Translate id='account.members.gir.addModal.label.period' /></Label>
                <FormikSelect
                    id={fieldsPrefix + "game_period"} name={fieldsPrefix + "game_period"}
                    clearable search={false}
                    options={GAME_PERIODS?.map((period) => ({
                        label: `account.members.gir.addModal.label.gamePeriod.${period}`,
                        value: period,
                    }))}
                    renderOption={(option) => <Translate id={option.option.label} />}
                />
            </Col>
            <Col xs="6" lg="3" className="form-group">
                <Label className="text-muted">
                    <Translate id='account.members.gir.addModal.label.minutes' />
                </Label>
                <FormikInputNumber
                    name={fieldsPrefix + "minutes"}
                    max={59}
                    format='##'
                    min={0}
                    translatePlaceholder={false}
                    placeholder="00"
                />
            </Col>
            <Col xs="6" lg="3" className="form-group">
                <Label className="text-muted">
                    <Translate id='account.members.gir.addModal.label.seconds' />
                </Label>
                <FormikInputNumber
                    name={fieldsPrefix + "seconds"}
                    format='##'
                    max={59}
                    min={0}
                    placeholder="00"
                    translatePlaceholder={false}
                />
            </Col>
            <Col sm="12" className="form-group align-self-center">
                <Label className="text-muted">
                    Description
                </Label>
                <FormikTextArea name={fieldsPrefix + "description"} trim />
            </Col>
            <Col sm="12" className="form-group align-self-center">
                <FormikCheckedButton className="text-muted" label='account.members.gir.addModal.label.injury' name={fieldsPrefix + "injured"} />
                <CrossFade isVisible={!!incident.injured}>
                    <FormikCheckedButton className="text-muted mt-2" label='account.members.gir.addModal.label.returned' name={fieldsPrefix + "returned_to_game"} />
                </CrossFade>
            </Col>
        </Row>
    )
}

export default IncidentForm;