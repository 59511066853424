export default{
    "helpCenter.submitTicket.title" : "Demande de soutien",
    "helpCenter.submitTicket.type"  : "Type",

    "helpCenter.submitTicket.search.title"               : "Liste des problématiques",
    "helpCenter.submitTicket.search.tab.title"           : "Mes demandes",
    "helpCenter.submitTicket.search.comments"            : "Commentaire(s)",
    "helpCenter.submitTicket.search.updated"             : "Mis à jour",
    "helpCenter.submitTicket.search.didNotFindBug"       : "vous n'avez pas trouvé votre problématique? ",
    "helpCenter.submitTicket.search.createTicket"        : "Soumettre une demande",
    "helpCenter.submitTicket.search.gotoSupportRequests" : "Voir toutes mes demandes",

    "helpCenter.submitTicket.create.subject"          : "Sujet",
    "helpCenter.submitTicket.create.subject.required" : "Sujet est requis.",
    "helpCenter.submitTicket.create.priority"         : "Priorité",

    "helpCenter.submitTicket.create.priority.1" : "Élevé (Service interrompu ou fonctionnalité indisponible)",
    "helpCenter.submitTicket.create.priority.3" : "Medium (Dégradation du service ou fonctionnalité indisponible)",
    "helpCenter.submitTicket.create.priority.5" : "Bas (Problèmes techniques, de qualité ou d'orthographe)",

    "helpCenter.submitTicket.create.send" : "Envoyer",

    "helpCenter.submitTicket.success.title"  : "Merci !",
    "helpCenter.submitTicket.success.ticket" : "Étiquette",
    "helpCenter.submitTicket.success.text"   : "Votre demande a bel et bien été envoyée ! Notre équipe la regardera aussitôt que possible.",
    "helpCenter.submitTicket.success.btn"    : "Soumettre une autre demande",
}