import { Redirect } from 'react-router-dom';
import SpordleLogin from '../../views/spordle/login';
import HCRLogin from '../../views/hcr/login';
import TourLogin from '../../views/tournaments/login';
import { LOGINTYPE } from '../../helpers/constants';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import { isMultiSport } from '../../helpers/getReferer';

const LoginPicker = (props) => {
    switch (getLocalStorageItem('platformLogin') || LOGINTYPE.SPORDLE){
        case LOGINTYPE.HCR:
        case LOGINTYPE.PLAY:
        case LOGINTYPE.CLINIC:
            if(!isMultiSport())
                return <HCRLogin {...props} />;
        case LOGINTYPE.SPORDLE:
            return <SpordleLogin {...props} />;
        case 'TOUR':
            return <TourLogin {...props} />;

        default:
            return <Redirect to='/404' />
    }
}

export default LoginPicker;