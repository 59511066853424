import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, Card } from "reactstrap";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { SupervisionsContext } from "../../../../../contexts/SupervisionsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import AccountMemberAddCoachReport from "./addModal/AccountMemberAddCoachReport";
import AccountMemberCoachSidePanel from "../sidePanel/AccountMemberCoachSidePanel";
import DisplayCategory from "../../../../../components/DisplayCategory";
import { SupervisionSpordleTableMobileView } from "../memberCoachHelper";

const AccountMemberSupervisionsOfficiatingCoach = ({ member, reports }) => {
    const { getSupervisionsList } = useContext(SupervisionsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    return (
        <>
            <Card body className='card-shadow'>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0"><Translate id='account.members.coach.mySupervisions' /></div>
                </div>
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='supervision_id'
                    sidePanel={(sProps) => <AccountMemberCoachSidePanel {...sProps} />}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            tableHover
                            bordered
                            striped
                            clickable
                            id='memberOfficiatingCoach'
                            key={member.member_id}
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            pagination={10}
                            desktopWhen="md"
                            dataIndex='supervision_id'
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return getSupervisionsList({ member_id: member?.member_id })
                                            .then((supervisions) => {
                                                return supervisions.filter((superv) => superv.officiating_coach?.member_id === member.member_id)
                                            });
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={[
                                'supervision_date',
                                `official.first_name`,
                                `official.last_name`,
                                'home_team.short_name',
                                'away_team.short_name',
                                `period.${getGenericLocale()}.name`,
                                `team_category.division.i18n.${getGenericLocale()}.short_name`,
                                `team_category.class.i18n.${getGenericLocale()}.short_name`,
                                'home_team_name',
                                'away_team_name',
                            ]}
                            columns={[
                                {
                                    label: <Translate id='account.members.coach.season.label' />,
                                    key: 'period',
                                    sortKey: `period.${getGenericLocale()}.name`,
                                    fallbackSortKey: 'period.name',
                                    sortable: true,
                                },
                                {
                                    label: 'Date',
                                    key: 'supervision_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.official.label' />,
                                    key: 'official',
                                    sortKey: 'official.first_name',
                                    fallbackSortKey: 'official.last_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.teamCategory.label' />,
                                    key: 'category',
                                    sortKey: `team_category.division.i18n.${getGenericLocale()}.short_name`,
                                    fallbackSortKey: 'team_category.division.short_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.homeTeam.label' />,
                                    key: 'home_team',
                                    sortKey: 'home_team.short_name',
                                    fallbackSortKey: 'home_team_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='account.members.coach.awayTeam.label' />,
                                    key: 'away_team',
                                    sortKey: 'away_team.short_name',
                                    fallbackSortKey: 'away_team_name',
                                    sortable: true,
                                },
                            ]}
                            renderRow={(columnKey, supervision) => {
                                switch (columnKey){
                                    case 'period':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                i18n={supervision.period.i18n}
                                                defaultValue={supervision.period.name}
                                            />
                                        )
                                    case 'official':
                                        return (
                                            <>
                                                <div>{`${supervision.official?.first_name} ${supervision.official?.last_name}`}</div>
                                                <div className="text-muted small">#{supervision.official?.unique_identifier}</div>
                                                {supervision.official.member_qualification &&
                                                    <div className="text-muted small">
                                                        <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification.name} i18n={supervision.official.member_qualification.qualification.i18n} />{(supervision.official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification_level.name} i18n={supervision.official.member_qualification.qualification_level.i18n} /></>}
                                                    </div>
                                                }
                                            </>
                                        )
                                    case 'supervision_date':
                                        return <div className="text-nowrap">{supervision.supervision_date}</div>
                                    case 'category':
                                        return <DisplayCategory category={supervision.team_category} short />
                                    case 'away_team':
                                        return supervision.away_team?.short_name || supervision.away_team_name || "-";
                                    case 'home_team':
                                        return supervision.home_team?.short_name || supervision.home_team_name || "-";
                                    default:
                                        break;
                                }
                            }}
                            emptyLayout={({ emptySearch }) => (
                                <div className="text-center py-2">
                                    <i className="mdi mdi-book-open-page-variant fa-5x" />
                                    <div className="font-bold h5 text-body"><Translate id='account.members.coach.allMemberReports.noReports.title' /></div>
                                    <p className="text-muted">
                                        <Translate id={`account.members.coach.allMemberReports.noReports.officiating.${emptySearch ? 'search.' : ''}subtitle`} />
                                    </p>
                                </div>
                            )}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                        >
                            {(spordleTable) => (
                                <>
                                    <AccountMemberAddCoachReport member={member} types={reports.supervision_types} isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} />
                                    <div className='mb-2'>
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            <div className="flex-grow-1 flex-sm-grow-0 order-1 order-sm-0 mr-2">
                                                <SpordleTableProvider.SearchInput />
                                            </div>
                                            <SpordleTableProvider.Refresh className="ml-2 order-2 order-0" />
                                            <Button
                                                onClick={() => setModalIsOpen(true)}
                                                className="ml-sm-auto w-100 w-sm-auto mb-2 mb-sm-0 order-0"
                                                color="primary"
                                            >
                                                <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                                            </Button>
                                        </div>
                                    </div>
                                    {spordleTable.isMobile() ?
                                        <SupervisionSpordleTableMobileView fromView="officiatingCoach" spordleTable={spordleTable} panelProps={panelProps} />
                                        :
                                        <SpordleTableView />
                                    }
                                </>
                            )}
                        </SpordleTableProvider>
                    )}
                />
            </Card>
        </>
    );
}

export default AccountMemberSupervisionsOfficiatingCoach;