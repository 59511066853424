/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import {forwardRef}from'react';import PropTypes from'prop-types';import {Label,FormText}from'reactstrap';import SpordleSelect from'@spordle/spordle-select';import {useIntl}from'react-intl';import {useField}from'formik';import {stringBuilder}from'@spordle/helpers';import FormikError from'./FormikError.js';/**
 * @member FormikSelect
 * @description
 * FormikSelect will create a SpordleSelect that will work automagically with Fromik(yes it's a typo :)) validations
 * @example
 * <FormikSelect name="method_Type" id="method_Type" label="Method type" />
 *
 * @prop {string} name Used to make the FormikSelect work with validation
 * @prop {string|JSX.Element} [helper] The lang id to be translated OR JSX
 * @prop {string|JSX.Element} [label] The lang id to be translated OR JSX
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {JSX.Element} Returns a {@link https://github.com/Spordle/Spordle-Select SpordleSelect} that will work with Formik
 *
 * @see https://github.com/Spordle/Spordle-Select
 * @copyright Spordle
 */
const FormikSelect = forwardRef(({ label, helper, translateHelper, translateLabel, manualError, ...props }, ref) => {
    const [{ value, ...field }, meta, helpers] = useField(props);
    const intl = useIntl();
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx(SpordleSelect, { ...field, ...props, ref: ref, inputClassName: stringBuilder(props.inputClassName, { 'is-invalid': meta.touched && meta.error }), values: props.values ?? (Array.isArray(value) ? value : [value]).filter((v) => !!v), onOptionSelected: (options, spordleSelect) => {
                    if (!props.onOptionSelected?.(options, spordleSelect)) { // When true -> skip default behavior
                        if (props.multi) {
                            helpers.setValue(options);
                        }
                        else {
                            helpers.setValue(options[0] || '');
                        }
                    }
                    return true;
                } }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
});
FormikSelect.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikSelect.defaultProps = {
    translateHelper: true,
    translateLabel: true,
};
FormikSelect.displayName = 'FormikSelect';
const FormikSelect$1 = FormikSelect;export{FormikSelect$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/