import { groupByAsArray, stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import { Fragment, useContext, useState } from "react";
import { Alert, Badge, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useSWR from "swr";
import { object } from "yup";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { AccountsContext } from "../../../contexts/contexts";
import { I18nContext } from "../../../contexts/I18nContext";
import { displayField, getInitVal, getLabelHelper, getValidationSchema } from "../../../helpers/answerHelper";
import { displayI18n, DisplayI18n } from "../../../helpers/i18nHelper";
import CrossFade from "../../../views/account/members/gameIncidentReports/components/CrossFade";
import CollapsibleCard from "../../collapsibleCard/CollapsibleCard";
import CustomAnimatedIcon from "../../customAnimatedIcon/CustomAnimatedIcon";
import Required from "../../formik/Required";
import OverlayLoader from "../../loading/OverlayLoader";

const UnansweredFormsModal = ({ unansweredForms }) => {
    const [ currentStep, setStep ] = useState(0);

    const [ isOpen, setIsOpen ] = useState(true);
    const closeModal = () => setIsOpen(false);

    const grouppedUnansweredForms = groupByAsArray(unansweredForms, 'invoice_number');

    return (
        <AnalyticsModal analyticsName="UnansweredPopUpFormsModal" isOpen={isOpen}>
            <CrossFade isVisible={currentStep === 0}>
                <ModalHeader toggle={closeModal}>
                    <Translate id='unansweredFormsModal.modal.title' />
                </ModalHeader>
                <ModalBody className="text-center">
                    <i style={{ fontSize: 60 }} className='mdi mdi-alert-outline text-primary' />
                    <p className="font-bold h5">
                        <Translate id='unansweredFormsModal.modal.title' />
                    </p>
                    <p>
                        <Translate id='unansweredFormsModal.modal.subTitle' values={{ transactionCount: grouppedUnansweredForms.length }} />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="button" onClick={() => { setStep(currentStep + 1) }}><Translate id='unansweredFormsModal.modal.answer' /><i className="mdi mdi-arrow-right ml-1" /></Button>
                </ModalFooter>
            </CrossFade>
            {grouppedUnansweredForms.map((invoice, index) => (
                <CrossFade key={invoice.key} isVisible={currentStep === index + 1}>
                    <FormStep
                        invoiceNumber={invoice.key}
                        closeModal={closeModal}
                        nextStep={() => { setStep(currentStep + 1) }}
                        previousStep={() => { setStep(currentStep - 1) }}
                    />
                </CrossFade>
            ))}
            <CrossFade isVisible={currentStep === grouppedUnansweredForms.length + 1} mountOnEnter>
                <ModalHeader toggle={closeModal}>
                    <Translate id='unansweredFormsModal.modal.confirmation.title' />
                </ModalHeader>
                <ModalBody className="text-center">
                    <CustomAnimatedIcon icon='checkmark' withCircle className='text-success mb-2' size={50} strokeWidth='6' />
                    <div className='text-dark font-bold h4'>
                        <Translate id='unansweredFormsModal.modal.confirmation.subtitle' />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="button" onClick={closeModal}><Translate id='misc.close' /></Button>
                </ModalFooter>
            </CrossFade>
        </AnalyticsModal>
    )
}

const FormStep = ({ closeModal, nextStep, previousStep, invoiceNumber }) => {
    const { getInvoiceForms, updateForm } = useContext(AccountsContext);
    const i18nContext = useContext(I18nContext);

    const { data: customForms, isValidating } = useSWR(
        [ 'getInvoiceForms-FormStep', invoiceNumber ],
        () => getInvoiceForms(invoiceNumber).then((forms) => forms.filter((form) => form.active == '1')),
    );

    const getInitialValues = (groups) => {
        const temp = {};
        groups.forEach((group, indexGroup) => {
            group.fields.forEach((field, indexField) => {
                const fieldName = `group_${indexGroup}_fields_${indexField}`;
                temp[fieldName] = getInitVal(field, fieldName)[fieldName];
            })
        })
        return temp;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={customForms?.reduce((initialValues, form) => {
                initialValues[form.invoice_item_id] = getInitialValues(form.groups);
                return initialValues;
            }, {}) || {}}
            validationSchema={object().shape(customForms?.reduce((initialValues, form) => {
                const fieldValidations = {};
                for(let groupIndex = 0; groupIndex < form.groups.length; groupIndex++){
                    const group = form.groups[groupIndex];
                    for(let fieldIndex = 0; fieldIndex < group.fields.length; fieldIndex++){
                        const field = group.fields[fieldIndex];
                        if(!field.deleted_at){
                            const fieldName = `group_${groupIndex}_fields_${fieldIndex}`;
                            fieldValidations[fieldName] = getValidationSchema(field, fieldName, false)[fieldName];
                        }
                    }
                }
                initialValues[form.invoice_item_id] = object().shape(fieldValidations);
                return initialValues;
            }, {}) || {})}
            onSubmit={async(values, { setStatus }) => {
                return Promise.all(Object.keys(values).map((invoiceItemId) => {
                    const formikValues = values[invoiceItemId];
                    const customForm = customForms.find((form) => form.invoice_item_id === invoiceItemId);

                    return updateForm(customForm.member.member_id, customForm.custom_form_id, {
                        invoice_item_id: invoiceItemId,
                        fields: Object.keys(formikValues).reduce((newArray, key) => {
                            // keys look like this `group_${indexGroup}_fields_${indexField}`
                            const [ _temp1, groupIndex, _temp2, fieldIndex ] = key.split('_');
                            let answer, option = null;

                            if(customForm.groups[groupIndex].fields[fieldIndex].deleted_at){ // Filter out deleted fields
                                return newArray;
                            }

                            // has options -> checkbox, radio, select or multi select
                            if(customForm.groups[groupIndex].fields[fieldIndex].options){
                                option = formikValues[key];

                                // multi options -> checkbox or multi select
                                if(Array.isArray(formikValues[key])){
                                    // answer = array of translated option names
                                    answer = formikValues[key].map((optionId) => {
                                        return displayI18n(
                                            'field_option',
                                            customForm.groups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.i18n,
                                            customForm.groups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.field_option,
                                            i18nContext.getGenericLocale(),
                                        )
                                    })
                                }else{ // single option -> radio, select
                                    answer = displayI18n(
                                        'field_option',
                                        customForm.groups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === formikValues[key])?.i18n,
                                        customForm.groups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === formikValues[key])?.field_option,
                                        i18nContext.getGenericLocale(),
                                    ) || '';
                                }
                            }else{ // no options -> short answer, text area, date, time or number
                                // eslint-disable-next-line no-lonely-if
                                if(customForm.groups[groupIndex].fields[fieldIndex].form_field_code === 'DATE' && formikValues[key]){ // DATE, YYYY-MM-DD format
                                    answer = moment.isMoment(formikValues[key]) ? formikValues[key].format('YYYY-MM-DD') : moment(formikValues[key]).format('YYYY-MM-DD')
                                }else if(customForm.groups[groupIndex].fields[fieldIndex].form_field_code === 'DATETIME' && formikValues[key]){ // DATETIME, ISO format
                                    answer = moment.isMoment(formikValues[key]) ? formikValues[key].toISOString() : moment(formikValues[key]).toISOString()
                                }else{ // simple string or number
                                    answer = formikValues[key]
                                }
                            }

                            newArray.push({
                                custom_form_field_id: customForm.groups[groupIndex].fields[fieldIndex].custom_form_field_id,
                                custom_form_field_option_selected: Array.isArray(option) ? option.toString() : option,
                                answer: answer,
                            })
                            return newArray
                        }, []),
                    });
                }))
                    .then(() => {
                        nextStep();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
            validateOnMount
        >
            {(formik) => (
                <OverlayLoader isLoading={isValidating || formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={closeModal}>
                            <Translate id='unansweredFormsModal.modal.forms.transaction' />: {invoiceNumber}
                        </ModalHeader>
                        <ModalBody>
                            {customForms?.map((form, index) => (
                                <Fragment key={form.custom_form_id + form.member.member_id}>
                                    <div className="mb-2">
                                        <div className="h5 font-medium mb-1">{form.name}</div>
                                        <div className="mb-1">{form.member.first_name} {form.member.last_name}</div>
                                    </div>
                                    {form?.groups.map((group, groupIndex) => (
                                        // If this group only has deleted question with no answer
                                        group.fields.some((field) => ((field.deleted_at && field.answer) || !field.deleted_at)) &&
                                            <CollapsibleCard
                                                key={form.custom_form_id + group.custom_form_group_id + form.member.member_id}
                                                title={<DisplayI18n field="name" i18n={group.i18n} defaultValue={group.name} />}
                                                className={stringBuilder({ 'mb-2': index !== customForms.length - 1 })}
                                                initOpen
                                            >
                                                { group.fields.reduce((newArray, field, i) => {
                                                    if(!field.deleted_at){
                                                        newArray.push(
                                                            <CustomFormField
                                                                key={form.custom_form_id + field.custom_form_field_id + group.custom_form_group_id + form.member.member_id}
                                                                groupIndex={groupIndex}
                                                                field={field}
                                                                index={i}
                                                                invoiceItemId={form.invoice_item_id}
                                                                isLast={i === group.fields.length - 1}
                                                            />,
                                                        )
                                                    }
                                                    return newArray
                                                }, [])}
                                            </CollapsibleCard>
                                    ))}
                                    {index !== customForms.length - 1 && <hr />}
                                </Fragment>
                            ))}
                            <Collapse isOpen={!!formik.status} unmountOnExit mountOnEnter>
                                <Alert color='danger' className="mb-0 mt-3">
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter className="justify-content-between">
                            <Button color="primary" type="button" outline onClick={previousStep} disabled={formik.isSubmitting || isValidating}><Translate id='misc.previous' /></Button>
                            <Button color="primary" type="submit" disabled={formik.isSubmitting || isValidating}><Translate id='misc.submit' /></Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

const CustomFormField = ({ field, groupIndex, index, invoiceItemId, isLast }) => {
    const fieldName = `${invoiceItemId}.group_${groupIndex}_fields_${index}`;
    const formik = useFormikContext();
    return (
        <FormGroup className={stringBuilder({ 'mb-0': isLast })}>
            <Label for={fieldName} className='mb-0 d-flex align-items-center'>
                <DisplayI18n
                    field='title'
                    defaultValue={field?.title || '-Question-'}
                    i18n={field?.i18n}
                />
                {getLabelHelper(field)}
                {field.mandatory == 1 &&
                    <span className="ml-1"><Required /></span>
                }
                {field.seen == 0 &&
                    <sup className="small">
                        <small>
                            <Badge color='primary' pill className="ml-1 text-uppercase"><Translate id='misc.new' /></Badge>
                        </small>
                    </sup>
                }
            </Label>
            {displayField({ ...field, options: field.options?.filter((opt) => !opt.deleted_at) }, fieldName, formik, undefined, false)}
        </FormGroup>
    )
}

export default UnansweredFormsModal;