import Translate from '@spordle/intl-elements'
import { useState } from 'react'
import { Button } from 'reactstrap'
import ManagePaymentModal from './managePaymentModal/ManagePaymentModal'

const PaymentPayNowButton = ({ invoiceItem, updateInvoices, fromList }) => {
    const [ changeIsOpen, setChangeIsOpen ] = useState(false);
    return (
        <Button
            block
            id={fromList ? `invoice-payNow-${invoiceItem.invoice_payment_id}` : 'payNow-cta'}
            type="button"
            onClick={() => setChangeIsOpen(true)}
            color="primary"
            size={fromList ? "sm" : undefined}
        >
            <ManagePaymentModal
                isOpen={changeIsOpen}
                toggle={() => setChangeIsOpen(false)}
                invoiceItem={invoiceItem}
                transactionType={invoiceItem.provider === "QUICK_ENROLLMENT" ? "qe" : 'paysafe'}
                updateInvoices={updateInvoices}
                paymentMode
            />
            <Translate id='account.paymentMethods.label.payNow' />
        </Button>
    )
}

export default PaymentPayNowButton
