import moment from 'moment';
import { Component, createContext } from 'react';
import { LOCALES } from '../i18n';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/browserStorage';
import { setGA } from '@spordle/ga4';
import { setTag } from '@sentry/react';

/** @type {React.Context<Omit<I18nContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & I18nContextProvider['state']>} */
export const I18nContext = createContext();
I18nContext.displayName = 'I18nContext';

/**
 * Gets the general lang variable
 * @param {string} specificLang The specific lang like 'en-ca'
 * @returns {'en'|'fr'}
 */
export const getGenericLocale = (specificLang) => {
    switch (specificLang){
        case 'en-ca':
            return 'en';
        default: // This is valid for default language (fr) and language that is not available
            return 'fr';
    }
}

export const builtLocale = (locale) => {
    switch (locale?.split(/(-|_)/)[0]){
        default:
        case 'en':
            return LOCALES.ENGLISH;
        case 'fr':
            return LOCALES.FRENCH;
    }
};

class I18nContextProvider extends Component{
    constructor(props){
        super(props);
        this.state = {
            locale: builtLocale(getLocalStorageItem('lang') || window.navigator.userLanguage?.toLowerCase() || window.navigator.language.toLowerCase() || 'en-ca'),
        }
        moment.locale(this.state.locale);
        setGA({
            language: this.state.locale,
        });
        setTag('language', this.state.locale);
    }

    /**
     * Gets the full language name
     * @returns {'English'|'Français'}
     */
    getFullLanguage = () => {
        switch (this.state.locale){
            case 'en-ca':
                return 'English';
            default: // This is valid for default language (fr-ca) and language that is not available
                return 'Français';
        }
    }

    /**
     * Gets the general lang variable
     * @returns {'en'|'fr'}
     */
    getGenericLocale = () => getGenericLocale(this.state.locale);

    /**
     * Sets the lang of the platform
     * @param {string} [locale]
     */
    setLocale = (locale) => {
        const formattedLocale = builtLocale(locale);
        setLocalStorageItem('lang', formattedLocale)
        this.setState({ locale: formattedLocale });
        moment.locale(formattedLocale);
        setGA({
            language: formattedLocale,
        });
        setTag('language', formattedLocale);
    }

    render(){
        return (
            <I18nContext.Provider value={{ ...this.state, setLocale: this.setLocale, getLanguageName: this.getFullLanguage, getGenericLocale: this.getGenericLocale, getLanguageForMaps: this.getLanguageForMaps }}>
                {this.props.children}
            </I18nContext.Provider>
        );
    }
}
export default I18nContextProvider;