export default{
    "account.settings.profile.accountEmail.emailNotVerified" : "Your Email is not verified",

    "account.settings.profile.accountEmail.email.current.notVerified" : "You current Email is not verified",
    "account.settings.profile.accountEmail.email.current.change"      : "Change Email",
    "account.settings.profile.accountEmail.email.current.verify"      : "Verify",

    "account.settings.profile.accountEmail.email.toast.success" : "Email sent!",
    "account.settings.profile.accountEmail.email.toast.fail"    : "Email could not be sent",

    "account.settings.profile.accountEmail.code.toast.success" : "Your Account has been updated",
    "account.settings.profile.accountEmail.code.toast.fail"    : "An error occurred. Please refresh the page.",
}