import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail, success } from '@spordle/toasts';
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

/**
 * @description Button that handles the logic of the retry payment
 *
 * @param {Object} props
 * @param {string} props.paymentId
 * @param {"dark"|"primary"|"success"|"danger"|"light"|"warning"} props.color
 * @returns {JSX.Element}
 */
const RetryPaymentBtn = ({ color = "primary", provider, paymentId, onSuccess, ...props }) => {
    const [ isRetrying, setIsRetrying ] = useState(false);
    const { processPayment } = useContext(MembersContext);

    const retryPayment = () => {
        if(!isRetrying){ // prevent spam
            setIsRetrying(true);
            processPayment(paymentId)
                .then(async() => {
                    onSuccess?.();
                    success({ info: provider === 'QUICK_ENROLLMENT' ? 'account.paymentMethods.toast.success.payment' : 'account.paymentMethods.toast.success.payment.paysafe' });
                    setIsRetrying(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setIsRetrying(false);
                        fail({ info: <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />, skipInfoTranslate: true });
                    }
                })
        }
    }

    return (
        <Button block {...props} disabled={isRetrying} onClick={retryPayment} type="button" color={color}>
            <Translate id='account.paymentMethods.btn.tryAgain' />
            {isRetrying ?
                <Spinner color='white' type='grow' size='sm' className='ml-1' />
                :
                <i className="ml-1 mdi mdi-replay ml-1" />
            }
        </Button>
    )
}

export default RetryPaymentBtn;