import { Component } from 'react';
import {
    Fade,
    Container,
    Spinner,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import { AuthContext } from '../../../contexts/contexts';

// Components
import Translate from '@spordle/intl-elements';
import { AxiosCancelAll } from '../../../api/CancellableAPI';

import GoToHomeBtn from '../../../components/GoToHomeBtn';

import Account from './profile/AccountProfile';
import UATWarning from '../../../components/UATWarning';

class AccountSettings extends Component{
    static contextType = AuthContext;

    constructor(props){
        super(props);

        this.state = {
            activeTab: this.getTab(),
            userData: null,
        }
    }

    getTab = () => {
        switch (this.props.location.hash.substring(1)){
            case 'account':
                return 'account';
            case 'twoFA':
                return 'twoFA';
                // case 'history':
                // 	return 'history';

            default:
                return 'account';
        }
    }

    toggleTabs = (tab) => {
        this.setState(() => ({ activeTab: tab }));
    }

    componentDidUpdate(prevProps){
        if(this.props.location.hash !== prevProps.location.hash){
            this.toggleTabs(this.getTab());
        }
    }

    componentDidMount(){
        this.fetchUser();
    }

    fetchUser = () => {
        return this.context.getUserData()
            .then((user) => {
                this.setState(() => ({ userData: user }));
            })
            .catch((_error) => {
                this.props.history.replace({ pathname: '/auth/HCR', search: (this.props.location.search ?? '?') + '&goto=account' });
            })
    }

    // updateMFA = (mfaSettings) => {
    //     this.setState((prevState) => ({ userData: {
    //         ...prevState.userData,
    //         UserMFASettingList: {
    //             ...prevState.userData.UserMFASettingList,
    //             ...mfaSettings,
    //         },
    //     } }));
    // }

    // updateMFAPref = (mfaPref) => {
    //     this.setState((prevState) => ({ userData: {
    //         ...prevState.userData,
    //         PreferredMfaSetting: mfaPref,
    //     } }));
    // }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <header className="p-3 mb-0 card card-border card-shadow">
                    <div className="d-flex align-items-center justify-content-between">
                        {/* Title */}
                        <div className="h3 font-medium text-primary mb-0"><Translate id='account.settings.accountSettings.title' /></div>
                        <div className="">
                            <GoToHomeBtn />
                        </div>
                    </div>
                </header>
                <UATWarning />

                <div className="page-content container-fluid">
                    <Container className="ml-0">
                        {!this.state.userData ?
                            <div className='text-center'>
                                <Spinner color="primary" type='grow' />
                            </div>
                            :
                            <>
                                {/*--------------------------------------------------------------------------------*/}
                                {/* Tabs                                                                  */}
                                {/*--------------------------------------------------------------------------------*/}
                                <Nav tabs className="mb-4 font-medium font-16 text-dark">
                                    <NavItem>
                                        <NavLink
                                            tabIndex="account"
                                            href='#account'
                                            className="border-left-0 border-right-0 border-top-0"
                                            active={this.state.activeTab === 'account'}
                                        >
                                            <span className="p-1 d-block"><Translate id='account.settings.accountSettings.tabs.account' /></span>
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            tabIndex="twoFA"
                                            href='#twoFA'
                                            className="border-left-0 border-right-0 border-top-0"
                                            active={this.state.activeTab === 'twoFA'}>
                                            <span className="p-1 d-block"><Translate id='account.settings.accountSettings.tabs.2step'/></span>
                                        </NavLink>
                                    </NavItem> */}
                                    {/* <NavItem>
                                        <NavLink
                                            tabIndex="history"
                                            href='#history'
                                            className="border-left-0 border-right-0 border-top-0"
                                            active={this.state.activeTab === 'history'}>
                                            <span className="p-1 d-block"><Translate id='account.settings.accountSettings.tabs.history'/></span>
                                        </NavLink>
                                    </NavItem> */}

                                </Nav>

                                <TabContent activeTab={this.state.activeTab} className="bg-transparent">

                                    {/* Account */}
                                    <TabPane tabId="account">
                                        <Fade in={this.state.activeTab === "account"}>
                                            <Account />
                                        </Fade>

                                    </TabPane>

                                    {/* twoFA */}
                                    {/* <TabPane tabId="twoFA">
                                        <Fade in={this.state.activeTab === "twoFA"}>
                                            <Account2FA
                                                phoneNumber={this.state.userData.UserAttributes.phone_number}
                                                mfa={this.state.userData.UserMFASettingList}
                                                updateMFA={this.updateMFA}
                                                email={this.state.userData.UserAttributes.email}
                                                mfaPreference={this.state.userData.PreferredMfaSetting}
                                                updateMFAPref={this.updateMFAPref}
                                            />
                                        </Fade>
                                    </TabPane> */}

                                    {/* Login history */}
                                    {/* <TabPane tabId="history">
                                        <Fade in={this.state.activeTab === "history"}>
                                            <LoginHistory/>
                                        </Fade>
                                    </TabPane> */}

                                </TabContent>

                            </>
                        }
                    </Container>
                </div>
            </>
        );
    }
}

export default AccountSettings;