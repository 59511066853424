import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import React from "react";
import {
    Badge,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
// Contexts
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import TransactionCredits from "./components/TransactionCredits";
import TransactionHeader from "./components/TransactionHeader";
import TransationRefunds from "./components/TransationRefunds";
import TransactionPaymentReceptions from "./components/TransactionPaymentReceptions";
import TransactionTotalDue from "./components/TransactionTotalDue";
import TransactionRegistrationForms from "./TransactionRegistrationForms";
import TransactionRegistrationWaivers from "./waivers/TransactionRegistrationWaivers";
import queryString from 'query-string';
import { withRouter } from "react-router";
import moment from "moment";

class TransationsSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.forceSignWaivers = queryString.parse(window.location.search).tab === 'waivers';
        const getInitialTab = () => {
            switch (queryString.parse(window.location.search).tab){
                case 'waivers':
                    return '3'
                case 'forms':
                    return '2'
                default:
                    return '1'
            }
        }

        this.state = {
            activeTab: getInitialTab(),
        };
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    getRemainingAffiliationFeeAmount = (member_id) => {
        return this.props.selectedRows[0].invoice_items.reduce((sum, item) => {
            if(item.affiliation_fee !== null && item.member.member_id === member_id && (item.invoice_item_payment_status !== 'COMPLETED' && item.invoice_item_payment_status !== 'REFUNDED'))
                sum += parseInt(item.new_amount)
            return sum
        }, 0)
    }

    toggleTabs(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentDidMount(){
        this.props.removeInvoiceNumberFromURL();
    }

    render(){
        return (
            <>
                <div className="p-3">
                    <TransactionHeader {...this.props} hideHeader={!!this.props.hideHeader} transaction={this.props.selectedRows[0]} />
                </div>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTabs('1'); }}
                        >
                            <Translate id='account.transactions.sidePanel.tabs.summary' />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTabs('2'); }}
                        >
                            <Translate id='account.transactions.sidePanel.tabs.forms' />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggleTabs('3'); }}
                        >
                            <Translate id='account.transactions.sidePanel.tabs.waivers' />
                        </NavLink>
                    </NavItem>
                </Nav>

                <div className='p-3'>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {/* Total / due */}
                            <TransactionTotalDue totalAmount={this.props.selectedRows[0].total} dueAmount={this.props.selectedRows[0].due} />

                            {/* Refunds */}
                            <TransationRefunds totalRefundAmount={this.props.selectedRows[0].total_refunded_amount} refunds={this.props.selectedRows[0].refunds} />

                            {/* Payment receptions */}
                            <TransactionPaymentReceptions paymentReceptions={this.props.selectedRows[0].payment_receptions} invoiceNumber={this.props.selectedRows[0].invoice_number} />

                            {/* Credits */}
                            <TransactionCredits credits={this.props.selectedRows[0].credits} />

                            {/* REGISTRATIONS */}
                            {this.props.selectedRows[0].registrations && this.props.selectedRows[0].registrations.length > 0 &&
                                <div className='mb-3'>
                                    <div className="h4 font-bold"><Translate id='account.transactions.registrations' /></div>
                                    {this.props.selectedRows[0].registrations.map((item) => {
                                        return (
                                            <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                <CardBody>
                                                    <UserDisplay className="mb-3">
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                abbr={item.member.first_name[0] + item.member.last_name[0]}
                                                                src={undefined}
                                                                width={30}
                                                                height={30}
                                                                alt={item.member.first_name + ' ' + item.member.last_name}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title>{item.member.first_name + ' ' + item.member.last_name}</UserDisplay.Title>
                                                            <UserDisplay.Subtitle>#{item.member.unique_identifier}</UserDisplay.Subtitle>
                                                        </UserDisplay.Container>
                                                    </UserDisplay>

                                                    <div className='font-medium'>
                                                        <Translate id='account.transactions.sidePanel.items.payments' />
                                                        <span className="mx-1">:</span>
                                                        <DisplayI18n
                                                            field='name'
                                                            i18n={item.registration_fee.fee.i18n}
                                                            defaultValue={item.registration_fee.fee.name}
                                                        />
                                                    </div>
                                                    {item.payments_to_display?.sort((a, b) => {
                                                        const aDueDate = moment(a.due_date);
                                                        const bDueDate = moment(b.due_date);

                                                        if(a.payment_type === 'INITIAL_PAYMENT' || b.payment_type === 'INITIAL_PAYMENT'){
                                                            return 0
                                                        }
                                                        if(aDueDate.isBefore(bDueDate)){
                                                            return -1
                                                        }else if(bDueDate.isBefore(aDueDate)){
                                                            return 1
                                                        }
                                                        return 0


                                                    }).map((payment) => (
                                                        <Card className='p-2 border mb-2 card-shadow' key={payment.invoice_payment_id}>
                                                            <div className="d-flex justify-content-between">
                                                                <DateFormat value={payment.due_date} utc />
                                                                {payment.status === 'COMPLETED' && payment.payment_method?.code === 'CREDITCARD' &&
                                                                    <button type='button' className='reset-btn text-link' onClick={() => this.props.history.push('/purchases/receipt?invoiceNumber=' + this.props.selectedRows[0].invoice_number + '&paymentId=' + payment.invoice_payment_id)}>
                                                                        <i className='mdi mdi-printer font-18' />
                                                                    </button>
                                                                }
                                                            </div>
                                                            <div className='d-flex justify-content-between'>
                                                                <Badge color={payment.status === 'COMPLETED' ? 'success' : (payment.status === 'PENDING' || payment.status === 'NEED_REVIEW' || payment.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                                    <Translate id={'account.transactions.sidePanel.status.' + payment.status} />
                                                                </Badge>
                                                                <div><DisplayI18n field='name' defaultValue={payment.payment_method?.name} i18n={payment.payment_method?.i18n} /></div>
                                                                <CurrencyFormat value={parseInt(payment.amount) / 100} />
                                                            </div>
                                                        </Card>
                                                    ))}
                                                </CardBody>
                                            </Card>
                                        )
                                    })}
                                </div>
                            }

                            {/* ITEMS */}
                            {(this.props.selectedRows[0].otherItems && this.props.selectedRows[0].otherItems.length > 0) &&
                                <div className='mb-3'>
                                    <div className="h4 font-bold"><Translate id='account.transactions.items' /></div>
                                    {this.props.selectedRows[0].otherItems.map((item) => {
                                        return (
                                            <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                <CardBody>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='h6 mb-0 text-truncate'>
                                                            <span className='font-bold'>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    i18n={item.other_fee.fee.i18n}
                                                                    defaultValue={item.other_fee.fee.name}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )
                                    })}
                                </div>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            <TransactionRegistrationForms {...this.props} />
                        </TabPane>
                        <TabPane tabId="3">
                            <TransactionRegistrationWaivers {...this.props} forceSignWaivers={this.forceSignWaivers} />
                        </TabPane>
                    </TabContent>
                </div>
            </>
        )
    }
}

export default withRouter(TransationsSidepanel);