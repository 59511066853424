export default{
    // ONLY ADD GENERAL FIELDS
    // Example of a general field   : First Name (can be used for attendees, instructors, users, etc.)
    // Example of a specific field  : Organization Name (only used in organization)

    // Fields
    "form.fields.streetNumber"                  : "Numéro d'adresse",
    "form.fields.address"                       : "Adresse",
    "form.fields.address2.placeholder"          : "Appartement, suite, unité, étage, bâtiment, etc.",
    "form.fields.addressType"                   : "Type d'adresse",
    "form.fields.city"                          : "Ville",
    "form.fields.confrimEmail"                  : "Confirmez votre courriel",
    "form.fields.confrimPhone"                  : "Confirmer le numéro de téléphone",
    "form.fields.country.ca"                    : "Canada",
    "form.fields.country"                       : "Pays",
    "form.fields.currentEmail"                  : "Courriel actuel",
    "form.fields.currentPhone"                  : "Numéro de téléphone actuel",
    "form.fields.date"                          : "Date",
    "form.fields.dateOfBirth"                   : "Date de naissance",
    "form.fields.description.lang"              : "Description ({lang})",
    "form.fields.description"                   : "Description",
    "form.fields.email"                         : "Courriel",
    "form.fields.extension"                     : "Extension",
    "form.fields.fees"                          : "Frais",
    "form.fields.firstName"                     : "Prénom",
    "form.fields.forms"                         : "Formulaires",
    "form.fields.gender.female"                 : "Féminin",
    "form.fields.gender.FEMALE"                 : "Féminin",
    "form.fields.gender.male"                   : "Masculin",
    "form.fields.gender.MALE"                   : "Masculin",
    "form.fields.gender.other"                  : "X",
    "form.fields.gender.OTHER"                  : "X",
    "form.fields.gender.MIXED"                  : "Mixte",
    "form.fields.gender.placeholder"            : "Sélectionnez un genre",
    "form.fields.gender.prefer_not_to_say"      : "X",
    "form.fields.gender"                        : "Identité de genre",
    "form.fields.general"                       : "Général",
    "form.fields.generalInfo"                   : "Informations Générales",
    "form.fields.language"                      : "Langue",
    "form.fields.lastConnection"                : "Dernière connexion",
    "form.fields.lastName"                      : "Nom de famille",
    "form.fields.mobilePhone"                   : "Téléphone mobile",
    "form.fields.name.lang"                     : "Nom ({lang})",
    "form.fields.name"                          : "Nom",
    "form.fields.nationality.CA"                : "Canadien",
    "form.fields.nationality.placeholder"       : "Sélectionnez une citoyenneté",
    "form.fields.nationality.selected"          : "{count} citoyenneté sélectionnées",
    "form.fields.nationality.US"                : "Américain",
    "form.fields.nationality"                   : "Citoyenneté",
    "form.fields.newEmail"                      : "Nouveau courriel",
    "form.fields.newPhone"                      : "Nouveau numéro de téléphone",
    "form.fields.oldEmail"                      : "Ancien courriel",
    "form.fields.oldPassword.required"          : "L'ancien mot de passe est requis",
    "form.fields.oldPassword"                   : "Ancien Mot de passe",
    "form.fields.password.length.max"           : "Doit contenir moins de 99 caractères",
    "form.fields.password.length.min"           : "Doit contenir au moins 8 caractères",
    "form.fields.password.letter"               : "Doit contenir une lettre",
    "form.fields.password.lowercase"            : "Doit contenir une minuscule",
    "form.fields.password.number"               : "Doit contenir 1 chiffre",
    "form.fields.password.required"             : "Mot de passe requis",
    "form.fields.password.space"                : "Le mot de passe ne peut pas contenir d'espaces",
    "form.fields.password.specialChar"          : "Le mot de passe doit avoir un caractère spécial",
    "form.fields.password.uppercase"            : "Doit contenir une majuscule",
    "form.fields.password"                      : "Mot de passe",
    "form.fields.phone"                         : "Numéro de téléphone",
    "form.fields.phoneType"                     : "Type de téléphone",
    "form.fields.primaryLanguage.placeholder"   : "Sélectionnez une langue",
    "form.fields.primaryLanguage"               : "Langue maternelle",
    "form.fields.province"                      : "Province",
    "form.fields.relation.CHILD"                : "Enfant",
    "form.fields.relation.DAUGHTER"             : "Fille",
    "form.fields.relation.FATHER"               : "Père",
    "form.fields.relation.MOTHER"               : "Mère",
    "form.fields.relation.OTHER"                : "Autre",
    "form.fields.relation.SON"                  : "Fils",
    "form.fields.relation"                      : "Relation",
    "form.fields.secondaryLanguages"            : "Langue seconde",
    "form.fields.short_name.lang"               : "Nom abrégé ({lang})",
    "form.fields.short_name"                    : "Nom abrégé",
    "form.fields.state"                         : "État",
    "form.fields.status"                        : "Statut",
    "form.fields.title.lang"                    : "Titre ({lang})",
    "form.fields.title"                         : "Titre",
    "form.fields.zip"                           : "Code postal",
    "form.fields.format"                        : "Format",
    "form.fields.identifyAsIndigenous"          : "S'identifier comme Autochtone",
    "form.fields.identifyAsIndigenous.required" : "Veuillez spécifier si vous vous identifiez comme Autochtone.",
    "form.fields.indigenousGroup"               : "Groupe Autochtone",
    "form.fields.indigenousGroup.required"      : "Le groupe Autochtone est requis.",
    "form.fields.ethnicity"                     : "Ethnicité",
    "form.fields.ethnicity.required"            : "L'ethnicité est requise.",
    "form.fields.ethnicityNote"                 : "Note",
    "form.fields.ethnicityNote.required"        : "La note est requise.",
    "form.fields.birthCountry"                  : "Pays de naissance",
    "form.validation.required"                  : "Champs requis",
    "form.validation.max"                       : "Max",
    "form.validation.min"                       : "Min",
    "form.validation.streetNumber.required"     : "Le numéro d'adresse est requis",
    "form.validation.address.required"          : "L'adresse est requise",
    "form.validation.addressType.required"      : "Le type d'adresse est requise",
    "form.validation.city.required"             : "La ville est requise",
    "form.validation.country.required"          : "Le pays est requis",
    "form.validation.date.format"               : "Date doit être d'un format valide",
    "form.validation.dateOfBirth.format"        : "La date de naissance doit être d'un format valide",
    "form.validation.dateOfBirth.required"      : "La date de naissance est requise",
    "form.validation.dateOfBirth.valid"         : "La date de naissance doit être d'un bon format",
    "form.validation.description.required"      : "La description est requise",
    "form.validation.email.matchConfirm"        : "Les courriel doivent correspondre",
    "form.validation.email.matchCurrent.not"    : "Le courriel ne peut pas être le même courriel",
    "form.validation.email.matchCurrent"        : "Le courriel doit correspondre à votre courriel actuel",
    "form.validation.email.required"            : "Le courriel est requis",
    "form.validation.email.valid"               : "Le courriel doit être valide",
    "form.validation.ethnicity.required"        : "L'ethnicité est requise",
    "form.validation.firstName.required"        : "Le prénom est requis",
    "form.validation.gender.required"           : "Le genre est requis",
    "form.validation.language.required"         : "La langue est requise",
    "form.validation.lastName.required"         : "Le nom est requis",
    "form.validation.name.lang.required"        : "Le nom ({lang}) est requis",
    "form.validation.name.required"             : "Le nom est requis",
    "form.validation.nationality.required"      : "La citoyenneté est requise",
    "form.validation.phone.matchConfirm"        : "Les numéros de téléphone doivent correspondre",
    "form.validation.phone.matchCurrent"        : "Le numéro de téléphone doit correspondre à votre numéro de téléphone actuel",
    "form.validation.phone.required"            : "Le numéro de téléphone est requis",
    "form.validation.phone.valid"               : "Le numéro de téléphone doit être valide",
    "form.validation.phoneType.required"        : "Le type de téléphone est requis",
    "form.validation.primaryLanguage.required"  : "Lalangue primaire est requise",
    "form.validation.relation.required"         : "La Relation est requise",
    "form.validation.state.required"            : "L'état est requis",
    "form.validation.province.required"         : "La province est requise",
    "form.validation.title.required"            : "Le titre est requis",
    "form.validation.zip.required"              : "Le code postal est requis",
    "form.validation.zip.valid"                 : "Le code postal doit être valide",
    "form.validation.number"                    : "Cette valeur doit être un nombre",
    "form.validation.number.positive"           : "Cette valeur doit être un nombre positif",
    "form.validation.number.positive.test"      : "Cette valeur doit \" être un nombre positif",

    "form.fields.waiver.required"      : "Veuillez sélectionner une option.",
    "form.fields.waiver.defaultAccept" : "J'ai lu et j'accepte la dispense",
    "form.fields.waiver.doNotSign"     : "Je préfère ne pas signer",
    "form.fields.waiver.notMandatory"  : "Cette dispense n'est pas obligatoire",
    "form.fields.waiver.mandatory"     : "Cette dispense est obligatoire",
    "form.fields.waiver.clear"         : "Effacer la sélection",
    "form.fields.waiver.VERBAL"        : "Accord verbal",
    "form.fields.waiver.PHYSICAL"      : "Accord physique",
    "form.fields.waiver.ONLINE"        : "Accord en ligne",
    // Canada
    // Error messages (Yup)
}