import { useContext, useState, useEffect } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap";
import { FormikInputText, FormikSelect, FormikInputNumber } from '@spordle/formik-elements';
import FormikEditable from '../../../../../components/formik/FormikEditable';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';

import { I18nContext } from '../../../../../contexts/I18nContext';
import { fail, success } from '@spordle/toasts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { AccountsContext } from '../../../../../contexts/contexts';

const TravelPermitSidepanelHost = ({ data, syncRows, createNewValues }) => {
    const accountsContext = useContext(AccountsContext)
    const i18nContext = useContext(I18nContext);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ travelTypes, setTravelTypes ] = useState([]);
    const [ travelTypesLoading, setTravelTypesLoading ] = useState([]);

    useEffect(() => {
        setTravelTypesLoading(true)
        accountsContext.getTravelTypes({ organisation_id: data.team.organisation.organisation_id })
            .then((travelTypes) => {
                setTravelTypes(travelTypes)
                setTravelTypesLoading(false)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])


    return (
        <OverlayLoader isLoading={isLoading}>
            <Row>
                {/* Event name */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='travel_event_name' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.name' /></Label>
                        <FormikEditable
                            id='travel_event_name'
                            initialValues={{
                                travel_event_name: data.travel_event_name || '',
                            }}
                            onSubmit={(values) => {
                                if(values.travel_event_name !== data.travel_event_name){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.travel_event_name}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='travel_event_name' name='travel_event_name' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Reference Number (travel_id) */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='travel_id' className='text-muted mb-0'><Translate id='account.members.travelPermits.travelId' /></Label>
                        <FormikEditable
                            id='travel_id'
                            initialValues={{
                                travel_id: data.travel_id || '',
                            }}
                            onSubmit={(values) => {
                                if(values.travel_id !== data.travel_id){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {data.travel_id || '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <FormikInputText id='travel_id' name='travel_id' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>

                {/* Travel Permit Fee */}
                <Col sm="12">
                    <FormGroup>
                        <Label for='' className='text-muted mb-0'>
                            <Translate id='account.members.travelPermits.fee' />
                        </Label>
                        <div className='font-medium text-dark'>
                            {data.travel_permit_fee ?
                                <CurrencyFormat value={(parseInt(data.travel_permit_fee)) / 100} />
                                :
                                '-'}
                        </div>
                    </FormGroup>
                </Col>

                {/* Event type */}
                <Col sm="12">
                    <FormGroup>
                        <Label for='travel_type_id' className='text-muted mb-0'>
                            <Translate id='account.members.travelPermits.sidePanelHost.travelType' />
                        </Label>
                        <FormikEditable
                            id='travel_type_id'
                            initialValues={{
                                travel_type_id: data.travel_type?.travel_type_id || '',
                            }}
                            noConfirmation
                            onSubmit={(values) => {
                                if(values.travel_type_id !== data.travel_type.travel_type_id){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const travelType = travelTypes.find((tT) => tT.travel_type_id == values.travel_type_id);
                                            syncRows(createNewValues({ travel_type: travelType }));
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {data.travel_type ?
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={data.travel_type.i18n}
                                                    defaultValue={data.travel_type.name}
                                                />
                                                :
                                                '-'
                                            }
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='travel_type_id'
                                        id='travel_type_id'
                                        onOptionSelected={options.stopEditing}
                                        menuIsDefaultOpen
                                        autoFocus
                                        search
                                        searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        renderOption={(opt) => <DisplayI18n field="name" defaultValue={opt.option.label} i18n={opt.option.i18n} />}
                                        noOptionLayout={<Translate id='account.members.travelPermits.sidePanelGeneral.noOptions' />}
                                        isLoading={travelTypesLoading}
                                        options={travelTypes.map((travelType) => ({
                                            value: travelType.travel_type_id,
                                            label: travelType.name,
                                            i18n: travelType.i18n,
                                        }))}

                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Division */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='host_division' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.division' /></Label>
                        <FormikEditable
                            id='host_division'
                            initialValues={{
                                host_division: data.host_division || '',
                            }}
                            onSubmit={(values) => {
                                if(values.host_division !== data.host_division){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.host_division || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='host_division' name='host_division' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Class */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='host_class' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.class' /></Label>
                        <FormikEditable
                            id='host_class'
                            initialValues={{
                                host_class: data.host_class || '',
                            }}
                            onSubmit={(values) => {
                                if(values.host_class !== data.host_class){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.host_class || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='host_class' name='host_class' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Category */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='host_category' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.category' /></Label>
                        <FormikEditable
                            id='host_category'
                            initialValues={{
                                host_category: data.host_category || '',
                            }}
                            onSubmit={(values) => {
                                if(values.host_category !== data.host_category){
                                    setIsLoading(true);
                                    accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'account.members.travelPermits.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.host_category || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='host_category' name='host_category' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>
            {true &&
                <Row>
                    {/* host_website */}
                    <Col sm="12">
                        <FormGroup>
                            <Label for='host_website' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.website' /></Label>
                            <FormikEditable
                                id='host_website'
                                initialValues={{
                                    host_website: data.host_website || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.host_website !== data.host_website){
                                        setIsLoading(true);
                                        accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'account.members.travelPermits.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.host_website || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='host_website' name='host_website' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
            }
            {true &&
                <Row>
                    {/* host_team_name */}
                    <Col sm="12">
                        <FormGroup>
                            <Label for='host_team_name' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.hostTeamName' /></Label>
                            <FormikEditable
                                id='host_team_name'
                                initialValues={{
                                    host_team_name: data.host_team_name || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.host_team_name !== data.host_team_name){
                                        setIsLoading(true);
                                        accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'account.members.travelPermits.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.host_team_name || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='host_team_name' name='host_team_name' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    {/* nb_games */}
                    <Col sm="12">
                        <FormGroup>
                            <Label for='nbGames' className='text-muted mb-0'><Translate id='account.members.travelPermits.sidePanelHost.nbGames' /></Label>
                            <FormikEditable
                                id='nbGames'
                                initialValues={{
                                    nbGames: data.nb_games || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.nb_games !== data.nb_games && values.nb_games > 0){
                                        setIsLoading(true);
                                        accountsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'account.members.travelPermits.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.nb_games || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputNumber id='nb_games' name='nb_games' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
            }
        </OverlayLoader>
    );
}

export default TravelPermitSidepanelHost;