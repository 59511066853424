import { useContext, useState } from 'react';
import { Button, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { AuthContext } from '../../../../../contexts/contexts';
import ListGroupItemLayout from './ListGroupItemLayout';

import { Form, Formik } from 'formik';
import { FormikInputText } from '@spordle/formik-elements';
import * as Yup from 'yup';
import Translate from '@spordle/intl-elements';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';

const AccountName = () => {
    const auth = useContext(AuthContext);
    const [ isOpen, setOpen ] = useState(false);
    const toggleModal = () => { setOpen(!isOpen) };
    return (
        <>
            <ListGroupItemLayout
                isFirst
                title={<Translate id='form.fields.name' />}
                value={`${auth.userData.UserAttributes.name} ${auth.userData.UserAttributes.family_name}`}
                onAction={toggleModal}
            />
            <AnalyticsModal analyticsName='AccountName' isOpen={isOpen}>
                <ModalHeader toggle={toggleModal}>
                    <Translate id='form.fields.name' />
                </ModalHeader>
                <Formik
                    initialValues={{
                        firstName: auth.userData.UserAttributes.name ?? '',
                        lastName: auth.userData.UserAttributes.family_name ?? '',
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(<Translate id='signup.signupForm.validation.firstName.required' />),
                        lastName: Yup.string().required(<Translate id='signup.signupForm.validation.lastName.required' />),
                    })}
                    onSubmit={({ firstName, lastName }, { setStatus, setSubmitting }) => {
                        setStatus();
                        auth.updateUserInfo({
                            ...auth.userData.UserAttributes,
                            name: firstName,
                            family_name: lastName,
                        }).then(() => {
                            setStatus();
                            success({ msg: 'account.setting.profile.accountName.update.toast.success' });
                            toggleModal();
                        })
                            .catch((error) => {
                                setStatus(error.message);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <FormikInputText name='firstName' label='signup.signupForm.label.firstName' trim />
                                </FormGroup>
                                <FormGroup>
                                    <FormikInputText name='lastName' label='signup.signupForm.label.lastName' trim />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggleModal}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    );
}

export default AccountName;