import Translate from "@spordle/intl-elements";
import { fail } from "@spordle/toasts";
import { useContext } from "react";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import SidePanel from "../../../../../../../components/sidePanel/SidePanel";
import { AuthContext } from "../../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { ReportsContext } from "../../../../../../../contexts/ReportsContext";
import { BC_ID } from "../../gameIncidentHelper";

const PdfExport = ({ gir }) => {
    const { getReports, createExportableReport } = useContext(ReportsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { userData } = useContext(AuthContext);

    /**
     * Gets the reports and finds the report with the game incident code
     * @returns {Promise.<string>}
     * @throws {Error}
     */
    async function getGirReportId(){
        return getReports({ type: "TEAM" }).then((reports) => reports.find((r) => r.code === "game_incidents")?.report_id)
    }

    /**
     * Function that handles the export to pdf logic
     * @async
     */
    async function handleOnExport(){
        try{
            const girReportId = await getGirReportId();
            // const reportData = await createExportableReport
            createExportableReport({
                organisation_id: BC_ID, // TODO: hardcoded for
                language_code: getGenericLocale(),
                total_count: 1,
                requested_by: userData.Username,
                report_id: girReportId,
                output: 'PDF', // TODO: harcoded for GIR
                request_parameter: JSON.stringify({ period_id: gir.period?.period_id, game_incident_number: gir.game_incident_number }),
            });
        }catch(e){
            if(!AxiosIsCancelled(e.message)){
                fail({
                    message: e.message === '3382' ? `account.members.gir.sidePanel.export.error.${e.message}` : 'misc.error',
                });
                console.error(e);
            }
        }
    }

    return (
        <SidePanel.MenuAction onClick={handleOnExport}>
            <Translate id='account.members.gir.sidePanel.export.pdf' />
        </SidePanel.MenuAction>
    );
}

export default PdfExport;