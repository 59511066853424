export default{
    "paysafe.error.duplicateCard" : "Cette carte est déjà reliée à votre compte.",
    "paysafe.error.9001"          : "Aucune connexion au serveur, veuillez réessayer. Si le problème persiste, veuillez contacter notre support.",
    "paysafe.error.9002"          : "Erreur de communication avec le serveur.",
    "paysafe.error.9003"          : "Il y a eu des erreurs avec les informations de paiement. Veuillez revérifier les champs erronés.",
    "paysafe.error.9004"          : "Une erreur s'est produite (tokenize: 9004), veuillez contacter notre support.",
    "paysafe.error.9013"          : "Clé API non valide (code: 9013), veuillez contacter notre support.",
    "paysafe.error.9014"          : "Une erreur s'est produite (code: 9014). Veuillez réessayer plus tard.",
    "paysafe.error.9015"          : "Une erreur s'est produite (tokenize: 9015), veuillez contacter notre support.",
    "paysafe.error.9028"          : "Le service est actuellement indisponible. Veuillez réessayer plus tard. (code: 9028)",
    "paysafe.error.9034"          : "Une erreur s'est produite (tokenize: 9034), veuillez contacter notre support.",
    "paysafe.error.9035"          : "Une erreur s'est produite (tokenize: 9035), veuillez contacter notre support.",
    "paysafe.error.9036"          : "Une erreur s'est produite (tokenize: 9036), veuillez contacter notre support.",
    "paysafe.error.9037"          : "Erreur de communication avec le serveur (3DS).",
    "paysafe.error.9039"          : "L'inscription n'est pas acceptée. Réessayez avec la même carte ou une autre.",
    "paysafe.error.9040"          : "Authentification non acceptée. Réessayez avec la même carte ou une autre.",
    "paysafe.error.9041"          : "Une erreur s'est produite (tokenize: 9041), veuillez contacter notre support.",
    "paysafe.error.9042"          : "L’utilisateur a abandonné l’authentification.",
    "paysafe.error.9043"          : "Le nom est invalide.",
    "paysafe.error.9044"          : "Pays est invalide",
    "paysafe.error.9045"          : "Code postal est invalide.",
    "paysafe.error.9046"          : "Province est invalide.",
    "paysafe.error.9047"          : "Ville invalide.",
    "paysafe.error.9048"          : "Addresse invalide.",
    "paysafe.error.9049"          : "Une erreur s'est produite (tokenize: 9049), veuillez contacter notre support.",
    "paysafe.error.9050"          : "Une erreur s'est produite (tokenize: 9050), veuillez contacter notre support.",
    "paysafe.error.9051"          : "Une erreur s'est produite (tokenize: 9051), veuillez contacter notre support.",
    "paysafe.error.9052"          : "Une erreur s'est produite (tokenize: 9052), veuillez contacter notre support.",
    "paysafe.error.9110"          : "Une erreur s'est produite (tokenize: 9110), veuillez contacter notre support.",
    "paysafe.error.9111"          : "Une erreur s'est produite (tokenize: 9111), veuillez contacter notre support.",
    "paysafe.error.9112"          : "Une erreur s'est produite (tokenize: 9112), veuillez contacter notre support.",
    "paysafe.error.9113"          : "Une erreur s'est produite (tokenize: 9113), veuillez contacter notre support.",
    "paysafe.error.9114"          : "Une erreur s'est produite (tokenize: 9114), veuillez contacter notre support.",
    "paysafe.error.9125"          : "Une erreur s'est produite (tokenize: 9125), veuillez contacter notre support.",
}