import SpordleTableProvider, { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Badge, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Fade, UncontrolledDropdown } from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const MemberDocumentFilters = ({ documentTypes }) => {
    const { filterChange, getFilters } = useSpordleTable();
    const filters = getFilters();

    const addFilters = (type) => {
        const previousFilters = filters.documentTypes;

        if(!previousFilters.some((prevType) => prevType.document_type_id === type.document_type_id)){
            filterChange('documentTypes', [ type, ...previousFilters ]);
        }
    }

    const removeFilter = (id) => {
        filterChange('documentTypes', filters.documentTypes.filter((type) => type.document_type_id !== id));
    }

    return (
        <div className='mb-3'>
            <div className='d-flex align-items-center'>
                <UncontrolledDropdown className="mr-2">
                    <DropdownToggle color="primary">
                        <i className="mdi mdi-filter-variant" />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header><Translate id='account.members.documents.documentType.label' /></DropdownItem>
                        {documentTypes.map((type) => (
                            <DropdownItem disabled={filters.documentTypes.some((docType) => docType.document_type_id === type.document_type_id)} onClick={() => addFilters(type)} key={type.document_type_id}>
                                <DisplayI18n
                                    field="name"
                                    defaultValue={type.name}
                                    i18n={type.i18n}
                                />
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <SpordleTableProvider.SearchInput />
            </div>
            <Collapse isOpen={filters.documentTypes.length > 0}>
                <div className="d-flex align-items-center mt-2">
                    <span className="my-1">
                        <Translate id='account.members.documents.filters.label' />:
                    </span>
                    <ul className="ml-2 mb-0 list-unstyled d-inline-flex align-items-center p-0 mt-0">
                        {filters.documentTypes.map((type) => (
                            <li className="mr-2" key={`badge-${type.document_type_id}`}>
                                <FilterBadge removeFilter={removeFilter} documentType={type} />
                            </li>
                        ))}
                    </ul>
                </div>
            </Collapse>
        </div>
    );
}

const FilterBadge = ({ documentType, removeFilter }) => {
    const [ isRemoved, setIsRemoved ] = useState(false);

    return (
        <Collapse onExited={() => removeFilter(documentType.document_type_id)} isOpen={!isRemoved}>
            <Fade in={!isRemoved}>
                <Badge className="clickable shadow-sm mb-0" onClick={() => setIsRemoved(true)}>
                    <span className="px-1 ml-n1">
                        <DisplayI18n
                            field="name"
                            i18n={documentType.i18n}
                            defaultValue={documentType.name}
                        />
                    </span>
                    <i className="text-white border-left border-light mdi mdi-close p-1 ml-1 mr-n1" />
                </Badge>
            </Fade>
        </Collapse>
    )
}

export default MemberDocumentFilters;