export default{
    "account.dashboard.accountDashboard.title"                : "Tableau de Bord",
    "account.dashboard.accountDashboard.welcome.title"        : "Bienvenue",
    "account.dashboard.accountDashboard.welcome.subtitle"     : "sur Spordle Mon Compte !",
    "account.dashboard.accountDashboard.welcome.text"         : "Vous pouvez commencer par lier un membre qui a déjà participé au sein d'Hockey Canada à votre compte. <break></break>Vous pouvez également visiter le site officiel d'inscription de Hockey Canada sur lequel vous pouvez vous <emphasis>inscrire pour jouer</emphasis> ou vous <emphasis>inscrire pour les stages</emphasis>.",
    "account.dashboard.accountDashboard.welcome.btn.register" : "Inscrire",
    "account.dashboard.accountDashboard.welcome.btn.link"     : "Lier un membre",

    "account.dashboard.accountDashboard.organizations"          : "Mes Organisations",
    "account.dashboard.accountDashboard.organizations.manage"   : "Gérer",
    "account.dashboard.accountDashboard.organizations.register" : "Inscrire",
    "account.dashboard.accountDashboard.organizations.hcr"      : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Registre de Hockey Canada",

    "account.dashboard.accountDashboard.additional_roles" : "Rôles additionnels",

    "account.dashboard.accountDashboard.members"      : "Mes Membres",
    "account.dashboard.accountDashboard.members.none" : "Aucun Membre",

    "account.dashboard.accountDashboard.addMember.title"         : "Ajouter un membre",
    "account.dashboard.accountDashboard.addMember.search.title"  : "Rechercher un membre",
    "account.dashboard.accountDashboard.addMember.link.title"    : "Lier un membre HCR",
    "account.dashboard.accountDashboard.addMember.link"          : "Lier",
    "account.dashboard.accountDashboard.addMember.toast.success" : "Membre ajouté!",

    "account.dashboard.accountDashboard.deleteMember.toast.success" : "Membre retiré!",
    "account.dashboard.accountDashboard.deleteMember.swal"          : "Vous êtes sur le point de supprimer ce membre de vos membres",

    "account.dashboard.accountDashboard.primaryEnable.toast.success"  : "Ajouté en tant qu'organisation primaire",
    "account.dashboard.accountDashboard.primaryDisable.toast.success" : "Retiré en tant qu'organisation primaire",
    "account.dashboard.accountDashboard.toast.error"                  : "Une erreur est survenue",
}