import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react'
import { Button } from 'reactstrap'
import useSWR from 'swr';
import { AccountsContext, AuthContext } from '../../../../../contexts/contexts';
import ManagePaymentModal from './managePaymentModal/ManagePaymentModal'

const UpcomingPaymentChangeCard = ({ theme, invoiceItem }) => {
    const [ changeIsOpen, setChangeIsOpen ] = useState(false);

    const accountsContext = useContext(AccountsContext);
    const auth = useContext(AuthContext);

    const { data, isValidating, mutate } = useSWR(
        [ 'getPaysafeCard', invoiceItem.invoice_payment_id ],
        () => accountsContext.getPaymentCard(invoiceItem.invoice_payment_id),
    );

    const { data: paysafeCards, isValidating: paysafeCardsLoading } = useSWR(
        [ 'vaultCards', auth.userData.Username ],
        () => accountsContext.getVaultCards(auth.userData.Username),
        { // Settings that will make this call use the one from VaultCards.js
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        },
    );

    const card = paysafeCards?.find((card) => card.paymentToken === data?.payment_token);

    return (
        !invoiceItem.provider ?
            null
            :
            <>
                <Button disabled={isValidating || paysafeCardsLoading} onClick={() => setChangeIsOpen(true)} className={`w-100 mt-1 pb-0 text-${theme}`} size="sm" color="link">
                    <Translate id='account.paymentMethods.btn.changeCard' />
                </Button>
                <ManagePaymentModal
                    invoiceItem={invoiceItem}
                    transactionType={invoiceItem.provider === "QUICK_ENROLLMENT" ? 'qe' : 'paysafe'}
                    isOpen={changeIsOpen}
                    toggle={() => setChangeIsOpen(false)}
                    cardToken={card?.paymentToken}
                    updatePaymentCard={mutate}
                />
            </>
    )
}

export default UpcomingPaymentChangeCard
