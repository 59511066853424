import React from "react";
import {
    Badge,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";

// Contexts
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import TransactionRegistrationForms from "./TransactionRegistrationForms";
import TransactionCredits from "./components/TransactionCredits";
import TransactionHeader from "./components/TransactionHeader";
import TransationRefunds from "./components/TransationRefunds";
import TransactionPaymentReceptions from "./components/TransactionPaymentReceptions";
import TransactionTotalDue from "./components/TransactionTotalDue";
import TransactionRegistrationWaivers from "./waivers/TransactionRegistrationWaivers";
import queryString from 'query-string';
import ClinicCategory from "../../../../../components/ClinicCategory";
import { withRouter } from "react-router-dom";

class TransationsSidepanelClinic extends React.Component{

    constructor(props){
        super(props);

        this.forceSignWaivers = queryString.parse(window.location.search).tab === 'waivers';

        const getInitialTab = () => {
            switch (queryString.parse(window.location.search).tab){
                case 'waivers':
                    return '3'
                case 'forms':
                    return '2'
                default:
                    return '1'
            }
        }

        this.state = {
            activeTab: getInitialTab(),
        };
    }

    toggleTabs(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentDidMount(){
        this.props.removeInvoiceNumberFromURL();
    }

    render(){
        const clinnicFeesPayments = this.props.selectedRows[0].clinicFees.flatMap((item) => item.payments);
        return (
            <>
                <div className="p-3">
                    <TransactionHeader {...this.props} hideHeader={!!this.props.hideHeader} transaction={this.props.selectedRows[0]} />
                </div>
                {!!this.props.selectedRows[0].clinic?.clinic &&
                    <>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTabs('1'); }}
                                >
                                    <Translate id='account.transactions.sidePanel.tabs.summary' />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTabs('2'); }}
                                >
                                    <Translate id='account.transactions.sidePanel.tabs.forms' />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggleTabs('3'); }}
                                >
                                    <Translate id='account.transactions.sidePanel.tabs.waivers' />
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <div className='p-3'>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {/* Total / due */}
                                    <TransactionTotalDue totalAmount={this.props.selectedRows[0].total} dueAmount={this.props.selectedRows[0].due} />

                                    {/* Refunds */}
                                    <TransationRefunds totalRefundAmount={this.props.selectedRows[0].total_refunded_amount} refunds={this.props.selectedRows[0].refunds} />

                                    {/* Payment receptions */}
                                    <TransactionPaymentReceptions paymentReceptions={this.props.selectedRows[0].payment_receptions} invoiceNumber={this.props.selectedRows[0].invoice_number} />

                                    {/* Credits */}
                                    <TransactionCredits credits={this.props.selectedRows[0].credits} />

                                    {/* CLINIC */}
                                    <div className='mb-3'>
                                        <div className="h4 font-bold"><Translate id='account.transactions.sidePanel.clinic' /></div>
                                        <Card className='card-shadow mb-3'>
                                            <CardBody>
                                                <UserDisplay className="mb-3">
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            abbr={(this.props.selectedRows[0].clinic.member?.first_name[0] + this.props.selectedRows[0].clinic.member?.last_name[0]) || ''}
                                                            src={undefined}
                                                            width={30}
                                                            height={30}
                                                            alt={this.props.selectedRows[0].clinic.member?.first_name + ' ' + this.props.selectedRows[0].clinic.member?.last_name}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{this.props.selectedRows[0].clinic.member?.first_name + ' ' + this.props.selectedRows[0].clinic.member?.last_name}</UserDisplay.Title>
                                                        <UserDisplay.Subtitle>#{this.props.selectedRows[0].clinic.member?.unique_identifier}</UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>

                                                <div className='mb-1'>
                                                    <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].clinic.clinic.name} i18n={this.props.selectedRows[0].clinic.clinic.i18n} />
                                                    <div className="small"><ClinicCategory.Builder qualification={this.props.selectedRows[0].clinic.clinic.qualification} /></div>
                                                </div>

                                                <Table size="sm" responsive className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td className='td-shrink'><Translate id='account.transactions.sidePanel.items.date' /></td>
                                                            <td className="text-center"><Translate id='misc.status' /></td>
                                                            <td><Translate id='account.transactions.sidePanel.items.amount' /></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clinnicFeesPayments.map((payment) => (
                                                            <tr key={payment.invoice_payment_id}>
                                                                <td className='td-shrink'><DateFormat value={payment.due_date} utc /></td>
                                                                <td className="text-center">
                                                                    <Badge color={payment.status === 'COMPLETED' ? 'success' : (payment.status === 'PENDING' || payment.status === 'NEED_REVIEW' || payment.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                                        <Translate id={'account.transactions.sidePanel.status.' + payment.status} />
                                                                    </Badge>
                                                                </td>
                                                                <td className="text-right"><CurrencyFormat value={parseInt(payment.amount) / 100} /></td>

                                                                {payment.status === 'COMPLETED' && payment.payment_method?.code === 'CREDITCARD' &&
                                                                    <td>
                                                                        <button type='button' className='reset-btn text-link' onClick={() => this.props.history.push('/purchases/receipt?invoiceNumber=' + this.props.selectedRows[0].invoice_number + '&paymentId=' + payment.invoice_payment_id)}>
                                                                            <i className='mdi mdi-printer font-18' />
                                                                        </button>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="2" className="text-right">
                                                                <div className="font-bold"><Translate id='account.transactions.total' /> : </div>
                                                                {/* due was confusing, the actual due amount for the whole transaction is displayed at the top */}
                                                                {/* <div className="font-medium"><Translate id='account.transactions.sidePanel.items.due'/> : </div> */}
                                                            </td>
                                                            <td className="text-right td-shrink">
                                                                <div className="text-nowrap"><CurrencyFormat value={this.props.selectedRows[0].total / 100} /></div>
                                                                {/* <div className="text-nowrap"><CurrencyFormat value={(clinnicFeesPayments.reduce((amountDue, payment) => amountDue += (payment.status !== 'COMPLETED' && payment.status !== 'REFUNDED') ? parseInt(payment.amount) : 0, 0)) / 100}/></div> */}
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </CardBody>
                                        </Card>

                                        {/* CLINIC FEES */}
                                        <div className='mb-3'>
                                            <div className="h4 font-bold"><Translate id='account.transactions.sidePanel.clinic.fee' /></div>
                                            {this.props.selectedRows[0].clinicFees?.map((item) => {
                                                return (
                                                    <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                        <CardBody>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='h6 mb-0 text-truncate'>
                                                                    <span className='font-bold'>
                                                                        <DisplayI18n
                                                                            field='name'
                                                                            i18n={item.clinic_fee.fee.i18n}
                                                                            defaultValue={item.clinic_fee.fee.name}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <TransactionRegistrationForms {...this.props} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <TransactionRegistrationWaivers {...this.props} forceSignWaivers={this.forceSignWaivers} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default withRouter(TransationsSidepanelClinic);