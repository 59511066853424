import Translate from "@spordle/intl-elements";
import { useEffect, useState } from "react";
import { Button, Fade, ModalBody, ModalHeader } from "reactstrap";
import OverlayLoader from '../loading/OverlayLoader';
import AddMemberStep1 from './addMemberSteps/AddMemberStep1';
import ConfirmMemberCreation from "./addMemberSteps/ConfirmMemberCreation";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { addBreadcrumb, Severity } from "@sentry/react";
import MemberHasUnsignedWaivers from "./addMemberSteps/MemberHasUnsignedWaivers";
import { DisplayI18n } from "../../helpers/i18nHelper";

export const VIEW_WARNING_UNSIGNED = 6;
export const VIEW_WARNING = 5;
export const VIEW_UNSIGNED = 4;

// Needs a lot of cleanup
const AddMemberModal = ({ getMetaMembers, metaMembers, isOpen, toggle, changeMember }) => {
    const initState = {
        view: 1,
        isLoading: false,
        data: null,
        warnings: [],
    }
    const [ currentState, setCurrentState ] = useState(initState);

    const toggleLoading = (bool) => setCurrentState((prev) => ({ ...prev, isLoading: bool ?? !currentState.isLoading }));
    const changeView = (view) => setCurrentState((prev) => ({ ...prev, view: view }));
    const setData = (data) => setCurrentState((prev) => ({ ...prev, data: { ...data, ...prev.data } }));
    const setWarnings = (data) => setCurrentState((prev) => ({ ...prev, warnings: data || [] }));
    const reset = () => setCurrentState(initState);

    useEffect(() => {
        if(isOpen) reset();
    }, [ isOpen ]);

    useEffect(() => {
        if(isOpen){
            addBreadcrumb({
                type: 'info',
                message: 'View changed',
                level: Severity.Log,
                category: 'AddMemberModal',
                data: {
                    view: currentState.view,
                    memberId: currentState.data?.member_id,
                },
            })
        }
    }, [ currentState.view ]);

    return (
        <AnalyticsModal
            isOpen={!!isOpen} unmountOnClose analyticsName='AddMemberModal'
            onClosed={() => {
                addBreadcrumb({ type: 'info', message: 'Add member modal closed', level: Severity.Log, category: 'AddMemberModal' });
            }}
            onOpened={() => {
                addBreadcrumb({ type: 'info', message: 'Add member modal opened', level: Severity.Log, category: 'AddMemberModal' });
            }}
        >
            <OverlayLoader isLoading={currentState.isLoading}>
                <ModalHeader toggle={toggle}><Translate id='addMemberModal.title' /></ModalHeader>
                { currentState.view === 1 &&
                    <Fade>
                        <AddMemberStep1
                            metaMembers={metaMembers}
                            closeModal={toggle}
                            isLoading={currentState.isLoading}
                            data={currentState.data}
                            setData={setData}
                            toggleLoading={toggleLoading}
                            changeView={changeView}
                        />
                    </Fade>
                }
                { currentState.view === 3 &&
                    <Fade>
                        <ConfirmMemberCreation
                            getMetaMembers={getMetaMembers}
                            closeModal={toggle}
                            isLoading={currentState.isLoading}
                            data={currentState.data}
                            setData={setData}
                            toggleLoading={toggleLoading}
                            changeView={changeView}
                            setWarnings={setWarnings}
                        />
                    </Fade>
                }
                {currentState.view === VIEW_UNSIGNED &&
                    <Fade>
                        <MemberHasUnsignedWaivers
                            data={currentState.data}
                            changeMember={changeMember}
                            closeModal={toggle}
                        />
                    </Fade>
                }
                {[ VIEW_WARNING_UNSIGNED, VIEW_WARNING ].includes(currentState.view) &&
                    <Fade>
                        <ModalBody className="text-center">
                            <i className="mdi text-danger mdi-alert-outline display-3" />
                            <div className="font-weight-bold h4 text-danger"><Translate id="misc.warning" /></div>
                            {currentState.warnings.map((warning) => (
                                <p key={warning.code + warning.origin}>
                                    <DisplayI18n
                                        field="message"
                                        i18n={warning.i18n}
                                        defaultValue={warning.message}
                                    />
                                </p>
                            ))}
                            <Button onClick={() => currentState.view === VIEW_WARNING ? toggle() : changeView(VIEW_UNSIGNED)} type="button" color="danger">
                                <Translate id="misc.gotIt" />
                            </Button>
                        </ModalBody>
                    </Fade>
                }
            </OverlayLoader>
        </AnalyticsModal>
    )
}

export default AddMemberModal;