import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Alert, Button, DropdownItem, DropdownMenu, DropdownToggle, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import HoverableComponent from "../../../../components/HoverableComponent";
import ImgUploadModal from "../../../../components/uploader/imgUpload/ImgUploadModal";
import UserImg from "../../../../components/UserImg";
import { AccountsContext, AuthContext } from "../../../../contexts/contexts";
import { copyToClipBoard } from "../../../../helpers/clipboardHelper";
import ImgDeleteConfirmModal from "./ImgDeleteConfirmModal";
import Skeleton from "react-loading-skeleton";
import StatusBadge from "../../../../components/badges/StatusBadge";
import ChangeSingleMemberAddressModal from "./ChangeSingleMemberAddressModal";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import MemberStatusBadges from "./MemberStatusBadges";
import AddContactModal from "../contacts/AddContactModal";
import InlineCopy from "../../../../components/inlineCopy/InlineCopy";

const AccountMemberHeader = ({ currentMember, handleUpdateMemberImg, refreshMetaMember, updateContactsKey }) => {
    const { deleteMetaMemberPicture, createMetaMemberPicture } = useContext(AccountsContext);
    const { userData } = useContext(AuthContext);
    const [ modalIsOpen, setModalIsOpen ] = useState({
        imgUpload: false,
        confirmImgDelete: false,
        addressChange: false,
        linkedMembers: false,
        addContact: false,
    });

    const toggleModal = (modal) => setModalIsOpen((prev) => ({ ...prev, [modal]: !prev[modal] }));

    /**
     * Deletes a meta member profile picture
     */
    const handleDeleteImg = () => {
        return handleUpdateMemberImg(
            async() => deleteMetaMemberPicture(
                userData.Username,
                currentMember?.meta_member_id,
            ),
        );
    }

    /**
     * Uploads a new member profile picture
     * @param {File} file
     * @param {string} filePos
     * @param {string} tempPath
     * @returns {promise}
     */
    const handleUpload = (file, filePos, tempPath) => {
        return handleUpdateMemberImg(
            async() => createMetaMemberPicture(
                userData.Username,
                currentMember?.meta_member_id,
                { attachment: file, file_position: filePos },
            ),
            tempPath,
            filePos,
        );
    }

    const otherAccounts = currentMember?.members[0]?.other_identities_linked?.filter((id) => !id.roles || id.roles?.length == 0 || id.identity_id === userData.Username)
    const personnelAccounts = currentMember?.members[0]?.other_identities_linked?.filter((id) => id.roles?.length > 0 && id.identity_id !== userData.Username)

    return (
        <>
            <div className="d-flex flex-column align-items-center flex-sm-row justify-content-center justify-content-sm-start">
                <div className="position-relative mb-5 mr-sm-4">
                    <UserImg
                        width={180}
                        src={currentMember?.attachment?.full_path}
                        abbr={currentMember?.first_name?.charAt(0) + currentMember?.last_name?.charAt(0) || 'HC'}
                        filePos={currentMember?.attachment?.file_position}
                        alt={currentMember?.first_name + ' ' + currentMember?.last_name}
                    />
                    {currentMember?.attachment?.full_path ?
                        <UncontrolledDropdown>
                            <DropdownToggle style={{ height: 40, width: 40 }} className="position-absolute bottom-0 right-0 rounded-circle mdi mdi-camera" />
                            <DropdownMenu>
                                <DropdownItem onClick={() => toggleModal('imgUpload')}>
                                    <Translate id='account.members.img.dropdown.upload' />
                                </DropdownItem>
                                <DropdownItem onClick={() => toggleModal('confirmImgDelete')}>
                                    <Translate id='account.members.img.dropdown.delete' />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        :
                        <Button
                            onClick={() => toggleModal('imgUpload')}
                            color="secondary"
                            className='position-absolute bottom-0 right-0 rounded-circle mdi mdi-camera'
                            style={{ height: 40, width: 40 }}
                        />
                    }
                    <ImgDeleteConfirmModal
                        member={currentMember}
                        isOpen={modalIsOpen.confirmImgDelete}
                        toggle={() => toggleModal('confirmImgDelete')}
                        onConfirm={handleDeleteImg}
                    />
                    <ImgUploadModal
                        isOpen={modalIsOpen.imgUpload}
                        toggle={() => toggleModal('imgUpload')}
                        onSave={handleUpload}
                    />
                    {modalIsOpen.linkedMembers &&
                        <AnalyticsModal isOpen={modalIsOpen.linkedMembers} analyticsName='ImgUploadModal' toggle={() => toggleModal('linkedMembers')} unmountOnClose backdrop="static">
                            <ModalHeader toggle={() => toggleModal('linkedMembers')} className="font-medium">
                                <Translate id='account.members.linked.accounts' />
                            </ModalHeader>
                            <ModalBody>
                                {(Array.isArray(otherAccounts) && otherAccounts.length > 0) &&
                                    <>
                                        {otherAccounts.map((user) => (
                                            <LinkedUserAccount user={user} userData={userData} key={user.identity_id} />
                                        ))}
                                    </>
                                }
                                {(Array.isArray(personnelAccounts) && personnelAccounts.length > 0) &&
                                    <>
                                        {personnelAccounts.map((user) => {
                                            return (
                                                <LinkedUserAccount user={user} userData={userData} key={user.identity_id} />
                                            )
                                        })}
                                    </>
                                }
                                {(!(Array.isArray(otherAccounts) && otherAccounts.length > 0) && !(Array.isArray(personnelAccounts) && personnelAccounts.length > 0)) &&
                                    <div className="text-muted"><Translate id='account.members.linked.accounts.no.linked' /></div>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="primary" outline onClick={() => toggleModal('linkedMembers')}><Translate id='misc.close' /></Button>
                            </ModalFooter>
                        </AnalyticsModal>
                    }
                </div>
                <div className='mb-5 d-flex'>
                    <div className="text-dark">
                        <div className="d-flex align-items-center">
                            {currentMember?.is_primary == "1" &&
                                <i className="mdi mdi-crown text-warning mr-1 font-22" />
                            }
                            {currentMember ?
                                <div className="d-md-flex w-100">
                                    <div className="h3 font-bold mb-0">
                                        {currentMember.first_name + ' ' + currentMember.last_name}
                                    </div>
                                    {currentMember?.members[0]?.member_status &&
                                        <div className="ml-md-1">
                                            <StatusBadge status={currentMember.members[0].member_status} />
                                        </div>
                                    }
                                </div>
                                : <Skeleton height={25} width={200} />
                            }
                        </div>
                        {!!currentMember?.members?.[0]?.unique_identifier &&
                            <InlineCopy tag='div' toCopy={currentMember.members[0].unique_identifier} className="font-medium">
                                <i className="fas fa-user text-primary mr-2" />
                                #{currentMember.members[0].unique_identifier}
                            </InlineCopy>
                        }
                        {!!currentMember?.members[0]?.organisation &&
                            <div className="font-medium">
                                <i style={{ width: 15 }} className="fas fa-users text-primary mr-2" />
                                <DisplayI18n field='name' defaultValue={currentMember.members[0].organisation.organisation_name} i18n={currentMember.members[0].organisation.i18n} />
                            </div>
                        }
                        {!!currentMember?.email &&
                            <div className="font-medium">
                                <i style={{ width: 15 }} className="fas fa-envelope text-primary mr-2" />{currentMember.email}
                            </div>
                        }
                        {!!currentMember?.phone_number &&
                            <div className="font-medium">
                                <i style={{ width: 15 }} className="fas fa-phone text-primary mr-2" />{currentMember.phone_number}
                            </div>
                        }
                        {!!currentMember?.birthdate &&
                            <div className="font-medium">
                                <i style={{ width: 15 }} className="fas fa-birthday-cake text-primary mr-2" /><DateFormat utc value={currentMember.birthdate} />
                            </div>
                        }
                        {(!!currentMember && currentMember?.members[0]) &&
                            <div>
                                <AddContactModal
                                    isOpen={modalIsOpen.addContact}
                                    toggle={() => toggleModal('addContact')}
                                    refreshTable={updateContactsKey}
                                    member={currentMember.members[0]}
                                />
                                <HoverableComponent>
                                    {(isHovering) => (
                                        <div
                                            className="font-medium clickable"
                                            onClick={() => toggleModal('addContact')}
                                        >
                                            <i style={{ width: 15 }} className='fas fa-user text-primary mr-2 text-primary' />
                                            <span className={stringBuilder({ "bg-light": isHovering })}>
                                                <span className="text-link"><Translate id='account.members.contacts.add' /></span>
                                            </span>
                                        </div>
                                    )}
                                </HoverableComponent>
                            </div>
                        }
                        {/* TODO: hardcoded */}
                        {/* Don't show the request address change if member is BLOCKED or INELIGIBLE */}
                        {currentMember?.members[0]?.member_status && currentMember.members[0].member_status.member_status_id !== '6c38a4a1-73e2-11ec-bed9-023f3d3ef136' && currentMember.members[0].member_status.member_status_id !== '8abea6d8-73e2-11ec-bed9-023f3d3ef136' &&
                            <>
                                {modalIsOpen.addressChange &&
                                    <ChangeSingleMemberAddressModal isOpen={modalIsOpen.addressChange} toggle={() => toggleModal('addressChange')} currentMember={currentMember?.members[0]} />
                                }
                                <HoverableComponent>
                                    {(isHovering) => (
                                        <div
                                            className="font-medium clickable"
                                            onClick={() => toggleModal('addressChange')}
                                        >
                                            <i style={{ width: 15 }} className='fas fa-map-marker-alt text-primary mr-2 text-primary' />
                                            <span className={stringBuilder({ "bg-light": isHovering })}>
                                                <span className="text-link"><Translate id='account.members.header.changeAddress' /></span>
                                                <i
                                                    className={stringBuilder('mdi mdi-lead-pencil text-primary clickable ml-1', { 'invisible': !isHovering })}
                                                    onClick={() => { copyToClipBoard(currentMember.members[0].unique_identifier) }}
                                                />
                                            </span>
                                        </div>
                                    )}
                                </HoverableComponent>
                            </>
                        }
                        {/* This is disabled because we do not receive the address for the moment being */}
                        {/* {!!currentMember?.address?.fullAddress &&
                            <div className="font-medium">
                                <i style={{width: 15}} className="fas fa-map-marker-alt text-primary mr-2"></i>{currentMember?.fullAddress}
                            </div>
                        } */}

                        {((Array.isArray(otherAccounts) && otherAccounts.length > 0) || (Array.isArray(personnelAccounts) && personnelAccounts.length > 0)) &&
                            <div className="font-medium d-flex align-items-center" onClick={() => toggleModal('linkedMembers')}>
                                <i style={{ width: 15 }} className="fas fa-info-circle text-primary mr-2" />
                                <span className='text-link clickable'><Translate id='account.members.linked.accounts' /></span>
                            </div>
                        }
                        {!!currentMember?.gender &&
                            <div className="font-medium">
                                <i style={{ width: 15 }} className={`fas fa-${currentMember.gender === "FEMALE" ? 'female' : 'male'} text-primary mr-2`} />{currentMember.gender}
                            </div>
                        }

                        {currentMember?.members[0]?.last_member_status_note &&
                            <div className="font-medium d-flex align-items-center">
                                <i style={{ width: 15 }} className='fas fa-comment-alt text-primary mr-2' /><span>{currentMember.members[0].last_member_status_note}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {currentMember?.members?.[0]?.need_member_status_warning == 1 &&
                <Alert color="dark" className="d-flex align-items-center">
                    <i className="mdi mdi-lock h1 mb-0 mr-3 text-muted" />
                    <div className="mb-0">
                        <div className="font-medium">
                            <Translate id={`account.members.ineligibleWarning${stringBuilder({ ".potential": currentMember?.members?.[0]?.potential_ineligible == 1 })}`} />
                        </div>
                        <div className="small">
                            <Translate id="account.members.ineligibleWarning.txt" />
                        </div>
                    </div>
                </Alert>
            }

            <MemberStatusBadges currentMember={currentMember} refreshMetaMember={refreshMetaMember} />
        </>
    );
}

export default AccountMemberHeader;

export const LinkedUserAccount = ({ user, userData }) => {
    return (
        <UserDisplay card className="w-100 mb-2">
            <UserDisplay.Container>
                <UserImg
                    abbr={user.name.substring(0, 1) + user.family_name.substring(0, 1)}
                    src={null}
                    width={40}
                    height={40}
                    alt={user.first_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="d-flex align-items-center">
                    {userData.Username === user.identity_id &&
                        <i className="mdi mdi-crown text-warning mr-1" />
                    }
                    <UserDisplay.Title className="mr-2">{user.name} {user.family_name}</UserDisplay.Title>
                </div>
                {user.roles?.length > 0 &&
                    user.roles.map((role) => (
                        <UserDisplay.Subtitle key={role.role.role_id}>
                            {role.role.title}
                            <span className="mx-2">-</span>
                            <DisplayI18n field='name' defaultValue={role.organisation.organisation_name} i18n={role.organisation.i18n} />
                        </UserDisplay.Subtitle>
                    ))
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}
