// /* eslint-disable react/no-unused-class-component-methods */
import { jsObjectToApi } from '@spordle/helpers';
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_PUBLIC_LOGGEDIN from '../api/API-Public-LoggedIn';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<SupervisionsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & SupervisionsContextProvider['state']>} */
export const SupervisionsContext = createContext();
SupervisionsContext.displayName = 'SupervisionsContext';

class SupervisionsContextProvider extends React.PureComponent{
    /**
     * [GET] Get allsupervisions of a specified member id
     * @param {object} queryParams
     * @param {string} queryParams.member_id
     * @param {string} queryParams.organisation_id
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/ec3a600a45fb5659028294854b1e607b|Documentation}
     */
    getSupervisionsList = (queryParams) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/supervisions`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.supervisions
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get a specific supervision
     * @param {string} supervisionId
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/144ac90991305fd1973d7311ca0a6986|Documentation}
     */
    getSupervision = (supervisionId) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/supervisions/${supervisionId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.supervisions
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get all supervision types
     * @param {object} queryParams
     * @param {1|0} queryParams.active
     * @param {string} queryParams.organisation_id
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervision%20Type/c6c3346c06213e8c17336f94e66d10fb|Documentation}
     */
    getSupervisionTypes = (queryParams = {}) => {
        return API_PUBLIC_LOGGEDIN.get(queryString.stringifyUrl({
            url: `/supervision-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.supervision_types
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Create a supervision
     * @param {object} values
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/5e35dc8404e25d40caca6fc94685fe22|Documentation}
     */
    createSupervision = (values = {}) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_PUBLIC_LOGGEDIN.post(queryString.stringifyUrl({
            url: `/supervisions`,
        }, {
            arrayFormat: 'comma',
        }), params)
            .then((res) => {

                if(res.data.status){
                    return res.data.supervision_id
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Send a supervision email
     * @param {object} values
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/5e35dc8404e25d40caca6fc94685fe22|Documentation}
     */
    sendSupervisionEmail = (supervisionId, values = {}) => {
        const params = new URLSearchParams(values);

        return API_PUBLIC_LOGGEDIN.post(queryString.stringifyUrl({
            url: `/supervisions/${supervisionId}/send-email`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((res) => {

                if(res.data.status){
                    return res.data;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [PUT] Update a supervision
     * @param {string} supervisionId
     * @param {object} values
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/3c7850ed660a74f86601daeebf2fa1f5|Documentation}
     */
    updateSupervision = (supervisionId, values) => {
        const params = new URLSearchParams(values);

        return API_PUBLIC_LOGGEDIN.put(queryString.stringifyUrl({
            url: `/supervisions/${supervisionId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((res) => {

                if(res.data.status){
                    return true
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [PATCH] Update a supervision
     * @param {string} supervisionId
     * @param {object} values
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/cf97b20d1c2a4ae847286a939f47b686|Documentation}
     */
    partiallyUpdateSupervision = (supervisionId, values) => {
        const params = new URLSearchParams(values);

        return API_PUBLIC_LOGGEDIN.patch(queryString.stringifyUrl({
            url: `/supervisions/${supervisionId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((res) => {

                if(res.data.status){
                    return true
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [DELETE] Delete a supervisionId
     * @param {string} supervisionId
     * @returns {Promise}
     * @throws {Object}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Supervisions/36608901fa7a22811a63115ced3d3675|Documentation}
     */
    deleteSupervision = (supervisionId) => {
        return API_PUBLIC_LOGGEDIN.delete(queryString.stringifyUrl({
            url: `/supervisions/${supervisionId}`,
        }))
            .then((res) => {
                if(res.data.status){
                    return true
                }

                throw res.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <SupervisionsContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </SupervisionsContext.Provider>
        );
    }
}

export default SupervisionsContextProvider;
