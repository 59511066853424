import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { getIcon, waiverNotSigned } from "../../../../../../helpers/waiversHelper";

const WaiverInfo = ({ waiver, answer }) => {
    return (
        <div className="d-flex align-items-center">
            {getIcon(answer)}
            <div className="ml-2 min-w-0">
                <div className="text-primary font-medium text-truncate">
                    <DisplayI18n
                        field='name'
                        defaultValue={waiver.waiver.name}
                        i18n={waiver.waiver.i18n}
                    />
                    {answer === waiverNotSigned && waiver.waiver.mandatory != "0" && <div className="text-dark small"><i className="mdi mdi-alert-outline text-danger" /> <Translate id='misc.required' /></div>}
                </div>
                <div className='text-muted small text-truncate'>
                    <span className='small'>
                        <DisplayI18n
                            field='name'
                            defaultValue={waiver.invoiceItem.organisation.organisation_name}
                            i18n={waiver.invoiceItem.organisation.i18n}
                        />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default WaiverInfo;