export default{
    "account.transactions.registrations" : "Inscription(s)",
    "account.transactions.items"         : "Article(s)",
    "account.transactions.paidBy"        : "Payé par",
    "account.transactions.total"         : "Total",
    "account.transactions.viewReceipt"   : "Voir le reçu",
    "account.transactions.due"           : "Dû",
    "account.transactions.add"           : "Ajouter un crédit",
    "account.transactions.credit"        : "Crédit",
    "account.transactions.appliedCredit" : "Crédit appliqué",

    "account.transactions.sidePanel.status.PENDING"        : "En attente",
    "account.transactions.sidePanel.status.PAID"           : "Payé",
    "account.transactions.sidePanel.status.COMPLETED"      : "Complété",
    "account.transactions.sidePanel.status.NEED_REVIEW"    : "En révision",
    "account.transactions.sidePanel.status.SUSPENDED"      : "Suspendu",
    "account.transactions.sidePanel.status.DECLINED"       : "Refusé",
    "account.transactions.sidePanel.status.PARTIALLY_PAID" : "Partiellement payé",
    "account.transactions.sidePanel.status.CANCELED"       : "Annulé",
    "account.transactions.sidePanel.status.VOID"           : "Annulé",
    "account.transactions.sidePanel.paymentType.manual"    : "Transaction manuelle",
    "account.transactions.sidePanel.paymentType.online"    : "Transaction en ligne",

    "account.transactions.sidePanel.header.invoice"            : "Facture",
    "account.transactions.sidePanel.header.initialPaymentDate" : "Date de paiement initial",
    "account.transactions.sidePanel.header.viewInvoice"        : "Voir la facture",

    "account.transactions.sidePanel.actions.refund" : "Rembourser",

    "account.transactions.sidePanel.items.payments"          : "Paiements",
    "account.transactions.sidePanel.items.date"              : "Date",
    "account.transactions.sidePanel.items.status"            : "Statut",
    "account.transactions.sidePanel.items.amount"            : "Montant",
    "account.transactions.sidePanel.items.price"             : "Prix",
    "account.transactions.sidePanel.items.due"               : "Dû",
    "account.transactions.sidePanel.items.credit"            : "Crédit",
    "account.transactions.sidePanel.items.refund"            : "Remboursement",
    "account.transactions.sidePanel.items.refunds"           : "Remboursements",
    "account.transactions.sidePanel.items.paymentReception"  : "Paiement reçu",
    "account.transactions.sidePanel.items.paymentReceptions" : "Paiements reçus",

    "account.transactions.sidePanel.clinic"            : "Clinique",
    "account.transactions.sidePanel.clinic.fee"        : "Frais de la clinique(s)",
    "account.transactions.sidePanel.clinic.fee.single" : "Frais de clinique",

    "account.transactions.sidePanel.credit.delete.message" : "Vous êtes sur le point de supprimer ce crédit.",
    "account.transactions.sidePanel.credit.updated"        : "Crédit mis à jour!",
    "account.transactions.sidePanel.credit.creationDate"   : "Date de création",
    "account.transactions.sidePanel.credit.balance"        : "Balance",

    "account.transactions.sidePanel.tabs.summary" : "Résumé",
    "account.transactions.sidePanel.tabs.forms"   : "Questionnaires",
    "account.transactions.sidePanel.tabs.waivers" : "Dispenses",

    "account.transactions.sidePanel.forms.noForms.title"         : "Aucun questionnaire",
    "account.transactions.sidePanel.forms.noForms.text"          : "Il n'y a pas de questionnaire associé à cette transaction.",
    "account.transactions.sidePanel.forms.label.min"             : "Min",
    "account.transactions.sidePanel.forms.label.max"             : "Max",
    "account.transactions.sidePanel.forms.label.between"         : "Entre",
    "account.transactions.sidePanel.forms.label.and"             : "et",
    "account.transactions.sidePanel.forms.label.deletedQuestion" : "Cette question a été supprimée",

    "account.transactions.sidePanel.waivers.title"        : "Aucune dispense",
    "account.transactions.sidePanel.waivers.text"         : "Il n'y a pas de dispense associée à cette transaction.",
    "account.transactions.sidePanel.waivers.accept"       : "J'ai lu et j'accepte la dispense",
    "account.transactions.sidePanel.waivers.accepted"     : "Acceptée par {name}",
    "account.transactions.sidePanel.waivers.declined"     : "Refusée par {name}",
    "account.transactions.sidePanel.waivers.required"     : "Cette dispense est obligatoire",
    "account.transactions.sidePanel.waivers.notMandatory" : "Cette dispense n'est pas obligatoire",

    "members.profile.waiver.tab" : "Dispenses",

    "members.profile.waiver.table.empty"            : "Aucune dispense effectuée pour ce membre pour la période en cours",
    "members.profile.waiver.table.signAll"          : "Signer toutes les dispenses obligatoires",
    "members.profile.waiver.table.column.invoice"   : "Facture",
    "members.profile.waiver.table.column.reference" : "Référence",
    "members.profile.waiver.table.column.waiver"    : "Dispense",
    "members.profile.waiver.table.column.season"    : "Saison",
    "members.profile.waiver.table.column.signed"    : "Signée",

    "members.profile.waiver.signed.by"                : "Signée par",
    "members.profile.waiver.signed.by.YES"            : "Acceptée par",
    "members.profile.waiver.signed.by.NO"             : "Refusée par",
    "members.profile.waiver.signed.not"               : "Pas signée",
    "members.profile.waiver.sidePanel.sign.label"     : "Je suis d'accord avec cette dispense",
    "members.profile.waiver.signed.apiPartner"        : "Partenaire API",
    "members.profile.waiver.signed.confirmationLabel" : "Étiquette de confirmation: ",
    "members.profile.waiver.signed.declineLabel"      : "Étiquette de déclin: ",

    "members.profile.waiver.csv.header.organisation"          : "Organisation",
    "members.profile.waiver.csv.header.signedOn"              : "Signé le",
    "members.profile.waiver.csv.header.answer.YES"            : "Oui",
    "members.profile.waiver.csv.header.answer.NO"             : "Non",
    "members.profile.waiver.csv.header.answer.NOT_SIGNED_YET" : "Pas signée",
}