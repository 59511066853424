import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Collapse,
    Card,
    CardBody,
    Spinner,
    Alert
} from 'reactstrap';

// Context Providers
import { AuthContext } from "../../contexts/contexts";
import { AuthenticatorContext } from '../../components/authentication/AuthenticatorProvider';
import withContexts from '../../helpers/withContexts';

// Language
import Translate from '@spordle/intl-elements';

// Components
import AntiSpam from '@spordle/anti-spam';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';

import HCLogo from '../../assets/images/logos/HC@2x.png';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CapsLock from '@spordle/capslock';
import PinField from 'react-pin-field';
import WithHcrTheme from '../../components/HOC/WithHcrTheme';
import FormikInputAuth from '../../components/loginInputs/FormikInputAuth';
import hcrStyles from '../../assets/scss/auth/hcr/style.module.scss';
import AuthLayout from '../../layouts/layout-components/auth/AuthLayout';
import { getLocalStorageItem } from '../../helpers/browserStorage';
import { fail, fire, success } from '@spordle/toasts';

class ForgotPassword extends React.Component{
    static contextType = AuthContext;

    componentDidMount(){
        if(!this.props.location.newPasswordRequired){
            if(!this.props.AuthenticatorContext.email)
                this.props.history.replace(this.props.AuthenticatorContext.goToPage('LOGIN', true, true));

            this.sendRecoveryEmail();
        }
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    sendRecoveryEmail = () => {
        if(!this.props.location.newPasswordRequired)
            this.props.AuthContext.sendRecoveryEmail(this.props.AuthenticatorContext.email)
                .then(() => {
                    success({ msg: 'login.form.action.send_email.success' });
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        fail({ msg: `recovery.error.${error.message}` });
                    }
                })
    }

    render(){
        return (
            <AuthLayout layout="hcr">
                <Card className="text-dark card-shadow">
                    <CardBody>
                        <div className="ml-n2 mt-n2">
                            <Link to={this.props.AuthenticatorContext.goToPage('LOGIN', true, { show: 'password' })} className="d-inline-block mb-2 text-dark"><i className="mdi mdi-chevron-left" /><Translate id='forgotPassword.action.backToLogin' /></Link>
                        </div>
                        <div className="text-center">
                            <img className="mb-2" src={HCLogo} width="90px" alt="Hockey Canada" />
                            <h1 className="h4 font-bold mb-4"><Translate id='forgotPassword.title.accountRecovery' /></h1>
                        </div>
                        <Formik
                            initialValues={{
                                code: '',
                                recoveryPassword: '',
                                confirmPassword: '',
                            }}
                            validationSchema={Yup.object().shape({
                                recoveryPassword: Yup.string().cognitoPassword(),
                                confirmPassword: Yup.string()
                                    .test('recoveryPassword-match', <Translate id='recovery.validation.passwordConfirm.match' />, function(value){
                                        return this.parent.recoveryPassword === value;
                                    }),
                            })}
                            onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                                setStatus();

                                if(this.props.location.newPasswordRequired){
                                    this.props.AuthenticatorContext.challengeLogin(values.recoveryPassword, getLocalStorageItem('platformLogin'))
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                setFieldError('recoveryPassword', <Translate id={`login.error.${error.message}`} />);
                                                setSubmitting(false);
                                            }
                                        });
                                }else{
                                    this.props.AuthContext.resetPassword(this.props.AuthenticatorContext.email, values.recoveryPassword, values.code)
                                        .then(() => {
                                            this.props.AuthenticatorContext.goToPage('LOGIN', false, { show: 'password' })
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                setStatus(error.message);
                                                setSubmitting(false);
                                            }
                                        });
                                }
                            }}
                        >
                            {(formik) => (
                                <Form id="recoveryForm">
                                    {!this.props.location.newPasswordRequired &&
                                        <>
                                            <div className="d-flex px-4 align-items-center mb-4">
                                                <i className="mdi mdi-email-outline font-22 mr-3" />
                                                <div className="mb-0">
                                                    <Translate id='forgotPassword.label.enterCode' /> <a href={`mailto: ${this.props.AuthenticatorContext.email}`} target="_blank" rel="noreferrer">{this.props.AuthenticatorContext.email}</a>
                                                    <div>
                                                        <AntiSpam
                                                            timeoutDuration={5000}
                                                            clicksForTimeout={2}
                                                            resetClicksDuration={10000}
                                                            isSpamming={() => {
                                                                fire({ theme: 'warning', msg: 'login.toast.warning.spam' });
                                                            }}
                                                        >
                                                            {(_, antiSpamCallback) => (
                                                                <button
                                                                    type="button" className="reset-btn text-link" onClick={(e) => {
                                                                        antiSpamCallback(e);
                                                                        this.sendRecoveryEmail();
                                                                    }}
                                                                >
                                                                    <Translate id='forgotPassword.action.resendCode' />
                                                                </button>
                                                            )}
                                                        </AntiSpam>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-n2 d-flex'>
                                                <PinField
                                                    autoFocus className="min-w-0 form-control mx-2 text-center" validate="0123456789"
                                                    disabled={formik.isSubmitting} length={6}
                                                    onChange={(code) => {
                                                        formik.setFieldValue('code', code)
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                    <Collapse isOpen={formik.values.code.length === 6 || !!this.props.location.newPasswordRequired}>
                                        <FormikInputAuth id={'recoveryPassword'} name={'recoveryPassword'} label={'forgotPassword.label.enterNewPassword'} type={'password'} icon={'ti-lock'} trim />
                                        <FormikInputAuth id={'confirmPassword'} name={'confirmPassword'} label={'forgotPassword.label.confirmNewPassword'} type={'password'} icon={'ti-lock'} trim />
                                        <Button className={`py-3 ${hcrStyles['btn-hcr']}`} block type='submit' disabled={formik.isSubmitting}>
                                            {formik.isSubmitting ?
                                                <Spinner size="sm" type='grow' color='light' />
                                                :
                                                <>
                                                    <Translate id='forgotPassword.action.confirmNewPassword' />
                                                    <i className="ti-arrow-right ml-2 align-middle" />
                                                </>
                                            }
                                        </Button>
                                    </Collapse>

                                    <CapsLock>
                                        {(isActive) => (
                                            <Collapse isOpen={isActive}>
                                                <Alert color='warning' className='mb-0'><i className="mr-1 mdi mdi-apple-keyboard-caps" /><Translate id='warning.capsLock' /></Alert>
                                            </Collapse>
                                        )}
                                    </CapsLock>
                                    {formik.status &&
                                        <Alert className='mb-0 mt-3'><Translate id={`recovery.error.${formik.status}`} /></Alert>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </AuthLayout>
        );
    }
}


export default WithHcrTheme(withContexts(AuthContext, AuthenticatorContext)(ForgotPassword));
