import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { AuthContext } from "../contexts/contexts";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <AuthContext.Consumer>
            {({ accessToken, userData }) => (
                <Route
                    {...rest} render={(props) => {
                        if(accessToken && userData){
                            return <Component {...props} />
                        }
                        const referrer = queryString.stringifyUrl({
                            url: props.location.pathname,
                            query: queryString.parse(props.location.search),
                        });
                        const buildSearch = queryString.stringify({
                            referrer: referrer !== '/' ? referrer : undefined,
                        })
                        return <Redirect to={{ pathname: '/auth/SPORDLE', search: buildSearch ? `?${buildSearch}` : undefined, state: { from: props.location } }} />


                    }}
                />
            )}
        </AuthContext.Consumer>
    );
}

export default PrivateRoute;