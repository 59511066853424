import PropTypes from 'prop-types';
import UserImg from '../../UserImg';
import { FormatHour } from '../components/helpers';

/**
 * @description Single Event List Item.
 *
 * @param {Object} props
 * @param {object} props.eventData An object containing all of the event's data.
 * @param {string} [props.itemType] Type of event item. Changes look.
 *
 */
const EventItem = ({
    eventData,
    momentObj,
    itemType = 'daily',
    ...props
}) => {
    const getLocation = (type, name, link) => {
        let icon = "mr-1 mdi mdi-";

        switch (type){
            case 'ONLINE_LIVE':
                icon += "access-point";
                break;
            case 'ONLINE':
                icon += 'play'
                break;
            case 'ONSITE':
            default:
                icon += "map-marker"
                break;
        }

        return link ? <a href={link} target="_blank" rel="noopener noreferrer"><i className={icon} />{name}</a> : <span className="text-body"><i className={icon} />{name}</span>
    }

    return (
        <div className={`event-item event-item-${itemType}`} {...props}>
            <div className={`event-item-inner`}>
                <div className="event-item-info">
                    <span className="event-item-type text-capitalize">
                        { eventData.eventType }
                    </span>
                    { eventData.eventType !== "payment" &&
                        <span className="event-item-time">
                            <FormatHour
                                date={eventData.date.format('YYYY-MM-DD')}
                                start={eventData.startTime}
                                end={eventData.endTime}
                                startClassName="event-item-time-start"
                                endClassName="event-item-time-end"
                            />
                            {eventData.timeZoneAbr && ` (${eventData.timeZoneAbr})`}
                        </span>
                    }
                </div>
                <h4 className={`event-item-name`}>
                    {eventData.title}
                </h4>
                { eventData.location &&
                    <div className="event-item-location text-truncate">
                        {getLocation(eventData.session_format, eventData.location, eventData.locationLink)}
                    </div>
                }
                { eventData.members?.length > 0 &&
                    <div className="event-item-members">
                        {eventData.members.map((member, i) => (
                            ((itemType == 'weekly' && i <= 1) || (itemType != 'weekly' && i <= 3)) &&
                                <div key={member.member_id} className={`event-item-member-container${itemType !== 'weekly' ? ' is-hoverable' : ''}`}>
                                    <UserImg
                                        className="event-item-member-pill"
                                        alt={member.first_name + ' ' + member.last_name}
                                        abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                        filePos={member.attachment?.file_position}
                                        src={member.attachment?.full_path}
                                        width={30}
                                    />
                                    <div className="event-item-member-info" style={{ lineHeight: 1 }}>
                                        <div>{member.first_name}</div>
                                        <div>{member.last_name}</div>
                                    </div>
                                </div>
                        ))}
                        {(eventData.members.length > (itemType == "weekly" ? 2 : 3)) &&
                            <div className={`event-item-member-container${itemType !== 'weekly' ? ' is-hoverable' : ''}`}>
                                <UserImg
                                    className="event-item-member-pill bg-primary text-white"
                                    alt={'other members'}
                                    abbr={`+${eventData.members.length - (itemType == "weekly" ? 2 : 3)}`}
                                    width={30}
                                />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default EventItem;

EventItem.propTypes = {
    eventData: PropTypes.object.isRequired,
    itemType: PropTypes.string,
};
EventItem.defaultProps = {
    itemType: 'daily',
};