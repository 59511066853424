import Translate from "@spordle/intl-elements";
import { Button, ButtonGroup } from "reactstrap";

const PaymentFilter = ({ activeFilter, setFilter }) => {
    return (
        <ButtonGroup>
            <Button id='payments-filters-ALL' color="primary" type="button" outline active={activeFilter === 'ALL'} onClick={() => { setFilter('ALL') }}>
                <Translate id='account.paymentMethods.btn.allTransactions' />
            </Button>
            <Button id='payments-filters-PENDINGS' color="primary" type="button" outline active={activeFilter === 'PENDINGS'} onClick={() => { setFilter(true) }}>
                <Translate id='account.paymentMethods.btn.pendingTransactions' />
            </Button>
            <Button id='payments-filters-COMPLETED' color="primary" type="button" outline active={activeFilter === 'COMPLETED'} onClick={() => { setFilter(false) }}>
                <Translate id='account.paymentMethods.btn.pastTransactions' />
            </Button>
        </ButtonGroup>
    )
}

export default PaymentFilter;