import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { AccountsContext } from "../../../contexts/contexts";
import CrossFade from "../../../views/account/members/gameIncidentReports/components/CrossFade";
import ChangeAddressModal from "../../../views/account/settings/profile/components/ChangeAddressModal";
import OverlayLoader from "../../loading/OverlayLoader";
import UserDisplay from "../../userDisplay/UserDisplay";

const AddressModalPopUp = ({ address }) => {
    const [ isOpen, setOpen ] = useState(true);
    const toggleModal = () => { setOpen(!isOpen) };

    return (
        <AnalyticsModal analyticsName='AccountAddressPopUp' isOpen={isOpen}>
            <AddressModalInner toggleModal={toggleModal} address={address} />
        </AnalyticsModal>
    )
};

const AddressModalInner = ({ toggleModal, address }) => {
    const [ showchangeAddress, setShowchangeAddress ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const { validateCurrentAddress } = useContext(AccountsContext);
    const needAddress = address.needAddress;

    function getAddressString(){
        if(address){
            return `${address.unit_number ? `${address.unit_number}-` : ''}${address.street_number} ${address.street}, ${address.city}, ${address.province_code}, ${address.country_code}, ${address.zip_code}`
        }
        return '';
    }

    return (
        <>
            <CrossFade isVisible={!showchangeAddress}>
                <OverlayLoader isLoading={isLoading}>
                    <ModalHeader>
                        {needAddress ?
                            <Translate id='account.settings.profile.accountAddress.popUp.newAddress.title' />
                            :
                            <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.title' />
                        }
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="mdi mdi-alert-circle-outline text-primary" style={{ fontSize: 75 }} />

                        {needAddress ?
                            <>
                                <p className="font-bold h5">
                                    <Translate id='account.settings.profile.accountAddress.popUp.newAddress.description0' />
                                </p>
                                <p>
                                    {/* <Translate id='account.settings.profile.accountAddress.popUp.newAddress.description1' />
                                    <br /> */}
                                    <Translate id='account.settings.profile.accountAddress.popUp.newAddress.description2' />
                                </p>
                            </>
                            :
                            <>
                                <p className="font-bold">
                                    <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.description1' />
                                </p>
                                <p>
                                    <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.description2' />
                                    <span className="d-block font-medium">
                                        {getAddressString()}
                                    </span>
                                </p>

                                <UserDisplay
                                    card block hover
                                    className="mb-2"
                                    onClick={() => {
                                        setIsLoading(true)
                                        validateCurrentAddress(address.identity_address_id)
                                            .then(toggleModal)
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message);
                                                    setIsLoading(false);
                                                }
                                            })
                                    }}
                                >
                                    <UserDisplay.Container>
                                        <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                            <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                            <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-check-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-success' />
                                        </div>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title className='font-bold'>
                                            <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.valid.title' />
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.valid.subtitle' />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <UserDisplay
                                    card block hover
                                    className="mb-0"
                                    onClick={() => { setShowchangeAddress(true) }}
                                >
                                    <UserDisplay.Container>
                                        <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                            <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                            <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-close-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-danger' />
                                        </div>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title className='font-bold'>
                                            <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.title' />
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Translate id='account.settings.profile.accountAddress.popUp.verifyAddress.changeAddress.subtitle' />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </>
                        }
                    </ModalBody>
                    {needAddress &&
                        <ModalFooter>
                            <Button color='primary' type='button' onClick={() => { setShowchangeAddress(true) }}>
                                <i className="mdi mdi-plus mr-1" />
                                <Translate id='account.settings.profile.accountAddress.popUp.newAddress.title' />
                            </Button>
                        </ModalFooter>
                    }
                </OverlayLoader>
            </CrossFade>
            <CrossFade isVisible={showchangeAddress}>
                <ChangeAddressModal toggleModal={toggleModal} required />
            </CrossFade>
        </>
    )
};

export default AddressModalPopUp;
