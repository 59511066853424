import AuthController from '../views/AuthController';
import BundledDevRoutes from './routeBundles/BundledDevRoutes';

const routes = [
    { path: '/auth/:platform', component: AuthController },

    { path: '/home', bundleComponentKey: 'home', devOnly: true, bundleRoute: BundledDevRoutes },
    { path: '/test-api', inProduction: false, bundleComponentKey: 'api-tester', bundleRoute: BundledDevRoutes },
    { path: '/review', inProduction: false, bundleComponentKey: 'i18n-review', bundleRoute: BundledDevRoutes },

    { path: '/login', dynamicComponent: 'login', exact: true },
    { path: '/signup', dynamicComponent: 'signup', exact: true },
    { path: '/signup-confirmation', dynamicComponent: 'confirmation', exact: true },
    { path: '/account-recovery', dynamicComponent: 'recovery', exact: true },
    { path: '/forgot-password', dynamicComponent: 'forgot-password', exact: true },
    { path: '/terms', dynamicComponent: 'terms', exact: true },

];
export default routes;