/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {addMethod,string,object}from'yup';import {isValidPhoneNumber}from'../src/phoneUtils.js';/**
 * Function that sets custom yup validations
 *
 * Validations added:
 * * yup.string().isValidPhoneNumber()
 * * yup.object().address()
 */
function setupCustomValidation() {
    addMethod(string, 'isValidPhoneNumber', function (message) {
        return this.test({
            name: 'isValidPhoneNumber-test',
            message: message,
            test: async function (value) {
                if (!value) // When no value -> isValid. If it needs to be required, user will use string().required()
                    return true;
                return await isValidPhoneNumber(value);
            },
        });
    });
    addMethod(object, 'address', function (required, requiredMessages) {
        return this.shape({
            streetNumber: required ? string().required(requiredMessages?.streetNumber) : string(),
            address: required ? string().required(requiredMessages?.address) : string(),
            city: required ? string().required(requiredMessages?.city) : string(),
            state: required ? string().required(requiredMessages?.state) : string(),
            country: required ? string().required(requiredMessages?.country) : string(),
            zipCode: required ? string().required(requiredMessages?.zipCode) : string(),
            fullAddress: string(),
            origin: string(),
            mapsUrl: string(),
        });
    });
}export{setupCustomValidation};
/*
@Copyrights Spordle 2023 - All rights reserved
*/