import moment from "moment";
import { useContext } from "react";
import useSWR from "swr";
import { AccountsStatusContext } from "../../contexts/AccountsStatusContext";
import { AccountsContext, AuthContext } from "../../contexts/contexts";
import AddressModalPopUp from "./modals/AddressModalPopUp";
import DeclinedPaymentModal from "./modals/DeclinedPaymentModal";
import MissingInformationModal from "./modals/MissingInformationModal";
import PreinitModal from "./modals/PreinitModal";
import UnansweredFormsModal from "./modals/UnansweredFormsModal";
import UnsignedWaiverModal from "./modals/UnsignedWaiverModal";
import WaitingListItemsModal from "./modals/WaitingListItemsModal";

const NotificationPopups = () => {
    const accountsStatusContext = useContext(AccountsStatusContext)
    const { getAccountAddresses, getIdentityMetaMembers } = useContext(AccountsContext)
    const auth = useContext(AuthContext);

    const initialStatusObject = {
        address: null,
        failedPayment: null,
        preInit: null,
        missingInformation: null,
        waitingListItems: null,
        unansweredForms: null,
    }

    /**
     * Tries to find the promise returning a resource
     * @param {Array} promises Promises from Promise.all
     * @param {number} offset The offset to add to the returned index of the resource
     * @returns {Object|null} Found resource or null
     */
    const findResourceIndex = (promises, offset) => {
        for(let i = 0; i < promises.length; i++){
            // status is an array and has at least 1 element (example: the first promise for failed payments returns an array of 1)
            const resource = promises[i]?.[0]
            if(resource){
                return {
                    type: getResourceType(i + offset),
                    resource: resource,
                }
            }
        }
        return null // went through all the promises, did not find anything
    }

    const getResourceType = (index) => {
        switch (index){
            case 0:
                return 'address';
            case 1:
                return 'failedPayment';
            case 2:
                return 'waitingListItems';
            case 3:
                return 'preInit';
            case 4:
                return 'missingInformation';
            case 5:
                return 'unansweredForms';
        }
    }

    const formatWaitingListItems = (waitingListItems) => {
        return waitingListItems.filter((item) => item.status === 'WAITING_FOR_CLIENT')
    }

    const formatAccountAddresses = (accountAddresses) => {
        if(!accountAddresses.length){
            return [ {
                needAddress: true,
            } ];
        }
        const currentAddress = accountAddresses[0];
        // Last edited / created more than 1 year ago
        if(currentAddress && moment().diff(moment(currentAddress.updated_at), 'years', true) > 1){
            return [ currentAddress ];
        }
    }

    const formatAccountUnansweredForms = (unansweredForms) => {
        if(unansweredForms.length){
            return [ unansweredForms ];
        }
    }

    const formatMetaMembers = (metaMembers) => {
        const missingInfo = metaMembers.reduce((newArray, metaMember) => {
            const missingFields = metaMember.members?.[0]?.missing_required_fields?.length > 0;
            const missingDocuments = metaMember.members?.[0]?.need_address_document == 1;
            const unsignedWaivers = metaMember.members?.[0]?.unsigned_critical_waivers == 1;

            if(missingFields || missingDocuments || unsignedWaivers){
                newArray.push(metaMember)
            }
            return newArray
        }, [])

        if(missingInfo.length)
            return [ missingInfo ]
    }

    const { data } = useSWR(
        [ 'accountStatus', auth.userData.Username ],
        () => Promise.all([
            getAccountAddresses().then(formatAccountAddresses),
            accountsStatusContext.getFailedPaymentStatus(auth.userData.Username),
            accountsStatusContext.getWaitingListNotifications(auth.userData.Username).then(formatWaitingListItems),
            accountsStatusContext.getPreInitStatus(auth.userData.Username),
        ])
            .then(async(firstGroupPromises) => {
                let offset = 0;
                let foundResourceIndex = findResourceIndex(firstGroupPromises, offset)

                // START SECOND GROUP LOGIC
                if(!foundResourceIndex){
                    // if we haven't found a resource in the first group, Promise.all() on the second one
                    const secondGroupPromises = await Promise.all([
                        getIdentityMetaMembers(auth.userData.Username, {}, true).then((res) => formatMetaMembers(res.meta_members)),
                        // accountsStatusContext.getUnsignedWaiverStatus(auth.userData.Username).then(formatUnsignedWaivers),
                        accountsStatusContext.getUnansweredForms(auth.userData.Username, { mandatory: 1 }).then(formatAccountUnansweredForms),
                    ])

                    // update offset for the switch in getResourceType
                    offset += firstGroupPromises.length;
                    foundResourceIndex = findResourceIndex(secondGroupPromises, offset) // try to find a resource within the new promises
                }
                // END SECOND GROUP LOGIC

                // keep going with 3rd group if we need one

                const returnObject = initialStatusObject;
                if(foundResourceIndex){
                    returnObject[foundResourceIndex.type] = foundResourceIndex.resource
                }
                return returnObject;
            })
        ,
    );

    if(accountsStatusContext.showPopups && data){
        if(data.address)
            return <AddressModalPopUp address={data.address} />
        else if(data.failedPayment)
            return <DeclinedPaymentModal />
        else if(data.waitingListItems)
            return <WaitingListItemsModal waitingListItem={data.waitingListItems} />
        else if(data.preInit)
            return <PreinitModal invoice={data.preInit} />
        else if(data.missingInformation)
            return <MissingInformationModal metaMembers={data.missingInformation} />
        else if(data.unsignedWaiver)
            return <UnsignedWaiverModal unsignedWaiver={data.unsignedWaiver} />
        else if(data.unansweredForms)
            return <UnansweredFormsModal unansweredForms={data.unansweredForms} />
    }
    return null;
}

export default NotificationPopups;