import React, { createContext } from 'react';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';
import API_PUBLIC from '../api/API-Public';

/** @type {React.Context<Omit<OnlineStoreContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const OnlineStoreContext = createContext();
OnlineStoreContext.displayName = 'OnlineStoreContext';

class OnlineStoreContextProvider extends React.Component{
    constructor(props){
        super(props);

        /**
         * @private
         */
        this._initState = {
            hasDeclinedPayment: false,
        }

        this.state = this._initState;
    }

    /**
     * Gets all online-store invoices
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStorePurchases%7Cdocumentation%7D}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreInvoices = (identityId, queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/invoices`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.invoices;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            <OnlineStoreContext.Provider value={{ ...this }}>
                {this.props.children}
            </OnlineStoreContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(OnlineStoreContextProvider);