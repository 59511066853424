export default{
    "account.dashboard.accountDashboard.title"                : "Dashboard",
    "account.dashboard.accountDashboard.welcome.title"        : "Welcome",
    "account.dashboard.accountDashboard.welcome.subtitle"     : "to Spordle MyAccount!",
    "account.dashboard.accountDashboard.welcome.text"         : "You can start by linking a member to your account that has previously participated in Hockey Canada.<break></break>You can also visit the official Hockey Canada Registration site where you can <emphasis>Register to play</emphasis> or <emphasis>Register for clinics</emphasis>.",
    "account.dashboard.accountDashboard.welcome.btn.register" : "Register",
    "account.dashboard.accountDashboard.welcome.btn.link"     : "Link a member",

    "account.dashboard.accountDashboard.organizations"          : "My Organizations",
    "account.dashboard.accountDashboard.organizations.manage"   : "Manage",
    "account.dashboard.accountDashboard.organizations.register" : "Register",
    "account.dashboard.accountDashboard.organizations.hcr"      : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Hockey Canada Registry",

    "account.dashboard.accountDashboard.additional_roles" : "Additional Roles",

    "account.dashboard.accountDashboard.members"      : "My Members",
    "account.dashboard.accountDashboard.members.none" : "No Membres",

    "account.dashboard.accountDashboard.addMember.title"         : "Add a member",
    "account.dashboard.accountDashboard.addMember.search.title"  : "Search a Member",
    "account.dashboard.accountDashboard.addMember.link.title"    : "Link an HCR Member",
    "account.dashboard.accountDashboard.addMember.link"          : "Link",
    "account.dashboard.accountDashboard.addMember.toast.success" : "Member Added!",

    "account.dashboard.accountDashboard.deleteMember.toast.success" : "Member removed!",
    "account.dashboard.accountDashboard.deleteMember.swal"          : "You are about to delete this member from your members",

    "account.dashboard.accountDashboard.primaryEnable.toast.success"  : "Set as primary",
    "account.dashboard.accountDashboard.primaryDisable.toast.success" : "Removed as primary",
    "account.dashboard.accountDashboard.toast.error"                  : "An error occurred",
}