export const spordleApps = [
    {
        name: "Spordle",
        apps: [

        ],

    },
];

export const classPrefix = "spdl-header-apps";