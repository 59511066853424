import { Alert, Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import Translate from '@spordle/intl-elements';
import { mixed, object, string } from 'yup';
import { AccountsContext, AuthContext } from '../../../../contexts/contexts';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { isMoment } from 'moment';
import { useContext, useState } from 'react';
import { FormikDateTime, FormikInputText, FormikSelect, FormikError, getFormikAddressInitialValues, FormikAddress } from '@spordle/formik-elements';
import ReactTooltip from 'react-tooltip';
import CrossFade from '../gameIncidentReports/components/CrossFade';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import FileUpload from '../../../../components/uploader/FileUpload';
import { MembersContext } from '../../../../contexts/MembersContext';
import Required from '../../../../components/formik/Required';
import useSWR from 'swr';
import { mbToBytes } from '../../../../components/uploader/uploadHelpers';

const ChangeSingleMemberAddressModal = ({ toggle, isOpen, currentMember }) => {
    const { publishAddressToMember, getAddressTypes } = useContext(AccountsContext);
    const { createMemberAttachments, getDocumentTypes } = useContext(MembersContext);
    const { federation, getOrganizationSettings } = useContext(OrganizationContext);
    const { userData } = useContext(AuthContext);
    const [ currentStep, setCurrentStep ] = useState(1);

    // TODO: REFACTOR
    const { data: documents, isValidating } = useSWR(
        [ 'getOrganizationSettings', 'getDocumentTypes', currentMember.organisation.organisation_id ],
        () => Promise.all([
            getOrganizationSettings(currentMember.organisation.organisation_id),
            getDocumentTypes(currentMember.organisation.organisation_id),
        ]).then(([ orgSettings, documentTypes ]) => {
            return orgSettings.attachment_for_new_member_in_registration.value.map((documentTypeId) => (
                documentTypes.find(({ document_type_id }) => document_type_id === documentTypeId)
            )).filter((document) => !!document);
        }),
    )

    return (
        <AnalyticsModal analyticsName='ChangeSingleMemberAddressModal' isOpen={isOpen} toggle={toggle}>
            <CrossFade isVisible={currentStep === 1}>
                <Formik
                    initialValues={{
                        address: getFormikAddressInitialValues(),
                        address2: '',
                        addressTypeId: '',
                        yearsSameAddress: '',
                        documents: {},
                    }}
                    validationSchema={object().shape({
                        address: object().address(true, {
                            streetNumber: <Translate id='form.validation.streetNumber.required' />,
                            address: <Translate id='form.validation.address.required' />,
                            city: <Translate id='form.validation.city.required' />,
                            zipCode: <Translate id='form.validation.zip.required' />,
                            state: <Translate id='form.validation.province.required' />,
                            country: <Translate id='form.validation.country.required' />,
                        }),
                        addressTypeId: string().required(<Translate id='account.settings.profile.accountAddress.validation.addressType.required' />),
                        yearsSameAddress: mixed()
                            .required(<Translate id='account.settings.profile.accountAddress.validation.yearsSameAddress.required' />)
                            .isDate(<Translate id='form.validation.date.format' />)
                            .test({
                                name: 'isBefore',
                                message: <Translate id='account.settings.profile.accountAddress.validation.yearsSameAddress.isBefore' />,
                                test: (date) => {
                                    if(isMoment(date)){
                                        return date.year() <= new Date().getFullYear();
                                    }
                                    return true;
                                },
                            }),
                    })}
                    onSubmit={(values, { setStatus }) => {
                        setStatus();
                        const apiValues = {
                            street_number: values.address.streetNumber,
                            street: values.address.address,
                            city: values.address.city,
                            zip_code: values.address.zipCode,
                            province_code: values.address.state,
                            country_code: values.address.country,
                            years_same_address: values.yearsSameAddress.year(),
                            member_ids: currentMember.member_id,
                            address_type_id: values.addressTypeId,
                            unit_number: values.address2,
                            origin_address: values.address.origin,
                        }
                        return publishAddressToMember(userData.Username, apiValues)
                            .then(async() => {
                                await Promise.all(
                                    Object.keys(values.documents).reduce((mappedDocuments, documentTypeId) => {
                                        const promisifiedDocument = values.documents[documentTypeId].map((document) => (
                                            createMemberAttachments(currentMember.member_id, { attachment: document, document_type_id: documentTypeId })
                                        ))
                                        mappedDocuments.pushArray(promisifiedDocument);
                                        return mappedDocuments;
                                    }, []),
                                )
                                setCurrentStep(2);
                                setStatus('success');
                            })
                            .catch((e) => {
                                console.error(e);
                                setStatus(e.message)
                            })

                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={isValidating || formik.isSubmitting}>
                                <ModalHeader toggle={toggle}>
                                    <Translate id='account.settings.profile.accountAddress.modalTitle' />
                                </ModalHeader>
                                <ModalBody>
                                    <FormikAddress
                                        id='address' name='address'
                                        required
                                        allowManualPlace
                                        label='account.settings.profile.accountAddress.label'
                                    />
                                    <FormGroup>
                                        <Label for="address2"><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                        <FormikInputText id='address2' name='address2' />
                                    </FormGroup>
                                    <Row>
                                        <Col xs='12' sm='6'>
                                            <FormGroup>
                                                <Label for='yearsSameAddress'>
                                                    <ReactTooltip id="move-in" effect="solid" getContent={(tip) => tip && <Translate id={tip} />} />
                                                    <Translate id='account.settings.profile.accountAddress.step2.moveIn.label' />
                                                    <i className='mx-1 text-primary mdi mdi-information-outline' data-tip='account.settings.profile.accountAddress.step2.moveIn.tip' data-for='move-in' />
                                                    <Required />
                                                </Label>
                                                <FormikDateTime
                                                    id='yearsSameAddress'
                                                    name='yearsSameAddress'
                                                    timeFormat={false}
                                                    dateFormat='YYYY'
                                                    initialViewMode='years'
                                                    isValidDate={(date) => date.year() <= new Date().getFullYear()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs='12' sm='6'>
                                            <FormGroup>
                                                <Label for='addressTypeId'>
                                                    <Translate id='account.settings.profile.accountAddress.step2.addressType.label' /> <Required />
                                                </Label>
                                                <FormikSelect
                                                    id='addressTypeId'
                                                    name='addressTypeId'
                                                    renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return getAddressTypes(federation.organisation_id)
                                                                    .then((addressTypes) => addressTypes.map((addressType) => ({
                                                                        value: addressType.address_type_id,
                                                                        label: addressType.name,
                                                                        i18n: addressType.i18n,
                                                                    })));
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {documents?.map((documentType) => (
                                            <Col xs='12' key={documentType.document_type_id}>
                                                <FormGroup>
                                                    <Label for={'documentType_' + documentType.document_type_id}><DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} /></Label>
                                                    <FileUpload
                                                        id={'documentType_' + documentType.document_type_id}
                                                        dropzoneProps={{
                                                            multiple: true,
                                                            maxSize: documentType.file_size ? mbToBytes(parseFloat(documentType.file_size)) : undefined,
                                                            accept: ".jpg, .jpeg, .png, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                        }}
                                                        onFileSelect={(files) => {
                                                            formik.setFieldValue(`documents.${documentType.document_type_id}`, files);
                                                        }}
                                                    />
                                                    <FormikError name={`documents.${documentType.document_type_id}`} />
                                                    {documentType.description &&
                                                        <div className='small text-muted'>
                                                            <DisplayI18n field='description' defaultValue={documentType.description} i18n={documentType.i18n} />
                                                        </div>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Collapse isOpen={!!formik.status} unmountOnExit mountOnEnter>
                                        <Alert color='danger' className='mb-0'>{formik.status}</Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='account.settings.profile.accountAddress.step2.title' /></Button>
                                    <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={toggle}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </CrossFade>
            <CrossFade isVisible={currentStep == 2} unmountOnExit mountOnEnter>
                <ModalHeader toggle={toggle}>
                    <Translate id='account.members.changeSingleAddress.modal.success.title' />
                </ModalHeader>
                <ModalBody className='text-center'>
                    <CustomAnimatedIcon icon='checkmark' withCircle className='text-success mb-2' size={50} />
                    <div className='text-dark font-bold h4'><Translate id='account.members.changeSingleAddress.modal.success' /></div>
                    <div className='text-dark h6'><Translate id='account.members.changeSingleAddress.modal.success.subText' /></div>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' type='button' onClick={toggle}><Translate id='misc.close' /></Button>
                </ModalFooter>
            </CrossFade>
        </AnalyticsModal>
    );
};

export default ChangeSingleMemberAddressModal;
