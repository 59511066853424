export default{
    "account.settings.profile.accountEmail.emailNotVerified" : "Votre courriel n'est pas vérifié",

    "account.settings.profile.accountEmail.email.current.notVerified" : "Votre courriel actuel n'est pas vérifié",
    "account.settings.profile.accountEmail.email.current.change"      : "Changer de courriel",
    "account.settings.profile.accountEmail.email.current.verify"      : "Vérifier",

    "account.settings.profile.accountEmail.email.toast.success" : "Courriel envoyé!",
    "account.settings.profile.accountEmail.email.toast.fail"    : "Le courriel n'a pas pu être envoyé",

    "account.settings.profile.accountEmail.code.toast.success" : "Votre compte a été mis à jour!",
    "account.settings.profile.accountEmail.code.toast.fail"    : "Une erreur est survenue. Veuillez rafraichir la page.",
}