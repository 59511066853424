export default{
    // headings
    "login.heading.title" : (process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Hockey Canada Registry",
    "login.heading.intro" : `Welcome to the new ${(process.env.REACT_APP_VERSION_CLIENT === "EIHA" && process.env.REACT_APP_ENVIRONMENT === "prod") ? "English Ice Hockey Association" : "Hockey Canada Registry"} homepage, the central hub of participant registration and national affiliation management platform for all levels and activities (clinics, training sessions, certifications) of minor hockey across the country.`,
    "login.heading.cta"   : "Learn More",

    // login card
    "login.card.signIn"     : "Sign in to your account",
    "login.card.welcome"    : "Welcome",
    "login.card.poweredBy"  : "Powered by",
    "login.card.changeUser" : "Change user",

    // login modal
    "login.modal.text"     : "This account does not exist",
    "login.modal.tryAgain" : "Try again",

    // Login form
    "login.form.label.title"                  : "Login",
    "login.form.label.email"                  : "Email",
    "login.form.label.password"               : "Password",
    "login.form.label.rememberMe"             : "Remember me",
    "login.form.action.submit"                : "Login",
    "login.form.action.forgotPassword"        : "Forgot password?",
    "login.form.label.signup"                 : "Don't have an account?",
    "login.form.action.signup"                : "Sign up",
    "login.form.validation.email.required"    : "Email is required",
    "login.form.validation.email.email"       : "This must be a valid email",
    "login.form.validation.password.required" : "Password is required",

    "login.form.action.next"               : "Next",
    "login.form.action.send_email.success" : "An email has been sent.",

    // Login 2FA / MFA
    "login.mfa.SMS_MFA.label"            : "Enter the code we sent you to the phone number ending by ",
    "login.mfa.SOFTWARE_TOKEN_MFA.label" : "Use your 2-Step Verification Application to enter the verification code ",

    // Login reactivate
    "login.reactivate.title"   : "Re-activate your account",
    "login.reactivate.message" : "Please contact the support team at {email} to reactivate your account.",

    // Login locked
    "login.locked.title"   : "Your account has been locked",
    "login.locked.message" : "Please contact the support team at {email} to unlock your account.",

    // Admin login
    "login.adminConnexion.loading"       : "Login...",
    "login.adminConnexion.EXPIRED_TOKEN" : "Your session has expired. Please log back in.",

    // AntiSpam
    "login.toast.warning.spam" : "Warning, please wait a few seconds to continue",

    // Error
    "login.error.server"                 : "There was a problem. Please refresh the page and try again.",
    "login.error.101"                    : "Wrong password.",
    "login.error.300"                    : "The email or password is incorrect.",
    "login.error.NotAuthorizedException" : "The password is incorrect. {attempsLeft} {attempsLeft, plural, one {attempt} other {attempts}} left.",
    "login.error.AliasExistsException"   : "An account with the given email already exists.",
    "login.error.LimitExceededException" : "Change limit exceeded, please try again after some time.",
}