import { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CheckboxStar.scss';
import { stringBuilder } from '@spordle/helpers';

/**
 * @description Star checkbox
 *
 * @param {String|Number} id Id added to input and to the for of the label.
 * @param {String|Number} [name] Name added to input. If not specified, id will be the name.
 * @param {boolean} [checked] Default state of the checkbox.
 * @param {string} [className] Added to label container
 */
const CheckboxStar = ({ id, checked, name, className, ...props }) => {
    const [ isChecked, setIsChecked ] = useState(checked);
    const toggleCheckbox = (e) => setIsChecked(e.currentTarget.checked);

    useLayoutEffect(() => {
        setIsChecked(checked);
    }, [ checked ])

    return (
        <label htmlFor={id} className={stringBuilder(`sfs-label is-${isChecked ? `checked` : `unchecked`}`, { 'is-disabled': props.disabled }, className)}>
            <input name={name ?? id} checked={isChecked} onChange={(e) => toggleCheckbox(e)} id={id} className="sfs-input" type="checkbox" {...props} />
            <i className="mdi mdi-star sfs-icon" />
        </label>
    );
}

CheckboxStar.defaultProps = {
    checked: false,
}

CheckboxStar.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    checked: PropTypes.bool,
}

export default CheckboxStar;
