export default{
    "schedule.btn.today" : "Today",

    "schedule.filter.title" : "Filter events",

    "schedule.helmet.title"       : "{org} Schedule",
    "schedule.helmet.description" : "Full schedule of all the teams of {org}. Showed with a calendar.",

    "schedule.page.name" : "Schedule",

    "schedule.tabs.week"  : "Week",
    "schedule.tabs.day"   : "Day",
    "schedule.tabs.month" : "Month",
    "schedule.tabs.full"  : "Full",

    "schedule.daily.recurrent.title" : "Recurrent",
    "schedule.daily.empty.title"     : "No events",
    "schedule.daily.empty.text"      : "We did not find any events to display. Enjoy your day!",

    "schedule.label.time.starts"      : "Starts",
    "schedule.label.time.ends"        : "Ends",
    "schedule.label.time.startsAt"    : "Starts at",
    "schedule.label.time.until"       : "Until",
    "schedule.label.time.allDay"      : "All day",
    "schedule.label.upcomingEvents"   : "Upcoming Events",
    "schedule.label.payment"          : "Payment",
    "schedule.label.members"          : "Members",
    "schedule.label.scheduledPayment" : "Scheduled payment",
    "schedule.label.amount"           : "Amount",
    "schedule.label.invoice"          : "Invoice",
    "schedule.label.duration"         : "Duration",
    "schedule.label.link.hu"          : "Hockey University",
    "schedule.label.link.hcop"        : "HCOP Login",
    "schedule.format.ONLINE"          : "Online - Recorded/On-Demand",
    "schedule.format.ONLINE_LIVE"     : "Online - Live",
    "schedule.format.ONSITE"          : "In Person",

    "schedule.error.title"   : "Oops!",
    "schedule.error.message" : "Something went wrong. Please come back later or contact our support team.",
}