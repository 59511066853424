/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {useContext}from'react';import {FormikElementsGlobalConfigsContext}from'../components/FormikElementsContext.js';/**
 * @function useFormikElementsGlobalConfigsContext
 * @description Custom hook to get the FormikElementsGlobalConfigsContext in a functional component
 * @example
 * const formikElementsGlobalConfigsContext = useFormikElementsGlobalConfigsContext();
 */
function useFormikElementsGlobalConfigsContext() {
    const formikElementsContext = useContext(FormikElementsGlobalConfigsContext);
    if (!formikElementsContext) {
        console.warn('useFormikElementsGlobalConfigsContext: There is no FormikElementsGlobalConfigs wrapping this hook');
    }
    return formikElementsContext;
}export{useFormikElementsGlobalConfigsContext as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/