import Translate from '@spordle/intl-elements'

const VaultCardsHeader = () => {
    return (
        <div className="d-flex align-items-end pb-1 flex-wrap border-bottom mb-3">
            <div className="h4 mb-0 font-bold card-title">
                <Translate id='account.paymentMethods.vault.title' />
            </div>
            {/* <button id='togglePaysafeHint' onClick={() => setInfoIsOpen(!infoIsOpen)} className="reset-btn ml-auto"
                aria-label={intl.formatMessage({ id: 'account.paymentMethods.vault.hint.ariaLabel' })}
                aria-describedby='whatisPaysafe'
            >
                <i className="h4 mb-0 mdi mdi-information-outline text-primary" />
            </button>
            <Collapse className="w-100" isOpen={infoIsOpen}>
                <div className="py-2 w-100" id='whatisPaysafe'>
                    Long paragraph that explains what exactly is PaySafe's vault.
                </div>
            </Collapse> */}
        </div>
    )
}

export default VaultCardsHeader
