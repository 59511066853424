/*
@Copyrights Spordle 2023 - All rights reserved
*/
import {jsxs,Fragment,jsx}from'react/jsx-runtime';import PropTypes from'prop-types';import {Label,FormText}from'reactstrap';import Datetime from'react-datetime';import {stringBuilder}from'@spordle/helpers';import {useIntl}from'react-intl';import {useField}from'formik';import {memo,forwardRef}from'react';import FormikError from'./FormikError.js';import {Input,Btn}from'../styles/stitchesDateTime.js';import useFormikElementsGlobalConfigsContext from'../src/useFormikElementsContext.js';/**
 * @member FormikDateTime
 * @description
 * FormikDateTime will create an input text that will work automagically with Fromik(yes it's a typo :) -> Update: At this point it's a feature) validations<br>
 * **IMPORTANT:** The value that is used for formik is either a string(invalid date) or a {@link https://momentjs.com/docs/#/displaying/format/|Moment object}
 * NOTE: Work with french and english only
 * @example
 * <FormikDateTime label='DateTime testing' translateLabel={false} name="date" id="date" timeFormat={false} initialValue={new Date(1990, 0)} locale='en'/>
 *
 * @prop {string} name Used to make the FormikDateTime work with validation
 * @prop {bool} [trim] If the field trims on onBlur
 * @prop {string|React.ReactNode} [label] The lang id to be translated OR JSX
 * @prop {string|React.ReactNode} [helper] The lang id to be translated OR JSX
 * @prop {string} [placeholder] The lang id to be translated
 * @prop {boolean} [translateHelper=true]
 * @prop {boolean} [translatePlaceholder=true]
 * @prop {boolean} [translateLabel=true]
 * @prop {boolean} [manualError=false] This prevents the error from displaying. Use {@link FormikError} to display the error manually
 * @returns {React.ReactNode} Returns a {@link https://github.com/arqex/react-datetime|DateTime} picker that will work with Formik
 *
 * @see https://github.com/arqex/react-datetime
 * @copyright Spordle
 */
const FormikDateTime = forwardRef(({ label, helper, placeholder, translatePlaceholder, translateHelper, translateLabel, manualError, dateFormat, ...props }, ref) => {
    // @ts-ignore
    const [{ value, ...field }, meta, helpers] = useField(props);
    const intl = useIntl();
    const formikElementsGlobalConfigsContext = useFormikElementsGlobalConfigsContext();
    if (translatePlaceholder && placeholder && typeof placeholder === 'string')
        placeholder = intl.formatMessage({ id: placeholder });
    if (translateHelper && helper && typeof helper === 'string')
        helper = intl.formatMessage({ id: helper });
    if (translateLabel && label && typeof label === 'string')
        label = intl.formatMessage({ id: label });
    return (jsxs(Fragment, { children: [label && typeof label === 'string' ? jsx(Label, { for: props.id || props.name, children: label }) : label, jsx(Datetime, { ...field, ...props, ref: ref, dateFormat: dateFormat ?? formikElementsGlobalConfigsContext?.formikDateTime?.dateFormat ?? 'YYYY-MM-DD', initialValue: value || undefined, inputProps: {
                    id: props.id,
                    name: props.name,
                    disabled: props.disabled,
                    autoComplete: 'new-password',
                    ...props.inputProps,
                    placeholder: props.inputProps?.placeholder ?? placeholder,
                    className: stringBuilder('form-control', props.inputProps?.className, { 'is-invalid': meta.touched && meta.error }),
                }, onChange: (value) => {
                    helpers.setValue(value);
                    props.onChange?.(value);
                }, locale: intl.locale.toLowerCase(), renderInput: props.renderInput || ((inputProps, openCalendar, closeCalendar) => (jsxs("div", { className: 'search-wrapper', children: [jsx(Input, { isClearable: !!value, ...inputProps }), jsx(Btn, { type: 'button', isInvisible: !value, className: stringBuilder('reset-btn search-clear'), onClick: () => {
                                closeCalendar();
                                inputProps.onChange({ target: { value: '' } });
                            }, disabled: !!inputProps.disabled || !value, children: jsx("i", { className: 'mdi mdi-close' }) }), jsx(Btn, { position: "left", type: 'button', className: 'reset-btn search-clear', onClick: openCalendar, disabled: !!inputProps.disabled, children: jsx("i", { className: stringBuilder('mdi', { 'mdi-calendar': !props.timeFormat, 'mdi-clock-outline': props.timeFormat }) }) })] }))) }), helper && typeof helper === 'string' ? jsx(FormText, { color: "muted", children: helper }) : helper, !manualError && jsx(FormikError, { name: props.name })] }));
});
FormikDateTime.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    translateHelper: PropTypes.bool,
    translateLabel: PropTypes.bool,
    translatePlaceholder: PropTypes.bool,
    name: PropTypes.string.isRequired,
    manualError: PropTypes.bool,
};
FormikDateTime.defaultProps = {
    translateHelper: true,
    translateLabel: true,
    translatePlaceholder: true,
    inputProps: {},
};
FormikDateTime.displayName = 'FormikDateTime';
const FormikDateTime$1 = memo(FormikDateTime);export{FormikDateTime$1 as default};
/*
@Copyrights Spordle 2023 - All rights reserved
*/