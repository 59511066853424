import { useEffect, useState } from "react";
import { Collapse, Fade } from "reactstrap"

const CrossFade = ({ isVisible, children, unmountOnExit, onEntering, onExited, ...props }) => {
    const [ isMounted, setIsMounted ] = useState(unmountOnExit ? isVisible : true);

    useEffect(() => {
        if(unmountOnExit && isVisible && !isMounted){
            setIsMounted(true);
        }
    }, [ isVisible ]);

    return (
        <Collapse {...props} isOpen={isVisible}>
            <Fade in={isVisible}>
                {children}
            </Fade>
        </Collapse>
    )
}

export default CrossFade;