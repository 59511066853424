import { parseUrl } from "query-string";
import { getSessionStorageItem } from "./browserStorage";

/**
 * @param {'SPORDLE'|'ACCOUNT'|'HCR'|'PAGE'|'PAGE-VERCEL'|'PAGE-VERCEL-2'|'TOUR'|'HCR-SITE'} platform
 * @param {'SQ'|'BQ'|'UK'} [from]
 * @returns
 */
function getReferrer(platform, from){
    switch (platform){
        case 'SPORDLE':
            return window.location.href;
        case 'ACCOUNT':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return `https://account.spordle.com`;
                case 'preprod':
                    return `https://account.preprod.hcr.spordle.com`;
                case 'training':
                    return `https://account.training.hcr.spordle.com`;
                case 'uat':
                    return `https://account.uat.spordle.dev`;
                case 'hcstage':
                    return `https://account.hcstage.spordle.dev`;
                case 'stage':
                    return `https://account.stage.spordle.dev`;
                case 'int':
                    return `https://account.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://account.dev.spordle.dev`;
            }
        case 'PAGE':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' ? 'https://page.eiha.spordle.com' : 'https://page.hockeycanada.ca';
                case 'preprod':
                    return `https://page.training.hcr.spordle.com`;
                case 'training':
                    return `https://page.training.hcr.spordle.com`;
                case 'uat':
                    return `https://page.uat.spordle.dev`;
                case 'hcstage':
                    return `https://page.hcstage.spordle.dev`;
                case 'stage':
                    if(isDemo()){
                        return 'https://page.demo.spordle.dev';
                    }
                    switch (from){
                        case 'BQ':
                            return 'https://page.bq.spordle.dev';
                        case 'SQ':
                            return 'https://page.sq.spordle.dev';
                        case 'UK':
                            return 'https://page.demo.spordle.dev';
                        default:
                            break;
                    }
                    return `https://page.stage.spordle.dev`;
                case 'int':
                    return `https://page.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://page.dev.spordle.dev`;
            }
        case 'PAGE-VERCEL':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' ? 'https://page.eiha.spordle.com' : 'https://page2.hockeycanada.ca';
                case 'training':
                    return `https://pages-git-training-spordle.vercel.app`;
                case 'uat':
                    return `https://pages-git-uat-spordle.vercel.app`;
                case 'hcstage':
                    return `https://pages-git-hcstage-spordle.vercel.app`;
                case 'stage':
                    if(isDemo()){
                        return 'https://page.demo.spordle.dev';
                    }
                    switch (from){
                        case 'BQ':
                            return 'https://page.bq.spordle.dev';
                        case 'SQ':
                            return 'https://page.sq.spordle.dev';
                        case 'UK':
                            return 'https://page.demo.spordle.dev';
                        default:
                            break;
                    }
                    return `https://pages-git-stage-spordle.vercel.app`;
                case 'int':
                    return `https://pages-git-int-spordle.vercel.app`;
                case 'dev':
                default:
                    return `https://pages-git-dev-spordle.vercel.app`;
            }
        case 'PAGE-VERCEL-2':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://page.spordle.com';
                case 'training':
                case 'uat':
                    return `https://page.v2.spordle.dev`;
                case 'hcstage':
                case 'stage':
                    return `https://pages-next-v2-git-pagenextv2-stage-spordle.vercel.app`;
                case 'int':
                case 'dev':
                default:
                    return `https://pages-next-v2-git-pagenextv2-int-spordle.vercel.app`;
            }
        case 'HCR': // SID - HCR
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' ? 'https://id.eiha.spordle.com' : 'https://hcr3.hockeycanada.ca';
                case 'preprod':
                    return `https://preprod.hcr.spordle.com`;
                case 'training':
                    return `https://id.training.hcr.spordle.com`;
                case 'uat':
                    return `https://id.uat.spordle.dev`;
                case 'hcstage':
                    return `https://id.hcstage.spordle.dev`;
                case 'stage':
                    if(isDemo()){
                        return 'https://id.demo.spordle.dev';
                    }
                    switch (from){
                        case 'BQ':
                            return 'https://id.bq.spordle.dev';
                        case 'SQ':
                            return 'https://id.sq.spordle.dev';
                        case 'UK':
                            return 'https://id.demo.spordle.dev';
                        default:
                            break;
                    }
                    return `https://id.stage.spordle.dev`;
                case 'int':
                    return `https://id.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://id.dev.spordle.dev`;
            }
        case 'SID':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' ? 'https://id.eiha.spordle.com' : 'https://id.spordle.com';
                case 'preprod':
                    return 'https://id.training.hcr.spordle.com';
                case 'training':
                    return 'https://id.training.hcr.spordle.com';
                case 'uat':
                    return `https://id.uat.spordle.dev`;
                case 'hcstage':
                    return `https://id.hcstage.spordle.dev`;
                case 'stage':
                    if(isDemo()){
                        return 'https://id.demo.spordle.dev';
                    }
                    switch (from){
                        case 'BQ':
                            return 'https://id.bq.spordle.dev';
                        case 'SQ':
                            return 'https://id.sq.spordle.dev';
                        case 'UK':
                            return 'https://id.demo.spordle.dev';
                        default:
                            break;
                    }
                    return `https://id.stage.spordle.dev`;
                case 'int':
                    return `https://id.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://id.dev.spordle.dev`;
            }
        case 'HCR-SITE':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://register.hockeycanada.ca';
                case 'preprod':
                    return `https://hcrsite.training.hcr.spordle.com`;
                case 'training':
                    return `https://hcrsite.training.hcr.spordle.com`;
                case 'uat':
                    return `https://hcrsite.uat.spordle.dev`;
                case 'hcstage':
                    return `https://hcrsite.hcstage.spordle.dev`;
                case 'stage':
                    return `https://hcrsite.stage.spordle.dev`;
                case 'int':
                    return `https://hcrsite.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://hcrsite.dev.spordle.dev`;
            }
        case 'TOUR':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    // TODO
                    return 'Some prod url';
                case 'stage':
                default:
                    return 'https://stage.d22wymmaco0ct.amplifyapp.com/auth';
                case 'dev':
                    return 'https://dev.d22wymmaco0ct.amplifyapp.com/auth';
            }
        default:
            break;
    }
}

function getSubDomain(){
    return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
}

function getDomain(url = window.location.href){
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    url = url.split('.');
    url = url[url.length - 2];

    if(!url)
        return '';

    if(url.indexOf('/') !== -1){
        return url.split('/')[0];
    }

    return url;
}

function getFullSubDomain(url = window.location.host){
    const splittedDomain = url.split('.');
    const domain = getDomain(url);
    const buildedSubDomain = [];
    for(let index = 0; index < splittedDomain.length; index++){
        if(splittedDomain[index] === domain){ // Is the domain
            return buildedSubDomain.join('.');
        }
        buildedSubDomain.push(splittedDomain[index]);
    }
    return buildedSubDomain.join('.')
}

export const MULTISPORT_SESSION_STORAGE_KEY = 'multisport';

export function getMultisportApiUrl(){
    if(process.env.REACT_APP_VERSION_CLIENT === 'EIHA'){
        return 'https://proxy.eiha.spordle.com/v1';
    }

    if(process.env.REACT_APP_ENVIRONMENT !== 'stage')
        return '';

    try{
        const { query } = parseUrl(location.href);
        const { origin } = new URL(query.referrer);
        switch (origin){
            case "https://id.sq.spordle.dev":
            case "https://page.sq.spordle.dev":
                return "https://proxy.id.sq.spordle.dev/v1";
            case "https://id.bq.spordle.dev":
            case "https://page.bq.spordle.dev":
                return "https://proxy.id.bq.spordle.dev/v1";
            case "https://id.demo.spordle.dev":
            case "https://page.demo.spordle.dev":
                return "https://proxy.id.demo.spordle.dev/v1";
            default:
                break;
        }
    }catch(_e){
        switch (getSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY)){
            case "SQ":
                return "https://proxy.id.sq.spordle.dev/v1";
            case "BQ":
                return "https://proxy.id.bq.spordle.dev/v1";
            case "UK":
                return "https://proxy.id.demo.spordle.dev/v1";
            default:
                break;
        }
    }
    return ''
}

export function isSidMultiSport(){
    if(process.env.REACT_APP_VERSION_CLIENT === 'EIHA'){
        return true;
    }
    if(process.env.REACT_APP_ENVIRONMENT !== 'stage')
        return false;

    try{
        const { query } = parseUrl(location.href);
        const { origin } = new URL(query.referrer);
        switch (origin){
            case "https://id.sq.spordle.dev":
            case "https://id.bq.spordle.dev":
            case "https://id.demo.spordle.dev":
                return true;
            default:
                break;
        }
    }catch(_e){
        if(getSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY)){
            return true;
        }
        return false;
    }
    return false;
}

export function isMultiSport(){
    if(process.env.REACT_APP_VERSION_CLIENT === 'EIHA'){
        return true;
    }
    if(process.env.REACT_APP_ENVIRONMENT !== 'stage')
        return false;

    try{
        const { query } = parseUrl(location.href);
        const { origin } = new URL(query.referrer);
        switch (origin){
            case "https://id.sq.spordle.dev":
            case "https://id.bq.spordle.dev":
            case "https://id.demo.spordle.dev":
            case "https://page.sq.spordle.dev":
            case "https://page.bq.spordle.dev":
            case "https://page.demo.spordle.dev":
                return true;
            default:
                break;
        }
    }catch(_e){
        if(getSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY)){
            return true;
        }
        return false;
    }
    return false;
}

export function getMultisportCode(){
    if(process.env.REACT_APP_VERSION_CLIENT === 'EIHA'){
        return 'UK';
    }
    if(process.env.REACT_APP_ENVIRONMENT !== 'stage')
        return;

    try{
        const { query } = parseUrl(location.href);
        const { origin } = new URL(query.referrer);
        switch (origin){
            case "https://id.sq.spordle.dev":
            case "https://page.sq.spordle.dev":
                return 'SQ'
            case "https://id.bq.spordle.dev":
            case "https://page.bq.spordle.dev":
                return 'BQ'
            case "https://id.demo.spordle.dev":
            case "https://page.demo.spordle.dev":
                return 'UK';
            default:
                break;
        }
    }catch(_e){
        return getSessionStorageItem(MULTISPORT_SESSION_STORAGE_KEY);
    }
    return;
}

export function isProdFishNChips(){
    return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' && process.env.REACT_APP_ENVIRONMENT === 'prod'
}

export function isDemo(){
    try{
        return new URL(location.href).origin === 'https://account.demo.spordle.dev';
    }catch(_e){
        return false;
    }
}

export default getReferrer;
export{ getDomain, getFullSubDomain, getSubDomain };