/*
@Copyrights Spordle 2022 - All rights reserved
*/
import {createStitches}from'@stitches/react';const padding = 0.375;
const height = 200;
const KEYBOARD_HEIGHT = 256;
const menuOffset = 2;
const spordleSelectStitches = createStitches({
    utils: {
        py: (value) => ({
            paddingBottom: value,
            paddingTop: value,
        }),
        px: (value) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
    },
    prefix: 'spordleSelect',
    theme: {
        colors: {
            borderColor: 'rgba(0, 0, 0, 0.2)',
        },
        space: {
            paddingHalf: `${padding / 2}rem`,
            padding: `${padding}rem`,
            padding2: `${padding * 2}rem`,
            padding3: `${padding * 3}rem`,
            iconWidth: '2rem',
            selectMenuHeight: `${height}px`,
            mobileSelectMenuHeight: `calc(100vh - ${KEYBOARD_HEIGHT}px)`,
            menuOffset: `${menuOffset}px`,
        },
        sizes: {
            selectMenuHeight: '$space$selectMenuHeight',
        },
    },
});
const loadingDots = spordleSelectStitches.keyframes({
    '0%': {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0), 0.4rem 0 0 rgba(0,0,0,0)',
    },
    '20%': {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0.5), 0.4rem 0 0 rgba(0,0,0,0)',
    },
    '40%': {
        backgroundColor: 'rgba(0,0,0,0.5)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0.25), 0.4rem 0 0 rgba(0,0,0,0)',
    },
    '60%': {
        backgroundColor: 'rgba(0,0,0,0.25)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0), 0.4rem 0 0 rgba(0,0,0,0.5)',
    },
    '80%': {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0), 0.4rem 0 0 rgba(0,0,0,0.25)',
    },
    '100%': {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: '-0.4rem 0 0 rgba(0,0,0,0), 0.4rem 0 0 rgba(0,0,0,0)',
    },
});
// https://stackoverflow.com/questions/3047337/does-overflowhidden-applied-to-body-work-on-iphone-safari
const scrollLock = spordleSelectStitches.css({
    position: 'relative',
    overflow: "hidden !important",
    variants: {
        search: { true: {} },
        device: { ios: {} },
    },
    compoundVariants: [
        {
            search: true,
            device: "ios",
            css: {
                position: "fixed",
            },
        },
    ],
});
// .spordleSelect-dummy-input
const DummySearchInput = spordleSelectStitches.styled('input', {
    display: 'none',
});export{DummySearchInput,KEYBOARD_HEIGHT,height,loadingDots,menuOffset,scrollLock,spordleSelectStitches};
/*
@Copyrights Spordle 2022 - All rights reserved
*/