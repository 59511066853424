export default{
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.title"                         : "Bulk update",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.helper"                        : "You are about to update {mode, select, PRESENCE {presence status} RESULT {results} other {{mode}}} for all members below",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.inputLabel"                    : "{mode, select, PRESENCE {Presence} RESULT {Result} other {{mode}}}",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.cannot"                        : "The selected members cannot be updated",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateBefore"         : "The date must be before today",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter"          : "The date must be after {minDate}",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validation.dateAfter.attended" : "The date must be after the attended date ({minDate})",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.title"       : "{members, plural, =0 {Member} one {Member} other {Members}} ({members})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.description" : "The following {members, plural, =0 {member} one {member} other {members}} will be updated",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.validMembers.none"        : "No members can be updated",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.title"    : "{members, plural, =0 {Invalid member} one {Invalid member} other {Invalid members}} ({members})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.subtitle" : "These members cannot be updated",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.paid"     : "This member did not pay",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.attended" : "This member did not attend",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.invalidMembers.passed"   : "This member already passed",

    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success"                : "Successfull update ({count})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.success.desc"           : "The following {count, plural, =0 {member has} one {member has} other {members have}} been updated",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail"                   : "Failed update ({count})",
    "account.members.clinicsManagement.attendeesActions.bulkUpdate.confirm.fail.desc"              : "The following {count, plural, =0 {member} one {member} other {members}} could not be updated",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result"   : "Update results",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence" : "Update presence status",
}